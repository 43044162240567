import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthData } from '../../types/systemTypes';

interface AuthPersistState {
  data: AuthData;
}

const initialState: AuthPersistState = { data: {} };

const authPersist = createSlice({
  name: 'authPersist',
  initialState,
  reducers: {
    persistUserData(state, action: PayloadAction<AuthData>) {
      state.data = action.payload;
    },
    removeUserData(state, action) {
      state.data = {};
    },
  },
});

export const { persistUserData, removeUserData } = authPersist.actions;

export const authPersistReducer = authPersist.reducer;
