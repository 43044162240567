import { getReceiverName, uniqueBy } from '../utils';
import type { IdNamePair } from '../../types';

interface RequiresStateCode {
  countryId: number | string;
  countriesObj: Record<number, Record<string, any>>;
  stateAlternate?: string;
}

export function getPackageTypesOptions(
  carrierCodes: Array<string | 'all'>,
  carrierPackageTypes: Record<string, Array<IdNamePair>>,
  multiple?: boolean,
) {
  if (!carrierPackageTypes) return;
  let packageTypeOptions = [] as Array<IdNamePair>;
  if (carrierCodes?.length === 0 || carrierCodes.includes('all')) {
    packageTypeOptions = Object.values(carrierPackageTypes).flatMap(
      (types) => types,
    );
  } else {
    // Filter package types based on selected carrier codes
    packageTypeOptions = carrierCodes.flatMap(
      (carrierCode) => carrierPackageTypes[carrierCode.toLowerCase()] || [],
    );
  }

  if (multiple && packageTypeOptions.length > 0) {
    packageTypeOptions.unshift({ id: 'all', name: 'All package types' });
  }

  return uniqueBy(packageTypeOptions, 'id') || [];
}

export function requiresStateCode({
  countryId: _countryId,
  countriesObj,
  stateAlternate,
}: RequiresStateCode): boolean {
  const countryId =
    typeof _countryId === 'string' ? parseInt(_countryId) : _countryId;

  const countryCode = countriesObj[countryId]?.code;

  return (
    !countryCode ||
    countryCode === 'US' ||
    (countryCode === 'CA' && !stateAlternate)
  );
}

export function getPostalName({ firstName, lastName, companyName }) {
  let receiverName = getReceiverName({
    firstName: firstName,
    lastName: lastName,
  });
  if (companyName && receiverName) {
    receiverName = receiverName + ` - ${companyName}`;
  } else if (companyName) {
    receiverName = companyName;
  }
  return receiverName;
}
