import { useCallback, useState, useEffect } from 'react';
import { ButtonGroup, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useNotify } from '../../hooks';
import { scanModes } from '../../lib';
import { useDispatch, getBasicPackingMaterials } from '../../state';
import { AutoCompleteScanner } from '../common';
import { validateSelectedMaterial } from './orderValidators';

export function ScanPackingMaterial({
  includePackingType,
  excludePackingType,
  onScan,
  setScanMode,
  scanMode,
  customerID,
  disabled,
  inputText,
  fetchedMaterial,
  setFetchedMaterial,
}) {
  const { classes, cx } = styles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const [packingItems, setPackingItems] = useState(fetchedMaterial);

  useEffect(() => {
    (async function () {
      if (!fetchedMaterial) {
        const { data, error } = await dispatch(
          getBasicPackingMaterials({
            includeTypeList: includePackingType,
            excludeTypeList: excludePackingType,
            customerID,
          }),
        );
        if (error) {
          return notify(error.message, 'error');
        }
        setPackingItems(data);
        setFetchedMaterial && setFetchedMaterial(data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scanValidatorFunc = useCallback(({ value }) => {
    return validateSelectedMaterial({ value });
  }, []);

  if (!packingItems) return null;

  return (
    <div className={classes.card}>
      {setScanMode && (
        <ButtonGroup color='primary' className={classes.btnGroup}>
          <Button
            onClick={() => setScanMode(scanModes.PICK_AND_STICK)}
            className={cx(classes.tab, {
              [classes.activeTab]: scanMode === scanModes.PICK_AND_STICK,
            })}
          >
            Pick & stick
          </Button>
          <Button
            onClick={() => setScanMode(scanModes.PICK_AND_PACK)}
            className={cx(classes.tab, {
              [classes.activeTab]: scanMode === scanModes.PICK_AND_PACK,
            })}
          >
            Pick & pack
          </Button>
        </ButtonGroup>
      )}
      {/* {!!packingType && ( */}
      <AutoCompleteScanner
        scanValidatorFunc={scanValidatorFunc}
        onScan={onScan}
        wrapInCard={false}
        options={packingItems}
        optionsLabelProperties={['name']}
        optionsValueProperties={['id']}
        label={inputText ?? 'Scan or select packaging materials'}
        disabled={disabled}
      />
      {/* )} */}
    </div>
  );
}

const styles = makeStyles({
  name: 'scanOrder',
})((theme) => ({
  card: {
    maxWidth: 828,
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: '31px 40px',
  },
  input: {
    width: '100%',
    // marginRight: 24,
  },
  tab: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'capitalize',
    padding: '5px 10px',
    color: '#000000',
    border: '1px solid #D5D8EC',
  },
  activeTab: {
    color: '#00C4F8',
    border: '1px solid #00C4F8',
  },
  btnGroup: {
    width: 200,
    marginRight: 80,
  },
}));
