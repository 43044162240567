import * as yup from 'yup';

const loginSchema = yup.object().shape({
  userName: yup.string().required('Email is required'),
  password: yup.string().required().min(6),
});
const mfAuthSchema = yup.object().shape({
  verificationCode: yup.string().required('Verification is required').min(6),
});

const forgotPwSchema = yup.object().shape({
  userName: yup
    .string()
    .email('please enter a valid Email')
    .required('Email is required'),
});

const resetPwSchema = yup.object().shape({
  password: yup.string().required().min(6),
  confirmPassword: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
});

export { loginSchema, forgotPwSchema, resetPwSchema, mfAuthSchema };
