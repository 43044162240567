import { useState, useEffect, useCallback, Fragment } from 'react';
import { Tabs, Tab, Badge, Tooltip, Button } from '@mui/material';
import { DetailsTab } from './DetailsTab';
import { ItemsTab } from './ItemsTab';
import { PackagesTab } from './PackagesTab';
import { PlaceOnHold, CancelOrder, PauseOrder } from './components';
import { useGetQueryParams, useUpdatePageQueryParams } from '../../hooks';
import { useSelector, orderSelectors } from '../../state';
import { CameraUpload } from '../common';
import { CameraAltIcon } from '../../assets';
import { getDocumentsSubmitUrlAndIdFromServiceType } from '../../lib';
import { useStyles } from './OrderBar.styles';

export function OrderBar({ orderNumber }) {
  const { classes } = useStyles();
  const completedPackagesCount = useSelector(
    orderSelectors.completedPackagesCount,
  );
  const completedItemsQty = useSelector(orderSelectors.completedItemsQty);
  const selectedOrder = useSelector(orderSelectors.selectedOrder);
  const isReship = useSelector(orderSelectors.isReship);

  const isFreight = selectedOrder?.isFreight;

  const { view } = useGetQueryParams();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const [tabPosition, setTabPosition] = useState(view || 'items');

  useEffect(() => {
    if (view) {
      setTabPosition(view);
    } else {
      // default to items tab
      updatePageQueryParams({ view: 'items' });
    }
  }, [view, updatePageQueryParams]);

  const handleTabChange = useCallback(
    (_event, position) => {
      updatePageQueryParams({ view: position });
    },
    [updatePageQueryParams],
  );

  return (
    <div className={classes.container}>
      <div className={classes.topRow}>
        <div className={classes.topLeft}>
          {/* <IconButtonLink
            className={classes.backIcon}
            size='small'
            to={generateUrl(paths.SCAN_ORDER)}
          >
            <ArrowBackIcon />
          </IconButtonLink> */}
          <div className={classes.orderNum}>
            {`${isReship ? 'Reship' : 'Order'} #${orderNumber}`}{' '}
          </div>
        </div>

        {!isReship && (
          <div className={classes.actionContainer}>
            <TakePhoto orderId={selectedOrder?.id} />
            <PlaceOnHold />
            <PauseOrder />
            <CancelOrder orderId={selectedOrder.id} />
          </div>
        )}
      </div>
      <div className={classes.tabContainer}>
        <Tabs
          value={tabPosition}
          onChange={handleTabChange}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          centered
        >
          <Tab label='Details' value='details' className={classes.tab} />
          <Tab
            value='items'
            label={
              <Badge
                badgeContent={completedItemsQty}
                color='primary'
                classes={{ badge: classes.badge, root: classes.badgeRoot }}
                max={999}
                overlap='rectangular'
              >
                <span>Items</span>
              </Badge>
            }
            className={classes.tab}
          />
          <Tab
            label={
              <Badge
                badgeContent={completedPackagesCount}
                color='primary'
                classes={{ badge: classes.badge, root: classes.badgeRoot }}
                overlap='rectangular'
              >
                <span>{`${isFreight ? 'Pallets' : 'Packages'} `}</span>
              </Badge>
            }
            value='packages'
            className={classes.tab}
          />
        </Tabs>
      </div>
      {tabPosition === 'details' && (
        <DetailsTab selectedOrder={selectedOrder} />
      )}
      {tabPosition === 'items' && <ItemsTab />}
      {tabPosition === 'packages' && <PackagesTab />}
    </div>
  );
}

function TakePhoto({ orderId }) {
  const { classes } = useStyles();
  const [photoSubmitData, setPhotoSubmitData] = useState();
  const [showCamera, setShowCamera] = useState(false);

  useEffect(() => {
    const data = getDocumentsSubmitUrlAndIdFromServiceType(
      'OrderFulfillment',
      orderId,
    );
    if (data) {
      setPhotoSubmitData(data);
    }
  }, [orderId]);

  return (
    <Fragment>
      <Tooltip title='Take photo' placement='bottom'>
        <span>
          <Button
            color='secondary'
            className={classes.actionButton}
            size='small'
            onClick={() => setShowCamera(true)}
            disabled={!photoSubmitData}
          >
            <CameraAltIcon fontSize='small' />
          </Button>
        </span>
      </Tooltip>
      {showCamera && (
        <CameraUpload
          open={showCamera}
          handleClose={() => setShowCamera(false)}
          apiConfigs={photoSubmitData}
        />
      )}
    </Fragment>
  );
}
