import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTempAdminToken } from '../../state';
import {
  InputAdornment,
  DialogActions,
  Button,
  Grid2 as Grid,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { TextInput } from '../../components';
import { VisibilityIcon, VisibilityOffIcon } from '../../assets';
import { loginSchema } from '../../schemas';

const initialState = {
  userName: '',
  password: '',
};

export function ManagerLogin({ handleCancel, handleNext }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [showPw, setShowPw] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      const { userName, password } = values;
      const { token } = await dispatch(
        getTempAdminToken({ userName, password }),
      );
      if (token) {
        handleNext(token);
      }
    },
    [dispatch, handleNext],
  );

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: handleSubmit,
    enableReinitialize: false,
    validationSchema: loginSchema,
  });

  const { handleChange: formikHandleChange, ...formikProps } = formik;

  const handleChange = useCallback(
    (e) => {
      // HACK to prevent autocomplete. Don't know why autoComplete='new-password' doesn't work
      if (e.nativeEvent.inputType) {
        formikHandleChange(e);
      }
    },
    [formikHandleChange],
  );

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <div className={classes.layout}>
        <Grid container spacing={5} rowSpacing={2} className={classes.root}>
          <Grid style={{ paddingBottom: 0 }} size={12}>
            <TextInput
              name='userName'
              formikProps={formikProps}
              onChange={handleChange}
              label='Email'
              className={classes.input}
              // autoComplete='new-password'
              slotProps={{
                input: {
                  autoComplete: 'new-password',
                },
              }}
              type='email'
            />
          </Grid>
          <Grid style={{ paddingBottom: 0 }} size={12}>
            <TextInput
              name='password'
              formikProps={formikProps}
              label='Password'
              onChange={handleChange}
              className={classes.input}
              // autoComplete='new-password'
              type={showPw ? 'text' : 'password'}
              slotProps={{
                input: {
                  autoComplete: 'new-password',
                  endAdornment: (
                    <InputAdornment
                      className={classes.visibilityIcon}
                      position='end'
                      onClick={() => setShowPw(!showPw)}
                    >
                      {showPw ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
        </Grid>
        <DialogActions className={classes.actions}>
          <Button color='primary' onClick={handleCancel}>
            Cancel
          </Button>
          <Button color='primary' type='submit' disabled={formik.isSubmitting}>
            Done
          </Button>
        </DialogActions>
      </div>
    </form>
  );
}

const useStyles = makeStyles({ name: { ManagerLogin } })((theme) => ({
  layout: {
    padding: 24,
    [theme.breakpoints.down('tablet')]: {
      padding: 0,
    },
  },
  root: {
    width: 330,
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
  },
  input: {
    width: '100%',
  },
  actions: {
    paddingTop: 40,
  },
}));
