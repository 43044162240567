import _clonedeep from 'lodash.clonedeep';
import {
  authGet,
  authPost,
  // orderStatuses,
  updateNestedState,
  packageCollectionTypes,
  makeuuid,
} from '../../lib';
import {
  setSelectedOrder,
  resetOrderFields,
  // setCanOnlyShip,
  setPhysicalItems,
  showError,
  showNotification,
  setPackages,
  setItemsRemaining,
  setItemsCompleted,
  setPackageEditId,
  setHasUnsealedPackage,
  setIsReship,
  updateReshipProperties,
  setUILoading,
  setShouldPromptDocQty,
  setHasUnsealedPallet,
} from '../features';
import { addResource, customPost } from './resource';

/**
 * Get a order by id
 * @param {string} orderId
 * @returns {normalizeReturn}
 */
export function getOrderById(orderId) {
  return async (dispatch) => {
    const { data, error } = await authGet(`orders/${orderId}`);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

/**
 * Gets an order by id and sets the status to `Processing`
 * @param {number} orderNumber
 * @returns {normalizeReturn}
 */
function getProcessOrder(
  orderNumber,
  shouldResetFields = true,
  shouldShowError,
  isReship,
) {
  return async (dispatch) => {
    shouldResetFields && dispatch(resetOrderFields());
    const params = isReship ? { getAllStatuses: true } : undefined;
    const { data, error } = await authGet([
      `orders/process/${orderNumber}`,
      params,
    ]);
    if (error) {
      shouldShowError && dispatch(showError({ message: error.message }));
      return { error };
    }
    // The order was processed but not shipped.
    // const isNotShippedOrder =
    //   data.status === orderStatuses.CLOSED && !data.hasShipped;
    shouldResetFields && dispatch(setSelectedOrder(data));
    isReship && dispatch(setIsReship(true));
    dispatch(getShouldPromptDocQty(isReship));
    // shouldResetFields &&
    //   isNotShippedOrder &&
    //   dispatch(setCanOnlyShip(isNotShippedOrder));
    return { data };
  };
}

function getShouldPromptDocQty(isReship) {
  return (dispatch, getState) => {
    const {
      orders: { selectedOrder: { documents } = {} },
    } = getState();
    const shouldPrompt =
      !isReship &&
      Array.isArray(documents) &&
      documents.some(
        (d) =>
          d.documentFeeType === 'CartonLabelDocumentFee' ||
          d.documentFeeType === 'ItemLabelDocumentFee',
      );
    dispatch(setShouldPromptDocQty(shouldPrompt));
  };
}

/**
 *
 * @param {object} params
 * @param {string} params.sku
 * @param {string} params.value
 * @param {string} params.itemProperty If the value is an object we can specify the key to modify
 * @param {object} [params.currentRemainingState] This will ensure that we don't override any previous changes to the state
 * @returns
 */
function updateItemsRemaining({
  sku,
  value,
  itemProperty,
  currentRemainingState,
}) {
  return (dispatch, getState) => {
    const {
      orders: { itemsRemaining = {}, originalItems = {} },
    } = getState();
    let itemsRemainingState = _clonedeep(itemsRemaining);
    // if the item was removed from the itemsRemaining list adn they change the quantity we need to add it back
    if (!itemsRemaining[sku]) {
      itemsRemainingState = {
        ...itemsRemainingState,
        [sku]: originalItems[sku],
      };
    }
    const path = [sku, ...ensurePropertyArray(itemProperty)];
    const newState = updateNestedState({
      nestedKey: path,
      value,
      state: currentRemainingState || itemsRemainingState,
    });
    dispatch(setItemsRemaining(newState));
    return newState;
  };
}

/**
 *
 * @param {object} params
 * @param {string} params.sku
 * @param {string} params.value
 * @param {string} params.itemProperty If the value is an object we can specify the key to modify
 * @param {object} [params.currentCompletedState] This will ensure that we don't override any previous changes to the state
 * @returns
 */
function updateItemsCompleted({
  sku,
  value,
  itemProperty,
  currentCompletedState,
}) {
  return (dispatch, getState) => {
    const {
      orders: { itemsCompleted = {} },
    } = getState();
    const path = [sku, ...ensurePropertyArray(itemProperty)];
    const newState = updateNestedState({
      nestedKey: path,
      value,
      state: currentCompletedState || itemsCompleted,
    });
    dispatch(setItemsCompleted(newState));
    return newState;
  };
}

/**
 *
 * @param {object} params
 * @param {string} params.packageNum
 * @param {number} params.childPackageNumber If it's a nested package (ie on a pallet)
 * @param {string} [params.sku]
 * @param {string} params.itemProperty
 * @param {"items"|"material"|"metadata"} [params.collection] collection is either items, material, metadata
 * @param {object} [params.currentPackagesState] Use this to ensure that previous changes will not be overridden when calling `updatePackages` right  after the packages were update
 * @returns {object}
 */
function updatePackages({
  packageNum,
  childPackageNumber,
  sku,
  value,
  itemProperty,
  collection,
  currentPackagesState,
}) {
  return (dispatch, getState) => {
    const {
      orders: { packages = {} },
    } = getState();

    const path = [
      packageNum,
      ...(childPackageNumber ? ['childPackages', childPackageNumber] : []),
      ...ensurePropertyArray(collection), // if updating more then one collection leave empty and specify with payload
      ...ensurePropertyArray(sku), // for material there is no sku
      ...ensurePropertyArray(itemProperty),
    ];
    const newState = updateNestedState({
      nestedKey: path,
      value,
      state: currentPackagesState || packages,
    });
    dispatch(setPackages(newState));
    return newState;
  };
}

/**
 *
 * @param {object} params
 * @param {string} params.sku
 * @param {number} params.originalPackageItemQty The qty of this item on the order
 * @param {object} [params.currentItemsRemainingState] This will ensure that we don't override any previous changes to the state
 * @param {object} [params.currentItemsCompletedState] This will ensure that we don't override any previous changes to the state
 * @param {boolean} [params.shouldDispatchChanges] Flag if this function should update the `itemsRemaining` and `itemsCompleted` states. Defaults to `true`
 * @returns
 */
function syncItemsLists({
  sku,
  originalPackageItemQty,
  currentItemsRemainingState,
  currentItemsCompletedState,
  shouldDispatchChanges = true,
}) {
  return (dispatch, getState) => {
    const {
      orders: { itemsRemaining = {}, itemsCompleted = {}, originalItems = {} },
    } = getState();
    let itemsRemainingCurrentState =
      currentItemsRemainingState || itemsRemaining;
    let itemsCompletedCurrentState =
      currentItemsCompletedState || itemsCompleted;
    const originalItemCompletedQty =
      originalItems[sku].quantity - originalPackageItemQty;
    const newState = updateNestedState({
      nestedKey: [sku, 'quantity'],
      value: originalPackageItemQty,
      state: itemsRemainingCurrentState,
    });
    itemsRemainingCurrentState = newState;
    if (originalItemCompletedQty === 0) {
      // remove item from itemsCompleted
      const { [sku]: itemCompletedToDelete, ...otherCompletedItems } =
        itemsCompletedCurrentState;
      itemsCompletedCurrentState = otherCompletedItems;
    } else {
      const newCompletedState = updateNestedState({
        nestedKey: [sku, 'quantity'],
        value: originalItems[sku].quantity - originalPackageItemQty,
        state: itemsCompletedCurrentState,
      });
      itemsCompletedCurrentState = newCompletedState;
    }
    shouldDispatchChanges &&
      dispatch(setItemsRemaining(itemsRemainingCurrentState));
    shouldDispatchChanges &&
      dispatch(setItemsCompleted(itemsCompletedCurrentState));
    return { itemsRemainingCurrentState, itemsCompletedCurrentState };
  };
}

function removePackedAsFromItemRemaining({
  itemRemainingState,
  itemPackedPerUnitOfMeasureCount,
}) {
  if (
    !itemPackedPerUnitOfMeasureCount ||
    !itemRemainingState?.packedPerUnitOfMeasureCount
  )
    return itemRemainingState;

  const updatedPackedAsState = Object.keys(
    itemPackedPerUnitOfMeasureCount,
  ).reduce((acc, uom) => {
    const qtyForUom = itemRemainingState.packedPerUnitOfMeasureCount[uom];
    if (qtyForUom) {
      const qtyForUomForPackage = itemPackedPerUnitOfMeasureCount[uom];
      acc[uom] = qtyForUom - qtyForUomForPackage;
    }
    return acc;
  }, {});

  return {
    ...itemRemainingState,
    packedPerUnitOfMeasureCount: {
      ...itemRemainingState.packedPerUnitOfMeasureCount,
      ...updatedPackedAsState,
    },
  };
}

/**
 *
 * @param {object} params
 * @param {string} params.packageNum
 * @param {number} params.childPackageNumber If it's a nested package (ie on a pallet)
 * @param {object} params.originalQtyItemsInPackage an object with the item sku as key and qty before packing this package as value ie. {503-12210: 2, 1503-12211: 1}
 * @param {object} [params.updatedPackage] an optional package that has already been modified
 * @returns
 */
function deletePackage({
  packageNum,
  childPackageNumber,
  originalQtyItemsInPackage,
  updatedPackage,
}) {
  return (dispatch, getState) => {
    const {
      orders: {
        itemsRemaining = {},
        itemsCompleted = {},
        originalItems = {},
        packages = {},
        packageEditId,
      },
    } = getState();
    let currentPackage = updatedPackage || packages[packageNum];

    if (childPackageNumber) {
      currentPackage = currentPackage?.childPackages?.[childPackageNumber];
    }

    if (!currentPackage) return;
    if (packageEditId) {
      dispatch(setPackageEditId(null));
    }

    let packageItems = currentPackage.items;

    if (currentPackage.childPackages) {
      packageItems = getGroupedChildPackageItems(currentPackage.childPackages);
    }

    // reset itemsRemaining and itemsCompleted
    if (packageItems) {
      let itemsRemainingState = itemsRemaining;
      let itemsCompletedState = itemsCompleted;
      let packagePhysicalItemsGroup = {};

      Object.keys(packageItems).forEach((sku) => {
        if (!itemsRemaining[sku]) {
          itemsRemainingState = {
            ...itemsRemainingState,
            [sku]: { ...originalItems[sku], quantity: 0 },
          };
        } else {
          itemsRemainingState = {
            ...itemsRemainingState,
            [sku]: removePackedAsFromItemRemaining({
              itemRemainingState: itemsRemaining[sku],
              itemPackedPerUnitOfMeasureCount:
                packageItems[sku].packedPerUnitOfMeasureCount,
            }),
          };
        }

        const { itemsCompletedCurrentState, itemsRemainingCurrentState } =
          dispatch(
            syncItemsLists({
              sku,
              originalPackageItemQty: originalQtyItemsInPackage[sku],
              currentItemsRemainingState: itemsRemainingState,
              currentItemsCompletedState: itemsCompletedState,
              shouldDispatchChanges: false, // only dispatch once
            }),
          );
        itemsRemainingState = itemsRemainingCurrentState;
        itemsCompletedState = itemsCompletedCurrentState;

        const itemPhysicalItems = packageItems[sku].physicalItems;
        if (itemPhysicalItems) {
          const physicalItemsKeys = Object.keys(itemPhysicalItems);
          if (physicalItemsKeys.length) {
            packagePhysicalItemsGroup[sku] = physicalItemsKeys.map(
              (k) => itemPhysicalItems[k],
            );
          }
        }
      });

      dispatch(setItemsRemaining(itemsRemainingState));
      dispatch(setItemsCompleted(itemsCompletedState));

      if (Object.keys(packagePhysicalItemsGroup).length) {
        dispatch(
          addMultipleItemsToPhysicalItemsState(packagePhysicalItemsGroup),
        );
      }
    }

    let newPackageState = {};
    if (childPackageNumber) {
      const { [childPackageNumber]: packageToDelete, ...otherChildPackages } =
        packages[packageNum].childPackages;

      newPackageState = {
        ...packages,
        [packageNum]: {
          ...packages[packageNum],
          childPackages: otherChildPackages,
        },
      };
    } else {
      const { [packageNum]: packageToDelete, ...otherPackages } = packages;
      newPackageState = otherPackages;
    }
    dispatch(setPackages(newPackageState));
  };
}

/**
 * returns a grouped collection of items for all child packages, summing the qty and uom info
 * @param {object} childPackages
 * @returns
 */
function getGroupedChildPackageItems(childPackages) {
  return Object.keys(childPackages).reduce((acc, cur) => {
    const items = childPackages[cur].items;

    if (items) {
      Object.keys(items).forEach((sku) => {
        const item = items[sku];
        const accumulatedItem = acc[sku];

        if (!accumulatedItem) {
          acc[sku] = item;
        } else {
          const {
            quantity: currentQuantity,
            packedPerUnitOfMeasureCount: currentUomCount = {},
          } = acc[sku];
          const { quantity, packedPerUnitOfMeasureCount } = item;

          const summedUomCount = Object.keys(currentUomCount).reduce((a, c) => {
            const uomCount = currentUomCount[c] ?? 0;
            const itemUomCount = packedPerUnitOfMeasureCount[c] ?? 0;
            a[c] = itemUomCount + uomCount;
            return a;
          }, {});

          acc[sku] = {
            ...accumulatedItem,
            quantity: currentQuantity + quantity,
            packedPerUnitOfMeasureCount: summedUomCount,
          };
        }
      });
    }
    return acc;
  }, {});
}

/**
 *
 * @param {object} params
 * @param {string} params.packageNum
 * @param {string} params.sku
 * @param {number} params.originalPackageItemQty The qty of this item on the order
 * @returns
 */
function removeItemFromPackage({
  packageNum,
  childPackageNumber,
  sku,
  originalPackageItemQty,
}) {
  return (dispatch, getState) => {
    const {
      orders: { selectedOrder = {}, packages = {}, itemsRemaining = {} },
    } = getState();
    let currentPackage = packages[packageNum];

    if (childPackageNumber) {
      currentPackage = currentPackage?.childPackages?.[childPackageNumber];
    }

    if (!currentPackage?.items) return;
    // update package
    const { [sku]: itemToDelete, ...otherItems } = currentPackage.items;
    if (!Object.keys(otherItems).length) {
      dispatch(
        deletePackage({
          packageNum,
          childPackageNumber,
          originalQtyItemsInPackage: { [sku]: originalPackageItemQty },
        }),
      );
    } else {
      let newPackageState = {};

      if (childPackageNumber) {
        newPackageState = {
          ...packages,
          [packageNum]: {
            ...packages[packageNum],
            childPackages: {
              ...packages[packageNum].childPackages,
              [childPackageNumber]: {
                ...packages[packageNum].childPackages[childPackageNumber],
                items: otherItems,
              },
            },
          },
        };
      } else {
        newPackageState = {
          ...packages,
          [packageNum]: {
            ...packages[packageNum],
            items: otherItems,
          },
        };
      }

      dispatch(setPackages(newPackageState));
      // update items remaining
      const itemsRemainingState = {
        ...itemsRemaining,
        [sku]: removePackedAsFromItemRemaining({
          itemRemainingState: itemsRemaining[sku],
          itemPackedPerUnitOfMeasureCount:
            currentPackage.items[sku].packedPerUnitOfMeasureCount,
        }),
      };

      dispatch(
        syncItemsLists({
          sku,
          originalPackageItemQty,
          currentItemsRemainingState: itemsRemainingState,
        }),
      );
      // update global physical items collection
      const physicalItemsToReturn = itemToDelete?.physicalItems;
      if (physicalItemsToReturn) {
        const idsToRemove = Object.keys(physicalItemsToReturn);
        // get the physical items from the original list. This will ensure the we reset any edited values
        // on the physical item before we return it to the `physicalItems` array for this sku
        const itemsToRemove = selectedOrder.physicalItems.filter((p) =>
          idsToRemove.includes(p.id + ''),
        );
        dispatch(addToPhysicalItemsState(sku, itemsToRemove));
      }
    }
  };
}

/**
 *
 * @param {string[]} skus
 * @returns
 */
function removeItemsRemaining(skus = []) {
  return (dispatch, getState) => {
    const {
      orders: { itemsRemaining = {} },
    } = getState();
    let currentItemsRemainingState = itemsRemaining;
    skus.forEach((sku) => {
      const { [sku]: itemToDelete, ...otherItems } = currentItemsRemainingState;
      currentItemsRemainingState = otherItems;
    });
    dispatch(setItemsRemaining(currentItemsRemainingState));
  };
}

/**
 *
 * @param {object} params
 * @param {string} params.packageNum
 * @param {number} params.childPackageNumber If it's a nested package (ie on a pallet)
 * @param {string} params.sku
 * @returns
 */
function removeMaterialItem({ packageNum, childPackageNumber, sku }) {
  return (dispatch, getState) => {
    const {
      orders: { packages = {} },
    } = getState();
    let currentMaterial = packages[packageNum]?.material;

    if (childPackageNumber) {
      currentMaterial =
        packages[packageNum]?.childPackages?.[childPackageNumber]?.material;
    }

    if (!currentMaterial) return;
    const { [sku]: itemToDelete, ...otherItems } = currentMaterial;
    let newPackageState = {};
    if (childPackageNumber) {
      newPackageState = {
        ...packages,
        [packageNum]: {
          ...packages[packageNum],
          childPackages: {
            ...packages[packageNum].childPackages,
            [childPackageNumber]: {
              ...packages[packageNum].childPackages[childPackageNumber],
              material: otherItems,
            },
          },
        },
      };
    } else {
      newPackageState = {
        ...packages,
        [packageNum]: {
          ...packages[packageNum],
          material: otherItems,
        },
      };
    }

    dispatch(setPackages(newPackageState));
  };
}

/**
 * Gets random physical items from the imported array
 * and removes it from that array
 * @param {string} sku
 * @param {number} totalItems The amount of physical items to get
 */
function getAndUpdateItemPhysicalItems(sku, totalItems) {
  return (dispatch, getState) => {
    const {
      orders: { physicalItems = {} },
    } = getState();

    const physicalItemsForSku = physicalItems[sku];
    if (!Array.isArray(physicalItemsForSku)) return [];
    // TODO handle for this
    if (physicalItemsForSku.length < totalItems) return [];
    const cloned = _clonedeep(physicalItemsForSku);
    const itemsToReturn = cloned.splice(0, totalItems);
    const newState = {
      ...physicalItems,
      [sku]: cloned,
    };
    dispatch(setPhysicalItems(newState));

    return itemsToReturn;
  };
}

/**
 * This put's physical items back into the `physicalItems` state when removing it from track inventory
 * @param {string} sku
 * @param {Array} physicalItems The physical items to return to the state
 */
function addToPhysicalItemsState(sku, physicalItemsToAdd = []) {
  return (dispatch, getState) => {
    const {
      orders: { physicalItems = {} },
    } = getState();
    const physicalItemsForSku = physicalItems[sku] || [];
    const updatedPhysicalItemsForSku = [
      ...physicalItemsForSku,
      ...physicalItemsToAdd,
    ];
    const newState = {
      ...physicalItems,
      [sku]: updatedPhysicalItemsForSku,
    };
    dispatch(setPhysicalItems(newState));
  };
}

/**
 * This put's physical items for multiple items back into the `physicalItems` collection (ie when deleting a package)
 * @param {object} physicalItemsGroup an object with the sku as the key and an array physical items as the value
 */
function addMultipleItemsToPhysicalItemsState(physicalItemsGroup) {
  return (dispatch, getState) => {
    const {
      orders: { physicalItems = {} },
    } = getState();
    if (!physicalItemsGroup) return;

    const addedItems = Object.keys(physicalItemsGroup).reduce((acc, sku) => {
      const physicalItemsForSku = physicalItems[sku] || [];
      const updatedPhysicalItemsForSku = [
        ...physicalItemsForSku,
        ...physicalItemsGroup[sku],
      ];
      acc[sku] = updatedPhysicalItemsForSku;
      return acc;
    }, {});

    const newState = {
      ...physicalItems,
      ...addedItems,
    };
    dispatch(setPhysicalItems(newState));
  };
}

/**
 * Update the package item with the correct physical items when removing some
 * @param {object} params
 * @param {number} params.packageId
 * @param {number} params.childPackageNumber If it's a nested package (ie on a pallet)
 * @param {string} params.sku
 * @param {Array} params.physicalItemIdsToRemove
 * @param {object} [params.currentPackagesState] If the packages were just updated pass in the updated state to ensure that this won't override those changes
 */
function removePhysicalItemsFromPackage({
  packageId,
  childPackageNumber,
  sku,
  physicalItemIdsToRemove,
  currentPackagesState,
}) {
  return (dispatch, getState) => {
    let allPackages = currentPackagesState;
    if (!currentPackagesState) {
      allPackages = getState().orders.packages || {};
    }
    let selectedPackage = allPackages[packageId];
    if (childPackageNumber) {
      selectedPackage = selectedPackage?.childPackages?.[childPackageNumber];
    }
    if (!selectedPackage) return;

    const currentItemPhysicalItems =
      selectedPackage.items?.[sku]?.physicalItems;
    if (!currentItemPhysicalItems) return;

    const updatedPhysicalItems = Object.keys(currentItemPhysicalItems).reduce(
      (acc, cur) => {
        if (!physicalItemIdsToRemove.includes(cur + '')) {
          acc[cur] = currentItemPhysicalItems[cur];
        }
        return acc;
      },
      {},
    );
    let newState = {};
    if (childPackageNumber) {
      newState = {
        ...allPackages,
        [packageId]: {
          ...selectedPackage,
          childPackages: {
            ...selectedPackage[packageId].childPackages,
            [childPackageNumber]: {
              ...allPackages[packageId].childPackages[childPackageNumber],
              items: {
                ...selectedPackage.items,
                [sku]: {
                  ...selectedPackage.items[sku],
                  physicalItems: updatedPhysicalItems,
                },
              },
            },
          },
        },
      };
    } else {
      newState = {
        ...allPackages,
        [packageId]: {
          ...selectedPackage,
          items: {
            ...selectedPackage.items,
            [sku]: {
              ...selectedPackage.items[sku],
              physicalItems: updatedPhysicalItems,
            },
          },
        },
      };
    }
    dispatch(setPackages(newState));
  };
}

/**
 * Adds physical items when updating qty manually and `not` with inventory tracking.
 * @param {object} params
 * @param {object} params.currentPackagesState If the packages were just updated pass in the updated state to ensure that this won't override those changes
 * @param {number} params.packageId
 * @param {number} params.childPackageNumber If it's a nested package (ie on a pallet)
 * @param {number} params.childPackageNumber If it's a nested package (ie on a pallet)
 * @param {string} params.sku
 * @param {number} params.qty
 * @returns
 */
function addPhysicalItems({
  packageId,
  childPackageNumber,
  currentPackagesState,
  sku,
  qty,
  newPhysicalItems: _newPhysicalItems,
}) {
  return (dispatch, getState) => {
    // Get the required amount of physical items from the `physicalItems` array for this sku and remove them from the array
    // For now we are returning any random items. TODO handle for when editing closed orders
    const {
      orders: { physicalItems = {} },
    } = getState();

    const physicalItemsForSku = physicalItems[sku];

    // if not tracking inventory and there is no physical items for the item then there is no need to do anything
    if (!_newPhysicalItems && !physicalItemsForSku) return;

    let newPhysicalItems = _newPhysicalItems || {};

    if (!_newPhysicalItems) {
      let dbPhysicalItems = {};

      if (physicalItemsForSku?.length) {
        dbPhysicalItems = dispatch(getAndUpdateItemPhysicalItems(sku, qty));
      }

      for (let i = 0; i < qty; i++) {
        const dbPhysicalItem = dbPhysicalItems[i] || {
          expirationDate: null,
          lotNumber: '',
          serialNumber: '',
        };
        newPhysicalItems[dbPhysicalItem.id ?? makeuuid(6)] = dbPhysicalItem;
      }
    }
    dispatch(
      updatePackages({
        currentPackagesState: currentPackagesState,
        packageNum: packageId,
        childPackageNumber,
        collection: packageCollectionTypes.ITEMS,
        sku,
        itemProperty: 'physicalItems',
        value: newPhysicalItems,
      }),
    );
  };
}

/**
 * Adds physical items when doing inventory tracking. This is called only the first time and not when editing existing physical items
 * @param {object} params
 * @param {object} params.currentPackagesState If the packages were just updated pass in the updated state to ensure that this won't override those changes
 * @param {object} params.inventoryState
 * @param {number} params.packageId
 * @param {string} params.sku
 * @returns
 */
function addPhysicalItemsWithInventoryTracking({
  packageId,
  childPackageNumber,
  currentPackagesState,
  sku,
  inventoryState,
}) {
  return (dispatch) => {
    // Get the required amount of physical items from the `physicalItems` array for this sku and remove them from the array
    // For now we are returning any random items. TODO handle for when editing closed orders
    const inventoryStateKeys = Object.keys(inventoryState);
    const dbPhysicalItems = dispatch(
      getAndUpdateItemPhysicalItems(sku, inventoryStateKeys.length),
    );
    const value = inventoryStateKeys.reduce((acc, cur, index) => {
      const dbPhysicalItem = dbPhysicalItems[index] || {};
      const updatedInventory = inventoryState[cur];
      // There may not be a dbPhysicalItem. For example if no physicalItems were returned with the order
      acc[dbPhysicalItem.id ?? makeuuid(6)] = {
        ...dbPhysicalItem,
        ...updatedInventory,
      };

      return acc;
    }, {});

    dispatch(
      addPhysicalItems({
        packageId,
        childPackageNumber,
        currentPackagesState,
        sku,
        qty: inventoryStateKeys.length,
        newPhysicalItems: value,
      }),
    );
  };
}

/**
 * Updates any physical items that were modified
 * @param {object} params
 * @param {object} params.currentPackagesState If the packages were just updated pass in the updated state to ensure that this won't override those changes
 * @param {object} params.currentInventoryState The inventory state before any changes
 * @param {object} params.updatedInventoryState The updated inventory state
 * @param {number} params.packageId
 * @param {string} params.sku
 * @returns
 */
function updatePhysicalItems({
  packageId,
  childPackageNumber,
  currentPackagesState,
  sku,
  currentInventoryState,
  updatedInventoryState,
}) {
  return (dispatch) => {
    dispatch(
      updatePackages({
        currentPackagesState,
        packageNum: packageId,
        childPackageNumber,
        collection: packageCollectionTypes.ITEMS,
        sku,
        itemProperty: 'physicalItems',
        value: Object.keys(updatedInventoryState).reduce((acc, cur, index) => {
          if (currentInventoryState[cur]) {
            acc[cur] = updatedInventoryState[cur];
          }
          return acc;
        }, {}),
      }),
    );
  };
}

/**
 * Updates any physical items that were modified and adds physical items from the item when changing the qty with inventory tracking
 * @param {object} params
 * @param {object} params.currentPackagesState If the packages were just updated pass in the updated state to ensure that this won't override those changes
 * @param {number} params.currentInventoryStateCount
 * @param {number} params.newInventoryStateCount
 * @param {object} params.currentInventoryState The inventory state before any changes
 * @param {object} params.updatedInventoryState The updated inventory state
 * @param {number} params.packageId
 * @param {number} params.childPackageNumber If it's a nested package (ie on a pallet)
 * @param {string} params.sku
 * @returns
 */
function updateAndAddPhysicalItems({
  packageId,
  childPackageNumber,
  newInventoryStateCount,
  currentInventoryStateCount,
  currentInventoryState,
  updatedInventoryState,
  currentPackagesState,
  sku,
}) {
  return (dispatch) => {
    // Get the amount of new physical items from the `physicalItems` array for this sku and remove them from the array
    // For now we are returning any random items. TODO handle for when editing closed orders
    const dbPhysicalItems = dispatch(
      getAndUpdateItemPhysicalItems(
        sku,
        newInventoryStateCount - currentInventoryStateCount,
      ),
    );
    dispatch(
      updatePackages({
        currentPackagesState: currentPackagesState,
        packageNum: packageId,
        childPackageNumber,
        collection: packageCollectionTypes.ITEMS,
        sku,
        itemProperty: 'physicalItems',
        value: Object.keys(updatedInventoryState).reduce((acc, cur, index) => {
          if (currentInventoryState[cur]) {
            // Already in there so just edit
            acc[cur] = updatedInventoryState[cur];
          } else {
            // Add the new one
            const dbPhysicalItem = dbPhysicalItems.shift() || {};
            const updatedInventory = updatedInventoryState[cur];
            acc[dbPhysicalItem.id ?? makeuuid(6)] = {
              ...dbPhysicalItem,
              ...updatedInventory,
            };
          }
          return acc;
        }, {}),
      }),
    );
  };
}

/**
 * Updates any physical items that were modified and remove physical items from the item when changing the qty with inventory tracking
 * and return the items to the global physicalItems collection for this sku
 * @param {object} params
 * @param {object} params.currentPackagesState If the packages were just updated pass in the updated state to ensure that this won't override those changes
 * @param {string[]} params.currentInventoryStateKeys Array of keys for the inventory state before any changes
 * @param {object} params.updatedInventoryState The updated inventory state
 * @param {number} params.packageId
 * @param {number} params.childPackageNumber If it's a nested package (ie on a pallet)
 * @param {string} params.sku
 * @returns
 */
function updateAndRemovePhysicalItems({
  currentInventoryStateKeys,
  currentPackagesState,
  updatedInventoryState,
  packageId,
  childPackageNumber,
  sku,
}) {
  return (dispatch, getState) => {
    const inventoryStateKeys = Object.keys(updatedInventoryState);
    const idsToRemove = currentInventoryStateKeys.filter(
      (key) => !inventoryStateKeys.includes(key),
    );
    // get the physical items from the original list. This will ensure the we reset any edited values
    // on the physical item before we return it to the `physicalItems` array for this sku
    const {
      orders: { selectedOrder },
    } = getState();
    const itemsToRemove = selectedOrder.physicalItems.filter((p) =>
      idsToRemove.includes(p.id + ''),
    );
    // First edit the item's physical items and save the updated packages state
    const newPackageState = dispatch(
      updatePackages({
        currentPackagesState: currentPackagesState,
        packageNum: packageId,
        childPackageNumber,
        collection: packageCollectionTypes.ITEMS,
        sku,
        itemProperty: 'physicalItems',
        value: inventoryStateKeys.reduce((acc, cur, index) => {
          acc[cur] = updatedInventoryState[cur];
          return acc;
        }, {}),
      }),
    );
    // remove the physical items to delete from the item's physical items
    dispatch(
      removePhysicalItemsFromPackage({
        packageId,
        childPackageNumber,
        physicalItemIdsToRemove: idsToRemove,
        sku,
        currentPackagesState: newPackageState,
      }),
    );
    // return the removed items to the `physicalItems` array
    dispatch(addToPhysicalItemsState(sku, itemsToRemove));
  };
}

/**
 * Removes physical items from the item when changing the qty manually (not with inventory tracking)
 * and return the items to the global physicalItems collection for this sku
 * @param {object} params
 * @param {object} params.currentPackagesState If the packages were just updated pass in the updated state to ensure that this won't override those changes
 * @param {number} params.qty
 * @param {number} params.packageId
 * @param {number} params.childPackageNumber If it's a nested package (ie on a pallet)
 * @param {string} params.sku
 * @returns
 */
function removePhysicalItems({
  currentPackagesState,
  qty,
  packageId,
  childPackageNumber,
  sku,
}) {
  return (dispatch, getState) => {
    const {
      orders: { packages, selectedOrder = {} },
    } = getState();

    let itemPhysicalItems = packages[packageId].items[sku]?.physicalItems;

    if (childPackageNumber) {
      itemPhysicalItems =
        packages[packageId]?.childPackages?.[childPackageNumber]?.items[sku]
          ?.physicalItems;
    }

    const hasItemPhysicalItems =
      !!itemPhysicalItems && Object.keys(itemPhysicalItems).length;

    if (!hasItemPhysicalItems) return;

    const physicalItemsKeys = Object.keys(itemPhysicalItems);
    if (physicalItemsKeys.length - 1 < qty) return; // TODO
    const idsToRemove = physicalItemsKeys.slice(-qty);
    // get the physical items from the original list. This will ensure the we reset any edited values
    // on the physical item before we return it to the `physicalItems` array for this sku
    const itemsToRemove = selectedOrder.physicalItems.filter((p) =>
      idsToRemove.includes(p.id + ''),
    );

    // remove the physical items to delete from the item's physical items
    dispatch(
      removePhysicalItemsFromPackage({
        packageId,
        childPackageNumber,
        physicalItemIdsToRemove: idsToRemove,
        sku,
        currentPackagesState,
      }),
    );
    // return the removed items to the `physicalItems` array
    dispatch(addToPhysicalItemsState(sku, itemsToRemove));
  };
}

/**
 * Process an order
 * @param {object} params
 * @param {object} params.payload
 * @param {object} [params.token] Use if we need an admin token
 * @returns {normalizeReturn}
 */
function processOrder({ payload, token }) {
  return async (dispatch) => {
    if (token) {
      const { error, data } = await dispatch(
        customPost({
          url: 'orders/process',
          body: payload,
          authToken: token,
        }),
      );
      if (error) return { error };
      return { data };
    } else {
      const { data, error } = await authPost(`orders/process/`, payload);
      if (error) return { error };
      const validationMessage = data.validationMessages?.[0];
      if (validationMessage) {
        // dispatch(setSelectedOrder(data));
        // dispatch(setCanOnlyShip(true));
        return { error: validationMessage };
      }
      return { data };
    }
  };
}

/**
 * Process an order
 * @param {object} params
 * @param {object} params.payload
 * @returns {normalizeReturn}
 */
function processFreightOrder({ payload, showLoader = true, token }) {
  return async (dispatch) => {
    showLoader && dispatch(setUILoading(true));
    if (token) {
      const { error, data } = await dispatch(
        customPost({
          url: 'orders/process/freight',
          body: payload,
          authToken: token,
        }),
      );
      showLoader && dispatch(setUILoading(false));
      if (error) return { error };
      return { data };
    } else {
      const { data, error } = await authPost(`orders/process/freight`, payload);
      showLoader && dispatch(setUILoading(false));
      if (error) return { error };
      const validationMessage = data.validationMessages?.[0];
      if (validationMessage) {
        // dispatch(setSelectedOrder(data));
        // dispatch(setCanOnlyShip(true));
        return { error: validationMessage };
      }
      return { data };
    }
  };
}

/**
 * Process an order
 * @param {object} params
 * @param {object} params.orderId
 * @param {object} params.payload
 * @param {object} [params.token] Use if we need an admin token
 * @returns {normalizeReturn}
 */
function processReship({ orderId, payload }) {
  return async (dispatch) => {
    const { data, error } = await authPost(`orders/${orderId}/reship`, payload);
    if (error) {
      dispatch(showError({ message: error.message }));
      return { error };
    }
    const validationMessage = data.validationMessages?.[0];
    if (validationMessage) {
      dispatch(showError({ message: validationMessage.message }));
      // save the created entity ids
      const entityIds = {
        overrideOrderShipmentID: data.overrideOrderShipmentID,
      };
      if (data.shipToContactOverride) {
        entityIds.shipToContactId = data.shipToContactOverride.id;
      }
      dispatch(updateReshipProperties({ entityIds }));
      return { error: validationMessage.message };
    }
    dispatch(
      showNotification({ message: 'Successfully processed the reship.' }),
    );
    return { data };
  };
}

// function updateOrderFields(data) {
//   return dispatch => {

//   }
// }

function printLabels(orderId, shipmentId) {
  return async (dispatch, getState) => {
    const {
      systemPersist: { machineName },
    } = getState();
    dispatch(setUILoading(true));
    const { data, error } = await authPost([
      `orders/${orderId}/print-labels/${shipmentId}`,
      { machineName },
    ]);
    dispatch(setUILoading(false));
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'Error printing labels',
        }),
      );
      return { error };
    }
    dispatch(showNotification({ message: 'Labels printing' }));
    return { data };
  };
}

function printOrderCustomsForm(orderId, shipmentId) {
  return async (dispatch, getState) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `orders/${orderId}/print-customs-form/${shipmentId}`,
        payload: {},
        shouldSetUILoading: true,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

function printPalletLabels(orderId) {
  return async (dispatch, getState) => {
    const {
      systemPersist: { machineName },
    } = getState();
    dispatch(setUILoading(true));
    const { data, error } = await authPost([
      `orders/${orderId}/freight/print-pallet-labels`,
      { machineName },
    ]);
    dispatch(setUILoading(false));
    if (error) return { error };
    return { data };
  };
}

function printLabelsByExternalId(orderExternalId) {
  return async (dispatch, getState) => {
    const {
      systemPersist: { machineName },
    } = getState();
    const { data, error } = await authPost([
      `orders/${orderExternalId}/print-labels`,
      { machineName },
    ]);
    if (error) return { error };
    return { data };
  };
}

function voidShipment(orderId, shipmentId, payload = {}, tokenOverride) {
  return async (dispatch, getState) => {
    let data;
    let error;
    const url = `orders/${orderId}/shipment/${shipmentId}/cancel`;

    if (tokenOverride) {
      const { data: responseData, error: errorResponse } = await dispatch(
        customPost({
          url,
          authToken: tokenOverride,
          body: payload,
        }),
      );
      data = responseData;
      error = errorResponse;
    } else {
      const { data: responseData, error: errorResponse } = await authPost(
        url,
        payload,
      );
      data = responseData;
      error = errorResponse;
    }
    if (error) {
      return {
        error,
      };
    }
    dispatch(showNotification({ message: 'Successfully voided the shipment' }));
    return { data };
  };
}

function getOrderAggregatedFeesTotals(orderId) {
  return async (dispatch, getState) => {
    const { data, error } = await authGet(`orders/${orderId}/fees/aggregated`);
    if (error) {
      dispatch(showError({ message: error.message }));
      return {
        error,
      };
    }
    return { data };
  };
}

function getOrderProcessingTimeline(orderId) {
  return async (dispatch, getState) => {
    const { data, error } = await authGet(
      `orders/${orderId}/processing-timeline`,
    );
    if (error) {
      dispatch(showError({ message: error.message }));
      return {
        error,
      };
    }
    return { data };
  };
}

function getOrderTotalProcessingTime(orderId) {
  return async (dispatch, getState) => {
    const { data, error } = await authGet(
      `orders/${orderId}/total-processing-time`,
    );
    if (error) {
      return {
        error,
      };
    }
    return { data };
  };
}

function runOrdersImport(orderIds) {
  return async (dispatch, getState) => {
    const { data, error } = await authPost([
      '/orders/sync-wms-orders',
      { orderIds },
    ]);
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'Something went wrong',
        }),
      );
      return {
        error,
      };
    }
    dispatch(showNotification({ message: 'Order syncing is in progress' }));
    return { data };
  };
}

function cancelOrder({ orderId, token, payload }) {
  return async (dispatch, getState) => {
    if (token) {
      const { error, data } = await dispatch(
        customPost({
          url: `orders/${orderId}/cancel`,
          authToken: token,
          body: payload,
        }),
      );
      if (error) return { error };
      return { data };
    } else {
      const { data, error } = await authPost(
        `orders/${orderId}/cancel`,
        payload,
      );
      if (error) {
        dispatch(showError({ message: error.message }));
        return {
          error,
        };
      }
      return { data };
    }
  };
}

function clearOrderPackages({ orderId, token }) {
  return async (dispatch, getState) => {
    if (token) {
      const { error, data } = await dispatch(
        customPost({
          url: `orders/${orderId}/clear-packages`,
          authToken: token,
        }),
      );
      if (error) {
        dispatch(showError({ message: error.message }));
        return { error };
      }
      dispatch(showNotification({ message: 'Changes saved' }));
      return { data };
    } else {
      const { data, error } = await authPost(
        `orders/${orderId}/clear-packages`,
        {},
      );
      if (error) {
        dispatch(showError({ message: error.message }));
        return {
          error,
        };
      }
      dispatch(showNotification({ message: 'Changes saved' }));
      return { data };
    }
  };
}

function pauseOrder(orderExternalId, payload) {
  return async (dispatch, getState) => {
    const { data, error } = await authPost(
      `orders/${orderExternalId}/pause-order`,
      payload,
    );
    if (error) {
      dispatch(showError({ message: error.message }));
      return {
        error,
      };
    }
    return { data };
  };
}

function getOrderFulfillmentSchedule(params) {
  return async (dispatch, getState) => {
    const { data, error } = await authGet([
      'reports/orders/fulfillment-schedule',
      params,
    ]);
    if (error) {
      dispatch(showError({ message: error.message }));
      return {
        error,
      };
    }
    return { data };
  };
}

function getOrderFulfillmentProgress(params) {
  return async (dispatch, getState) => {
    const { data, error } = await authGet([
      'reports/orders/fulfillment-progress',
      params,
    ]);
    if (error) {
      dispatch(showError({ message: error.message }));
      return {
        error,
      };
    }
    return { data };
  };
}

function getOrderFulfillmentExpressShipmentsProgress(params) {
  return async (dispatch, getState) => {
    const { data, error } = await authGet([
      'reports/orders/fulfillment-express-shipments',
      params,
    ]);
    if (error) {
      dispatch(showError({ message: error.message }));
      return {
        error,
      };
    }
    return { data };
  };
}

function getOrderFulfillmentOpenOrdersPerCustomer(params) {
  return async (dispatch, getState) => {
    const { data, error } = await authGet([
      'reports/orders/fulfillment/open-per-customer',
      params,
    ]);
    if (error) {
      dispatch(showError({ message: error.message }));
      return {
        error,
      };
    }
    return { data };
  };
}

function ensurePropertyArray(property) {
  if (!property) return [];
  return Array.isArray(property) ? property : [property];
}

const processOrderActions = {
  addPhysicalItems,
  addPhysicalItemsWithInventoryTracking,
  updatePhysicalItems,
  updateAndAddPhysicalItems,
  updateAndRemovePhysicalItems,
  updateItemsRemaining,
  updateItemsCompleted,
  removeItemFromPackage,
  removeItemsRemaining,
  removeMaterialItem,
  removePhysicalItems,
  syncItemsLists,
  deletePackage,
  updatePackages,
  setHasUnsealedPackage,
  setHasUnsealedPallet,
  setPackageEditId,
  setPackages,
  setItemsRemaining,
  setItemsCompleted,
  setPhysicalItems,
  getAndUpdateItemPhysicalItems,
};

export {
  getProcessOrder,
  processOrder,
  processFreightOrder,
  getOrderAggregatedFeesTotals,
  getOrderProcessingTimeline,
  getOrderTotalProcessingTime,
  getAndUpdateItemPhysicalItems,
  addToPhysicalItemsState,
  removePhysicalItemsFromPackage,
  printLabels,
  printPalletLabels,
  printOrderCustomsForm,
  printLabelsByExternalId,
  voidShipment,
  runOrdersImport,
  cancelOrder,
  clearOrderPackages,
  processReship,
  processOrderActions,
  updatePackages,
  removeMaterialItem,
  addPhysicalItems,
  addPhysicalItemsWithInventoryTracking,
  updatePhysicalItems,
  updateAndAddPhysicalItems,
  updateAndRemovePhysicalItems,
  removePhysicalItems,
  updateItemsRemaining,
  updateItemsCompleted,
  removeItemFromPackage,
  removeItemsRemaining,
  syncItemsLists,
  deletePackage,
  setHasUnsealedPackage,
  pauseOrder,
  getOrderFulfillmentSchedule,
  getOrderFulfillmentProgress,
  getOrderFulfillmentExpressShipmentsProgress,
  getOrderFulfillmentOpenOrdersPerCustomer,
};

/**
 * @typedef {Object} normalizeReturn
 * @property {object} [data]
 * @property {number} [error]
 */
// #endregion
