import { Fragment, useCallback, useMemo } from 'react';
import { getDimensionsString } from '../../lib';
import { PickNStickIcon, EditIcon, ExpandMoreIcon } from '../../assets';
import {
  useSelector,
  useDispatch,
  orderSelectors,
  setPackageEditId,
} from '../../state';
import {
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import EmptyPackagesSVG from '../../assets/images/empty-packages.svg';
import EmptyPackagesFullPageSVG from '../../assets/images/empty-packages-full-page.svg';
import PickAndStickSVG from '../../assets/images/pick-and-stick.svg';
import PalletEmptyStateSVG from '../../assets/images/pallet-empty-state.svg';
import { useStyles } from './packagesTab.styles';

export function PackagesTab() {
  const packages = useSelector(orderSelectors.packages);
  const completedPackagesCount = useSelector(
    orderSelectors.completedPackagesCount,
  );
  const hasUnsealedPackage = useSelector(orderSelectors.hasUnsealedPackage);
  const hasPallets = useSelector(orderSelectors.hasPallets);
  const isFreight = useSelector(orderSelectors.isFreight);

  return (
    <Fragment>
      {completedPackagesCount > 0 ? (
        <Fragment>
          {hasPallets ? (
            <RenderPallets packages={packages} />
          ) : (
            <RenderPackagesItems
              packages={packages}
              hasUnsealedPackage={hasUnsealedPackage}
            />
          )}
        </Fragment>
      ) : isFreight ? (
        <EmptyPallets />
      ) : (
        <EmptyPackages />
      )}
    </Fragment>
  );
}

function RenderPackagesItems({ packages, hasUnsealedPackage }) {
  const { classes, css, cx } = useStyles();
  const dispatch = useDispatch();
  const canOnlyShip = useSelector(orderSelectors.canOnlyShip);

  const handleEdit = useCallback(
    (packageId) => {
      dispatch(setPackageEditId(packageId));
    },
    [dispatch],
  );

  return (
    <div className={cx(classes.listContainer, css({ padding: 24 }))}>
      {Object.keys(packages).map((k) => {
        const {
          metadata,
          items: packageItems,
          material,
          pickAndStickLengthInches,
          pickAndStickWidthInches,
          pickAndStickHeightInches,
          weightPounds,
        } = packages[k];
        if (!metadata?.isCompleted || !packageItems) return null;
        const packageKeys = Object.keys(packageItems);
        const materialKeys = !!material && Object.keys(material);
        const isPickAndStick = metadata?.isPickAndStick;
        const box = material?.box;
        let dimensions = box?.dimensions;
        if (!box && !isPickAndStick) {
          const itemKey = packageKeys[0];
          dimensions = getDimensionsString({
            width: packageItems[itemKey]?.widthInches,
            length: packageItems[itemKey]?.lengthInches,
            height: packageItems[itemKey]?.heightInches,
          });
        }
        if (isPickAndStick) {
          dimensions = getDimensionsString({
            width: pickAndStickWidthInches,
            length: pickAndStickLengthInches,
            height: pickAndStickHeightInches,
          });
        }

        const weight = weightPounds || metadata?.weightPounds;

        return (
          <div key={k} style={{ marginBottom: 24 }}>
            <div className={classes.headerContainer}>
              <div className={classes.editIconContainer}>
                <div className={classes.subHeader}>{`Package ${k}`}</div>
                {!canOnlyShip && !hasUnsealedPackage && (
                  <EditIcon
                    className={classes.editIcon}
                    onClick={() => handleEdit(k)}
                  />
                )}
              </div>
              <span className={classes.dimensions}>{`${
                weight ? weight + ' lb' : ''
              } ${dimensions || ''}`}</span>
            </div>
            {!!material &&
              materialKeys.map((mKey, index) => {
                if (mKey === 'box') return null;
                const { name, quantity } = material[mKey];
                return (
                  <Fragment key={mKey}>
                    <div>
                      <span className={classes.itemQty}>{quantity}</span>
                      <span className={classes.itemSku}>{name}</span>
                    </div>
                    {index === materialKeys.length - 2 && (
                      <Divider className={classes.itemMaterialDivider} />
                    )}
                  </Fragment>
                );
              })}
            {packageKeys.map((itemKey, index) => {
              const { customerItemID, quantity, sku } = packageItems[itemKey];
              return (
                <Fragment key={customerItemID}>
                  <div className={classes.itemContainer}>
                    <div>
                      <span className={classes.itemQty}>{quantity}</span>
                      <span className={classes.itemSku}>{sku}</span>
                    </div>
                    {isPickAndStick && (
                      <PickNStickIcon
                        className={classes.remainingPickNStickIcon}
                      />
                    )}
                  </div>
                  {index === packageKeys.length - 1 && (
                    <Divider className={classes.itemDivider} />
                  )}
                </Fragment>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

function RenderPallets({ packages }) {
  const { classes } = useStyles();

  return (
    <div className={classes.listContainer}>
      {Object.keys(packages).map((k) => {
        return <RenderPallet key={k} pallet={packages[k]} palletId={k} />;
      })}
    </div>
  );
}

function RenderPallet({ pallet, palletId }) {
  const { classes, css, cx } = useStyles();
  const dispatch = useDispatch();

  const hasUnsealedPallet = useSelector(orderSelectors.hasUnsealedPallet);

  const handleEdit = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(setPackageEditId(palletId));
    },
    [dispatch, palletId],
  );

  const {
    metadata,
    material,
    items: pickAndShipItems,
    pickAndStickLengthInches,
    pickAndStickWidthInches,
    pickAndStickHeightInches,
    weightPounds,
    childPackages,
    lotNumber,
  } = pallet;

  const palletItemsSummary = useMemo(() => {
    if (childPackages) {
      const itemsArray = Object.values(childPackages).flatMap((obj) =>
        obj?.items
          ? Object.values(obj.items).map(({ sku, quantity }) => ({
              sku,
              quantity,
            }))
          : [],
      );

      return itemsArray.reduce((acc, { sku, quantity }) => {
        if (!acc[sku]) {
          acc[sku] = { sku, quantity: 0 };
        }
        acc[sku].quantity += quantity;
        return acc;
      }, {});
    }
  }, [childPackages]);

  if (!metadata?.isCompleted) return null;
  const isPickAndShip = metadata?.isPickAndShip;

  const dimensions = getDimensionsString({
    width: pickAndStickWidthInches,
    length: pickAndStickLengthInches,
    height: pickAndStickHeightInches,
  });

  const weight = weightPounds || metadata?.weightPounds;
  const palletName = material?.pallet?.name;

  if (isPickAndShip && !pickAndShipItems) return null;

  return (
    <Accordion
      elevation={0}
      classes={{ root: classes.accordion }}
      disableGutters
      square='true'
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        classes={{
          root: classes.summary,
          expanded: classes.expanded,
          content: css({ margin: 0 }),
        }}
      >
        <div className={classes.headerContainer}>
          <div className={classes.editIconContainer}>
            <div className={classes.header}>{`Pallet ${palletId}`}</div>
            {!hasUnsealedPallet && (
              <EditIcon className={classes.editIcon} onClick={handleEdit} />
            )}
          </div>
          <div>
            <span className={classes.dimensions}>{`${dimensions} ${
              weight ? weight + ' lbs' : ''
            }`}</span>
            {!isPickAndShip && <PickAndStickSVG style={{ marginLeft: 8 }} />}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ padding: '0px 0px 16px' }}>
        <div style={{ width: '100%' }}>
          <div className={classes.itemSku}>{palletName}</div>
          {lotNumber && <div className={classes.lot}>{lotNumber}</div>}
          {palletItemsSummary && (
            <div>
              <div className={classes.sectionTitle}>Items</div>
              {Object.keys(palletItemsSummary).map((sku) => (
                <div key={sku}>
                  <span className={classes.itemQty}>
                    {palletItemsSummary[sku].quantity}
                  </span>
                  <span className={classes.itemSku}>{sku}</span>
                </div>
              ))}
            </div>
          )}
          {isPickAndShip
            ? Object.keys(pickAndShipItems).map((pi) => {
                const { customerItemID, quantity, sku } = pickAndShipItems[pi];
                return (
                  <Fragment key={customerItemID}>
                    <div className={classes.itemContainer}>
                      <div>
                        <span className={classes.itemQty}>{quantity}</span>
                        <span className={classes.itemSku}>{sku}</span>
                      </div>
                    </div>
                  </Fragment>
                );
              })
            : Object.keys(childPackages).map((cp, index) => {
                const {
                  metadata: childMetadata,
                  items: packageItems,
                  material: packageMaterial,
                } = childPackages[cp];

                if (!packageItems) return null;

                const itemKeys = Object.keys(packageItems);
                const materialKeys =
                  !!packageMaterial && Object.keys(packageMaterial);
                const isPickAndStick = childMetadata?.isPickAndStick;
                const box = packageMaterial?.box;
                const boxDimensions = box?.dimensions;

                return (
                  <div key={cp + 'ch'}>
                    {index === 0 ? (
                      <div
                        className={cx(
                          classes.sectionTitle,
                          css({ marginBottom: 16 }),
                        )}
                      >
                        Packages
                      </div>
                    ) : (
                      <Divider className={classes.packageDivider} />
                    )}
                    <div className={classes.palletPackageHeaderContainer}>
                      <div className={classes.editIconContainer}>
                        <div
                          className={classes.palletPackage}
                        >{`Package ${cp}`}</div>
                        {isPickAndStick && (
                          <PickNStickIcon
                            className={classes.remainingPickNStickIcon}
                            style={{ marginLeft: 8 }}
                          />
                        )}
                      </div>
                    </div>
                    {!!box && (
                      <div style={{ marginTop: -5, marginBottom: 8 }}>
                        <span className={classes.palletPackage}>
                          {box.name}
                        </span>
                        {!!boxDimensions && (
                          <span style={{ marginLeft: 8 }}>{boxDimensions}</span>
                        )}
                      </div>
                    )}
                    {!!packageMaterial &&
                      materialKeys.map((mKey, index) => {
                        // if (mKey === 'box') return null;
                        const { name, quantity } = packageMaterial[mKey];
                        if (mKey === 'box') return null;
                        return (
                          <Fragment key={mKey}>
                            <div>
                              <span className={classes.itemQty}>
                                {quantity}
                              </span>
                              <span className={classes.itemSku}>{name}</span>
                            </div>
                            {index === materialKeys.length - 2 && (
                              <Divider
                                className={classes.itemMaterialDivider}
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    {itemKeys.map((itemKey, index) => {
                      const { customerItemID, quantity, sku } =
                        packageItems[itemKey];
                      return (
                        <Fragment key={customerItemID}>
                          <div className={classes.itemContainer}>
                            <div>
                              <span className={classes.itemQty}>
                                {quantity}
                              </span>
                              <span className={classes.itemSku}>{sku}</span>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                );
              })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export function EmptyPackages({ isFullPage }) {
  const { classes, cx } = useStyles();
  return (
    <div
      className={cx(classes.emptyContainer, {
        [classes.fullPageEmptyContainer]: isFullPage,
      })}
    >
      {isFullPage ? (
        <EmptyPackagesFullPageSVG
          draggable={false}
          style={{ marginBottom: 16 }}
        />
      ) : (
        <EmptyPackagesSVG draggable={false} style={{ marginBottom: 16 }} />
      )}
      <div
        className={cx(classes.emptyText, {
          [classes.emptyTextFullPage]: isFullPage,
        })}
      >
        No packages
      </div>
      <div
        className={cx(classes.emptySubText, {
          [classes.emptySubTextFullPage]: isFullPage,
        })}
      >
        Package info will show up here once the order is prepped
      </div>
    </div>
  );
}

function EmptyPallets() {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.emptyContainer)}>
      <PalletEmptyStateSVG style={{ marginBottom: 16 }} />
      {/* <div className={classes.emptyText}> */}
      <div className={cx(classes.emptyText)}>No pallets</div>
      <div className={cx(classes.emptySubText)}>
        <span>Begin to prep a pallet</span>
      </div>
      {/* </div> */}
    </div>
  );
}
