import React, { memo } from 'react';
import { makeStyles } from 'tss-react/mui';

export const PasswordInfo = memo(() => {
  const { classes } = useStyles();

  return (
    <ul className={classes.list}>
      <li>Passwords must be at least 6 characters.</li>
      <li>Passwords must have at least one digit ('0'-'9').</li>
      <li>Passwords must have at least one uppercase ('A'-'Z').</li>
      <li>Passwords must have at least one non alphanumeric character.</li>
    </ul>
  );
});

const useStyles = makeStyles()((theme) => ({
  list: {
    marginTop: 8,
    marginLeft: 14,
    listStyle: 'disc',
  },
}));
