import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getSignedReadUrl } from '../state';
import { useNotify } from './useNotify';

export function useOpenFile() {
  const dispatch = useDispatch();
  const notify = useNotify();

  return useCallback(
    async (objectName) => {
      const { data } = await dispatch(getSignedReadUrl(objectName));
      if (data) {
        const { signedReadUrl } = data;
        const win = window.open(signedReadUrl, '_blank');
        if (!win) {
          return notify(
            'Unable to open document. Please ensure your pop-up blocker is disabled try again.',
          );
        }
        win.focus();
      }
    },
    [dispatch, notify],
  );
}
