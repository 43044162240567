import React, { useCallback, useState, Fragment } from 'react';
import { object, string } from 'yup';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { SelectInput, RadioInput } from '../inputs';
import {
  useDispatch,
  useSelector,
  shallowEqual,
  updateDrayageJobStatus,
  systemSelectors,
} from '../../../state';
import { useRefresh, useNotify } from '../../../hooks';

const updateModes = {
  DELIVERY_ORDER_LEVEL: 'deliveryOrderLevel',
  JOB_LEVEL: 'jobLevel',
};

const initialState = {
  updateMode: updateModes.JOB_LEVEL,
  newStatus: '',
};

export function DrayageStatusForm({
  open,
  handleClose,
  jobIds, // for batch edit
  jobId, // for single edit
  deliveryOrderNumber, // for DO level edit
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();

  const [submitting, setSubmitting] = useState(false);

  const drayageStatuses = useSelector(
    systemSelectors.drayageLoadStatusTypes,
    shallowEqual,
  );

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const { updateMode, ...rest } = values;
      const payload = { ...rest };
      if (updateMode === updateModes.DELIVERY_ORDER_LEVEL) {
        payload.deliveryOrderNumber = deliveryOrderNumber;
      } else {
        // for batch level
        if (jobIds) {
          payload.drayageJobIDs = jobIds;
        } else {
          payload.drayageJobIDs = [jobId];
        }
      }
      const { data } = await dispatch(
        updateDrayageJobStatus({
          payload,
        }),
      );
      setSubmitting(false);
      if (data) {
        if (
          Array.isArray(data.validationMessages) &&
          data.validationMessages.length
        ) {
          const msgDisplay = data.validationMessages.map((m, i) => (
            <Fragment key={i}>
              <span>{m.message}</span>
              <br />
            </Fragment>
          ));
          notify(msgDisplay, 'error');
        } else {
          notify('Changes saved');
        }
        refresh();
        handleClose();
      }
    },
    [
      deliveryOrderNumber,
      dispatch,
      handleClose,
      jobId,
      jobIds,
      notify,
      refresh,
    ],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Update status</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      <div>
        {/* Only show options if not batch edit */}
        {!jobIds && (
          <RadioInput
            formikProps={formik}
            name='updateMode'
            radiosRow
            size='small'
            title='Update mode'
            titleClassName={classes.labelTitle}
            radios={[
              {
                label: `Job #${jobId}`,
                value: updateModes.JOB_LEVEL,
                labelClassName: classes.radioLabel,
              },
              {
                label: `Delivery order #${deliveryOrderNumber}`,
                value: updateModes.DELIVERY_ORDER_LEVEL,
                labelClassName: classes.radioLabel,
              },
            ]}
          />
        )}
        <SelectInput
          name='newStatus'
          formikProps={formik}
          options={drayageStatuses}
          label='New status'
          className={classes.input}
          required
        />
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
  labelTitle: {
    fontWeight: 'normal',
  },
  radioLabel: {
    fontSize: 12,
  },
}));

const schema = object().shape({
  newStatus: string('Invalid string').nullable().required(),
});
