import { Fragment, useState, useCallback, useRef, useEffect } from 'react';
import {
  Chip,
  MenuList,
  MenuItem,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Typography,
  IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import { FilterMenu } from '../../';
import { Dropdown } from '../../common';
import { useUpdatePageQueryParams, useGetQueryParams } from '../../../hooks';
import { ArrowDropDownIcon, ArrowDropUpIcon, CloseIcon } from '../../../assets';
import { MouseChangeEventType } from '../../../types';
import { stableObject } from '../../../lib';

interface Props {
  label?: string;
  queryParam?: string;
  filters?: Record<string, any>;
  defaultValue?: string;
  filterSortIds?: Array<number>;
  clearable?: boolean;
  className?: string;
}

export const FiltersSelectSingle = ({
  label = 'Filters',
  defaultValue,
  queryParam = 'list-filters',
  filters: _filters,
  filterSortIds,
  className,
  clearable = true,
  ...rest
}: Props) => {
  const { classes, cx } = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { [queryParam]: queryListFilter } = useGetQueryParams();
  const filters = _filters || stableObject;

  const [curFilter, setCurFilter] = useState(
    queryListFilter ?? defaultValue ?? null,
  );
  const [open, setOpen] = useState(false);

  const isFirstRender = useRef(true);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback(
    (event: MouseEvent | TouchEvent | MouseChangeEventType) => {
      if (anchorRef.current?.contains(event.currentTarget as Node)) {
        return;
      }
      setOpen(false);
    },
    [],
  );

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      if (defaultValue) {
        setCurFilter(defaultValue);
      }
      return;
    } else if (queryListFilter !== curFilter) {
      setCurFilter(queryListFilter ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, queryListFilter]);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleSelect = useCallback(
    (filterId, filterName) => {
      const isChecked = curFilter === filterId;
      if (!clearable && isChecked) {
        return;
      }
      const newFilter = isChecked ? null : filterId;
      updatePageQueryParams({
        [queryParam]: newFilter,
      });
      setCurFilter(newFilter);
      setOpen(false);
    },
    [clearable, curFilter, queryParam, updatePageQueryParams],
  );

  const handleClear = useCallback(
    (e) => {
      updatePageQueryParams({
        [queryParam]: null,
      });
      setCurFilter(null);
      setOpen(false);
    },
    [queryParam, updatePageQueryParams],
  );

  const { text, icon } = getLabel({
    labelProp: label,
    selectedFilter: queryListFilter,
    allFilters: filters,
    isOpen: open,
    iconClass: classes.arrowIcon,
  });

  return (
    <Fragment>
      <Chip
        ref={anchorRef}
        clickable
        color={!!curFilter?.length ? 'primary' : 'default'}
        label={text}
        className={classes.chip}
        onDelete={clearable && !!curFilter?.length ? handleClear : undefined}
        icon={icon}
        variant='outlined'
        onClick={handleToggle}
      />
      <Dropdown
        ref={anchorRef}
        open={open}
        handleClose={handleClose}
        popperProps={{ style: { zIndex: 1 }, placement: 'bottom-start' }}
      >
        <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
          <ListItem
            secondaryAction={
              <IconButton onClick={handleClose} size='large'>
                <CloseIcon />
              </IconButton>
            }
          >
            <ListItemText primary={label} />
          </ListItem>
          {(filterSortIds || Object.keys(filters)).map((key) => {
            const value =
              typeof filters[key] === 'object' ? filters[key].id : key;
            const displayVal =
              typeof filters[key] === 'object'
                ? filters[key].name
                : filters[key];

            const selected = curFilter === value;
            return (
              <MenuItem
                key={key}
                onClick={(_) => handleSelect(key, value)}
                dense
              >
                <ListItemIcon>
                  <Checkbox size='small' color='primary' checked={selected} />
                </ListItemIcon>
                <Typography
                  className={cx(classes.text, {
                    [classes.selectedText]: selected,
                  })}
                >
                  {displayVal}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Dropdown>
    </Fragment>
  );
};

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    // So we see the popover when it's empty.
    minWidth: 16,
    minHeight: 16,
    maxWidth: 'calc(100vw - 32px)',
    maxHeight: 'calc(100vh - 32px)',
    width: 300,
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,
  },
  chip: {
    margin: '0 4px 10px 4px',
  },
  text: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 16,
  },
  selectedText: {
    color: '#000000',
  },
  arrowIcon: {
    fontSize: 18,
  },
}));

function getLabel({
  labelProp,
  selectedFilter,
  allFilters,
  isOpen,
  iconClass,
}) {
  let text = '';
  let icon;

  if (selectedFilter) {
    const selectedName =
      typeof allFilters[selectedFilter] === 'object'
        ? allFilters[selectedFilter].name
        : allFilters[selectedFilter];
    text = selectedName;
  } else {
    text = labelProp;
    icon = isOpen ? (
      <ArrowDropDownIcon className={iconClass} />
    ) : (
      <ArrowDropUpIcon className={iconClass} />
    );
  }

  return {
    text,
    icon,
  };
}
