import { memo } from 'react';
import _get from 'lodash.get';
import { Typography, TypographyProps } from '@mui/material';
import { ClearIcon, DoneIcon } from '../../../assets';

import { useStyles } from './fields.styles';
import { AlignType } from '../../../types';
import { stableObject } from '../../../lib';

interface Props {
  typographyProps?: TypographyProps;
  record?: Record<string, any>;
  className?: string;
  headerClassName?: string;
  cellStyle?: React.CSSProperties;
  source: string;
  label: string;
  disablePadding?: string;
  align?: AlignType;
  sortBy?: string;
  sortable?: false;
}

export const BooleanField = memo<Props>(
  ({
    className,
    typographyProps: _typographyProps,
    source,
    record: _record,
    ...rest
  }) => {
    const { classes, cx } = useStyles();
    const record = _record || stableObject;
    const typographyProps = _typographyProps || stableObject;
    const value = _get(record, source);

    return (
      <Typography
        component='span'
        variant='body2'
        className={cx(classes.field, className)}
        {...typographyProps}
      >
        {value ? (
          <DoneIcon className={classes.checkIcon} />
        ) : (
          <ClearIcon className={classes.clearIcon} />
        )}
      </Typography>
    );
  },
);
