import { Card, CardContent, Typography, CardActionArea } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    marginLeft: 10,
    marginRight: 10,
    width: '230px',
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
  },
  btnRoot: {
    padding: '3px 0',
  },
  number: {
    display: 'inline',
    color: '#00C4F8',
    fontSize: '20px',
    fontWeight: '600',
    marginRight: '12px',
  },
  text: {
    display: 'inline',
    color: '#000000',
    fontSize: '14px',
    marginRight: '16px',
  },
});
export function CardButton({
  color,
  title,
  name,
  numberAwaiting,
  onClick,
  selectedCard,
}) {
  const { classes, css, cx } = useStyles();

  return (
    <Card
      className={cx(
        classes.root,
        css(
          selectedCard === name
            ? { border: '1px solid', borderColor: color }
            : {},
        ),
      )}
    >
      <CardActionArea className={classes.btnRoot} onClick={() => onClick(name)}>
        <CardContent>
          {numberAwaiting !== undefined && (
            <Typography className={classes.number} style={{ color }}>
              {numberAwaiting}
            </Typography>
          )}
          <Typography className={classes.text}>{title}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
