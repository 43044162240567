import { Fragment, useCallback, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import {
  generateOrderPayload,
  generateFreightOrderPayload,
} from '../../../pages/processOrder/generateProcessPayload';
import {
  useSelector,
  useDispatch,
  processOrder,
  processFreightOrder,
  orderSelectors,
} from '../../../state';
import { TextInput } from '../../../components';
import { useNotify, useGetUsersData } from '../../../hooks';
import { PanToolOutlinedIcon } from '../../../assets';
import { orderStatuses, generateUrl, paths, Navigation } from '../../../lib';
import { FormModal } from '../..';

const initialState = {
  reason: '',
};

export function PlaceOnHold() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const { isAdmin, isCustomerService, isTruckingSalesPerson } =
    useGetUsersData();
  const hasEditPermission =
    isAdmin || isCustomerService || isTruckingSalesPerson;
  const selectedOrder = useSelector(orderSelectors.selectedOrder);
  const packages = useSelector(orderSelectors.packages);
  const physicalItems = useSelector(orderSelectors.physicalItems);
  const hasUnsealedPackage = useSelector(orderSelectors.hasUnsealedPackage);
  const hasUnsealedPallet = useSelector(orderSelectors.hasUnsealedPallet);
  const hasPallets = useSelector(orderSelectors.hasPallets);
  // const isFullyPacked = useSelector(orderSelectors.isFullyPacked);

  const [showManagerLogin, setShowManagerLogin] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [adminToken, setAdminToken] = useState();
  const [submitting, setSubmitting] = useState(false);

  const handleShowModal = useCallback(() => {
    if (hasEditPermission) {
      setShowConfirmation(true);
    } else {
      setShowManagerLogin(true);
    }
  }, [hasEditPermission]);

  const handlePlaceOnHold = useCallback(
    async (values) => {
      setSubmitting(true);
      // const newStatus = isFullyPacked
      //   ? orderStatuses.PACKED_HOLD
      //   : orderStatuses.HOLD;
      const payload = selectedOrder.isFreight
        ? generateFreightOrderPayload({
            packages,
            allPhysicalItems: physicalItems,
            orderData: selectedOrder,
            status: orderStatuses.HOLD,
            isFreightPalletMode: hasPallets,
          })
        : generateOrderPayload({
            packages,
            allPhysicalItems: physicalItems,
            orderData: selectedOrder,
            status: orderStatuses.HOLD,
          });

      payload.note = values.reason;

      if (selectedOrder.isFreight) {
        payload.freightContainerType = hasPallets ? 'Pallet' : 'Box';
      }

      const { error } = selectedOrder.isFreight
        ? await dispatch(processFreightOrder({ payload, token: adminToken }))
        : await dispatch(processOrder({ payload, token: adminToken }));
      setSubmitting(false);
      if (error) {
        return notify(
          error.message || error.title || 'Something went wrong',
          'error',
        );
      }
      notify('Order successfully placed on hold');
      Navigation.redirect(generateUrl(paths.SCAN_ORDER));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      adminToken,
      hasPallets,
      // isFullyPacked,
      packages,
      physicalItems,
      selectedOrder,
    ],
  );

  const onAdminLogin = useCallback((data) => {
    setShowManagerLogin(false);
    setShowConfirmation(true);
    setAdminToken(data);
  }, []);

  const handleCancel = useCallback(() => {
    setShowConfirmation(false);
    setShowManagerLogin(false);
  }, []);

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handlePlaceOnHold,
    validationSchema: schema,
  });

  return (
    <Fragment>
      <Tooltip title='Place on hold' placement='bottom'>
        <Button
          color='secondary'
          className={classes.actionButton}
          size='small'
          onClick={handleShowModal}
          disabled={hasUnsealedPackage || hasUnsealedPallet}
        >
          <PanToolOutlinedIcon fontSize='small' />
        </Button>
      </Tooltip>
      <FormModal
        open={showConfirmation || showManagerLogin}
        handleClose={handleCancel}
        title={
          showManagerLogin
            ? 'Manager login required to complete edits'
            : 'Are you sure you want to place this order on hold?'
        }
        callback={formik.handleSubmit}
        btnText='SAVE'
        paperProps={{ style: { width: 468 } }}
        showManagerLogin={showManagerLogin}
        onAdminLogin={onAdminLogin}
        submitting={submitting}
        typeSubmit
      >
        <TextInput
          name='reason'
          formikProps={formik}
          label='Reason'
          className={classes.input}
          multiline
          rows={3}
          required
        />
      </FormModal>
    </Fragment>
  );
}

const useStyles = makeStyles({ name: 'poh' })((theme) => ({
  actionButton: {
    width: 32,
    height: 32,
    minWidth: 'unset',
    backgroundColor: '#F5F6FE',
  },
}));

const schema = object().shape({
  reason: string().required('Required'),
});
