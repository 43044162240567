import { useCallback, useState, useEffect } from 'react';
import { object, string } from 'yup';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput, SelectInput, CheckboxInput } from '../inputs';
import {
  useDispatch,
  addResource,
  deleteResource,
  fetchResourceById,
} from '../../../state';
import { useRefresh, useGetUsersData } from '../../../hooks';
import { convertNullFieldsToEmptyString } from '../../../lib';

const initialState = {
  type: '',
  name: '',
  amount: '',
  customerFeeID: '',
  quantity: 1,
  billableToCustomer: true,
  note: '',
};

export function TruckingJobFeeForm({
  open,
  handleClose,
  truckingJobId,
  truckingJobFeeId,
  customerId,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const { isCustomer } = useGetUsersData();

  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);
  const [specialMaterialOptions, setSpecialMaterialOptions] = useState([]);
  const [specialHandlingOptions, setSpecialHandlingOptions] = useState([]);
  const [specialShipmentOptions, setSpecialShipmentOptions] = useState([]);
  const [specialAdjustmentOptions, setSpecialAdjustmentOptions] = useState([]);
  const [customerFeesMapping, setCustomerFeesMapping] = useState({});

  useEffect(() => {
    (async function () {
      if (truckingJobFeeId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/shippingJobs',
            id: truckingJobId,
            path: `fees/${truckingJobFeeId}`,
          }),
        );
        if (data) {
          const {
            customerFeeID,
            discountedRates,
            feeTypeDisplay,
            max,
            min,
            percentage,
            ...rest
          } = data;

          if (data.type) {
            // if a custom fee was generated somewhere else (ie refresh order fee), make sure it's in the list
            const specialFeeTypesOptions = customFeeTypes.map((t) => t.id);
            if (!specialFeeTypesOptions.includes(data.type)) {
              customFeeTypes.push({ id: data.type, name: feeTypeDisplay });
            }
          }

          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString(rest),
          }));
        }
      } else if (customerId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/customers',
            id: customerId,
            path: `fees/special-fees/basic`,
          }),
        );
        if (data) {
          let idAmountMapping = {};
          Object.keys(data).forEach((d) => {
            const fees = data[d];

            const mappings = fees.reduce((acc, cur) => {
              acc[cur.id.toString()] = cur;
              return acc;
            }, {});

            idAmountMapping = { ...idAmountMapping, ...mappings };
            switch (d) {
              case 'SpecialMaterialFee':
                setSpecialMaterialOptions(fees);
                break;
              case 'SpecialHandlingFee':
                setSpecialHandlingOptions(fees);
                break;
              case 'SpecialShipmentFee':
                setSpecialShipmentOptions(fees);
                break;
              case 'AdjustmentFee':
                setSpecialAdjustmentOptions(fees);
                break;
              default:
                break;
            }
          });

          setCustomerFeesMapping(idAmountMapping);
        }
      }
    })();
  }, [truckingJobId, dispatch, truckingJobFeeId, customerId]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = {
        ...values,
        id: values.id || null,
      };
      const { error } = await dispatch(
        addResource({
          baseUrl: `shippingJobs/${truckingJobId}/fees`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [dispatch, truckingJobId, refresh, handleClose],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/shippingJobs',
        id: truckingJobId,
        path: `fees/${truckingJobFeeId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
  }, [truckingJobId, dispatch, truckingJobFeeId, handleClose, refresh]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  useEffect(() => {
    if (!truckingJobFeeId) {
      formik.setFieldValue('customerFeeID', '');
      formik.setFieldValue('amount', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.type]);

  const handleTypeChange = useCallback(
    async (e) => {
      const { name, value } = e.target;
      formik.setFieldValue(name, value);
      const foundMapping = customerFeesMapping[value + ''];
      if (foundMapping) {
        formik.setFieldValue('amount', foundMapping.amount ?? 0);
        formik.setFieldValue('name', foundMapping.name ?? 0);
      }
    },
    [customerFeesMapping, formik],
  );

  const { handleChange, ...formikProps } = formik;

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          truckingJobFeeId ? 'Edit' : 'Add'
        } special fee`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={!!truckingJobFeeId}
      handleDelete={handleDelete}
    >
      <SelectInput
        name='type'
        formikProps={formik}
        label='Fee type'
        className={classes.input}
        options={customFeeTypes}
        disabled={!!truckingJobFeeId}
      />
      {!!specialMaterialOptions.length &&
        formik.values.type === 'SpecialMaterialFee' && (
          <SelectInput
            name='customerFeeID'
            formikProps={formikProps}
            onChange={handleTypeChange}
            label='Select fee'
            className={classes.input}
            options={specialMaterialOptions}
            clearable
          />
        )}
      {!!specialHandlingOptions.length &&
        formik.values.type === 'SpecialHandlingFee' && (
          <SelectInput
            name='customerFeeID'
            formikProps={formikProps}
            onChange={handleTypeChange}
            label='Select fee'
            className={classes.input}
            options={specialHandlingOptions}
            clearable
          />
        )}
      {!!specialShipmentOptions.length &&
        formik.values.type === 'SpecialShipmentFee' && (
          <SelectInput
            name='customerFeeID'
            formikProps={formikProps}
            onChange={handleTypeChange}
            label='Select fee'
            className={classes.input}
            options={specialShipmentOptions}
            clearable
          />
        )}
      {!!specialAdjustmentOptions.length &&
        formik.values.type === 'AdjustmentFee' && (
          <SelectInput
            name='customerFeeID'
            formikProps={formikProps}
            onChange={handleTypeChange}
            label='Select fee'
            className={classes.input}
            options={specialAdjustmentOptions}
            clearable
          />
        )}
      {!formik.values.customerFeeID && (
        <TextInput
          name='name'
          formikProps={formik}
          label='Fee name'
          className={classes.input}
        />
      )}
      <TextInput
        name='amount'
        formikProps={formik}
        label='Rate'
        className={classes.input}
        format='currency'
      />
      <TextInput
        name='quantity'
        formikProps={formik}
        label='Quantity'
        className={classes.input}
        type='number'
        slotProps={{ htmlInput: { min: 1 } }}
      />
      <TextInput
        name='note'
        label='Notes'
        formikProps={formik}
        className={classes.input}
        multiline
        rows={3}
      />
      {!isCustomer && (
        <CheckboxInput
          formikProps={formik}
          checkboxes={[
            {
              labelProps: {
                label: 'Billable to customer',
              },
              checkboxProps: {
                name: 'billableToCustomer',
              },
            },
          ]}
        />
      )}
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));

const schema = object().shape({
  quantity: string().required('Required'),
});

const customFeeTypes = [
  { id: 'SpecialHandlingFee', name: 'Special Handling Fee' },
  { id: 'SpecialMaterialFee', name: 'Special Material Fee' },
  { id: 'SpecialShipmentFee', name: 'Special Shipment Fee' },
  // { id: 'AdjustmentFee', name: 'Adjustment Fee' },
];
