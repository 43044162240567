import { useCallback } from 'react';
import qs from 'query-string';
import { NavigateOptions, useNavigate } from 'react-router';

/**
 * Set to true if navigating from one `List` component to another to remove the old pagination.
 */
type removeListsParams = boolean | undefined;

export function useUpdatePageQueryParams(options?: NavigateOptions) {
  const navigate = useNavigate();
  return useCallback(
    (
      newQueryParams: Record<string, any> = {},
      removeListsParams: removeListsParams = false,
    ) => {
      const { pathname, search } = window.location;
      let currentParams = qs.parse(search);
      if (removeListsParams) {
        const { rowsPerPage, page, orderBy, order, ...otherParams } =
          currentParams;
        currentParams = otherParams;
      }
      const newParams = { ...currentParams, ...newQueryParams };
      const query = Object.keys(newParams).length
        ? `?${qs.stringify(newParams, { skipNull: true })}`
        : '';

      navigate(`${pathname}${query}`, options);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
