import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { FormikDateInput, FormikTimeInput, RadioInput } from '../inputs';
import { useDispatch, addResource } from '../../../state';

interface Props {
  handleClose: () => any;
  open: boolean;
  warehouseId: number;
}

enum ActionType {
  Unavailable = 'Unavailable',
  Available = 'Available',
}

interface State {
  date: Date | null;
  startTime: string;
  endTime: string;
  action: ActionType;
}

const initialValues: State = {
  date: null,
  startTime: '',
  endTime: '',
  action: ActionType.Unavailable,
};

export function WarehouseSlotAvailabilityForm({
  handleClose,
  open,
  warehouseId,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (values) => {
      const payload = {
        warehouseID: warehouseId,
        ...values,
      };
      const { error } = await dispatch(
        addResource({
          baseUrl: `/warehouses/${warehouseId}/appointments/appointments-slots/manage-availability`,
          payload,
          shouldSetUILoading: false,
          message: 'Changes saved',
          shouldShowErrorMessage: true,
        }),
      );
      if (!error) {
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [warehouseId],
  );

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>
          Manage appointment slots availability
        </span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
    >
      <FormikDateInput
        name='date'
        formikProps={formik}
        label='Date'
        className={classes.input}
      />
      <FormikTimeInput
        name='startTime'
        formikProps={formik}
        label='Start time'
        className={classes.input}
      />
      <FormikTimeInput
        name='endTime'
        formikProps={formik}
        label='End time'
        className={classes.input}
      />
      <div>
        <RadioInput
          formikProps={formik}
          name='action'
          radiosRow
          title='Set as'
          size='small'
          radios={[
            { label: 'Unavailable', value: ActionType.Unavailable },
            {
              label: 'Available',
              value: ActionType.Available,
            },
          ]}
        />
      </div>
    </FormModal>
  );
}

const schema = object().shape({
  date: string().required('Required'),
  startTime: string().required('Required'),
  endTime: string().required('Required'),
  action: string().required('Required'),
});

const useStyles = makeStyles({ name: { WarehouseSlotAvailabilityForm } })(
  (theme) => ({
    title: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 600,
    },
    input: {
      marginBottom: 16,
      width: 300,
    },
  }),
);
