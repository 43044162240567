import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import { useDispatch, addResource } from '../../../state';

const initialState = {
  shouldBillCustomer: true,
  reason: '',
};

export function ReopenOrderForm({ open, handleClose, orderId, externalId }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      // const payload = {
      //   ...values,
      //   customerID: orderId,
      // };
      const { error } = await dispatch(
        addResource({
          baseUrl: `/orders/${orderId}/reopen-wms-order`,
          payload: { reason: values.reason },
          message: 'Successfully scheduled reopening order',
        }),
      );
      setSubmitting(false);
      if (!error) {
        handleClose();
      }
    },
    [orderId, dispatch, handleClose],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`Reopen order ${externalId}?`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      <TextInput
        name='reason'
        formikProps={formik}
        label='Reason'
        multiline
        rows={3}
        required
      />
      {/* <div>
        <CheckboxInput
          formikProps={formik}
          checkboxes={[
            {
              labelProps: {
                label: 'Charge to customer',
              },
              checkboxProps: {
                name: 'shouldBillCustomer',
              },
            },
          ]}
        />
      </div> */}
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { ReopenOrderForm } })((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
}));

const schema = object().shape({
  reason: string().required('Required'),
  // shouldBillCustomer: bool().typeError('Invalid value').nullable(),
});
