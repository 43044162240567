import { createSlice } from '@reduxjs/toolkit';
import { SelectOption } from '../../types/globalTypes';

interface SystemPersistState {
  systemSettings: { [key: string]: Array<SelectOption> | Record<string, any> };
  countryUsId: number | null;
  internationalShippingTypes: { [key: string]: Array<SelectOption> };
  machineName?: string | null;
}

const initialState: SystemPersistState = {
  systemSettings: {},
  countryUsId: null,
  internationalShippingTypes: {},
  machineName: null,
};

const systemPersist = createSlice({
  name: 'systemPersist',
  initialState,
  reducers: {
    setSystemSettings(state, action) {
      state.systemSettings = action.payload;
    },
    setInternationalShippingTypes(state, action) {
      state.internationalShippingTypes = action.payload;
    },
    setMachineName(state, action) {
      state.machineName = action.payload;
    },
    setCountryUsId(state, action) {
      state.countryUsId = action.payload;
    },
  },
});

export const {
  setSystemSettings,
  setInternationalShippingTypes,
  setMachineName,
  setCountryUsId,
} = systemPersist.actions;

export const systemPersistReducer = systemPersist.reducer;
