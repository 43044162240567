import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { object, date } from 'yup';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { FormikDateInput } from '../inputs';
import { addResource } from '../../../state';
// import { useRefresh, useNotify } from '../../../hooks';

const initialState = {
  fromDate: null,
};

export function SyncCustomerItemsForm({ open, handleClose, customerId }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = {
        ...values,
        customerID: customerId,
      };
      const { error } = await dispatch(
        addResource({
          baseUrl: '/customers/sync-wms-customer-items',
          payload,
          message: 'Items syncing is in progress',
        }),
      );
      setSubmitting(false);
      if (!error) {
        handleClose();
      }
    },
    [customerId, dispatch, handleClose],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Sync customer items</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      <FormikDateInput
        name='fromDate'
        formikProps={formik}
        label='From date'
        className={classes.input}
      />
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));

const schema = object().shape({
  fromDate: date().typeError('Invalid Date').nullable().required('Required'),
});
