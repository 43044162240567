import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({
  name: 'scanOrder',
})((theme) => ({
  content: {
    flex: 1,
  },
  card: {
    maxWidth: 828,
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: '31px 40px',
    [theme.breakpoints.down('tablet')]: {
      padding: '0px 16px',
    },
  },
  input: {
    width: '100%',
    // marginRight: 24,
  },
  tab: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'capitalize',
    padding: '5px 10px',
    color: '#000000',
    border: '1px solid #D5D8EC',
  },
  activeTab: {
    color: '#00C4F8',
    border: '1px solid #00C4F8',
  },
}));
