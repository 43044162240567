import { isBefore, addHours, parseISO } from 'date-fns';
import { authDelete, authGet } from '../../lib';
import { showError, getWarehouses, getTerminals } from '../features';
import type { IdNamePair } from '../../types/globalTypes';
import type {
  WarehouseAppointmentSettingsAPI,
  WarehouseClosedDay,
  WarehouseDetailsAPI,
  WarehouseLeadTimesSettingsAPI,
} from '../../types/warehouseTypes';
import type { NormalizeReturn, AppThunk } from '../types';
import { addResource, updateResource } from './resource';

export function getBasicWarehouses(
  ignoreCache = false,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch, getState) => {
    const { warehouses: { basicWarehouses, lastFetchedWarehousesOn } = {} } =
      getState();
    if (
      !ignoreCache &&
      basicWarehouses?.length &&
      !!lastFetchedWarehousesOn &&
      isBefore(new Date(), addHours(parseISO(lastFetchedWarehousesOn), 1))
    ) {
      return {
        data: basicWarehouses,
      };
    }
    const { data, error } =
      await authGet<Array<IdNamePair>>('/warehouses/basic');
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    dispatch(getWarehouses(data));
    return { data };
  };
}

export function getBasicTerminals(
  ignoreCache = false,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch, getState) => {
    const { warehouses: { basicTerminals, lastFetchedTerminalsOn } = {} } =
      getState();
    if (
      !ignoreCache &&
      basicTerminals?.length &&
      !!lastFetchedTerminalsOn &&
      isBefore(new Date(), addHours(parseISO(lastFetchedTerminalsOn), 1))
    ) {
      return {
        data: basicTerminals,
      };
    }
    const { data, error } = await authGet<Array<IdNamePair>>(
      '/system/terminals/basic',
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    dispatch(getTerminals(data));
    return { data };
  };
}

export function getWarehouseById(
  warehouseId: string | number,
): AppThunk<Promise<NormalizeReturn<WarehouseDetailsAPI>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<WarehouseDetailsAPI>(
      `/warehouses/${warehouseId}`,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function getWarehouseLeadTimesSettings(
  warehouseId: string | number,
): AppThunk<Promise<NormalizeReturn<WarehouseLeadTimesSettingsAPI>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<WarehouseLeadTimesSettingsAPI>(
      `/warehouses/${warehouseId}/lead-time-settings`,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function updateWarehouseLeadTimeSettings(
  warehouseId: string | number,
  payload: WarehouseLeadTimesSettingsAPI,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      updateResource<
        WarehouseLeadTimesSettingsAPI,
        WarehouseLeadTimesSettingsAPI
      >({
        baseUrl: `/warehouses/${warehouseId}/lead-time-settings`,
        payload,
        shouldSetUILoading: true,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function getWarehouseClosedDays(
  warehouseId: number | string,
  month?: number,
  signal?: AbortSignal,
): AppThunk<Promise<NormalizeReturn<WarehouseClosedDay>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<WarehouseClosedDay>(
      [`/warehouses/${warehouseId}/closed-days`, { month }],
      undefined,
      undefined,
      { signal },
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function getWarehouseClosedDay(
  warehouseId: number | string,
  closedDayId: number | string,
): AppThunk<Promise<NormalizeReturn<WarehouseClosedDay>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<WarehouseClosedDay>(
      `/warehouses/${warehouseId}/closed-days/${closedDayId}`,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function deleteWarehouseClosedDay(
  warehouseId: number | string,
  closedDayId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authDelete(
      `/warehouses/${warehouseId}/closed-days/${closedDayId}`,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function upsertWarehouseClosedDay(
  warehouseId: number | string,
  payload: WarehouseClosedDay,
): AppThunk<Promise<NormalizeReturn<WarehouseClosedDay>>> {
  return async (dispatch) => {
    const { error, data } = await dispatch(
      addResource<WarehouseClosedDay>({
        baseUrl: `/warehouses/${warehouseId}/closed-days`,
        payload,
        shouldSetUILoading: false,
        shouldShowErrorMessage: true,
      }),
    );
    if (error) {
      return { error };
    }

    return { data };
  };
}

export function updateWarehouseAppointmentSettings(
  warehouseId: number | string,
  payload: WarehouseAppointmentSettingsAPI,
): AppThunk<Promise<NormalizeReturn<WarehouseAppointmentSettingsAPI>>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource<
        WarehouseAppointmentSettingsAPI,
        WarehouseAppointmentSettingsAPI
      >({
        baseUrl: `/warehouses/${warehouseId}/appointment-settings`,
        payload,
        shouldSetUILoading: true,
        message: 'Settings updated',
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function getWarehouseAppointmentSettings(
  warehouseId: number | string,
): AppThunk<Promise<NormalizeReturn<WarehouseAppointmentSettingsAPI>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<WarehouseAppointmentSettingsAPI>(
      `/warehouses/${warehouseId}/appointment-settings`,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function getBasicVehiclesByWarehouseId(
  warehouseId: string | number,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<Array<IdNamePair>>(
      `/warehouses/${warehouseId}/assets/basic?type=1`,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}
