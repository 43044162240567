import { memo, useCallback } from 'react';
import _get from 'lodash.get';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  jsDateToLocalISO8601DateString,
  getDateFromDateString,
  convertDateToTimezone,
  getLocalTimeZoneId,
} from '../../../lib';
import { useStyles } from './inputs.styles';

export const FormikDateInput = memo(
  /**
   * A material-ui date picker using formik
   * @param {import("@mui/x-date-pickers/DatePicker").DatePickerProps & otherProps} props
   * @returns
   */
  function ({
    name,
    label,
    type = 'text',
    formikProps,
    handleChange,
    handleBlur,
    className,
    format,
    required,
    validationKey,
    textFieldProps: _textFieldProps,
    ...otherProps
  }) {
    const { classes, cx } = useStyles();
    const {
      handleChange: formikHandleChange,
      handleBlur: formikHandleBlur,
      values,
      touched,
      errors,
    } = formikProps;

    const textFieldProps = _textFieldProps || {};

    const onBlur = useCallback(
      (e) => {
        formikHandleBlur(e);
        handleBlur && handleBlur(e);
      },
      [formikHandleBlur, handleBlur],
    );

    const onChange = useCallback(
      (date) => {
        const value = date ? jsDateToLocalISO8601DateString(date) : null;
        const event = { target: { name, value } };
        formikHandleChange && formikHandleChange(event);
        handleChange && handleChange(event);
      },
      [formikHandleChange, handleChange, name],
    );

    // On blur touched is set on the name but on submit it's set on the nested object so we need to check both
    const isTouched = !!(_get(touched, name) || _get(touched, validationKey));
    const errorText = _get(errors, validationKey ?? name);

    return (
      <DatePicker
        value={toLocalDate(values[name]) ?? null}
        format='MM/dd/yyyy'
        onChange={onChange}
        slotProps={{
          textField: {
            name,
            variant: 'standard',
            onBlur,
            error: isTouched && !!errorText,
            helperText: isTouched ? errorText : undefined,
            className: cx(classes.inputRoot, className),
            InputLabelProps: { className: classes.label },
            type,
            label,
            required,
            ...textFieldProps,
          },
        }}
        {...otherProps}
      />
    );
  },
);

function toLocalDate(s) {
  if (!s) return null;
  if (s instanceof Date)
    return convertDateToTimezone({ date: s, timezone: getLocalTimeZoneId() });
  return getDateFromDateString({ dateStr: s });
}
