import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showNotification } from '../state/features';

export function useNotify() {
  const dispatch = useDispatch();
  const cb = useCallback(
    (
      message: string,
      variant: 'success' | 'error' | 'warning' | 'info' = 'success',
      duration?: number,
    ) => {
      dispatch(
        showNotification({
          message,
          variant,
          duration,
        }),
      );
    },
    [dispatch],
  );
  return cb;
}
