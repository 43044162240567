import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({
  name: 'inputs',
})((theme) => ({
  searchRoot: {
    width: 300,
  },
  searchIcon: {
    color: theme.palette.primary.main,
    fontSize: 18,
  },
  inputRoot: {
    width: '100%',
  },
  select: {
    width: 300,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 16,
  },
  radioLabel: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
    '&.Mui-focused': {
      color: '#000000',
    },
  },
  focused: {},
  selectClearIcon: {
    fontSize: 18,
    marginRight: 22,
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    height: 22,
  },
  placeholder: {
    color: '#888EA7',
    fontFamily: 'Lato',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '16px',
  },
}));
