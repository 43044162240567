import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Resource } from '../types';

type ResourceKeyValue = { [key: string]: Resource };

interface ListState {
  resources: ResourceKeyValue;
}

const initialState: ListState = { resources: {} };

const lists = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    registerLists(state, action: PayloadAction<ResourceKeyValue>) {
      state.resources = { ...action.payload };
    },
    populateList(state, action) {
      const {
        list,
        data: { params, /* data,*/ ...rest },
      } = action.payload;
      state.resources[list] = {
        ...rest,
        // data: { ...state.resources[list].data, ...data },
        params: { ...state.resources[list].params, ...params },
      };
    },
    updateListParams(state, action) {
      const { list, params } = action.payload;
      state.resources[list] = {
        ...state.resources[list],
        params: { ...state.resources[list].params, ...params },
      };
    },
  },
});

export const { registerLists, populateList, updateListParams } = lists.actions;

export const listsReducer = lists.reducer;
