import { formatISO } from 'date-fns';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ScanReceiptItemResponseType } from '../../types/receiptTypes';
import { ItemUnitOfMeasureType } from '../../types/customerTypes';

interface ReceiptState {
  scannedReceiptItemMapping: Record<string, ScanReceiptItemResponseType>;
  scannedReceiptSkuMapping: Record<
    string,
    Record<ItemUnitOfMeasureType, ScanReceiptItemResponseType>
  >;
  lastFetchedScannedReceiptItemMappingOn: string | null;
}

type CachedType = 'scannedItemsMapping';

interface ScannedItemMappingPayload {
  scannedItem: string;
  results: ScanReceiptItemResponseType;
}

const initialState: ReceiptState = {
  scannedReceiptItemMapping: {},
  scannedReceiptSkuMapping: {},
  lastFetchedScannedReceiptItemMappingOn: null,
};

const receipts = createSlice({
  name: 'receipts',
  initialState,
  reducers: {
    setScannedItemMapping: (
      state,
      action: PayloadAction<ScannedItemMappingPayload>,
    ) => {
      const scannedItem = action.payload.scannedItem;
      const results = action.payload.results;

      if (results.scanType !== 'Sku') {
        state.scannedReceiptItemMapping[scannedItem] = results;
      }
      if (results.scanType === 'Sku' && results.unitOfMeasureType) {
        state.scannedReceiptSkuMapping[scannedItem] =
          state.scannedReceiptSkuMapping[scannedItem] || {};
        state.scannedReceiptSkuMapping[scannedItem][results.unitOfMeasureType] =
          results;
      }
      if (!state.lastFetchedScannedReceiptItemMappingOn) {
        state.lastFetchedScannedReceiptItemMappingOn = formatISO(new Date());
      }
    },
    invalidateReceiptCache: (state, action: PayloadAction<CachedType>) => {
      switch (action.payload) {
        case 'scannedItemsMapping':
          state.lastFetchedScannedReceiptItemMappingOn = null;
          state.scannedReceiptItemMapping = {};
          state.scannedReceiptSkuMapping = {};
          break;
      }
    },
  },
});

export const { setScannedItemMapping, invalidateReceiptCache } =
  receipts.actions;

export const ReceiptsReducer = receipts.reducer;
