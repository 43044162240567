import { useState, Fragment, useCallback, ReactElement, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogProps,
  PaperProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Loader } from '../../ui';
import { DeleteConfirmation } from './DeleteConfirmation';
import { ManagerLogin } from '../ManagerLogin';
// import { useStyles } from './modals.styles';

interface Props extends Omit<DialogProps, 'title' | 'PaperProps'> {
  title?: string | ReactElement<any>;
  managerLoginTitle?: string;
  handleClose: (
    event?: object,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => any;
  disableCancel?: boolean;
  disableSubmit?: boolean;
  callback: (e?: any) => any;
  cancelBtnText?: string;
  btnText?: string;
  children?: React.ReactNode;
  hasCustomActionBtn?: boolean;
  hideDeleteBtn?: boolean;
  handleDelete?: () => any;
  submitting?: boolean;
  typeSubmit?: boolean;
  isEdit?: boolean;
  showManagerLogin?: boolean;
  onAdminLogin?: (token: string) => any;
  disableScrollLock?: boolean;
  paperProps?: Partial<PaperProps<React.ElementType>>;
}

export function FormModal({
  open,
  title,
  managerLoginTitle = 'Manager login required to complete edits',
  handleClose: _handleClose,
  disableCancel = false,
  disableSubmit = false,
  callback,
  cancelBtnText = 'Cancel',
  btnText = 'Ok',
  children,
  hasCustomActionBtn = false,
  paperProps: _paperProps,
  maxWidth,
  hideDeleteBtn = false,
  handleDelete = () => {},
  submitting = false,
  typeSubmit,
  isEdit = false,
  showManagerLogin = false,
  onAdminLogin,
  disableScrollLock = true,
  ...rest
}: Props) {
  const { classes } = useStyles();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleClose = useCallback(
    (event?: object, reason?: 'backdropClick' | 'escapeKeyDown') => {
      if (reason !== 'backdropClick') {
        _handleClose(event, reason);
      }
    },
    [_handleClose],
  );
  const paperProps = useMemo<
    Partial<PaperProps<React.ElementType>> | undefined
  >(() => {
    return typeSubmit && !showDeleteConfirm && !showManagerLogin
      ? {
          ...(_paperProps || {}),
          component: 'form',
          onSubmit: callback,
          noValidate: true,
          autoComplete: 'off',
        }
      : _paperProps;
  }, [_paperProps, callback, showDeleteConfirm, showManagerLogin, typeSubmit]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
      PaperProps={paperProps}
      disableScrollLock={disableScrollLock}
      {...rest}
    >
      {showDeleteConfirm ? (
        <DeleteConfirmation
          handleClose={() => setShowDeleteConfirm(false)}
          title={title}
          handleDelete={handleDelete}
          open={showDeleteConfirm}
          text='Are you sure you want to delete?'
        />
      ) : showManagerLogin ? (
        <Fragment>
          <DialogTitle>{managerLoginTitle}</DialogTitle>
          <ManagerLogin handleNext={onAdminLogin} handleCancel={handleClose} />
        </Fragment>
      ) : (
        <Fragment>
          {title && <DialogTitle>{title}</DialogTitle>}
          <DialogContent>
            {children}
            {submitting && <Loader />}
          </DialogContent>
          {!hasCustomActionBtn && (
            <DialogActions>
              <div style={{ marginRight: 'auto' }}>
                {isEdit && !hideDeleteBtn && (
                  <Button
                    className={classes.deleteBtn}
                    onClick={() => setShowDeleteConfirm(true)}
                    disabled={submitting}
                  >
                    Delete
                  </Button>
                )}
              </div>
              {!disableCancel && (
                <Button color='primary' onClick={handleClose}>
                  {cancelBtnText}
                </Button>
              )}
              {typeSubmit ? (
                <Button
                  type='submit'
                  color='primary'
                  disabled={submitting || disableSubmit}
                >
                  {btnText}
                </Button>
              ) : (
                <Button
                  onClick={callback}
                  color='primary'
                  disabled={submitting || disableSubmit}
                >
                  {btnText}
                </Button>
              )}
            </DialogActions>
          )}
        </Fragment>
      )}
    </Dialog>
  );
}

const useStyles = makeStyles()((theme) => ({
  deleteBtn: {
    color: '#A5AABD',
    fontSize: 14,
    fontWeight: 600,
  },
}));
