import { forwardRef, useMemo } from 'react';
import {
  NumericFormat,
  PatternFormat,
  NumericFormatProps,
  PatternFormatProps,
} from 'react-number-format';

type FormatType = 'phone' | 'ssn' | 'currency';

interface Props {
  name: string;
  format: FormatType;
  value: string | number;
  onChange: (event: any) => void;
}

export const NumberFormatter = forwardRef(function NumberFormatter(
  props: Props,
  ref: ((el: HTMLInputElement) => void) | React.Ref<any> | undefined,
) {
  const { onChange, format, value, ...other } = props;

  const formattedValue = useMemo(() => {
    const stringValue = value?.toString();
    return stringValue && format === 'phone' && stringValue.startsWith('1')
      ? stringValue.substring(1)
      : value;
  }, [format, value]);

  const configs = formatMap[format];

  if (!configs) {
    return null;
  }

  const { component, formatConfig } = configs;

  if (component === 'NumericFormat') {
    return (
      <NumericFormat
        {...other}
        value={value}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        valueIsNumericString
        {...formatConfig}
      />
    );
  }

  if (component === 'PatternFormat') {
    return (
      <PatternFormat
        {...other}
        value={formattedValue}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        valueIsNumericString
        {...formatConfig}
      />
    );
  }
});

const numericFormatFields = {
  currency: {
    prefix: '$',
    thousandSeparator: true,
  },
} satisfies Partial<Record<FormatType, NumericFormatProps>>;

const patternFormatFields = {
  phone: {
    format: '(###) ###-####',
    // mask: '_',
    // allowEmptyFormatting: true,
  },
  ssn: {
    format: '###-##-####',
    // mask: '_',
    // allowEmptyFormatting: true,
  },
} satisfies Partial<Record<FormatType, PatternFormatProps>>;

const formatMap = {
  currency: {
    formatConfig: numericFormatFields.currency,
    component: 'NumericFormat',
  },
  phone: {
    formatConfig: patternFormatFields.phone,
    component: 'PatternFormat',
  },
  ssn: { formatConfig: patternFormatFields.ssn, component: 'PatternFormat' },
} satisfies Record<
  FormatType,
  {
    formatConfig: NumericFormatProps | PatternFormatProps;
    component: 'NumericFormat' | 'PatternFormat';
  }
>;
