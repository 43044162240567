import { memo } from 'react';
import _get from 'lodash.get';
import { Typography, TypographyProps } from '@mui/material';
import { formatPhone, stableObject } from '../../../lib';

import { useStyles } from './fields.styles';
import { AlignType } from '../../../types';

interface Props {
  typographyProps?: TypographyProps;
  record?: Record<string, any>;
  className?: string;
  headerClassName?: string;
  cellStyle?: React.CSSProperties;
  source: string;
  label: string;
  disablePadding?: string;
  align?: AlignType;
  sortBy?: string;
  sortable?: false;
  extSource?: string;
}

export const PhoneField = memo<Props>(
  ({
    className,
    typographyProps: _typographyProps,
    source,
    extSource = '',
    record: _record,
    ...rest
  }) => {
    const { classes, cx } = useStyles();
    const record = _record || stableObject;
    const typographyProps = _typographyProps || stableObject;

    const value = _get(record, source);
    const ext = _get(record, extSource);
    const displayExt = ext ? `x${ext}` : '';
    return (
      <Typography
        component='span'
        variant='body2'
        className={cx(classes.field, className)}
        {...typographyProps}
      >
        {typeof value === 'string'
          ? `${formatPhone(value)} ${displayExt}`
          : '--'}
      </Typography>
    );
  },
);
