import { useState, useCallback, useMemo, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { TextField } from '@mui/material';
import { SearchIcon } from '../../../assets';
import { useGetQueryParams, useUpdatePageQueryParams } from '../../../hooks';
import { useStyles } from './inputs.styles';

/**
 * Material-ui TextField with validation TextInput
 * @param {import("@mui/material/TextField").TextFieldProps & searchInputProps} props
 */
export const SearchInput = function SearchInput({
  label = 'Search',
  queryParam = 'search',
  className,
  slotProps: _slotProps,
  ...rest
}) {
  const { classes, cx } = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { [queryParam]: search, 'custom-view-id': customViewId } =
    useGetQueryParams();

  const { input = {}, inputLabel = {}, ...slotProps } = _slotProps || {};

  const [inputValue, setInputValue] = useState(search || '');

  // See here why we use useMemo and not useCallback
  // https://github.com/facebook/react/issues/19240#issuecomment-652945246
  const debounceFilter = useMemo(
    () =>
      debounce((value) => {
        updatePageQueryParams({ [queryParam]: value || null });
      }, 300),
    [queryParam, updatePageQueryParams],
  );

  const onFilterChange = useCallback(
    (e) => {
      const { value } = e.target;
      debounceFilter(value);
      setInputValue(value);
    },
    [debounceFilter],
  );

  // keep the input synched with the query param
  useEffect(() => {
    if (customViewId && search !== inputValue) {
      setInputValue(search || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <TextField
      variant='standard'
      label={label}
      name='searchText'
      onChange={onFilterChange}
      value={inputValue}
      autoComplete='off'
      slotProps={{
        input: {
          classes: {
            root: cx(classes.searchRoot, className),
          },
          endAdornment: <SearchIcon className={classes.searchIcon} />,
          ...input,
        },

        inputLabel: { classes: { root: classes.label }, ...inputLabel },
        ...slotProps,
      }}
      {...rest}
    />
  );
};

// #region Typedefs
/** @typedef {object} searchInputProps
 * @property {String} [queryParam] The query name to store in the URL. Defaults to `search`
 * @property {import("react").HTMLAttributes} [className]
 */
// #endregion
