import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import { addResource } from '../../../state';
import { useRefresh, useNotify } from '../../../hooks';
import { InfoMessage } from '../InfoMessage';

const initialState = {
  nmfcCode: '',
  commodityCategory: '',
};

export function BatchEditCustomerItemForm({
  open,
  handleClose,
  customerId,
  customerItemIds,
  clearSelectedItems,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = {
        ...values,
        customerItemsIDs: customerItemIds,
        customerID: customerId,
      };
      const { error, data } = await dispatch(
        addResource({
          baseUrl: `/batches/edit/customer-items`,
          payload,
        }),
      );
      setSubmitting(false);
      if (!error) {
        if (Array.isArray(data?.messages) && data.messages.length) {
          const msgDisplay = data.messages.map((m, i) => (
            <>
              <span key={i}>{m}</span>
              <br />
            </>
          ));
          notify(msgDisplay, 'error');
        } else {
          notify('Changes saved');
          refresh();
          handleClose();
          clearSelectedItems && clearSelectedItems();
        }
      }
    },
    [
      customerItemIds,
      customerId,
      dispatch,
      notify,
      refresh,
      handleClose,
      clearSelectedItems,
    ],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Batch edit items</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      <div>
        <InfoMessage
          message={`Changes you make will affect all ${customerItemIds.length} items selected`}
        />
        <TextInput
          name='nmfcCode'
          formikProps={formik}
          label='NMFC Code'
          className={classes.input}
        />
        <TextInput
          name='commodityCategory'
          formikProps={formik}
          label='Commodity description'
          className={classes.input}
        />
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));

const schema = object().shape({
  nmfcCode: string(),
  commodityCategory: string(),
});
