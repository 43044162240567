import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  getBasicWarehouses,
  useDispatch,
  useSelector,
  warehousesSelectors,
} from '../../state';
import { ProcessContainer, SelectInput } from '..';
import { InputChangeEventType } from '../../types';
import { Button } from '@mui/material';

interface Props {
  handleSelect: (warehouseId: string | number) => any;
}

export function SelectWarehouse({ handleSelect }: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const warehouses = useSelector(warehousesSelectors.basicList);

  const [error, setError] = useState<string | null>(null);
  const [warehouseId, setWarehouseId] = useState('');

  useEffect(() => {
    (async function () {
      await dispatch(getBasicWarehouses());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback((e: InputChangeEventType) => {
    setError(null);
    setWarehouseId(e.target.value);
  }, []);

  const handleGo = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (!warehouseId) {
        setError('Warehouse is required');
        return;
      }
      handleSelect(warehouseId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [warehouseId],
  );

  return (
    <ProcessContainer header='Select warehouse'>
      <div className={classes.card}>
        <form onSubmit={handleGo} className={classes.form}>
          <SelectInput
            variant='standard'
            label='Select warehouse'
            className={classes.input}
            name='warehouseId'
            value={warehouseId}
            onChange={handleChange}
            options={warehouses}
            autoFocus
            error={Boolean(error)}
            helperText={error}
          />
          <Button variant='contained' color='primary' type='submit'>
            go
          </Button>
        </form>
      </div>
    </ProcessContainer>
  );
}

const useStyles = makeStyles({
  name: { SelectWarehouse },
})((theme) => ({
  card: {
    height: 110,
    width: 468,
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '0px 16px',
  },
  input: {
    width: '100%',
    [theme.breakpoints.down('tablet')]: {
      marginRight: 16,
    },
  },
}));
