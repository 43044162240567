import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setUILoading } from '../state/features';

export function useLoader() {
  const dispatch = useDispatch();
  const retFunc = useCallback(
    /**
     * Toggles the page loader
     * @param {boolean} [setLoading] Set to true to show page loader. Defaults to false.
     */
    (setLoading = false) => {
      dispatch(setUILoading(setLoading));
    },
    [dispatch],
  );
  return retFunc;
}
