import { memo } from 'react';
import _get from 'lodash.get';
import { Chip, ChipProps } from '@mui/material';

import { useStyles } from './fields.styles';
import { AlignType } from '../../../types';
import { stableObject } from '../../../lib';

interface Props {
  chipProps?: ChipProps;
  format?: string;
  record?: Record<string, any>;
  className?: string;
  headerClassName?: string;
  cellStyle?: React.CSSProperties;
  source: string;
  label: string;
  disablePadding?: string;
  align?: AlignType;
  sortBy?: string;
  sortable?: false;
}

export const ChipField = memo<Props>(
  ({ className, chipProps: _chipProps, source, record: _record, ...rest }) => {
    const { classes, cx } = useStyles();
    const record = _record || stableObject;
    const chipProps = _chipProps || stableObject;
    const value = _get(record, source);
    if (!value) return null;
    return (
      <Chip
        className={cx(classes.chip, className)}
        label={value}
        size='small'
        {...chipProps}
      />
    );
  },
);
