import { useEffect, useState, lazy } from 'react';
import {
  BrowserRouter,
  Routes as RouteRoutes,
  Route,
  Navigate,
  useNavigate,
  useParams,
  useLocation,
} from 'react-router';
import { isAfter } from 'date-fns';
import { Navigation, paths, generateUrl } from './lib';
import { useDispatch, setAfterLoginRoute } from './state';
import { useGetUsersData, useIsMobile } from './hooks';
import { MainLayout } from './components/layout/MainLayout';

const MobileHomePage = lazy(() => import('./pages/MobileHomePage'));
const MobileCameraUploadPage = lazy(
  () => import('./components/common/modals/MobileCameraUploadPage'),
);
const CustomerDocuments = lazy(
  () => import('./pages/customers/CustomerDocuments'),
);
const CustomerResellerBills = lazy(
  () => import('./pages/customers/CustomerResellerBills'),
);
const CustomerAccounting = lazy(
  () => import('./pages/customers/CustomerAccounting'),
);
const ReportsPage = lazy(() => import('./pages/ReportsPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const OrderProcessingDashboard = lazy(
  () => import('./pages/dashboards/orderProcessing/OrderProcessingDashboard'),
);
const ProfitAndLossDashboard = lazy(
  () => import('./pages/dashboards/profitAndLoss/ProfitAndLossDashboard'),
);
const ShippingDashboard = lazy(
  () => import('./pages/dashboards/shipping/ShippingDashboardPageContainer'),
);
const AuthPage = lazy(() => import('./pages/Auth'));
const CreateCompanyUserAccount = lazy(
  () => import('./pages/users/CreateCompanyUserAccount'),
);
const CompanyUserProfile = lazy(
  () => import('./pages/users/CompanyUserProfile'),
);
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const ProcessOrderHome = lazy(
  () => import('./pages/processOrder/ProcessOrderHome'),
);
const ScanOrder = lazy(() => import('./pages/processOrder/ScanOrder'));
const PackingMode = lazy(() => import('./pages/processOrder/PackingMode'));
const FreightPackingMode = lazy(
  () => import('./pages/processOrder/FreightPackingMode'),
);
const ManagePalletsHome = lazy(
  () => import('./pages/processOrder/ManagePalletsHome'),
);
const ManagePalletsPagesContainer = lazy(
  () =>
    import('./pages/processOrder/managePallets/ManagePalletsPagesContainer'),
);
const UnloadAppointmentsContainer = lazy(
  () => import('./pages/unloadAppointment/UnloadAppointmentsContainer'),
);
const CompleteAppointmentMobileToggle = lazy(
  () => import('./pages/CompleteAppointmentMobileToggle'),
);
const VerifyAppointmentsContainer = lazy(
  () =>
    import(
      './pages/appointments/verifyAppointment/VerifyAppointmentsContainer'
    ),
);
const AssignAppointmentReceiptsMobileContainer = lazy(
  () =>
    import(
      './pages/appointments/assignAppointment/AssignAppointmentReceiptsMobileContainer'
    ),
);
const OrdersPage = lazy(() => import('./pages/OrdersPage'));
const BatchOrdersPage = lazy(() => import('./pages/BatchOrdersPage'));
const BatchesPage = lazy(() => import('./pages/BatchesPage'));
const BatchPagesContainer = lazy(
  () => import('./pages/batchComponents/BatchPagesContainer'),
);
const OrderPagesContainer = lazy(
  () => import('./pages/orderComponents/OrderPagesContainer'),
);
const LocationsInventoryPage = lazy(
  () => import('./pages/LocationsInventoryPage'),
);
const InventoryStockPage = lazy(() => import('./pages/InventoryStockPage'));
const InventoryStockItemHistoryPage = lazy(
  () => import('./pages/inventory/InventoryStockItemHistoryPage'),
);
const InventoryMobileTogglePage = lazy(
  () => import('./pages/inventory/InventoryMobileToggle'),
);
const InventoryLookupMobileTogglePage = lazy(
  () => import('./pages/inventory/InventoryLookupMobileToggle'),
);
const CreateReturn = lazy(() => import('./pages/orderComponents/CreateReturn'));
const ShippingHistoryPage = lazy(
  () => import('./pages/shipping/ShippingHistoryPage'),
);
const ShipmentManifestsPage = lazy(
  () => import('./pages/shipping/ShipmentManifestsPage'),
);
const PrintLabelsPage = lazy(() => import('./pages/PrintLabelsPage'));
const TruckingJobsPage = lazy(() => import('./pages/TruckingJobsPage'));
const CustomerQuoteView = lazy(
  () => import('./pages/truckingJobsComponents/CustomerQuoteView'),
);

const TruckingJobRatesPage = lazy(
  () => import('./pages/truckingJobsComponents/TruckingJobRatesPage'),
);
const DrayagePage = lazy(() => import('./pages/DrayagePage'));
const DrayagePagesContainer = lazy(
  () => import('./pages/drayageJobComponents/DrayagePagesContainer'),
);
const DrayageJobCarrierConfirmView = lazy(
  () => import('./pages/drayageJobComponents/DrayageJobCarrierConfirmView'),
);
const GetQuoteContainer = lazy(
  () => import('./pages/quotes/GetQuoteContainer'),
);
const PurchasedLabelsPage = lazy(
  () => import('./pages/quotes/PurchasedLabelsPage'),
);
const PurchaseLabelsContainer = lazy(
  () => import('./pages/quotes/PurchaseLabelsContainer'),
);
const PurchasedLabelPagesContainer = lazy(
  () => import('./pages/quotes/PurchasedLabelPagesContainer'),
);
const CustomersPage = lazy(() => import('./pages/customers/CustomersPage'));
const CustomerPagesContainer = lazy(
  () => import('./pages/customers/CustomerPagesContainer'),
);
const EditCustomerProfile = lazy(
  () => import('./pages/customers/EditCustomerProfile'),
);
const AuthorizeAmazonLandingPage = lazy(
  () => import('./pages/customers/AuthorizeAmazonLandingPage'),
);
const BillPage = lazy(() => import('./pages/billing/BillPage'));
const BillDetailsContainer = lazy(
  () => import('./pages/billing/BillDetailsContainer'),
);
const AccountsReceivablePage = lazy(
  () => import('./pages/AccountsReceivablePage'),
);
const AccountPayablePage = lazy(
  () => import('./pages/billing/AccountPayablePage'),
);
const AccountPayableDetailsPage = lazy(
  () => import('./pages/billing/AccountPayableDetailsPage'),
);
const CarriersAndAccountsPage = lazy(
  () => import('./pages/CarriersAndAccountsPage'),
);
const AddEditCarrierAccountPage = lazy(
  () => import('./pages/AddEditCarrierAccountPage'),
);
const AddNewQuotePage = lazy(
  () => import('./pages/truckingJobsComponents/AddNewQuotePage'),
);
const TruckingJobsPagesContainer = lazy(
  () => import('./pages/truckingJobsComponents/TruckingJobsPagesContainer'),
);
const ManageCarrierAccountServicesPage = lazy(
  () => import('./pages/ManageCarrierAccountServicesPage'),
);
const MaterialPage = lazy(() => import('./pages/vendorsMaterial/MaterialPage'));
const AddEditProducts = lazy(
  () => import('./pages/vendorsMaterial/AddEditProducts'),
);
const AddEditVendor = lazy(
  () => import('./pages/vendorsMaterial/AddEditVendor'),
);
const UsersPage = lazy(() => import('./pages/users/UsersPage'));
const AddEditUser = lazy(() => import('./pages/users/AddEditUser'));
const SettingsPage = lazy(() => import('./pages/settings/SettingsPage'));
const TerminalsPage = lazy(() => import('./pages/TerminalsPage'));
const AddEditDrayageJob = lazy(
  () => import('./pages/drayageJobComponents/AddEditDrayageJob'),
);
const SystemServices = lazy(() => import('./pages/services/SystemServices'));
const FulfilledServices = lazy(() => import('./pages/FulfilledServices'));
const AddEditFulfilledService = lazy(
  () => import('./pages/services/AddEditFulfilledService'),
);
const FulfilledServicesPagesContainer = lazy(
  () => import('./pages/services/FulfilledServicesPagesContainer'),
);
const WarehousesPage = lazy(() => import('./pages/warehouses/WarehousesPage'));
const WarehousePagesContainer = lazy(
  () => import('./pages/warehouses/WarehousePagesContainer'),
);
const ReceiptsPage = lazy(() => import('./pages/ReceiptsPage'));
const AddReceipt = lazy(() => import('./pages/receipt/AddReceipt'));
const ReceiptPagesContainer = lazy(
  () => import('./pages/receipt/ReceiptPagesContainer'),
);
const EditCustomerItemSettings = lazy(
  () => import('./pages/customers/EditCustomerItemSettings'),
);
const CheckInSignInPage = lazy(
  () => import('./pages/appointmentCheckIn/CheckInSignInPage'),
);
const CheckInForm = lazy(
  () => import('./pages/appointmentCheckIn/CheckInForm'),
);
const InternalUserCheckInDriver = lazy(
  () => import('./pages/appointmentCheckIn/InternalUserCheckInDriver'),
);
const DriverCheckInsPage = lazy(() => import('./pages/DriverCheckInsPage'));
const AppointmentsPage = lazy(() => import('./pages/AppointmentsPage'));
const AddAppointmentPage = lazy(
  () => import('./pages/appointments/AddEditAppointment'),
);
const AppointmentPagesContainer = lazy(
  () => import('./pages/appointments/AppointmentPagesContainer'),
);
const PrepReceiptHome = lazy(
  () => import('./pages/receipt/prepReceipt/PrepReceiptHome'),
);
const PutAwayReceiptHome = lazy(
  () => import('./pages/receipt/putAwayReceipt/PutAwayReceiptHome'),
);
const PutAwayMobileToggle = lazy(() => import('./pages/PutAwayMobileToggle'));
const ValidateReceiptHome = lazy(
  () => import('./pages/receipt/validateReceipt/ValidateReceiptHome'),
);
const MoveInventoryHomePage = lazy(
  () => import('./pages/inventory/moveInventory/MoveInventoryHome'),
);
const LookupInventoryHomePage = lazy(
  () => import('./pages/inventory/lookups/LookupLocationHome'),
);
const LookupMoveableUnitHomePage = lazy(
  () => import('./pages/inventory/lookups/LookupMoveableUnitHome'),
);

export default function Routes() {
  return (
    <BrowserRouter>
      <NavigationSetter />
      <RouteRoutes>
        <Route element={<AuthPage />}>
          <Route path={generateUrl(paths.LOGIN)} />
          <Route path={generateUrl(paths.FORGOT_PASSWORD)} />
          <Route path={generateUrl(paths.MF_AUTH)} />
          <Route path={generateUrl(paths.RESET_PASSWORD)} />
        </Route>

        <Route
          path={generateUrl(paths.CREATE_COMPANY_USER_ACCOUNT)}
          element={<CreateCompanyUserAccount />}
        />

        <Route element={<MainLayout />}>
          <Route
            path={generateUrl(paths.MOBILE_HOME)}
            element={
              <AuthorizeRoute>
                <MobileHomePage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.MOBILE_PHOTOS)}
            element={
              <AuthorizeRoute>
                <MobileCameraUploadPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.COMPANY_USER_PROFILE)}
            element={
              <AuthorizeRoute>
                <CompanyUserProfile />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.MAIN_KPI_DASHBOARD)}
            element={
              <AuthorizeRoute>
                <DashboardPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ORDER_FULFILLMENT_DASHBOARD)}
            element={
              <AuthorizeRoute>
                <OrderProcessingDashboard />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.P_L_DASHBOARD)}
            element={
              <AuthorizeRoute>
                <ProfitAndLossDashboard />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.SHIPPING_DASHBOARD)}
            element={
              <AuthorizeRoute>
                <ShippingDashboard />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.SYSTEM_SERVICES)}
            element={
              <AuthorizeRoute>
                <SystemServices />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.FULFILLED_SERVICES)}
            element={
              <AuthorizeRoute>
                <FulfilledServices />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.FULFILLED_SERVICE_PAGES)}
            element={
              <AuthorizeRoute>
                <FulfilledServicesPagesContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ADD_FULFILLED_SERVICE)}
            element={
              <AuthorizeRoute>
                <AddEditFulfilledService />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.EDIT_FULFILLED_SERVICE)}
            element={
              <AuthorizeRoute>
                <AddEditFulfilledService />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ADMIN_REPORTS)}
            element={
              <AuthorizeRoute>
                <ReportsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.SHARED_REPORTS)}
            element={
              <AuthorizeRoute>
                <ReportsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.PROCESS_ORDER)}
            element={
              <AuthorizeRoute>
                <ProcessOrderHome />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.SCAN_ORDER)}
            element={
              <AuthorizeRoute>
                <ScanOrder />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.PACKING_MODE)}
            element={
              <AuthorizeRoute>
                <PackingMode />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.FREIGHT_PACKING_MODE)}
            element={
              <AuthorizeRoute>
                <FreightPackingMode />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.MANAGE_PALLETS)}
            element={
              <AuthorizeRoute>
                <ManagePalletsHome />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.MANAGE_PALLETS_PAGES)}
            element={
              <AuthorizeRoute>
                <ManagePalletsPagesContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.UNLOAD_APPOINTMENT)}
            element={
              <AuthorizeRoute>
                <UnloadAppointmentsContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.COMPLETE_APPOINTMENT_TOGGLE)}
            element={
              <AuthorizeRoute>
                <CompleteAppointmentMobileToggle />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.VERIFY_APPOINTMENT)}
            element={
              <AuthorizeRoute>
                <VerifyAppointmentsContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ASSIGN_APPOINTMENT_RECEIPTS)}
            element={
              <AuthorizeRoute>
                <AssignAppointmentReceiptsMobileContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ORDERS)}
            element={
              <AuthorizeRoute>
                <OrdersPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ADD_ORDER_APPOINTMENT)}
            element={
              <AuthorizeRoute>
                <OrdersPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ORDER_PAGES)}
            element={
              <AuthorizeRoute>
                <OrderPagesContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.PROCESS_RETURN)}
            element={
              <AuthorizeRoute>
                <CreateReturn />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.BATCH_ORDERS)}
            element={
              <AuthorizeRoute>
                <BatchOrdersPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.BATCH_LIST)}
            element={
              <AuthorizeRoute>
                <BatchesPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.BATCH_PAGES)}
            element={
              <AuthorizeRoute>
                <BatchPagesContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.STOCK)}
            element={
              <AuthorizeRoute>
                <InventoryStockPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.LOCATIONS)}
            element={
              <AuthorizeRoute>
                <LocationsInventoryPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.STOCK_ITEM_HISTORY)}
            element={
              <AuthorizeRoute>
                <InventoryStockItemHistoryPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.INVENTORY_MOBILE_TOGGLE)}
            element={
              <AuthorizeRoute>
                <InventoryMobileTogglePage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.MOVE_INVENTORY)}
            element={
              <AuthorizeRoute>
                <MoveInventoryHomePage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.INVENTORY_LOOKUPS)}
            element={
              <AuthorizeRoute>
                <InventoryLookupMobileTogglePage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.INVENTORY_LOOKUP_LOCATION)}
            element={
              <AuthorizeRoute>
                <LookupInventoryHomePage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.INVENTORY_LOOKUP_MOVEABLE_UNIT)}
            element={
              <AuthorizeRoute>
                <LookupMoveableUnitHomePage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.SHIPPING_HISTORY)}
            element={
              <AuthorizeRoute>
                <ShippingHistoryPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.SHIPMENT_MANIFESTS)}
            element={
              <AuthorizeRoute>
                <ShipmentManifestsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.PRINT_LABEL)}
            element={
              <AuthorizeRoute>
                <PrintLabelsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ADD_NEW_TRUCKING_JOB)}
            element={
              <AuthorizeRoute>
                <AddNewQuotePage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.EDIT_TRUCKING_JOB)}
            element={
              <AuthorizeRoute>
                <AddNewQuotePage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.TRUCKING_JOBS)}
            element={
              <AuthorizeRoute>
                <TruckingJobsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ADD_TRUCKING_JOB_APPOINTMENT)}
            element={
              <AuthorizeRoute>
                <TruckingJobsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.EDIT_QUOTE)}
            element={
              <AuthorizeRoute>
                <AddNewQuotePage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.TRUCKING_JOB_RATES)}
            element={
              <AuthorizeRoute>
                <TruckingJobRatesPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.DRAYAGE_JOBS)}
            element={
              <AuthorizeRoute>
                <DrayagePage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ADD_DRAYAGE_JOB_APPOINTMENT)}
            element={
              <AuthorizeRoute>
                <DrayagePage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.EDIT_DRAYAGE_DELIVERY_ORDER_JOBS)}
            element={
              <AuthorizeRoute>
                <AddEditDrayageJob />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ADD_NEW_DRAYAGE_JOB)}
            element={
              <AuthorizeRoute>
                <AddEditDrayageJob />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.DRAYAGE_JOB_PAGES)}
            element={
              <AuthorizeRoute>
                <DrayagePagesContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.GET_SP_ESTIMATE)}
            element={
              <AuthorizeRoute>
                <GetQuoteContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.PURCHASED_LABEL)}
            element={
              <AuthorizeRoute>
                <PurchasedLabelsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.PURCHASE_LABEL)}
            element={
              <AuthorizeRoute>
                <PurchaseLabelsContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.PURCHASED_LABEL_DETAILS)}
            element={
              <AuthorizeRoute>
                <PurchasedLabelPagesContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.CUSTOMERS)}
            element={
              <AuthorizeRoute>
                <CustomersPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.EDIT_CUSTOMER_PROFILE)}
            element={
              <AuthorizeRoute>
                <EditCustomerProfile />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.CUSTOMER_AUTHORIZE_AMAZON_LANDING_PAGE)}
            element={
              <AuthorizeRoute>
                <AuthorizeAmazonLandingPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.CUSTOMER_INVOICE_DETAILS)}
            element={
              <AuthorizeRoute>
                <CustomerPagesContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.CUSTOMER_PAGES)}
            element={
              <AuthorizeRoute>
                <CustomerPagesContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.EDIT_CUSTOMER_ITEM)}
            element={
              <AuthorizeRoute>
                <EditCustomerItemSettings />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ACCOUNTING)}
            element={
              <AuthorizeRoute>
                <CustomerAccounting />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.RESELLER_BILLS)}
            element={
              <AuthorizeRoute>
                <CustomerResellerBills />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.DOCUMENTS)}
            element={
              <AuthorizeRoute>
                <CustomerDocuments />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.BILLS)}
            element={
              <AuthorizeRoute>
                <BillPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.BILL_DETAILS_PAGE)}
            element={
              <AuthorizeRoute>
                <BillDetailsContainer />
              </AuthorizeRoute>
            }
          />
          <Route
            path={generateUrl(paths.ACCOUNT_RECEIVABLE)}
            element={
              <AuthorizeRoute>
                <AccountsReceivablePage />
              </AuthorizeRoute>
            }
          />
          <Route
            path={generateUrl(paths.ACCOUNT_PAYABLE)}
            element={
              <AuthorizeRoute>
                <AccountPayablePage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ACCOUNT_PAYABLE_DETAILS_PAGE)}
            element={
              <AuthorizeRoute>
                <AccountPayableDetailsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.CARRIERS_ACCOUNTS)}
            element={
              <AuthorizeRoute>
                <CarriersAndAccountsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.EDIT_CARRIER_ACCOUNT)}
            element={
              <AuthorizeRoute>
                <AddEditCarrierAccountPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ADD_CARRIER_ACCOUNT)}
            element={
              <AuthorizeRoute>
                <AddEditCarrierAccountPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.TRUCKING_JOB_PAGES)}
            element={
              <AuthorizeRoute>
                <TruckingJobsPagesContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.MANAGE_CARRIER_ACCOUNT_SERVICES)}
            element={
              <AuthorizeRoute>
                <ManageCarrierAccountServicesPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.MATERIALS)}
            element={
              <AuthorizeRoute>
                <MaterialPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ADD_PRODUCTS)}
            element={
              <AuthorizeRoute>
                <AddEditProducts />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.EDIT_PRODUCTS)}
            element={
              <AuthorizeRoute>
                <AddEditProducts />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ADD_VENDORS)}
            element={
              <AuthorizeRoute>
                <AddEditVendor />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.EDIT_VENDORS)}
            element={
              <AuthorizeRoute>
                <AddEditVendor />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ADD_USERS)}
            element={
              <AuthorizeRoute>
                <AddEditUser />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.USERS)}
            element={
              <AuthorizeRoute>
                <UsersPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.EDIT_USERS)}
            element={
              <AuthorizeRoute>
                <AddEditUser />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.SETTINGS)}
            element={
              <AuthorizeRoute>
                <SettingsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.TERMINALS)}
            element={
              <AuthorizeRoute>
                <TerminalsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.WAREHOUSES)}
            element={
              <AuthorizeRoute>
                <WarehousesPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.WAREHOUSE_PAGES)}
            element={
              <AuthorizeRoute>
                <WarehousePagesContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.RECEIPTS)}
            element={
              <AuthorizeRoute>
                <ReceiptsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ADD_RECEIPT_APPOINTMENT)}
            element={
              <AuthorizeRoute>
                <ReceiptsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.RECEIPT_PAGES)}
            element={
              <AuthorizeRoute>
                <ReceiptPagesContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ADD_RECEIPT)}
            element={
              <AuthorizeRoute>
                <AddReceipt />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.RECEIPT_PURCHASE_LABELS)}
            element={
              <AuthorizeRoute>
                <PurchaseLabelsContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.PREP_RECEIPTS)}
            element={
              <AuthorizeRoute>
                <PrepReceiptHome />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.PUT_AWAY_TOGGLE)}
            element={
              <AuthorizeRoute>
                <PutAwayMobileToggle />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.PUT_AWAY_RECEIPTS)}
            element={
              <AuthorizeRoute>
                <PutAwayReceiptHome />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.VALIDATE_RECEIPTS)}
            element={
              <AuthorizeRoute>
                <ValidateReceiptHome />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.VALIDATE_RECEIPT)}
            element={
              <AuthorizeRoute>
                <ValidateReceiptHome />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.RECEIPT_TRUCKING_JOB)}
            element={
              <AuthorizeRoute>
                <AddNewQuotePage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.RECEIPT_DRAYAGE_JOB)}
            element={
              <AuthorizeRoute>
                <AddEditDrayageJob />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.DRIVER_CHECK_IN)}
            element={
              <AuthorizeRoute>
                <DriverCheckInsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.LINK_DRIVER_CHECK_IN_APPOINTMENT)}
            element={
              <AuthorizeRoute>
                <DriverCheckInsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.LINK_APPOINTMENTS_CHECK_IN)}
            element={
              <AuthorizeRoute>
                <AppointmentsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.APPOINTMENTS)}
            element={
              <AuthorizeRoute>
                <AppointmentsPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.ADD_APPOINTMENT)}
            element={
              <AuthorizeRoute>
                <AddAppointmentPage />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.APPOINTMENT_PAGES)}
            element={
              <AuthorizeRoute>
                <AppointmentPagesContainer />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.TRACK_TIME)}
            element={
              <AuthorizeRoute>
                <BlankComponent />
              </AuthorizeRoute>
            }
          />

          <Route
            path={generateUrl(paths.APPOINTMENT_CHECK_IN_FORM)}
            element={<CheckInForm />}
          />

          <Route
            path={generateUrl(paths.APPOINTMENT_CHECK_IN_FORM_INTERNAL)}
            element={
              <AuthorizeRoute>
                <InternalUserCheckInDriver />
              </AuthorizeRoute>
            }
          />
        </Route>

        <Route
          path={generateUrl(paths.APPOINTMENT_CHECK_IN)}
          element={<CheckInSignInPage />}
        />

        <Route
          path={generateUrl(paths.CUSTOMER_VIEW_QUOTE)}
          element={
            <AuthorizeRoute>
              <CustomerQuoteView />
            </AuthorizeRoute>
          }
        />

        <Route
          path={generateUrl(paths.CARRIER_CONFIRM_DRAYAGE_DELIVERY_ORDER_JOB)}
          element={<DrayageJobCarrierConfirmView />}
        />

        <Route path='/' element={<RootRedirect />} />

        <Route path={generateUrl(paths.NOT_FOUND)} element={<NotFoundPage />} />

        <Route
          path='*'
          element={<Navigate replace to={generateUrl(paths.NOT_FOUND)} />}
        />
      </RouteRoutes>
    </BrowserRouter>
  );
}

function RootRedirect(props) {
  const isMobile = useIsMobile(true);
  const {
    isCustomer,
    isScanner,
    isScannerProcessor,
    isDriverCheckInMode,
    isManageAppointmentUser,
    isManager,
  } = useGetUsersData();
  let path = paths.PROCESS_ORDER;

  switch (true) {
    case isDriverCheckInMode:
      path = paths.APPOINTMENT_CHECK_IN;
      break;
    case isCustomer:
      path = paths.ORDERS;
      break;
    case isScannerProcessor:
      if (isMobile) {
        path = paths.MOBILE_HOME;
      }
      break;
    case isScanner:
      if (isMobile) {
        path = paths.MOBILE_HOME;
      } else {
        path = paths.MANAGE_PALLETS;
      }
      break;
    case isManageAppointmentUser:
      path = paths.APPOINTMENTS;
      break;
    case isManager:
      if (isMobile) {
        path = paths.MOBILE_HOME;
      } else {
        path = paths.APPOINTMENTS;
      }
      break;
    default:
      break;
  }
  return <Navigate replace to={generateUrl(path)} {...props} />;
}

// function DynamicRedirect({
//   path,
//   params: dynamicParams,
//   query,
//   ...rest
// }: {
//   path: string;
//   params?: Record<string, any>;
//   query?: Record<string, any>;
// }) {
//   const params = useParams();
//   const to = generateUrl(path, { ...params, ...(dynamicParams || {}) }, query);
//   return <Navigate replace to={to} {...rest} />;
// }

function AuthorizeRoute({ children }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { pathname } = useLocation();
  // const { path, computedMatch: { params } = {} } = rest;

  const [canRedirect, setCanRedirect] = useState(false);

  const {
    token,
    expiration,
    isAdmin,
    isCustomer,
    isProcessor,
    isCustomerService,
    isTruckingSalesPerson,
    isScanner,
    isScannerProcessor,
    isManager,
    isManageAppointmentUser,
    customerId,
  } = useGetUsersData();
  const hasPermission = checkPermissions({
    path: pathname,
    params,
    isAdmin,
    isCustomer,
    isProcessor,
    isCustomerService,
    isTruckingSalesPerson,
    isScanner,
    isScannerProcessor,
    isManageAppointmentUser,
    isManager,
    customerId,
  });

  const hasInvalidToken = !token || isAfter(new Date(), new Date(expiration!));

  useEffect(() => {
    if (!hasInvalidToken) {
      setCanRedirect(false);
    } else {
      const { pathname, search } = window.location;
      if (
        hasInvalidToken &&
        pathname !== generateUrl(paths.LOGIN) &&
        pathname !== generateUrl(paths.PROCESS_ORDER)
      ) {
        dispatch(
          setAfterLoginRoute({
            redirectTo: pathname + search,
          }),
        );
      }
      setCanRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInvalidToken]);

  // make sure that we set the redirect url before navigating
  if (hasInvalidToken && !canRedirect) return null;
  if (hasInvalidToken) {
    return <Navigate replace to={generateUrl(paths.LOGIN)} />;
  }
  if (!hasPermission) {
    return <Navigate replace to={generateUrl(paths.NOT_FOUND)} />;
  }

  return children;
}

// Need this for the track-time route because the layout will handle rendering the component
// so we just need the router not to redirect to not-found
const BlankComponent = () => null;

function checkPermissions({
  path,
  params,
  isAdmin,
  isProcessor,
  isCustomerService,
  isTruckingSalesPerson,
  isCustomer,
  isScanner,
  isScannerProcessor,
  isManageAppointmentUser,
  isManager,
  customerId,
}) {
  // define the route as the key and an array of user types with permission to this route. Admin will have permission to all paths.
  // ie. '/admin/account/create-company': [permissionTypes.PROCESSOR],
  const userTypesPermissions = {
    '/process-order': [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.MAIN_KPI_DASHBOARD)]: [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.ORDER_FULFILLMENT_DASHBOARD)]: [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.P_L_DASHBOARD)]: [],
    [generateUrl(paths.SHIPPING_DASHBOARD)]: [permissionTypes.CUSTOMER],
    [generateUrl(paths.ADMIN_REPORTS)]: [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.SHARED_REPORTS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.SYSTEM_SERVICES)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.FULFILLED_SERVICES)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.ADD_FULFILLED_SERVICE)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.EDIT_FULFILLED_SERVICE)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.FULFILLED_SERVICE_PAGES)]: [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/process-order/scan-order': [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/process-order/:ORDER_ID/packing-mode': [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.FREIGHT_PACKING_MODE)]: [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.MANAGE_PALLETS)]: [
      permissionTypes.SCANNER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.MANAGE_PALLETS_PAGES)]: [
      permissionTypes.SCANNER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.UNLOAD_APPOINTMENT)]: [
      permissionTypes.SCANNER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.VERIFY_APPOINTMENT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.COMPLETE_APPOINTMENT_TOGGLE)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.ASSIGN_APPOINTMENT_RECEIPTS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.MANAGER,
    ],
    '/orders': [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/order/:ORDER_ID/:NESTED_PAGE': [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.PROCESS_RETURN)]: [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/batch-orders': [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/batches': [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/batches/:BATCH_ID/:NESTED_PAGE': [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/trucking-jobs/add': [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.TRUCKING_JOBS)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.EDIT_TRUCKING_JOB)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.TRUCKING_JOB_PAGES)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.TRUCKING_JOB_RATES)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.TRUCKING_LOAD_DETAILS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.DRAYAGE_JOBS)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.ADD_NEW_DRAYAGE_JOB)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.EDIT_DRAYAGE_DELIVERY_ORDER_JOBS)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.DRAYAGE_JOB_PAGES)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.GET_SP_ESTIMATE)]: [
      permissionTypes.PROCESSOR,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.PURCHASED_LABEL)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.PURCHASE_LABEL)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.PURCHASED_LABEL_DETAILS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER,
    ],
    '/customers': [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/customer/:CUSTOMER_ID/:NESTED_PAGE': [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    [generateUrl(paths.CUSTOMER_AUTHORIZE_AMAZON_LANDING_PAGE)]: [
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.CUSTOMER_INVOICE_DETAILS)]: [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/billing': [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.ACCOUNT_RECEIVABLE)]: [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.ACCOUNT_PAYABLE)]: [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.ACCOUNT_PAYABLE_DETAILS_PAGE)]: [
      permissionTypes.CUSTOMER_SERVICE,
    ],
    '/carriers-accounts': [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/carriers-accounts/edit/:CARRIER_ID': [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/carriers-accounts/add': [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/carriers-accounts/services': [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
    ],
    '/materials/:NESTED_PAGE': [permissionTypes.CUSTOMER_SERVICE],
    '/materials/products/edit/:PRODUCT_ID': [],
    '/materials/vendors/edit/:VENDOR_ID': [],
    '/materials/vendors/add': [],
    '/materials/products/add': [],
    '/users/:NESTED_PAGE': [permissionTypes.CUSTOMER_SERVICE],
    '/users/:NESTED_PAGE/edit/:USER_ID': [],
    '/users/add': [],
    '/settings/:NESTED_PAGE': [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.TERMINALS)]: [
      permissionTypes.TRUCKING_SALES,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.EDIT_CUSTOMER_ITEM)]: [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.RESELLER_BILLS)]: [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.ACCOUNTING)]: [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.DOCUMENTS)]: [
      permissionTypes.CUSTOMER,
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.WAREHOUSES)]: [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.WAREHOUSE_PAGES)]: [],
    [generateUrl(paths.TRACK_TIME)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.TRUCKING_SALES,
      permissionTypes.PROCESSOR,
      permissionTypes.SCANNER,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.RECEIPTS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.RECEIPT_PAGES)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.ADD_RECEIPT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.RECEIPT_PURCHASE_LABELS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.RECEIPT_TRUCKING_JOB)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.RECEIPT_DRAYAGE_JOB)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
    ],
    [generateUrl(paths.PREP_RECEIPTS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGER,
      permissionTypes.SCANNER,
    ],
    [generateUrl(paths.PUT_AWAY_TOGGLE)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGER,
      permissionTypes.SCANNER,
    ],
    [generateUrl(paths.PUT_AWAY_RECEIPTS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGER,
      permissionTypes.SCANNER,
    ],
    [generateUrl(paths.VALIDATE_RECEIPT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.VALIDATE_RECEIPTS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.DRIVER_CHECK_IN)]: [permissionTypes.CUSTOMER_SERVICE],
    [generateUrl(paths.APPOINTMENT_CHECK_IN_FORM_INTERNAL)]: [
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.LINK_DRIVER_CHECK_IN_APPOINTMENT)]: [
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.LINK_APPOINTMENTS_CHECK_IN)]: [
      permissionTypes.CUSTOMER_SERVICE,
    ],
    [generateUrl(paths.APPOINTMENTS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
      permissionTypes.MANAGE_APPOINTMENTS,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.ADD_APPOINTMENT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
      permissionTypes.MANAGE_APPOINTMENTS,
    ],
    [generateUrl(paths.APPOINTMENT_PAGES)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
      permissionTypes.MANAGE_APPOINTMENTS,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.ADD_ORDER_APPOINTMENT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGE_APPOINTMENTS,
    ],
    [generateUrl(paths.ADD_TRUCKING_JOB_APPOINTMENT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGE_APPOINTMENTS,
    ],
    [generateUrl(paths.ADD_DRAYAGE_JOB_APPOINTMENT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGE_APPOINTMENTS,
    ],
    [generateUrl(paths.ADD_RECEIPT_APPOINTMENT)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.MANAGE_APPOINTMENTS,
    ],
    [generateUrl(paths.COMPANY_USER_PROFILE)]: [
      permissionTypes.MANAGE_APPOINTMENTS,
    ],
    [generateUrl(paths.STOCK)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.LOCATIONS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.CUSTOMER,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.INVENTORY_MOBILE_TOGGLE)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.SCANNER,
      permissionTypes.SCANNER_PROCESSOR,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.MOVE_INVENTORY)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.SCANNER,
      permissionTypes.SCANNER_PROCESSOR,
      permissionTypes.MANAGER,
    ],
    [generateUrl(paths.INVENTORY_LOOKUPS)]: [
      permissionTypes.CUSTOMER_SERVICE,
      permissionTypes.SCANNER,
      permissionTypes.SCANNER_PROCESSOR,
      permissionTypes.MANAGER,
    ],
  };

  if (isCustomer && path === '/customer/:CUSTOMER_ID/:NESTED_PAGE') {
    const { CUSTOMER_ID } = params;
    return parseInt(CUSTOMER_ID) === customerId;
  }

  return (
    isAdmin ||
    !userTypesPermissions[path] ||
    userTypesPermissions[path].some((userType) => {
      if (isScannerProcessor) {
        return (
          userType === permissionTypes.SCANNER ||
          userType === permissionTypes.PROCESSOR
        );
      } else if (isProcessor) {
        return userType === permissionTypes.PROCESSOR;
      } else if (isCustomerService) {
        return userType === permissionTypes.CUSTOMER_SERVICE;
      } else if (isTruckingSalesPerson) {
        return userType === permissionTypes.TRUCKING_SALES;
      } else if (isCustomer) {
        return userType === permissionTypes.CUSTOMER;
      } else if (isScanner) {
        return userType === permissionTypes.SCANNER;
      } else if (isManageAppointmentUser) {
        return userType === permissionTypes.MANAGE_APPOINTMENTS;
      } else if (isManager) {
        return userType === permissionTypes.MANAGER;
      }
      return false;
    })
  );
}

const permissionTypes = {
  ADMIN: 'isAdmin',
  CUSTOMER_SERVICE: 'isCustomerService',
  TRUCKING_SALES: 'isTruckingSalesPerson',
  PROCESSOR: 'isProcessor',
  SCANNER: 'isScanner',
  CUSTOMER: 'isCustomer',
  MANAGE_APPOINTMENTS: 'isManageAppointmentUser',
  DRIVER_CHECK_IN_MODE: 'isDriverCheckInMode',
  MANAGER: 'isManager',
  SCANNER_PROCESSOR: 'isScannerProcessor',
};

const NavigationSetter = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Navigation.init(navigate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null; // No UI needed, just setting up the navigation
};
