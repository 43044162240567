import { useCallback, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { FileInput, TextInput, SelectInput } from '../inputs';
import {
  useDispatch,
  useSelector,
  systemSelectors,
  addResource,
  uploadFiles,
  deleteResource,
  fetchResourceById,
} from '../../../state';
import { useRefresh, useNotify } from '../../../hooks';
import { convertNullFieldsToEmptyString } from '../../../lib';
import {
  DocumentAPI,
  FileType,
  ReceiptDocumentType,
  SignedUrlMapping,
} from '../../../types';

interface Props {
  open: boolean;
  handleClose: () => any;
  receiptId?: number;
  documentId?: number | string;
  getFiles?: (newFiles: Array<FileType>) => any;
}

interface Payload {
  receiptID: number;
  documentType: ReceiptDocumentType | '';
  document: DocumentAPI;
}

interface State {
  name: string;
  description: string;
  documentType: ReceiptDocumentType | '';
}

const initialState: State = {
  name: '',
  description: '',
  documentType: '',
};

export const ReceiptDocumentUpload = ({
  open,
  handleClose,
  receiptId,
  documentId,
  getFiles,
}: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();

  const [submitting, setSubmitting] = useState(false);
  const [files, setFiles] = useState<Array<FileType>>([]);
  const [state, setState] = useState(initialState);
  // const [configuredDocumentFees, setConfiguredDocumentFees] = useState([]);

  const documentTypes = useSelector(systemSelectors.receiptDocumentTypes);

  useEffect(() => {
    (async function () {
      if (documentId && receiptId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/receipts',
            id: receiptId,
            path: `documents/${documentId}`,
          }),
        );
        if (data) {
          const { document, id, ...otherData } = data;
          const { name, description, ...rest } = document || {};
          setState((cur) => ({
            ...cur,
            ...rest,
            ...convertNullFieldsToEmptyString({
              name,
              description,
              ...otherData,
            }),
          }));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptId, documentId]);

  const handleSubmit = useCallback(
    async (values) => {
      if (!documentId && !files.length) {
        return notify('Please upload a file', 'error');
      }
      if (typeof getFiles === 'function') {
        getFiles(files);
        handleClose();
        return;
      }
      if (!receiptId) return;
      setSubmitting(true);
      let googleResponse, signedUrlsMapping;

      if (!documentId) {
        const { data, error: uploadError } = await dispatch(uploadFiles(files));
        if (uploadError) {
          return setSubmitting(false);
        }
        googleResponse = data?.googleResponse;
        signedUrlsMapping = data?.signedUrlsMapping;
      }
      const payload = generatePayload({
        receiptId,
        files,
        signedUrlsMapping,
        values,
        hasUpload: !documentId,
      });
      if (documentId || googleResponse) {
        const { error } = await dispatch(
          addResource({
            baseUrl: `/receipts/${receiptId}/documents`,
            payload,
            message: 'Changes saved',
          }),
        );
        setSubmitting(false);
        if (!error) {
          refresh();
          handleClose();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentId, files, getFiles, handleClose, receiptId],
  );

  const handleDelete = useCallback(async () => {
    if (!documentId) return;
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: `/receipts/${receiptId}/documents`,
        id: documentId,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
  }, [dispatch, documentId, handleClose, receiptId, refresh]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Upload document</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={!!documentId}
      handleDelete={handleDelete}
    >
      <SelectInput
        name='documentType'
        formikProps={formik}
        label='Document type'
        className={classes.input}
        options={documentTypes}
        required
      />
      <TextInput
        name='name'
        formikProps={formik}
        label='Document name'
        className={classes.input}
      />
      {!documentId && (
        <FileInput
          files={files}
          setFiles={setFiles}
          filePrefix={`receipts/${receiptId ?? 'create'}-`}
          multiple
        />
      )}
    </FormModal>
  );
};

const useStyles = makeStyles({ name: { ReceiptDocumentUpload } })((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));

interface GenerateParams {
  receiptId: number;
  files: Array<FileType>;
  signedUrlsMapping: SignedUrlMapping;
  values: State;
  hasUpload: boolean;
}

function generatePayload({
  receiptId: receiptID,
  files,
  signedUrlsMapping,
  values,
  hasUpload,
}: GenerateParams) {
  const {
    documentType,
    // BillableToCustomer,
    ...rest
  } = values;

  // payload.BillableToCustomer = BillableToCustomer;

  const Document = rest as DocumentAPI;

  if (hasUpload) {
    const { key, rawFile: { type } = {} } = files[0];
    Document.objectName = key;
    Document.url = signedUrlsMapping[key];
    Document.type = type ?? '';
  }

  return {
    receiptID,
    documentType,
    document: Document,
  } as Payload;
}

const schema = object().shape({
  name: string(),
  documentType: string().required('Required'),
  description: string().nullable(),
});
