import { memo, useState } from 'react';
import _get from 'lodash.get';
import { Typography, TypographyProps } from '@mui/material';

import { useStyles } from './fields.styles';
import { formatDate, stableObject } from '../../../lib';
import { AlignType } from '../../../types';

interface Props {
  typographyProps?: TypographyProps;
  format?: string;
  useTime?: boolean;
  timeZone?: string;
  record?: Record<string, any>;
  convertToLocalTimezone?: boolean;
  timeZoneSource?: string;
  className?: string;
  headerClassName?: string;
  cellStyle?: React.CSSProperties;
  source: string;
  label: string;
  disablePadding?: string;
  align?: AlignType;
  sortBy?: string;
  sortable?: false;
}

export const DateField = memo<Props>(
  ({
    className,
    typographyProps: _typographyProps,
    source,
    record: _record,
    format: formatString = 'MM/dd/yyyy',
    convertToLocalTimezone,
    useTime,
    timeZone: _timeZone,
    timeZoneSource,
    ...rest
  }) => {
    const { classes, cx } = useStyles();
    const record = _record || stableObject;
    const [typographyProps] = useState(_typographyProps || {});
    const value = _get(record, source);
    if (!value) return <span>--</span>;
    const timeZone = _timeZone || _get(record, timeZoneSource);
    const date = formatDate(value, {
      formatStr: formatString,
      convertToLocalTimezone,
      useTime,
      timeZone,
    });
    if (!date) return <span>--</span>;
    return (
      <Typography
        component='span'
        variant='body2'
        className={cx(classes.field, className)}
        {...typographyProps}
      >
        {date}
      </Typography>
    );
  },
);
