import { Fragment, useState, useCallback, useRef } from 'react';
import {
  Chip,
  MenuList,
  ListItem,
  ListItemText,
  IconButton,
  ChipProps,
  TextFieldProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import { FilterMenu } from '../../';
import { Dropdown, SearchInput } from '../../common';
import { useUpdatePageQueryParams, useGetQueryParams } from '../../../hooks';
import { CloseIcon } from '../../../assets';
import { MouseChangeEventType } from '../../../types';

interface Props {
  label?: string;
  queryParam?: string;
  // filters?: Record<string, any>;
  filterSortIds?: Array<number>;
  clearable?: boolean;
  className?: string;
  chipProps?: ChipProps;
  textFieldProps?: TextFieldProps;
  style?: React.CSSProperties;
}
export const FiltersSearch = ({
  label = 'Filters',
  queryParam = 'list-filters',
  chipProps: _chipProps,
  textFieldProps: _textFieldProps,
  clearable = true,
  ...rest
}: Props) => {
  const textFieldProps = _textFieldProps || {};
  const chipProps = _chipProps || {};
  const { classes, cx } = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { [queryParam]: search } = useGetQueryParams();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = useCallback(
    (event: MouseEvent | TouchEvent | MouseChangeEventType) => {
      if (anchorRef.current?.contains(event.currentTarget as Node)) {
        return;
      }
      setOpen(false);
    },
    [],
  );

  const handleClear = useCallback(() => {
    updatePageQueryParams({ [queryParam]: null });
    setOpen(false);
  }, [queryParam, updatePageQueryParams]);

  const { className, ...otherTextFieldProps } = textFieldProps;

  return (
    <Fragment>
      <Chip
        ref={anchorRef}
        clickable
        color={!!search ? 'primary' : 'default'}
        label={label}
        className={classes.chip}
        onDelete={clearable && !!search ? handleClear : undefined}
        variant='outlined'
        onClick={handleToggle}
        {...chipProps}
      />
      <Dropdown
        ref={anchorRef}
        open={open}
        handleClose={handleClose}
        popperProps={{ style: { zIndex: 1 }, placement: 'bottom-start' }}
      >
        <MenuList autoFocusItem={open}>
          <ListItem
            secondaryAction={
              <IconButton onClick={handleClose} size='large'>
                <CloseIcon />
              </IconButton>
            }
          >
            <ListItemText primary={label} />
          </ListItem>
          <ListItem>
            <SearchInput
              label=''
              queryParam={queryParam}
              className={cx(classes.input, className)}
              classes={{ root: classes.input }}
              variant='outlined'
              size='small'
              {...otherTextFieldProps}
            />
          </ListItem>
        </MenuList>
      </Dropdown>
    </Fragment>
  );
};

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    // So we see the popover when it's empty.
    minWidth: 16,
    minHeight: 16,
    maxWidth: 'calc(100vw - 32px)',
    maxHeight: 'calc(100vh - 32px)',
    width: 300,
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,
  },
  chip: {
    margin: '0 4px 10px 4px',
  },
  input: {
    width: '100%',
    marginTop: 0,
  },
}));
