import { useCallback } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { TextInput, FormModal } from '../../';
import { addResource, useDispatch } from '../../../state';
import { useRefresh } from '../../../hooks';
import { Navigation, generateUrl, paths } from '../../../lib';

interface State {
  reason: string;
}
interface Props {
  handleClose: () => void;
  id: number;
  open: boolean;
  shouldRefresh?: boolean;
}

const initialState: State = {
  reason: '',
};

export function CancelReceiptForm({
  handleClose,
  id,
  open,
  shouldRefresh,
}: Props) {
  const refresh = useRefresh();
  const dispatch = useDispatch();

  const handleCancelReceipt = useCallback(
    async (values: State) => {
      const { error } = await dispatch(
        addResource({
          baseUrl: `/receipts/${id}/cancel`,
          payload: values,
          shouldSetUILoading: true,
          message: 'Receipt successfully cancelled',
        }),
      );
      if (!error) {
        handleClose();
        shouldRefresh
          ? refresh()
          : Navigation.redirect(generateUrl(paths.RECEIPTS));
      }
    },
    [dispatch, handleClose, id, refresh, shouldRefresh],
  );

  const formik = useFormik<State>({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleCancelReceipt,
    validationSchema: schema,
  });

  return (
    <FormModal
      callback={formik.handleSubmit}
      handleClose={handleClose}
      open={open}
      title='Are you sure you want to cancel this receipt?'
    >
      <TextInput
        formikProps={formik}
        label='Reason'
        multiline
        name='reason'
        rows={3}
        required
      />
    </FormModal>
  );
}

const schema = object().shape({
  reason: string().required('Required'),
});
