import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { formatISO } from 'date-fns';
import { MfaInfo } from '../../types/systemTypes';
import { UserContactsResponse, UserContactType } from '../../types';

interface AuthState {
  error?: object;
  afterLoginRedirect?: string;
  mfaInfo?: MfaInfo;
  userContacts: Array<UserContactsResponse>;
  lastFetchedUserContactsOn: string | null;
  userContactMapping: Record<string, UserContactType>;
}

const initialState: AuthState = {
  mfaInfo: {},
  userContacts: [],
  lastFetchedUserContactsOn: null,
  userContactMapping: {},
};

type CachedType = 'userContacts';

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      state.error = undefined;
    },
    loginFailed(state, action) {
      state.error = { ...action.payload };
    },
    setAfterLoginRoute(state, action) {
      const { redirectTo } = action.payload;
      state.afterLoginRedirect = redirectTo;
    },
    setMfaInfo(state, action) {
      state.mfaInfo = action.payload;
    },
    setUserContacts(state, action: PayloadAction<Array<UserContactsResponse>>) {
      state.userContacts = action.payload;
      state.lastFetchedUserContactsOn = formatISO(new Date());
      state.userContactMapping = action.payload.reduce<
        Record<string, UserContactType>
      >((acc, cur) => {
        acc[cur.id.toString()] = cur.contact;
        return acc;
      }, {});
    },
    invalidateAuthCache(state, action: PayloadAction<CachedType>) {
      switch (action.payload) {
        case 'userContacts':
          state.lastFetchedUserContactsOn = null;
          break;
      }
    },
  },
});

export const {
  login: updateLoginState,
  loginFailed,
  setAfterLoginRoute,
  setMfaInfo,
  setUserContacts,
  invalidateAuthCache,
} = auth.actions;

export const authReducer = auth.reducer;
