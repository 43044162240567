import { useCallback, useState, useEffect, Fragment, useMemo } from 'react';
import { Grid2 as Grid, Button, Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import {
  TextInput,
  SelectInput,
  CheckboxInput,
  AutocompleteInput,
} from '../inputs';
import {
  useDispatch,
  useSelector,
  addResource,
  fetchResourceById,
  systemSelectors,
  getCarrierAccountsList,
  getBasicCompaniesList,
  getAllCarrierPackageTypes,
  carriersSelectors,
  getCustomerSettings,
} from '../../../state';
import { useRefresh, useGetUsersData } from '../../../hooks';
import {
  convertNullFieldsToEmptyString,
  makeuuid,
  authGet,
  smallParcelBillToPartyOptions,
  freightBillToPartyOptions,
  getCarrierTypeFromFulfillment,
  validOrderStatusForShipmentJob,
  getPackageTypesOptions,
  taxEntityTypes,
} from '../../../lib';
import { ListDeleteIcon } from '../..';
import { ContactFields } from './ContactFields';
import { InfoMessage } from '../InfoMessage';

const initialState = {
  fulfillmentType: '',
  companyID: '',
  carrierID: '',
  carrierServiceCode: '',
  carrierPackageCode: '',
  shippingSelectionPreference: '',
  thirdPartyAccountNumber: '',
  insuranceCost: '',
  billToParty: '',
  confirmationType: '',
  requiresDeliveryConfirmation: false,
  containsAlcohol: false,
  deliveredDutyPaid: false,
  nonMachinable: false,
  saturdayDelivery: false,
  dryIce: false,
  dryIceWeight: false,
  billToContactCompanyName: '',
  billToContactFirstName: '',
  billToContactLastName: '',
  billToContactAddress: '',
  billToContactCity: '',
  billToContactStateId: '',
  billToContactZip: '',
  billToContactCountryId: '',
  truckingJobPickupNote: '',
  truckingJobDestinationNote: '',
};

export function EditOrderCarrierForm({
  open,
  handleClose,
  curData,
  orderId,
  orderStatus,
  customSubmit,
  isProcessing, // flag if opening the modal during order processing
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const { isAdmin, isCustomer, isCustomerService, isTruckingSalesPerson } =
    useGetUsersData();
  const hasEditPermission =
    isAdmin || isCustomer || isCustomerService || isTruckingSalesPerson;

  const [showManagerLogin, setShowManagerLogin] = useState(!hasEditPermission);
  const [adminToken, setAdminToken] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);
  const [hasAdvancedOptions, setHasAdvancedOptions] = useState(false);
  const [customsState, setCustomsState] = useState({});
  const [carrierAccountsList, setCarrierAccountsList] = useState([]);
  const [carrierCompanyList, setCarrierCompanyList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [orderItems, setOrderItems] = useState({});
  const [taxIdentifiersState, setTaxIdentifiersState] = useState({});
  const [showInternationalFields, setShowInternationalFields] = useState(false);
  const [defaultTaxEntityType, setDefaultTaxEntityType] = useState(
    taxEntityTypes.RECIPIENT,
  );

  const shippingSelectionPreferenceTypes = useSelector(
    systemSelectors.shippingSelectionPreferenceTypes,
  );
  const carrierPackageTypes = useSelector(
    carriersSelectors.carrierPackageTypes,
  );
  const customsContentsType = useSelector(systemSelectors.customsContentsType);
  const customsNonDeliveryType = useSelector(
    systemSelectors.customsNonDeliveryType,
  );
  const taxEntityType = useSelector(systemSelectors.taxEntityType);
  const taxIdentifierType = useSelector(systemSelectors.taxIdentifierType);

  const fulfillmentTypes = useSelector(systemSelectors.fulfillmentTypes);

  const deliveryConfirmationTypes = useSelector(
    systemSelectors.deliveryConfirmationTypes,
  );

  const countries = useSelector(systemSelectors.countries);

  useEffect(() => {
    dispatch(getAllCarrierPackageTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCarrierAccounts = useCallback(async (companyID) => {
    const { data } = await dispatch(
      getCarrierAccountsList({
        companyID,
        customerID: curData.customerID,
        includeTplAccountsForCustomer: true,
      }),
    );
    if (data) {
      setCarrierAccountsList(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function () {
      if (curData.customerID) {
        const { data } = await dispatch(
          getCustomerSettings(curData.customerID),
        );
        if (data) {
          const { taxEntity } = data;
          setDefaultTaxEntityType(taxEntity || taxEntityTypes.RECIPIENT);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curData.customerID]);

  const getAllCustomsItemsData = useCallback(
    async (items, itemsData = {}) => {
      if (items) {
        // make sure all items have data
        items.forEach((i) => {
          const { customerItemID, sku } = i;
          itemsData[customerItemID + ''] = itemsData[customerItemID + '']
            ? { ...itemsData[customerItemID + ''], sku }
            : {
                customerItemID,
                sku,
                harmonizedTariffCode: '',
                cost: '',
                quantity: '',
                countryOfOriginID: '',
              };
        });
      } else {
        const { data } = await authGet(`/orders/${orderId}/items`);
        if (data) {
          data.results.forEach((i) => {
            const { customerItemID, sku } = i;
            itemsData[customerItemID + ''] = itemsData[customerItemID + '']
              ? { ...itemsData[customerItemID + ''], sku }
              : {
                  customerItemID,
                  sku,
                  harmonizedTariffCode: '',
                  cost: '',
                  quantity: '',
                  countryOfOriginID: '',
                };
          });
        }
      }
      return itemsData;
    },
    [orderId],
  );

  const handleSubmit = async (_values) => {
    const {
      billToContactId,
      billToContactPersonId,
      billToContactFirstName,
      billToContactLastName,
      billToContactAddress,
      billToContactCity,
      billToContactStateId,
      billToContactZip,
      billToContactCountryId,
      ...values
    } = _values;
    let payload = values;
    if (curData.isInternationalShipment || showInternationalFields) {
      payload = generateInternationalPayload({
        values,
        customsState,
        orderItems,
        taxIdentifiersState,
      });
    }
    payload.advancedOptions = generateAdvancedOptions(
      values,
      hasAdvancedOptions,
    );
    payload.billToContact = generateBillToContact(_values);
    if (typeof customSubmit === 'function') {
      const carrierName = carrierCompanyList.filter(
        (s) => s.id + '' === values.companyID + '',
      );
      const selectedService = servicesList.filter(
        (s) => s.id === values.carrierServiceCode,
      );
      const fulfillmentType = fulfillmentTypes.filter(
        (s) => s.id === values.fulfillmentType,
      );
      payload.carrierName = carrierName?.[0]?.name;
      payload.carrierServiceName = selectedService?.[0]?.name;
      payload.fulfillmentTypeDisplay = fulfillmentType?.[0]?.name;
      customSubmit(payload);
      handleClose();
      return;
    }
    setSubmitting(true);
    const { error } = await dispatch(
      addResource({
        baseUrl: `/orders/${orderId}/carrier-info`,
        payload: {
          id: orderId,
          ...payload,
        },
        message: 'Changes saved',
        tokenOverride: adminToken,
      }),
    );
    setSubmitting(false);
    if (!error) {
      refresh();
      handleClose();
    }
  };

  const formik = useFormik({
    initialValues: { ...initialState, ...state, ...customsState },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const carrierType = getCarrierTypeFromFulfillment(
    formik.values.fulfillmentType,
  );

  const packageTypesOptions =
    useMemo(() => {
      if (carrierType !== 'SmallParcel') return null;
      let selectedCarrierCodes = [];
      if (formik.values.companyID) {
        selectedCarrierCodes =
          carrierCompanyList
            .filter((c) => c.id === formik.values.companyID)
            .map((c) => c.carrierCode) || [];
      }
      return getPackageTypesOptions(selectedCarrierCodes, carrierPackageTypes);
    }, [
      carrierCompanyList,
      carrierPackageTypes,
      carrierType,
      formik.values.companyID,
    ]) || [];

  const onAdminLogin = useCallback((data) => {
    setShowManagerLogin(false);
    // setShowConfirmation(true);
    setAdminToken(data);
  }, []);

  const handleOrderItems = useCallback(
    (customs, items, shouldSetShowFields = true) => {
      (async () => {
        const {
          internationalContents,
          internationalNonDelivery,
          taxIdentifiers,
          items,
        } = customs || {};

        setCustomsState({
          internationalContents: internationalContents ?? 'Merchandise',
          internationalNonDelivery:
            internationalNonDelivery ?? 'ReturnToSender',
        });
        let itemsData = {};

        if (items) {
          // convert to object for easier access
          items.forEach((d) => {
            const { quantity, ...rest } = d;
            const data = convertNullFieldsToEmptyString({ ...rest });
            // only set this fields if they were not imported from WMS
            if (!quantity) data.quantity = '';
            itemsData[d.customerItemID + ''] = data;
          });
        }
        const allItemsData = await getAllCustomsItemsData(items, itemsData);
        setOrderItems(allItemsData);

        if (taxIdentifiers) {
          const taxState = taxIdentifiers.reduce((acc, cur) => {
            acc[cur.guid] = cur;
            return acc;
          }, {});
          setTaxIdentifiersState(taxState);
        }

        shouldSetShowFields && setShowInternationalFields(true);
      })();
    },
    [getAllCustomsItemsData],
  );

  const fetchCarrierCompanies = useCallback(
    (_carrierType) => {
      (async () => {
        const currentCarrierType = _carrierType ?? carrierType;
        const { data } = await dispatch(
          getBasicCompaniesList({
            params: {
              carrierType: currentCarrierType,
              customerIDs: [curData.customerID],
              includeManualCarriers: true,
            },
          }),
        );
        if (data) {
          setCarrierCompanyList(data);
        }
      })();
    },
    [carrierType, curData.customerID, dispatch],
  );

  const fetchServices = useCallback(
    async (carrierId, _carrierType) => {
      if (carrierId) {
        const currentCarrierType = _carrierType ?? carrierType;
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/carriers',
            id: carrierId,
            path: 'services/basic',
            query: { carrierType: currentCarrierType },
          }),
        );
        if (data) {
          setServicesList(data);
        }
      }
    },
    [carrierType, dispatch],
  );

  useEffect(() => {
    (async function () {
      const carrierType = getCarrierTypeFromFulfillment(
        curData.fulfillmentType,
      );

      const promises = [fetchCarrierCompanies(carrierType)];
      const {
        companyID,
        carrierRequestedID,
        isInternationalShipment,
        customs,
        items,
        advancedOptions,
        billToContact: _billToContact,
      } = curData;
      !!companyID && promises.push(fetchCarrierAccounts(companyID));
      if (carrierRequestedID) {
        promises.push(fetchServices(carrierRequestedID, carrierType));
        promises.push(getAllCarrierPackageTypes());
      }

      if (isInternationalShipment) {
        promises.push(handleOrderItems(customs, items));
      }
      await Promise.all(promises);

      !!advancedOptions && setHasAdvancedOptions(true);
      const billToContact = _billToContact ?? {};
      const billToContactPerson = billToContact.person ?? {};
      const billToContactAddress = billToContactPerson.address ?? {};

      setState((cur) => ({
        ...cur,
        ...convertNullFieldsToEmptyString({
          companyID,
          fulfillmentType: curData.fulfillmentType ?? '',
          carrierID:
            curData.carrierRequestedID > 0 ? curData.carrierRequestedID : '',
          carrierServiceCode: curData.carrierServiceRequestedCode,
          carrierPackageCode: curData.carrierPackageCode,
          shippingSelectionPreference: curData.shippingSelectionPreference,
          thirdPartyAccountNumber: curData.thirdPartyAccountNumber,
          insuranceCost: curData.insuranceCost,
          customerID: curData.customerID,
          confirmationType: curData.confirmationType,
          billToParty: advancedOptions?.billToParty,
          dryIceWeight: advancedOptions?.dryIceWeight,
          billToContactCompanyName: billToContact.companyName,
          billToContactFirstName: billToContactPerson.firstName,
          billToContactLastName: billToContactPerson.lastName,
          billToContactAddress: billToContactAddress.address1,
          billToContactCity: billToContactAddress.city,
          billToContactStateId: billToContactAddress.stateID,
          billToContactZip: billToContactAddress.zip,
          billToContactCountryId: billToContactAddress.countryID,
          truckingJobPickupNote: curData.truckingJobPickupNote,
          truckingJobDestinationNote: curData.truckingJobDestinationNote,
        }),
        requiresDeliveryConfirmation:
          curData.requiresDeliveryConfirmation ?? false,
        containsAlcohol: advancedOptions?.ContainsAlcohol ?? false,
        deliveredDutyPaid: advancedOptions?.deliveredDutyPaid ?? false,
        nonMachinable: advancedOptions?.nonMachinable ?? false,
        saturdayDelivery: advancedOptions?.saturdayDelivery ?? false,
        dryIce: advancedOptions?.dryIce ?? false,
        billToContactId: billToContact.id,
        billToContactPersonId: billToContactPerson.personID,
      }));
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { handleChange, ...formikProps } = formik;

  const onChange = async (e) => {
    const { name, value } = e.target;

    if (formik.values[name] === value) return;

    if (name === 'fulfillmentType') {
      formik.setFieldValue('companyID', '');
      formik.setFieldValue('carrierID', '');
      formik.setFieldValue('carrierServiceCode', '');
      formik.setFieldValue('carrierPackageCode', '');
      setCarrierAccountsList([]);
      setServicesList([]);

      const newCarrierType = getCarrierTypeFromFulfillment(value);
      if (value && carrierType !== newCarrierType) {
        await fetchCarrierCompanies(newCarrierType);
      }
    }
    if (name === 'companyID') {
      formik.setFieldValue('carrierID', '');
      formik.setFieldValue('carrierServiceCode', '');
      formik.setFieldValue('carrierPackageCode', '');
      setCarrierAccountsList([]);
      setServicesList([]);
      !!value && (await fetchCarrierAccounts(value));
    }
    if (name === 'carrierID') {
      formik.setFieldValue('carrierServiceCode', '');
      !!value && (await fetchServices(value));
    }
    if (
      name === 'thirdPartyAccountNumber' &&
      !!formik.values.shippingSelectionPreference
    ) {
      formik.setFieldValue('shippingSelectionPreference', '');
    }
    handleChange(e);
  };

  const handleInternationalDataChange = useCallback((e, customerItemID) => {
    const { name, value } = e.target;
    if (customerItemID) {
      const key = customerItemID + '';
      setOrderItems((cur) => ({
        ...cur,
        [key]: {
          ...cur[key],
          [name]: value,
        },
      }));
    } else {
      setCustomsState((cur) => ({ ...cur, [name]: value }));
    }
  }, []);

  const handleAddTaxIdentifier = useCallback(() => {
    const newRecord = {
      internationalTaxEntity: defaultTaxEntityType,
      internationalTaxIdentifier: '',
      internationalTaxIdentifierValue: '',
      internationalTaxIdentifierIssuingAuthority: '',
    };
    setTaxIdentifiersState((cur) => ({ ...cur, [makeuuid(6)]: newRecord }));
  }, [defaultTaxEntityType]);

  const handleRemoveTaxIdentifier = useCallback((guid) => {
    setTaxIdentifiersState((cur) => {
      const { [guid]: toRemove, ...rest } = cur;
      return rest;
    });
  }, []);

  const handleTaxIdentifierChange = useCallback((e, guid) => {
    const { name, value } = e.target;
    setTaxIdentifiersState((cur) => ({
      ...cur,
      [guid]: {
        ...cur[guid],
        [name]: value,
      },
    }));
  }, []);

  const toggleShowCustoms = useCallback(() => {
    setShowInternationalFields((cur) => {
      if (cur) {
        setCustomsState({});
        setTaxIdentifiersState({});
      } else {
        setCustomsState({
          internationalContents: 'Merchandise',
          internationalNonDelivery: 'ReturnToSender',
        });
        if (!Object.keys(orderItems).length) {
          if (curData.customs) {
            handleOrderItems(curData.customs, curData.items, false);
          } else {
            getAllCustomsItemsData(curData.items).then((allItemsData) => {
              setOrderItems(allItemsData);
            });
          }
        }
      }
      return !cur;
    });
  }, [
    curData.customs,
    curData.items,
    getAllCustomsItemsData,
    handleOrderItems,
    orderItems,
  ]);

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Edit carrier info</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: showManagerLogin ? 468 : 720 } }}
      submitting={submitting}
      showManagerLogin={showManagerLogin}
      onAdminLogin={onAdminLogin}
    >
      <div className={classes.layout}>
        {isProcessing && (
          <InfoMessage
            message='Changing fulfillment type may reset to the beginning of the
          process'
          />
        )}
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='fulfillmentType'
              formikProps={formikProps}
              onChange={onChange}
              options={fulfillmentTypes}
              label='Fulfillment type'
              className={classes.input}
              disabled={isCustomer}
              clearable
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <AutocompleteInput
              formikProps={formik}
              textFieldProps={{
                name: 'companyID',
                onChange,
                label: 'Carrier',
                autoComplete: 'new-password',
                required: true,
              }}
              autocompleteProps={{
                options: carrierCompanyList,
              }}
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='carrierID'
              formikProps={formikProps}
              onChange={onChange}
              label='Carrier account'
              className={classes.input}
              options={carrierAccountsList}
              clearable
              required={!!formik.values.companyID}
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              key={formik.values.carrierID}
              name='carrierServiceCode'
              formikProps={formikProps}
              onChange={onChange}
              label='Service'
              className={classes.input}
              options={servicesList}
              clearable
              required={!!formik.values.companyID}
            />
          </Grid>
          {carrierType === 'SmallParcel' && (
            <Grid
              size={{
                sm: 6,
              }}
            >
              <SelectInput
                key={formik.values.carrierID}
                name='carrierPackageCode'
                formikProps={formikProps}
                onChange={onChange}
                label='Package type'
                className={classes.input}
                options={packageTypesOptions}
                clearable
              />
            </Grid>
          )}
          <Grid
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='shippingSelectionPreference'
              formikProps={formikProps}
              onChange={onChange}
              label='Preferred shipping method'
              className={classes.input}
              options={shippingSelectionPreferenceTypes}
              disabled={!!formik.values.thirdPartyAccountNumber}
              clearable
            />
          </Grid>
        </Grid>
        <div className={classes.sectionTitle}>Shipping options</div>
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='billToParty'
              formikProps={formik}
              label='Bill to'
              className={classes.input}
              options={
                carrierType === 'SmallParcel'
                  ? smallParcelBillToPartyOptions
                  : freightBillToPartyOptions
              }
              clearable
            />
          </Grid>
          {(carrierType === 'SmallParcel' ||
            !!curData.thirdPartyAccountNumber) && (
            <Grid
              size={{
                sm: 6,
              }}
            >
              <TextInput
                name='thirdPartyAccountNumber'
                formikProps={formikProps}
                onChange={onChange}
                label='Third party account'
                className={classes.input}
              />
            </Grid>
          )}
          <Grid
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='insuranceCost'
              formikProps={formikProps}
              onChange={onChange}
              label='Insurance amount'
              className={classes.input}
              format='currency'
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='confirmationType'
              formikProps={formikProps}
              onChange={onChange}
              label='Delivery confirmation type'
              className={classes.input}
              options={deliveryConfirmationTypes}
              clearable
            />
          </Grid>
        </Grid>
        {carrierType === 'Freight' &&
          formik.values.billToParty === 'ThirdParty' &&
          validOrderStatusForShipmentJob.includes(orderStatus) && (
            <Fragment>
              <div className={classes.sectionTitle}>Bill to contact</div>
              <ContactFields
                formikProps={formik}
                inputClassName={classes.input}
                inputNamePrefix='billToContact'
              />
            </Fragment>
          )}
        {carrierType === 'Freight' &&
          validOrderStatusForShipmentJob.includes(orderStatus) && (
            <Grid
              container
              spacing={5}
              rowSpacing={2}
              style={{ marginTop: 16 }}
            >
              <Grid
                size={{
                  sm: 12,
                }}
              >
                <TextInput
                  name='truckingJobPickupNote'
                  formikProps={formik}
                  label='Trucking job pickup instructions'
                  className={classes.input}
                  rows={3}
                  multiline
                />
              </Grid>
              <Grid
                size={{
                  sm: 12,
                }}
              >
                <TextInput
                  name='truckingJobDestinationNote'
                  formikProps={formik}
                  label='Trucking job pickup instructions'
                  className={classes.input}
                  rows={3}
                  multiline
                />
              </Grid>
            </Grid>
          )}
        {/* don't show advanced options for reship  */}
        {typeof customSubmit !== 'function' && (
          <Fragment>
            <div style={{ marginTop: 40 }}>
              <CheckboxInput
                formikProps={formik}
                checkboxes={[
                  {
                    labelProps: {
                      label: 'Signature required',
                    },
                    checkboxProps: {
                      name: 'requiresDeliveryConfirmation',
                    },
                  },
                ]}
              />
              <CheckboxInput
                formikProps={formik}
                checkboxes={[
                  {
                    labelProps: {
                      label: 'Contains alcohol',
                    },
                    checkboxProps: {
                      name: 'containsAlcohol',
                    },
                  },
                ]}
              />
              <CheckboxInput
                formikProps={formik}
                checkboxes={[
                  {
                    labelProps: {
                      label: 'Delivered duty paid',
                    },
                    checkboxProps: {
                      name: 'deliveredDutyPaid',
                    },
                  },
                ]}
              />
              <CheckboxInput
                formikProps={formik}
                checkboxes={[
                  {
                    labelProps: {
                      label: 'Non machinable',
                    },
                    checkboxProps: {
                      name: 'nonMachinable',
                    },
                  },
                ]}
              />
              <CheckboxInput
                formikProps={formik}
                checkboxes={[
                  {
                    labelProps: {
                      label: 'Saturday delivery',
                    },
                    checkboxProps: {
                      name: 'saturdayDelivery',
                    },
                  },
                ]}
              />
              <CheckboxInput
                formikProps={formik}
                checkboxes={[
                  {
                    labelProps: {
                      label: 'Dry ice',
                    },
                    checkboxProps: {
                      name: 'dryIce',
                    },
                  },
                ]}
              />
            </div>
            {formik.values.dryIce && (
              <Grid container spacing={5} rowSpacing={2}>
                <Grid
                  size={{
                    sm: 6,
                  }}
                >
                  <TextInput
                    name='dryIceWeight'
                    formikProps={formik}
                    label='dry ice weight'
                    className={classes.input}
                    type='number'
                  />
                </Grid>
              </Grid>
            )}
          </Fragment>
        )}
        {!curData.isInternationalShipment && (
          <div style={{ marginTop: 8 }}>
            <Button
              color='primary'
              className={classes.addBtn}
              onClick={toggleShowCustoms}
            >
              {`${showInternationalFields ? 'Remove ' : 'Add '}  customs`}
            </Button>
          </div>
        )}
        {(curData.isInternationalShipment || showInternationalFields) && (
          <Fragment>
            <div className={classes.sectionTitle}>
              International shipping options
            </div>
            <div className={classes.sectionTitle}>Customs</div>
            <Grid container spacing={5} rowSpacing={2}>
              <Grid
                size={{
                  sm: 6,
                }}
              >
                <SelectInput
                  name='internationalContents'
                  value={customsState.internationalContents ?? ''}
                  // formikProps={formik}
                  label='Contents'
                  onChange={handleInternationalDataChange}
                  className={classes.input}
                  options={customsContentsType}
                />
              </Grid>
              <Grid
                size={{
                  sm: 6,
                }}
              >
                <SelectInput
                  name='internationalNonDelivery'
                  value={customsState.internationalNonDelivery ?? ''}
                  // formikProps={formik}
                  label='Non delivery options'
                  onChange={handleInternationalDataChange}
                  className={classes.input}
                  options={customsNonDeliveryType}
                />
              </Grid>
            </Grid>
            {Object.keys(orderItems).map((customerItemID) => {
              const {
                sku,
                harmonizedTariffCode,
                cost,
                quantity,
                countryOfOriginID,
              } = orderItems[customerItemID];

              return (
                <Fragment key={customerItemID}>
                  <div className={classes.sku}>{sku}</div>
                  <Grid container spacing={5} rowSpacing={2}>
                    <Grid
                      size={{
                        sm: 6,
                      }}
                    >
                      <TextInput
                        name='harmonizedTariffCode'
                        value={harmonizedTariffCode}
                        // formikProps={formik}
                        label='Harmonized tariff code'
                        onChange={(e) =>
                          handleInternationalDataChange(e, customerItemID)
                        }
                        className={classes.input}
                      />
                    </Grid>
                    <Grid
                      size={{
                        sm: 6,
                      }}
                    >
                      <TextInput
                        name='cost'
                        value={cost}
                        // formikProps={formik}
                        label='Cost'
                        onChange={(e) =>
                          handleInternationalDataChange(e, customerItemID)
                        }
                        className={classes.input}
                        format='currency'
                      />
                    </Grid>
                    <Grid
                      size={{
                        sm: 6,
                      }}
                    >
                      <AutocompleteInput
                        // formikProps={formik}
                        value={countryOfOriginID + ''}
                        textFieldProps={{
                          name: 'countryOfOriginID',
                          label: 'country of origin',
                          autoComplete: 'new-password',
                          // value: countryOfOriginID + '',
                          onChange: (e) =>
                            handleInternationalDataChange(e, customerItemID),
                        }}
                        autocompleteProps={{
                          options: countries,
                        }}
                      />
                    </Grid>
                    {quantity !== undefined && (
                      <Grid
                        size={{
                          sm: 6,
                        }}
                      >
                        <TextInput
                          name='quantity'
                          value={quantity}
                          // formikProps={formik}
                          label='Quantity'
                          onChange={(e) =>
                            handleInternationalDataChange(e, customerItemID)
                          }
                          className={classes.input}
                          type='number'
                        />
                      </Grid>
                    )}
                  </Grid>
                </Fragment>
              );
            })}
            <div style={{ marginTop: 30 }}>
              <div className={classes.sectionTitle}>Tax identifiers</div>
              {Object.keys(taxIdentifiersState).map((guid) => {
                const {
                  internationalTaxEntity,
                  internationalTaxIdentifier,
                  internationalTaxIdentifierValue,
                  internationalTaxIdentifierIssuingAuthority,
                } = taxIdentifiersState[guid];

                return (
                  <Fragment key={guid}>
                    <Grid container spacing={5} rowSpacing={2}>
                      <Grid
                        size={{
                          sm: 6,
                        }}
                      >
                        <SelectInput
                          name='internationalTaxEntity'
                          value={internationalTaxEntity}
                          // formikProps={formik}
                          label='Tax entity'
                          onChange={(e) => handleTaxIdentifierChange(e, guid)}
                          className={classes.input}
                          options={taxEntityType}
                        />
                      </Grid>
                      <Grid
                        size={{
                          sm: 6,
                        }}
                      >
                        <SelectInput
                          name='internationalTaxIdentifier'
                          value={internationalTaxIdentifier}
                          // formikProps={formik}
                          label='Tax identifier'
                          onChange={(e) => handleTaxIdentifierChange(e, guid)}
                          className={classes.input}
                          options={taxIdentifierType}
                        />
                      </Grid>
                      <Grid
                        size={{
                          sm: 6,
                        }}
                      >
                        <TextInput
                          name='internationalTaxIdentifierValue'
                          value={internationalTaxIdentifierValue}
                          // formikProps={formik}
                          label='Value'
                          onChange={(e) => handleTaxIdentifierChange(e, guid)}
                          className={classes.input}
                        />
                      </Grid>
                      <Grid
                        size={{
                          sm: 6,
                        }}
                      >
                        <TextInput
                          name='internationalTaxIdentifierIssuingAuthority'
                          value={internationalTaxIdentifierIssuingAuthority}
                          // formikProps={formik}
                          label='Issuing authority'
                          onChange={(e) => handleTaxIdentifierChange(e, guid)}
                          className={classes.input}
                        />
                      </Grid>
                      <Grid
                        size={{
                          sm: 6,
                        }}
                      />
                      <Grid
                        size={{
                          sm: 6,
                        }}
                      >
                        <div className={classes.removeContainer}>
                          <ListDeleteIcon
                            onClick={() => handleRemoveTaxIdentifier(guid)}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                  </Fragment>
                );
              })}
            </div>
            <Button
              variant='text'
              color='primary'
              style={{ textTransform: 'none' }}
              size='small'
              onClick={handleAddTaxIdentifier}
            >
              Add tax identifier
            </Button>
          </Fragment>
        )}
      </div>
    </FormModal>
  );
}

const schema = object().shape({
  carrierID: string()
    .nullable()
    .when('companyID', (name, schema) => {
      return !!name ? schema.required('Required') : schema;
    }),
  carrierServiceCode: string()
    .nullable()
    .when('companyID', (name, schema) => {
      return !!name ? schema.required('Required') : schema;
    }),
  // required when there is a carrier but no preferred method
  // .when(['carrierID', 'shippingSelectionPreference'], {
  //   is: (carrierID, shippingSelectionPreference) =>
  //     !!carrierID && !shippingSelectionPreference,
  //   then: string().required('Required'),
  // }),
});

function generateInternationalPayload({
  values,
  customsState,
  orderItems,
  taxIdentifiersState,
}) {
  const items = Object.keys(orderItems).reduce((acc, cur) => {
    const { cost, quantity, ...rest } = orderItems[cur];
    const payload = { ...rest };
    if (cost) payload.cost = cost;
    if (quantity) payload.quantity = quantity;
    acc.push(payload);
    return acc;
  }, []);

  const taxIdentifiers = Object.keys(taxIdentifiersState).map(
    (k) => taxIdentifiersState[k],
  );

  const customs = {
    ...customsState,
    items,
    taxIdentifiers,
  };

  return { ...values, customs };
}

function generateAdvancedOptions(values, hasAdvancedOptions) {
  const {
    containsAlcohol,
    deliveredDutyPaid,
    nonMachinable,
    saturdayDelivery,
    dryIce,
    dryIceWeight,
    billToParty,
  } = values;

  // if there are no currently saved options and they don't add options
  if (
    !hasAdvancedOptions &&
    ![
      containsAlcohol,
      deliveredDutyPaid,
      nonMachinable,
      saturdayDelivery,
      dryIce,
      billToParty,
    ].some((d) => !!d)
  ) {
    return null;
  }

  const rv = {
    containsAlcohol,
    deliveredDutyPaid,
    nonMachinable,
    saturdayDelivery,
    dryIce,
    billToParty,
  };

  if (dryIce) {
    rv.dryIceWeight = dryIceWeight;
  }

  return rv;
}

export function generateBillToContact(values) {
  const {
    fulfillmentType,
    billToParty,
    billToContactFirstName,
    billToContactLastName,
    billToContactId,
    billToContactPersonId,
    billToContactCompanyName,
    billToContactAddress,
    billToContactCity,
    billToContactStateId,
    billToContactZip,
    billToContactCountryId,
  } = values;
  const carrierType = getCarrierTypeFromFulfillment(fulfillmentType);
  let contact = null;

  if (carrierType === 'Freight' && billToParty === 'ThirdParty') {
    contact = {
      id: billToContactId,
      companyName: billToContactCompanyName,
      person: {
        personID: billToContactPersonId,
        firstName: billToContactFirstName,
        lastName: billToContactLastName,
        address: {
          address1: billToContactAddress,
          city: billToContactCity,
          stateID: billToContactStateId,
          zip: billToContactZip,
          countryID: billToContactCountryId,
        },
      },
    };
  }
  return contact;
}

const useStyles = makeStyles({ name: { EditOrderCarrierForm } })((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
    padding: '24px 16px',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 50,
    marginBottom: 16,
  },
  sku: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 50,
    marginBottom: 8,
  },
  input: {
    width: '100%',
  },
  item: {
    paddingBottom: 0,
  },
  labelTitle: {
    fontWeight: 'normal',
  },
  removeContainer: {
    marginTop: 7,
    marginBottom: 7,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  divider: {
    borderColor: 'E5E7F5',
    marginTop: 30,
    marginBottom: 30,
    width: 788,
  },
  addBtn: {
    textTransform: 'none',
    marginTop: 10,
    marginBottom: 10,
  },
}));
