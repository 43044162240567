import { useCallback, useState, useEffect } from 'react';
import _get from 'lodash.get';
import {
  TextField,
  MenuItem,
  ListItemIcon,
  Checkbox,
  Chip,
} from '@mui/material';
import { CloseIcon } from '../../../assets';
import { useStyles } from './inputs.styles';

/**
 *
 * @param {import("@mui/material/TextField").TextFieldProps & otherProps} props
 * @returns
 */
export function SelectInput({
  name,
  value,
  useCustomValue = false,
  setValue,
  handleBlur,
  formikProps,
  onChange,
  onMouseEnter,
  onMouseLeave,
  placeholder,
  ignoreFormikOnchange,
  validationKey,
  ...otherProps
}) {
  const [focused, setFocused] = useState(false);

  const {
    handleChange: formikHandleChange,
    handleBlur: formikHandleBlur,
    values,
    touched,
    errors,
  } = formikProps || {};

  const hasMultiple = otherProps.slotProps?.select?.multiple;

  const handleChange = useCallback(
    (e) => {
      onChange && onChange(e);
      !!formikHandleChange &&
        !ignoreFormikOnchange &&
        !useCustomValue &&
        formikHandleChange(e);
      setValue && setValue(e.target.value);
    },
    [
      formikHandleChange,
      ignoreFormikOnchange,
      onChange,
      setValue,
      useCustomValue,
    ],
  );

  const onBlur = useCallback(
    (e) => {
      formikHandleBlur && formikHandleBlur(e);
      handleBlur && handleBlur(e);
      setFocused(false);
    },
    [formikHandleBlur, handleBlur],
  );

  const handleMouseEnter = useCallback(
    (e) => {
      onMouseEnter && onMouseEnter(e);
      setFocused(true);
    },
    [onMouseEnter],
  );

  const handleMouseLeave = useCallback(
    (e) => {
      onMouseLeave && onMouseLeave(e);
      setFocused(false);
    },
    [onMouseLeave],
  );

  const handleClear = useCallback(() => {
    const event = { target: { name, value: hasMultiple ? [] : '' } };
    handleChange(event);
  }, [handleChange, hasMultiple, name]);

  // On blur touched is set on the name but on submit it's set on the nested object so we need to check both
  const isTouched = !!(_get(touched, name) || _get(touched, validationKey));
  const errorText = _get(errors, validationKey ?? name);

  return (
    <RenderSelectInput
      name={name}
      value={
        formikProps !== undefined && !useCustomValue ? values[name] : value
      }
      onBlur={onBlur}
      onChange={handleChange}
      error={isTouched && !!errorText}
      helperText={isTouched ? errorText : undefined}
      handleClear={handleClear}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
      focused={focused}
      placeholder={placeholder}
      {...otherProps}
    />
  );
}

function RenderSelectInput({
  name,
  value,
  options,
  label,
  handleBlur,
  className,
  onChange,
  onBlur,
  placeholder,
  error,
  helperText,
  idField = 'id',
  displayField = 'name',
  clearable,
  handleClear,
  handleMouseEnter,
  handleMouseLeave,
  focused,
  slotProps: _slotProps,
  renderWith,
  ...otherProps
}) {
  const { classes, cx } = useStyles();
  const [valueObj, setValueObj] = useState({});

  const {
    input = {},
    select = {},
    inputLabel = {},
    ...slotProps
  } = _slotProps || {};
  const hasMultiple = select.multiple;

  useEffect(() => {
    if (select.multiple) {
      const objById = options.reduce((acc, cur) => {
        const { enumValue, id, name } = cur;
        const value = enumValue || id;
        acc[value + ''] = name;
        return acc;
      }, {});
      setValueObj(objById);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <TextField
      variant='standard'
      select
      name={name}
      value={value}
      label={label}
      onChange={onChange}
      onBlur={onBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cx(classes.select, className)}
      error={error}
      helperText={helperText}
      size='small'
      slotProps={{
        input: {
          endAdornment:
            clearable && focused && !!value ? (
              <CloseIcon
                onClick={handleClear}
                className={classes.selectClearIcon}
              />
            ) : undefined,
          size: 'small',
          ...input,
        },
        select: {
          MenuProps: {
            disableScrollLock: true, // need this to stop the menu from jumping
          },
          renderValue:
            placeholder && !value
              ? () => <span className={classes.placeholder}>{placeholder}</span>
              : hasMultiple
                ? renderWith === 'chip'
                  ? (selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={valueObj[value]}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )
                  : (selected) => selected.map((v) => valueObj[v]).join(', ')
                : undefined,
          ...select,
        },

        inputLabel: {
          className: classes.label,
          ...inputLabel,
        },
        ...slotProps,
      }}
      {...otherProps}
    >
      {/* {clearable && <MenuItem value=''>Clear</MenuItem>} */}
      {options?.map((c) => {
        const { [idField]: id, [displayField]: name } = c;
        return (
          <MenuItem key={id} value={id}>
            {hasMultiple && (
              <ListItemIcon>
                <Checkbox
                  size='small'
                  color='primary'
                  checked={value.includes(id)}
                />
              </ListItemIcon>
            )}
            {name}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

/**
 * @typedef {object} otherProps
 * @property {Array<import('../../../types').SelectOption>} options
 * @property {object} [formikProps]
 * @property {string} [displayField] The property in the list to use for populating the dropdown. Defaults to `name`
 * @property {string} [idField] The property in the list to use for the value to send to the API. Defaults to `id`
 * @property {Boolean} [clearable] If true, an empty option will be added to clear
 * @property {string|Array<string>} [validationKey] For validation of nested object
 * @property {boolean} [useCustomValue]
 * @property {'chip' | 'text'} [renderWith]
 */
