import { useCallback, useState, useEffect } from 'react';
import { Grid2 as Grid } from '@mui/material';
import { useFormik } from 'formik';
import { makeStyles } from '../../../themes';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import { useDispatch, addResource } from '../../../state';
import { useRefresh } from '../../../hooks';

interface Props {
  open: boolean;
  handleClose: () => any;
  receiptId: number;
  directiveOverrideMaxWeight?: number;
  directiveOverrideMaxHeight?: number;
  setViewVersion?: React.Dispatch<React.SetStateAction<number>>;
}

interface State {
  directiveOverrideMaxWeight?: number | '';
  directiveOverrideMaxHeight?: number | '';
}

const initialState: State = {
  directiveOverrideMaxHeight: '',
  directiveOverrideMaxWeight: '',
};

export function ReceiptMoveableUnitOverrideForm({
  open,
  handleClose,
  receiptId,
  directiveOverrideMaxHeight,
  directiveOverrideMaxWeight,
  setViewVersion,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState({
      directiveOverrideMaxHeight: directiveOverrideMaxHeight ?? '',
      directiveOverrideMaxWeight: directiveOverrideMaxWeight ?? '',
    });
  }, [directiveOverrideMaxHeight, directiveOverrideMaxWeight]);

  const handleSubmit = useCallback(
    async (values: State) => {
      setSubmitting(true);
      const payload = {
        ...values,
        receiptID: receiptId,
      };
      const { error } = await dispatch(
        addResource({
          baseUrl: `/receipts/${receiptId}/moveable-unit-override`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [receiptId],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>Moveable unit override specs</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 368 } }}
      submitting={submitting}
    >
      <Grid container spacing={5} rowSpacing={2}>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 12,
          }}
        >
          <TextInput
            name='directiveOverrideMaxHeight'
            formikProps={formik}
            label='Max height'
            className={classes.input}
            type='number'
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 12,
          }}
        >
          <TextInput
            name='directiveOverrideMaxWeight'
            formikProps={formik}
            label='Max weight'
            className={classes.input}
            type='number'
          />
        </Grid>
      </Grid>
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { ReceiptMoveableUnitOverrideForm } })(
  (theme) => ({
    title: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 600,
    },
    input: {
      marginBottom: 16,
      width: '100%',
    },
  }),
);
