import type { RootState } from '../types';

export const uiSelectors = {
  viewVersion: (state: RootState) => state.ui.viewVersion,
  listLoading: (state: RootState) => state.ui.listLoading,
  isSidebarOpen: (state: RootState) => state.ui.isSidebarOpen,
  alerts: (state: RootState) =>
    (state.ui.alerts || {}) as { [id: string | number]: Record<string, any> },
  shipmentLoadGuid: (state: RootState) => state.ui.shipmentLoadGuid,
  appointmentStatusChangedAlertGuid: (state: RootState) =>
    state.ui.appointmentStatusChangedAlertGuid,
  appointmentUnloadedAlertGuid: (state: RootState) =>
    state.ui.appointmentUnloadedAlertGuid,
  driverCheckInAlertGuid: (state: RootState) => state.ui.driverCheckInAlertGuid,
  smallParcelAlertGuid: (state: RootState) => state.ui.smallParcelAlertGuid,
  freightAlertGuid: (state: RootState) => state.ui.freightAlertGuid,
  showTimer: (state: RootState) => state.ui.showTimer,
  cameraEntityConfig: (state: RootState) => state.ui.cameraEntityConfig,
};
