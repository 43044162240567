import { jwtDecode } from 'jwt-decode';
import {
  setAuthRequestToken,
  removeAuthRequestToken,
  generateUrl,
  paths,
  Navigation,
  AuthResponseError,
  anonPost,
  anonGet,
} from '../../lib';
import {
  setUILoading,
  showError,
  showNotification,
  // showUpdated,
  updateLoginState as loginUser,
  persistUserData,
  loginFailed,
  removeUserData,
  setMfaInfo,
  invalidateCarrierCache,
} from '../features';
import type { AppThunk, NormalizeReturn } from '../types';
import { AuthData } from '../../types/systemTypes';
import { CompanyUserAPI, UserAPI } from '../../types/contactTypes';

interface Login {
  token: string;
  expiration: string;
  userName: string;
  firstName: string;
  lastName: string;
  permissionClaims: Array<string>;
  requiresMfa: boolean;
  mfaInfo?: {
    contactMethodType:
      | 'None'
      | 'Email'
      | 'OfficePhone'
      | 'Fax'
      | 'MobileNumber';
    maskedSentToValue: string;
  };
}

interface LoginResults<T = any> {
  data?: T;
  error?: AuthResponseError;
}

function createCompanyAccount(
  payload: CompanyUserAPI,
): AppThunk<Promise<NormalizeReturn<UserAPI>>> {
  return async (dispatch) => {
    const { data, error } = await anonPost<UserAPI>({
      url: 'accounts/company-user',
      data: payload,
    });
    if (error) {
      dispatch(
        showError({
          message:
            error.message ||
            error.Message ||
            error.title ||
            'An error occurred',
        }),
      );
      if (payload.company) {
        dispatch(invalidateCarrierCache('basicCarrierCompanies'));
      }
      return { error };
    }
    dispatch(showNotification({ message: 'Changes saved' }));
    return { data };
  };
}

function login({
  userName,
  password,
  recaptchaToken = null,
  isTemp = false,
}): AppThunk<Promise<NormalizeReturn<Login>>> {
  return async (dispatch, getState) => {
    const {
      auth: { afterLoginRedirect },
    } = getState();

    const { data, error } = await anonPost<Login>({
      url: ['auth/login/basic', { isTemp }],
      data: {
        userName,
        password,
        recaptchaToken,
      },
      requestConfig: {
        withCredentials: true,
      },
      logoutOnUnauthorized: !isTemp,
    });

    if (error) {
      if (!isTemp) {
        dispatch(loginFailed(error));
        dispatch(showError({ message: error.message || 'Login Failed' }));
      }
      return { error };
    }

    if (isTemp) {
      return { data };
    }

    const { token, expiration } = data;
    const authTokenData: object = jwtDecode(token);
    const userData = generateUserData({
      ...data,
      ...authTokenData,
    });

    if (userData.requiresMfa) {
      dispatch(setMfaInfo({ ...userData.mfaInfo, token }));
      Navigation.redirect('/mf-auth');
    } else {
      setAuthRequestToken(token, expiration);
      dispatch(persistUserData(userData));
      dispatch(loginUser(null));
      requestAnimationFrame(() => {
        window.location.replace(afterLoginRedirect || '/');
      });
    }
    return { data };
  };
}

function logout(): AppThunk {
  return async (dispatch) => {
    // HACK: Clear local storage since redux-persist isn't persisting this
    // even when running as a PWA...
    localStorage.clear();

    // CONSIDER: Tell the server that we're logging out so it can blacklist
    // the token until it expires...
    // const response = await authPost("/logout");
    // TODO: Make ajax call as shown above or delete mock response below.

    dispatch(removeUserData(null));
    removeAuthRequestToken();
    requestAnimationFrame(() => {
      window.location.replace(generateUrl(paths.LOGIN));
    });
  };
}

// function confirmAccount({ email, token, password1, password2 }) {
//   return async (dispatch) => {
//     dispatch(setUILoading(true));
//     // const response = await authPost("/api/auth/confirm", {
//     //   email,
//     //   newPassword: password1,
//     //   token,
//     // });
//     // TODO: Make ajax call as shown above and delete mock response below.
//     const response = {};
//     const { error } = response;
//     if (error) {
//       dispatch(loginFailed(error));
//       dispatch(setUILoading(false));
//       dispatch(showError({ message: 'Account Confirmation Failed' }));
//       return {
//         error,
//       };
//     }
//     const data = response.data;
//     const { token, expiration } = data;
//     setAuthRequestToken(token, expiration);
//     dispatch(loginUser(data));
//     // NOT setting loading false here, whole page will be reloaded.
//     requestAnimationFrame(() => {
//       window.location.reload();
//     });
//   };
// }

function mf_Auth({
  verificationCode,
  rememberMe,
}): AppThunk<Promise<LoginResults<Login>>> {
  return async (dispatch, getState) => {
    const {
      auth: { afterLoginRedirect, mfaInfo: { token = '' } = {} },
    } = getState();

    const { data, error } = await anonGet<Login>(
      ['auth/verifyMfa', { code: verificationCode, rememberMe }],
      undefined,
      undefined,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      },
    );
    if (error) {
      dispatch(loginFailed(error));
      dispatch(
        showError({
          message:
            error.status === 401
              ? 'Code is incorrect'
              : (error.message ?? error.title ?? 'Code is incorrect'),
        }),
      );
      return { error };
    } else {
      const { token, expiration } = data;
      const authTokenData: object = jwtDecode(token);

      const userData = generateUserData({
        ...data,
        ...authTokenData,
      });
      setAuthRequestToken(token, expiration);
      dispatch(persistUserData(userData));
      dispatch(loginUser(null));
      // need this to ensure that the data was persisted before reloading
      requestAnimationFrame(() => {
        window.location.replace(afterLoginRedirect || '/');
      });
      return { data };
    }
  };
}

function resendMfa(): AppThunk {
  return async (dispatch, getState) => {
    const {
      auth: { mfaInfo: { token = '' } = {} },
    } = getState();

    const { data, error } = await anonGet<Login>(
      'auth/sendMfa',
      undefined,
      undefined,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      },
    );

    if (error) {
      dispatch(
        showError({
          message:
            error.status === 401
              ? 'Error please try again'
              : (error.message ?? error.title ?? 'Code is incorrect'),
        }),
      );

      return { error };
    }

    dispatch(
      showNotification({
        message: 'Code has been resent',
      }),
    );

    return { data };
  };
}

function forgotPassword({ email }): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    dispatch(setUILoading(true));
    const response = await anonPost({
      url: '/accounts/sendSetPwEmail',
      data: {
        email,
        clientDomain: window.location.href.split('/').slice(0, 3).join('/'), // domain and port
      },
    });
    const { error } = response;
    if (error) {
      dispatch(
        showError({
          message:
            typeof error === 'string'
              ? error
              : error.message || 'Something went wrong',
        }),
      );
      dispatch(setUILoading(false));
      return { error };
    }
    dispatch(setUILoading(false));
    return {
      data: 'success',
    };
  };
}

function setPassword({ email, password, token, recaptchaToken }): AppThunk {
  return async (dispatch) => {
    if (!token) {
      return dispatch(showError({ message: 'Missing token' }));
    }
    const { data, error } = await anonPost({
      url: 'accounts/resetPw',
      data: {
        email,
        newPw: password,
        token,
      },
    });

    if (error) {
      dispatch(
        showError({
          message: error.message ?? error.title ?? 'An error occurred',
        }),
      );
      return { error };
    }

    dispatch(login({ password, userName: email, recaptchaToken }));
    return {
      data,
    };
  };
}

function getTempAdminToken({
  userName,
  password,
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { error, data } = await dispatch(
      login({ userName, password, isTemp: true }),
    );
    if (error) {
      dispatch(
        showError({
          message: error?.title ?? error?.message ?? 'Something went wrong',
        }),
      );
      return { error };
    }
    // TODO handle permissions when implemented
    return { token: data?.token };
  };
}

function generateUserData(data): AuthData {
  const {
    PermissionClaim,
    CustomerIdClaim,
    CanViewAccountingDetailsClaim,
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier':
      userId,
    UserTypeClaim: userType,
    UserWarehouseIdClaim,
    CompanyIdClaim,
  } = data;
  const permissionClaims = Array.isArray(PermissionClaim)
    ? PermissionClaim
    : [PermissionClaim];

  let isAdmin = false;
  let isProcessor = false;
  let isCustomerService = false;
  let isTruckingSalesPerson = false;
  let canViewOrderList = false;
  let canViewOrderDetails = false;
  let isScanner = false;
  let isScannerProcessor = false; // For a scanner that's also a processor
  let isManageAppointmentUser = false;
  let isDriverCheckInMode = false;
  let isManager = false;

  switch (userType) {
    case 'Admin':
      isAdmin = true;
      break;
    case 'CustomerService':
      isCustomerService = true;
      break;
    case 'TruckingSalesperson':
      isTruckingSalesPerson = true;
      break;
    case 'Processor':
      isProcessor = true;
      break;
    case 'Scanner':
      isScanner = true;
      break;
    case 'ScannerProcessor':
      isScannerProcessor = true;
      isProcessor = true;
      isScanner = true;
      break;
    case 'ManageAppointment':
      isManageAppointmentUser = true;
      break;
    case 'AppointmentCheckIn':
      isDriverCheckInMode = true;
      break;
    case 'Manager':
      isManager = true;
      break;
    default:
      break;
  }

  if (permissionClaims.includes('ViewOrder')) {
    canViewOrderDetails = true;
    canViewOrderList = true;
  }
  if (permissionClaims.includes('ViewOrderList')) {
    canViewOrderList = true;
  }

  return {
    ...data,
    // permissionClaim: permissionClaim,
    userType,
    userId: userId ? parseInt(userId) : undefined,
    customerId: CustomerIdClaim ? parseInt(CustomerIdClaim) : undefined,
    canViewAccountingDetails:
      !CustomerIdClaim || CanViewAccountingDetailsClaim === 'True',
    userWarehouseId: UserWarehouseIdClaim
      ? parseInt(UserWarehouseIdClaim)
      : undefined,
    isAdmin,
    isProcessor,
    isCustomerService,
    isTruckingSalesPerson,
    isCustomer: !!CustomerIdClaim,
    isManageAppointmentUser,
    canViewOrderList,
    canViewOrderDetails,
    isScanner,
    isScannerProcessor,
    isManager,
    isDriverCheckInMode,
    userCompanyId: CompanyIdClaim,
  };
}

export {
  createCompanyAccount,
  login,
  mf_Auth,
  logout,
  // confirmAccount,
  forgotPassword,
  setPassword,
  getTempAdminToken,
  resendMfa,
};
