import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'CameraUpload' })((theme) => ({
  layout: {
    position: 'relative',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('tablet')]: {
      // height: 'calc(100vh - 150px)',
      width: '100vw',
    },
  },
  mobileModal: {
    position: 'fixed',
    top: 0,
    zIndex: theme.zIndex.appBar + 1,
    background: '#FFFFFF',
  },
  barSpacer: {
    ...(theme.mixins.toolbar as CSSObject),
  },
  viewPadding: {
    padding: '0px 24px',
    [theme.breakpoints.down('tablet')]: {
      padding: '0px 8px',
    },
  },
  viewLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
      height: '100%',
      paddingTop: 40,
    },
  },
  // img: {
  //   maxWidth: '100%',
  //   [theme.breakpoints.down('tablet')]: {
  //     height: 'calc(100% - 120px)',
  //   },
  // },
  input: {
    marginBottom: 16,
    width: 300,
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
  },
  btn: {
    borderRadius: 3,
  },
  retakeBtn: {
    backgroundColor: '#43485B',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 28,
    fontWeight: 500,
    letterSpacing: 0,
    [theme.breakpoints.down('tablet')]: {
      paddingTop: 40,
      marginBottom: 12,
      textAlign: 'center',
      width: '100%',
    },
  },
  deleteBtn: {
    color: '#A5AABD',
    fontSize: 14,
    fontWeight: 600,
  },
  outerCircle: {
    height: 48,
    width: 48,
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 1,
    position: 'relative',
    borderRadius: '50%',
  },
  innerCircle: {
    left: 29,
    top: 29,
    height: 34,
    width: 34,
    background: '#FFFFFF',
    margin: '-22px 0px 0px -22px',
    zIndex: 2,
    position: 'absolute',
    borderRadius: '50%',
  },
  flash: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'white',
    zIndex: 999, //Ensure it's above other content during the flash
  },
}));
