import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid2 } from '@mui/material';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { FormikTimeInput, TextInput } from '../inputs';
import {
  useDispatch,
  updateWarehouseLeadTimeSettings,
  getWarehouseLeadTimesSettings,
} from '../../../state';
import { WarehouseLeadTimesSettingsAPI } from '../../../types';

interface Props {
  handleClose: () => any;
  open: boolean;
  warehouseId: number | string;
  setViewVersion: React.Dispatch<React.SetStateAction<number>>;
}

interface State {
  startOfDay: Date | string | null;
  endOfDay: Date | string | null;
  standardOrderCutoff: Date | string | null;
  expressOrderCutoff: Date | string | null;
  smallParcelFulfillmentTime: Date | string | null;
  freightFulfillmentDays: string | number;
  freightFulfillmentTime: Date | string | null;
}

const initialValues: State = {
  startOfDay: null,
  endOfDay: null,
  standardOrderCutoff: null,
  expressOrderCutoff: null,
  smallParcelFulfillmentTime: null,
  freightFulfillmentDays: '',
  freightFulfillmentTime: null,
};

export function WarehouseLeadTimesForm({
  handleClose,
  open,
  warehouseId,
  setViewVersion,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState<State>(initialValues);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    (async function () {
      if (warehouseId) {
        const { data } = await dispatch(
          getWarehouseLeadTimesSettings(warehouseId),
        );
        if (data) {
          setState((cur) => ({
            ...cur,
            ...data,
            freightFulfillmentDays: data.freightFulfillmentDays ?? '',
          }));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const { error } = await dispatch(
        updateWarehouseLeadTimeSettings(warehouseId, {
          ...values,
          warehouseID: warehouseId,
        } as WarehouseLeadTimesSettingsAPI),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion && setViewVersion((cur) => ++cur);
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [warehouseId],
  );

  const formik = useFormik({
    initialValues: { ...initialValues, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>Warehouse hours and lead times</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      <div className={classes.sectionTitle}>Warehouse hours</div>
      <Grid2 container spacing={5} rowSpacing={2} marginBottom={1}>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <FormikTimeInput
            name='startOfDay'
            label='Start of day'
            formikProps={formik}
            className={classes.input}
          />
        </Grid2>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <FormikTimeInput
            name='endOfDay'
            label='End of day'
            formikProps={formik}
            className={classes.input}
          />
        </Grid2>
      </Grid2>
      <div className={classes.sectionTitle}>Lead times</div>
      <Grid2 container spacing={5} rowSpacing={2}>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <FormikTimeInput
            name='standardOrderCutoff'
            label='Standard order cutoff'
            formikProps={formik}
            className={classes.input}
          />
        </Grid2>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <FormikTimeInput
            name='expressOrderCutoff'
            label='Express order cutoff'
            formikProps={formik}
            className={classes.input}
          />
        </Grid2>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <FormikTimeInput
            name='smallParcelFulfillmentTime'
            label='Small parcel fulfillment time'
            formikProps={formik}
            className={classes.input}
          />
        </Grid2>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='freightFulfillmentDays'
            label='Freight fulfillment days'
            formikProps={formik}
            className={classes.input}
            type='number'
          />
        </Grid2>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <FormikTimeInput
            name='freightFulfillmentTime'
            label='Freight fulfillment time'
            formikProps={formik}
            className={classes.input}
          />
        </Grid2>
      </Grid2>
    </FormModal>
  );
}

const schema = object().shape({
  startOfDay: string().nullable(),
  endOfDay: string().nullable(),
  standardOrderCutoff: string().nullable(),
  expressOrderCutoff: string().nullable(),
  smallParcelFulfillmentTime: string().nullable(),
  freightFulfillmentDays: string().nullable(),
  freightFulfillmentTime: string().nullable(),
});

const useStyles = makeStyles({ name: { WarehouseLeadTimesForm } })((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 24,
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0,
    marginBottom: 8,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));
