import { useEffect, useState, useCallback, ReactNode } from 'react';
import { TablePagination, Table, TableFooter, TableRow } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CSSObject } from 'tss-react';
import { arrayToObjByEnumOrId, authGet } from '../../../lib';
import { useNotify, useLoader, useIsMobile } from '../../../hooks';
import { ListLoading } from '../ListLoading';

import type { ObjectWithId } from '../../../types';

type PagingInfoType = { rowsPerPage: number; pageNumber: number };

interface Props<TData extends ObjectWithId = ObjectWithId> {
  url: string;
  rowsPerPageOptions?: Array<number>;
  setRows: (data: TData) => any;
  setIds: (ids: Array<number>) => any;
  getPagingInfo?: (info: PagingInfoType) => any;
  getResponseData?: (data: any) => any;
  filters?: Record<string, any>;
  children?: ReactNode;
  className?: string;
  defaultState?: ReactNode | null;
  viewVersion?: number;
}

export const PagedList = <TData extends ObjectWithId = ObjectWithId>({
  children,
  className,
  defaultState = null,
  rowsPerPageOptions,
  setRows,
  setIds,
  url,
  filters,
  getPagingInfo,
  getResponseData,
  viewVersion,
}: Props<TData>) => {
  const notify = useNotify();
  const setLoading = useLoader();
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [numberOfRows, setNumberOfRows] = useState(-1);
  const [listLoading, setListLoading] = useState(true); // for first load
  const [hasResults, setHasResults] = useState(true); // for first load

  useEffect(() => {
    (async function () {
      !listLoading && setLoading(true);
      const { data, error } = await authGet([
        url,
        {
          perPage: rowsPerPage,
          pageNumber: page + 1, // the server is 1 based
          ...(filters || {}),
        },
      ]);
      !listLoading && setLoading(false);
      setListLoading(false);
      if (error) {
        notify(
          error.message || error.title || 'Failed to fetch the data',
          'error',
        );
      } else if (data) {
        const sortIds = [];
        const { results, numberOfRows: _numberOfRows } = data;
        if (_numberOfRows === 0) {
          setHasResults(false);
        } else {
          setRows(arrayToObjByEnumOrId(results, sortIds));
          setIds(sortIds);
          setNumberOfRows(_numberOfRows);
          getPagingInfo && getPagingInfo({ rowsPerPage, pageNumber: page + 1 });
          getResponseData && getResponseData(data);
          setHasResults(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, url, viewVersion]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
    // window.scrollTo(0, 0);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    // window.scrollTo(0, 0);
  }, []);

  return listLoading ? (
    <ListLoading
      classes={classes}
      // className={paperClassName}
      // expand={expand}
      hasBulkActions={false}
      nbChildren={20}
      size='small'
    />
  ) : !hasResults ? (
    defaultState ? (
      defaultState
    ) : null
  ) : (
    <Table
      table-layout='fixed'
      white-space='nowrap'
      className={cx(classes.table, className)}
    >
      {children}
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions || [10, 20, 30]}
            count={numberOfRows}
            rowsPerPage={rowsPerPage || 10}
            page={page || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={isMobile ? 'Per page:' : undefined}
            classes={{
              selectLabel: classes.pagination,
              displayedRows: classes.pagination,
              select: classes.pagination,
            }}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

const useStyles = makeStyles()((theme) => ({
  table: {
    // '& th:first-of-type': { paddingLeft: 0 },
    // '& th:first-child': { paddingLeft: 0 },
    '& th': { fontSize: 12 },
    // '& tr > td:first-of-type': { paddingLeft: 0 },
    // '& tr > td:first-child': { paddingLeft: 0 },
    '& tr td span': { fontFamily: 'Montserrat', fontSize: 14 },
  },
  pagination: {
    ...(theme.typography.body2 as CSSObject),
    fontSize: 14,
  },
}));
