import React, { memo, useMemo, useState, useCallback } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useStyles } from './actionsTabs.styles';

export const ActionsTabs = memo(function ActionsTabs({
  tabs: _tabs,
  tabPosition,
  onClick,
  style: _style,
}) {
  const { classes } = useStyles();
  const [style] = useState(_style);
  const tabs = useMemo(() => _tabs, [_tabs]);

  const handleChange = useCallback(
    (event, newValue) => {
      onClick(event, newValue);
    },
    [onClick],
  );

  const tabClasses = useMemo(
    () => ({
      root: classes.root,
      selected: classes.selected,
    }),
    [classes.root, classes.selected],
  );

  if (!tabPosition) return null;
  return (
    <Tabs
      value={tabPosition}
      onChange={handleChange}
      classes={{ indicator: classes.indicator }}
      style={style}
    >
      {tabs.map((t, i) => {
        const { name, value } = t;
        return (
          <Tab
            key={i}
            value={value}
            label={name}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            classes={tabClasses}
          />
        );
      })}
    </Tabs>
  );
});
