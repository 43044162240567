import { ArrowBackIcon } from '../../assets';
import { IconButtonLink } from '../common/ButtonLink';
import { makeStyles } from 'tss-react/mui';

interface TitleLinkProps {
  route: string;
  title: string;
}

export function TitleLink({ route, title }: TitleLinkProps) {
  const { classes } = useStyles();
  return (
    <div className={classes.pageHeader}>
      <IconButtonLink to={route} className={classes.backIcon} size='small'>
        <ArrowBackIcon />
      </IconButtonLink>
      <span>{title}</span>
    </div>
  );
}

const useStyles = makeStyles({ name: { TitleLink } })((theme) => ({
  pageHeader: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 40,
  },
  backIcon: {
    color: 'inherit',
    marginRight: 7,
  },
}));
