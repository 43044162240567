import { Tooltip } from '@mui/material';
import { limitStringSize } from '../../lib';

interface Props {
  text: string;
  maxLength: number;
  styleProps?: React.HTMLAttributes<HTMLSpanElement>;
  className?: string;
}

export function LongTextHoverTooltip({
  text = '',
  maxLength,
  className,
  ...styleProps
}: Props) {
  const truncated = limitStringSize(text, maxLength, true);
  const showTooltip = text?.length > maxLength;

  return (
    <Tooltip title={showTooltip ? text : ''}>
      <span {...styleProps} className={className}>
        {truncated}
      </span>
    </Tooltip>
  );
}
