import React, { Fragment } from 'react';
import { Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { BasicDialog } from './BasicDialog';
import { CloseIcon } from '../../../assets';

export function ProcessOrderNotes({ open, handleClose, notes }) {
  const { classes } = useStyles();

  return (
    <BasicDialog
      open={open}
      handleClose={handleClose}
      maxWidth='md'
      cancelBtnText='Close'
      disableScrollLock={false}
      hideCloseBtn
      title={
        <div className={classes.titleContainer}>
          <span className={classes.titleText}> Note </span>
          <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
        </div>
      }
    >
      {notes.map((n) => {
        const { id, text } = n;
        return (
          <Fragment key={id}>
            <p className={classes.text}>{text}</p>
            <Divider className={classes.divider} />
          </Fragment>
        );
      })}
    </BasicDialog>
  );
}

const useStyles = makeStyles()((theme) => ({
  text: {
    fontSize: 16,
  },
  divider: {
    color: '#E5E7F5',
    borderColor: '#E5E7F5',
    margin: '24px 0',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleText: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
}));
