import { Fragment, useCallback, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import {
  generateOrderPayload,
  generateFreightOrderPayload,
} from '../../../pages/processOrder/generateProcessPayload';
import {
  useSelector,
  useDispatch,
  processOrder,
  processFreightOrder,
  orderSelectors,
} from '../../../state';
import { TextInput } from '../../../components';
import { useNotify } from '../../../hooks';
import { PauseIcon } from '../../../assets';
import { orderStatuses, generateUrl, paths, Navigation } from '../../../lib';
import { FormModal } from '../..';

const initialState = {
  reason: '',
};

export function PauseOrder() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const selectedOrder = useSelector(orderSelectors.selectedOrder);
  const packages = useSelector(orderSelectors.packages);
  const physicalItems = useSelector(orderSelectors.physicalItems);
  const hasUnsealedPackage = useSelector(orderSelectors.hasUnsealedPackage);
  const hasUnsealedPallet = useSelector(orderSelectors.hasUnsealedPallet);
  const hasPallets = useSelector(orderSelectors.hasPallets);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleShowModal = useCallback(() => {
    setShowConfirmation(true);
  }, []);

  const handlePauseOrder = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = selectedOrder.isFreight
        ? generateFreightOrderPayload({
            packages,
            allPhysicalItems: physicalItems,
            orderData: selectedOrder,
            status: orderStatuses.PAUSED,
            isFreightPalletMode: hasPallets,
          })
        : generateOrderPayload({
            packages,
            allPhysicalItems: physicalItems,
            orderData: selectedOrder,
            status: orderStatuses.PAUSED,
          });

      payload.note = values.reason;

      if (selectedOrder.isFreight) {
        payload.freightContainerType = hasPallets ? 'Pallet' : 'Box';
      }

      const { error } = selectedOrder.isFreight
        ? await dispatch(processFreightOrder({ payload }))
        : await dispatch(processOrder({ payload }));
      setSubmitting(false);
      if (error) {
        return notify(
          error.message || error.title || 'Something went wrong',
          'error',
        );
      }
      notify('Order successfully paused');
      Navigation.redirect(generateUrl(paths.SCAN_ORDER));
    },
    [dispatch, hasPallets, notify, packages, physicalItems, selectedOrder],
  );

  const handleCancel = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handlePauseOrder,
    validationSchema: schema,
  });

  return (
    <Fragment>
      <Tooltip title='Pause processing' placement='bottom'>
        <Button
          color='secondary'
          className={classes.actionButton}
          size='small'
          onClick={handleShowModal}
          disabled={hasUnsealedPackage || hasUnsealedPallet}
        >
          <PauseIcon fontSize='small' />
        </Button>
      </Tooltip>
      <FormModal
        open={showConfirmation}
        handleClose={handleCancel}
        title='Are you sure you want to pause this order?'
        callback={formik.handleSubmit}
        btnText='SAVE'
        paperProps={{ style: { width: 468 } }}
        submitting={submitting}
        typeSubmit
      >
        <TextInput
          name='reason'
          formikProps={formik}
          label='Reason'
          multiline
          rows={3}
          required
        />
      </FormModal>
    </Fragment>
  );
}

const useStyles = makeStyles({ name: { PauseOrder } })((theme) => ({
  actionButton: {
    width: 32,
    height: 32,
    minWidth: 'unset',
    backgroundColor: '#F5F6FE',
  },
}));

const schema = object().shape({
  reason: string().required('Required'),
});
