export function validateSelectedItem({
  value,
  upcSkuObj: _upcSkuObj,
  originalItems = {},
  itemsCompleted = {},
  itemsRemaining = {},
  currentPackage = {},
}) {
  const upcSkuObj = _upcSkuObj || {}; // it could be null
  let sku = value;
  // check if they scanned a UPC
  if (!originalItems[sku]) {
    sku = upcSkuObj[value];
  }
  const selectedItem = originalItems[sku];
  if (!selectedItem) {
    return {
      error: 'There were no results found with the item number entered.',
      isNotFound: true,
    };
  }
  if (!itemsRemaining[sku]) {
    return {
      error: 'This item was already completed and cannot be processed again.',
    };
  }
  // if (currentPackage.items?.[sku]) {
  //   return {
  //     error: 'This item was already scanned.',
  //   };
  // }
  return { data: sku };
}

export function validateSelectedMaterial({ value }) {
  if (!value) {
    return {
      error: 'There were no results found with the item number entered.',
      isNotFound: true,
    };
  }
  // TODO add logic
  return { data: value };
}
