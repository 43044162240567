import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { formatISO } from 'date-fns';
import { IdNamePair } from '../../types/globalTypes';
import { UsersForActivityType } from '../../types/contactTypes';

interface AccountState {
  basicUsers: Array<IdNamePair>;
  usersForActivity: Array<UsersForActivityType>;
  lastFetchedBasicUsersOn: string | null;
  lastFetchedUsersForActivityOn: string | null;
}

const initialState: AccountState = {
  basicUsers: [],
  usersForActivity: [],
  lastFetchedBasicUsersOn: null,
  lastFetchedUsersForActivityOn: null,
};

const contacts = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setBasicUsers(state, action: PayloadAction<Array<IdNamePair>>) {
      state.basicUsers = action.payload;
      state.lastFetchedBasicUsersOn = formatISO(new Date());
    },
    setUsersForActivity(
      state,
      action: PayloadAction<Array<UsersForActivityType>>,
    ) {
      state.usersForActivity = action.payload;
      state.lastFetchedUsersForActivityOn = formatISO(new Date());
    },
  },
});

export const { setBasicUsers, setUsersForActivity } = contacts.actions;

export const ContactReducer = contacts.reducer;
