import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AlertColor } from '@mui/material';
import { CameraApiConfig } from '../../lib/systemUtils';

interface Notification {
  message: string;
  variant?: AlertColor;
  duration?: number;
}

interface UiState {
  loading: boolean;
  listLoading: false;
  isSidebarOpen: boolean;
  viewVersion: number;
  shipmentLoadGuid?: string | null;
  smallParcelAlertGuid?: string | null;
  freightAlertGuid?: string | null;
  appointmentStatusChangedAlertGuid?: string | null;
  appointmentUnloadedAlertGuid?: string | null;
  driverCheckInAlertGuid?: string | null;
  orderProcessingScheduleWarehouseId?: number | null;
  alerts: { [id: string | number]: Record<string, any> };
  notification?: Notification;
  showTimer: boolean;
  cameraEntityConfig: CameraApiConfig | null;
}

const initialState: UiState = {
  loading: false,
  listLoading: false,
  isSidebarOpen: true,
  viewVersion: 1,
  shipmentLoadGuid: null,
  smallParcelAlertGuid: null,
  freightAlertGuid: null,
  appointmentUnloadedAlertGuid: null,
  appointmentStatusChangedAlertGuid: null,
  orderProcessingScheduleWarehouseId: null,
  showTimer: false,
  cameraEntityConfig: null,
  alerts: {
    // first: {
    //   title: 'Batch {Batch Customer - Time Stamp} Completed!',
    //   message:
    //     'Your batch {Batch Name - Customer - Time Stamp} is ready for printing. This batch includes {count of orders with errors } errors that should be reviewed',
    //   pageLink: '/orders',
    // },
    // second: {
    //   title: 'Batch {Batch Customer - Time Stamp} Completed!',
    //   message:
    //     'Your batch {Batch Name - Customer - Time Stamp} is ready for printing. This batch includes {count of orders with errors } errors that should be reviewed',
    //   pageLink: '/orders',
    // },
    // third: {
    //   title: 'Batch {Batch Customer - Time Stamp} Completed!',
    //   message:
    //     'Your batch {Batch Name - Customer - Time Stamp} is ready for printing. This batch includes {count of orders with errors } errors that should be reviewed',
    //   pageLink: '/orders',
    // },
    // fourth: {
    //   title: 'Batch {Batch Customer - Time Stamp} Completed!',
    //   message:
    //     'Your batch {Batch Name - Customer - Time Stamp} is ready for printing. This batch includes {count of orders with errors } errors that should be reviewed',
    //   pageLink: '/orders',
    // },
    // fifth: {
    //   title: 'Batch {Batch Customer - Time Stamp} Completed!',
    //   message:
    //     'Your batch {Batch Name - Customer - Time Stamp} is ready for printing. This batch includes {count of orders with errors } errors that should be reviewed',
    //   pageLink: '/orders',
    // },
    // six: {
    //   title: 'Batch {Batch Customer - Time Stamp} Completed!',
    //   message:
    //     'Your batch {Batch Name - Customer - Time Stamp} is ready for printing. This batch includes {count of orders with errors } errors that should be reviewed',
    //   pageLink: '/orders',
    // },
    // seven: {
    //   title: 'Batch {Batch Customer - Time Stamp} Completed!',
    //   message:
    //     'Your batch {Batch Name - Customer - Time Stamp} is ready for printing. This batch includes {count of orders with errors } errors that should be reviewed',
    //   pageLink: '/orders',
    // },
    // eight: {
    //   title: 'Batch {Batch Customer - Time Stamp} Completed!',
    //   message:
    //     'Your batch {Batch Name - Customer - Time Stamp} is ready for printing. This batch includes {count of orders with errors } errors that should be reviewed',
    //   pageLink: '/orders',
    // },
  },
};

const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    hideNotification(state, action) {
      state.notification = undefined;
    },
    showNotification(state, action: PayloadAction<Notification>) {
      const { message, variant, duration: _duration } = action.payload;
      let duration = _duration;
      if (duration === undefined && variant !== 'error') {
        duration = 6000;
      }
      state.notification = { message, variant, duration };
    },
    showUpdated(state, action) {
      const { message = 'Your changes have been submitted.' } = action.payload;
      state.notification = { message };
    },
    showError(state, action) {
      const { message = 'There was an error processing your request.' } =
        action.payload || {};
      state.notification = { message, variant: 'error' };
    },
    setUILoading(state, action) {
      state.loading = action.payload;
    },
    setListLoading(state, action) {
      state.listLoading = action.payload;
    },
    toggleSidebar(state, action) {
      state.isSidebarOpen = action.payload;
    },
    toggleTimer(state, action) {
      state.showTimer = action.payload;
    },
    setCameraEntityConfig(
      state,
      action: PayloadAction<CameraApiConfig | null>,
    ) {
      state.cameraEntityConfig = action.payload;
    },
    incrementViewVersion(state, action) {
      state.viewVersion = state.viewVersion + 1;
    },
    addAlert(state, action) {
      const { id, value } = action.payload;
      state.alerts = { ...state.alerts, [id]: value };
    },
    removeAlert(
      state,
      action: PayloadAction<{ [id: string | number]: string }>,
    ) {
      const { id } = action.payload;
      const { [id]: alertToDelete, ...newState } = state.alerts;
      state.alerts = newState;
    },
    removeAllAlerts(state, action) {
      state.alerts = {};
    },
    setShipmentLoadGuid(state, action) {
      state.shipmentLoadGuid = action.payload;
    },
    setSmallParcelOrderAlertGuid(state, action) {
      state.smallParcelAlertGuid = action.payload;
    },
    setFreightOrderAlertGuid(state, action) {
      state.freightAlertGuid = action.payload;
    },
    setAppointmentStatusChangedAlertGuid(state, action) {
      state.appointmentStatusChangedAlertGuid = action.payload;
    },
    setAppointmentUnloadedAlertGuid(state, action) {
      state.appointmentUnloadedAlertGuid = action.payload;
    },
    setDriverCheckedInAlertGuid(state, action) {
      state.driverCheckInAlertGuid = action.payload;
    },
    setOrderProcessingScheduleWarehouseId(state, action) {
      state.orderProcessingScheduleWarehouseId = action.payload;
    },
  },
});

export const {
  hideNotification,
  showNotification,
  showUpdated,
  showError,
  setUILoading,
  setListLoading,
  toggleSidebar,
  toggleTimer,
  incrementViewVersion,
  addAlert,
  removeAlert,
  removeAllAlerts,
  setShipmentLoadGuid,
  setSmallParcelOrderAlertGuid,
  setFreightOrderAlertGuid,
  setAppointmentStatusChangedAlertGuid,
  setAppointmentUnloadedAlertGuid,
  setDriverCheckedInAlertGuid,
  setOrderProcessingScheduleWarehouseId,
  setCameraEntityConfig,
} = ui.actions;

export const uiReducer = ui.reducer;
