import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { incrementViewVersion } from '../state';

export function useRefresh() {
  const dispatch = useDispatch();
  return useCallback(
    (doRefresh = true) => doRefresh && dispatch(incrementViewVersion()),
    [dispatch],
  );
}
