import { Fragment } from 'react';
import {
  paths,
  generateValidatedParamsUrl,
  isProduction,
  pathParams,
  stockViewTypes,
} from '../../../lib';
import {
  CalendarTodayIcon,
  InventoryIcon,
  ReceiptLongIcon,
} from '../../../assets';
import { SidebarItem } from '../SidebarItem';
import { NestedList } from '../NestedList';

export function ManagerUserSidebar() {
  return (
    <Fragment>
      {!isProduction && (
        <SidebarItem
          text='Receipts'
          to={generateValidatedParamsUrl(paths.RECEIPTS)}
          icon={ReceiptLongIcon}
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.RECEIPTS}`)
          }
        />
      )}
      <NestedList text='Inventory' icon={InventoryIcon} id='inventory'>
        <SidebarItem
          text='Stock'
          to={generateValidatedParamsUrl(paths.STOCK, {
            [pathParams.VIEW_TYPE]: stockViewTypes.DETAILS,
          })}
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.STOCK}`)
          }
        />
        <SidebarItem
          text='Locations'
          isNested
          to={generateValidatedParamsUrl(paths.LOCATIONS)}
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.LOCATIONS}`)
          }
        />
      </NestedList>
      <SidebarItem
        to={generateValidatedParamsUrl(paths.APPOINTMENTS)}
        text='Appointments'
        icon={CalendarTodayIcon}
        isActive={() =>
          window.location.pathname.startsWith(
            generateValidatedParamsUrl(paths.APPOINTMENTS),
          )
        }
      />
    </Fragment>
  );
}
