import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'fi-in' })((theme) => ({
  container: {
    width: '100%',
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#A5AABD',
    fontFamily: 'Montserrat',
    fontSize: 14,
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  active: {
    borderColor: '#2196f3',
  },
  accepted: {
    borderColor: '#00e676',
  },
  rejected: {
    borderColor: '#ff1744',
  },
}));
