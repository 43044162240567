import React, { useCallback, useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Grid2 as Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { SelectInput, TextInput } from '../inputs';
import { addResource } from '../../../state';
import { useRefresh } from '../../../hooks';
import {
  convertNullFieldsToEmptyString,
  smallParcelBillToPartyOptions,
  freightBillToPartyOptions,
  validOrderStatusForShipmentJob,
} from '../../../lib';
import { ContactFields } from './ContactFields';
import { generateBillToContact } from './EditOrderCarrierForm';

const initialState = {
  billToParty: '',
  billToContactCompanyName: '',
  billToContactFirstName: '',
  billToContactLastName: '',
  billToContactAddress: '',
  billToContactCity: '',
  billToContactStateId: '',
  billToContactZip: '',
  billToContactCountryId: '',
  truckingJobPickupNote: '',
  truckingJobDestinationNote: '',
};

export function CustomerEditOrderCarrierForm({
  open,
  handleClose,
  curData,
  orderId,
  orderStatus,
  customerId,
  carrierType,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  const handleSubmit = useCallback(
    async (_values) => {
      const {
        billToContactId,
        billToContactPersonId,
        billToContactFirstName,
        billToContactLastName,
        billToContactAddress,
        billToContactCity,
        billToContactStateId,
        billToContactZip,
        billToContactCountryId,
        billToParty,
        ...values
      } = _values;
      const payload = values;
      payload.billToContact = generateBillToContact(_values);
      if (billToParty) {
        payload.advancedOptions = { billToParty };
      }
      setSubmitting(true);
      const { error } = await dispatch(
        addResource({
          baseUrl: `/customers/${customerId}/orders/${orderId}/carrier-info`,
          payload: {
            id: orderId,
            ...payload,
          },
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [customerId, dispatch, handleClose, orderId, refresh],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  useEffect(() => {
    const { advancedOptions, billToContact: _billToContact } = curData;

    const billToContact = _billToContact ?? {};
    const billToContactPerson = billToContact.person ?? {};
    const billToContactAddress = billToContactPerson.address ?? {};

    setState((cur) => ({
      ...cur,
      ...convertNullFieldsToEmptyString({
        fulfillmentType: curData.fulfillmentType,
        customerID: curData.customerID,
        truckingJobPickupNote: curData.truckingJobPickupNote,
        truckingJobDestinationNote: curData.truckingJobDestinationNote,
        billToParty: advancedOptions?.billToParty,
        billToContactCompanyName: billToContact.companyName,
        billToContactFirstName: billToContactPerson.firstName,
        billToContactLastName: billToContactPerson.lastName,
        billToContactAddress: billToContactAddress.address1,
        billToContactCity: billToContactAddress.city,
        billToContactStateId: billToContactAddress.stateID,
        billToContactZip: billToContactAddress.zip,
        billToContactCountryId: billToContactAddress.countryID,
      }),
      billToContactId: billToContact.id,
      billToContactPersonId: billToContactPerson.personID,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Edit carrier info</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      <div className={classes.layout}>
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='billToParty'
              formikProps={formik}
              label='Bill to'
              className={classes.input}
              options={
                carrierType === 'SmallParcel'
                  ? smallParcelBillToPartyOptions
                  : freightBillToPartyOptions
              }
              clearable
            />
          </Grid>
        </Grid>
        {carrierType === 'Freight' &&
          formik.values.billToParty === 'ThirdParty' &&
          validOrderStatusForShipmentJob.includes(orderStatus) && (
            <Fragment>
              <div className={classes.sectionTitle}>Bill to contact</div>
              <ContactFields
                formikProps={formik}
                inputClassName={classes.input}
                inputNamePrefix='billToContact'
              />
            </Fragment>
          )}
        {carrierType === 'Freight' &&
          validOrderStatusForShipmentJob.includes(orderStatus) && (
            <Grid
              container
              spacing={5}
              rowSpacing={2}
              style={{ marginTop: 16 }}
            >
              <Grid
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 12,
                }}
              >
                <TextInput
                  name='truckingJobPickupNote'
                  formikProps={formik}
                  label='Trucking job pickup instructions'
                  className={classes.input}
                  rows={3}
                  multiline
                />
              </Grid>
              <Grid
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 12,
                }}
              >
                <TextInput
                  name='truckingJobDestinationNote'
                  formikProps={formik}
                  label='Trucking job destination instructions'
                  className={classes.input}
                  rows={3}
                  multiline
                />
              </Grid>
            </Grid>
          )}
      </div>
    </FormModal>
  );
}

const schema = object().shape({
  billToParty: string().nullable(),
});

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
    padding: '24px 16px',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 50,
    marginBottom: 16,
  },
  input: {
    width: '100%',
  },
}));
