import { useCallback, useState, useEffect, Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import { useDispatch, addResource, fetchResourceById } from '../../../state';
import { useRefresh } from '../../../hooks';
import {
  convertNullFieldsToEmptyString,
  updateNestedState,
} from '../../../lib';
import { InputChangeEventType, ServiceCustomFieldType } from '../../../types';
import { Grid2 as Grid } from '@mui/material';

interface Props {
  open: boolean;
  handleClose: () => any;
  fulfilledServiceId?: string | number;
  submitUrl?: string;
  setViewVersion?: React.Dispatch<React.SetStateAction<number>>;
}

interface State {
  referenceFields: Record<string, ServiceCustomFieldType>;
}

const initialState: State = {
  referenceFields: {
    '1': {
      name: '',
      value: '',
    },
    '2': {
      name: '',
      value: '',
    },
    '3': {
      name: '',
      value: '',
    },
  },
};

export function FulfilledServiceCustomFieldsForm({
  open,
  handleClose,
  fulfilledServiceId,
  submitUrl,
  setViewVersion,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    (async function () {
      if (fulfilledServiceId) {
        const { data } = await dispatch(
          fetchResourceById<Array<ServiceCustomFieldType>>({
            baseUrl: '/services',
            id: fulfilledServiceId,
            path: `custom-fields`,
          }),
        );
        if (data) {
          setState((cur) => {
            const { referenceFields } = cur;
            return {
              ...cur,
              referenceFields: Object.keys(referenceFields).reduce(
                (acc, cur, i) => {
                  acc[cur] = {
                    ...referenceFields[cur],
                    ...convertNullFieldsToEmptyString(data.at(i) ?? {}),
                  };
                  return acc;
                },
                {} as Record<string, ServiceCustomFieldType>,
              ),
            };
          });
        }
      }
    })();
  }, [fulfilledServiceId, dispatch]);

  const handleSubmit = useCallback(
    async (values: State) => {
      setSubmitting(true);
      const { referenceFields, ...rest } = values;
      const payload = {
        ...rest,
        fulfilledServiceID: fulfilledServiceId,
        referenceFields: Object.keys(referenceFields).reduce((acc, cur) => {
          const field = referenceFields[cur];
          if (!!field.name && !!field.value) {
            acc.push(field);
          }
          return acc;
        }, [] as Array<ServiceCustomFieldType>),
      };
      const { error } = await dispatch(
        addResource({
          baseUrl: submitUrl ?? `/services/${fulfilledServiceId}/custom-fields`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fulfilledServiceId, submitUrl],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const handleChange = (nestedKey: string) => (e: InputChangeEventType) => {
    const { values, setValues } = formik;
    const { name, value } = e.target;

    const newState = updateNestedState({
      nestedKey: ['referenceFields', nestedKey, name],
      value,
      state: values,
    });

    setValues({ ...values, ...newState });
  };

  const { handleChange: _handleChange, ...formikProps } = formik;

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Custom fields</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 472 } }}
      submitting={submitting}
    >
      <Grid container spacing={5} rowSpacing={2}>
        {Object.keys(formik.values.referenceFields).map((key, i) => {
          const { name, value } = formik.values.referenceFields[key];

          return (
            <Fragment key={i}>
              <Grid
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 6,
                }}
              >
                <TextInput
                  name='name'
                  formikProps={formikProps}
                  value={name}
                  label='Field name'
                  className={classes.input}
                  handleChange={handleChange(key)}
                  useCustomValue
                />
              </Grid>
              <Grid
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 6,
                }}
              >
                <TextInput
                  name='value'
                  formikProps={formikProps}
                  label='Value'
                  value={value}
                  className={classes.input}
                  handleChange={handleChange(key)}
                  useCustomValue
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: '100%',
  },
}));
