import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import { addResource } from '../../../state';
import { useRefresh } from '../../../hooks';

const initialState = {
  password: '',
  confirmPassword: '',
};

export function ChangeUserPasswordForm({
  open,
  handleClose,
  userId,
  userName,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = {
        ...values,
        userId,
      };
      const { error } = await dispatch(
        addResource({
          baseUrl: 'accounts/set-user-password',
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [dispatch, handleClose, refresh, userId],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span
          className={classes.title}
        >{`Change password for user ${userName}`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      <TextInput
        name='password'
        formikProps={formik}
        label='Enter password'
        className={classes.input}
        required
      />
      <TextInput
        name='confirmPassword'
        formikProps={formik}
        label='Confirm password'
        className={classes.input}
        required
      />
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));

const schema = object().shape({
  password: string().required().min(6),
  confirmPassword: string().test(
    'passwords-match',
    'Passwords must match',
    function (value) {
      return this.parent.password === value;
    },
  ),
});
