import { useCallback, useState, useEffect } from 'react';
import { object, string } from 'yup';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput, SelectInput, CheckboxInput } from '../inputs';
import {
  useDispatch,
  addResource,
  deleteResource,
  fetchResourceById,
  useSelector,
  systemSelectors,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { convertNullFieldsToEmptyString } from '../../../lib';
import {
  FeeType,
  GroupedCustomerSpecialFeesBasic,
  ReceiptFeeAPI,
  SelectOption,
} from '../../../types';

interface Props {
  open: boolean;
  handleClose: () => void;
  receiptId: string | number;
  customerId?: string | number;
  feeId?: string | number;
}

const initialState = {
  type: '',
  name: '',
  amount: '',
  baseCost: '',
  customerFeeID: '',
  quantity: 1,
  billableToCustomer: true,
  note: '',
};

export function ReceiptFeeForm({
  open,
  handleClose,
  receiptId,
  customerId,
  feeId,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);
  const [customFeeTypes, setCustomFeeTypes] = useState<
    Array<SelectOption<FeeType>>
  >([]);
  const [specialOrderOptions, setSpecialOrderOptions] = useState([]);
  const [specialItemOptions, setSpecialItemOptions] = useState([]);
  const [specialMaterialOptions, setSpecialMaterialOptions] = useState([]);
  const [specialHandlingOptions, setSpecialHandlingOptions] = useState([]);
  const [specialShipmentOptions, setSpecialShipmentOptions] = useState([]);
  const [customerFeesMapping, setCustomerFeesMapping] = useState({});

  const _customFeeTypes = useSelector(systemSelectors.specialFeeTypes);

  useEffect(() => {
    (async function () {
      const feeTypes = [..._customFeeTypes];
      if (feeId) {
        const { data } = await dispatch(
          fetchResourceById<ReceiptFeeAPI>({
            baseUrl: '/receipts',
            id: receiptId,
            path: `fees/${feeId}`,
          }),
        );
        if (data) {
          const {
            discountedRates,
            feeTypeDisplay,
            amount,
            baseCost,
            quantity,
            ...rest
          } = data;

          if (data.type) {
            // if a custom fee was generated somewhere else (ie refresh order fee), make sure it's in the list
            const specialFeeTypesOptions = customFeeTypes.map((t) => t.id);
            if (!specialFeeTypesOptions.includes(data.type)) {
              feeTypes.push({ id: data.type, name: feeTypeDisplay });
            }
          }

          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString(rest),
            amount: amount != null ? amount + (baseCost ?? 0) : '',
            quantity: quantity ?? 1,
          }));
        }
      } else if (customerId) {
        const { data } = await dispatch(
          fetchResourceById<GroupedCustomerSpecialFeesBasic>({
            baseUrl: '/customers',
            id: customerId,
            path: `fees/special-fees/basic`,
          }),
        );
        if (data) {
          let idAmountMapping = {};
          Object.keys(data).forEach((d) => {
            const fees = data[d];

            const mappings = fees.reduce((acc, cur) => {
              acc[cur.id.toString()] = cur.amount;
              return acc;
            }, {});

            idAmountMapping = { ...idAmountMapping, ...mappings };
            switch (d) {
              case 'SpecialOrderFee':
                setSpecialOrderOptions(fees);
                break;
              case 'SpecialMaterialFee':
                setSpecialMaterialOptions(fees);
                break;
              case 'SpecialHandlingFee':
                setSpecialHandlingOptions(fees);
                break;
              case 'SpecialShipmentFee':
                setSpecialShipmentOptions(fees);
                break;
              case 'SpecialItemFee':
                setSpecialItemOptions(fees);
                break;
              default:
                break;
            }
          });

          setCustomerFeesMapping(idAmountMapping);
        }
      }

      setCustomFeeTypes(feeTypes);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptId, customerId]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = {
        ...values,
        id: values.id || null,
      };
      const { error } = await dispatch(
        addResource({
          baseUrl: `/receipts/${receiptId}/fees`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [receiptId],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/receipts',
        id: receiptId,
        path: `fees/${feeId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptId, feeId]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  useEffect(() => {
    if (!feeId) {
      formik.setFieldValue('customerFeeID', '');
      formik.setFieldValue('amount', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.type]);

  const handleTypeChange = useCallback(
    async (e) => {
      const { name, value } = e.target;
      formik.setFieldValue(name, value);
      const feeAmount = customerFeesMapping[value + ''] ?? 0;
      if (feeAmount) {
        formik.setFieldValue('amount', feeAmount);
      }
    },
    [customerFeesMapping, formik],
  );

  const { handleChange, ...formikProps } = formik;

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          feeId ? 'Edit' : 'Add'
        } receipt fee`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={!!feeId}
      handleDelete={handleDelete}
    >
      <SelectInput
        name='type'
        formikProps={formik}
        label='Fee type'
        className={classes.input}
        options={customFeeTypes}
        disabled={!!feeId}
      />
      {!!specialOrderOptions.length &&
        formik.values.type === 'SpecialOrderFee' && (
          <SelectInput
            name='customerFeeID'
            formikProps={formikProps}
            onChange={handleTypeChange}
            label='Select fee'
            className={classes.input}
            options={specialOrderOptions}
            clearable
          />
        )}
      {!!specialMaterialOptions.length &&
        formik.values.type === 'SpecialMaterialFee' && (
          <SelectInput
            name='customerFeeID'
            formikProps={formikProps}
            onChange={handleTypeChange}
            label='Select fee'
            className={classes.input}
            options={specialMaterialOptions}
            clearable
          />
        )}
      {!!specialHandlingOptions.length &&
        formik.values.type === 'SpecialHandlingFee' && (
          <SelectInput
            name='customerFeeID'
            formikProps={formikProps}
            onChange={handleTypeChange}
            label='Select fee'
            className={classes.input}
            options={specialHandlingOptions}
            clearable
          />
        )}
      {!!specialShipmentOptions.length &&
        formik.values.type === 'SpecialShipmentFee' && (
          <SelectInput
            name='customerFeeID'
            formikProps={formikProps}
            onChange={handleTypeChange}
            label='Select fee'
            className={classes.input}
            options={specialShipmentOptions}
            clearable
          />
        )}
      {!!specialItemOptions.length &&
        formik.values.type === 'SpecialItemFee' && (
          <SelectInput
            name='customerFeeID'
            formikProps={formikProps}
            onChange={handleTypeChange}
            label='Select fee'
            className={classes.input}
            options={specialItemOptions}
            clearable
          />
        )}
      {!formik.values.customerFeeID && (
        <TextInput
          name='name'
          formikProps={formik}
          label='Fee name'
          className={classes.input}
        />
      )}
      {!feeId && (
        <TextInput
          name='baseCost'
          formikProps={formik}
          label='Base cost'
          className={classes.input}
          format='currency'
        />
      )}
      <TextInput
        name='amount'
        formikProps={formik}
        label='Rate'
        className={classes.input}
        format='currency'
      />
      <TextInput
        name='quantity'
        formikProps={formik}
        label='Quantity'
        className={classes.input}
        type='number'
        slotProps={{ htmlInput: { min: 1 } }}
      />
      <TextInput
        name='note'
        label='Notes'
        formikProps={formik}
        className={classes.input}
        multiline
        rows={3}
      />
      <CheckboxInput
        formikProps={formik}
        checkboxes={[
          {
            labelProps: {
              label: 'Billable to customer',
            },
            checkboxProps: {
              name: 'billableToCustomer',
            },
          },
        ]}
      />
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));

const schema = object().shape({
  quantity: string().required('Required'),
});
