import { Fragment, useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { stopEmployeeTracking, useDispatch } from '../../state';
import { TextInput } from '../../components';
import {
  CameraAltIcon,
  PauseIcon,
  ReportProblemIcon,
  TaskAltIcon,
} from '../../assets';
import {
  ClickEventType,
  EmployeeTrackingPayload,
  InputChangeEventType,
} from '../../types';
import { Navigation, generateUrl, paths } from '../../lib';
import MobileActionForm from './MobileActionForm';

interface Props {
  onClick?: (e?: ClickEventType) => any;
}

interface DefaultListItemPros extends Props {
  title: string;
  Icon:
    | React.FC<React.SVGProps<SVGElement>>
    | (OverridableComponent<SvgIconTypeMap<any, 'svg'>> & { muiName: string });
  iconClassName?: string;
}

interface StopEmployeeTrackingProps
  extends Props,
    Omit<EmployeeTrackingPayload, 'note'> {
  title?: string;
  modalTitle?: string;
  disabled?: boolean;
  onClose?: () => any;
  onSubmit?: () => any;
  successMessage?: string;
  defaultNote?: string;
  ButtonComponent?: React.FC<Props>;
}

export function MobileModalCameraListItem({ onClick }: Props) {
  const { classes, cx } = useStyles();

  return (
    <Fragment>
      <ListItemButton onClick={onClick}>
        <ListItemAvatar>
          <div className={cx(classes.iconLayout, classes.camera)}>
            <CameraAltIcon className={classes.icon} />
          </div>
        </ListItemAvatar>
        <ListItemText>
          <span className={classes.text}>Take a photo</span>
        </ListItemText>
      </ListItemButton>
    </Fragment>
  );
}

export function MobileModalIssuesListItem({ onClick, ...rest }: Props) {
  const { classes, cx } = useStyles();
  return (
    <ListItemButton onClick={onClick} {...rest}>
      <ListItemAvatar>
        <div className={cx(classes.iconLayout, classes.issues)}>
          <ReportProblemIcon className={classes.icon} />
        </div>
      </ListItemAvatar>
      <ListItemText>
        <span className={classes.text}>Report an issue</span>
      </ListItemText>
    </ListItemButton>
  );
}

type PauseTimerViews = 'button' | 'form';

export function MobileModalPauseTimerListItem({
  title = 'Pause timer',
  modalTitle = 'Are you sure you want to pause this task?',
  onClick,
  onClose,
  onSubmit,
  entityID,
  activityType,
  details,
  fulfilledServiceAssigneeID,
  fulfilledServiceID,
  disabled,
  successMessage,
  defaultNote,
  ButtonComponent,
}: StopEmployeeTrackingProps) {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();

  const [view, setView] = useState<PauseTimerViews>('button');
  const [pausedReason, setPausedReason] = useState(defaultNote || '');
  const [submitting, setSubmitting] = useState(false);

  const handleShowForm = useCallback(() => {
    setView('form');
    onClick?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(() => {
    setView('button');
    onClose?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback((e: InputChangeEventType) => {
    const { value } = e.target;
    setPausedReason(value);
  }, []);

  const handleSubmit = useCallback(
    async () => {
      if (!pausedReason) return;

      setSubmitting(true);
      const payload: EmployeeTrackingPayload = {
        activityType,
        entityID,
        fulfilledServiceAssigneeID,
        fulfilledServiceID,
        details,
        note: pausedReason,
      };
      const { error } = await dispatch(
        stopEmployeeTracking(payload, true, successMessage),
      );
      setSubmitting(false);
      if (!error) {
        onSubmit?.();
        Navigation.redirect(generateUrl(paths.MOBILE_HOME));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      entityID,
      activityType,
      details,
      fulfilledServiceAssigneeID,
      fulfilledServiceID,
      pausedReason,
      successMessage,
    ],
  );

  return (
    <Fragment>
      {view === 'button' && !ButtonComponent && (
        <ListItemButton disabled={disabled} onClick={handleShowForm}>
          <ListItemAvatar>
            <div className={cx(classes.iconLayout, classes.pauseTimer)}>
              <PauseIcon className={classes.icon} />
            </div>
          </ListItemAvatar>
          <ListItemText>
            <span className={classes.text}>{title}</span>
          </ListItemText>
        </ListItemButton>
      )}
      {view === 'button' && ButtonComponent && (
        <ButtonComponent onClick={handleShowForm} />
      )}
      {view === 'form' && (
        <MobileActionForm
          title={modalTitle}
          handleCancel={handleClose}
          callback={handleSubmit}
          btnText='SAVE'
          submitting={submitting}
        >
          {!defaultNote && (
            <TextInput
              name='pausedReason'
              value={pausedReason}
              handleChange={handleChange}
              label='Reason'
              multiline
              rows={3}
              required
              autoFocus
            />
          )}
        </MobileActionForm>
      )}
    </Fragment>
  );
}

export function MobileModalTaskFulfilledListItem({ onClick, ...rest }: Props) {
  const { classes, cx } = useStyles();
  return (
    <ListItemButton onClick={onClick} {...rest}>
      <ListItemAvatar>
        <div className={cx(classes.iconLayout, classes.taskFulfilled)}>
          <TaskAltIcon className={classes.icon} />
        </div>
      </ListItemAvatar>
      <ListItemText>
        <span className={classes.text}>Completed my job</span>
      </ListItemText>
    </ListItemButton>
  );
}

export function MobileModalActionListItem({
  onClick,
  Icon,
  title,
  iconClassName,
  ...rest
}: DefaultListItemPros) {
  const { classes, cx } = useStyles();

  return (
    <Fragment>
      <ListItemButton onClick={onClick} {...rest}>
        <ListItemAvatar>
          <div
            className={cx(classes.iconLayout, classes.camera, iconClassName)}
          >
            <Icon className={classes.icon} />
          </div>
        </ListItemAvatar>
        <ListItemText>
          <span className={classes.text}>{title}</span>
        </ListItemText>
      </ListItemButton>
    </Fragment>
  );
}

const useStyles = makeStyles({ name: 'MobileModalListActions' })((theme) => ({
  iconLayout: {
    height: 40,
    width: 40,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: '#FFFFFF',
  },
  text: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
  },
  camera: {
    backgroundColor: '#00C4F8',
  },
  issues: {
    backgroundColor: '#FF4F5B',
  },
  pauseTimer: {
    backgroundColor: '#FFBD00',
  },
  taskFulfilled: {
    backgroundColor: '#8FD854',
  },
}));
