import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import { useDispatch, overrideTruckingJobRate } from '../../../state';
import { InfoMessage } from '../InfoMessage';
import { useRefresh } from '../../../hooks';

const initialState = {
  customerRateOverride: '',
};

export function OverrideTruckingJobRateForm({
  handleClose,
  open,
  rateID,
  shipmentJobId,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const { error } = await dispatch(
        overrideTruckingJobRate(shipmentJobId, rateID, {
          customerRateOverride: values.customerRateOverride,
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rateID, shipmentJobId],
  );

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title='Override rate'
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      <InfoMessage message="By overriding the customer's rate the configured settings for this customers markup will not be applied" />
      <TextInput
        name='customerRateOverride'
        formikProps={formik}
        label='Customer rate override'
        className={classes.input}
        format='currency'
      />
    </FormModal>
  );
}

const schema = object().shape({
  customerRateOverride: string().nullable().required('Required'),
});

const useStyles = makeStyles()((theme) => ({
  input: {
    marginBottom: 16,
    width: 300,
  },
}));
