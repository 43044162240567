import { useCallback, useState, useEffect } from 'react';
import { object, string } from 'yup';
import { Grid2 as Grid } from '@mui/material';
import { makeStyles } from '../../../themes';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput, AutocompleteInput } from '../inputs';
import {
  useDispatch,
  useSelector,
  addResource,
  systemSelectors,
  getBasicQbModels,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { CreateCreditMemoRequest } from '../../../types';

interface CreditMemoFormProps {
  open: boolean;
  handleClose: () => void;
  customerId?: number | string;
  customerName?: string;
  orderId?: number;
  shipmentJobId?: number;
  drayageJobId?: number;
  receiptId?: number;
  appointmentId?: number;
  purchaseLabelFulfilledServiceId?: number;
  invoiceId?: number;
  setViewVersion?: React.Dispatch<React.SetStateAction<number>>;
}

interface State {
  amount: number | string;
  note?: string;
  customerMemo?: string;
  lineItemDescription?: string;
  qbItemRefID?: string;
  qbItemClassID?: string;
}

const initialState: State = {
  amount: '',
  note: '',
  customerMemo: '',
  lineItemDescription: '',
  qbItemRefID: '',
  qbItemClassID: '',
};

export function CreditMemoForm(props: CreditMemoFormProps) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const {
    open,
    handleClose,
    setViewVersion,
    customerId,
    orderId,
    shipmentJobId,
    drayageJobId,
    receiptId,
    appointmentId,
    purchaseLabelFulfilledServiceId,
    invoiceId,
  } = props;

  const title = getTitle(props);

  const qbItems = useSelector(systemSelectors.basicQbItems);
  const qbClasses = useSelector(systemSelectors.basicQbClasses);

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    dispatch(getBasicQbModels('Item'));
    dispatch(getBasicQbModels('Class'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    async (values: State) => {
      setSubmitting(true);

      const payload: CreateCreditMemoRequest = {
        customerID: customerId,
        orderID: orderId,
        shipmentJobID: shipmentJobId,
        drayageJobID: drayageJobId,
        receiptID: receiptId,
        appointmentID: appointmentId,
        purchaseLabelFulfilledServiceID: purchaseLabelFulfilledServiceId,
        invoiceID: invoiceId,
        ...values,
      };

      const { error } = await dispatch(
        addResource<any, CreateCreditMemoRequest>({
          baseUrl: 'accounting/credit',
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      appointmentId,
      customerId,
      drayageJobId,
      invoiceId,
      orderId,
      receiptId,
      shipmentJobId,
      purchaseLabelFulfilledServiceId,
    ],
  );

  const formik = useFormik<State>({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>{title}</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      <Grid container spacing={5} rowSpacing={2}>
        <Grid
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='amount'
            formikProps={formik}
            label='Total amount'
            className={classes.input}
            type='number'
            required
          />
        </Grid>
        <Grid
          size={{
            sm: 6,
          }}
        >
          <AutocompleteInput
            formikProps={formik}
            textFieldProps={{
              name: 'qbItemRefID',
              label: 'Item',
            }}
            autocompleteProps={{
              options: qbItems,
            }}
          />
        </Grid>
        <Grid
          size={{
            sm: 6,
          }}
        >
          <AutocompleteInput
            formikProps={formik}
            textFieldProps={{
              name: 'qbItemClassID',
              label: 'Class',
            }}
            autocompleteProps={{
              options: qbClasses,
            }}
          />
        </Grid>
        <Grid
          size={{
            sm: 12,
          }}
        >
          <TextInput
            name='lineItemDescription'
            formikProps={formik}
            label='Line description'
            className={classes.input}
          />
        </Grid>
        <Grid
          size={{
            sm: 12,
          }}
        >
          <TextInput
            name='note'
            formikProps={formik}
            label='Memo'
            className={classes.input}
            multiline
            rows={3}
          />
        </Grid>
        <Grid
          size={{
            sm: 12,
          }}
        >
          <TextInput
            name='customerMemo'
            formikProps={formik}
            label='Customer memo'
            className={classes.input}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { CreditMemoForm } })((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    width: '100%',
  },
}));

const schema = object().shape({
  amount: string().required('Required'),
});

function getTitle(props: CreditMemoFormProps) {
  if (props.customerName) {
    return `Add credit memo for ${props.customerName}`;
  }
  if (props.orderId) {
    return `Add credit memo for order #${props.orderId}`;
  }
  if (props.drayageJobId) {
    return `Add credit memo for drayage job #${props.drayageJobId}`;
  }
  if (props.shipmentJobId) {
    return `Add credit memo for shipment job #${props.shipmentJobId}`;
  }
  if (props.receiptId) {
    return `Add credit memo for receipt #${props.receiptId}`;
  }
  if (props.appointmentId) {
    return `Add credit memo for appointment #${props.appointmentId}`;
  }
  if (props.invoiceId) {
    return `Add credit memo for invoice #${props.invoiceId}`;
  }
}
