import { Fragment, useCallback, useState, useEffect } from 'react';
import { Button, DialogActions, Table } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import {
  useDispatch,
  useSelector,
  reconcileBill,
  systemSelectors,
} from '../../../state';
import { useNotify, useRefresh } from '../../../hooks';
import { SelectInput, TextInput } from '..';
import { ErrorIcon } from '../../../assets';
import { ListEditIcon } from '../../../components';
import { CurrencyField, DataGrid, TextField } from '../../list';

const initialState = {
  reconciliationType: '',
  adjustmentNote: '',
};

export function BulkReconcileForm({
  billShipments,
  selectedIds,
  billExternalId,
  open,
  handleClose,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();

  const [reconciliationTypesOptions, setReconciliationTypesOptions] = useState(
    [],
  );
  const [step, setStep] = useState(1);
  const [submitting, setSubmitting] = useState(false);

  const toggleStep = useCallback(() => {
    setStep((cur) => (cur === 1 ? 2 : 1));
  }, []);

  const reconciliationTypes = useSelector(systemSelectors.reconciliationTypes);

  useEffect(() => {
    const options = reconciliationTypes.filter((r) => r.id !== 'NotReconciled');
    setReconciliationTypesOptions(options);
  }, [reconciliationTypes]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const { billShipments: payload, error: validationError } =
        generatePayload(billShipments, values);
      if (validationError) {
        setSubmitting(false);
        return notify(validationError, 'error');
      }
      const { error } = await dispatch(
        reconcileBill({ billShipments: payload }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [billShipments, dispatch, handleClose, notify, refresh],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Reconcile shipments</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='md'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
      hasCustomActionBtn={step === 1}
    >
      <div>
        {step === 1 ? (
          <Fragment>
            <div>
              <div className={classes.warningTextContainer}>
                <ErrorIcon className={classes.errorIcon} />
                <span className={classes.warningText}>
                  The change to the customers account will be applied
                  immediately
                </span>
              </div>
              <p className={classes.reconciliationTitle}>
                How would you like to reconcile this?
              </p>
              <SelectInput
                name='reconciliationType'
                formikProps={formik}
                options={reconciliationTypesOptions}
                label='Select option'
                className={classes.input}
              />
            </div>
            <TextInput
              name='adjustmentNote'
              formikProps={formik}
              label='Note (optional)'
              rows={3}
              multiline
            />
            <DialogActions>
              <Button color='primary' onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button color='primary' onClick={toggleStep}>
                Continue
              </Button>
            </DialogActions>
          </Fragment>
        ) : (
          <div>
            <div className={classes.subTitle}>
              {getSubTitle(formik.values.reconciliationType)}{' '}
              <ListEditIcon onClick={toggleStep} />
            </div>
            <Table>
              <DataGrid
                bulkActionButtons={false}
                ids={selectedIds}
                rows={billShipments}
                hideFooter
              >
                <TextField
                  source='trackingNumber'
                  label='Tracking#'
                  sortable={false}
                />
                <CurrencyField
                  source='shipmentCostWithAdjustment'
                  label='Estimate'
                  sortable={false}
                />
                <CurrencyField
                  source='totalBilledAmount'
                  label='Billed'
                  sortable={false}
                />
              </DataGrid>
            </Table>
          </div>
        )}
      </div>
    </FormModal>
  );
}

function getSubTitle(reconciliationType) {
  switch (reconciliationType) {
    case 'ChargeCustomer':
      return 'You have chosen to charge customer the difference for the following shipments';
    case 'AcceptAndPay':
      return 'You have chosen to accept and pay the following shipments';
    case 'NotApplicable':
      return 'You have chosen that the following shipments do not need to be reconciled';
  }
}

function generatePayload(billShipments, state) {
  if (!billShipments) return { error: 'No shipments found' };

  if (
    state?.reconciliationType === 'ChargeCustomer' &&
    Object.keys(billShipments).some((k) => !billShipments[k].shipmentID)
  ) {
    return {
      error: 'Unable to charge customer because some shipments are unmatched',
    };
  }

  return {
    billShipments: Object.keys(billShipments).reduce((acc, cur) => {
      const { billShipmentID, shipmentID } = billShipments[cur];
      acc.push({ ...state, billShipmentID, shipmentID });
      return acc;
    }, []),
  };
}

const schema = object().shape({
  reconciliationType: string().required('Required'),
});

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
  },
  reconciliationTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '18px',
  },
  input: {
    marginBottom: 16,
    width: '300px',
  },
  warningTextContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  errorIcon: {
    fontSize: 18,
    color: '#FFBD00',
    background: '#FFFFFF',
    marginRight: 6,
  },
  warningText: {
    color: '#A5AABD',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
  },
}));
