import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { SelectInput } from '../inputs';
import { getBasicWarehouses, warehousesSelectors } from '../../../state';

const initialState = {
  warehouseID: '',
};

export function EditReshipWarehouseForm({
  open,
  handleClose,
  warehouseID,
  callback,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [state] = useState({ ...initialState, warehouseID: warehouseID || '' });

  const warehouses = useSelector(warehousesSelectors.basicList, shallowEqual);

  useEffect(() => {
    dispatch(getBasicWarehouses());
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (values) => {
      const warehouse = warehouses.filter((s) => s.id === values.warehouseID);
      callback({
        warehouseID: values.warehouseID,
        warehouseName: warehouse?.[0]?.name,
      });
      handleClose();
    },
    [callback, handleClose, warehouses],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Edit warehouse</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
    >
      <SelectInput
        name='warehouseID'
        formikProps={formik}
        label='Warehouse location'
        className={classes.input}
        options={warehouses}
      />
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));
