import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useIsMobile, useLoader } from '../../hooks';
import { FormSubmitEventType, InputChangeEventType } from '../../types';
import { ProcessContainer } from '../processOrder';
import { Button, TextField } from '@mui/material';

interface Props<T> {
  fetchData: (
    receiptId: string | number,
  ) => Promise<{ error: any; data: T | null }>;
  onSuccess: (data: T, receipt: string) => void;
}
export function SelectReceipt<T>({ fetchData, onSuccess }: Props<T>) {
  const { classes } = useStyles();
  const setLoader = useLoader();
  const isMobile = useIsMobile();

  const [selectedReceipt, setSelectedReceipt] = useState('');
  const [error, setError] = useState<null | string>(null);

  const handleInputChange = (e: InputChangeEventType) => {
    const value = e.target.value;
    if (!value) setError('Receipt is required');
    else setError(null);
    setSelectedReceipt(value);
  };

  const handleGo = useCallback(
    async (e: FormSubmitEventType) => {
      e?.preventDefault();
      if (!selectedReceipt) {
        setError('Receipt is required');
        return;
      }
      setLoader(true);
      const { error, data } = await fetchData(selectedReceipt);
      setLoader(false);
      if (error) {
        const errorMessage =
          error.status === 404
            ? 'There were no results found with the receipt entered, please try again.'
            : error.message || 'Something went wrong';
        setError(errorMessage);
      } else if (data) {
        onSuccess(data, selectedReceipt);
      }
    },
    [fetchData, onSuccess, selectedReceipt, setLoader],
  );

  return (
    <ProcessContainer header='Scan or enter receipt'>
      <div className={classes.card}>
        <form onSubmit={handleGo} className={classes.form}>
          <TextField
            variant='standard'
            label={`Scan or enter receipt ${!isMobile ? ' number' : ''}`}
            className={classes.input}
            name='selectedReceipt'
            value={selectedReceipt}
            onChange={handleInputChange}
            autoFocus
            error={Boolean(error)}
            helperText={error}
          />
          <Button variant='contained' color='primary' type='submit'>
            Go
          </Button>
        </form>
      </div>
    </ProcessContainer>
  );
}

const useStyles = makeStyles({
  name: { SelectReceipt },
})((theme) => ({
  card: {
    height: 110,
    width: 468,
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '0px 16px',
  },
  input: {
    width: '100%',
    [theme.breakpoints.down('tablet')]: {
      marginRight: 16,
    },
  },
}));
