import { useCallback, useRef, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import { Button, IconButton } from '@mui/material';
import { DataGrid, DateField, PagedList, TextField } from '../../components';
import { ListToolbar } from '../../components/list/ListToolbar';
import { ArrowBackIcon } from '../../assets';
import { InventoryTrackingForm } from './InventoryTrackingForm';
import { getDifference } from '../../lib';
// import {  useDispatch } from '../../state';
import { makeStyles, TextButton } from '../../themes';
import {
  WarehouseLocationPhysicalItemAPI as _WarehouseLocationPhysicalItemAPI,
  BulkComponentProps,
  AddPhysicalItemsToWarehouseLocationRequest,
} from '../../types';
import { EditInventoryItemProps, CreateInventoryPhysicalItem } from './utils';

interface WarehouseLocationPhysicalItemAPI
  extends _WarehouseLocationPhysicalItemAPI {
  id: number;
}

interface ApiResponse {
  itemSkuQuantity: number;
  itemSkuPhysicalItemQty: number;
  numberOfRows: number;
  results: Array<WarehouseLocationPhysicalItemAPI>;
}

interface Props {
  receiptId?: string | number;
  warehouseLocationId: string | number;
  moveableUnitId?: string | number | null;
  customerItemId: number;
  sku: string;
  canEdit?: boolean;
  getPhysicalItemsUrlOverride?: string;
  handleBack?: () => void;
  handleSubmitOverride?: (
    payload: AddPhysicalItemsToWarehouseLocationRequest,
  ) => void;
  setMuViewVersion?: React.Dispatch<React.SetStateAction<number>>;
}

interface ItemStats {
  itemSkuQuantity: number;
  itemSkuPhysicalItemQty: number;
}

interface BulkProps
  extends BulkComponentProps<WarehouseLocationPhysicalItemAPI> {
  receiptId?: string | number;
  warehouseLocationId: string | number;
  moveableUnitId?: string | number | null;
  customerItemId: number;
  sku: string;
  itemStats: ItemStats;
  selectedIdsOverride: Array<string | number>;
  selectedRowsOverride: Record<string, WarehouseLocationPhysicalItemAPI>;
  handleClearSelected: () => void;
  handleSubmit: (values: EditInventoryItemProps) => Promise<void>;
  formikProps: FormikProps<EditInventoryItemProps>;
}

export function InventoryPhysicalItems({
  receiptId,
  warehouseLocationId,
  moveableUnitId,
  customerItemId,
  sku,
  canEdit,
  handleBack,
  getPhysicalItemsUrlOverride,
  setMuViewVersion,
  handleSubmitOverride,
}: Props) {
  const { classes, css } = useStyles();
  // const dispatch = useDispatch();

  // const [viewVersion, setViewVersion] = useState(0);
  const [rows, setRows] = useState<
    Record<number, WarehouseLocationPhysicalItemAPI>
  >({});
  const [ids, setIds] = useState<Array<number>>([]);
  const [bulkSelectedRecordsIds, setBulkSelectedRecordIds] = useState<
    Array<string | number>
  >([]);
  const [bulkSelectedRecordsRows, setSelectedBulkRecordsRows] = useState<
    Record<string, WarehouseLocationPhysicalItemAPI>
  >({});
  const [itemStats, setItemStats] = useState<ItemStats>({
    itemSkuQuantity: 0,
    itemSkuPhysicalItemQty: 0,
  });
  const [item, setItem] = useState<EditInventoryItemProps>({
    receiptID: receiptId,
    sku,
    customerItemID: customerItemId,
    skuQuantity: itemStats.itemSkuQuantity,
    createPhysicalItems: {},
    primaryQuantityRemaining: 0,
  });
  const [showAddModal, setShowAddModal] = useState(false);

  // const incrementViewVersion = useCallback(() => {
  //   setViewVersion((prev) => ++prev);
  //   setMuViewVersion?.((prev) => ++prev);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleClearSelected = useCallback(() => {
    setBulkSelectedRecordIds([]);
    setSelectedBulkRecordsRows({});
  }, []);

  const handleGetResponseData = useCallback((value: ApiResponse) => {
    if (value) {
      setItemStats({
        itemSkuQuantity: value.itemSkuQuantity,
        itemSkuPhysicalItemQty: value.itemSkuPhysicalItemQty,
      });
      setItem((cur) => ({ ...cur, skuQuantity: value.itemSkuQuantity }));
    }
  }, []);

  const getBulkSelectedRecords = useCallback(
    (
      ids: Array<string | number>,
      rows: Record<string, WarehouseLocationPhysicalItemAPI>,
    ) => {
      setBulkSelectedRecordIds(ids);
      setSelectedBulkRecordsRows(rows);
    },
    [],
  );

  const handleSubmit = useCallback(
    async (values: EditInventoryItemProps) => {
      if (!values) return;
      const arrayValue = Object.values(values.createPhysicalItems);
      const removedPhysicalItemIds = getDifference<number>(
        bulkSelectedRecordsIds as number[],
        arrayValue
          .map((i) => i.moveableUnitPhysicalItemId)
          .filter((i) => i !== undefined),
      );

      const payload: AddPhysicalItemsToWarehouseLocationRequest = {
        warehouseLocationID: warehouseLocationId,
        physicalItemsToDelete: arrayValue
          .filter(
            (i) =>
              !!i.moveableUnitPhysicalItemId &&
              removedPhysicalItemIds.includes(i.moveableUnitPhysicalItemId),
          )
          .map((i) => ({
            moveableUnitPhysicalItemID: i.moveableUnitPhysicalItemId!,
            receiptID: i.receiptId as number,
          })),
        physicalItems: arrayValue.map((i) => ({
          id: i.moveableUnitPhysicalItemId,
          moveableUnitID: moveableUnitId,
          receiptID: i.receiptId,
          quantity: i.quantity,
          physicalItemAPI: {
            customerItemID: customerItemId ? customerItemId : undefined,
            sku,
            serialNumber: i.serialNumber,
            lotNumber: i.lotNumber,
            expirationDate: i.expirationDate,
          },
        })),
      };
      if (handleSubmitOverride) {
        handleSubmitOverride(payload);
        handleClearSelected();
        // clear the form
        formik.setFieldValue('createPhysicalItems', {});
        return;
      }
      // const { error } = await dispatch(
      //   upsertMoveableUnitPhysicalItems({ receiptId, moveableUnitId, payload }),
      // );
      // if (!error) {
      //   incrementViewVersion();
      //   handleClearSelected();
      //   // clear the form
      //   formik.setFieldValue('createPhysicalItems', {});
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerItemId, moveableUnitId, receiptId, bulkSelectedRecordsIds, sku],
  );

  const formik = useFormik<EditInventoryItemProps>({
    initialValues: item,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const handleShowAdd = () => {
    setShowAddModal(true);
  };

  const handleHideAdd = useCallback(async (doSubmit?: boolean) => {
    if (doSubmit) {
      await formik.submitForm();
    }
    setShowAddModal(false);
    formik.setFieldValue('createPhysicalItems', {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {handleBack && (
        <>
          <div className={classes.navigator}>
            <IconButton
              onClick={handleBack}
              className={classes.backIcon}
              size='small'
            >
              <ArrowBackIcon />
            </IconButton>
            <div className={classes.title}>Inventory tracking</div>
          </div>
          <div className={classes.subtitle}>
            <span>{`MU ${moveableUnitId}`}</span>
            <span className={classes.spacer}>|</span>
            <span>{`Item ${sku}`}</span>
          </div>
        </>
      )}
      <div className={css({ position: 'relative' })}>
        {canEdit && (
          <ListToolbar
            numSelected={bulkSelectedRecordsIds.length}
            ids={ids}
            rows={rows}
            selectedIds={bulkSelectedRecordsIds}
            selectedRows={bulkSelectedRecordsRows}
            bulkActionButtons={
              <BulkActions
                receiptId={receiptId}
                warehouseLocationId={warehouseLocationId}
                moveableUnitId={moveableUnitId}
                customerItemId={customerItemId}
                sku={sku}
                itemStats={itemStats}
                selectedIdsOverride={bulkSelectedRecordsIds}
                selectedRowsOverride={bulkSelectedRecordsRows}
                handleClearSelected={handleClearSelected}
                formikProps={formik}
                handleSubmit={handleSubmit}
              />
            }
          />
        )}
        <PagedList
          url={
            getPhysicalItemsUrlOverride ||
            `inventory/stock/locations/${warehouseLocationId}/physical-items`
          }
          filters={{
            customerItemID: customerItemId,
            sku,
            moveableUnitID: moveableUnitId,
            getItemStats: true,
          }}
          setIds={setIds}
          setRows={setRows}
          getResponseData={handleGetResponseData}
          // viewVersion={viewVersion}
          // table-layout='fixed'
          // white-space='nowrap'
          // className={classes.table}
          // getPagingInfo={handleSetPagingInfo}
          // viewVersion={viewVersion}
        >
          <DataGrid<WarehouseLocationPhysicalItemAPI>
            ids={ids}
            rows={rows}
            // hideHeader
            hasBulkActions={canEdit}
            getSelectedRowsAndIds={getBulkSelectedRecords}
            selectedIds={bulkSelectedRecordsIds}
          >
            <TextField
              source='physicalItemAPI.serialNumber'
              label='Serial #'
              headerClassName={classes.noWrap}
              sortable={false}
            />
            <DateField
              source='physicalItemAPI.expirationDate'
              label='Exp date'
              headerClassName={classes.noWrap}
              sortable={false}
            />
            <TextField
              source='physicalItemAPI.lotNumber'
              label='Lot #'
              headerClassName={classes.noWrap}
              sortable={false}
            />
            <TextField
              source='quantity'
              label='QTY'
              headerClassName={classes.noWrap}
              sortable={false}
            />
            <TextField
              source='receiptID'
              label='Receipt #'
              headerClassName={classes.noWrap}
              sortable={false}
            />
            <TextField
              source='age'
              label='Age'
              headerClassName={classes.noWrap}
              sortable={false}
            />
          </DataGrid>
        </PagedList>
        {canEdit && (
          <div className={css({ marginTop: 8 })}>
            <TextButton
              onClick={handleShowAdd}
              disabled={!!bulkSelectedRecordsIds.length}
            >
              Add physical items
            </TextButton>
          </div>
        )}
      </div>
      {showAddModal && (
        <InventoryTrackingForm
          open={showAddModal}
          handleClose={handleHideAdd}
          item={formik.values}
          formikProps={formik}
          packageKey=''
          itemKey=''
          remainderSkuPhysicalItemsQuantity={itemStats.itemSkuPhysicalItemQty}
          nestedKey={['createPhysicalItems']}
        />
      )}
    </div>
  );
}

function BulkActions({
  selectedIdsOverride: selectedIds,
  selectedRowsOverride: selectedRows,
  itemStats,
  formikProps,
}: BulkProps) {
  const selectedPhysicalItemsTotal = useRef(0);

  const [showEdit, setShowEdit] = useState(false);

  const handleShowEdit = () => {
    let selectedTotal = 0;
    const physicalItems = Object.values(selectedRows).reduce<
      Record<string, CreateInventoryPhysicalItem>
    >((acc, cur) => {
      acc[cur.id + ''] = {
        id: cur.id,
        moveableUnitPhysicalItemId: cur.id,
        receiptId: cur.receiptID,
        serialNumber: cur.physicalItemAPI.serialNumber || '',
        lotNumber: cur.physicalItemAPI.lotNumber || '',
        expirationDate: cur.physicalItemAPI.expirationDate,
        quantity: (cur.quantity as number) || 0,
      };

      selectedTotal += cur.quantity as number;
      return acc;
    }, {});

    formikProps.setFieldValue('createPhysicalItems', physicalItems);
    selectedPhysicalItemsTotal.current = selectedTotal;

    setShowEdit(true);
  };

  const handleHideEdit = useCallback(async (doSubmit?: boolean) => {
    if (doSubmit) {
      await formikProps.submitForm();
    }
    setShowEdit(false);
    formikProps.setFieldValue('createPhysicalItems', {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Button
        color='primary'
        // style={{ marginLeft: 16 }}
        onClick={handleShowEdit}
        variant='contained'
        disableElevation
      >
        Edit
      </Button>
      {showEdit && (
        <InventoryTrackingForm
          open={showEdit}
          handleClose={handleHideEdit}
          item={formikProps.values}
          formikProps={formikProps}
          packageKey=''
          itemKey=''
          remainderSkuPhysicalItemsQuantity={
            itemStats.itemSkuPhysicalItemQty -
            selectedPhysicalItemsTotal.current
          }
          nestedKey={['createPhysicalItems']}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles({
  name: { InventoryItemPhysicalItems: InventoryPhysicalItems },
})((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  navigator: {
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
  },
  backIcon: {
    color: 'inherit',
    marginRight: 7,
  },
  subtitle: {
    fontSize: 14,
    letterSpacing: 0,
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
  },
  spacer: {
    marginRight: 4,
    marginLeft: 4,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));
