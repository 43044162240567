import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput, SelectInput } from '../inputs';
import {
  addResource,
  fetchResourceById,
  deleteResource,
  systemSelectors,
} from '../../../state';
import {
  convertNullFieldsToEmptyString,
  creditCardProcessingFeeTypes,
} from '../../../lib';
import { useRefresh } from '../../../hooks';

const initialState = {
  cardType: '',
  percentage: '',
};

export function CreditCardFeeForm({ open, handleClose, settingId }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const isEdit = !!settingId;

  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  const cardTypes = useSelector(systemSelectors.cardTypes);

  useEffect(() => {
    (async function () {
      if (settingId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/system/settings',
            id: settingId,
          }),
        );
        if (data) {
          const { id, additionalData: { cardType, percentage } = {} } = data;
          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString({ id, cardType, percentage }),
          }));
        }
      }
    })();
  }, [dispatch, settingId]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = generatePayload(values);
      const { error } = await dispatch(
        addResource({
          baseUrl: '/system/settings',
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [dispatch, handleClose, refresh],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/system/settings',
        id: settingId,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
  }, [dispatch, handleClose, refresh, settingId]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          settingId ? 'Edit' : 'Add'
        } CC processing fee`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={isEdit}
      handleDelete={handleDelete}
    >
      <SelectInput
        name='cardType'
        formikProps={formik}
        options={cardTypes}
        label='Card type'
        className={classes.input}
        required
        disabled={isEdit}
      />
      <TextInput
        name='percentage'
        formikProps={formik}
        label='Fee'
        className={classes.input}
        slotProps={{
          input: {
            endAdornment: <span>%</span>,
          },
        }}
        required
      />
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));

const schema = object().shape({
  cardType: string().required('Required'),
  percentage: string().required('Required'),
});

function generatePayload(state) {
  const { id, cardType, percentage } = state;
  let feeType;

  switch (state.cardType) {
    case 'Visa':
      feeType = creditCardProcessingFeeTypes.VISA;
      break;
    case 'MasterCard':
      feeType = creditCardProcessingFeeTypes.MASTER_CARD;
      break;
    case 'AmericanExpress':
      feeType = creditCardProcessingFeeTypes.AMERICAN_EXPRESS;
      break;
    case 'Discover':
      feeType = creditCardProcessingFeeTypes.DISCOVER;
      break;
    case 'Diners':
      feeType = creditCardProcessingFeeTypes.DINERS;
      break;
    case 'Jcb':
      feeType = creditCardProcessingFeeTypes.JCB;
      break;
    default:
  }

  return {
    id,
    type: feeType,
    additionalData: {
      cardType,
      percentage: parseFloat(percentage),
      type: feeType,
    },
  };
}
