import { useCallback, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import {
  useDispatch,
  useSelector,
  reconcileBill,
  systemSelectors,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { SelectInput, TextInput } from '..';
import { ErrorIcon } from '../../../assets';

const initialState = {
  reconciliationType: '',
  adjustmentNote: '',
};

export function ReconcileForm({
  open,
  handleClose,
  trackingNumber,
  billShipmentID,
  setViewVersion,
  shipmentID,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [submitting, setSubmitting] = useState(false);

  const reconciliationTypes = useSelector(systemSelectors.reconciliationTypes);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = {
        billShipments: [{ ...values, billShipmentID, shipmentID }],
      };
      var msg = `${shipmentID ? 'Shipment' : 'Tracking'} #${
        trackingNumber ?? shipmentID
      } was reconciled`;

      const { error } = await dispatch(reconcileBill(payload, msg));
      setSubmitting(false);
      if (!error) {
        refresh();
        setViewVersion && setViewVersion((cur) => ++cur);
        handleClose();
      }
    },
    [
      billShipmentID,
      dispatch,
      handleClose,
      refresh,
      setViewVersion,
      shipmentID,
      trackingNumber,
    ],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const reconciliationTypesOptions = useMemo(
    () =>
      reconciliationTypes.filter(
        (type) =>
          type.enumInt > 0 && (shipmentID || type.id !== 'ChargeCustomer'),
      ),
    [reconciliationTypes, shipmentID],
  );

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          shipmentID ? 'Shipment' : 'Tracking'
        } #${trackingNumber}`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='md'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      <div>
        <div className={classes.warningTextContainer}>
          <ErrorIcon className={classes.errorIcon} />
          <span className={classes.warningText}>
            The change to the customers account will be applied immediately
          </span>
        </div>
        <p className={classes.reconciliationTitle}>
          How would you like to reconcile this?
        </p>
        <SelectInput
          name='reconciliationType'
          formikProps={formik}
          options={reconciliationTypesOptions}
          label='Select option'
          className={classes.input}
        />
      </div>
      <TextInput
        name='adjustmentNote'
        formikProps={formik}
        label='Note (optional)'
        rows={3}
        multiline
      />
    </FormModal>
  );
}

const schema = object().shape({
  reconciliationType: string().required('Required'),
});

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  reconciliationTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '18px',
  },
  input: {
    marginBottom: 16,
    width: '300px',
  },
  warningTextContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  errorIcon: {
    fontSize: 18,
    color: '#FFBD00',
    background: '#FFFFFF',
    marginRight: 6,
  },
  warningText: {
    color: '#A5AABD',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
  },
}));
