import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { format } from 'date-fns';
import { FormModal } from './FormModal';
import { AutocompleteInput, FileInput, SelectInput } from '../inputs';
import {
  useSelector,
  customersSelectors,
  warehousesSelectors,
} from '../../../state';
import { useRefresh, useNotify } from '../../../hooks';
import { authPost } from '../../../lib';
import { FileType } from '../../../types';
import { Grid2 as Grid } from '@mui/material';

interface Props {
  open: boolean;
  handleClose: () => any;
}

interface State {
  customerID: string;
  warehouseID: string;
}

const initialState: State = {
  customerID: '',
  warehouseID: '',
};

export const ReceiptImportForm = ({ open, handleClose }: Props) => {
  const { classes, cx } = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();

  const [submitting, setSubmitting] = useState(false);
  const [files, setFiles] = useState<Array<FileType>>([]);

  // const [configuredDocumentFees, setConfiguredDocumentFees] = useState([]);

  const customers = useSelector(customersSelectors.basicList);
  const warehouses = useSelector(warehousesSelectors.basicList);

  const handleSubmit = useCallback(
    async (values: State) => {
      if (!files.length) {
        return notify('Please upload a file', 'error');
      }
      setSubmitting(true);

      const formData = new FormData();
      formData.append('customerID', values.customerID);
      formData.append('warehouseID', values.warehouseID);
      formData.append('File', files[0].rawFile);

      const { error } = await authPost(
        '/receipts/import',
        formData,
        undefined,
        undefined,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      setSubmitting(false);
      if (error) {
        notify(
          error.message ||
            error.Message ||
            error.title ||
            'Something went wrong ',
          'error',
        );
      } else {
        refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Import receipt</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      <div className={classes.layout}>
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <AutocompleteInput
              formikProps={formik}
              textFieldProps={{
                name: 'customerID',
                label: 'Select customer',
                autoComplete: 'off',
                required: true,
                className: classes.input,
              }}
              autocompleteProps={{
                options: customers,
              }}
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='warehouseID'
              formikProps={formik}
              label='Warehouse'
              className={classes.input}
              options={warehouses}
              required
            />
          </Grid>
        </Grid>
        <div className={classes.fileInput}>
          <FileInput
            files={files}
            setFiles={setFiles}
            filePrefix={`receipts/upload-${format(new Date(), 'Pp')}`}
            multiple={false}
            acceptFileTypes={{ 'text/*': ['.txt', '.csv'] }}
            uploadText={
              <div className={classes.inputTextContainer}>
                <span className={classes.inputText}>
                  Drag ’n’ drop a file here, or click to select files
                </span>
                <span className={cx(classes.inputText, classes.inputSmallText)}>
                  file should be txt or csv tab delimited
                </span>
              </div>
            }
          />
        </div>
      </div>
    </FormModal>
  );
};

const useStyles = makeStyles({ name: { ReceiptImportForm } })((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    width: '100%',
  },
  fileInput: {
    marginTop: 70,
  },
  inputTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputText: {
    color: '#A5AABD',
    fontSize: 16,
    letterSpacing: 0,
  },
  inputSmallText: {
    fontSize: 12,
  },
}));

// interface GenerateParams {
//   receiptId: number;
//   files: Array<FileType>;
//   signedUrlsMapping: SignedUrlMapping;
//   values: State;
// }

// function generatePayload({
//   receiptId: receiptID,
//   files,
//   signedUrlsMapping,
//   values,
// }: GenerateParams) {
//   const {
//     documentType,
//     // BillableToCustomer,
//     ...rest
//   } = values;

//   // payload.BillableToCustomer = BillableToCustomer;

//   const Document = rest as DocumentAPI;

//   const { key, rawFile: { type } = {} } = files[0];
//   Document.objectName = key;
//   Document.url = signedUrlsMapping[key];
//   Document.type = type ?? '';

//   return {
//     receiptID,
//     documentType,
//     document: Document,
//   } as Payload;
// }

const schema = object().shape({
  customerID: string(),
  warehouseID: string().required('Required'),
});
