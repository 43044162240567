import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'orderViewRate' })((theme) => ({
  paper: {
    minWidth: 1050,
    maxWidth: 1050,
    minHeight: 552,
  },
  paperManagerLogin: {
    width: 468,
    minWidth: 468,
    minHeight: 'inherit',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  filterLabel: {
    color: '#A5AABD',
    fontSize: 14,
    marginRight: 8,
  },
  filtersRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginRight: 24,
  },
  icon: {
    fontWeight: 18,
    color: '#A5AABD',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  dataValues: {
    fontSize: 12,
  },
  tableHead: {
    color: theme.palette.textTheme.main,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
}));
