import { useCallback, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import {
  AutocompleteInput,
  RadioInput,
  SelectInput,
  TextInput,
} from '../inputs';
import {
  useDispatch,
  addResource,
  deleteResource,
  fetchResourceById,
  useSelector,
  systemSelectors,
  getUsersForActivityList,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import {
  convertNullFieldsToEmptyString,
  userTypes,
  warehouseAssetConditionTypes,
} from '../../../lib';
import {
  IdNamePair,
  WarehouseAssetType,
  WarehouseAssetAPI,
  WarehouseVehicleType,
} from '../../../types';
import { Chip, Grid2 } from '@mui/material';

interface Props {
  open: boolean;
  handleClose: () => any;
  warehouseId: string | number;
  assetId?: number;
  assetType?: WarehouseAssetType;
}

interface State
  extends Omit<WarehouseAssetAPI, 'warehouseID' | 'assetType' | 'vehicleType'> {
  assetType: WarehouseAssetType | '';
  vehicleType: WarehouseVehicleType | '';
}

const initialState: State = {
  name: '',
  assetType: '',
  vehicleType: '',
  serialNumber: '',
  modelNumber: '',
  condition: 'Good',
  userIds: [],
};

export function WarehouseAssetForm({
  open,
  handleClose,
  warehouseId,
  assetId,
  assetType,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const assetTypes = useSelector(systemSelectors.warehouseAssetTypes);
  const vehicleTypes = useSelector(systemSelectors.warehouseVehicleTypes);

  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState<State>(initialState);
  const [users, setUsers] = useState<Array<IdNamePair>>([]);
  const [usersObj, setUsersObj] = useState<Record<string, string>>({});

  useEffect(() => {
    (async function () {
      if (assetId) {
        const { data } = await dispatch(
          fetchResourceById<WarehouseAssetAPI>({
            baseUrl: '/warehouses',
            id: warehouseId,
            path: `assets/${assetId}`,
          }),
        );
        if (data) {
          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString(data),
            condition: data.condition ?? 'Good',
            userIds: data.userIds ?? [],
          }));
        }
      }
    })();
  }, [warehouseId, dispatch, assetId, assetType]);

  useEffect(() => {
    (async function () {
      const { data } = await dispatch(
        getUsersForActivityList({
          userTypes: [
            userTypes.Processor,
            userTypes.Scanner,
            userTypes.ScannerProcessor,
          ],
          warehouseID: warehouseId,
        }),
      );
      if (data) {
        const userObj: Record<string, string> = {};
        const formattedData = data.map((d) => {
          const { id, userName, userTypeDisplay } = d;

          const name = `${userName} (${id}) - ${userTypeDisplay}`;
          userObj[id + ''] = name;
          return {
            id,
            name,
          };
        });

        setUsers(formattedData);
        setUsersObj(userObj);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId]);

  const handleSubmit = useCallback(
    async (values: State) => {
      setSubmitting(true);
      const payload: WarehouseAssetAPI = {
        ...values,
        id: assetId || undefined,
        warehouseID: warehouseId,
        assetType: values.assetType || undefined,
        vehicleType: values.vehicleType || undefined,
        condition: values.condition || undefined,
      };
      const { error } = await dispatch(
        addResource<WarehouseAssetAPI, WarehouseAssetAPI>({
          baseUrl: `/warehouses/${warehouseId}/assets`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [assetId, dispatch, handleClose, refresh, warehouseId],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/warehouses',
        id: warehouseId,
        path: `assets/${assetId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, assetId]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          assetId ? 'Edit' : 'Add'
        } warehouse asset`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
      isEdit={!!assetId}
      handleDelete={handleDelete}
    >
      <Grid2 container spacing={5} rowSpacing={2} marginBottom={0}>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 12,
          }}
        >
          <TextInput
            name='name'
            formikProps={formik}
            label='Name'
            required
            className={classes.input}
          />
        </Grid2>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <SelectInput
            name='assetType'
            formikProps={formik}
            label='Asset type'
            options={assetTypes}
            disabled={!!assetId}
            required
            className={classes.input}
          />
        </Grid2>
        {formik.values.assetType === 'Vehicle' && (
          <Grid2
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='vehicleType'
              formikProps={formik}
              label='Vehicle category'
              options={vehicleTypes}
              className={classes.input}
              required
            />
          </Grid2>
        )}
        <Grid2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          size={{
            sm: 12,
          }}
        >
          <AutocompleteInput
            formikProps={formik}
            textFieldProps={{
              name: 'userIds',
              label: 'Users',
            }}
            autocompleteProps={{
              // className: classes.smallInput,
              options: users,
              multiple: true,
              renderTags: (tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  const { key, ...otherProps } = getTagProps({ index });
                  return (
                    <Chip key={key} label={usersObj[option]} {...otherProps} />
                  );
                }),
            }}
          />
        </Grid2>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='serialNumber'
            formikProps={formik}
            label='Serial #'
            className={classes.input}
          />
        </Grid2>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='modelNumber'
            formikProps={formik}
            label='Model #'
            className={classes.input}
          />
        </Grid2>
        <Grid2
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <RadioInput
            formikProps={formik}
            name='condition'
            radiosRow
            title=''
            size='small'
            radios={[
              {
                label: 'Good condition',
                value: warehouseAssetConditionTypes.GOOD,
              },
              { label: 'Damaged', value: warehouseAssetConditionTypes.BAD },
            ]}
          />
        </Grid2>
      </Grid2>
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { WarehouseAssetForm } })((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: '100%',
  },
}));

const schema = object().shape({
  name: string().required('Required'),
  assetType: string().required('Required'),
  vehicleType: string()
    .nullable()
    .when('assetType', {
      is: 'Vehicle' as WarehouseAssetType,
      then: string().required('Required'),
    }),
});
