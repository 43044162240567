import { useCallback, useState, useEffect, useRef, Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Grid2 as Grid, Chip } from '@mui/material';
import { useFormik } from 'formik';
import { object, string, array, number } from 'yup';
import { FormModal } from './FormModal';
import {
  useDispatch,
  assignUsersToService,
  getUsersForActivityList,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { TextInput, AutocompleteInput, CheckboxInput } from '../inputs';
import { userTypes } from '../../../lib';
import { InfoMessage } from '../InfoMessage';

const initialState = {
  nickname: '',
  isCompleted: false,
  userIds: [],
};

/**
 *
 * @param {Props} param0
 * @returns
 */
export function AssignUserToServiceForm({
  open,
  handleClose,
  setViewVersion,
  currentData,
  entityId,
  warehouseId,
  serviceType,
  taskType = 'SpecialService',
  title = 'Manual Activity',
  fieldsToHide,
}) {
  const { classes, cx, css } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);
  const [users, setUsers] = useState([]);
  const [usersObj, setUsersObj] = useState({});
  const [disabledUsers, setDisabledUsers] = useState([]);

  const userAssigneeIdsMapping = useRef({});

  useEffect(() => {
    if (currentData) {
      const {
        nickname,
        isCompleted,
        distinctUserIDs,
        assigneesAlreadyTracking,
        assigneeActivities,
      } = currentData;
      const newState = {
        nickname: nickname ?? '',
        isCompleted: isCompleted ?? false,
        userIds: distinctUserIDs ?? [],
      };

      if (Array.isArray(assigneeActivities)) {
        const assigneeIds = assigneeActivities.reduce((acc, cur) => {
          const { userID, assigneesID } = cur;
          acc[userID + ''] = assigneesID;
          return acc;
        }, {});

        userAssigneeIdsMapping.current = assigneeIds;
      }

      setDisabledUsers(assigneesAlreadyTracking ?? []);
      setState((cur) => ({ ...cur, ...newState }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function () {
      const { data } = await dispatch(
        getUsersForActivityList({
          userTypes: [
            userTypes.Admin,
            userTypes.Processor,
            userTypes.Scanner,
            userTypes.ScannerProcessor,
            userTypes.CustomerService,
            userTypes.TruckingSalesperson,
          ],
          warehouseID: warehouseId,
        }),
      );
      if (data) {
        const userObj = {};
        const formattedData = data.map((d) => {
          const { id, userName, userTypeDisplay, vehicles } = d;

          const name = `${userName} (${id}) - ${userTypeDisplay} ${
            Array.isArray(vehicles) && vehicles.length
              ? `- ${vehicles.join(', ')}`
              : ''
          }`;
          userObj[id + ''] = name;
          return {
            id,
            name,
          };
        });

        setUsers(formattedData);
        setUsersObj(userObj);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = generatePayload(
        values,
        userAssigneeIdsMapping.current,
        taskType,
      );
      const { error } = await dispatch(
        assignUsersToService({ entityId, warehouseId, payload, serviceType }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entityId, serviceType],
  );

  const formik = useFormik({
    initialValues: state,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const handleDelete = useCallback(() => {
    formik.setFieldValue('userIds', []);
    formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>{title}</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
      isEdit={!!currentData}
      handleDelete={handleDelete}
      hideDeleteBtn={!!disabledUsers.length}
    >
      <div className={classes.layout}>
        {!fieldsToHide?.nickname && (
          <Fragment>
            <div className={cx(classes.sectionTitle, css({ marginTop: 8 }))}>
              Task info
            </div>
            <Grid container spacing={5} rowSpacing={2}>
              <Grid
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 12,
                }}
              >
                <TextInput
                  name='nickname'
                  label='Nickname (max 75 characters)'
                  formikProps={formik}
                  className={classes.input}
                  slotProps={{
                    htmlInput: {
                      maxLength: 75,
                    },
                  }}
                  required
                />
              </Grid>
            </Grid>
          </Fragment>
        )}
        <div
          className={cx(classes.sectionTitle, {
            [css({ marginTop: 0 })]: fieldsToHide?.nickname,
          })}
        >
          {' '}
          Assign employees
        </div>
        {!!disabledUsers.length && (
          <InfoMessage message='Only users that did not start tracking time can be removed.' />
        )}
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 12,
            }}
          >
            <AutocompleteInput
              formikProps={formik}
              textFieldProps={{
                name: 'userIds',
                label: 'Users',
                required: true,
              }}
              autocompleteProps={{
                // className: classes.smallInput,
                options: users,
                multiple: true,
                renderTags: (tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    const { key, ...otherProps } = getTagProps({ index });
                    return (
                      <Chip
                        key={key}
                        label={usersObj[option]}
                        {...otherProps}
                        disabled={disabledUsers.includes(option)}
                      />
                    );
                  }),
              }}
            />
          </Grid>
          {!fieldsToHide?.isCompleted && (
            <Grid
              style={{ paddingBottom: 0 }}
              size={{
                sm: 12,
              }}
            >
              <CheckboxInput
                formikProps={formik}
                checkboxes={[
                  {
                    labelProps: {
                      label: 'Completed?',
                    },
                    checkboxProps: {
                      name: 'isCompleted',
                    },
                  },
                ]}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { AssignUserToServiceForm } })(
  (theme) => ({
    layout: {
      padding: '0 16px 16px 16px',
    },
    title: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 600,
      padding: '24px 16px',
    },
    userName: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    optionValue: {
      fontSize: 14,
      letterSpacing: 0,
    },
    input: {
      width: '100%',
    },
    item: {
      paddingBottom: 0,
    },
    sectionTitle: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 'bold',
      marginTop: 50,
      marginBottom: 16,
    },
  }),
);

const schema = object().shape({
  nickname: string('Must be string').required(),
  userIds: array()
    .of(number().integer('Please enter a valid integer'))
    .min(1, 'At least 1 user required')
    .required('Required'),
});

/**
 *
 * @param {object} state
 * @param {object} assigneeIdsMapping
 * @returns {import('../../../types').AssignUsersRequest}
 */
function generatePayload(state, assigneeIdsMapping = {}, taskType) {
  const { nickname, isCompleted, userIds } = state;
  return {
    nickname,
    isCompleted,
    taskType,
    assignees: userIds.map((u) => ({
      id: assigneeIdsMapping[u + ''],
      userID: u,
    })),
  };
}

/**
 * @typedef {Object} Props
 * @property {boolean} open - Indicates if the form is open
 * @property {Function} handleClose - Function to close the form
 * @property {Function} [setViewVersion] - Function to set the view version
 * @property {Object} [currentData] - Current data for the form
 * @property {string|number} entityId - ID of the entity
 * @property {string|number} [warehouseId] - ID of the warehouse
 * @property {import('../../../types').SystemServiceType} serviceType - Type of service
 * @property {import('../../../types').FulfilledServiceAssigneeTaskType} [taskType='SpecialService'] - Type of task
 * @property {string} [title] - Title of the form
 * @property {Object} [fieldsToHide] - Fields to hide in the form
 */
