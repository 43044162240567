import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import qs from 'query-string';
import { store } from '../store';
import { removeUserData } from '../state';

export interface AuthResponseError {
  code: number | string;
  message?: string;
  Message?: string;
  title?: string;
  status?: number;
}

export interface AxiosAuthResponse<T = any, D = any>
  extends AxiosResponse<T, D> {
  error?: AuthResponseError;
}

type Url = string | Record<string, any>;
type Callback = (data: any) => typeof data;

interface AnonPost<TRequest = any> {
  url: Url;
  data: TRequest;
  callback?: Callback;
  defaultResponseData?: any;
  requestConfig?: AxiosRequestConfig;
  logoutOnUnauthorized?: boolean;
}

const {
  /** The base URL for the API. */
  VITE_API_BASE_URL,
  MODE,
} = import.meta.env;

const IS_DEV = MODE === 'development';

/** Token to apply to each request. */
let authToken: string | undefined;
let authExpirationDate: Date | undefined;

/** Id of the interceptor used to apply auth headers. */
let authInterceptorId: number | undefined;

/** Axios instance to use for authenticated requests. */
export const AuthRequest = axios.create({
  baseURL: VITE_API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

/** Axios instance to use for non authenticated requests. */
export const AnonRequest = axios.create({
  baseURL: VITE_API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

/** Default response handler.
 * @param {AxiosAuthResponse} response */
function defaultResponseCallback<T = any, D = any>(
  response: AxiosAuthResponse<T, D>,
) {
  return response;
}
/** Performs a `DELETE` with authorization.
 * @param {string | [string, any]} url
 * @param {AxiosRequestConfig} requestConfig
 * @param {(response:AxiosAuthResponse)=>AxiosAuthResponse} [callback]
 * @param {any} [defaultResponseData]
 * @returns {Promise<AxiosAuthResponse>} */
export async function authDelete<TResponse = any, TRequest = any>(
  url: Url,
  requestConfig?: AxiosRequestConfig<TRequest>,
  callback: Callback = defaultResponseCallback<TResponse>,
  defaultResponseData: any = [],
): Promise<AxiosAuthResponse<TResponse>> {
  const nurl = normalizeURL(url);
  return AuthRequest.delete(nurl, requestConfig)
    .catch(normalizeResponseError('DELETE', nurl, defaultResponseData))
    .then(callback);
}
/** Performs a GET with authorization.
 * @param {string | [string, any]} url
 * @param {(response:AxiosAuthResponse)=>AxiosAuthResponse} [callback]
 * @param {any} [defaultResponseData]
 * @param {AxiosRequestConfig} requestConfig
 * @returns {Promise<AxiosAuthResponse>} */
export async function authGet<TResponse = any, TRequest = any>(
  url: Url,
  callback: Callback = defaultResponseCallback<TResponse>,
  defaultResponseData: any = [],
  requestConfig?: AxiosRequestConfig<TRequest>,
): Promise<AxiosAuthResponse<TResponse>> {
  const nurl = normalizeURL(url);
  return AuthRequest.get(nurl, requestConfig)
    .catch(normalizeResponseError('GET', nurl, defaultResponseData))
    .then(callback);
}

/** Performs a GET without authorization.
 * @param {string | [string, any]} url
 * @param {(response:AxiosAuthResponse)=>AxiosAuthResponse} [callback]
 * @returns {Promise<AxiosAuthResponse>} */
export async function anonGet<TResponse = any, TRequest = any>(
  url: Url,
  callback: Callback = defaultResponseCallback<TResponse>,
  defaultResponseData: any = [],
  requestConfig?: AxiosRequestConfig<TRequest>,
): Promise<AxiosAuthResponse<TResponse>> {
  const nurl = normalizeURL(url);
  return AnonRequest.get(nurl, requestConfig)
    .catch(normalizeResponseError('GET', nurl, defaultResponseData))
    .then(callback);
}

/** Performs a POST with authorization.
 * @param {string | [string, any]} url
 * @param {(response:AxiosAuthResponse)=>AxiosAuthResponse} [callback]
 * @param {any} [defaultResponseData]
 * @returns {Promise<AxiosAuthResponse>} */
export async function authPost<TResponse = any, TRequest = Record<string, any>>(
  url: Url,
  data: TRequest,
  callback: Callback = defaultResponseCallback<TResponse>,
  defaultResponseData: any = {},
  requestConfig?: AxiosRequestConfig,
): Promise<AxiosAuthResponse<TResponse>> {
  const nurl = normalizeURL(url);
  return AuthRequest.post(nurl, data, requestConfig)
    .catch(normalizeResponseError('POST', nurl, defaultResponseData))
    .then(callback);
}

/** Performs a POST without authorization.
 * @param {AnonPost} data
 * @returns {Promise<AxiosAuthResponse>} */
export async function anonPost<
  TResponse = any,
  TRequest = Record<string, any>,
>({
  url,
  data,
  callback = defaultResponseCallback<TResponse>,
  defaultResponseData = {},
  requestConfig,
  logoutOnUnauthorized,
}: AnonPost<TRequest>): Promise<AxiosAuthResponse<TResponse>> {
  const nurl = normalizeURL(url);
  return AnonRequest.post(nurl, data, requestConfig)
    .catch(
      normalizeResponseError(
        'POST',
        nurl,
        defaultResponseData,
        logoutOnUnauthorized,
      ),
    )
    .then(callback);
}

/** Performs a PUT with authorization.
 * @param {string | [string, any]} url
 * @param {(response:AxiosAuthResponse)=>AxiosAuthResponse} [callback]
 * @param {any} [defaultResponseData]
 * @returns {Promise<AxiosAuthResponse>} */
export async function authPut<TResponse = any, TRequest = Record<string, any>>(
  url: Url,
  data: TRequest,
  callback: Callback = defaultResponseCallback<TResponse>,
  defaultResponseData: any = {},
  requestConfig?: AxiosRequestConfig,
): Promise<AxiosAuthResponse<TResponse>> {
  const nurl = normalizeURL(url);
  return AuthRequest.put(nurl, data, requestConfig)
    .catch(normalizeResponseError('PUT', nurl, defaultResponseData))
    .then(callback);
}
/**
 * @param {"GET" | "POST" | "PUT" | "DELETE"} operation
 * @param {string} nurl
 */
export function normalizeResponseError(
  operation: 'GET' | 'POST' | 'PUT' | 'DELETE',
  nurl: string,
  defaultResponseData: [] | object = {},
  logoutOnUnauthorized = true,
) {
  return (err: unknown) => {
    const defaultResponse: AxiosAuthResponse = {
      config: {},
      data: defaultResponseData,
      error: { code: 500 },
      headers: {},
      status: 0,
      statusText: '',
    };
    let response: AxiosAuthResponse = defaultResponse;

    if (axios.isAxiosError(err)) {
      const responseStatus = err.response?.status;
      response = err.response || defaultResponse;
      response.error = {
        ...response.data,
      };
      response.data = defaultResponseData;

      if (logoutOnUnauthorized && responseStatus === 401) {
        removeAuthRequestToken();
        store.dispatch(removeUserData(null));
      }
      if (responseStatus === 403 && !response.error?.message) {
        response.error!.message = 'Unauthorized';
      }
      if (IS_DEV) {
        console.warn(
          `DEFAULT DATA returned for ${operation} "${nurl}"`,
          defaultResponseData,
        );
      }
    }

    return response;
  };
}
/** @param {string | [string, object]} url */
export function normalizeURL(url: Url) {
  if (!Array.isArray(url)) {
    return url;
  }
  const len = url.length;
  if (len < 2) {
    return url[0];
  }
  const query = qs.stringify(url[1]);
  if (query.length < 1) {
    return url[0];
  }
  return `${url[0]}?${query}`;
}

/** Returns true if an auth token has been set and is not expired.
 * @returns {boolean}
 */
export function hasAuthRequestToken(): boolean {
  return !!authToken && !!authExpirationDate && authExpirationDate > new Date();
}

/** Assigns the token to be sent with each auth request.
 * @param {string} token Server token.
 * @param {string} expiration Date and Time in ISO 8601 format.
 */
export function setAuthRequestToken(token: string, expiration: string) {
  if (arguments.length < 2) {
    throw new Error('Token and expiration required.');
  }
  removeAuthRequestToken();
  if (token) {
    authToken = token;
    authExpirationDate = new Date(expiration);
    authInterceptorId = AuthRequest.interceptors.request.use(
      applyAuthHeaders,
      // CONSIDER: An error handler can be passed. (Useful for refresh token
      // logic, to retry requests after refreshing the access token.)
      // (err) => Promise.reject(err),
    );
  }
}
/** Removes the token to be sent with each auth request. */
export function removeAuthRequestToken() {
  authToken = undefined;
  authExpirationDate = undefined;
  if (authInterceptorId !== undefined) {
    AuthRequest.interceptors.request.eject(authInterceptorId);
    authInterceptorId = undefined;
  }
}
/** @param {AxiosRequestConfig} config */
function applyAuthHeaders(config: AxiosRequestConfig) {
  // We know that headers is defined because we already set the contentType
  config.headers!.Authorization = `Bearer ${authToken}`;
  return config;
}

// #region Typedefs

/** @typedef {import('axios').AxiosResponse} AxiosResponse */
/** @typedef {import('axios').AxiosPromise} AxiosPromise */
/** @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig */
/** @typedef {object} AuthResponseError
 * @property {number} code
 * @property {string} message
 */
/** @typedef {AxiosResponse & {error?:AuthResponseError}} AxiosAuthResponse */
/** @typedef {object} CompatAPIResult
 * @property {boolean} success True if successful.
 * @property {object} data Data returned from server (or default data).
 * @property {boolean} loading Always `false`.
 * @property {string} [message] Error message from server.
 * @property {number} [code] Error code from server.
 */
// #endregion
