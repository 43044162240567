import _merge from 'lodash.merge';
import _cloneDeep from 'lodash.clonedeep';
import qs from 'query-string';
import {
  setListLoading,
  showNotification,
  showError,
  populateList,
  updateListParams,
  setUILoading,
} from '../features';
import {
  authGet,
  authPost,
  authDelete,
  authPut,
  arrayToObjByEnumOrId,
  anonPost,
  AuthResponseError,
} from '../../lib';
import { AppThunk, NormalizeReturn } from '../types';

interface FetchList {
  resource: string;
  baseUrl?: string;
  order?: string | number;
  orderBy?: string;
  // start: string | number;
  // end: string | number;
  page: number;
  rowsPerPage?: number;
  noPagination?: boolean;
  sortable: boolean;
  filter?: object;
}

interface FetchResource {
  baseUrl: string;
  id: number | string;
  path?: string;
  query?: Record<string, any>;
  showErrorMessage?: boolean;
  shouldSetUILoading?: boolean;
  abortController?: AbortSignal;
  successMessage?: string;
}

interface AddResource<T extends object = Record<string, any>> {
  baseUrl: string;
  query?: Record<string, any>;
  payload?: T;
  message?: string;
  shouldSetUILoading?: boolean;
  shouldShowErrorMessage?: boolean;
  tokenOverride?: string;
}

interface CustomPostProps<TRequest = Record<string, any>> {
  url: string;
  queryParams?: Record<string, any>;
  body: TRequest;
  authToken?: string;
}

function fetchList({
  resource,
  baseUrl,
  order,
  orderBy,
  page,
  rowsPerPage,
  filter = {},
  noPagination,
  sortable = true,
}: FetchList): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch, getState) => {
    const {
      lists: {
        resources: {
          [resource]: {
            params: { selectedRows = {}, selectedIds = [] } = {},
          } = {},
        } = {},
      } = {},
    } = getState();

    dispatch(setListLoading(true));
    if (!rowsPerPage) {
      return Promise.resolve({ data: {} });
    }
    const response = await authGet([
      baseUrl || '/' + resource,
      {
        order: sortable ? order : undefined,
        orderBy: sortable ? orderBy : undefined,
        perPage: !noPagination ? rowsPerPage : undefined,
        pageNumber: !noPagination ? page + 1 : undefined, // the server is 1 based
        ...filter,
      },
    ]);
    dispatch(setListLoading(false));
    const { data, error } = response;
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'Failed to fetch the data',
        }),
      );
      return { error };
    }
    const { results, numberOfRows } = data;
    const sortIds = [];
    const rows = arrayToObjByEnumOrId(results, sortIds);

    const params = {
      numberOfRows,
      filter,
      queryParams: qs.parse(window.location.search, { parseBooleans: true }),
      hasList: true,
      selectedRows: {},
    };

    // if there are selected rows we need to update that value with the new values
    if (selectedIds?.length) {
      const updatedSelectedRows = selectedIds.reduce((acc, cur) => {
        const newRow = rows[cur];
        const currentSelectedRow = selectedRows?.[cur];
        if (currentSelectedRow) {
          acc[cur] = newRow
            ? _merge(_cloneDeep(currentSelectedRow), _cloneDeep(newRow))
            : currentSelectedRow;
        }
        return acc;
      }, {});

      params.selectedRows = updatedSelectedRows;
    }

    dispatch(
      populateList({
        list: resource,
        data: {
          name: resource,
          data: rows,
          ids: sortIds,
          params,
        },
      }),
    );
    return { data };
  };
}

export function customPost<TResponse = any, TRequest = Record<string, any>>({
  url,
  queryParams,
  body,
  authToken,
}: CustomPostProps<TRequest>): AppThunk<Promise<NormalizeReturn<TResponse>>> {
  return async (dispatch) => {
    const { data, error } = await anonPost<TResponse, TRequest>({
      url: [url, queryParams],
      data: body,
      requestConfig: {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken ? `Bearer ${authToken}` : false,
        },
      },
    });
    if (error) {
      return { error };
    }
    return { data };
  };
}
function fetchResourceById<T = any>({
  baseUrl,
  id,
  path: _path,
  query = {},
  showErrorMessage = true,
  shouldSetUILoading,
  abortController,
}: FetchResource): AppThunk<Promise<NormalizeReturn<T>>> {
  return async (dispatch) => {
    shouldSetUILoading && dispatch(setUILoading(true));
    const path = _path ? `/${_path}` : '';
    const requestConfig = abortController
      ? { signal: abortController }
      : undefined;
    const response = await authGet<T>(
      [`${baseUrl}/${id}${path}`, query],
      undefined,
      undefined,
      requestConfig,
    );
    shouldSetUILoading && dispatch(setUILoading(false));
    const { data, error } = response;
    if (error) {
      if (showErrorMessage) {
        dispatch(
          showError({
            message: error.message || error.title || 'Something went wrong',
          }),
        );
      }
      return { error };
    }
    return { data };
  };
}

function deleteResource<TResponse = any>({
  baseUrl,
  id,
  path: _path,
  query = {},
  successMessage = 'Changes saved',
  shouldSetUILoading,
}: FetchResource): AppThunk<Promise<NormalizeReturn<TResponse>>> {
  return async (dispatch) => {
    shouldSetUILoading && dispatch(setUILoading(true));
    const path = _path ? `/${_path}` : '';
    const response = await authDelete([`${baseUrl}/${id}${path}`, query]);
    shouldSetUILoading && dispatch(setUILoading(false));
    const { error } = response;
    if (error) {
      dispatch(
        showError({
          message: error.message || 'Something went wrong',
        }),
      );
      return { error };
    }
    dispatch(showNotification({ message: successMessage }));
    return { data: response.data || 'success' };
  };
}

function addResource<
  TResponse = any,
  TRequest extends object = Record<string, any>,
>({
  baseUrl,
  query = {},
  payload = {} as TRequest,
  message,
  shouldSetUILoading,
  shouldShowErrorMessage = true,
  tokenOverride,
}: AddResource<TRequest>): AppThunk<Promise<NormalizeReturn<TResponse>>> {
  return async (dispatch) => {
    shouldSetUILoading && dispatch(setUILoading(true));
    let data: TResponse | undefined;
    let error: AuthResponseError | undefined;

    if (tokenOverride) {
      const { data: responseData, error: errorResponse } = await dispatch(
        customPost<TResponse, TRequest>({
          url: baseUrl,
          queryParams: query,
          body: payload,
          authToken: tokenOverride,
        }),
      );
      data = responseData;
      error = errorResponse;
    } else {
      const { data: responseData, error: errorResponse } = await authPost<
        TResponse,
        TRequest
      >([baseUrl, query], {
        ...payload,
      });
      data = responseData;
      error = errorResponse;
    }
    shouldSetUILoading && dispatch(setUILoading(false));
    if (error) {
      shouldShowErrorMessage &&
        dispatch(
          showError({
            message: error.message || error.title || 'Something went wrong',
          }),
        );
      return { error };
    }
    if (message) {
      dispatch(showNotification({ message }));
    }
    return { data };
  };
}

function updateResource<
  TResponse = any,
  TRequest extends object = Record<string, any>,
>({
  baseUrl,
  query = {},
  payload = {} as TRequest,
  message,
  shouldSetUILoading,
  shouldShowErrorMessage = true,
}: AddResource<TRequest>): AppThunk<Promise<NormalizeReturn<TResponse>>> {
  return async (dispatch) => {
    shouldSetUILoading && dispatch(setUILoading(true));
    const response = await authPut<TResponse, TRequest>([baseUrl, query], {
      ...payload,
    });
    shouldSetUILoading && dispatch(setUILoading(false));
    const { data, error } = response;
    if (error) {
      shouldShowErrorMessage &&
        dispatch(
          showError({
            message: error.message || 'Something went wrong',
          }),
        );
      return { error };
    }
    if (message) {
      dispatch(showNotification({ message }));
    }
    return { data };
  };
}

function addSelectedIds({
  resource,
  newIds,
}: {
  resource: string;
  newIds: Array<number | string>;
}): AppThunk {
  return async (dispatch, getState) => {
    const {
      lists: {
        resources: {
          [resource]: {
            data: rows = {},
            params: { selectedRows = {} } = {},
          } = {},
        } = {},
      } = {},
    } = getState();

    const { newSelectedRows } = getSelectedRows({
      newIds,
      rows,
      currentSelectedRows: selectedRows,
    });

    dispatch(
      updateListParams({
        list: resource,
        params: { selectedIds: newIds, selectedRows: newSelectedRows },
      }),
    );
  };
}

function getSelectedRows({
  newIds,
  rows,
  currentSelectedRows,
}: {
  newIds: Array<number | string>;
  rows: Record<string, any>;
  currentSelectedRows?: Record<string, any>;
}) {
  let rowsCollection = _cloneDeep(rows);
  if (currentSelectedRows) {
    // If the user selects an id and then adds a filter which does not include the record that was selected,
    // they will loose that record since we get the selectedRows from the rows. So we need to add
    // the current selected rows to the collection
    rowsCollection = _merge(rowsCollection, _cloneDeep(currentSelectedRows));
  }

  const newSelectedRows = Object.keys(rowsCollection)
    .filter((k) => newIds?.map((i) => i.toString()).includes(k))
    .reduce((acc, cur) => {
      acc[cur] = rowsCollection[cur];
      return acc;
    }, {});

  return { newSelectedRows };
}

function clearSelectedIds({ resource }: { resource: string }): AppThunk {
  return async (dispatch) => {
    dispatch(
      updateListParams({
        list: resource,
        params: { selectedIds: [], selectedRows: {} },
      }),
    );
  };
}

export {
  fetchList,
  fetchResourceById,
  deleteResource,
  addResource,
  updateResource,
  addSelectedIds,
  getSelectedRows,
  clearSelectedIds,
};
