import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { fetchResourceById, addResource } from '../../../state';
import { FormModal, AutoCompleteScanner } from '../..';
import { useRefresh } from '../../../hooks';
import { InfoMessage } from '../InfoMessage';

export function PullOrderForm({
  handleClose,
  open,
  currentLocation: _currentLocation,
  doSave,
  externalId,
  handlePull,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [currentLocation, setCurrentLocation] = useState(
    _currentLocation ?? '',
  );
  const [location, setLocation] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const locationRef = useRef();

  useEffect(() => {
    (async function () {
      if (_currentLocation === undefined) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/orders',
            id: externalId,
            path: 'storage-location',
          }),
        );

        if (data) {
          setCurrentLocation(data.location);
        }
      }
    })();
  }, [_currentLocation, dispatch, externalId]);

  const locationOptions = useMemo(
    () =>
      currentLocation ? [{ id: currentLocation, name: currentLocation }] : [],
    [currentLocation],
  );

  const handleLocationSelect = useCallback(async (value) => {
    setLocation(value);
  }, []);

  const handleChange = useCallback((event) => {
    const { value } = event.target;
    setLocation(value);
  }, []);

  const scanLocationValidatorFunc = useCallback(
    ({ value }) => {
      var foundResult = locationOptions?.filter((o) => o.id === value);
      if (!foundResult.length) {
        return {
          error: 'There were no results found for the location entered.',
          isNotFound: true,
        };
      }
      return { data: value };
    },
    [locationOptions],
  );

  const handleSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      const { error } = scanLocationValidatorFunc({ value: location });
      if (location && !error) {
        if (doSave) {
          setSubmitting(true);
          const { error: pullError } = await dispatch(
            addResource({
              baseUrl: `/orders/${externalId}/pull`,
              payload: {
                externalID: externalId,
                location,
              },
              message: 'Changes saved',
            }),
          );

          if (!pullError) {
            refresh();
            handleClose();
            setSubmitting(false);
          }
        } else {
          handlePull && handlePull();
        }
      }
    },
    [
      dispatch,
      doSave,
      externalId,
      handleClose,
      handlePull,
      location,
      refresh,
      scanLocationValidatorFunc,
    ],
  );

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title='Pull order'
      callback={handleSubmit}
      paperProps={{ style: { width: 512 } }}
      btnText='Pull order'
      disableCancel
      typeSubmit
      submitting={submitting}
    >
      <div className={classes.layout}>
        {!doSave && (
          <InfoMessage message='Changes you make will be saved when the order is shipped' />
        )}
        <AutoCompleteScanner
          scanValidatorFunc={scanLocationValidatorFunc}
          onScan={handleLocationSelect}
          wrapInCard={false}
          options={locationOptions}
          optionsLabelProperties={['name']}
          optionsValueProperties={['id']}
          label='Location'
          scanTextOverride={location}
          onInputChange={handleChange}
          ref={locationRef}
          name='location'
          // className={classes.noBtnInput}
        />
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));
