import { Fragment, useMemo } from 'react';
import {
  Tooltip,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid2 as Grid,
} from '@mui/material';
import { limitStringSize, getDimensionsString, formatDate } from '../../lib';
import { LongTextHoverTooltip } from '../../components';
import { PickNStickIcon, ExpandMoreIcon } from '../../assets';
import PickAndStickSVG from '../../assets/images/pick-and-stick.svg';
import { useSelector, orderSelectors } from '../../state';
import { styles } from './ItemsTab.styles';

export function ItemsTab() {
  const itemsRemaining = useSelector(orderSelectors.itemsRemaining);
  const itemsCompleted = useSelector(orderSelectors.itemsCompleted);
  const selectedOrder = useSelector(orderSelectors.selectedOrder);

  const itemsRemainingKeys = Object.keys(itemsRemaining).filter(
    (key) => itemsRemaining[key].quantity > 0,
  );
  const itemsCompletedKeys = Object.keys(itemsCompleted);

  return (
    <Fragment>
      {itemsRemainingKeys.length ? (
        <RenderItems
          items={itemsRemaining}
          keys={itemsRemainingKeys}
          type='remaining'
          selectedOrder={selectedOrder}
        />
      ) : (
        <EmptyItemsRemaining />
      )}
      {itemsCompletedKeys.length ? (
        <RenderItems
          items={itemsCompleted}
          keys={itemsCompletedKeys}
          type='completed'
          selectedOrder={selectedOrder}
        />
      ) : (
        <EmptyItemsCompleted />
      )}
      <RenderPhysicalItems />
    </Fragment>
  );
}

function RenderItems({ items, keys, type, selectedOrder }) {
  const { classes, cx } = styles();
  const isReship = useSelector(orderSelectors.isReship);
  const isItemsRemaining = type === 'remaining';

  return (
    <div
      className={cx(classes.remainingContainer, {
        [classes.remainingBg]: isItemsRemaining,
      })}
    >
      <div className={classes.subHeader}>
        {getSectionTitle({ isItemsRemaining, isReship })}
      </div>
      <div className={classes.visibleSidebarContainer}>
        {keys.map((item, index) => {
          const {
            quantity,
            sku,
            description,
            lengthInches,
            heightInches,
            widthInches,
            weightPounds,
            hasEverPickedAndSticked,
            isPrimaryUnitPallet,
          } = items[item];

          const isPickAndShipItem =
            selectedOrder.isFreight &&
            !selectedOrder.defaultShipsWholePallet &&
            isPrimaryUnitPallet;

          return (
            <Fragment key={sku}>
              <div className={classes.itemContainer}>
                <span className={classes.itemQty}>{quantity}</span>
                <div className={classes.itemDetails}>
                  <div className={classes.skuContainer}>
                    <span className={classes.itemSku}>{sku}</span>
                    {isPickAndShipItem && (
                      <PickAndStickSVG className={classes.palletImg} />
                    )}
                  </div>
                  <div>
                    <Tooltip title={description}>
                      <span className={classes.itemDetailsItem}>
                        {limitStringSize(description, 15, true)}
                      </span>
                    </Tooltip>
                    <span className={classes.itemDetailsItem}>|</span>
                    <span className={classes.itemDetailsItem}>
                      {getDimensionsString({
                        length: lengthInches,
                        width: widthInches,
                        height: heightInches,
                      })}
                    </span>
                    <span className={classes.itemDetailsItem}>|</span>
                    <span className={classes.itemDetailsItem}>{`${
                      weightPounds ?? '-'
                    } lbs`}</span>
                  </div>
                </div>
                {isItemsRemaining && hasEverPickedAndSticked && (
                  <PickNStickIcon className={classes.remainingPickNStickIcon} />
                )}
              </div>
              {index < Object.keys(items).length - 1 && (
                <Divider className={classes.itemDivider} />
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

function RenderPhysicalItems() {
  const { classes } = styles();

  const physicalItems = useSelector(orderSelectors.physicalItems);
  const physicalItemsKeys = useMemo(
    () => (physicalItems ? Object.keys(physicalItems) : []),
    [physicalItems],
  );

  return (
    <div style={{ padding: '0px 14px' }}>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <div className={classes.subHeader} style={{ marginBottom: 0 }}>
            Allocated inventory
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ paddingRight: 0, paddingLeft: 0 }}>
          <div style={{ width: '100%' }} className={classes.remainingContainer}>
            {!!physicalItemsKeys?.length ? (
              <Fragment>
                <Grid container spacing={1} style={{ marginBottom: 8 }}>
                  <Grid
                    className={classes.physicalItemsHead}
                    style={{ textAlign: 'start' }}
                    size={{
                      sm: 4,
                    }}
                  >
                    Serial
                  </Grid>
                  <Grid
                    className={classes.physicalItemsHead}
                    style={{ textAlign: 'center' }}
                    size={{
                      sm: 4,
                    }}
                  >
                    Lot
                  </Grid>
                  <Grid
                    className={classes.physicalItemsHead}
                    style={{ textAlign: 'end' }}
                    size={{
                      sm: 4,
                    }}
                  >
                    Expiration
                  </Grid>
                </Grid>
                {physicalItemsKeys.map((sku, i) => {
                  const itemsPerSku = physicalItems[sku];
                  if (!itemsPerSku?.length) return null;

                  return (
                    <div key={sku} style={{ marginTop: 8 }}>
                      <p className={classes.skuHeader}>{sku}</p>
                      <Grid container spacing={1}>
                        {itemsPerSku.map((i) => {
                          const {
                            id,
                            serialNumber,
                            lotNumber,
                            importedLotNumber,
                            importedExpirationDate,
                            expirationDate,
                          } = i;
                          return (
                            <Fragment key={id}>
                              <Grid
                                className={classes.physicalItem}
                                style={{ textAlign: 'start' }}
                                size={{
                                  sm: 4,
                                }}
                              >
                                <LongTextHoverTooltip
                                  text={serialNumber || '--'}
                                  maxLength={15}
                                />
                              </Grid>
                              <Grid
                                className={classes.physicalItem}
                                style={{ textAlign: 'center' }}
                                size={{
                                  sm: 4,
                                }}
                              >
                                <LongTextHoverTooltip
                                  text={importedLotNumber || lotNumber || '--'}
                                  maxLength={15}
                                />
                              </Grid>
                              <Grid
                                className={classes.physicalItem}
                                style={{ textAlign: 'end' }}
                                size={{
                                  sm: 4,
                                }}
                              >
                                {formatDate(
                                  importedExpirationDate || expirationDate,
                                ) || '--'}
                              </Grid>
                            </Fragment>
                          );
                        })}
                      </Grid>
                      {i < physicalItemsKeys.length - 1 && (
                        <Divider className={classes.itemDivider} />
                      )}
                    </div>
                  );
                })}
              </Fragment>
            ) : (
              <EmptyPhysicalItems />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

function EmptyItemsRemaining() {
  const { classes, cx } = styles();
  const isReship = useSelector(orderSelectors.isReship);

  return (
    <div className={cx(classes.remainingContainer, classes.remainingBg)}>
      <div className={classes.subHeader}>
        {getSectionTitle({ isItemsRemaining: true, isReship })}
      </div>
      <div className={classes.emptyText}>
        <div>There are no items</div>
        <div>remaining in this order</div>
      </div>
    </div>
  );
}

function EmptyItemsCompleted() {
  const isReship = useSelector(orderSelectors.isReship);

  const { classes } = styles();
  return (
    <div className={classes.remainingContainer}>
      <div className={classes.subHeader}>
        {getSectionTitle({ isItemsRemaining: false, isReship })}
      </div>
      <div className={classes.emptyText}>
        <div>As you scan items they will </div>
        <div>appear here</div>
      </div>
    </div>
  );
}

function EmptyPhysicalItems() {
  const { classes } = styles();
  return (
    <div className={classes.remainingContainer}>
      <div className={classes.emptyText}>
        <div>There are no allocation details </div>
        <div>for this order</div>
      </div>
    </div>
  );
}

function getSectionTitle({ isItemsRemaining, isReship }) {
  if (isReship) {
    return `Items ${isItemsRemaining ? 'ordered' : 'reshipped'}`;
  } else {
    return `Items ${isItemsRemaining ? 'remaining' : 'completed'}`;
  }
}
