import { makeStyles } from 'tss-react/mui';
import { CheckCircleIcon, InfoIcon } from '../../assets';

interface Props {
  isConfirmed?: boolean;
  confirmedText: string;
  unconfirmedText: string;
  containerClassName?: string;
  iconsStyles?: React.CSSProperties;
}

export function ConfirmStatusIcons({
  isConfirmed = false,
  confirmedText,
  unconfirmedText,
  containerClassName,
  iconsStyles,
}: Props) {
  const { classes, cx } = useStyles();
  return isConfirmed ? (
    <span className={cx(classes.container, containerClassName)}>
      <CheckCircleIcon className={classes.confirmed} style={iconsStyles} />
      {confirmedText}
    </span>
  ) : (
    <span className={cx(classes.container, containerClassName)}>
      <InfoIcon className={classes.unConfirmed} style={iconsStyles} />
      {unconfirmedText}
    </span>
  );
}

const useStyles = makeStyles({ name: { StatusIcons: ConfirmStatusIcons } })(
  (theme) => ({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    confirmed: {
      fontSize: 15,
      marginRight: 4,
      color: '#8FD854',
    },
    unConfirmed: {
      fontSize: 15,
      marginRight: 4,
      color: '#FF4F5B',
    },
  }),
);
