import { Fragment, useState, useCallback, useRef, useEffect } from 'react';
import {
  Chip,
  MenuList,
  ListItem,
  ListItemText,
  Switch,
  IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import { FilterMenu } from '../../';
import { Dropdown, AutocompleteInput } from '../../common';
import { useUpdatePageQueryParams, useGetQueryParams } from '../../../hooks';
import { ArrowDropDownIcon, ArrowDropUpIcon, CloseIcon } from '../../../assets';
import { MouseChangeEventType, SelectOption } from '../../../types';
import { stableObject } from '../../../lib';

interface Props {
  label?: string;
  queryParam?: string;
  filters?: Record<string, any>;
  filterSortIds?: Array<number>;
  clearable?: boolean;
  className?: string;
  renderWithoutOuterChip?: boolean;
}

interface RenderAutocompleteProps {
  label: string;
  handleSelect: (event: any) => void;
  options: SelectOption<string | number>[];
  curFilters: any;
  className?: string;
}

export const FiltersAutocomplete = (props: Props) => {
  const {
    label = 'Filters',
    queryParam = 'list-filters',
    filters: _filters,
    filterSortIds,
    className,
    clearable = true,
    renderWithoutOuterChip,
  } = props;
  const { classes } = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { [queryParam]: queryListFilters } = useGetQueryParams();
  const [selectAll, setSelectAll] = useState(false);
  const [options, setOptions] = useState<Array<SelectOption>>([]);

  const filters = _filters || stableObject;

  // `queryListFilters` can be either undefined or a string (if only one) or an array.
  const curFilters =
    typeof queryListFilters === 'string'
      ? [queryListFilters]
      : queryListFilters || [];

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const mappedOptions: Array<SelectOption> = (
      filterSortIds || Object.keys(filters)
    ).map((key) => {
      const value = typeof filters[key] === 'object' ? filters[key].id : key;
      const displayVal =
        typeof filters[key] === 'object' ? filters[key].name : filters[key];
      return { id: value, name: displayVal };
    });
    setOptions(mappedOptions);
  }, [filterSortIds, filters]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = useCallback(
    (event: MouseEvent | TouchEvent | MouseChangeEventType) => {
      if (anchorRef.current?.contains(event.currentTarget as Node)) {
        return;
      }
      setOpen(false);
    },
    [],
  );

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleSelect = (event) => {
    setSelectAll(false);
    const {
      target: { value: valuesArray },
    } = event;
    let newFilters: Array<any> = [];

    if (valuesArray.length) {
      const filteredRemaining = curFilters.filter((f) =>
        valuesArray.includes(f),
      );
      const noDupes = Array.from(
        new Set([...filteredRemaining, ...valuesArray]),
      );
      newFilters = noDupes;
    }

    updatePageQueryParams({
      [queryParam]: newFilters.length ? newFilters : null,
    });
  };

  function handleClearAll(e) {
    setSelectAll(false);
    updatePageQueryParams({
      [queryParam]: null,
    });
    setOpen(false);
  }

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    setSelectAll(checked);
    if (checked) {
      const allFilters = (filterSortIds || Object.keys(filters)).map((key) =>
        typeof filters[key] === 'object' ? filters[key].id : key,
      );
      const noDupes = Array.from(new Set([...curFilters, ...allFilters]));
      updatePageQueryParams({
        [queryParam]: noDupes,
      });
    } else {
      handleClearAll(e);
    }
  };
  const { text, icon } = getLabel({
    labelProp: label,
    curFilters,
    allFilters: filters,
    isOpen: open,
    iconClass: classes.arrowIcon,
  });

  if (renderWithoutOuterChip) {
    return (
      <RenderAutocomplete
        label={label}
        handleSelect={handleSelect}
        options={options}
        curFilters={curFilters}
        className={className}
      />
    );
  }

  return (
    <Fragment>
      <Chip
        ref={anchorRef}
        clickable
        color={clearable && !!curFilters.length ? 'primary' : 'default'}
        label={text}
        className={classes.chip}
        onDelete={!!curFilters.length ? handleClearAll : undefined}
        icon={icon}
        variant='outlined'
        onClick={handleToggle}
      />
      <Dropdown
        ref={anchorRef}
        open={open}
        handleClose={handleClose}
        popperProps={{ style: { zIndex: 13 }, placement: 'bottom-start' }}
      >
        <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
          <ListItem
            secondaryAction={
              <IconButton onClick={handleClose} size='large'>
                <CloseIcon />
              </IconButton>
            }
          >
            <ListItemText primary={label} />
          </ListItem>
          <ListItem
            secondaryAction={
              <Switch
                checked={selectAll}
                onChange={handleSelectAll}
                color='primary'
              />
            }
          >
            <ListItemText primary='Select all' />
          </ListItem>
          <RenderAutocomplete
            label={label}
            handleSelect={handleSelect}
            options={options}
            curFilters={curFilters}
            className={className}
          />
        </MenuList>
      </Dropdown>
    </Fragment>
  );
};

function RenderAutocomplete({
  label,
  handleSelect,
  options,
  curFilters,
  className,
}: RenderAutocompleteProps) {
  return (
    <AutocompleteInput
      textFieldProps={{
        name: 'searchText',
        label,
        onChange: handleSelect,
        // classes: {
        //   root: cx(classes.searchRoot, className),
        // },
        // ...rest,
      }}
      autocompleteProps={{
        options,
        // className: classes.inputRoot,
        value: curFilters,
        multiple: true,
        limitTags: 2,
        size: 'small',
        ChipProps: { style: { maxWidth: 105 } },
        className,
      }}
    />
  );
}

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    // So we see the popover when it's empty.
    minWidth: 16,
    minHeight: 16,
    maxWidth: 'calc(100vw - 32px)',
    maxHeight: 'calc(100vh - 32px)',
    width: 300,
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,
  },
  chip: {
    margin: '0 4px 10px 4px',
  },
  text: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 16,
  },
  selectedText: {
    color: '#000000',
  },
  arrowIcon: {
    fontSize: 18,
  },
}));

function getLabel({ labelProp, curFilters, allFilters, isOpen, iconClass }) {
  let text = '';
  let icon;

  const firstSelected = curFilters[0];
  const firstSelectedName =
    typeof allFilters[curFilters[firstSelected]] === 'object'
      ? allFilters[firstSelected].name
      : allFilters[firstSelected];

  switch (true) {
    case curFilters.length === 0:
      text = labelProp;
      icon = isOpen ? (
        <ArrowDropDownIcon className={iconClass} />
      ) : (
        <ArrowDropUpIcon className={iconClass} />
      );
      break;
    case curFilters.length === 1:
      text = firstSelectedName;
      break;
    case curFilters.length > 1:
      text = `${labelProp} +${curFilters.length - 1}`;
      break;
    default:
      text = `${labelProp}`;
  }

  return {
    text,
    icon,
  };
}
