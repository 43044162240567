import { useCallback, useState, useEffect, Fragment } from 'react';
import { object, string } from 'yup';
import { makeStyles } from '../../../themes';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput, AutocompleteInput, SwitchInput } from '../inputs';
import {
  useDispatch,
  addResource,
  fetchResourceById,
  getCarrierServicesById,
} from '../../../state';
import { convertNullFieldsToEmptyString } from '../../../lib';
import { Loader } from '../../ui';

const initialState = {
  feePercentage: '',
  feeMax: '',
  feeMin: '',
  carrierServiceID: '',
  hasAccountAccess: false,
  isCustomerRestrictedCarrier: false,
  initialCustomerRestrictedCarrier: false,
  allowResellerForOrderProcessing: false,
};

export function CustomerCarrierAccountForm({
  open,
  handleClose,
  customerId,
  carrierId,
  carrierServiceID,
  configuredServicesIds: _configuredServicesIds,
  carrierType,
  isAddService,
  setViewVersion,
  hideFees,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);
  const [serviceName, setServiceName] = useState();
  const [carrierServicesOptions, setCarrierServicesOptions] = useState([]);
  const [showAccountRestrictionField, setShowAccountRestrictionField] =
    useState(false);
  const [showResellerOrderField, setShowResellerOrderField] = useState(false);

  useEffect(() => {
    (async function () {
      if (carrierId && !isAddService) {
        setLoading(true);
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/customers',
            id: customerId,
            path: `carrier-settings/${carrierId}`,
            query: { carrierType, carrierServiceID },
          }),
        );
        if (data) {
          const {
            carrierSettingID,
            shipmentMarkupFeeAmount,
            shipmentMarkupFeeMax,
            shipmentMarkupFeeMin,
            shipmentMarkupFeePercentage,
            carrierServiceName,
            carrierServiceID,
            isRestrictedCarrier,
            isCustomerRestrictedCarrier,
            hasAccountAccess,
            allowResellerForOrderProcessing,
            accountOwner,
          } = data;

          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString(
              {
                id: carrierSettingID,
                carrierServiceID,
                feeAmount: shipmentMarkupFeeAmount,
                feeMax: shipmentMarkupFeeMax,
                feeMin: shipmentMarkupFeeMin,
                feePercentage: shipmentMarkupFeePercentage,
              },
              ['hasAccountAccess', 'allowResellerForOrderProcessing'],
            ),
            hasAccountAccess,
            isCustomerRestrictedCarrier,
            initialCustomerRestrictedCarrier: isCustomerRestrictedCarrier,
            allowResellerForOrderProcessing:
              allowResellerForOrderProcessing ?? false,
            accountOwner,
          }));

          if (carrierServiceName) {
            setServiceName(carrierServiceName);
          }

          if (isRestrictedCarrier) {
            setShowAccountRestrictionField(true);
          }
          if (accountOwner === 'CustomerReseller') {
            setShowResellerOrderField(true);
          }
        }
        setLoading(false);
      }
    })();
  }, [
    carrierId,
    carrierServiceID,
    carrierType,
    customerId,
    dispatch,
    isAddService,
    setLoading,
  ]);

  useEffect(() => {
    (async function () {
      if (isAddService) {
        const { data } = await dispatch(
          getCarrierServicesById(carrierId, { carrierType }),
        );
        if (data) {
          const { services } = data;
          const configuredServicesIds = _configuredServicesIds || [];
          // first remove services that already have a fee configured
          const carrierData = services
            .filter((d) => !configuredServicesIds.includes(d.id))
            .map((d) => ({
              id: d.id,
              name: d.name,
            }));
          setCarrierServicesOptions(carrierData);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAddService, carrierId]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const { error } = await dispatch(
        addResource({
          baseUrl: `/customers/${customerId}/carrier-settings/${carrierId}`,
          payload: {
            carrierType,
            feeType: 'ShipmentMarkupFee',
            ...values,
            carrierServiceID: values.carrierServiceID || null,
            hasAccountAccess: showAccountRestrictionField
              ? values.hasAccountAccess
              : undefined,
            shouldUpdateCarrierFees:
              !hideFees && !values.isCustomerRestrictedCarrier,
            allowResellerForOrderProcessing: showResellerOrderField
              ? values.allowResellerForOrderProcessing
              : undefined,
          },
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion((cur) => ++cur);
        handleClose();
      }
    },
    [
      carrierId,
      carrierType,
      customerId,
      dispatch,
      handleClose,
      hideFees,
      setViewVersion,
      showAccountRestrictionField,
      showResellerOrderField,
    ],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const showFeeFields =
    !hideFees &&
    !showResellerOrderField &&
    !formik.values.isCustomerRestrictedCarrier &&
    (!showAccountRestrictionField || formik.values.hasAccountAccess);

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>
          {isAddService
            ? 'Add fee for service'
            : `Edit ${serviceName || 'account'}`}
        </span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {isAddService && (
            <AutocompleteInput
              formikProps={formik}
              textFieldProps={{
                name: 'carrierServiceID',
                label: 'Service',
                //  autoComplete: 'new-password',
                required: true,
                className: classes.input,
                style: { width: '100%' },
              }}
              autocompleteProps={{
                options: carrierServicesOptions,
              }}
            />
          )}
          {showAccountRestrictionField && (
            <SwitchInput
              name='hasAccountAccess'
              label='Account access'
              formikProps={formik}
            />
          )}
          {!showAccountRestrictionField && (
            <SwitchInput
              name='isCustomerRestrictedCarrier'
              label='Customer restricted'
              formikProps={formik}
            />
          )}
          {showResellerOrderField && (
            <SwitchInput
              name='allowResellerForOrderProcessing'
              label='Use reseller account for orders'
              formikProps={formik}
            />
          )}
          {showFeeFields && (
            <Fragment>
              <TextInput
                name='feePercentage'
                formikProps={formik}
                label='Percent'
                className={classes.input}
                type='number'
                required
                slotProps={{
                  input: {
                    endAdornment: <span>%</span>,
                  },
                }}
              />
              <TextInput
                name='feeMin'
                formikProps={formik}
                label='Min'
                className={classes.input}
                format='currency'
              />
              <TextInput
                name='feeMax'
                formikProps={formik}
                label='Max'
                className={classes.input}
                format='currency'
              />
            </Fragment>
          )}
        </>
      )}
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { CustomerCarrierAccountForm } })(
  (theme) => ({
    title: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 600,
    },
    input: {
      marginBottom: 16,
      width: 300,
    },
  }),
);

const schema = object().shape({
  feePercentage: string()
    .nullable()
    .when(
      [
        'accountOwner',
        'isCustomerRestrictedCarrier',
        'initialCustomerRestrictedCarrier',
      ],
      {
        is: (
          owner,
          isCustomerRestrictedCarrier,
          initialCustomerRestrictedCarrier,
        ) => {
          return (
            owner !== 'CustomerReseller' &&
            !isCustomerRestrictedCarrier &&
            !initialCustomerRestrictedCarrier
          );
        },
        then: (schema) => schema.required('Required'),
      },
    ),
});
