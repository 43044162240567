import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'defaultState' })((theme) => ({
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 0,
    height: 'calc(100vh - 350px)',
    minHeight: 300,
  },
  emptyText: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 8,
  },
  emptySubText: {
    ...(theme.typography.body2 as CSSObject),
    fontFamily: 'Montserrat',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 14,
  },
}));
