import { useCallback, useState } from 'react';
import { object, string } from 'yup';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import { useDispatch, voidInvoice } from '../../../state';
import { useRefresh } from '../../../hooks';

const initialState = {
  note: '',
};

export function VoidInvoiceForm({ open, handleClose, invoiceId }) {
  const { classes, css } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);

      const payload = {
        invoiceID: invoiceId,
        note: values.note,
      };

      const { error } = await dispatch(voidInvoice(invoiceId, payload));

      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoiceId],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>
          Are you sure you want to void this invoice?
        </span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 510 } }}
      submitting={submitting}
    >
      <div>
        <TextInput
          name='note'
          formikProps={formik}
          label='Reason'
          rows={3}
          multiline
          required
          className={css({ width: 460, marginTop: 5 })}
          variant='outlined'
        />
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { VoidInvoiceForm } })((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    width: 300,
  },
  deleteIcon: {
    color: theme.palette.error.main,
  },
}));

const schema = object().shape({
  note: string().required('Required'),
});
