import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { addResource } from '../../../state';
import { useNotify } from '../../../hooks';
import {
  bankAccountSchema,
  cardSchema,
  getQBPaymentToken,
  modes,
} from './creditCardUtils';
import { PaymentMethodFields } from '../..';

const initialState = {
  // shared
  name: '',
  isDefault: false,
  // card
  number: '',
  expiry: '',
  cvc: '',
  zip: '',
  focus: '',
  // bank
  accountNumber: '',
  phone: '',
  accountType: '',
  routingNumber: '',
};

export function AddPaymentMethodForm({
  open,
  handleClose,
  customerId,
  setViewVersion,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const [mode, setMode] = useState(modes.CREDIT_CARD);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const { qbData, errorMessage } = await getQBPaymentToken(mode, values);

      if (errorMessage) {
        setSubmitting(false);
        return notify(errorMessage, 'error');
      }

      const { error } = await dispatch(
        addResource({
          baseUrl: `/customers/${customerId}/add-payment-method`,
          payload: {
            token: qbData.value,
            accountType:
              mode === modes.CREDIT_CARD ? 'CreditCard' : 'BankAccount',
          },
        }),
      );

      setSubmitting(false);
      if (!error) {
        setViewVersion((cur) => ++cur);
        notify('Changes saved');
        handleClose();
      }
    },
    [mode, dispatch, customerId, notify, setViewVersion, handleClose],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema:
      mode === modes.CREDIT_CARD ? cardSchema : bankAccountSchema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Add credit card</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='md'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      <PaymentMethodFields
        formik={formik}
        initialState={initialState}
        mode={mode}
        setMode={setMode}
      />
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
    padding: '24px 16px',
  },
}));
