import { addHours, isAfter, parseISO } from 'date-fns';
import { authGet } from '../../lib';
import {
  BasicMaterialRequest,
  IdNamePair,
  MaterialBasicDetails,
} from '../../types';
import {
  invalidateMaterialCache,
  setScannedMaterialMapping,
  showError,
} from '../features';
import type { AppThunk, NormalizeReturn } from '../types';

export function getMaterialProductById(
  materialId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(`materials/${materialId}`);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function getVendorById(
  vendorId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(`vendors/${vendorId}`);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function fetchMaterialByIdOrCode(
  idOrCode: number | string,
): AppThunk<Promise<NormalizeReturn<MaterialBasicDetails>>> {
  return async (dispatch, getState) => {
    const {
      system: {
        lastFetchedScannedMaterialMappingOn,
        scannedMaterialMapping,
      } = {},
    } = getState();
    if (
      lastFetchedScannedMaterialMappingOn &&
      isAfter(
        new Date(),
        addHours(parseISO(lastFetchedScannedMaterialMappingOn), 1),
      )
    ) {
      dispatch(invalidateMaterialCache('scannedMaterialMapping'));
    } else {
      const foundMapping = scannedMaterialMapping?.[idOrCode];
      if (foundMapping) return { data: foundMapping };
    }

    const { data, error } = await authGet<MaterialBasicDetails>(
      `/materials/${idOrCode}/basic-details`,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    dispatch(
      setScannedMaterialMapping({ scannedMaterial: idOrCode, results: data }),
    );
    return { data };
  };
}

export function getBasicPackingMaterials(
  filters?: BasicMaterialRequest,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<Array<IdNamePair>>([
      '/materials/basic',
      filters,
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}
