import { memo, useCallback } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  jsDateToLocalISO8601DateString,
  getDateFromDateString,
  convertDateToTimezone,
  getLocalTimeZoneId,
} from '../../lib';

export const DateInput = memo(
  /**
   * A material-ui date picker not using formik
   * @param {import("@mui/x-date-pickers/DatePicker").DatePickerProps & inputProps} props
   * @returns
   */
  function ({ onBlur, onChange, value, name, required, ...rest }) {
    const { textFieldProps = {}, ...props } = rest;

    const handleBlur = useCallback(
      (e) => {
        onBlur && onBlur(e);
      },
      [onBlur],
    );

    const handleChange = useCallback(
      (date) => {
        // Needed because of https://github.com/mui-org/material-ui-pickers/issues/1526
        onChange(date ? jsDateToLocalISO8601DateString(date) : null, date);
      },
      [onChange],
    );

    return (
      <DatePicker
        value={toLocalDate(value) ?? null}
        format='MM/dd/yyyy'
        onChange={handleChange}
        slotProps={{
          textField: {
            name,
            variant: 'standard',
            onBlur: handleBlur,
            required,
            ...textFieldProps,
          },
        }}
        {...props}
      />
    );
  },
);

// #region Typedefs
/**
 *  @typedef {object} inputProps
 * @property {import("@mui/material/TextField").TextFieldProps} [textFieldProps]
 * @property {string} [name]
 */
// #endregion

function toLocalDate(s) {
  if (!s) return null;
  if (s instanceof Date)
    return convertDateToTimezone({ date: s, timezone: getLocalTimeZoneId() });
  return getDateFromDateString({ dateStr: s });
}
