import { useCallback, useState, useEffect } from 'react';
import { object, string } from 'yup';
import { Grid2 as Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput, SelectInput, AutocompleteInput } from '../inputs';
import {
  useDispatch,
  useSelector,
  shallowEqual,
  addResource,
  deleteResource,
  fetchResourceById,
  systemSelectors,
  getCarrierAccountsList,
} from '../../../state';
import { convertNullFieldsToEmptyString } from '../../../lib';

const initialState = {
  code: '',
  name: '',
  thirdParty3plAccountID: '',
  accountNumber: '',
  preferredService: '',
  countryID: '',
  zip: '',
};

export function CustomerThirdPartyForm({
  open,
  handleClose,
  customerId,
  carrierId,
  carrierType,
  setViewVersion,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);
  const [carrierCodes, setCarrierCodes] = useState([]);
  const [carrierList, setCarrierList] = useState([]);
  const [servicesList, setServicesList] = useState([]);

  const carrierCodesTypes = useSelector(
    systemSelectors.carrierCodesTypes,
    shallowEqual,
  );
  const countries = useSelector(systemSelectors.countries, shallowEqual);

  const fetchShipperAccounts = useCallback(
    async (code) => {
      if (code) {
        const { data } = await dispatch(
          getCarrierAccountsList({
            code,
            accountOwner: 'Tpl',
            includeTplAccountsForCustomer: true,
            customerOwnedAccountCustomerID: customerId,
          }),
        );
        if (data) {
          setCarrierList(data);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerId],
  );

  const fetchServices = useCallback(
    async (thirdPartyId) => {
      if (thirdPartyId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/carriers',
            id: thirdPartyId,
            path: 'services/basic',
          }),
        );
        if (data) {
          setServicesList(data);
        }
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (carrierCodesTypes.length) {
      const codes = carrierCodesTypes.map((c) => ({
        id: c.enumTechValue.toLowerCase(),
        name: c.name,
      }));
      setCarrierCodes(codes);
    }
  }, [carrierCodesTypes]);

  useEffect(() => {
    (async function () {
      if (carrierId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/customers',
            id: customerId,
            path: `carrier-settings/third-party/${carrierId}`,
          }),
        );
        if (data) {
          const { thirdParty3plAccountID, code } = data;
          const promises = [];
          !!code && promises.push(fetchShipperAccounts(code));
          !!thirdParty3plAccountID &&
            promises.push(fetchServices(thirdParty3plAccountID));
          !!promises.length && (await Promise.all(promises));
          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString(data),
          }));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierId, customerId, dispatch]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const { error } = await dispatch(
        addResource({
          baseUrl: `/customers/${customerId}/carrier-settings/third-party`,
          payload: { carrierType, ...values },
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion((cur) => ++cur);
        handleClose();
      }
    },
    [carrierType, customerId, dispatch, handleClose, setViewVersion],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/customers',
        id: customerId,
        path: `carrier-settings/third-party/${carrierId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      setViewVersion((cur) => ++cur);
      handleClose();
    }
  }, [carrierId, customerId, dispatch, handleClose, setViewVersion]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const { handleChange, ...formikProps } = formik;

  const onChange = useCallback(
    async (e) => {
      const { name, value } = e.target;
      if (name === 'code') {
        formik.setFieldValue('preferredService', '');
        formik.setFieldValue('thirdParty3plAccountID', '');
        !!value && (await fetchShipperAccounts(value));
      }
      if (name === 'thirdParty3plAccountID') {
        formik.setFieldValue('preferredService', '');
        !!value && (await fetchServices(value));
      }
      handleChange(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleChange],
  );

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          carrierId ? 'Edit' : 'New'
        } third party account`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
      isEdit={!!carrierId}
      handleDelete={handleDelete}
    >
      <Grid container spacing={5} rowSpacing={2} className={classes.layout}>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <SelectInput
            name='code'
            formikProps={formikProps}
            onChange={onChange}
            label='Carrier'
            className={classes.input}
            options={carrierCodes}
            required
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <SelectInput
            key={formik.values.code}
            name='thirdParty3plAccountID'
            formikProps={formikProps}
            onChange={onChange}
            label='Shipper account'
            className={classes.input}
            options={carrierList}
            displayField='accountNumber'
            required
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='name'
            label='Account nickname'
            formikProps={formik}
            autoComplete='new-password'
            required
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='accountNumber'
            formikProps={formikProps}
            onChange={onChange}
            label='Third party account #'
            className={classes.input}
            required
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <SelectInput
            key={formik.values.thirdParty3plAccountID}
            name='preferredService'
            formikProps={formikProps}
            onChange={onChange}
            label='Preferred shipment service'
            className={classes.input}
            options={servicesList}
            required
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <AutocompleteInput
            formikProps={formik}
            textFieldProps={{
              name: 'countryID',
              label: 'Country code',
              autoComplete: 'new-password',
              required: true,
            }}
            autocompleteProps={{
              options: countries,
            }}
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='zip'
            formikProps={formikProps}
            onChange={onChange}
            label='Zip code'
            className={classes.input}
            required
          />
        </Grid>
      </Grid>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
    padding: '24px 16px',
  },
  input: {
    width: '100%',
  },
  item: {
    paddingBottom: 0,
  },
}));

const schema = object().shape({
  code: string().required('Required'),
  thirdParty3plAccountID: string().required('Required'),
  accountNumber: string().required('Required'),
  preferredService: string().required('Required'),
  countryID: string().nullable().required('Required'),
  zip: string().required('Required'),
});
