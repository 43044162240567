import { Fragment, useState, useCallback, useRef, useEffect } from 'react';
import {
  Chip,
  MenuList,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import { FilterMenu } from '../../';
import { Dropdown, AutocompleteInput } from '../../common';
import { useUpdatePageQueryParams, useGetQueryParams } from '../../../hooks';
import { ArrowDropDownIcon, ArrowDropUpIcon, CloseIcon } from '../../../assets';
import { MouseChangeEventType, SelectOption } from '../../../types';
import { stableObject } from '../../../lib';

interface Props {
  label?: string;
  queryParam?: string;
  filters?: Record<string, any>;
  filterSortIds?: Array<number>;
  clearable?: boolean;
  className?: string;
}

export const FiltersAutocompleteSingle = ({
  label = 'Filters',
  queryParam = 'list-filters',
  filters: _filters,
  filterSortIds,
  className,
  clearable = true,
  ...rest
}: Props) => {
  const { classes, cx } = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { [queryParam]: queryListFilter } = useGetQueryParams();
  const [options, setOptions] = useState<Array<SelectOption>>([]);

  const filters = _filters || stableObject;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const mappedOptions: Array<SelectOption> = (
      filterSortIds || Object.keys(filters)
    ).map((key) => {
      const value = typeof filters[key] === 'object' ? filters[key].id : key;
      const displayVal =
        typeof filters[key] === 'object' ? filters[key].name : filters[key];
      return { id: value, name: displayVal };
    });
    setOptions(mappedOptions);
  }, [filterSortIds, filters]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = useCallback(
    (event: MouseEvent | TouchEvent | MouseChangeEventType) => {
      if (anchorRef.current?.contains(event.currentTarget as Node)) {
        return;
      }
      setOpen(false);
    },
    [],
  );

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleSelect = (event) => {
    const {
      target: { value },
    } = event;

    updatePageQueryParams({
      [queryParam]: queryListFilter === value ? null : value,
    });
    setOpen(false);
  };

  function handleClear(e) {
    updatePageQueryParams({
      [queryParam]: null,
    });
    setOpen(false);
  }

  const { text, icon } = getLabel({
    labelProp: label,
    selectedFilter: queryListFilter,
    allFilters: filters,
    isOpen: open,
    iconClass: classes.arrowIcon,
  });

  return (
    <Fragment>
      <Chip
        ref={anchorRef}
        clickable
        color={!!queryListFilter ? 'primary' : 'default'}
        label={text}
        className={classes.chip}
        onDelete={clearable && !!queryListFilter ? handleClear : undefined}
        icon={icon}
        variant='outlined'
        onClick={handleToggle}
      />
      <Dropdown
        ref={anchorRef}
        open={open}
        handleClose={handleClose}
        popperProps={{ style: { zIndex: 13 }, placement: 'bottom-start' }}
      >
        <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
          <ListItem
            secondaryAction={
              <IconButton onClick={handleClose} size='large'>
                <CloseIcon />
              </IconButton>
            }
          >
            <ListItemText primary={label} />
          </ListItem>
          <AutocompleteInput
            textFieldProps={{
              // name: 'searchText',
              label,
              onChange: handleSelect,
              // classes: {
              //   root: cx(classes.searchRoot, className),
              // },
              // ...rest,
            }}
            autocompleteProps={{
              options,
              // className: classes.inputRoot,
              value: queryListFilter ?? '',
              limitTags: 2,
              size: 'small',
              ChipProps: { style: { maxWidth: 105 } },
              classes: {
                root: cx(className),
              },
            }}
          />
        </MenuList>
      </Dropdown>
    </Fragment>
  );
};

const useStyles = makeStyles({ name: { FiltersAutocompleteSingle } })(
  (theme) => ({
    paper: {
      marginTop: theme.spacing(1),
      overflowY: 'auto',
      overflowX: 'hidden',
      // So we see the popover when it's empty.
      minWidth: 16,
      minHeight: 16,
      maxWidth: 'calc(100vw - 32px)',
      maxHeight: 'calc(100vh - 32px)',
      width: 300,
      // We disable the focus ring for mouse, touch and keyboard users.
      outline: 0,
    },
    chip: {
      margin: '0 4px 10px 4px',
    },
    text: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 16,
    },
    selectedText: {
      color: '#000000',
    },
    arrowIcon: {
      fontSize: 18,
    },
  }),
);

function getLabel({
  labelProp,
  selectedFilter,
  allFilters,
  isOpen,
  iconClass,
}) {
  let text = '';
  let icon;

  if (selectedFilter) {
    const selectedName =
      typeof allFilters[selectedFilter] === 'object'
        ? allFilters[selectedFilter].name
        : allFilters[selectedFilter];
    text = selectedName;
  } else {
    text = labelProp;
    icon = isOpen ? (
      <ArrowDropDownIcon className={iconClass} />
    ) : (
      <ArrowDropUpIcon className={iconClass} />
    );
  }

  return {
    text,
    icon,
  };
}
