import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'layout' })((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('laptop')]: {
      display: 'initial',
    },
  },
  content: {
    flexGrow: 1,
    // paddingRight: 40,
    // paddingLeft: 65,
    paddingBottom: 24,
    height: 'calc(100vh - 24px)',
  },
  avatar: {
    backgroundColor: '#604FFF',
    fontSize: 14,
    fontWeight: 600,
  },
  // appBar: {
  //   flexGrow: 1,
  //   flexDirection: 'space-between',
  // },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.up('laptop')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  grow: {
    flexGrow: 1,
  },
  mobilePaper: {
    marginTop: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    // So we see the popover when it's empty.
    minWidth: 'initial',
    minHeight: 'initial',
    maxWidth: 'calc(100vw - 32px)',
    maxHeight: 'calc(100vh - 32px)',
    width: 'initial',
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,
    boxShadow: 'none',
    background: 'inherit',
  },
  mobileCameraButton: {
    width: 31,
    height: 33,
    borderRadius: 4,
    minWidth: 'unset',
  },
}));
