import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'trackTime' })((theme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexGrow: 1,
    height: 'calc(100vh - 245px)',
    [theme.breakpoints.down('tablet')]: {
      paddingTop: 40,
      justifyContent: 'flex-start',
    },
  },
  globalLayout: {
    position: 'fixed',
    width: 790,
    height: 38,
    margin: '0 auto',
    top: 0,
    left: '50%',
    marginLeft: -395,
    [theme.breakpoints.down('tablet')]: {
      paddingTop: 0,
      justifyContent: 'flex-start',
      bottom: 0,
      top: undefined,
    },
  },
  globalMobileTimeContainer: {
    position: 'fixed',
    width: '100%',
    bottom: '15px',
    left: '11px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    zIndex: theme.zIndex.appBar + 1,
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 32,
    fontWeight: 500,
    letterSpacing: 0,
    marginBottom: 40,
    [theme.breakpoints.down('tablet')]: {
      fontSize: 28,
      marginBottom: 16,
    },
  },
  content: {
    width: 790,
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
      padding: '0 24px',
    },
  },
  bar: {
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    padding: '20px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('tablet')]: {
      boxShadow: 'none',
      padding: 0,
    },
  },
  timeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  time: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 'bold',
    letterSpacing: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('tablet')]: {
      fontSize: 28,
    },
  },
  timeText: {
    width: 29.5,
    [theme.breakpoints.down('tablet')]: {
      width: 40,
    },
  },
  timeTextGlobalMobil: {
    color: '#43485B',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 'bold',
    width: 29.5,
  },
  timeTickGlobalMobil: {
    color: '#43485B',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 'bold',
    width: 10,
  },
  timerBtn: {
    padding: 12,
    borderRadius: 3,
    marginLeft: 24,
    [theme.breakpoints.down('tablet')]: {
      padding: 20,
      marginLeft: 0,
    },
  },
  globalMobileTimerBtn: {
    padding: 5,
    marginLeft: 8,
    height: 'max-content',
  },
  timerIcon: {
    fontSize: 14,
    color: '#FFFFFF',
    [theme.breakpoints.down('tablet')]: {
      fontSize: 24,
    },
  },
  startBtn: {
    backgroundColor: '#8FD854',
    '&:hover': {
      backgroundColor: '#8FD854',
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  stopBtn: {
    backgroundColor: '#FF4F5B',
    '&:hover': {
      backgroundColor: '#FF4F5B',
    },
  },
  mobileTimeContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mobileTime: {
    [theme.breakpoints.down('tablet')]: {
      height: 242,
      width: 242,
      border: '2px solid #D5D8EC',
      borderRadius: 130,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 24,
      marginBottom: 40,
    },
  },
}));
