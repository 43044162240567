import { tz, TZDateMini } from '@date-fns/tz';
import {
  isValid,
  parse,
  startOfWeek,
  subDays,
  subMonths,
  subYears,
  format,
  parseISO,
} from 'date-fns';
import { enUS } from 'date-fns/locale/en-US';

type GetDateFromStringOptions = {
  dateStr: string;
  convertToLocalTimezone?: boolean;
  useTime?: boolean;
  timeZone?: string | null;
};

type FormatDateOptions = {
  formatStr?: string;
  convertToLocalTimezone?: boolean;
  useTime?: boolean;
  timeZone?: string;
};

export function getLocalTimeZoneId() {
  return Intl.DateTimeFormat(enUS.code).resolvedOptions().timeZone;
}

export function convertDateToTimezone({
  date,
  timezone,
}: {
  date: Date;
  timezone: string;
}) {
  if (!date || !isValid(date) || !timezone) {
    return date;
  }
  return new TZDateMini(date, timezone);
}

export function jsDateToLocalISO8601DateString(date: Date) {
  // const localTimezone = getLocalTimeZoneId();
  // const tzDate = new TZDateMini(date, localTimezone);
  if (!isValid(date)) {
    return null;
  }
  return format(date, 'yyyy-MM-dd', { in: tz(getLocalTimeZoneId()) });
}

export function getDateFromDateString({
  dateStr,
  convertToLocalTimezone = true,
  useTime,
  timeZone,
}: GetDateFromStringOptions) {
  if (!dateStr) return null;
  let dateValue: Date | undefined;
  if (timeZone) {
    dateValue = TZDateMini.tz(timeZone, dateStr);
  } else {
    if (useTime) {
      dateValue = TZDateMini.tz(getLocalTimeZoneId(), dateStr);
    } else if (convertToLocalTimezone) {
      // Check if the string is in ISO format
      if (dateStr.includes('T')) {
        dateValue = TZDateMini.tz(getLocalTimeZoneId(), dateStr);
      } else {
        // Parse standard date string and convert to current timezone.
        // We are not using `TZDateMini.tz` here because it assumes the string represents midnight UTC since there is no time
        // which it then adjusts to the local timezone resulting in the previous day. However `parse` or `parseISO` with
        // the `in` option  assumes the date string represents local time in the specified time zone which is what we want if we don't specify time.
        dateValue = parse(dateStr, 'yyyy-MM-dd', new Date(), {
          in: tz(getLocalTimeZoneId()),
        });
      }
    } else {
      dateValue = parseISO(dateStr, { in: tz('UTC') });
    }
  }
  if (isValid(dateValue)) {
    return dateValue;
  }
  return null;
}

export function formatDate(
  date?: Date | string | null,
  options?: FormatDateOptions,
): string | null {
  const {
    formatStr = 'MM/dd/yyyy',
    convertToLocalTimezone = true,
    useTime = false,
    timeZone,
  } = options || {};
  if (!date) {
    return null;
  }
  const dateValue =
    date instanceof Date
      ? date
      : getDateFromDateString({
          dateStr: date,
          convertToLocalTimezone,
          useTime,
          timeZone,
        });

  if (!dateValue) return '';

  const formatted = format(dateValue, formatStr, {
    locale: enUS,
    in: timeZone ? tz(timeZone) : undefined,
  });

  if (!formatStr.includes('z')) {
    return formatted;
  }

  const timezoneOrLocal = (timeZone || getLocalTimeZoneId()) as string;

  const timezoneAbbreviation = getFormattedTimezoneName(
    timezoneOrLocal,
    'short',
  );
  return formatted.replace(/GMT[+-]\d+(?::\d+)?/g, timezoneAbbreviation).trim();
}

export function getFormattedTimezoneName(
  timezoneId: string,
  type?: Intl.DateTimeFormatOptions['timeZoneName'],
) {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezoneId,
    timeZoneName: type,
  };
  const formatter = new Intl.DateTimeFormat(enUS.code, options);
  const parts = formatter.formatToParts(new Date());
  const timezonePart = parts.find((part) => part.type === 'timeZoneName');
  return timezonePart ? timezonePart.value : '';
}

export function getDateFromTimeString(timeString?: string | null): Date | null {
  if (!timeString) {
    return null;
  }
  const dateString = '1970-01-01'; // a random date
  const combinedDateTimeString = `${dateString} ${timeString}`;
  return parse(combinedDateTimeString, 'yyyy-MM-dd HH:mm:ss', new Date());
}

export function getDateRanges() {
  const currentDate = new Date();

  // Get the first day of the current week
  const firstDayOfWeek = startOfWeek(currentDate);

  return {
    today: {
      from: format(currentDate, 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    yesterday: {
      from: format(subDays(currentDate, 1), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    currentMonth: {
      from: format(currentDate, 'yyyy-MM-01'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    lastTwoWeeks: {
      from: format(subDays(currentDate, 14), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    lastWeek: {
      from: format(subDays(currentDate, 7), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    twoMonthsAgo: {
      from: format(subMonths(currentDate, 2), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    pastThreeMonths: {
      from: format(subMonths(currentDate, 3), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    pastSixMonths: {
      from: format(subMonths(currentDate, 6), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    pastYear: {
      from: format(subYears(currentDate, 1), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    pastMonth: {
      from: format(subMonths(currentDate, 1), 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
    currentWeek: {
      from: format(firstDayOfWeek, 'yyyy-MM-dd'),
      to: format(currentDate, 'yyyy-MM-dd'),
    },
  };
}
