import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { Grid2 as Grid } from '@mui/material';
import { FormModal } from './FormModal';
import { AutocompleteInput, TextInput } from '../inputs';
import {
  useDispatch,
  linkAppointmentsToCheckIn,
  getBasicCompaniesList,
} from '../../../state';
import {
  Navigation,
  generateUrl,
  nestedPages,
  pathParams,
  paths,
} from '../../../lib';
import { CompanyType, IdNamePair, InputChangeEventType } from '../../../types';

interface Props {
  open: boolean;
  handleClose: () => any;
  warehouseId: string | number;
  appointmentIds: Array<string | number>;
  checkInId: string | number;
  companyId?: string | number;
}

interface State {
  companyID: string | number;
  companyName: string;
}

const initialState: State = {
  companyID: '',
  companyName: '',
};

export function LinkCheckInToAppointmentForm({
  open,
  handleClose,
  warehouseId,
  appointmentIds,
  checkInId,
  companyId,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [companiesList, setCompaniesList] = useState<Array<IdNamePair>>([]);

  useEffect(() => {
    (async function () {
      if (!companyId) {
        const { data } = await dispatch(
          getBasicCompaniesList({
            params: {
              companyType: CompanyType.Carrier,
              includeManualCarriers: true,
            },
          }),
        );
        if (data) {
          setCompaniesList(data);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const handleSubmit = useCallback(
    async (values: State) => {
      if (!appointmentIds?.length || !checkInId || !warehouseId) {
        return;
      }
      setSubmitting(true);
      const payload = {
        warehouseID: warehouseId,
        appointmentIDs: appointmentIds,
        checkInID: checkInId,
        ...generatePayload(values),
      };
      const { error } = await dispatch(
        linkAppointmentsToCheckIn({
          warehouseId,
          checkInId: checkInId,
          payload,
        }),
      );
      setSubmitting(false);
      if (!error) {
        const appointmentId = appointmentIds[0];
        Navigation.redirect(
          generateUrl(paths.APPOINTMENT_PAGES, {
            [pathParams.WAREHOUSE_ID]: warehouseId,
            [pathParams.APPOINTMENT_ID]: appointmentId,
            [pathParams.NESTED_PAGE]: nestedPages.APPOINTMENT_OVERVIEW,
          }),
        );
      }
    },

    [appointmentIds, checkInId, dispatch, warehouseId],
  );

  const formik = useFormik<State>({
    initialValues: { ...initialState, companyID: companyId ?? '' },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const { handleChange, ...formikProps } = formik;
  const { setFieldValue, values } = formik;

  const customOnChange = async (e: InputChangeEventType) => {
    const { name } = e.target;
    if (name === 'companyName') {
      setFieldValue('companyID', '');
    } else if (name === 'companyID') {
      setFieldValue('companyName', '');
    }

    handleChange(e);
  };

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span
          className={classes.title}
        >{`Link check-in #${checkInId} to appointment('s) ${appointmentIds.join(
          ', ',
        )}?`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      {!companyId && (
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <AutocompleteInput
              formikProps={formikProps}
              textFieldProps={{
                name: 'companyID',
                label: 'Select company',
                onChange: customOnChange,
                required: !values.companyName,
              }}
              autocompleteProps={{
                className: classes.input,
                options: companiesList,
              }}
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='companyName'
              label='Enter new company'
              formikProps={formikProps}
              handleChange={customOnChange}
              className={classes.input}
              required={!values.companyID}
            />
          </Grid>
        </Grid>
      )}
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { LinkCheckInToAppointmentForm } })(
  (theme) => ({
    title: {
      fontFamily: 'Montserrat',
      fontSize: 24,
      fontWeight: 600,
    },
    input: {
      marginBottom: 16,
      width: '100%',
    },
  }),
);

function generatePayload(state: State) {
  const { companyID, companyName } = state;
  if (companyID) {
    return {
      companyID,
    };
  }
  return {
    company: {
      type: CompanyType.Carrier,
      companyName: companyName,
    },
  };
}

const schema = object().shape(
  {
    companyID: string()
      .nullable()
      .when(['companyName', 'isEdit'], {
        is: (companyName, isEdit) => {
          return !isEdit && !companyName;
        },
        then: (schema) => schema.required('Required').typeError('Required'),
      }),
    companyName: string()
      .nullable()
      .when(['companyID', 'isEdit'], {
        is: (companyID, isEdit) => {
          return !isEdit && !companyID;
        },
        then: (schema) => schema.required('Required').typeError('Required'),
      }),
  },
  [['companyName', 'companyID']],
);
