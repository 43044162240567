import { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal, SelectInput } from '../../';
import { addResource, getUsersBasicList, useDispatch } from '../../../state';
import { useRefresh } from '../../../hooks';
import { IdNamePair } from '../../../types';
import { useStyles } from '../../../themes';

interface State {
  userId: string;
}
interface Props {
  handleClose: () => void;
  id: number;
  warehouseId: number | string;
  open: boolean;
  currentUserId?: number | string | null;
}

const initialState: State = {
  userId: '',
};

export function AssignReceiptManagerForm({
  handleClose,
  id,
  warehouseId,
  open,
  currentUserId,
}: Props) {
  const { css } = useStyles();
  const refresh = useRefresh();
  const dispatch = useDispatch();

  const [managers, setManagers] = useState<Array<IdNamePair>>([]);

  useEffect(() => {
    (async function () {
      const { data } = await dispatch(
        getUsersBasicList({ warehouseID: warehouseId, userTypes: ['Manager'] }),
      );
      if (data) {
        setManagers(
          data.filter((u) => u.id.toString() !== currentUserId?.toString()),
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, warehouseId]);

  const handleAssignManager = useCallback(
    async (values: State) => {
      const { error } = await dispatch(
        addResource({
          baseUrl: `/receipts/${id}/assign-manager`,
          payload: {
            receiptID: id,
            userID: values.userId,
          },
          shouldSetUILoading: true,
          message: 'Receipt assigned successfully',
        }),
      );
      if (!error) {
        handleClose();
        refresh();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  const formik = useFormik<State>({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleAssignManager,
    validationSchema: schema,
  });

  return (
    <FormModal
      callback={formik.handleSubmit}
      handleClose={handleClose}
      open={open}
      paperProps={{ style: { width: 468 } }}
      title='Assign Receipt Manager'
    >
      <SelectInput
        name='userId'
        label='Select manager'
        options={managers}
        formikProps={formik}
        required
        className={css({ width: '100%' })}
      />
    </FormModal>
  );
}

const schema = object().shape({
  userId: string().required('Required'),
});
