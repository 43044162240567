import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormModal } from './FormModal';
import { printLabelsByExternalId } from '../../../state';
import { useNotify } from '../../../hooks';

export function PrintLabelsForm({ open, handleClose }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const [orderNumber, setOrderNumber] = useState('');
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (!value) setError('order number is required');
    else setError(null);
    setOrderNumber(value);
  };

  const handlePrint = useCallback(
    async (e) => {
      e.preventDefault();
      if (!orderNumber) {
        return notify('order number is required', 'error');
      }
      setSubmitting(true);
      const { error } = await dispatch(printLabelsByExternalId(orderNumber));
      setSubmitting(false);
      if (error) {
        return notify(
          error.message || error.title || 'Error printing labels',
          'error',
        );
      }
      notify('Labels printing');
      handleClose();
    },
    [dispatch, handleClose, notify, orderNumber],
  );

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Print labels</span>}
      btnText='PRINT'
      callback={handlePrint}
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      <TextField
        variant='standard'
        label='Scan or enter order number'
        className={classes.input}
        name='orderNumber'
        value={orderNumber}
        onChange={handleInputChange}
        autoFocus
        error={Boolean(error)}
        helperText={error}
        required
      />
    </FormModal>
  );
}

const useStyles = makeStyles({
  name: 'scanOrder',
})((theme) => ({
  card: {
    height: 110,
    width: 468,
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    width: 300,
    marginRight: 24,
  },
}));
