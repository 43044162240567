import { Fragment } from 'react';
import {
  paths,
  pathParams,
  nestedPages,
  generateValidatedParamsUrl,
} from '../../../lib';
import { DashboardIcon, ListAltIcon, MaterialsIcon } from '../../../assets';
import { SidebarItem } from '../SidebarItem';
import { NestedList } from '../NestedList';

export function ProcessorSidebar({ handleClearSelectedOrders }) {
  return (
    <Fragment>
      <SidebarItem
        to={generateValidatedParamsUrl(paths.PROCESS_ORDER)}
        text='Home'
        icon={DashboardIcon}
      />
      <NestedList text='Orders' icon={ListAltIcon} id='orders' defaultOpen>
        <SidebarItem
          text='Orders'
          to={generateValidatedParamsUrl(paths.ORDERS)}
          isNested
          onClick={handleClearSelectedOrders}
          isActive={() => window.location.pathname.startsWith('/order')}
        />
        <SidebarItem
          text='Batches'
          isNested
          to={generateValidatedParamsUrl(paths.BATCH_LIST)}
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.BATCH_LIST}`)
          }
        />
      </NestedList>
      <NestedList text='Shipping' icon={MaterialsIcon} id='shipping'>
        <SidebarItem
          to={generateValidatedParamsUrl(paths.GET_SP_ESTIMATE, {
            [pathParams.NESTED_PAGE]: nestedPages.SP_ESTIMATE,
          })}
          text='Browse Rates'
          isNested
          isActive={() =>
            window.location.pathname.startsWith('/small-parcel-estimate')
          }
        />
        {/* <SidebarItem
          to={generateValidatedParamsUrl(paths.PURCHASED_LABEL)}
          text='Labels & Rates'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.PURCHASED_LABEL}`)
          }
        /> */}
        <SidebarItem
          to={generateValidatedParamsUrl(paths.SHIPPING_HISTORY)}
          text='Shipping history'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.SHIPPING_HISTORY}`)
          }
        />
      </NestedList>
    </Fragment>
  );
}
