import { useCallback, useState, Fragment } from 'react';
import { object, string } from 'yup';
import { Grid2 as Grid, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput, SelectInput } from '../inputs';
import {
  useDispatch,
  useSelector,
  upsertShippingJob,
  systemSelectors,
} from '../../../state';
import { BasicDialog } from '../../../components';
import {
  getCarrierTypeFromFulfillment,
  generateUrl,
  paths,
  pathParams,
  Navigation,
  nestedPages,
} from '../../../lib';
import { useRefresh, useNotify } from '../../../hooks';
import { InfoIcon } from '../../../assets';

export function QuoteEditForm({
  handleClose,
  jobData,
  normalizedJobData,
  open,
  truckingJobId,
  truckingJobTab,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();
  const shipmentLocationTypes = useSelector(
    systemSelectors.shipmentLocationTypes,
  );

  const [showRequoteConfirmationModal, setShowRequoteConfirmationModal] =
    useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {
    shipFromWarehouseID,
    shipFromAddress1,
    shipFromAddress2,
    shipFromFirstName,
    shipFromLastName,
    shipFromCompanyName,
    shipFromCity,
    shipFromPhone,
    shipFromEmail,
    shipFromLocationType,
    shipToAddress1,
    shipToAddress2,
    shipToFirstName,
    shipToLastName,
    shipToCompanyName,
    shipToCity,
    shipToPhone,
    shipToEmail,
    shipToLocationType,
    destinationNote,
    pickupNote,
    customerRefNumber,
    hazmatEmergencyPhone,
    hazmatEmergencyName,
  } = normalizedJobData;

  const initialState = {
    shipToFirstName: shipToFirstName || '',
    shipToLastName: shipToLastName || '',
    shipToCompanyName: shipToCompanyName || '',
    shipToAddress1: shipToAddress1 || '',
    shipToAddress2: shipToAddress2 || '',
    shipToCity: shipToCity || '',
    shipToPhone: shipToPhone || '',
    shipToEmail: shipToEmail || '',
    shipToLocationType: shipToLocationType || '',
    shipFromFirstName: shipFromFirstName || '',
    shipFromLastName: shipFromLastName || '',
    shipFromCompanyName: shipFromCompanyName || '',
    shipFromAddress1: shipFromAddress1 || '',
    shipFromAddress2: shipFromAddress2 || '',
    shipFromCity: shipFromCity || '',
    shipFromLocationType: shipFromLocationType || '',
    shipFromPhone: shipFromPhone || '',
    shipFromEmail: shipFromEmail || '',
    destinationNote: destinationNote || '',
    pickupNote: pickupNote || '',
    customerRefNumber: customerRefNumber || '',
    hazmatEmergencyPhone: hazmatEmergencyPhone || '',
    hazmatEmergencyName: hazmatEmergencyName || '',
  };

  const isOrderJob = !!jobData.orderExternalID;

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = restructureTruckingQuotePayload(values, jobData);
      const { error, data } = await dispatch(
        upsertShippingJob({
          payload,
          shouldSetUILoading: false,
        }),
      );
      setSubmitting(false);
      if (!error) {
        if (Array.isArray(data?.messages) && data.messages.length) {
          const msgDisplay = data.messages.map((m, i) => (
            <>
              <span key={i}>{m}</span>
              <br />
            </>
          ));
          notify(msgDisplay, 'error');
        } else {
          notify('Changes saved');
          refresh();
          handleClose();
        }
      }
    },
    [dispatch, handleClose, jobData, notify, refresh],
  );

  const formik = useFormik({
    initialValues: initialState,
    // enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Edit</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      <div className={classes.layout}>
        <span className={classes.messageContainer}>
          <InfoIcon className={classes.icon} />
          {'To change location or package info can be done here'}
          <Button
            className={classes.reQuoteButton}
            onClick={() => setShowRequoteConfirmationModal(true)}
            size='small'
          >
            Re-Quote
          </Button>
        </span>
        <div className={classes.sectionTitle}>Emergency contact</div>
        <Grid
          container
          spacing={5}
          rowSpacing={2}
          className={classes.gridContainer}
        >
          <Grid
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='hazmatEmergencyName'
              formikProps={formik}
              label='Name'
              className={classes.input}
              autoComplete='new-password'
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='hazmatEmergencyPhone'
              formikProps={formik}
              label='Phone'
              className={classes.input}
              autoComplete='new-password'
              format='phone'
            />
          </Grid>
        </Grid>
        <div className={classes.sectionTitle}>Ship to</div>
        <Grid
          container
          spacing={5}
          rowSpacing={2}
          className={classes.gridContainer}
        >
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToFirstName'
              formikProps={formik}
              label='First name'
              className={classes.input}
              autoComplete='new-password'
              disabled={isOrderJob}
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToLastName'
              formikProps={formik}
              label='Last name'
              className={classes.input}
              autoComplete='new-password'
              disabled={isOrderJob}
            />
          </Grid>{' '}
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToCompanyName'
              label='Company name'
              formikProps={formik}
              className={classes.input}
              autoComplete='new-password'
              disabled={isOrderJob}
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToAddress1'
              formikProps={formik}
              label='Address line 1'
              className={classes.input}
              autoComplete='new-password'
              disabled={isOrderJob}
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToAddress2'
              formikProps={formik}
              label='Address line 2'
              className={classes.input}
              autoComplete='new-password'
              disabled={isOrderJob}
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToCity'
              formikProps={formik}
              label='City'
              className={classes.input}
              autoComplete='new-password'
              disabled={isOrderJob}
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToPhone'
              formikProps={formik}
              label='Phone'
              className={classes.input}
              autoComplete='new-password'
              format='phone'
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToEmail'
              formikProps={formik}
              label='Email'
              className={classes.input}
              autoComplete='new-password'
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='shipToLocationType'
              formikProps={formik}
              options={shipmentLocationTypes}
              label='Shipping Category'
              className={classes.input}
              clearable
            />
          </Grid>
        </Grid>
        <Fragment>
          <div className={classes.sectionTitle}>Ship from</div>
          <Grid
            container
            spacing={5}
            rowSpacing={2}
            className={classes.gridContainer}
          >
            <Grid
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <TextInput
                name='shipFromFirstName'
                formikProps={formik}
                label='First name'
                className={classes.input}
                autoComplete='new-password'
                disabled={isOrderJob || shipFromWarehouseID}
              />
            </Grid>
            <Grid
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <TextInput
                name='shipFromLastName'
                formikProps={formik}
                label='Last name'
                className={classes.input}
                autoComplete='new-password'
                disabled={isOrderJob || shipFromWarehouseID}
              />
            </Grid>{' '}
            <Grid
              ex={12}
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <TextInput
                name='shipFromCompanyName'
                label='Company name'
                formikProps={formik}
                className={classes.input}
                autoComplete='new-password'
                disabled={isOrderJob || shipFromWarehouseID}
              />
            </Grid>
            <Grid
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <TextInput
                name='shipFromAddress1'
                formikProps={formik}
                label='Address line 1'
                className={classes.input}
                autoComplete='new-password'
                disabled={isOrderJob || shipFromWarehouseID}
              />
            </Grid>
            <Grid
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <TextInput
                name='shipFromAddress2'
                formikProps={formik}
                label='Address line 2'
                className={classes.input}
                autoComplete='new-password'
                disabled={isOrderJob || shipFromWarehouseID}
              />
            </Grid>
            <Grid
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <TextInput
                name='shipFromCity'
                formikProps={formik}
                label='City'
                className={classes.input}
                autoComplete='new-password'
                disabled={isOrderJob || shipFromWarehouseID}
              />
            </Grid>
            <Grid
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <TextInput
                name='shipFromPhone'
                formikProps={formik}
                label='Phone'
                className={classes.input}
                autoComplete='new-password'
                format='phone'
                disabled={isOrderJob || shipFromWarehouseID}
              />
            </Grid>
            <Grid
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <TextInput
                name='shipFromEmail'
                formikProps={formik}
                label='Email'
                className={classes.input}
                disabled={isOrderJob || shipFromWarehouseID}
              />
            </Grid>
            <Grid
              size={{
                sm: 6,
              }}
            >
              <SelectInput
                name='shipFromLocationType'
                formikProps={formik}
                options={shipmentLocationTypes}
                label='Shipping Category'
                className={classes.input}
                clearable
              />
            </Grid>
          </Grid>
        </Fragment>
        <div className={classes.sectionTitle}>Other info</div>
        <Grid
          container
          spacing={5}
          rowSpacing={2}
          className={classes.gridContainer}
        >
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 12,
            }}
          >
            <TextInput
              name='pickupNote'
              label='Pickup Note'
              className={classes.input}
              autoComplete='new-password'
              formikProps={formik}
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 12,
            }}
          >
            <TextInput
              name='destinationNote'
              label='Destination Note'
              className={classes.input}
              autoComplete='new-password'
              formikProps={formik}
            />
          </Grid>
          <Grid
            size={{
              sm: 12,
            }}
          >
            <TextInput
              name='customerRefNumber'
              label='Customer Ref #'
              className={classes.input}
              autoComplete='new-password'
              formikProps={formik}
            />
          </Grid>
        </Grid>
      </div>
      {showRequoteConfirmationModal && (
        <BasicDialog
          open={showRequoteConfirmationModal}
          handleClose={() => setShowRequoteConfirmationModal(false)}
          text='Are you sure you want to cancel and re-quote?'
          callback={() =>
            Navigation.redirect(
              generateUrl(paths.TRUCKING_JOB_PAGES, {
                [pathParams.TRUCKING_JOB_TAB]: truckingJobTab,
                [pathParams.TRUCKING_JOB_ID]: truckingJobId,
                [pathParams.NESTED_PAGE]: nestedPages.EDIT_TRUCKING_JOB,
              }),
            )
          }
          btnText='Continue'
        />
      )}
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  sectionTitle: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 24,
    marginTop: 40,
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    width: '100%',
  },
  gridContainer: {
    maxWidth: 645,
  },
  reQuoteButton: {
    color: ' #00C4F8',
  },
  icon: {
    fontSize: 18,
    marginBottom: -4,
    marginRight: 8,
    color: '#FFBD00',
    background: '#FFFFFF',
  },
  messageContainer: {
    marginBottom: 16,
    color: '#A5AABD',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
  },
}));

const schema = object().shape({
  fulfillmentType: string().nullable(),
  carrierName: string()
    .nullable()
    .when('createNewCarrier', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
  carrierID: string()
    .nullable()
    .when(['carrierName', 'fulfillmentType'], {
      is: (carrierName, fulfillmentType) => {
        const carrierType = getCarrierTypeFromFulfillment(fulfillmentType);
        return carrierType === 'SmallParcel' && !!carrierName;
      },
      then: (schema) => schema.required('Required'),
    }),
  carrierServiceCode: string()
    .nullable()
    .when(['carrierName', 'createNewCarrier'], {
      is: (carrierName, fulfillmentType) => {
        const carrierType = getCarrierTypeFromFulfillment(fulfillmentType);
        return carrierType === 'SmallParcel' && !!carrierName;
      },
      then: (schema) => schema.required('Required'),
    }),
  thirdPartyCountryID: string()
    .nullable()
    .when('createThirdPartyAccount', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
  thirdPartyZip: string()
    .nullable()
    .when('createThirdPartyAccount', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
  thirdPartyAccountNumber: string()
    .nullable()
    .when('createThirdPartyAccount', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
});

export function restructureTruckingQuotePayload(values, apiData = {}) {
  const { chargesDisplay, locations, items, quotedRates, ...rest } = apiData;

  const shipFrom = locations?.find((l) => l.sortOrder === 1) || {};
  const shipTo = locations?.find((l) => l.sortOrder === 2) || {};

  const restructurePayload = {
    ...rest,
    shouldUpdateItems: false,
    pickupNote: values.pickupNote,
    destinationNote: values.destinationNote,
    customerRefNumber: values.customerRefNumber,
    hazmatEmergencyName: values.hazmatEmergencyName,
    hazmatEmergencyPhone: values.hazmatEmergencyPhone,
    locations: [
      {
        ...shipFrom,
        sortOrder: 1, //Ship from
        // locationType: ,
        firstName: values.shipFromFirstName,
        lastName: values.shipFromLastName,
        companyName: values.shipFromCompanyName,
        address1: values.shipFromAddress1,
        address2: values.shipFromAddress2,
        city: values.shipFromCity,
        phoneNumber: values.shipFromPhone,
        emailAddress: values.shipFromEmail,
        locationType: values.shipFromLocationType,
      },
      {
        ...shipTo,
        sortOrder: 2, //Ship to
        firstName: values.shipToFirstName,
        lastName: values.shipToLastName,
        companyName: values.shipToCompanyName,
        address1: values.shipToAddress1,
        address2: values.shipToAddress2,
        city: values.shipToCity,
        phoneNumber: values.shipToPhone,
        emailAddress: values.shipToEmail,
        locationType: values.shipToLocationType,
      },
    ],
    // items: additionalPackagesArrayOfObjects,
  };
  return restructurePayload;
}
