import { authGet, authPost, authPut } from '../../lib';
import { showError, showNotification, setUILoading } from '../features';
import { addResource } from './resource';
import type { AppThunk, NormalizeReturn } from '../types';

export function getShippingJobsById(
  id: number | string,
  params?: Record<string, any>,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet([`shippingJobs/${id}`, params]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function quoteShippingJob({
  payload = {},
  shouldShowError = true,
  shouldSetUILoading = false,
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { error, data } = await dispatch(
      addResource({
        baseUrl: 'ShippingJobs/quote',
        payload,
        shouldSetUILoading,
      }),
    );
    if (error) {
      return { error };
    }
    const validationMessage = data.validationMessages?.[0];
    if (validationMessage) {
      shouldShowError &&
        dispatch(showError({ message: validationMessage.message }));
      // we still want to continue as if it is successful
      return { data };
      // return { error: validationMessage, isNotPrimaryError: true };
    }

    dispatch(showNotification({ message: 'Changes saved' }));
    return { data };
  };
}

export function upsertShippingJob({
  payload = {},
  shouldShowErrorMessage = true,
  shouldSetUILoading = false,
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { error, data } = await dispatch(
      addResource({
        baseUrl: 'ShippingJobs',
        payload,
        shouldSetUILoading,
        message: 'Changes saved',
        shouldShowErrorMessage,
      }),
    );
    if (error) {
      return { error };
    }

    return { data };
  };
}

export function bookShippingJob({
  jobId,
  payload = {} as Record<string, any>,
  shouldShowError = true,
  shouldSetUILoading = false,
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch, getState) => {
    const { systemPersist: { machineName } = {} } = getState();
    payload.machineName = machineName;
    payload.shouldDispatch = true;
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `shippingJobs/${jobId}/book`,
        payload,
        shouldSetUILoading,
        shouldShowErrorMessage: shouldShowError,
        message: 'Successfully booked the shipment',
      }),
    );
    if (error) {
      shouldShowError &&
        dispatch(
          showError({ message: `${error.message}. ${error.code || ''}` }),
        );
      return { error };
    }
    const validationMessage = data.validationMessages?.[0];
    if (validationMessage) {
      shouldShowError &&
        dispatch(showError({ message: validationMessage.message }));
      // we still want to continue as if it is successful
      return { data };
    }
    return { data };
  };
}

export function sendCustomerQuoteEmail(
  jobId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `/shippingJobs/${jobId}/quote/send-quote-email`,
        shouldSetUILoading: true,
        shouldShowErrorMessage: true,
        message: 'Email sent to customer',
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function printJobBol(
  jobId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `/shippingJobs/${jobId}/print-bol`,
        shouldSetUILoading: true,
        shouldShowErrorMessage: true,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function shippingJobPageHeaders(): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet('/shippingJobs/list-header');
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function getShippingJobsPackages(
  id: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(`shippingJobs/${id}/items`);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function getFreightCustomRateCarrierList(
  id: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(
      `shippingJobs/${id}/quote/quote-carriers/basic`,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function printTruckingJobLabels(
  jobId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch, getState) => {
    const {
      systemPersist: { machineName },
    } = getState();
    dispatch(setUILoading(true));
    const { data, error } = await authPost(
      [`shippingJobs/${jobId}/print-labels`, { machineName }],
      {},
    );
    dispatch(setUILoading(false));
    if (error) return { error };
    return { data };
  };
}

export function getTruckingJobBillingTotals(
  truckingJobId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch, getState) => {
    const { data, error } = await authGet(
      `shippingJobs/${truckingJobId}/billing/aggregated`,
    );
    if (error) {
      dispatch(showError({ message: error.message }));
      return {
        error,
      };
    }
    return { data };
  };
}

export function overrideTruckingJobRate(
  truckingJobId: number | string,
  rateId: string,
  payload = {} as Record<string, any>,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch, getState) => {
    const { data, error } = await authPut(
      `shippingJobs/${truckingJobId}/quote-rates/${rateId}`,
      payload,
    );
    if (error) {
      dispatch(showError({ message: error.message }));
      return {
        error,
      };
    }
    return { data };
  };
}
