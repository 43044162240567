import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { WarningOutlinedIcon, InfoIcon } from '../../assets';

interface Props {
  message: string | React.ReactElement<any>;
  className?: string;
}

export function InfoMessage({ message, className }: Props) {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.messageContainer, className)}>
      <InfoIcon className={classes.icon} />
      <span>{message}</span>
    </div>
  );
}

export function WarningMessage({ message, className }: Props) {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.messageContainer, className)}>
      <WarningOutlinedIcon className={classes.icon} />
      <span>{message}</span>
    </div>
  );
}

const useStyles = makeStyles({ name: { InfoMessage } })((theme) => ({
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    color: '#888EA7',
    fontSize: 14,
    letterSpacing: 0,
    [theme.breakpoints.up('tablet')]: {
      alignItems: 'flex-start',
      color: '#A5AABD',
      fontFamily: 'Montserrat',
      fontWeight: 500,
    },
  },
  icon: {
    fontSize: 18,
    marginRight: 8,
    color: '#FFBD00',
    background: '#FFFFFF',
  },
}));
