import { useEffect, useState } from 'react';
import { getUsersBasicList, useDispatch } from '../state';
import { IdNamePair, UserType } from '../types';

interface GetUsersForSelectProps {
  warehouseId: string | number;
  userTypes?: Array<UserType>;
  shouldFetchUsers?: boolean;
}

export function useGetUsersForSelect({
  warehouseId,
  userTypes,
  shouldFetchUsers = true,
}: GetUsersForSelectProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    (async function () {
      if (!warehouseId || !shouldFetchUsers) return;

      const { data } = await dispatch(
        getUsersBasicList({
          warehouseID: warehouseId,
          userTypes,
        }),
      );
      if (data) {
        setUsers(data);
        const userObj = data.reduce((acc, cur) => {
          acc[cur.id + ''] = cur.name;
          return acc;
        }, {});

        setUsersObject(userObj);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchUsers, JSON.stringify(userTypes), warehouseId]);

  const [users, setUsers] = useState<Array<IdNamePair>>([]);
  const [usersObj, setUsersObject] = useState<Record<string, string>>({});

  return [users, usersObj] as const;
}
