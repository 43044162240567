import { useCallback, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { date, object } from 'yup';
import { FormModal } from './FormModal';
import { FormikDateTimeInput, CheckboxInput } from '../inputs';
import {
  useDispatch,
  deleteResource,
  adjustEmployeeTracking,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { AdjustActivityFormType } from '../../../types';

interface Props {
  open: boolean;
  handleClose: () => void;
  trackingId: number;
  assigneesId?: string | null;
  startTime: Date | null | undefined;
  endTime: Date | null | undefined;
  isCompleted?: boolean;
  setViewVersion?: (value: any) => void;
  adjustActivityFormType: AdjustActivityFormType;
}

interface FormValues {
  startTime: Date | null;
  endTime: Date | null;
  isCompleted?: boolean;
}

const initialState: FormValues = {
  startTime: null,
  endTime: null,
  isCompleted: false,
};

export function AdjustEmployeeTrackingForm({
  open,
  handleClose,
  trackingId,
  assigneesId,
  startTime,
  endTime,
  isCompleted,
  setViewVersion,
  adjustActivityFormType,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [state, setState] = useState(initialState);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setState({
      startTime: startTime ? new Date(startTime) : null,
      endTime: endTime ? new Date(endTime) : null,
      isCompleted: isCompleted ?? false,
    });
  }, [adjustActivityFormType, endTime, isCompleted, startTime]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (!adjustActivityFormType) return;

      const payload = {
        ...values,
        trackingID: trackingId,
        assigneesID: assigneesId,
      };

      setSubmitting(true);

      const { error } = await dispatch(
        adjustEmployeeTracking(trackingId, payload, adjustActivityFormType),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackingId, assigneesId],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/services/employee-tracking',
        id: trackingId,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingId]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Adjust employee tracking</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
      isEdit
      handleDelete={handleDelete}
    >
      <div className={classes.layout}>
        <FormikDateTimeInput
          name='startTime'
          formikProps={formik}
          label='Start time'
          className={classes.input}
          textFieldProps={{ required: true }}
          showSeconds
        />
        <FormikDateTimeInput
          name='endTime'
          formikProps={formik}
          label='End time'
          className={classes.input}
          showSeconds
        />
        {adjustActivityFormType === 'Assignee' && (
          <CheckboxInput
            formikProps={formik}
            checkboxes={[
              {
                labelProps: {
                  label: 'Completed?',
                },
                checkboxProps: {
                  name: 'isCompleted',
                },
              },
            ]}
          />
        )}
      </div>
    </FormModal>
  );
}

const schema = object().shape({
  startTime: date().typeError('Invalid Date').nullable().required('Required'),
});

const useStyles = makeStyles({ name: { AdjustEmployeeTrackingForm } })(
  (theme) => ({
    layout: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 600,
    },
    input: {
      marginBottom: 16,
      width: 300,
    },
  }),
);
