import { useCallback, useState, useEffect, Fragment } from 'react';
import { object, string } from 'yup';
import { makeStyles } from 'tss-react/mui';
import { useTheme, Table, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput, SelectInput, CheckboxInput } from '../inputs';
import { DataGrid, FunctionField } from '../../list';
import {
  useDispatch,
  useSelector,
  shallowEqual,
  systemSelectors,
  addResource,
} from '../../../state';
import { useRefresh, useNotify } from '../../../hooks';
import { updateNestedState } from '../../../lib';
import { DeleteIcon, DeleteOutlineIcon } from '../../../assets';

const initialState = {
  note: '',
  fees: {},
  feeIds: [],
};

export function EditInvoiceFeesForm({
  open,
  handleClose,
  invoiceId,
  feeIds,
  feeRows,
}) {
  const theme = useTheme();
  const { classes, css, cx } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();

  const feeTypes = useSelector(systemSelectors.feeTypes, shallowEqual);

  const feeCategories = useSelector(
    systemSelectors.feeCategoryTypes,
    shallowEqual,
  );

  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState((cur) => ({
      ...cur,
      fees: Object.keys(feeRows).reduce((acc, key) => {
        const curValue = feeRows[key];
        acc[key] = {
          id: curValue.id,
          type: curValue.type ?? '',
          feeCategory: curValue.feeCategory ?? '',
          name: curValue.name ?? '',
          baseCost: curValue.baseCost ?? '',
          amount: curValue.amount ?? '',
          quantity: curValue.quantity ?? '',
          billableToCustomer: curValue.billableToCustomer ?? true,
          isDeleted: false,
        };
        return acc;
      }, {}),
      feeIds,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);

      const payload = {
        invoiceID: invoiceId,
        note: values.note,
        feesToUpdate: Object.values(values.fees),
      };

      const { data } = await dispatch(
        addResource({
          baseUrl: `/accounting/invoice/${invoiceId}/edit-fees`,
          payload,
          message:
            'Your edit was successful and the invoice will update when it is done processing.',
        }),
      );

      setSubmitting(false);
      if (data) {
        if (
          Array.isArray(data.validationMessages) &&
          data.validationMessages.length
        ) {
          const msgDisplay = (
            <div>
              <span>
                Your edit was successful and the invoice will update when it is
                done processing. There were some errors notifying the customer:
              </span>
              <br />
              {data.validationMessages.map((m, i) => (
                <Fragment key={i}>
                  <span>{m.message}</span>
                  <br />
                </Fragment>
              ))}
            </div>
          );
          notify(msgDisplay, 'error');
        } else {
          notify(
            'Your edit was successful and the invoice will update when it is done processing.',
          );
        }
        refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoiceId],
  );

  const formik = useFormik({
    initialValues: state,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const { values: formikValues, setValues } = formik;

  const handleFeeChange = (feeID) => (e) => {
    const { name, value: _value, checked, type } = e.target;
    const value = type === 'checkbox' ? checked : _value;

    const newState = updateNestedState({
      nestedKey: ['fees', feeID, name],
      value,
      state: formikValues,
    });
    setValues({ ...formikValues, ...newState });
  };

  const rowStyle = useCallback((row) => {
    if (row.isDeleted) {
      return { background: theme.palette.error.light };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span
          className={classes.title}
        >{`Edit invoice #${invoiceId} fees`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='xl'
      submitting={submitting}
    >
      <div>
        <TextInput
          name='note'
          formikProps={formik}
          label='Reason'
          rows={3}
          multiline
          required
          className={css({ width: 500 })}
          variant='outlined'
        />
        <Table>
          <DataGrid
            ids={formikValues.feeIds}
            rows={formikValues.fees}
            hideFooter
            bulkActionButtons={false}
            rowStyle={rowStyle}
          >
            <FunctionField
              source='name'
              label=''
              sortable={false}
              render={(record, source, index, rowKey) => (
                <Tooltip title='Delete'>
                  <div>
                    <CheckboxInput
                      checkboxes={[
                        {
                          labelProps: {
                            label: '',
                          },
                          checkboxProps: {
                            name: 'isDeleted',
                            checked: record.isDeleted,
                            onChange: handleFeeChange(rowKey),
                            disabled: !!record.baseCost,
                            icon: (
                              <DeleteOutlineIcon
                                className={cx(classes.deleteIcon, {
                                  [classes.disabled]: !!record.baseCost,
                                })}
                              />
                            ),
                            checkedIcon: (
                              <DeleteIcon className={classes.deleteIcon} />
                            ),
                          },
                        },
                      ]}
                    />
                  </div>
                </Tooltip>
              )}
            />
            <FunctionField
              source='name'
              label=''
              sortable={false}
              render={(record, source, index, rowKey) => (
                <SelectInput
                  name='type'
                  value={record.type}
                  useCustomValue
                  onChange={handleFeeChange(rowKey)}
                  label='Fee type'
                  className={css({ width: 210 })}
                  options={feeTypes}
                  disabled={record.isDeleted}
                />
              )}
            />
            <FunctionField
              source='name'
              label=''
              sortable={false}
              render={(record, source, index, rowKey) => (
                <TextInput
                  name='name'
                  value={record.name}
                  handleChange={handleFeeChange(rowKey)}
                  useCustomValue
                  label='Fee name'
                  className={classes.input}
                  disabled={record.isDeleted}
                />
              )}
            />
            <FunctionField
              source='name'
              label=''
              sortable={false}
              render={(record, source, index, rowKey) => (
                <SelectInput
                  name='feeCategory'
                  value={record.feeCategory}
                  onChange={handleFeeChange(rowKey)}
                  useCustomValue
                  label='Fee category'
                  className={css({ width: 210 })}
                  options={feeCategories}
                  disabled={record.isDeleted}
                />
              )}
            />
            <FunctionField
              source='name'
              label=''
              sortable={false}
              render={(record, source, index, rowKey) => (
                <TextInput
                  name='baseCost'
                  value={record.baseCost}
                  handleChange={handleFeeChange(rowKey)}
                  useCustomValue
                  label='Base cost'
                  className={css({ width: 100 })}
                  format='currency'
                  disabled
                />
              )}
            />
            <FunctionField
              source='name'
              label=''
              sortable={false}
              render={(record, source, index, rowKey) => (
                <TextInput
                  name='amount'
                  value={record.amount}
                  handleChange={handleFeeChange(rowKey)}
                  useCustomValue
                  label='customer markup'
                  className={css({ width: 100 })}
                  format='currency'
                  disabled={record.isDeleted}
                />
              )}
            />
            <FunctionField
              source='name'
              label=''
              sortable={false}
              render={(record, source, index, rowKey) => (
                <TextInput
                  name='quantity'
                  value={record.quantity}
                  handleChange={handleFeeChange(rowKey)}
                  useCustomValue
                  label='Qty'
                  className={css({ width: 50 })}
                  disabled={record.isDeleted}
                />
              )}
            />
            <FunctionField
              source='name'
              label=''
              sortable={false}
              render={(record, source, index, rowKey) => (
                <CheckboxInput
                  formikProps={formik}
                  useCustomValue
                  checkboxes={[
                    {
                      labelProps: {
                        label: 'Billable to customer',
                      },
                      checkboxProps: {
                        name: 'billableToCustomer',
                        checked: record.billableToCustomer,
                        onChange: handleFeeChange(rowKey),
                      },
                    },
                  ]}
                />
              )}
            />
          </DataGrid>
        </Table>
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { EditInvoiceFeesForm } })((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    width: 300,
  },
  deleteIcon: {
    color: theme.palette.error.main,
  },
  disabled: {
    color: theme.palette.grey[300],
  },
}));

const schema = object().shape({
  note: string().required('Required'),
});
