import { formatISO } from 'date-fns';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IdNamePair } from '../../types/globalTypes';

interface CustomerState {
  basicCustomers: Array<IdNamePair>;
  lastFetchedCustomersOn: string | null;
}

const initialState: CustomerState = {
  basicCustomers: [],
  lastFetchedCustomersOn: null,
};

type CachedType = 'basicCarrierCompanies';

const customers = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    getCustomers(state, action: PayloadAction<Array<IdNamePair>>) {
      state.basicCustomers = action.payload;
      state.lastFetchedCustomersOn = formatISO(new Date());
    },
    /**
     * Invalidate the cache so that the next api call will re-fetch the data and update the cache
     * @param state
     * @param action
     */
    invalidateCustomerCache(state, action: PayloadAction<CachedType>) {
      switch (action.payload) {
        case 'basicCarrierCompanies':
          state.lastFetchedCustomersOn = null;
          break;
      }
    },
  },
});

export const { getCustomers, invalidateCustomerCache } = customers.actions;

export const CustomersReducer = customers.reducer;
