import { authGet } from '../../lib';
import { showError, showNotification } from '../features';
import { addResource } from './resource';
import type { AppThunk, NormalizeReturn } from '../types';

export function getDrayageJobById(
  id: number | string,
  params?: Record<string, any>,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet([`drayageJobs/${id}`, params]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function drayageJobPageHeaders(): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet('/drayageJobs/list-header');
    if (error) {
      return { error };
    }
    return { data };
  };
}

export function getDrayageJobsByDeliveryOrderId(
  id: number | string,
  params?: Record<string, any>,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet([
      `drayageJobs/delivery-order/${id}`,
      params,
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

export function createDrayageJob({
  payload = {},
  shouldShowError = true,
  shouldSetUILoading = false,
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { error, data } = await dispatch(
      addResource({
        baseUrl: '/drayageJobs/delivery-order',
        payload,
        shouldSetUILoading,
      }),
    );
    if (error) {
      return { error };
    }
    const validationMessage = data.validationMessages?.[0];
    if (validationMessage) {
      shouldShowError &&
        dispatch(showError({ message: validationMessage.message }));
      // we still want to continue as if it is successful
      return { data };
      // return { error: validationMessage, isNotPrimaryError: true };
    }

    dispatch(showNotification({ message: 'Changes saved' }));
    return { data };
  };
}

export function updateDrayageJobStatus({
  payload = {},
  shouldShowError = true,
  shouldSetUILoading = false,
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { error, data } = await dispatch(
      addResource({
        baseUrl: '/drayageJobs/update-status',
        payload,
        shouldSetUILoading,
      }),
    );
    if (error) {
      shouldShowError && dispatch(showError({}));
      return { error };
    }

    return { data };
  };
}

export function scheduleDrayageJobAppointments({
  payload = {},
  shouldShowError = true,
  shouldSetUILoading = false,
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { error, data } = await dispatch(
      addResource({
        baseUrl: '/drayageJobs/schedule-appointments',
        payload,
        shouldSetUILoading,
      }),
    );
    if (error) {
      shouldShowError && dispatch(showError({}));
      return { error };
    }

    return { data };
  };
}

export function getCarrierConfirmDrayageJobsByDeliveryOrderId({
  deliveryOrderNumber,
  payload = {},
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { error, data } = await dispatch(
      addResource({
        baseUrl: `/drayageJobs/delivery-order/${deliveryOrderNumber}/carrier-confirm`,
        payload,
        shouldSetUILoading: false,
        shouldShowErrorMessage: true,
      }),
    );
    if (error) {
      return { error };
    }

    return { data };
  };
}

export function carrierConfirmJob({
  jobId,
  payload = {},
}): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { error, data } = await dispatch(
      addResource({
        baseUrl: `/drayageJobs/${jobId}/carrier-confirm`,
        payload,
        shouldSetUILoading: true,
        shouldShowErrorMessage: true,
        message: 'Thank you for confirming',
      }),
    );
    if (error) {
      return { error };
    }

    return { data };
  };
}

export function createInvoice({ jobId }): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { error, data } = await dispatch(
      addResource({
        baseUrl: `/drayageJobs/${jobId}/fees/prepay-fees`,
        shouldSetUILoading: true,
        shouldShowErrorMessage: true,
        message: 'The invoice and bill were updated.',
      }),
    );
    if (error) {
      return { error };
    }

    return { data };
  };
}

export function getDrayageJobBillingTotals(
  drayageJobId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch, getState) => {
    const { data, error } = await authGet(
      `drayageJobs/${drayageJobId}/billing/aggregated`,
    );
    if (error) {
      dispatch(showError({ message: error.message }));
      return {
        error,
      };
    }
    return { data };
  };
}
