import { ReactElement, useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  ModalProps,
  SwipeableDrawer,
  SwipeableDrawerProps,
} from '@mui/material';

interface MobileModalProps
  extends Omit<SwipeableDrawerProps, 'title' | 'onClose' | 'onOpen'> {
  title?: string | ReactElement<any>;
  handleClose: () => any;
  children?: React.ReactNode;
  modalProps?: Partial<ModalProps>;
}

export function MobileModal({
  open,
  handleClose,
  children,
  modalProps,
}: MobileModalProps) {
  const { classes } = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleCloseDrawer = useCallback(() => {
    handleClose();
    setDrawerOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = useCallback(() => setDrawerOpen(true), []);

  useEffect(() => {
    if (open) {
      handleOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <SwipeableDrawer
        anchor='bottom'
        open={drawerOpen}
        onClose={handleCloseDrawer}
        onOpen={handleOpen}
        PaperProps={{ className: classes.layout }}
        disableSwipeToOpen={false}
        // SwipeAreaProps={{ classes: { root: classes.drawerRoot } }}
        ModalProps={{
          keepMounted: true,
          ...modalProps,
        }}
      >
        <div>
          <div className={classes.puller} />
          {children}
        </div>
      </SwipeableDrawer>
    </div>
  );
}

const useStyles = makeStyles({ name: { MobileModal } })((theme) => ({
  layout: {
    borderRadius: '32px 32px 0 0',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 32px 0 rgba(0,0,0,0.08), 0 2px 24px 0 rgba(0,0,0,0.12)',
    paddingTop: 32,
  },
  puller: {
    width: 48,
    height: 4,
    borderRadius: 2,
    position: 'absolute',
    top: 16,
    left: 'calc(50% - 15px)',
    backgroundColor: '#D8D8D8',
  },
}));
