import { Fragment } from 'react';
import { Grid2 as Grid } from '@mui/material';
import { TextInput, AutocompleteInput } from '../inputs';
import { useSelector, systemSelectors } from '../../../state';
import { stringCapitalize } from '../../../lib';

export function ContactFields({
  formikProps,
  inputClassName,
  inputNamePrefix = '',
  addressFieldName = 'address',
  addressFieldFullWidth = false,
  idUpperCase,
  includePhone,
}) {
  return (
    <Grid container spacing={5} rowSpacing={2}>
      <Grid
        style={{ paddingBottom: 0 }}
        size={{
          sm: 6,
        }}
      >
        <TextInput
          name={getFieldName('companyName', inputNamePrefix)}
          formikProps={formikProps}
          label='Company name'
          className={inputClassName}
        />
      </Grid>
      <Grid
        style={{ paddingBottom: 0 }}
        size={{
          sm: 6,
        }}
      >
        <TextInput
          name={getFieldName('firstName', inputNamePrefix)}
          formikProps={formikProps}
          label='First name'
          className={inputClassName}
        />
      </Grid>
      <Grid
        style={{ paddingBottom: 0 }}
        size={{
          sm: 6,
        }}
      >
        <TextInput
          name={getFieldName('lastName', inputNamePrefix)}
          formikProps={formikProps}
          label='Last name'
          className={inputClassName}
        />
      </Grid>
      {includePhone && (
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name={getFieldName('officePhone', inputNamePrefix)}
            formikProps={formikProps}
            label='Phone'
            className={inputClassName}
            format='phone'
          />
        </Grid>
      )}
      <ContactAddressFields
        formikProps={formikProps}
        inputClassName={inputClassName}
        inputNamePrefix={inputNamePrefix}
        addressFieldName={addressFieldName}
        addressFieldFullWidth={addressFieldFullWidth}
        idUpperCase={idUpperCase}
      />
    </Grid>
  );
}

export function ContactAddressFields({
  formikProps,
  inputClassName,
  inputNamePrefix = '',
  addressFieldName = 'address',
  addressFieldFullWidth = false,
  idUpperCase,
}) {
  const countries = useSelector(systemSelectors.countries);
  const states = useSelector(systemSelectors.states);

  return (
    <Fragment>
      <Grid
        style={{ paddingBottom: 0 }}
        size={{
          sm: addressFieldFullWidth ? 12 : 6,
        }}
      >
        <TextInput
          name={getFieldName(addressFieldName, inputNamePrefix)}
          formikProps={formikProps}
          label='Address'
          className={inputClassName}
        />
      </Grid>
      <Grid
        style={{ paddingBottom: 0 }}
        size={{
          sm: 6,
        }}
      >
        <TextInput
          name={getFieldName('city', inputNamePrefix)}
          formikProps={formikProps}
          label='City'
          className={inputClassName}
        />
      </Grid>
      <Grid
        style={{ paddingBottom: 0 }}
        size={{
          sm: 6,
        }}
      >
        <AutocompleteInput
          formikProps={formikProps}
          textFieldProps={{
            name: getFieldName(
              `${idUpperCase ? 'stateID' : 'stateId'}`,
              inputNamePrefix,
            ),
            label: 'State',
            autoComplete: 'new-password',
          }}
          autocompleteProps={{
            options: states,
          }}
        />
      </Grid>
      <Grid
        style={{ paddingBottom: 0 }}
        size={{
          sm: 6,
        }}
      >
        <TextInput
          name={getFieldName('zip', inputNamePrefix)}
          formikProps={formikProps}
          label='Zip'
          className={inputClassName}
        />
      </Grid>
      <Grid
        style={{ paddingBottom: 0 }}
        size={{
          sm: 6,
        }}
      >
        <AutocompleteInput
          formikProps={formikProps}
          textFieldProps={{
            name: getFieldName(
              `${idUpperCase ? 'countryID' : 'countryId'}`,
              inputNamePrefix,
            ),
            label: 'Country',
            autoComplete: 'new-password',
          }}
          autocompleteProps={{
            options: countries,
          }}
        />
      </Grid>
    </Fragment>
  );
}

function getFieldName(field, inputNamePrefix) {
  if (!inputNamePrefix) {
    return field;
  }

  return inputNamePrefix + stringCapitalize(field);
}
