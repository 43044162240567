import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  title: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '20px',
    marginBottom: 24,
  },
  item: {
    marginRight: 80,
    marginBottom: 64,
    fontFamily: 'Montserrat',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  itemTitle: { fontWeight: 'bold', marginBottom: 8 },
  itemStatus: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  dot: {
    height: 8,
    width: 8,
    marginRight: 8,
    borderRadius: 10,
  },
  itemInfo: { color: '#888EA7', lineHeight: '20px', marginBottom: 8 },
  viewLink: {
    fontFamily: 'Lato',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '18px',
  },
  emptyState: {
    height: 100,
    paddingTop: 20,
    color: '#A5AABD',
    display: 'flex',
    fontSize: 14,
    alignItems: 'center',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    flexDirection: 'column',
  },
}));
