import { authGet } from '../../lib';
import { setBasicQbDataValues, showError } from '../features';
import type { AppThunk, NormalizeReturn } from '../types';
import type { IdNamePair, SelectOption } from '../../types/globalTypes';
import { QbDataModelType } from '../../types/accountingTypes';

/**
 * Get QB model list for dropdown
 * @returns {normalizeReturn}
 */
export function getBasicQbModels(
  type: QbDataModelType,
  getFromCache = true,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch, getState) => {
    const {
      system: {
        basicQbClasses,
        basicQbTerms,
        basicQbVendors,
        basicQbItems,
      } = {},
    } = getState();
    if (getFromCache) {
      switch (true) {
        case type === 'Class' && !!basicQbClasses?.length:
          return { data: basicQbClasses };
        case type === 'Term' && !!basicQbTerms?.length:
          return { data: basicQbTerms };
        case type === 'Vendor' && !!basicQbVendors?.length:
          return { data: basicQbVendors };
        case type === 'Item' && !!basicQbItems?.length:
          return { data: basicQbItems };
      }
    }
    const { data, error } = await authGet<Array<IdNamePair>>([
      '/system/qb-models/basic',
      { type },
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    if (cachedQbDataEntities.includes(type)) {
      dispatch(setBasicQbDataValues({ data, type }));
    }
    return { data };
  };
}

/**
 * Get Basic Custom View per List
 * @returns {AppThunk}
 */
export function getBasicListCustomViews(
  listType: string,
): AppThunk<Promise<NormalizeReturn<Array<SelectOption>>>> {
  return async (dispatch, getState) => {
    const { data, error } = await authGet<Array<SelectOption>>([
      '/system/custom-views/basic',
      {
        listType,
      },
    ]);

    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'Something went wrong',
        }),
      );
      return { error };
    }
    return { data };
  };
}

const cachedQbDataEntities: Array<QbDataModelType> = [
  'Class',
  'Term',
  'Vendor',
  'Item',
];
