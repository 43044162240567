import { Fragment, useEffect, useCallback, memo } from 'react';
import { Outlet, useLocation } from 'react-router';
import { CssBaseline } from '@mui/material';
import {
  useDispatch,
  useSelector,
  logout,
  toggleSidebar,
  systemSelectors,
  uiSelectors,
  setWeight,
  getMachineName,
  setSignalRDevicesConnectionError,
  setSignalRDevicesReconnecting,
  setSignalRDevicesDisconnected,
  setSignalRMessagesConnectionError,
  setSignalRMessagesDisconnected,
  setSignalRMessagesReconnecting,
  getBatchAlertById,
  handleGenericMessage,
  setSignalRDevicesConnectionSuccess,
  setSignalRMessagesConnectionSuccess,
  setSignalRAlertsConnectionError,
  setSignalRAlertsConnectionSuccess,
  setSignalRAlertsReconnecting,
  setSignalRAlertsDisconnected,
} from '../../state';
import {
  useIsMediumDesktop,
  useGetUsersData,
  useIsMobile,
  useIsTablet,
} from '../../hooks';
import { connectionTypes, paths } from '../../lib';
import { TrackTimePage } from '../../pages/TrackTimePage';
import { Notification, PageLoader } from '../ui';
import { Sidebar } from './Sidebar';
import {
  connectToSignalRDevice,
  connectToSignalRMessages,
  connectToSignalRAlerts,
} from '../../signalRConnection';
import { MobileLayout } from './MobileLayout';
import { CheckInTabletLayout } from './CheckInTabletLayout';
import { useStyles } from './layout.styles';

export const MainLayout = memo(function MainLayout({ ...props }) {
  const isMediumDesktop = useIsMediumDesktop();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { classes, cx } = useStyles();
  const machineName = useSelector(systemSelectors.machineName);
  const showTimer = useSelector(uiSelectors.showTimer);
  const { token, isDriverCheckInMode } = useGetUsersData();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const isTimerPage = pathname === `/${paths.TRACK_TIME}`;

  const handleLogout = useCallback(
    (e) => {
      dispatch(logout());
    },
    [dispatch],
  );

  useEffect(() => {
    if (isMediumDesktop) {
      dispatch(toggleSidebar(false));
    }
  }, [dispatch, isMediumDesktop]);

  const onScale = useCallback((weightObj) => {
    dispatch(setWeight(weightObj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOrderBatchMessage = useCallback(
    async (message) => {
      const { BatchID, Status } = message || {};
      await dispatch(getBatchAlertById(BatchID, Status));
    },
    [dispatch],
  );

  const onGenericMessage = useCallback(
    async (message) => {
      dispatch(handleGenericMessage(message));
    },
    [dispatch],
  );

  useEffect(() => {
    (async function () {
      if (!machineName) {
        await dispatch(getMachineName());
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConnectionSuccess = useCallback(
    (connectionType) => {
      if (connectionType === connectionTypes.DEVICES) {
        dispatch(setSignalRDevicesConnectionError(null));
        dispatch(setSignalRDevicesConnectionSuccess());
      }

      if (connectionType === connectionTypes.SYSTEM_ALERT) {
        dispatch(setSignalRAlertsConnectionError(null));
        dispatch(setSignalRAlertsConnectionSuccess());
      }

      if (connectionType === connectionTypes.SYSTEM_MESSAGE) {
        dispatch(setSignalRMessagesConnectionError(null));
        dispatch(setSignalRMessagesConnectionSuccess());
      }
    },
    [dispatch],
  );

  const onConnectionError = useCallback(
    (err, connectionType) => {
      connectionType === connectionTypes.DEVICES &&
        dispatch(setSignalRDevicesConnectionError(err));

      connectionType === connectionTypes.SYSTEM_ALERT &&
        dispatch(setSignalRAlertsConnectionError(err));

      connectionType === connectionTypes.SYSTEM_MESSAGE &&
        dispatch(setSignalRMessagesConnectionError(err));
    },
    [dispatch],
  );

  const onReconnecting = useCallback(
    (value, connectionType) => {
      connectionType === connectionTypes.DEVICES &&
        dispatch(setSignalRDevicesReconnecting(value));

      connectionType === connectionTypes.SYSTEM_ALERT &&
        dispatch(setSignalRAlertsReconnecting(value));

      connectionType === connectionTypes.SYSTEM_MESSAGE &&
        dispatch(setSignalRMessagesReconnecting(value));
    },
    [dispatch],
  );

  const onDisconnected = useCallback(
    (connectionType) => {
      connectionType === connectionTypes.DEVICES &&
        dispatch(setSignalRDevicesDisconnected(true));

      connectionType === connectionTypes.SYSTEM_ALERT &&
        dispatch(setSignalRAlertsDisconnected(true));

      connectionType === connectionTypes.SYSTEM_MESSAGE &&
        dispatch(setSignalRMessagesDisconnected(true));
    },
    [dispatch],
  );

  useEffect(() => {
    if (token) {
      connectToSignalRDevice({
        token,
        onReadScale: onScale,
        onConnectionSuccess,
        onConnectionError,
        onReconnecting,
        onDisconnected,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (token) {
      connectToSignalRMessages({
        token,
        onOrderBatchMessage,
        onConnectionSuccess,
        onConnectionError,
        onReconnecting,
        onDisconnected,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (token) {
      connectToSignalRAlerts({
        token,
        onGenericMessage,
        onConnectionSuccess,
        onConnectionError,
        onReconnecting,
        onDisconnected,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 250);
  }, [pathname]);

  if (isMobile) {
    return (
      <MobileLayout handleLogout={handleLogout} {...props}>
        <Outlet />
      </MobileLayout>
    );
  }

  return (
    <Fragment>
      <CssBaseline />
      <div className={classes.root}>
        {!isMobile && (!isTablet || !isDriverCheckInMode) && (
          <Sidebar {...props} />
        )}
        {isTablet && isDriverCheckInMode && <CheckInTabletLayout {...props} />}

        <main className={cx({ [classes.content]: !isTimerPage })}>
          <Outlet />
        </main>
        {/* The timer page is rendered as a full page when navigating to the timer route and as
        a fixed component on every other page so in order to keep the same state the layout will handle the rendering */}
        {(showTimer || isTimerPage) && <TrackTimePage {...props} />}
        <Notification />
        <PageLoader />
      </div>
    </Fragment>
  );
});
