import { makeStyles } from 'tss-react/mui';
import {
  IconButton,
  type IconButtonProps,
  type SvgIconProps,
} from '@mui/material';
import { DeleteIcon } from '../../../assets';
import { stableObject } from '../../../lib';

interface CustomIconButtonProps extends IconButtonProps {
  [key: `data-${string}`]: any;
}

interface Props extends SvgIconProps {
  wrapWithButton?: boolean;
  iconButtonProps?: CustomIconButtonProps;
}

export function ListDeleteIcon(props: Props) {
  const { classes, cx } = useStyles();
  const { className, wrapWithButton, iconButtonProps, ...rest } = props;
  return wrapWithButton ? (
    <IconButton size='small' {...(iconButtonProps || stableObject)}>
      <DeleteIcon {...rest} className={cx(classes.deleteIcon, className)} />
    </IconButton>
  ) : (
    <DeleteIcon
      {...rest}
      className={cx(classes.deleteIcon, classes.cursor, className)}
    />
  );
}

const useStyles = makeStyles()((theme) => ({
  deleteIcon: {
    color: '#D5D8EC',
    fontSize: 18,
  },
  cursor: {
    cursor: 'pointer',
  },
}));
