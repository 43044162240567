import { Fragment, useState, useCallback, memo } from 'react';
import {
  Drawer,
  Popper,
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
} from '@mui/material';
import { paths, generateValidatedParamsUrl } from '../../lib';
import { AccessTimeIcon } from '../../assets';
import {
  useDispatch,
  useSelector,
  logout,
  toggleSidebar,
  uiSelectors,
  clearSelectedIds,
} from '../../state';
import { useGetUsersData } from '../../hooks';
import { /*FrequentlyAccessed, */ Alerts } from '../ui';
import { useStyles } from './Sidebar.styles';
import { SidebarItem } from './SidebarItem';
import {
  AdminSidebar,
  CustomerServiceSidebar,
  TruckingJobUserSidebar,
  CustomerSidebar,
  ProcessorSidebar,
  ScannerSidebar,
  ScannerProcessorSidebar,
  AppointmentUserSidebar,
  ManagerUserSidebar,
} from './userSidebars';
import TplLogo from '../../assets/images/3pl-logo.svg';
import TplLogoSmall from '../../assets/images/3pl-logo-small.svg';

export const Sidebar = memo(function Sidebar() {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();

  const isSidebarOpen = useSelector(uiSelectors.isSidebarOpen);

  const {
    firstName,
    lastName,
    isAdmin,
    isCustomer,
    isCustomerService,
    isTruckingSalesPerson,
    isProcessor,
    isScanner,
    isScannerProcessor,
    isManageAppointmentUser,
    isManager,
  } = useGetUsersData();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback((event) => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(
    (e) => {
      handleClose(e);
      dispatch(logout());
    },
    [dispatch, handleClose],
  );

  const handleToggleSidebar = useCallback(() => {
    dispatch(toggleSidebar(!isSidebarOpen));
  }, [dispatch, isSidebarOpen]);

  const handleClearSelectedOrders = useCallback(() => {
    dispatch(clearSelectedIds({ resource: 'orders' }));
  }, [dispatch]);

  return (
    <Fragment>
      <Drawer
        variant='permanent'
        className={cx({
          [classes.drawerOpen]: isSidebarOpen,
          [classes.drawerClose]: !isSidebarOpen,
        })}
        classes={{
          paper: cx({
            [classes.drawerOpen]: isSidebarOpen,
            [classes.drawerClose]: !isSidebarOpen,
          }),
        }}
      >
        <div className={classes.content}>
          <div>
            <List>
              <ListItemButton
                onClick={handleToggleSidebar}
                // className={classes.ListItem}
              >
                <div className={classes.logo}>
                  {isSidebarOpen ? (
                    <TplLogo draggable={false} />
                  ) : (
                    <TplLogoSmall draggable={false} />
                  )}
                </div>
                {/* <ListItemIcon>
                  <TplLogo />
                </ListItemIcon> */}
              </ListItemButton>
            </List>
            <List>
              {isAdmin && (
                <AdminSidebar
                  handleClearSelectedOrders={handleClearSelectedOrders}
                />
              )}
              {isCustomerService && (
                <CustomerServiceSidebar
                  handleClearSelectedOrders={handleClearSelectedOrders}
                />
              )}
              {isTruckingSalesPerson && (
                <TruckingJobUserSidebar
                  handleClearSelectedOrders={handleClearSelectedOrders}
                />
              )}
              {isProcessor && !isScannerProcessor && (
                <ProcessorSidebar
                  handleClearSelectedOrders={handleClearSelectedOrders}
                />
              )}
              {isScannerProcessor && (
                <ScannerProcessorSidebar
                  handleClearSelectedOrders={handleClearSelectedOrders}
                />
              )}
              {isScanner && !isScannerProcessor && <ScannerSidebar />}
              {isCustomer && (
                <CustomerSidebar
                  handleClearSelectedOrders={handleClearSelectedOrders}
                />
              )}
              {isManageAppointmentUser && <AppointmentUserSidebar />}
              {isManager && <ManagerUserSidebar />}
            </List>
          </div>
          <div>
            <List>
              {!isCustomer && !isManageAppointmentUser && (
                <SidebarItem
                  to={generateValidatedParamsUrl(paths.TRACK_TIME)}
                  text=''
                  icon={AccessTimeIcon}
                />
              )}
              {!isManageAppointmentUser && (
                <ListItemButton style={{ paddingLeft: 14 }}>
                  {/* <FrequentlyAccessed /> */}
                  <Alerts />
                </ListItemButton>
              )}
            </List>
          </div>
        </div>
        <ListItemButton
          onMouseEnter={handleClick}
          onMouseLeave={handleClose}
          disableGutters
          style={{ paddingLeft: 14 }}
        >
          <ListItemIcon>
            <Avatar className={classes.avatar}>{`${
              firstName?.charAt(0)?.toUpperCase() || ''
            }${lastName?.charAt(0)?.toUpperCase() || ''}`}</Avatar>
          </ListItemIcon>
          {isSidebarOpen && (
            <ListItemText primary={`${firstName || ''} ${lastName || ''}`} />
          )}
          <Popper
            // disablePortal={true}
            className={classes.logout}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            // onClose={handleClose}
            placement={'right'}
            // anchorOrigin={{
            //   vertical: "top",
            //   horizontal: "left",
            // }}
            // transformOrigin={{
            //   vertical: "bottom",
            //   horizontal: "left",
            // }}
          >
            <Button
              id='log-out'
              color='primary'
              variant='contained'
              className={classes.logout}
              onClick={handleLogout}
            >
              log out
            </Button>
          </Popper>
        </ListItemButton>
      </Drawer>
    </Fragment>
  );
});
