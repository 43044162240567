import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { formatISO } from 'date-fns';
import { IdNamePair } from '../../types/globalTypes';
import { MaterialBasicDetails, QbDataModelType } from '../../types';

interface SystemState {
  weight:
    | {
        pounds: string | number;
        ouncesPercent: string | number;
      }
    | object;
  signalRDevicesConnected: boolean;
  signalRDevicesConnectionError: object | null;
  signalRDevicesReconnecting: boolean;
  signalRDevicesDisconnected: boolean;
  signalRDevicesSubscribedToScale: boolean;
  signalRMessagesConnected: boolean;
  signalRMessagesConnectionError: object | null;
  signalRMessagesReconnecting: boolean;
  signalRMessagesDisconnected: boolean;
  signalRAlertsConnected: boolean;
  signalRAlertsConnectionError: object | null;
  signalRAlertsReconnecting: boolean;
  signalRAlertsDisconnected: boolean;
  batchTemplates: Array<IdNamePair>;
  basicQbVendors: Array<IdNamePair>;
  basicQbTerms: Array<IdNamePair>;
  basicQbClasses: Array<IdNamePair>;
  basicQbItems: Array<IdNamePair>;
  scannedMaterialMapping: Record<string | number, MaterialBasicDetails>;
  lastFetchedScannedMaterialMappingOn: string | null;
}

interface ScannedMaterialMappingPayload {
  scannedMaterial: string | number;
  results: MaterialBasicDetails;
}

interface QbDataPayload {
  type: QbDataModelType;
  data: Array<IdNamePair>;
}

type CachedType = 'scannedMaterialMapping';

const initialState: SystemState = {
  weight: {},
  signalRDevicesConnected: false, // If successfully connected
  signalRDevicesConnectionError: null, // If the initial connection failed
  signalRDevicesReconnecting: false, // If the connection is temporarily lost
  signalRDevicesDisconnected: false, // If the connection is permanently lost
  signalRDevicesSubscribedToScale: false, // If successfully subscribed to scale
  signalRMessagesConnected: false, // If successfully connected
  signalRMessagesConnectionError: null, // If the initial connection failed
  signalRMessagesReconnecting: false, // If the connection is temporarily lost
  signalRMessagesDisconnected: false, // If the connection is permanently lost
  signalRAlertsConnected: false, // If successfully connected
  signalRAlertsConnectionError: null, // If the initial connection failed
  signalRAlertsReconnecting: false, // If the connection is temporarily lost
  signalRAlertsDisconnected: false, // If the connection is permanently lost
  batchTemplates: [],
  basicQbVendors: [],
  basicQbTerms: [],
  basicQbClasses: [],
  basicQbItems: [],
  scannedMaterialMapping: {},
  lastFetchedScannedMaterialMappingOn: null,
};

const system = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setSignalRMessagesConnectionError(state, action) {
      state.signalRMessagesConnectionError = action.payload;
    },
    setSignalRMessagesConnectionSuccess(state, action) {
      state.signalRMessagesConnected = true;
    },
    setSignalRMessagesReconnecting(state, action) {
      state.signalRMessagesReconnecting = action.payload;
    },
    setSignalRMessagesDisconnected(state, action) {
      state.signalRMessagesDisconnected = action.payload;
    },
    setSignalRAlertsConnectionError(state, action) {
      state.signalRAlertsConnectionError = action.payload;
    },
    setSignalRAlertsConnectionSuccess(state, action) {
      state.signalRAlertsConnected = true;
    },
    setSignalRAlertsReconnecting(state, action) {
      state.signalRAlertsReconnecting = action.payload;
    },
    setSignalRAlertsDisconnected(state, action) {
      state.signalRAlertsDisconnected = action.payload;
    },
    setSignalRDevicesConnectionSuccess(state, action) {
      state.signalRDevicesConnected = true;
    },
    setSignalRDevicesConnectionError(state, action) {
      state.signalRDevicesConnectionError = action.payload;
    },
    setSignalRDevicesReconnecting(state, action) {
      state.signalRDevicesReconnecting = action.payload;
    },
    setSignalRDevicesDisconnected(state, action) {
      state.signalRDevicesDisconnected = action.payload;
    },
    setSignalRDevicesSubscribedToScale(state, action) {
      state.signalRDevicesSubscribedToScale = action.payload;
    },
    setWeight(state, action) {
      state.weight = action.payload;
    },
    setBatchTemplates(state, action) {
      state.batchTemplates = action.payload;
    },
    setBasicQbDataValues(state, action: PayloadAction<QbDataPayload>) {
      const { data, type } = action.payload;
      switch (type) {
        case 'Vendor':
          state.basicQbVendors = data;
          break;
        case 'Term':
          state.basicQbTerms = data;
          break;
        case 'Class':
          state.basicQbClasses = data;
          break;
        case 'Item':
          state.basicQbItems = data;
          break;
      }
    },
    setScannedMaterialMapping(
      state,
      action: PayloadAction<ScannedMaterialMappingPayload>,
    ) {
      state.scannedMaterialMapping[action.payload.scannedMaterial] =
        action.payload.results;
      state.lastFetchedScannedMaterialMappingOn = formatISO(new Date());
    },
    invalidateMaterialCache(state, action: PayloadAction<CachedType>) {
      switch (action.payload) {
        case 'scannedMaterialMapping':
          state.lastFetchedScannedMaterialMappingOn = null;
          state.scannedMaterialMapping = {};
          break;
      }
    },
  },
});

export const {
  setSignalRMessagesConnectionSuccess,
  setSignalRDevicesConnectionError,
  setSignalRDevicesReconnecting,
  setSignalRDevicesDisconnected,
  setSignalRDevicesConnectionSuccess,
  setSignalRMessagesConnectionError,
  setSignalRMessagesDisconnected,
  setSignalRMessagesReconnecting,
  setSignalRAlertsConnectionError,
  setSignalRAlertsConnectionSuccess,
  setSignalRAlertsDisconnected,
  setSignalRAlertsReconnecting,
  setSignalRDevicesSubscribedToScale,
  setWeight,
  setBatchTemplates,
  setBasicQbDataValues,
  setScannedMaterialMapping,
  invalidateMaterialCache,
} = system.actions;

export const systemReducer = system.reducer;
