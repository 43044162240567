import {
  Fragment,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import {
  Chip,
  MenuList,
  MenuItem,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Typography,
  Switch,
  IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import { FilterMenu } from '../../';
import { Dropdown } from '../../common';
import { useUpdatePageQueryParams, useGetQueryParams } from '../../../hooks';
import { ArrowDropDownIcon, ArrowDropUpIcon, CloseIcon } from '../../../assets';
import { stableObject } from '../../../lib';
import { MouseChangeEventType } from '../../../types';

interface Props {
  label?: string;
  queryParam?: string;
  filters?: Record<string, any>;
  defaultValue?: string;
  filterSortIds?: Array<number>;
  clearable?: boolean;
  className?: string;
}

export const FiltersSelect = ({
  label = 'Filters',
  defaultValue,
  queryParam = 'list-filters',
  filters: _filters,
  filterSortIds,
  className,
  clearable = true,
  ...rest
}: Props) => {
  const { classes, cx } = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const filters = _filters || stableObject;
  const { [queryParam]: queryListFilters } = useGetQueryParams();
  const [selectAll, setSelectAll] = useState(false);

  // `queryListFilters` can be either undefined or a string (if only one) or an array.
  const filtersArray = useMemo(
    () =>
      typeof queryListFilters === 'string'
        ? [queryListFilters]
        : queryListFilters,
    [queryListFilters],
  );

  const [curFilters, setCurFilters] = useState(
    filtersArray ?? defaultValue ?? [],
  );

  const [open, setOpen] = useState(false);

  const isFirstRender = useRef(true);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = useCallback(
    (event: MouseEvent | TouchEvent | MouseChangeEventType) => {
      if (anchorRef.current?.contains(event.currentTarget as Node)) {
        return;
      }
      setOpen(false);
    },
    [],
  );

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      if (defaultValue) {
        setCurFilters(defaultValue);
      }
      return;
    } else if (filtersArray !== curFilters) {
      setCurFilters(filtersArray ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersArray, defaultValue]);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleSelect = (filterId, filterName) => {
    setSelectAll(false);
    if (curFilters.includes(filterId)) {
      const newFilters = curFilters.filter((f) => f !== filterId) || [];
      updatePageQueryParams({
        [queryParam]: newFilters.length ? newFilters : null,
      });
      setCurFilters(newFilters);
    } else {
      const noDupes = Array.from(new Set([...curFilters, filterId]));
      updatePageQueryParams({
        [queryParam]: noDupes,
      });
      setCurFilters(noDupes);
    }
  };

  function handleClearAll(e) {
    updatePageQueryParams({
      [queryParam]: null,
    });
    setCurFilters([]);
    setOpen(false);
  }

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    setSelectAll(checked);
    if (checked) {
      const allFilters = Object.keys(filters).map((key) =>
        typeof filters[key] === 'object' ? filters[key].id : key,
      );
      const noDupes = Array.from(new Set([...curFilters, ...allFilters]));
      updatePageQueryParams({
        [queryParam]: noDupes,
      });
      setCurFilters(noDupes);
    } else {
      handleClearAll(e);
    }
  };
  const { text, icon } = getLabel({
    labelProp: label,
    curFilters,
    allFilters: filters,
    isOpen: open,
    iconClass: classes.arrowIcon,
  });

  return (
    <Fragment>
      <Chip
        ref={anchorRef}
        clickable
        color={!!curFilters.length ? 'primary' : 'default'}
        label={text}
        className={classes.chip}
        onDelete={clearable && !!curFilters.length ? handleClearAll : undefined}
        icon={icon}
        variant='outlined'
        onClick={handleToggle}
      />
      <Dropdown
        ref={anchorRef}
        open={open}
        handleClose={handleClose}
        popperProps={{ style: { zIndex: 1 }, placement: 'bottom-start' }}
      >
        <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
          <ListItem
            secondaryAction={
              <IconButton onClick={handleClose} size='large'>
                <CloseIcon />
              </IconButton>
            }
          >
            <ListItemText primary={label} />
          </ListItem>
          <ListItem
            secondaryAction={
              <Switch
                checked={selectAll}
                onChange={handleSelectAll}
                color='primary'
              />
            }
          >
            <ListItemText primary='Select all' />
          </ListItem>
          {(filterSortIds || Object.keys(filters)).map((key) => {
            const value =
              typeof filters[key] === 'object' ? filters[key].id : key;
            const displayVal =
              typeof filters[key] === 'object'
                ? filters[key].name
                : filters[key];

            const selected = curFilters?.includes(value);
            return (
              <MenuItem
                key={key}
                onClick={(_) => handleSelect(key, value)}
                dense
              >
                <ListItemIcon>
                  <Checkbox size='small' color='primary' checked={selected} />
                </ListItemIcon>
                <Typography
                  className={cx(classes.text, {
                    [classes.selectedText]: selected,
                  })}
                >
                  {displayVal}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Dropdown>
    </Fragment>
  );
};

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    // So we see the popover when it's empty.
    minWidth: 16,
    minHeight: 16,
    maxWidth: 'calc(100vw - 32px)',
    maxHeight: 'calc(100vh - 32px)',
    width: 300,
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,
  },
  chip: {
    margin: '0 4px 10px 4px',
  },
  text: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 16,
  },
  selectedText: {
    color: '#000000',
  },
  arrowIcon: {
    fontSize: 18,
  },
}));

function getLabel({ labelProp, curFilters, allFilters, isOpen, iconClass }) {
  let text = '';
  let icon;

  const firstSelected = curFilters[0];
  const firstSelectedName =
    typeof allFilters[curFilters[firstSelected]] === 'object'
      ? allFilters[firstSelected].name
      : allFilters[firstSelected];

  switch (true) {
    case curFilters.length === 0:
      text = labelProp;
      icon = isOpen ? (
        <ArrowDropDownIcon className={iconClass} />
      ) : (
        <ArrowDropUpIcon className={iconClass} />
      );
      break;
    case curFilters.length === 1:
      text = firstSelectedName;
      break;
    case curFilters.length > 1:
      text = `${labelProp} +${curFilters.length - 1}`;
      break;
    default:
      text = `${labelProp}`;
  }

  return {
    text,
    icon,
  };
}
