import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { Grid2 as Grid } from '@mui/material';
import { FormModal } from './FormModal';
import { AutocompleteInput, SelectInput, TextInput } from '../inputs';
import {
  useDispatch,
  addResource,
  fetchResourceById,
  deleteResource,
  getBasicCompaniesList,
  useSelector,
  systemSelectors,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { stableObject } from '../../../lib';
import { ContactAddressFields } from './ContactFields';

import {
  ContactAPI,
  ContactType,
  IdNamePair,
  JobTitleType,
} from '../../../types';

interface OverrideFields {
  companyID?: number | string;
  contactType?: ContactType;
  jobTitle?: JobTitleType;
  email?: string;
}

interface Props {
  handleClose: () => any;
  open: boolean;
  contactId?: number;
  /**
   * These values will be hidden and sent to the API
   */
  overrideValues?: OverrideFields;
  hideDelete?: boolean;
}

interface State {
  companyID: string | number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  faxNumber: string;
  email: string;
  address1: string;
  city: string;
  stateID: number | '';
  zip: string;
  countryID: number | '';
  contactType: ContactType | '';
  jobTitle: JobTitleType | '';
}

const initialValues: State = {
  companyID: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  faxNumber: '',
  email: '',
  address1: '',
  city: '',
  stateID: '',
  zip: '',
  countryID: '',
  contactType: '',
  jobTitle: '',
};

export function UpsertContactForm({
  handleClose,
  open,
  contactId,
  overrideValues: _overrideValues,
  hideDelete,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [state, setState] = useState<State>({} as State);
  const [contact, setContact] = useState<ContactAPI>({});
  const [companiesList, setCompaniesList] = useState<Array<IdNamePair>>([]);
  const [submitting, setSubmitting] = useState(false);

  const overrideValues = _overrideValues ?? (stableObject as OverrideFields);

  const contactTypes = useSelector(systemSelectors.contactTypes);
  const jobTitleTypes = useSelector(systemSelectors.jobTitleTypes);
  const countryUsId = useSelector(systemSelectors.countryUsId);

  useEffect(() => {
    (async function () {
      if (contactId) {
        const { data } = await dispatch(
          fetchResourceById<ContactAPI>({
            baseUrl: '/accounts/contact',
            id: contactId,
          }),
        );
        if (data) {
          setContact(data);
          setState((cur) => ({
            ...cur,
            firstName: data.person?.firstName ?? '',
            lastName: data.person?.lastName ?? '',
            phoneNumber: data.person?.officePhone ?? '',
            faxNumber: data.person?.faxNumber ?? '',
            email: data.person?.email ?? '',
            contactType: data.contactType ?? '',
            jobTitle: data.jobTitle ?? '',
            companyID: data.companyID ?? '',
            address1: data.person?.address?.address1 ?? '',
            city: data.person?.address?.city ?? '',
            stateID: data.person?.address?.stateID ?? '',
            zip: data.person?.address?.zip ?? '',
            countryID: data.person?.address?.countryID ?? countryUsId ?? '',
            ...overrideValues,
          }));
        }
      } else {
        setState((cur) => ({
          ...cur,
          ...overrideValues,
          countryID: countryUsId ?? '',
        }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  useEffect(() => {
    (async function () {
      if (!overrideValues.companyID) {
        const { data } = await dispatch(
          getBasicCompaniesList({
            getAnonymous: true,
            params: { includeManualCarriers: true },
          }),
        );
        if (data) {
          setCompaniesList(data);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    async (values: State) => {
      const payload = generatePayload(values, contact);
      const { error } = await dispatch(
        addResource({
          baseUrl: '/accounts/contact',
          payload,
          shouldSetUILoading: false,
          message: 'Changes saved',
          shouldShowErrorMessage: true,
        }),
      );
      if (!error) {
        refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contact],
  );

  const handleDelete = useCallback(async () => {
    if (!contactId) return;
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/accounts/contact',
        id: contactId,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  const formik = useFormik({
    initialValues: { ...initialValues, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Edit contact info</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      isEdit={!!contactId}
      handleDelete={handleDelete}
      submitting={submitting}
      hideDeleteBtn={hideDelete}
    >
      <div className={classes.layout}>
        <Grid container spacing={5} rowSpacing={2}>
          {!overrideValues.companyID && (
            <Grid
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <AutocompleteInput
                formikProps={formik}
                textFieldProps={{
                  name: 'companyID',
                  label: 'Company',
                  required: true,
                }}
                autocompleteProps={{
                  className: classes.input,
                  options: companiesList,
                }}
              />
            </Grid>
          )}
          {!overrideValues.contactType && (
            <Grid
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <SelectInput
                name='contactType'
                formikProps={formik}
                label='Contact type'
                options={contactTypes}
              />
            </Grid>
          )}
          {!overrideValues.jobTitle && (
            <Grid
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <SelectInput
                name='jobTitle'
                formikProps={formik}
                label='Job title'
                options={jobTitleTypes}
              />
            </Grid>
          )}
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='firstName'
              formikProps={formik}
              label='First name'
              required
              className={classes.input}
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='lastName'
              formikProps={formik}
              label='Last name'
              required
              className={classes.input}
            />
          </Grid>
          {!overrideValues.email && (
            <Grid
              style={{ paddingBottom: 0 }}
              size={{
                sm: 6,
              }}
            >
              <TextInput
                name='email'
                formikProps={formik}
                label='Email'
                className={classes.input}
              />
            </Grid>
          )}
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='phoneNumber'
              formikProps={formik}
              label='Phone number'
              className={classes.input}
              format='phone'
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='faxNumber'
              formikProps={formik}
              label='Fax number'
              className={classes.input}
              format='phone'
            />
          </Grid>
          <ContactAddressFields
            formikProps={formik}
            idUpperCase
            inputClassName={classes.input}
            addressFieldName='address1'
          />
        </Grid>
      </div>
    </FormModal>
  );
}

const schema = object().shape({
  firstName: string().required('Required'),
  lastName: string().required('Required'),
});

function generatePayload(state: State, contact: ContactAPI): ContactAPI {
  const {
    companyID,
    firstName,
    lastName,
    phoneNumber,
    email,
    faxNumber,
    contactType,
    jobTitle,
    address1,
    city,
    stateID,
    zip,
    countryID,
  } = state;

  return {
    ...contact,
    companyID,
    contactType: contactType || undefined,
    jobTitle: jobTitle || undefined,
    person: {
      ...contact.person,
      firstName,
      lastName,
      officePhone: phoneNumber,
      faxNumber,
      email,
      address: {
        ...(contact.person?.address ?? {}),
        address1,
        city,
        stateID: stateID || undefined,
        zip,
        countryID: countryID || undefined,
      },
    },
  };
}

const useStyles = makeStyles({ name: { EditContactForm: UpsertContactForm } })(
  (theme) => ({
    layout: {
      padding: '0 16px 40px 16px',
    },
    title: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 600,
    },
    input: {
      marginBottom: 16,
      width: 300,
    },
  }),
);
