import { memo } from 'react';
import _get from 'lodash.get';
import { Typography, TypographyProps } from '@mui/material';
import { formatCurrency, stableObject } from '../../../lib';

import { useStyles } from './fields.styles';
import { AlignType } from '../../../types';

interface Props {
  typographyProps?: TypographyProps;
  format?: string;
  record?: Record<string, any>;
  className?: string;
  headerClassName?: string;
  cellStyle?: React.CSSProperties;
  source: string;
  label: string;
  emptyText?: string;
  disablePadding?: string;
  align?: AlignType;
  sortBy?: string;
  sortable?: false;
}

export const CurrencyField = memo<Props>(
  ({
    className,
    typographyProps: _typographyProps,
    source,
    record: _record,
    emptyText = '--',
    ...rest
  }) => {
    const { classes, cx } = useStyles();
    const record = _record || stableObject;
    const typographyProps = _typographyProps || stableObject;
    const value = _get(record, source);
    if (!value) return <span>{emptyText}</span>;
    return (
      <Typography
        component='span'
        variant='body2'
        className={cx(classes.field, className)}
        {...typographyProps}
      >
        {formatCurrency(value, 2)}
      </Typography>
    );
  },
);
