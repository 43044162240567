import { useCallback, useState, Fragment } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ListDeleteIcon } from '../list';
import { useDispatch, deleteResource } from '../../state';
import { DeleteConfirmation } from '.';
import { useRefresh } from '../../hooks';
import { DeleteIcon } from '../../assets';

interface Props {
  text: string;
  title: string;
  btnText?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  baseUrl: string;
  id: string | number;
  path: string;
  query?: Record<string, any>;
  style?: React.CSSProperties;
  afterDeleteCallback?: () => void;
  shouldRefresh?: boolean;
  btnType?: 'button' | 'icon';
  showIcon?: boolean;
  setViewVersion?: React.Dispatch<React.SetStateAction<number>>;
  className?: string;
  buttonProps?: Omit<ButtonProps, 'onClick | className'>;
}

export function DeleteButton({
  text,
  title,
  btnText = 'DELETE',
  confirmBtnText = 'DELETE',
  cancelBtnText = 'CANCEL',
  baseUrl,
  id,
  path,
  query,
  style,
  afterDeleteCallback,
  shouldRefresh = true,
  btnType = 'button', // button or icon
  showIcon,
  setViewVersion,
  buttonProps,
  className,
}: Props) {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({ baseUrl, id, path, query }),
    );
    setSubmitting(false);
    if (response.data) {
      if (shouldRefresh) {
        setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
      }
      afterDeleteCallback && afterDeleteCallback();
      setShowModal(false);
    }
  }, [
    afterDeleteCallback,
    baseUrl,
    dispatch,
    id,
    path,
    query,
    refresh,
    setViewVersion,
    shouldRefresh,
  ]);

  return (
    <Fragment>
      {btnType === 'icon' ? (
        <ListDeleteIcon onClick={handleClick} className={className} />
      ) : (
        <Button
          className={cx(classes.deleteButton, className)}
          onClick={handleClick}
          style={style}
          startIcon={
            showIcon ? <DeleteIcon style={{ fontSize: 16 }} /> : undefined
          }
          {...buttonProps}
        >
          {btnText}
        </Button>
      )}
      {showModal && (
        <DeleteConfirmation
          open={showModal}
          handleClose={() => setShowModal(false)}
          handleDelete={handleDelete}
          text={text}
          title={title}
          btnText={confirmBtnText}
          cancelText={cancelBtnText}
          submitting={submitting}
          submitBtnClassName={className}
        />
      )}
    </Fragment>
  );
}

const useStyles = makeStyles({ name: { DeleteButton } })((theme) => ({
  deleteButton: {
    color: '#FF4F5B',
    fontSize: 14,
    fontWeight: 600,
  },
}));
