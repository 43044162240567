import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch /*, useSelector, shallowEqual */ } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import {
  addResource,
  deleteResource,
  fetchResourceById,
  // systemSelectors,
} from '../../../state';
import { convertNullFieldsToEmptyString, getFeeAmountType } from '../../../lib';

const initialState = {
  // feeName: '',
  // feeType: '',
  feeAmount: '',
  feePercentage: '',
};

export function CustomerDrayageFeeForm({
  open,
  handleClose,
  customerId,
  carrierId,
  feeId,
  feeType,
  defaultCarrierFeeID,
  defaultFeeName,
  setViewVersion,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  // const feeTypes = useSelector(systemSelectors.shippingFeeTypes, shallowEqual);

  useEffect(() => {
    (async function () {
      if (carrierId && feeId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/customers',
            id: customerId,
            path: `carrier-settings/${carrierId}`,
            query: { carrierType: 'Drayage' },
          }),
        );
        if (data) {
          const { drayageFees, carrierSettingID } = data;
          let newState = { id: carrierSettingID };
          const fee = drayageFees?.find((f) => f.id === parseInt(feeId));
          if (fee) {
            const { name, percentage, amount, type } = fee;
            newState = {
              ...newState,
              ...convertNullFieldsToEmptyString({
                feeName: name,
                feeType: type,
                feeAmount: amount,
                feePercentage: percentage,
              }),
            };
          }
          setState((cur) => ({
            ...cur,
            ...newState,
          }));
        }
      } else {
        setState((cur) => ({ ...cur, feeType, feeName: defaultFeeName ?? '' }));
      }
    })();
  }, [carrierId, customerId, defaultFeeName, dispatch, feeId, feeType]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const { error } = await dispatch(
        addResource({
          baseUrl: `/customers/${customerId}/carrier-settings/${carrierId}`,
          payload: {
            carrierType: 'Drayage',
            feeType,
            ...values,
            carrierFeeID: defaultCarrierFeeID,
            feeID: feeId,
          },
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion((cur) => ++cur);
        handleClose();
      }
    },
    [
      carrierId,
      customerId,
      defaultCarrierFeeID,
      dispatch,
      feeId,
      feeType,
      handleClose,
      setViewVersion,
    ],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: `/customers/carrier-settings`,
        id: state.id,
        path: `fees/${feeId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      setViewVersion((cur) => ++cur);
      handleClose();
    }
  }, [dispatch, feeId, handleClose, setViewVersion, state.id]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const isFeeTypeAmount = getFeeAmountType(formik.values.feeType) === 'amount';

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${feeId ? 'Edit' : 'Add'} fee`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={!!feeId}
      handleDelete={handleDelete}
    >
      {/* <TextInput
        name='feeName'
        formikProps={formik}
        label='Fee name'
        className={classes.input}
        required
      />
      <SelectInput
        name='feeType'
        formikProps={formik}
        options={feeTypes}
        label='Fee type'
        className={classes.input}
        required
      /> */}
      <TextInput
        name={isFeeTypeAmount ? 'feeAmount' : 'feePercentage'}
        formikProps={formik}
        label='Customer Rate'
        className={classes.input}
        format={isFeeTypeAmount ? 'currency' : undefined}
        slotProps={{
          input: {
            endAdornment: !isFeeTypeAmount ? <span>%</span> : undefined,
          },
        }}
        required
      />
    </FormModal>
  );
}

const schema = object().shape({
  // feeName: string().required('Required'),
  // feeType: string().required('Required'),
  feePercentage: string('Must be string').when('feeType', {
    is: 'FuelFee',
    then: string().required('Required'),
  }),
  feeAmount: string('Must be string').when('feeType', (type, schema) => {
    return type !== 'FuelFee' ? schema.required('Required') : schema;
  }),
});

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));
