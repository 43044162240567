import { Fragment, useCallback, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { ArrowBackIcon, ExpandMoreIcon, MoreHorizIcon } from '../../assets';
import { IconButtonLink } from '../../components';
import { MobilePageHeaderNotification } from './MobilePageHeaderNotification';

interface Props {
  header: React.ReactNode;
  backNavigation?: string;
  onBackClick?: () => any;
  handleOpenModal?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((open: boolean) => any);
  notification?: string;
  showNotificationOnFirstRender?: boolean;
  onNotificationClose?: () => any;
  toggleDetails?: () => any;
}

export function MobilePageHeader({
  header,
  backNavigation,
  onBackClick,
  handleOpenModal,
  notification,
  showNotificationOnFirstRender,
  onNotificationClose,
  toggleDetails,
}: Props) {
  const { classes, css } = useStyles();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleOpen = useCallback(() => {
    handleOpenModal?.(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppBar
      position='fixed'
      elevation={0}
      color='default'
      className={classes.layout}
    >
      <Toolbar>
        <div className={classes.navigator}>
          {!!onBackClick && (
            <IconButton
              className={classes.backIcon}
              onClick={onBackClick}
              size='small'
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          {!!backNavigation && (
            <IconButtonLink
              className={classes.backIcon}
              to={backNavigation}
              size='small'
            >
              <ArrowBackIcon />
            </IconButtonLink>
          )}
          <div className={classes.header}>
            {header}
            {!!toggleDetails && (
              <IconButton
                ref={anchorRef}
                size='small'
                onClick={toggleDetails}
                className={css({ padding: 0, marginLeft: 4 })}
              >
                <ExpandMoreIcon htmlColor='#000000' />
              </IconButton>
            )}
          </div>
          {!!notification && (
            <MobilePageHeaderNotification
              ref={anchorRef}
              notification={notification}
              onNotificationClose={onNotificationClose}
              showNotificationOnFirstRender={showNotificationOnFirstRender}
            />
          )}
        </div>
        {!!handleOpenModal && (
          <Fragment>
            <span className={classes.spacer} />
            <IconButton onClick={handleOpen}>
              <MoreHorizIcon className={classes.threeDotIcon} />
            </IconButton>
          </Fragment>
        )}
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles({ name: { MobilePageHeader } })((theme) => ({
  layout: {
    top: 0,
    bottom: 'auto',
    background: '#FFFFFF',
    paddingTop: 24,
  },
  spacer: {
    flexGrow: 1,
  },
  navigator: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    display: 'flex',
    alignItems: 'center',
  },
  backIcon: {
    color: '#43485B',
    marginRight: 20,
    fontSize: 16,
  },
  threeDotIcon: {
    color: '#43485B',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
  },
}));
