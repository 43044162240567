import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid2 as Grid, Table, DialogActions } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { date, object, string } from 'yup';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import {
  useDispatch,
  useSelector,
  systemSelectors,
  addBill,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { CloseIcon, ErrorIcon } from '../../../assets';
import {
  authGet,
  formatCurrency,
  updateNestedState,
  roundDownToDecimal,
} from '../../../lib';
import {
  CurrencyField,
  DataGrid,
  FunctionField,
  FormikDateInput,
  SelectInput,
  TextInput,
} from '../..';

const defaultCharge = {
  adjustmentNote: '',
  amount: '',
  billShipmentID: '',
  internalShipmentID: '',
  reconciliationType: '',
  shipmentTrackingNumber: '',
};

const initialState = {
  billID: null,
  externalBillID: '',
  billDate: null,
  billShipments: {},
  shouldSendToQB: null,
};

export function EnterBillForm({
  drayageContainerID,
  drayageJobID,
  handleClose,
  open,
  orderID,
  selectedRows,
  selectedIds,
  setViewVersion,
  shipmentID,
  shipmentJobID,
}) {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [submitting, setSubmitting] = useState(false);
  const [isAmountDiff, setIsAmountDiff] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [shipmentsData, setShipmentsData] = useState(selectedRows || {});
  const [orderShipments, setOrderShipments] = useState([]);

  const reconciliationTypes = useSelector(systemSelectors.reconciliationTypes);

  const reconciliationTypesOptions = useMemo(
    () => reconciliationTypes.filter((type) => type.enumInt > 0),
    [reconciliationTypes],
  );

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = generatePayload(values);
      await dispatch(addBill(payload));
      setSubmitting(false);
      // We alway close the modal and refresh incase the error occurred after an internal bill or invoice
      // was created
      setViewVersion && setViewVersion((cur) => ++cur);
      refresh();
      handleClose();
    },
    [dispatch, handleClose, refresh, setViewVersion],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const { setValues } = formik;

  const fetchShipmentData = useCallback(
    async (shipmentID) => {
      const { data } = await authGet([`/shipping/shipment/${shipmentID}`]);
      if (data) {
        const shipmentData = {
          drayageContainerID,
          drayageJobID,
          estimatedShipmentCost: data.shipmentCost,
          insuranceCost: data.insuranceCost,
          shipmentCostWithAdjustment:
            data.shipmentCostWithAdjustment ??
            data.shipmentCost + (data.insuranceCost ?? 0),
          totalBilledAmount: data.totalBilledAmount ?? 0,
          id: data.id,
          orderID,
          shipmentID: data.id,
          shipmentJobID,
          trackingNumber: data.trackingNumber,
        };
        setShipmentsData({ [shipmentID]: shipmentData });
      }
    },
    [drayageContainerID, drayageJobID, orderID, shipmentJobID],
  );

  useEffect(() => {
    (async function () {
      if (shipmentID) {
        await fetchShipmentData(shipmentID);
      }
    })();
  }, [fetchShipmentData, shipmentID]);

  useEffect(() => {
    (async function () {
      if (orderID) {
        const { data } = await authGet([`/orders/${orderID}/shipment/basic`]);
        if (data) {
          setOrderShipments(data);
        }
      }
    })();
  }, [orderID]);

  useEffect(() => {
    const billShipmentRows = Object.values(shipmentsData).reduce((acc, cur) => {
      var billedAmount = cur.totalBilledAmount ?? 0;
      // const amount = parseFloat(
      //   Math.max(cur.shipmentCostWithAdjustment - billedAmount, 0).toFixed(2) ??
      //     0,
      // );
      const amount =
        roundDownToDecimal(
          Math.max(cur.shipmentCostWithAdjustment - billedAmount, 0),
          2,
        ) ?? 0;

      acc[cur.id] = {
        ...defaultCharge,
        amount: amount > 0 ? amount : '',
        billShipmentID: cur.billShipmentID,
        internalShipmentID: cur.shipmentID,
        shipmentTrackingNumber: cur.trackingNumber,
        needsReconciliation:
          !!cur.totalBilledAmount &&
          billedAmount >= cur.shipmentCostWithAdjustment,
      };
      schema.fields.billShipments.fields[cur.id] = billShipmentsSchema;
      return acc;
    }, {});
    setValues((cur) => ({
      ...cur,
      billShipments: billShipmentRows,
    }));
    if (Object.values(billShipmentRows).some((c) => c.needsReconciliation)) {
      setIsAmountDiff(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentsData]);

  const {
    totalEstimatedCost,
    totalShipmentCostWithAdjustment,
    totalBilledAmount,
  } = useMemo(
    () =>
      Object.values(shipmentsData).reduce(
        (acc, curr) => {
          const insuranceCost = curr.insuranceCost ?? 0;
          return {
            totalEstimatedCost:
              acc.totalEstimatedCost +
                curr.estimatedShipmentCost +
                insuranceCost || 0,
            totalShipmentCostWithAdjustment:
              acc.totalShipmentCostWithAdjustment +
              (curr.shipmentCostWithAdjustment ||
                curr.estimatedShipmentCost ||
                0),
            totalBilledAmount:
              acc.totalBilledAmount + curr.totalBilledAmount || 0,
          };
        },
        {
          totalEstimatedCost: 0,
          totalShipmentCostWithAdjustment: 0,
          totalBilledAmount: 0,
        },
      ),
    [shipmentsData],
  );

  const billShipmentsCount = useMemo(
    () => Object.keys(formik.values.billShipments).length,
    [formik.values.billShipments],
  );

  const renderFormTitle = useCallback(() => {
    if (billShipmentsCount === 0) {
      if (orderID) {
        return (
          <span className={classes.title}>Enter bill for Order #{orderID}</span>
        );
      }
      return '';
    } else {
      const shipmentTotals = [
        `${billShipmentsCount > 1 ? 'Total ' : ''}Estimate ${formatCurrency(
          totalEstimatedCost,
        )}`,
        totalShipmentCostWithAdjustment > 0 &&
          `${
            billShipmentsCount > 1 ? 'Total ' : ''
          }Estimate + Audit ${formatCurrency(totalShipmentCostWithAdjustment)}`,
        totalBilledAmount > 0 &&
          `Total Billed ${formatCurrency(totalBilledAmount)}`,
      ];
      if (billShipmentsCount > 1) {
        return (
          <>
            <span className={classes.title}>
              Enter bill for selected shipments
            </span>
            <span className={classes.subTitle}>
              {shipmentTotals.filter((v) => !!v).join(' | ')}
            </span>
          </>
        );
      } else {
        const { trackingNumber, drayageContainerID, ...rest } =
          Object.values(shipmentsData)[0];
        return (
          <>
            <span className={classes.title}>
              Enter bill for {getJobOrderOrDrayageID(rest)}
            </span>
            <span className={classes.subTitle}>
              {[
                !!trackingNumber && !orderID && `Shipment # ${trackingNumber}`,
                !!drayageContainerID && `Container # ${drayageContainerID}`,
                ...shipmentTotals,
              ]
                .filter((v) => !!v)
                .join(' | ')}
            </span>
          </>
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billShipmentsCount, orderID, shipmentsData]);

  const handleChange = (id) => (e) => {
    const { name, value } = e.target;
    const newState = updateNestedState({
      nestedKey: ['billShipments', id, name],
      value,
      state: formik.values,
    });
    setValues({ ...formik.values, ...newState });
  };

  const handleSelectShipment = useCallback(
    async (e) => {
      await fetchShipmentData(e.target.value);
    },
    [fetchShipmentData],
  );

  const onSave = useCallback(
    (shouldSendToQB) => {
      setValues((cur) => ({
        ...cur,
        shouldSendToQB,
      }));
      // Using some instead of forEach to short circuit
      var hasErrors = Object.values(formik.values.billShipments).some((c) => {
        if (!c.amount) {
          setErrorMsg(
            `Shipment amount${
              billShipmentsCount > 1 ? '(s) are' : ' is'
            } required`,
          );
          return true;
        } else if (
          shouldSendToQB &&
          c.needsReconciliation &&
          !c.reconciliationType
        ) {
          setErrorMsg('Reconciliation type is required');
          return true;
        }
        return false;
      });
      if (hasErrors) {
        return;
      } else {
        setErrorMsg(null);
      }
      formik.handleSubmit();
    },
    [billShipmentsCount, formik, setValues],
  );

  return (
    <FormModal
      open={open}
      title={
        <div className={classes.titleContainer}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {renderFormTitle()}
          </div>
          <CloseIcon onClick={handleClose} className={classes.closeIcon} />
        </div>
      }
      hasCustomActionBtn={true}
      maxWidth='md'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      <div className={classes.layout}>
        <div className={classes.warningTextContainer}>
          <ErrorIcon className={classes.errorIcon} />
          <span className={classes.warningText}>
            The change to the customers account will be applied immediately
          </span>
        </div>
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            ex={12}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='externalBillID'
              formikProps={formik}
              label='Bill #'
              required
            />
          </Grid>
          <Grid
            ex={12}
            size={{
              sm: 6,
            }}
          >
            <FormikDateInput
              name='billDate'
              formikProps={formik}
              label='Date'
              style={{ width: '100%' }}
              required
            />
          </Grid>
          {orderID && (
            <Grid
              ex={12}
              size={{
                sm: 6,
              }}
            >
              <SelectInput
                name='shipmentID'
                options={orderShipments}
                onChange={handleSelectShipment}
                label='Select Shipment'
                required
              />
            </Grid>
          )}

          {billShipmentsCount > 0 && (
            <>
              {!isAmountDiff ? (
                <>
                  {billShipmentsCount > 1 ? (
                    <div className={classes.scrollTable}>
                      <Table className={classes.table}>
                        <DataGrid
                          ids={selectedIds}
                          rows={shipmentsData}
                          hideFooter
                          bulkActionButtons={false}
                          rowCellStyle={(record) =>
                            !record.trackingNumber && {
                              paddingTop: 8,
                              paddingBottom: 8,
                            }
                          }
                        >
                          <FunctionField
                            source=''
                            label='Job/Order/Drayage ID'
                            className={cx(classes.tableCell)}
                            render={(record) => (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <span style={{ fontWeight: 'bold' }}>
                                  {getJobOrderOrDrayageID(record)}
                                </span>
                                {record.drayageContainerID && (
                                  <span
                                    className={classes.chargeSubTitle}
                                  >{`Container #${record.drayageContainerID}`}</span>
                                )}
                              </div>
                            )}
                          />
                          <FunctionField
                            source=''
                            label='Shipment #'
                            className={classes.tableCell}
                            render={(record) =>
                              record.trackingNumber || (
                                <TextInput
                                  formikProps={formik}
                                  label='Shipment #'
                                  name='shipmentTrackingNumber'
                                  handleChange={handleChange(record.id)}
                                />
                              )
                            }
                          />
                          <CurrencyField
                            source='estimatedShipmentCost'
                            label='Amount'
                            className={classes.tableCell}
                            sortable={false}
                          />
                        </DataGrid>
                      </Table>
                    </div>
                  ) : (
                    Object.keys(shipmentsData).map((key) => {
                      const { trackingNumber } = shipmentsData[key];
                      if (trackingNumber) {
                        return null;
                      }
                      return (
                        <Grid
                          ex={12}
                          key={key}
                          size={{
                            sm: 6,
                          }}
                        >
                          <TextInput
                            formikProps={formik}
                            label='Shipment #'
                            name='shipmentTrackingNumber'
                            handleChange={handleChange(key)}
                            value={trackingNumber}
                          />
                        </Grid>
                      );
                    })
                  )}
                  <div className={classes.amountDiff}>
                    Billed amount is different than the remaining balance of{' '}
                    {formatCurrency(
                      totalShipmentCostWithAdjustment - totalBilledAmount,
                      2,
                    )}
                    <Button
                      variant='text'
                      color='primary'
                      style={{
                        textTransform: 'none',
                        fontFamily: 'Montserrat',
                        fontSize: 14,
                        fontWeight: '500',
                        letterSpacing: 0,
                        lineHeight: '16px',
                      }}
                      size='small'
                      onClick={() => setIsAmountDiff(true)}
                    >
                      Update
                    </Button>
                  </div>
                </>
              ) : (
                <Grid
                  className={classes.gridItem}
                  size={{
                    sm: 12,
                  }}
                >
                  {Object.keys(formik.values.billShipments).map((key) => {
                    const {
                      adjustmentNote,
                      amount,
                      reconciliationType,
                      shipmentTrackingNumber,
                      needsReconciliation,
                    } = formik.values.billShipments[key];

                    const {
                      drayageContainerID,
                      shipmentCostWithAdjustment,
                      totalBilledAmount,
                      trackingNumber,
                      ...rest
                    } = shipmentsData[key];
                    const showReconciliationFields =
                      needsReconciliation ||
                      totalBilledAmount + parseFloat(amount || 0) >
                        shipmentCostWithAdjustment;

                    const displayShipmentInput = !trackingNumber;
                    return (
                      <Fragment key={key}>
                        {billShipmentsCount > 1 && (
                          <div className={classes.chargeTitleContainer}>
                            <span className={classes.chargeTitle}>
                              {getJobOrderOrDrayageID(rest)}
                            </span>
                            {(trackingNumber || drayageContainerID) && (
                              <span className={classes.chargeSubTitle}>
                                {[
                                  !!trackingNumber &&
                                    `Shipment # ${trackingNumber}`,
                                  !!drayageContainerID &&
                                    `Container # ${drayageContainerID}`,
                                ]
                                  .filter((v) => !!v)
                                  .join(' | ')}
                              </span>
                            )}
                          </div>
                        )}
                        <Grid container spacing={5} rowSpacing={2}>
                          {displayShipmentInput && (
                            <Grid
                              ex={12}
                              size={{
                                sm: 6,
                              }}
                            >
                              <TextInput
                                formikProps={formik}
                                label='Shipment #'
                                name='shipmentTrackingNumber'
                                handleChange={handleChange(key)}
                                value={shipmentTrackingNumber}
                                defaultValue={shipmentTrackingNumber}
                              />
                            </Grid>
                          )}
                          <Grid
                            ex={12}
                            size={{
                              sm: 6,
                            }}
                          >
                            <TextInput
                              format='currency'
                              formikProps={formik}
                              label='Amount'
                              name='amount'
                              handleChange={handleChange(key)}
                              value={amount || ''}
                              defaultValue={amount}
                            />
                          </Grid>
                          {showReconciliationFields && (
                            <>
                              <Grid
                                ex={12}
                                className={classes.gridItem}
                                size={{
                                  sm: 12,
                                }}
                              >
                                <SelectInput
                                  name='reconciliationType'
                                  value={reconciliationType || ''}
                                  defaultValue={''}
                                  formikProps={formik}
                                  ignoreFormikOnchange
                                  options={reconciliationTypesOptions}
                                  onChange={handleChange(key)}
                                  label='Reconciliation'
                                />
                              </Grid>
                              <Grid
                                className={classes.gridItem}
                                size={{
                                  sm: 12,
                                }}
                              >
                                <TextInput
                                  name='adjustmentNote'
                                  value={adjustmentNote}
                                  formikProps={formik}
                                  label='Note (optional)'
                                  handleChange={handleChange(key)}
                                  rows={3}
                                  multiline
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Fragment>
                    );
                  })}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </div>
      <div className={classes.errorMsg}>{errorMsg}</div>
      <DialogActions>
        <Button onClick={() => onSave(true)} color='primary'>
          Save & Add bill to QB
        </Button>
        <Button onClick={() => onSave(false)} color='primary'>
          Save bill on hold
        </Button>
      </DialogActions>
    </FormModal>
  );
}

const getJobOrderOrDrayageID = (shipmentData = {}) => {
  const {
    drayageJobID,
    orderID,
    purchaseLabelsFulfilledServiceID,
    shipmentJobID,
  } = shipmentData;
  if (orderID) return `Order #${orderID}`;
  if (drayageJobID) return `Drayage #${drayageJobID}`;
  if (shipmentJobID) return `Job #${shipmentJobID}`;
  if (purchaseLabelsFulfilledServiceID)
    return `Label Purchase #${purchaseLabelsFulfilledServiceID}`;

  return '';
};

function generatePayload(values) {
  const { billShipments: _billShipments, ...rest } = values;

  const billShipments = Object.keys(_billShipments).reduce((acc, cur) => {
    acc.push(_billShipments[cur]);
    return acc;
  }, []);

  return { ...rest, billShipments };
}

const billShipmentsSchema = object().shape({
  adjustmentNote: string('Must be a string').nullable(),
  amount: string('Must be a string').nullable(),
  reconciliationType: string('Must be a string').nullable(),
  shipmentTrackingNumber: string('Must be a string'),
});
const schema = object().shape({
  externalBillID: string().required('Required'),
  billDate: date().typeError('Invalid Date').nullable().required('Required'),
  billShipments: object().shape({}),
});

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 16px 0',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '24px',
  },
  closeIcon: { cursor: 'pointer', color: '#A5AABD', marginTop: 5 },
  gridItem: {
    paddingTop: '0px !important',
  },
  chargeTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 8,
  },
  chargeTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '16px',
  },
  chargeSubTitle: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    letterSpacing: 0,
    lineHeight: '14px',
  },
  reconciliationTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '18px',
    marginBottom: 8,
  },
  input: {
    marginBottom: 16,
    width: '100%',
  },
  amountDiff: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 20px 20px',
  },
  table: {
    borderCollapse: 'separate',
    // '& th:first-child': { paddingLeft: 0 },
    '& th:first-of-type': { paddingLeft: 0 },
    '& th': { fontSize: 12, lineHeight: '13px', fontFamily: 'Lato' },
    // '& tr > td:first-child': { paddingLeft: 0 },
    '& tr > td:first-of-type': { paddingLeft: 0 },
  },
  tableCell: {
    fontFamily: 'Montserrat',
    lineHeight: '28px',
  },
  scrollTable: {
    maxHeight: 400,
    overflowY: 'auto',
    marginTop: 24,
    padding: '0 20px 20px',
    width: '100%',
  },
  boldText: {
    fontWeight: 'bold',
  },
  errorMsg: {
    display: 'flex',
    paddingRight: 16,
    justifyContent: 'flex-end',
    color: '#FF4F5B',
  },
  warningTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    fontSize: 18,
    color: '#FFBD00',
    background: '#FFFFFF',
    marginRight: 6,
  },
  warningText: {
    color: '#A5AABD',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
  },
}));
