import { useCallback, useState, useEffect, Fragment } from 'react';
import { object, string, date, array, number } from 'yup';
import { Grid2 as Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import {
  TextInput,
  SelectInput,
  AutocompleteInput,
  FormikDateInput,
} from '../inputs';
import {
  useDispatch,
  addResource,
  deleteResource,
  fetchResourceById,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { convertNullFieldsToEmptyString, authGet } from '../../../lib';

const initialState = {
  friendlyName: '',
  customerID: '',
  warehouseID: '',
  carrierCode: '',
  carrierServiceCode: '',
  // quantity: '',
  countryIDs: [],
  stateIDs: [],
};

export function BatchTemplateForm({
  carriers,
  countries,
  customers,
  handleClose,
  id,
  open,
  states,
  warehouses,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);
  const [servicesList, setServicesList] = useState([]);

  const fetchServices = useCallback(
    async (carrierCode) => {
      if (carrierCode) {
        const { data } = await authGet([
          '/carriers/services/basic',
          { carrierCode },
        ]);
        if (data) {
          setServicesList(data);
        }
      }
    },
    [setServicesList],
  );

  useEffect(() => {
    (async function () {
      if (id) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/batches/batch-template',
            id: id,
          }),
        );
        if (data) {
          const {
            filtersResponse: { filters },
            ...rest
          } = data;
          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString({ ...filters, ...rest }),
          }));
          if (filters.carrierCode) {
            await fetchServices(filters.carrierCode);
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = generatePayload({
        values,
        customers,
        warehouses,
        carriers,
        carrierServices: servicesList,
        countries,
        states,
      });
      const { error } = await dispatch(
        addResource({
          baseUrl: `/batches/create-batch-template`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [
      carriers,
      countries,
      customers,
      dispatch,
      handleClose,
      refresh,
      servicesList,
      states,
      warehouses,
    ],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/batches/batch-template',
        id,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
  }, [dispatch, handleClose, id, refresh]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          id ? 'Edit' : 'Add'
        } batch template`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
      isEdit={!!id}
      handleDelete={handleDelete}
    >
      <Grid container spacing={5} rowSpacing={2} className={classes.layout}>
        <RenderBatchTemplateForm
          carriers={carriers}
          countries={countries}
          customers={customers}
          formik={formik}
          fetchServices={fetchServices}
          isTemplate
          servicesList={servicesList}
          states={states}
          warehouses={warehouses}
        />
      </Grid>
    </FormModal>
  );
}

export function RenderBatchTemplateForm({
  countries,
  customers,
  carriers,
  fetchServices,
  formik,
  isTemplate,
  servicesList,
  states,
  warehouses,
}) {
  const { classes } = useStyles();

  const { handleChange, ...formikProps } = formik;

  const onChange = async (e) => {
    const { name, value } = e.target;
    if (name === 'carrierCode') {
      formik.setFieldValue('carrierServiceCode', '');
      !!value && (await fetchServices(value));
    }
    handleChange(e);
  };

  return (
    <Fragment>
      {isTemplate && (
        <Grid
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='friendlyName'
            onChange={onChange}
            formikProps={formikProps}
            className={classes.input}
            label='Batch name'
            required
          />
        </Grid>
      )}
      <Grid
        size={{
          sm: 6,
        }}
      >
        <AutocompleteInput
          formikProps={formikProps}
          textFieldProps={{
            name: 'customerID',
            label: 'Customer',
            onChange,
            autoComplete: 'new-password',
            className: classes.input,
            required: true,
          }}
          autocompleteProps={{
            options: customers,
          }}
        />
      </Grid>
      <Grid
        size={{
          sm: 6,
        }}
      >
        <SelectInput
          name='warehouseID'
          formikProps={formikProps}
          onChange={onChange}
          options={warehouses}
          label='Warehouse'
          className={classes.input}
          required
        />
      </Grid>
      {!isTemplate && (
        <Fragment>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <FormikDateInput
              name='orderCreatedStart'
              formikProps={formik}
              label='Order date start'
              className={classes.input}
              required={!!formik.values.orderCreatedEnd}
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <FormikDateInput
              name='orderCreatedEnd'
              formikProps={formik}
              label='Order date end'
              className={classes.input}
              required={!!formik.values.orderCreatedStart}
            />
          </Grid>
        </Fragment>
      )}
      <Grid
        size={{
          sm: 6,
        }}
      >
        <SelectInput
          name='carrierCode'
          formikProps={formikProps}
          onChange={onChange}
          options={carriers}
          label='Carrier'
          className={classes.input}
          clearable
        />
      </Grid>
      <Grid
        size={{
          sm: 6,
        }}
      >
        <SelectInput
          key={formik.values.carrierCode}
          name='carrierServiceCode'
          formikProps={formikProps}
          onChange={onChange}
          label='Carrier service'
          className={classes.input}
          options={servicesList}
          clearable
        />
      </Grid>
      <Grid
        size={{
          sm: 6,
        }}
      >
        <AutocompleteInput
          formikProps={formik}
          textFieldProps={{
            name: 'countryIDs',
            label: 'Country',
          }}
          autocompleteProps={{
            options: countries,
            multiple: true,
            limitTags: 2,
            size: 'small',
            ChipProps: { style: { maxWidth: 105 } },
          }}
        />
      </Grid>
      <Grid
        style={{ paddingBottom: !isTemplate ? 0 : undefined }}
        size={{
          sm: 6,
        }}
      >
        <AutocompleteInput
          formikProps={formik}
          hasSelectAllOption={true}
          textFieldProps={{
            name: 'stateIDs',
            label: 'State',
          }}
          autocompleteProps={{
            options: states,
            multiple: true,
            limitTags: 2,
            size: 'small',
            ChipProps: { style: { maxWidth: 105 } },
          }}
        />
      </Grid>
    </Fragment>
  );
}

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
    padding: '24px 16px',
  },
  input: {
    // marginBottom: 16,
    width: '100%',
  },
}));

const schema = object().shape(
  {
    friendlyName: string().required('Required'),
    customerID: string().required('Required'),
    warehouseID: string().required('Required'),
    orderCreatedStart: date()
      .typeError('Invalid Date')
      .nullable()
      .when('orderCreatedEnd', {
        is: (orderCreatedEnd) => !!orderCreatedEnd,
        then: date().typeError('Invalid Date').nullable().required('Required'),
        otherwise: date().typeError('Invalid Date').nullable(),
      }),
    orderCreatedEnd: date()
      .typeError('Invalid Date')
      .nullable()
      .when('orderCreatedStart', {
        is: (orderCreatedStart) => !!orderCreatedStart,
        then: date().typeError('Invalid Date').nullable().required('Required'),
        otherwise: date().typeError('Invalid Date').nullable(),
      }),
    stateIDs: array()
      .of(number().integer('Please enter a valid integer'))
      .nullable(),
    countryIDs: array()
      .of(number().integer('Please enter a valid integer'))
      .nullable(),
  },
  ['orderCreatedStart', 'orderCreatedEnd'],
);

export function generatePayload({
  values,
  customers,
  warehouses,
  carriers,
  carrierServices,
  countries,
  states,
}) {
  const { friendlyName, id, ...rest } = values;

  const warehouseDisplay = warehouses.filter(
    (w) => w.id + '' === values.warehouseID + '',
  )[0]?.name;
  const customerDisplay = customers.filter(
    (c) => c.id + '' === values.customerID + '',
  )[0]?.name;
  let carrierDisplay;
  let carrierServiceDisplay;
  let countryDisplay;
  let stateDisplay;

  if (values.carrierCode) {
    carrierDisplay = carriers.filter((c) => c.id === values.carrierCode)[0]
      ?.name;
  }

  if (values.carrierServiceCode) {
    carrierServiceDisplay = carrierServices.filter(
      (c) => c.id === values.carrierServiceCode,
    )[0]?.name;
  }

  if (values.countryIDs) {
    countryDisplay = countries
      .filter((c) => values.countryIDs.includes(c.id))
      .map((c) => c.name)
      .join('  ');
  }

  if (values.stateIDs) {
    stateDisplay = states
      .filter((c) => values.stateIDs.includes(c.id))
      .map((c) => c.name)
      .join('  ');
  }

  const filtersDisplay = {
    warehouseDisplay,
    customerDisplay,
    carrierDisplay,
    carrierServiceDisplay,
    countryDisplay,
    stateDisplay,
  };
  const filters = Object.keys(rest).reduce((acc, cur) => {
    // remove any empty fields
    if (rest[cur]) {
      acc[cur] = rest[cur];
    }
    return acc;
  }, {});

  return { id, friendlyName, filters, filtersDisplay };
}
