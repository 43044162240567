import type { RootState } from '../types';

export const carriersSelectors = {
  basicList: (state: RootState) => state.carriers.basicCarriers || [],
  basicAccountsList: (state: RootState) =>
    state.carriers.basicCarrierAccounts || [],
  basicCarrierCompanies: (state: RootState) =>
    state.carriers.basicCarrierCompanies || [],
  carrierPackageTypes: (state: RootState) =>
    state.carriers.carrierPackageTypes || {},
};
