import axios from 'axios';
import { authGet, arrayToObjByEnumOrId, clientMessageTypes } from '../../lib';
import {
  setSystemSettings,
  setInternationalShippingTypes,
  setMachineName,
  setBatchTemplates,
  showError,
  showNotification,
  addAlert,
  setShipmentLoadGuid,
  setSmallParcelOrderAlertGuid,
  setFreightOrderAlertGuid,
  setCountryUsId,
  setAppointmentUnloadedAlertGuid,
  setAppointmentStatusChangedAlertGuid as setAppointmentStatusChangedAlertGuid,
  setDriverCheckedInAlertGuid,
} from '../features';
import type { AppThunk, NormalizeReturn } from '../types';
import { SignalRGenericMessage } from '../../types/signalRTypes';

const { VITE_GET_MACHINE_URL } = import.meta.env;

function getSystemSettings(): AppThunk {
  return async (dispatch) => {
    const response = await authGet('/system');
    const { data, error } = response;
    if (!error) {
      const statesObj = arrayToObjByEnumOrId(data.states);
      const countriesObj = arrayToObjByEnumOrId(data.countries);
      const countryUsId = data.countries?.find((c) => c.code === 'US')?.id;

      dispatch(
        setSystemSettings({
          ...data,
          statesObj,
          countriesObj,
        }),
      );
      dispatch(setCountryUsId(countryUsId));
    }
  };
}

function getInternationalShippingTypes(): AppThunk {
  return async (dispatch) => {
    const response = await authGet('/system/international-shipping-types');
    const { data, error } = response;
    if (!error) {
      dispatch(setInternationalShippingTypes(data));
    }
  };
}

function getMachineName(): AppThunk<Promise<string>> {
  return async (dispatch) => {
    let res = '';
    await axios
      .get(VITE_GET_MACHINE_URL!)
      .then((response) => {
        const { data } = response;
        if (data) {
          dispatch(setMachineName(data.machineName));
          res = 'success';
        }
      })
      .catch((err) => {
        res = 'error';
      });
    return res;
  };
}

function getBatchTemplates(): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const response = await authGet('/batches/batch-filters/basic');
    const { data, error } = response;
    if (error) {
      dispatch(
        showError({
          message: error.message || error.Message || 'Something went wrong',
        }),
      );
      return { error };
    }
    dispatch(setBatchTemplates(data));
    return { data };
  };
}

function handleGenericMessage(message: SignalRGenericMessage): AppThunk {
  return async (dispatch, getState) => {
    const { Type, Guid, Payload, EntityID } = message || {};
    if (Type) {
      switch (Type) {
        case clientMessageTypes.SHIPMENT_LOAD_CHANGES:
          dispatch(setShipmentLoadGuid(Guid));
          break;
        case clientMessageTypes.SHIPMENT_LOAD_DISPATCHED:
          createNotificationMessage(dispatch, {
            id: Guid,
            title: Payload?.Title,
            message: Payload?.Message,
          });
          break;
        case clientMessageTypes.SP_ORDER_PROCESSED:
          if (getState().ui.orderProcessingScheduleWarehouseId === EntityID) {
            dispatch(setSmallParcelOrderAlertGuid(Guid));
          }
          break;
        case clientMessageTypes.DRIVER_CHECKED_IN:
          dispatch(setDriverCheckedInAlertGuid(Guid));
          break;
        case clientMessageTypes.APPOINTMENT_STATUS_CHANGED:
          dispatch(setAppointmentStatusChangedAlertGuid(Guid));
          break;
        case clientMessageTypes.APPOINTMENT_UNLOADED:
          dispatch(setAppointmentUnloadedAlertGuid(Guid));
          break;
        case clientMessageTypes.FREIGHT_ORDER_PROCESSED:
          if (getState().ui.orderProcessingScheduleWarehouseId === EntityID) {
            dispatch(setFreightOrderAlertGuid(Guid));
          }
          break;

        default:
          break;
      }
    }
  };
}

function createNotificationMessage(dispatch, { id, title, message }) {
  dispatch(
    addAlert({
      id,
      value: {
        title,
        message,
      },
    }),
  );
  dispatch(showNotification({ message: 'You have an alert' }));
}

export {
  getSystemSettings,
  getInternationalShippingTypes,
  getMachineName,
  getBatchTemplates,
  handleGenericMessage,
};
