import { useCallback, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { FileInput, TextInput, CheckboxInput } from '../inputs';
import {
  useDispatch,
  addResource,
  uploadFiles,
  deleteResource,
  fetchResourceById,
} from '../../../state';
import { useRefresh, useNotify, useGetUsersData } from '../../../hooks';
import { convertNullFieldsToEmptyString } from '../../../lib';

const initialState = {
  name: '',
  description: '',
  visibleToCustomer: true,
  // documentFeeType: '',
  // quantity: 1,
  // billableToCustomer: true,
};

export function FulfilledServiceDocumentUpload({
  open,
  handleClose,
  fulfilledServiceId,
  documentId,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();

  const { isCustomer } = useGetUsersData();

  const [submitting, setSubmitting] = useState(false);
  const [files, setFiles] = useState([]);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    (async function () {
      if (documentId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/services/fulfilled',
            id: fulfilledServiceId,
            path: `documents/${documentId}`,
          }),
        );
        if (data) {
          const {
            document,
            id,
            documentFeeType,
            quantity,
            // billableToCustomer,
            ...otherData
          } = data;
          const { name, description, ...rest } = document || {};
          setState((cur) => ({
            ...cur,
            ...rest,
            ...convertNullFieldsToEmptyString({
              name,
              description,
              ...otherData,
            }),
            // quantity: quantity ?? 1,
            // billableToCustomer,
            // documentFeeType: documentFeeType
            //   ? documentFeeType
            //   : isCustomer
            //   ? ''
            //   : 'Other',
          }));
        }
      }
    })();
  }, [fulfilledServiceId, documentId, dispatch]);

  const handleSubmit = useCallback(
    async (values) => {
      if (!documentId && !files.length) {
        return notify('Please upload a file', 'error');
      }
      setSubmitting(true);
      let googleResponse, signedUrlsMapping;

      if (!documentId) {
        const { data, error: uploadError } = await dispatch(uploadFiles(files));
        if (uploadError) {
          return setSubmitting(false);
        }
        googleResponse = data.googleResponse;
        signedUrlsMapping = data.signedUrlsMapping;
      }
      const payload = generatePayload({
        fulfilledServiceId,
        files,
        signedUrlsMapping,
        values,
        hasUpload: !documentId,
      });
      if (documentId || googleResponse) {
        const { error } = await dispatch(
          addResource({
            baseUrl: `/services/fulfilled/${fulfilledServiceId}/documents`,
            payload,
            message: 'Changes saved',
          }),
        );
        setSubmitting(false);
        if (!error) {
          refresh();
          handleClose();
        }
      }
    },
    [
      dispatch,
      documentId,
      files,
      handleClose,
      notify,
      fulfilledServiceId,
      refresh,
    ],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: `/services/fulfilled/${fulfilledServiceId}/documents`,
        id: documentId,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
  }, [dispatch, documentId, handleClose, fulfilledServiceId, refresh]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Upload document</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={!!documentId}
      handleDelete={handleDelete}
    >
      <TextInput
        name='name'
        formikProps={formik}
        label='Name'
        className={classes.input}
        required
      />
      <TextInput
        name='description'
        formikProps={formik}
        label='Description'
        className={classes.input}
      />
      {!isCustomer && (
        <CheckboxInput
          formikProps={formik}
          checkboxes={[
            {
              labelProps: {
                label: 'Visible to customer',
              },
              checkboxProps: {
                name: 'visibleToCustomer',
              },
            },
          ]}
        />
      )}
      {!documentId && (
        <FileInput
          files={files}
          setFiles={setFiles}
          filePrefix={`services/fulfilled-service-`}
          multiple
        />
      )}
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));

function generatePayload({
  hasUpload,
  fulfilledServiceId,
  files,
  signedUrlsMapping,
  values,
}) {
  const {
    documentFeeType,
    shipmentJobID,
    quantity,
    billableToCustomer,
    name,
    description,
    ...rest
  } = values;

  const payload = {
    fulfilledServiceID: fulfilledServiceId,
  };

  // if (documentFeeType && documentFeeType !== 'Other') {
  //   payload.documentFeeType = documentFeeType;
  //   payload.quantity = quantity;
  // }

  // payload.billableToCustomer = billableToCustomer;

  if (hasUpload) {
    payload.documents = files.reduce((acc, cur) => {
      const { key, rawFile: { type } = {} } = cur;
      acc.push({
        description,
        name,
        objectName: key,
        url: signedUrlsMapping[key],
        type,
      });
      return acc;
    }, []);
  } else {
    payload.documents = [{ description, name, ...rest }];
  }

  return payload;
}

const schema = object().shape({
  name: string().required('Required'),
  // documentFeeType: string().required('Required'),
  description: string().nullable(),
  quantity: string().nullable(),
  // quantity: string()
  //   .nullable()
  //   .when('documentFeeType', {
  //     is: (documentFeeType) => documentFeeType !== 'Other',
  //     then: string().nullable().required('Required'),
  //   }),
});
