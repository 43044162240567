import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../types';

export const orderSelectors = {
  isFullyPacked: createSelector(
    (state: RootState) => state.orders.itemsRemaining,
    (remainingItems) => {
      const itemsRemainingKeys = Object.keys(remainingItems);
      return !itemsRemainingKeys.some(
        (sku) => (remainingItems[sku].quantity ?? 0) > 0,
      );
    },
  ),
  selectedOrder: (state: RootState) => state.orders.selectedOrder,
  isFreight: createSelector(
    (state: RootState) => state.orders.selectedOrder,
    (selectedOrder): boolean => (selectedOrder as any)?.isFreight ?? false,
  ),
  packages: (state: RootState) => state.orders.packages || {},
  physicalItems: (state: RootState) => state.orders.physicalItems || {},
  originalItems: (state: RootState) => state.orders.originalItems || {},
  itemsRemaining: (state: RootState) => state.orders.itemsRemaining || {},
  itemsCompleted: (state: RootState) => state.orders.itemsCompleted || {},
  hasUnsealedPackage: (state: RootState) => state.orders.hasUnsealedPackage,
  hasUnsealedPallet: (state: RootState) => state.orders.hasUnsealedPallet,
  hasPallets: (state: RootState) => state.orders.hasPallets,
  completedPackagesCount: (state: RootState) =>
    state.orders.completedPackagesCount,
  completedItemsQty: (state: RootState) => state.orders.completedItemsQty,
  rateId: (state: RootState) => state.orders.rateId,
  canOnlyShip: (state: RootState) => state.orders.canOnlyShip,
  isReship: (state: RootState) => state.orders.isReship,
};
