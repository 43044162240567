import { Fragment } from 'react';
import {
  paths,
  pathParams,
  nestedPages,
  reportTypes,
  generateValidatedParamsUrl,
} from '../../../lib';
import {
  DashboardIcon,
  ListAltIcon,
  LocalShippingIcon,
  MaterialsIcon,
  SettingsIcon,
  AssessmentIcon,
  SummarizeIcon,
  PieChartIcon,
} from '../../../assets';
import { SidebarItem } from '../SidebarItem';
import { NestedList } from '../NestedList';

export function TruckingJobUserSidebar({ handleClearSelectedOrders }) {
  return (
    <Fragment>
      <SidebarItem
        to={generateValidatedParamsUrl(paths.PROCESS_ORDER)}
        text='Home'
        icon={DashboardIcon}
      />
      <SidebarItem
        to={generateValidatedParamsUrl(paths.ORDER_FULFILLMENT_DASHBOARD, {
          [pathParams.ORDER_FULFILLMENT_DASHBOARD_TAB]:
            nestedPages.FULFILLMENT_DASHBOARD_SP,
        })}
        text='Dashboard'
        icon={AssessmentIcon}
        isActive={() =>
          window.location.pathname.startsWith('/dashboards/order-fulfillment')
        }
      />
      <SidebarItem
        to={generateValidatedParamsUrl(paths.SHARED_REPORTS, {
          [pathParams.REPORT_TYPE]: nestedPages.P_L_REPORTS,
          [pathParams.NESTED_PAGE]: reportTypes.P_L_FEE_CATEGORY,
        })}
        text='Reporting'
        icon={PieChartIcon}
        isActive={() => window.location.pathname.startsWith('/reports')}
      />
      <NestedList text='Orders' icon={ListAltIcon} id='orders' defaultOpen>
        <SidebarItem
          text='Orders'
          to={generateValidatedParamsUrl(paths.ORDERS)}
          isNested
          onClick={handleClearSelectedOrders}
          isActive={() => window.location.pathname.startsWith('/order')}
        />
        <SidebarItem
          text='Batches'
          isNested
          to={generateValidatedParamsUrl(paths.BATCH_LIST)}
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.BATCH_LIST}`)
          }
        />
      </NestedList>
      <NestedList text='Shipping' icon={MaterialsIcon} id='shipping'>
        <SidebarItem
          to={generateValidatedParamsUrl(paths.GET_SP_ESTIMATE, {
            [pathParams.NESTED_PAGE]: nestedPages.SP_ESTIMATE,
          })}
          text='Browse Rates'
          isNested
          isActive={() =>
            window.location.pathname.startsWith('/small-parcel-estimate')
          }
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.PURCHASED_LABEL)}
          text='Labels & Rates'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.PURCHASED_LABEL}`)
          }
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.SHIPPING_HISTORY)}
          text='Shipping history'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.SHIPPING_HISTORY}`)
          }
        />
      </NestedList>
      <NestedList text='Trucking' icon={LocalShippingIcon} id='trucking'>
        <SidebarItem
          to={generateValidatedParamsUrl(paths.DRAYAGE_JOBS, {
            [pathParams.DRAYAGE_JOB_TAB]: nestedPages.DRAYAGE_JOBS_OPENED,
          })}
          text='Drayage'
          isNested
          isActive={() => window.location.pathname.startsWith(`/drayage-jobs`)}
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.TRUCKING_JOBS, {
            [pathParams.TRUCKING_JOB_TAB]: nestedPages.TRUCKING_JOBS_OPENED,
          })}
          text='Quotes & Trucking'
          isNested
          isActive={() => window.location.pathname.startsWith(`/trucking-jobs`)}
        />
      </NestedList>
      {/* {!isProduction  && (
              <NestedList
                text='Appointments'
                icon={CalendarTodayIcon}
                id='appointment'
              >
                <SidebarItem
                  to={generateValidatedParamsUrl(
                    paths.DRIVER_CHECK_IN,
                  )}
                  text='Driver check-in'
                  isNested
                />
                <SidebarItem
                  to={generateValidatedParamsUrl(paths.APPOINTMENTS)}
                  text='Appointments'
                  isNested
                />
              </NestedList>
            )} */}

      <SidebarItem
        to={generateValidatedParamsUrl(paths.FULFILLED_SERVICES)}
        text='Fulfilled services'
        icon={SummarizeIcon}
        isActive={() =>
          window.location.pathname.startsWith(`/${paths.FULFILLED_SERVICES}`)
        }
      />

      <NestedList text='Settings' icon={SettingsIcon} id='settings'>
        <SidebarItem
          to={generateValidatedParamsUrl(paths.CARRIERS_ACCOUNTS)}
          text='Carriers & accounts'
          isNested
          isActive={() =>
            window.location.pathname.startsWith('/carriers-accounts')
          }
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.TERMINALS)}
          text='Terminals'
          isActive={() => window.location.pathname.startsWith('/terminals')}
          isNested
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.CUSTOMERS)}
          text='Customers'
          isActive={() =>
            !window.location.pathname.startsWith('/users') &&
            window.location.pathname.startsWith('/customer')
          }
          isNested
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.SYSTEM_SERVICES)}
          text='Services'
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.SYSTEM_SERVICES}`)
          }
          isNested
        />
      </NestedList>
    </Fragment>
  );
}
