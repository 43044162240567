import { useCallback, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { CheckboxInput, TextInput } from '../inputs';
import {
  useDispatch,
  addResource,
  deleteResource,
  fetchResourceById,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { convertNullFieldsToEmptyString } from '../../../lib';
import { WarehouseDockAPI } from '../../../types';

interface Props {
  open: boolean;
  handleClose: () => any;
  warehouseId: string | number;
  dockId?: number;
}

type State = Omit<WarehouseDockAPI, 'warehouseID'>;

const initialState: State = {
  doorNumber: '',
  canScheduleAppointment: true,
  isInbound: false,
  isOutbound: false,
};

export function WarehouseDocksForm({
  open,
  handleClose,
  warehouseId,
  dockId,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    (async function () {
      if (dockId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/warehouses',
            id: warehouseId,
            path: `docks/${dockId}`,
          }),
        );
        if (data) {
          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString(data),
          }));
        }
      }
    })();
  }, [warehouseId, dispatch, dockId]);

  const handleSubmit = useCallback(
    async (values: State) => {
      setSubmitting(true);
      const payload = {
        ...values,
        id: values.id || null,
      };
      const { error } = await dispatch(
        addResource<WarehouseDockAPI>({
          baseUrl: `/warehouses/${warehouseId}/docks`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [warehouseId, dispatch, handleClose, refresh],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/warehouses',
        id: warehouseId,
        path: `docks/${dockId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, dockId]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          dockId ? 'Edit' : 'Add'
        } warehouse dock`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={!!dockId}
      handleDelete={handleDelete}
    >
      <TextInput
        name='doorNumber'
        formikProps={formik}
        label='Door #'
        className={classes.input}
      />
      <div>
        <CheckboxInput
          formikProps={formik}
          checkboxes={[
            {
              labelProps: {
                label: 'Inbound',
              },
              checkboxProps: {
                name: 'isInbound',
              },
            },
            {
              labelProps: {
                label: 'Outbound',
              },
              checkboxProps: {
                name: 'isOutbound',
              },
            },
            {
              labelProps: {
                label: 'Can use for appointments',
              },
              checkboxProps: {
                name: 'canScheduleAppointment',
              },
            },
          ]}
        />
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: '100%',
  },
}));

const schema = object().shape({
  doorNumber: string().required('Required'),
});
