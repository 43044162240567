import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { SelectInput, CheckboxInput } from '../inputs';
import { systemSelectors } from '../../../state';

const initialState = {
  reshipReturnReason: '',
  overrideShouldChargeToCustomer: false,
};

export function ReshipReasonForm({
  open,
  handleClose,
  reshipReturnReason,
  overrideShouldChargeToCustomer,
  callback,
}) {
  const { classes } = useStyles();
  const [state] = useState({
    ...initialState,
    reshipReturnReason: reshipReturnReason || '',
    overrideShouldChargeToCustomer: overrideShouldChargeToCustomer ?? false,
  });
  const [filteredReasons, setFilteredReasons] = useState([]);

  const reshipReturnReasonTypes = useSelector(
    systemSelectors.reshipReturnReasonTypes,
    shallowEqual,
  );

  useEffect(() => {
    if (reshipReturnReasonTypes?.length) {
      const filteredReshipReturnReasons = reshipReturnReasonTypes.filter(
        (r) => r.id !== 'NotShipped',
      );
      setFilteredReasons(filteredReshipReturnReasons);
    }
  }, [reshipReturnReasonTypes]);

  const handleSubmit = useCallback(
    async (values) => {
      const reshipReturnReason = reshipReturnReasonTypes.filter(
        (s) => s.id === values.reshipReturnReason,
      );
      callback({
        reshipReturnReason: values.reshipReturnReason,
        reshipReturnReasonDisplay: reshipReturnReason?.[0]?.name,
        overrideShouldChargeToCustomer: values.overrideShouldChargeToCustomer,
      });
      handleClose();
    },
    [callback, handleClose, reshipReturnReasonTypes],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Reship reason</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
    >
      <SelectInput
        name='reshipReturnReason'
        formikProps={formik}
        label='Reason'
        className={classes.input}
        options={filteredReasons}
        required
      />
      <CheckboxInput
        formikProps={formik}
        checkboxes={[
          {
            labelProps: {
              label: 'Charge to customer',
            },
            checkboxProps: {
              name: 'overrideShouldChargeToCustomer',
            },
          },
        ]}
      />
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));

const schema = object().shape({
  reshipReturnReason: string().required('Required'),
});
