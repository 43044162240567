import { IconButton, Link, List, ListItem, ListItemIcon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useEffect, useState } from 'react';
import { CloseIcon, DescriptionIcon } from '../../assets';

type FilePreviewType = 'Modal' | 'List';
interface FilePreview {
  file: File;
  fileKey: string;
  handleRemove: (key: string) => void;
  previewType?: FilePreviewType;
}

interface RenderPreview {
  file: File;
  fileKey: string;
  preview: string;
  handleRemove: (key: string) => void;
}

export function FilePreview({
  file,
  fileKey,
  handleRemove,
  previewType = 'Modal',
}: FilePreview) {
  const { classes, cx } = useStyles();
  const [preview, setPreview] = useState('');

  useEffect(() => {
    setPreview(URL.createObjectURL(file));
  }, [file]);

  return (
    <List dense className={cx({ [classes.list]: previewType === 'List' })}>
      {typeof handleRemove === 'function' && previewType === 'Modal' ? (
        <RenderModalView
          file={file}
          fileKey={fileKey}
          preview={preview}
          handleRemove={handleRemove}
        />
      ) : (
        <RenderListView
          file={file}
          fileKey={fileKey}
          preview={preview}
          handleRemove={handleRemove}
        />
      )}
    </List>
  );
}

function RenderModalView({
  file,
  fileKey,
  handleRemove,
  preview,
}: RenderPreview) {
  const { classes } = useStyles();

  const { name } = file;

  return (
    <ListItem disableGutters>
      <ListItemIcon style={{ minWidth: 30 }}>
        <IconButton size='small' onClick={() => handleRemove(fileKey)}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </ListItemIcon>
      <Link href={preview} target='_blank' underline='hover'>
        {name}
      </Link>
    </ListItem>
  );
}

function RenderListView({
  file,
  fileKey,
  handleRemove,
  preview,
}: RenderPreview) {
  const { classes } = useStyles();

  const { name } = file;

  return (
    <ListItem disableGutters className={classes.listItem}>
      <div className={classes.flex}>
        <ListItemIcon style={{ minWidth: 30 }}>
          <IconButton
            size='small'
            LinkComponent={Link}
            href={preview}
            target='_blank'
          >
            <DescriptionIcon className={classes.linkIcon} />
          </IconButton>
        </ListItemIcon>
        <span>{name}</span>
      </div>
      <ListItemIcon style={{ minWidth: 30 }}>
        <IconButton size='small' onClick={() => handleRemove(fileKey)}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </ListItemIcon>
    </ListItem>
  );
}

const useStyles = makeStyles({ name: { FilePreview } })((theme) => ({
  closeIcon: {
    fontSize: 18,
    color: '#D5D8EC',
  },
  linkIcon: {
    fontSize: 18,
    color: '#D5D8EC',
  },
  list: {
    width: 280,
    padding: 0,
  },
  listItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 16,
    paddingTop: 0,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));
