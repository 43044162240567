import { makeStyles } from 'tss-react/mui';
import { formatCurrency } from '../../lib';
import { NorthEastIcon, SouthWestIcon } from '../../assets';
import type { AggregatedServiceFeeData } from '../../types';

interface Props {
  totals: AggregatedServiceFeeData;
}

export function ServiceTotals({ totals }: Props) {
  const { classes, css } = useStyles();

  const {
    totalCost,
    totalCustomerAmount,
    totalMarkupPrice,
    totalProfit,
    totalCreditAmount,
  } = totals;

  return (
    <div className={classes.layout}>
      <div className={classes.barLeft}>
        <div className={css({ display: 'flex', flexDirection: 'column' })}>
          <span className={classes.total}>
            {formatCurrency(totalMarkupPrice)}
          </span>
          <span className={classes.totalText}>Total markup pricing</span>
        </div>
      </div>
      <div className={classes.barRight}>
        <div className={classes.feeTotalContainer}>
          <div
            className={classes.feeTotalIconContainer}
            style={{ backgroundColor: 'rgb(96, 79, 255, 0.08)' }}
          >
            <NorthEastIcon
              style={{ color: '#604FFF', width: 11.25, height: 11.25 }}
            />
          </div>
          <div className={classes.feeTotal}>{formatCurrency(totalCost)}</div>
          <div className={classes.feeTotalTitle}>3pl expenses</div>
          {/* <div className={classes.feeTotalTitle}>3pl profit</div> */}
        </div>
        <div className={classes.feeTotalContainer}>
          <div
            className={classes.feeTotalIconContainer}
            style={{ backgroundColor: 'rgb(96, 79, 255, 0.08)' }}
          >
            <NorthEastIcon
              style={{ color: '#604FFF', width: 11.25, height: 11.25 }}
            />
          </div>
          <div className={classes.feeTotal}>
            {formatCurrency(totalCustomerAmount)}
          </div>
          <div className={classes.feeTotalTitle}>3pl charges</div>
        </div>
        <div className={classes.feeTotalContainer}>
          <div
            className={classes.feeTotalIconContainer}
            style={{ backgroundColor: 'rgb(96, 79, 255, 0.08)' }}
          >
            <NorthEastIcon
              style={{ color: '#604FFF', width: 11.25, height: 11.25 }}
            />
          </div>
          <div className={classes.feeTotal}>
            {formatCurrency(totalCreditAmount)}
          </div>
          <div className={classes.feeTotalTitle}>Credits</div>
        </div>
        <div className={classes.feeTotalContainer}>
          <div
            className={classes.feeTotalIconContainer}
            style={{ backgroundColor: 'rgb(255, 125, 0, 0.08)' }}
          >
            <SouthWestIcon
              style={{ color: '#FF7D00', width: 11.25, height: 11.25 }}
            />
          </div>
          <div className={classes.feeTotal}>{formatCurrency(totalProfit)}</div>
          <div className={classes.feeTotalTitle}>3pl profit</div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({ name: { ServiceTotals } })((theme) => ({
  layout: {
    width: '100%',
    display: 'flex',
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    fontFamily: 'Montserrat',
    alignItems: 'center',
    padding: 24,
    // margin: '24px 0px 40px 0px',
  },
  barLeft: {
    flex: 1,
    borderRight: '1px solid #E5E7F5',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  barRight: {
    flex: 1,
    display: 'flex',
    paddingLeft: 40,
  },
  total: {
    fontSize: 32,
    fontWeight: 500,
    letterSpacing: 0,
  },
  totalText: {
    color: '#A5AABD',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    marginBottom: 8,
  },
  feeTotalContainer: {
    fontFamily: 'Montserrat',
    width: 133,
    marginRight: 40,
  },
  feeTotalIconContainer: {
    width: 32,
    height: 32,
    padding: '10.75px 10px 10.75px 10px',
    borderRadius: 3,
    display: 'flex',
    marginBottom: 18,
  },
  feeTotal: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '24px',
    marginBottom: 8,
  },
  feeTotalTitle: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '18px',
    color: '#A5AABD',
  },
}));
