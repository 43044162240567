import { useCallback, useState, useEffect, Fragment } from 'react';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from '../../../themes';
import { FormModal } from './FormModal';
import { TextInput, CheckboxInput } from '../inputs';
import { useDispatch, addResource, fetchResourceById } from '../../../state';
import { ErrorIcon } from '../../../assets';

const initialState = {
  totalPrice: '',
  billableToCustomer: true,
};

export function CustomerMaterialForm({
  open,
  handleClose,
  customerId,
  type,
  dimension,
  materialId,
  settingId,
  title,
  setViewVersion,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    (async function () {
      if (settingId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/customers',
            id: customerId,
            path: `material/${settingId}`,
          }),
        );
        if (data) {
          const { totalPrice, id, billableToCustomer } = data;
          setState((cur) => ({
            ...cur,
            totalPrice: totalPrice ?? '',
            billableToCustomer,
            id,
          }));
        }
      }
    })();
  }, [settingId, customerId, dispatch]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const { error } = await dispatch(
        addResource({
          baseUrl: `/customers/${customerId}/material`,
          payload: {
            ...values,
            customerId,
            packingMaterialID: materialId,
            typeToUpdate: type,
            dimensionToUpdate: dimension,
          },
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion((cur) => ++cur);
        handleClose();
      }
    },
    [
      customerId,
      dimension,
      dispatch,
      handleClose,
      materialId,
      setViewVersion,
      type,
    ],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <div className={classes.titleContainer}>
          <span className={classes.title}>Edit material</span>
          {!!title && <span className={classes.subTitle}>{title}</span>}
        </div>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      <Fragment>
        {!!type && (
          <div className={classes.warningTextContainer}>
            <ErrorIcon className={classes.errorIcon} />
            <span className={classes.warningText}>
              This will override all items in this category
            </span>
          </div>
        )}
        <TextInput
          name='totalPrice'
          formikProps={formik}
          label='Customer price'
          className={classes.input}
          format='currency'
          required={formik.values.billableToCustomer}
        />
        <CheckboxInput
          formikProps={formik}
          checkboxes={[
            {
              labelProps: {
                label: 'Billable to customer',
              },
              checkboxProps: {
                name: 'billableToCustomer',
              },
            },
          ]}
        />
      </Fragment>
    </FormModal>
  );
}

const schema = object().shape({
  totalPrice: string()
    .nullable()
    .when('billableToCustomer', {
      is: (billableToCustomer) => billableToCustomer,
      then: string().required('Required'),
    }),
});

const useStyles = makeStyles()((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
  warningTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    fontSize: 18,
    color: '#FFBD00',
    background: '#FFFFFF',
    marginRight: 6,
  },
  warningText: {
    color: '#A5AABD',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
  },
}));
