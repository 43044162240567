import {
  useCallback,
  useState,
  useEffect,
  useRef,
  // Fragment,
} from 'react';
import _cloneDeep from 'lodash.clonedeep';
import {
  // Table,
  // TableBody,
  // TableRow,
  // TableCell,
  // TableHead,
  Grid2 as Grid,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { SelectInput, TextInput } from '../inputs';
import {
  useDispatch,
  addResource,
  getFreightCustomRateCarrierList,
  fetchResourceById,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import {
  makeuuid,
  authGet,
  arrayToObjByEnumOrId,
  formatCurrency,
} from '../../../lib';
import { InfoMessage } from '../InfoMessage';

const initialState = {
  internalCarrierID: '',
  carrierCode: '',
  serviceTypeCode: '',
  serviceTypeDescription: '',
  deliveryDays: 1,
  totalCost: '',
  customerRateOverride: '',
  carrierQuoteNumber: '',
};

export function CustomRateForm({ open, handleClose, jobDetails }) {
  const { classes, css } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  var jobId = jobDetails.id;
  var customerId = jobDetails.customerID;

  const [submitting, setSubmitting] = useState(false);
  const [carriersList, setCarriersList] = useState([]);
  const [carriersObj, setCarriersObj] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [negotiatedData, setNegotiatedData] = useState({});

  const carrierCodeMapping = useRef({});
  const carrierServiceCodeMapping = useRef({});
  const cachedConfigs = useRef({});

  useEffect(() => {
    (async function () {
      const { data } = await dispatch(getFreightCustomRateCarrierList(jobId));
      if (data) {
        const mapped = data.reduce((acc, cur) => {
          acc[cur.id + ''] = cur.code;
          return acc;
        }, {});
        setCarriersList(data);
        setCarriersObj(arrayToObjByEnumOrId(data));
        carrierCodeMapping.current = mapped;
      }
    })();
  }, [dispatch, jobId]);

  const handleSubmit = useCallback(
    async (values) => {
      const payload = generatePayload(values, jobDetails, carriersObj);

      setSubmitting(true);
      const { error } = await dispatch(
        addResource({
          baseUrl: '/shippingJobs',
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [jobDetails, carriersObj, dispatch, refresh, handleClose],
  );

  const fetchConfigs = useCallback(
    async (carrierId) => {
      if (carrierId) {
        const foundConfig = cachedConfigs.current[carrierId + ''];

        if (foundConfig) {
          setNegotiatedData(foundConfig);
        } else {
          const { data } = await authGet([
            '/shippingJobs/quote/carrier-negotiated-rates',
            { carrierId, customerId },
          ]);
          if (data) {
            cachedConfigs.current[carrierId + ''] = data;
            setNegotiatedData(data);
          }
        }
      }
    },
    [customerId],
  );

  const fetchServices = useCallback(
    async (carrierId) => {
      if (carrierId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/carriers',
            id: carrierId,
            path: 'services/basic',
            query: { carrierType: 'Freight' },
          }),
        );
        if (data) {
          setServicesList(data);
          const mapped = data.reduce((acc, cur) => {
            acc[cur.id] = cur.name;
            return acc;
          }, {});
          carrierServiceCodeMapping.current = {
            ...carrierServiceCodeMapping.current,
            ...mapped,
          };
        }
      }
    },
    [dispatch],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const { setFieldValue, handleChange, ...formikProps } = formik;

  const customOnchange = useCallback(
    async (e) => {
      const { name, value } = e.target;
      if (name === 'internalCarrierID') {
        setFieldValue('serviceTypeCode', '');
        setFieldValue('serviceTypeDescription', '');
        setFieldValue('internalCarrierID', value);
        setFieldValue('carrierCode', carrierCodeMapping.current[value + '']);
        await fetchServices(value);
        await fetchConfigs(value);
      } else if (name === 'serviceTypeCode') {
        setFieldValue('serviceTypeCode', value);
        setFieldValue(
          'serviceTypeDescription',
          carrierServiceCodeMapping.current[value],
        );
      }
    },
    [fetchConfigs, fetchServices, setFieldValue],
  );

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Add custom rate</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      <div className={classes.layout}>
        <Grid container spacing={5} rowSpacing={2}>
          <Grid size={{ sm: 6 }}>
            <SelectInput
              name='internalCarrierID'
              formikProps={formikProps}
              onChange={customOnchange}
              label='Carrier'
              className={classes.input}
              options={carriersList}
              required
            />
          </Grid>
          <Grid size={{ sm: 6 }}>
            <SelectInput
              name='serviceTypeCode'
              formikProps={formikProps}
              onChange={customOnchange}
              label='Service'
              className={classes.input}
              options={servicesList}
              required
            />
          </Grid>
          <Grid size={{ sm: 6 }}>
            <TextInput
              name='deliveryDays'
              formikProps={formik}
              label='Delivery days'
              className={classes.input}
              type='number'
              required
            />
          </Grid>
          <Grid size={{ sm: 6 }}>
            <TextInput
              name='totalCost'
              formikProps={formik}
              label='Rate'
              className={classes.input}
              format='currency'
              required
            />
          </Grid>
          <Grid size={{ sm: 6 }}>
            <TextInput
              name='carrierQuoteNumber'
              formikProps={formik}
              label='Carrier quote number'
              className={classes.input}
            />
          </Grid>
          <Grid
            size={{
              sm: 12,
            }}
          >
            <InfoMessage
              message="By overriding the customer's rate the configured settings for this customers markup will not be applied"
              className={css({ alignItems: 'flex-start' })}
            />
          </Grid>
          <Grid size={{ sm: 6 }} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <TextInput
              name='customerRateOverride'
              formikProps={formik}
              label='Customer rate override'
              className={classes.input}
              format='currency'
            />
          </Grid>
          {!!negotiatedData?.customerMarkup && (
            <Grid size={{ sm: 6 }}>
              <p className={classes.configTitle}>Customer percentage markup</p>
              <p>
                <span>{`${negotiatedData.customerMarkup}%`}</span>
              </p>
              {!!negotiatedData.min && (
                <p>
                  <span>{`Min - ${formatCurrency(negotiatedData.min)}`}</span>
                </p>
              )}
              {!!negotiatedData.max && (
                <p>
                  <span>{`Max - ${formatCurrency(negotiatedData.max)}`}</span>
                </p>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </FormModal>
  );
}

const schema = object().shape({
  internalCarrierID: string().nullable().required('Required'),
  serviceTypeCode: string().nullable().required('Required'),
  deliveryDays: string().nullable().required('Required'),
  totalCost: string().nullable().required('Required'),
});

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
    padding: '24px 16px',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 50,
    marginBottom: 16,
  },
  input: {
    width: '100%',
  },
  configTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  cellStyle: {
    border: 'none',
  },
  nestedTable: {
    marginLeft: 8,
  },
}));

function generatePayload(state, jobDetails, carriersObj) {
  const response = _cloneDeep(jobDetails);
  const foundCarrier = carriersObj[state.internalCarrierID];

  response.quotedRates = response.quotedRates || [];

  response.quotedRates.push({
    ...state,
    isCustomRate: true,
    rateID: makeuuid(8),
    carrierCode: foundCarrier?.code,
    carrierName: foundCarrier?.name,
    carrierNickName: foundCarrier?.name,
  });

  return response;
}
