import React, {
  useCallback,
  useState,
  useEffect,
  // Fragment,
} from 'react';
import { useDispatch } from 'react-redux';
import { Grid2 as Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import { addResource, deleteResource, fetchResourceById } from '../../../state';
import { useRefresh } from '../../../hooks';
import { convertNullFieldsToEmptyString } from '../../../lib';
import { ContactAddressFields } from './ContactFields';

const initialState = {
  name: '',
  address1: '',
  city: '',
  stateID: '',
  zip: '',
  countryID: '',
};

export function TerminalForm({ open, handleClose, terminalId }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [state, setState] = useState({});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    (async function () {
      if (terminalId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/system/terminals',
            id: terminalId,
            query: { carrierType: 'Freight' },
          }),
        );
        if (data) {
          const { address, ...rest } = data;
          setState({ ...rest, ...convertNullFieldsToEmptyString(address) });
        }
      }
    })();
  }, [dispatch, terminalId]);

  const handleSubmit = useCallback(
    async (values) => {
      const payload = generatePayload(values);

      setSubmitting(true);
      const { error } = await dispatch(
        addResource({
          baseUrl: '/system/terminals',
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [dispatch, refresh, handleClose],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/system/terminals',
        id: terminalId,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
  }, [dispatch, handleClose, refresh, terminalId]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Add terminal</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
      isEdit={!!terminalId}
      handleDelete={handleDelete}
    >
      <div className={classes.layout}>
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='name'
              formikProps={formik}
              label='Name'
              className={classes.input}
              required
            />
          </Grid>
          <ContactAddressFields
            formikProps={formik}
            idUpperCase
            inputClassName={classes.input}
            addressFieldName='address1'
          />
        </Grid>
      </div>
    </FormModal>
  );
}

const schema = object().shape({
  name: string().nullable().required('Required'),
  address1: string().nullable().required('Required'),
  city: string().nullable().required('Required'),
  stateID: string().nullable().required('Required'),
  zip: string().nullable().required('Required'),
  countryID: string().nullable().required('Required'),
});

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
    padding: '24px 16px',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 50,
    marginBottom: 16,
  },
  input: {
    width: '100%',
  },
  configTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  cellStyle: {
    border: 'none',
  },
  nestedTable: {
    marginLeft: 8,
  },
}));

function generatePayload(state) {
  const {
    address1,
    address2,
    addressValidationStatus,
    city,
    stateID,
    zip,
    countryID,
    countryName,
    shippingCategory,
    stateAlternate,
    stateCode,
    ...rest
  } = state;

  return {
    ...rest,
    address: {
      address1,
      address2,
      city,
      stateID,
      zip,
      countryID,
    },
  };
}
