import {
  Children,
  isValidElement,
  memo,
  useState,
  useCallback,
  useMemo,
  ReactNode,
} from 'react';
import _get from 'lodash.get';
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  TableCellBaseProps,
} from '@mui/material';
import { useStyles } from './list.styles';

import { BulkActionProps, InputChangeEventType } from '../../types';

interface Props {
  headerProps?: TableCellBaseProps;
  bulkActionOptions?: BulkActionProps;
  numSelected?: number;
  order?: 'asc' | 'desc';
  orderBy: string;
  onSelectAllClick?: (e: InputChangeEventType, checked: boolean) => any;
  onRequestSort?: (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    property: string,
  ) => any;
  rowCount?: number;
  fields?: ReactNode;
  hasBulkActions?: boolean;
  hasExpand?: boolean;
}

const ListHeadComponent = ({
  headerProps: _headerProps,
  bulkActionOptions,
  numSelected = 0,
  order,
  orderBy,
  onSelectAllClick,
  onRequestSort,
  rowCount = 0,
  fields,
  hasBulkActions,
  hasExpand,
}: Props) => {
  const { classes, cx } = useStyles();
  const [headerProps] = useState(_headerProps || {});

  const createSortHandler = useCallback(
    (property: string) =>
      (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        onRequestSort?.(event, property);
      },
    [onRequestSort],
  );

  const checkboxClasses = useMemo(
    () => ({
      root: classes.checkbox,
      checked: classes.checkboxChecked,
    }),
    [classes.checkbox, classes.checkboxChecked],
  );

  return (
    <TableHead>
      <TableRow>
        {hasBulkActions && (
          <TableCell
            padding='checkbox'
            className={cx(classes.tableHead, {
              [classes.stickyRightColumn]: bulkActionOptions?.isSticky,
            })}
          >
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              classes={checkboxClasses}
              size='small'
            />
          </TableCell>
        )}
        {Children.map(fields, (field, index) => {
          if (isValidElement(field)) {
            const fieldProp = field.props as any;
            return (
              <TableCell
                key={_get(field, 'props.source', index)}
                align={_get(field, 'props.align', 'left')}
                padding={fieldProp.disablePadding ? 'none' : 'normal'}
                sortDirection={
                  orderBy === fieldProp.sortBy || orderBy === fieldProp.source
                    ? order
                    : false
                }
                className={cx(
                  classes.tableHead,
                  {
                    [classes.stickyRightColumn]:
                      fieldProp.stickyPosition === 'right',
                    [classes.stickyLeftColumn]:
                      fieldProp.stickyPosition === 'left',
                  },
                  fieldProp.headerClassName,
                )}
                {...headerProps}
              >
                {fieldProp.sortable !== false &&
                (fieldProp.sortBy || fieldProp.source) ? (
                  <TableSortLabel
                    active={
                      orderBy === fieldProp.sortBy ||
                      orderBy === fieldProp.source
                    }
                    direction={
                      orderBy === fieldProp.sortBy ||
                      orderBy === fieldProp.source
                        ? order
                        : 'asc'
                    }
                    onClick={createSortHandler(
                      fieldProp.sortBy || fieldProp.source,
                    )}
                  >
                    {fieldProp.label}
                    {orderBy === fieldProp.sortBy ||
                    orderBy === fieldProp.source ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <span>{fieldProp.label}</span>
                )}
              </TableCell>
            );
          } else return null;
        })}
        {hasExpand && <TableCell />}
      </TableRow>
    </TableHead>
  );
};

export const ListHead = memo(ListHeadComponent) as (
  props: Props,
) => React.ReactElement<any>;
