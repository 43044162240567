import { Fragment, useCallback, useEffect, useState } from 'react';
import { Grid2 as Grid, Tooltip } from '@mui/material';
import { makeStyles, TextButton } from '../../../themes';
import { FormikProps, useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { SelectInput, TextInput } from '../inputs';
import {
  useDispatch,
  getInventoryItemDetails,
  editInventoryItem,
} from '../../../state';
import { useIsMobile, useRefresh } from '../../../hooks';
import { MobileModalForm } from '../../../themes/mobile/MobileModalForm';
import { InventoryPhysicalItems } from '../../../pages/inventory/InventoryPhysicalItems';
import { InfoIcon } from '../../../assets';
import {
  AddPhysicalItemsToWarehouseLocationRequest,
  EditInventoryItemRequest,
  WarehouseLocationInventoryItemAPI,
} from '../../../types';
import { InfoMessage } from '../InfoMessage';

interface EditInventoryItemProps {
  open: boolean;
  handleClose: () => any;
  warehouseLocationId: number;
  warehouseLocationName?: string;
  warehouseId: number;
  customerItemId: number;
  sku: string;
  moveableUnitId?: string | number | null;
  receiptId?: number;
  receiptIds?: Array<number>;
}

interface RenderProps {
  receiptId?: number;
  receiptIds?: Array<number>;
  item: WarehouseLocationInventoryItemAPI | null;
  formik: FormikProps<State>;
  handleShowPhysicalItems: () => void;
}

interface State {
  quantity: number | '';
  reason: string;
  receiptId: number | '';
}

type View = 'details' | 'physicalItems';

const initialState: State = {
  quantity: '',
  reason: '',
  receiptId: '',
};

export function EditInventoryItemForm({
  open,
  handleClose,
  receiptId,
  receiptIds,
  warehouseId,
  warehouseLocationName,
  warehouseLocationId,
  customerItemId,
  sku,
  moveableUnitId,
}: EditInventoryItemProps) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const isMobile = useIsMobile();

  const [view, setView] = useState<View>('details');
  const [item, setItem] = useState<WarehouseLocationInventoryItemAPI | null>(
    null,
  );
  const [submitting, setSubmitting] = useState(false);
  const [muPhysicalItemsToEdit, setMuPhysicalItemsToEdit] =
    useState<AddPhysicalItemsToWarehouseLocationRequest | null>(null);

  useEffect(() => {
    (async function () {
      const { data } = await dispatch(
        getInventoryItemDetails(
          warehouseLocationId ?? warehouseLocationName,
          customerItemId,
          {
            moveableUnitID: moveableUnitId ? moveableUnitId : undefined,
            receiptID: receiptId,
            warehouseID: warehouseId,
          },
        ),
      );
      if (data) {
        setItem(data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    async (values: State) => {
      const { quantity, reason, receiptId: transferReceiptId } = values;
      setSubmitting(true);
      const payload: EditInventoryItemRequest = {
        requestType: 'Override',
        receiptID: transferReceiptId || undefined,
        warehouseLocationID: warehouseLocationId,
        warehouseLocationName,
        warehouseID: warehouseId,
        customerItemID: customerItemId,
        sku,
        moveableUnitID: moveableUnitId ? moveableUnitId : undefined,
        quantity: quantity || 0,
        reason,
        physicalItemsToUpdate: muPhysicalItemsToEdit,
      };

      const { error } = await dispatch(editInventoryItem(payload, true));
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      warehouseId,
      warehouseLocationId,
      warehouseLocationName,
      customerItemId,
      moveableUnitId,
      muPhysicalItemsToEdit,
      sku,
    ],
  );

  const formik = useFormik<State>({
    initialValues: {
      ...initialState,
      receiptId: receiptId || '',
    },
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const handleShowDetails = useCallback(() => {
    setView('details');
  }, []);

  const handleShowPhysicalItems = useCallback(() => {
    setView('physicalItems');
  }, []);

  const handleGetPhysicalItemsToTransfer = useCallback(
    (payload: AddPhysicalItemsToWarehouseLocationRequest) => {
      setMuPhysicalItemsToEdit(payload);
      setView('details');
    },
    [],
  );

  if (isMobile) {
    return (
      <MobileModalForm
        open={open}
        handleClose={handleClose}
        callback={formik.handleSubmit}
        disableSubmit={submitting}
        btnText='Transfer'
        submitting={submitting}
        title='Move item'
      >
        {view === 'physicalItems' &&
        warehouseLocationId &&
        customerItemId &&
        formik.values.receiptId ? (
          <InventoryPhysicalItems
            receiptId={formik.values.receiptId}
            warehouseLocationId={warehouseLocationId}
            moveableUnitId={moveableUnitId}
            customerItemId={customerItemId}
            sku={sku}
            handleBack={handleShowDetails}
            canEdit
            handleSubmitOverride={handleGetPhysicalItemsToTransfer}
          />
        ) : (
          <RenderTransferForm
            formik={formik}
            receiptIds={receiptIds}
            receiptId={receiptId}
            item={item}
            handleShowPhysicalItems={handleShowPhysicalItems}
          />
        )}
      </MobileModalForm>
    );
  }

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Edit inventory stock</span>}
      callback={formik.handleSubmit}
      btnText='EDIT ITEM'
      typeSubmit
      maxWidth='md'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      {view === 'physicalItems' &&
      warehouseLocationId &&
      customerItemId &&
      formik.values.receiptId ? (
        <InventoryPhysicalItems
          receiptId={formik.values.receiptId}
          warehouseLocationId={warehouseLocationId}
          moveableUnitId={moveableUnitId}
          customerItemId={customerItemId}
          sku={sku}
          handleBack={handleShowDetails}
          canEdit
          handleSubmitOverride={handleGetPhysicalItemsToTransfer}
        />
      ) : (
        <Fragment>
          <div className={classes.locationContainer}>
            {warehouseLocationName && <div>{warehouseLocationName}</div>}
            {sku && warehouseLocationName && <span className={classes.dot} />}
            {sku && <div className={classes.sku}>{sku}</div>}
          </div>
          <RenderTransferForm
            formik={formik}
            receiptIds={receiptIds}
            receiptId={receiptId}
            item={item}
            handleShowPhysicalItems={handleShowPhysicalItems}
          />
        </Fragment>
      )}
    </FormModal>
  );
}

function RenderTransferForm({
  formik,
  receiptIds,
  receiptId,
  item,
  handleShowPhysicalItems,
}: RenderProps) {
  const { classes, css } = useStyles();
  const isMobile = useIsMobile();

  const {
    primaryQuantityRemaining: skuQuantity = 0,
    skuPhysicalItemsQuantity = 0,
  } = item || {};

  const maxQtyAllowedToRemove = skuQuantity - skuPhysicalItemsQuantity;

  return (
    <div>
      <InfoMessage
        className={css({ marginBottom: 16 })}
        message='Tread carefully. Changes made to this inventory stock item will override current data and cannot be undone. The storage date will be changed to the date of today’s cycle count.'
      />
      <Grid container spacing={3} rowSpacing={2}>
        <Grid
          size={{
            sm: isMobile ? 12 : 3,
          }}
        >
          <TextInput
            name='quantity'
            formikProps={formik}
            label='New quantity'
            className={classes.input}
            type='number'
            slotProps={{
              htmlInput: {
                max: skuQuantity,
                min: 0,
              },
              input: {
                endAdornment:
                  skuPhysicalItemsQuantity > 0 &&
                  maxQtyAllowedToRemove < skuQuantity ? (
                    <Tooltip
                      title={`The qty can’t be less than ${
                        skuQuantity - maxQtyAllowedToRemove
                      } since there are ${skuQuantity} items for this SKU and ${skuPhysicalItemsQuantity} physical items created. To decrease it, you must first remove the physical items in the inventory tracking screen and then update the qty.`}
                    >
                      <InfoIcon
                        className={css({
                          fontSize: 16,
                          color: '#A5AABD',
                          marginLeft: 8,
                        })}
                      />
                    </Tooltip>
                  ) : undefined,
              },
            }}
          />
        </Grid>
        <Grid
          size={{
            sm: isMobile ? 12 : 9,
          }}
        >
          <TextInput
            name='reason'
            formikProps={formik}
            label='Reason'
            className={classes.input}
          />
        </Grid>
        {!receiptId && !!receiptIds?.length && (
          <Grid
            size={{
              sm: 12,
            }}
          >
            <SelectInput
              name='receiptId'
              formikProps={formik}
              label='Select a receipt'
              options={receiptIds.map((id) => ({
                id,
                name: `Receipt ${id}`,
              }))}
              className={classes.input}
            />
          </Grid>
        )}
      </Grid>
      {skuPhysicalItemsQuantity > 0 && formik.values.quantity !== '' && (
        <div>
          <TextButton onClick={handleShowPhysicalItems}>
            View physical items
          </TextButton>
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  name: { TransferInventoryItemForm: EditInventoryItemForm },
})((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    letterSpacing: 0,
    marginBottom: 16,
  },
  input: {
    width: '100%',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0,
    marginTop: 40,
    marginBottom: 16,
    [theme.breakpoints.down('tablet')]: {
      marginTop: 16,
    },
  },
  sku: {
    color: '#00C4F8',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    height: 8,
    width: 8,
    marginRight: 8,
    borderRadius: 10,
    background: '#000000',
    margin: '0px 8px',
  },
}));

const schema = object().shape({
  quantity: string().required('Required'),
  reason: string().required('Required'),
});
