import React, { cloneElement, isValidElement, Children, memo } from 'react';
import { Paper, Slide } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  aside: {
    maxWidth: '50%',
    width: '50%',
    position: 'relative',
    marginBottom: 16,
  },
}));

export const ListAside = memo(function ListAside({ rows, ids, children }) {
  const { classes, cx } = useStyles();
  return Children.map(children, (aside, index) => {
    return isValidElement(aside) ? (
      <Slide
        direction='left'
        in={aside.props.isOpen}
        mountOnEnter
        unmountOnExit
      >
        <Paper
          elevation={0}
          classes={{
            root: cx(classes.aside, aside.props.drawClasses),
          }}
        >
          {cloneElement(aside, {
            rows,
            ids,
            record: rows[aside.props.recordId],
          })}
        </Paper>
      </Slide>
    ) : null;
  });
});
