import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { object, bool } from 'yup';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { CheckboxInput } from '../inputs';
import { addResource } from '../../../state';
// import { useRefresh, useNotify } from '../../../hooks';

const initialState = {
  shouldSendCustomerEmail: true,
};

export function ReQuoteOrderShippingJobForm({
  open,
  handleClose,
  orderId,
  externalId,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      // const payload = {
      //   ...values,
      //   customerID: orderId,
      // };
      const { error } = await dispatch(
        addResource({
          baseUrl: `/orders/${orderId}/freight/quote`,
          payload: { shouldSendCustomerEmail: values.shouldSendCustomerEmail },
          message: 'Successfully quoted the order',
        }),
      );
      setSubmitting(false);
      if (!error) {
        handleClose();
      }
    },
    [orderId, dispatch, handleClose],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`Re-quote order ${externalId}?`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      <div>
        <CheckboxInput
          formikProps={formik}
          checkboxes={[
            {
              labelProps: {
                label: 'Send email to customer',
              },
              checkboxProps: {
                name: 'shouldSendCustomerEmail',
              },
            },
          ]}
        />
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
}));

const schema = object().shape({
  shouldSendCustomerEmail: bool().typeError('Invalid value').nullable(),
});
