import React, { useMemo } from 'react';
import { Table } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormModal } from '.';
import { getCCDisplay } from './creditCardUtils';
import { CurrencyField, DataGrid, DateField, FunctionField } from '../..';
import { formatCurrency } from '../../../lib';

export function AllPaymentsForm({
  balance,
  handleClose,
  invoiceId,
  open,
  paymentIds,
  paymentRows,
}) {
  const { classes, cx } = useStyles();

  const totalPaid = useMemo(
    () => Object.values(paymentRows).reduce((acc, cur) => acc + cur.amount, 0),
    [paymentRows],
  );

  return (
    <FormModal
      btnText='Close'
      callback={handleClose}
      disableCancel
      open={open}
      title={
        <div className={classes.titleContainer}>
          <span className={classes.title}>Payments</span>
          <span className={classes.subTitle}>Invoice #{invoiceId}</span>
        </div>
      }
    >
      <div className={classes.layout}>
        <Table>
          <DataGrid
            bulkActionButtons={false}
            ids={paymentIds}
            rows={paymentRows}
            hideFooter
          >
            <CurrencyField
              source='amount'
              label='Amount'
              headerClassName={cx(classes.noWrap, classes.paymentHeader)}
              className={classes.bold}
              sortable={false}
              cellStyle={{ paddingLeft: 0 }}
            />
            <FunctionField
              source=''
              label='Initial Status'
              sortable={false}
              render={(record) => {
                const { eCheckStatus, chargeStatus } = record;
                return eCheckStatus || chargeStatus || '--';
              }}
            />
            <DateField
              source='createdOn'
              label='Paid On'
              headerClassName={classes.noWrap}
              sortable={false}
            />
            <FunctionField
              source=''
              label='Payment Method'
              cellStyle={{ paddingLeft: 24 }}
              sortable={false}
              render={(record) => {
                const { lastFour, paymentMethodTypeDisplay } = record;
                return `${paymentMethodTypeDisplay} ${getCCDisplay({
                  lastFour,
                })}`;
              }}
            />
          </DataGrid>
        </Table>
        <div className={classes.invoiceTotals}>
          <span style={{ marginBottom: 4 }}>
            Total paid: {formatCurrency(totalPaid)}
          </span>
          <span>Balance due: {formatCurrency(balance)}</span>
        </div>
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 8px 16px 8px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 8px 0px 8px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
  },
  accountBalance: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '24px',
  },
  selectInput: {
    '& span': {
      color: '#000000 !important',
    },
  },
  input: {
    width: '100%',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 24,
    padding: '0 20px',
  },
  paymentHeader: {
    paddingLeft: 0,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  bold: {
    fontWeight: 'bold',
  },
  invoiceTotals: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32,
    fontFamily: 'Lato',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '17px',
  },
}));
