import React, { useState, Fragment, memo, useMemo } from 'react';
import _get from 'lodash.get';
import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  TypographyProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { limitStringSize } from '../../../lib';
import { AlignType } from '../../../types';

interface Props {
  typographyProps?: TypographyProps;
  record?: Record<string, any>;
  className?: string;
  headerClassName?: string;
  cellStyle?: React.CSSProperties;
  source: string;
  label: string;
  disablePadding?: string;
  align?: AlignType;
  sortBy?: string;
  sortable?: false;
  maxCharacterLength: number;
  modalTitle?: string;
}

interface ModalProps {
  open: boolean;
  note: string;
  handleClose: () => any;
  title?: string;
}

export const NotesField = memo<Props>(
  ({
    className,
    typographyProps: _typographyProps,
    source,
    record: _record,
    maxCharacterLength,
    modalTitle,
  }) => {
    const { classes, cx, css } = useStyles();
    const record = useMemo(() => _record || {}, [_record]);
    const [typographyProps] = useState(_typographyProps || {});
    const value = _get(record, source);
    const [showNotesModal, setShowNotesModal] = useState(false);
    // const [selectedNote, setSelectedNote] = useStateWithCallbackInstant(null, (note) =>
    // setShowNotesModal(!!note),
    // );
    const truncated = limitStringSize(value, maxCharacterLength, true);

    return (
      <Fragment>
        <Typography
          component='span'
          variant='body2'
          className={cx(
            classes.field,
            css({ whiteSpace: 'nowrap' }),
            className,
          )}
          {...typographyProps}
        >
          {value?.length > maxCharacterLength ? (
            <span>
              {truncated}{' '}
              <Typography
                color='primary'
                component='span'
                className={classes.showMore}
                onClick={() => setShowNotesModal(true)}
              >
                More
              </Typography>
            </span>
          ) : (
            (truncated ?? <span>--</span>)
          )}
        </Typography>
        {showNotesModal && (
          <NotesModal
            open={showNotesModal}
            handleClose={() => setShowNotesModal(false)}
            note={value}
            title={modalTitle}
          />
        )}
      </Fragment>
    );
  },
);

export const NotesModal = memo<ModalProps>(
  ({ note, open, handleClose, title }) => {
    const { classes } = useStyles();
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        disableScrollLock
      >
        <DialogContent>
          <h1 className={classes.title}>{title || 'Notes'}</h1>
          <span>{note}</span>
        </DialogContent>
        <div className={classes.btnContainer}>
          <Button
            color='primary'
            variant='contained'
            className={classes.closeBtn}
            onClick={handleClose}
            disableElevation
          >
            Close
          </Button>
        </div>
      </Dialog>
    );
  },
);

const useStyles = makeStyles({ name: { NotesField } })((theme) => ({
  field: {
    ...theme.palette.fontSize14,
  },
  showMore: {
    cursor: 'pointer',
  },
  closeBtn: {
    backgroundColor: '#F5F6FE',
    '&:hover': {
      backgroundColor: '#F5F6FE',
    },
    color: theme.palette.text.primary,
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: 20,
  },
  title: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 500,
    textTransform: 'capitalize',
    marginBottom: 12,
  },
}));
