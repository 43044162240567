import { createTheme, useTheme } from '@mui/material/styles';
// import createCache from '@emotion/cache';
import { createMakeStyles } from 'tss-react';

const defaultTheme = createTheme({ cssVariables: true });

// const muiCache = createCache({
//   key: 'mui',
//   prepend: true,
// });

// const tssCache = createCache({
//   key: 'tss',
// });

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
    tv: true;
  }

  interface Palette {
    textTheme: {
      main: string;
    };
    outlineTheme: {
      main: string;
    };
    fontSize14: {
      main: {
        color: string;
        fontSize: number;
        fontFamily: string;
      };
    };
  }

  interface PaletteOptions {
    textTheme?: {
      main?: string;
    };
    outlineTheme?: {
      main?: string;
    };
    fontSize14?: {
      color?: string;
      fontSize?: number;
      fontFamily?: string;
    };
  }

  interface TypographyVariants {
    background: {
      default: string;
    };
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    background?: {
      default?: string;
    };
  }

  interface Components {
    MuiMonthCalendar: any;
    MuiYearCalendar: any;
  }
}

export const muiTheme = createTheme({
  cssVariables: true,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 375,
      tablet: 700,
      laptop: 1024,
      desktop: 1280,
      tv: 1921,
    },
  },
  palette: {
    text: {
      primary: '#000000',
      secondary: '#B4B8C8',
    },
    textTheme: {
      main: '#A5AABD',
    },
    outlineTheme: {
      main: '#8A91A3',
    },
    fontSize14: {
      color: '#000000',
      fontSize: 14,
      fontFamily: 'Lato',
    },
    primary: { main: '#00C4F8', contrastText: '#FFFFFF' },
    secondary: { main: '#000000' },
    error: { main: '#FF4F5B' },
  },
  typography: {
    fontFamily: [
      'Lato',
      'Montserrat',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    background: {
      default: '#ffffff',
    },
    subtitle1: {
      fontFamily: 'Montserrat',
      fontSize: 32,
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 500,
    },
    body2: {
      fontFamily: 'Lato',
      fontSize: 12,
      color: '#A5AABD',
    },
  },
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#FF4F5B',
        },
      },
    },
    // https://github.com/mui/material-ui/issues/32286#issuecomment-1511193436
    MuiBackdrop: {
      styleOverrides: {
        root: {
          '&[style*="opacity: 0"]': {
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '3px',
          whiteSpace: 'nowrap',
        },
        contained: {
          padding: '10px 17.5px 12px !important',
          fontSize: 14,
          lineHeight: '18px',
          letterSpacing: '1px',
          fontWeight: 'bold',
          fontFamily: 'Lato',
        },
        containedSecondary: {
          color: '#000000',
          backgroundColor: '#F5F6FE',
          ':hover': {
            backgroundColor: '#F5F6FE',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        dense: {
          ...defaultTheme.typography.body2,
          color: 'inherit',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          width: '100%',
        },
        'spacing-xs-3': {
          width: 'calc(100% + 24px)',
          margin: -12,
          '& .MuiGrid-item': {
            padding: 12,
          },
        },
        'spacing-xs-5': {
          width: 'calc(100% + 40px)',
          margin: -20,
          '& .MuiGrid-item': {
            padding: 20,
          },
        },
        'spacing-xs-8': {
          width: 'calc(100% + 64px)',
          margin: -32,
          '& .MuiGrid-item': {
            padding: 32,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '&+.MuiDialogContent-root': {
            paddingTop: 0,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '8px 24px',
        },
      },
    },
    // MuiTab: {
    //   styleOverrides: {
    //     root: {
    //       '@media (min-width: 600px)': {
    //         minWidth: 160,
    //       },
    //     },
    //   },
    // },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: 3,
          fontSize: '1.125rem',
        },
        colorSecondary: { color: '#f50057' },
      },
    },
    MuiMonthCalendar: {
      styleOverrides: {
        root: {
          '& .MuiPickersMonth-monthButton': {
            ...defaultTheme.typography.subtitle1,
          },
        },
      },
    },
    MuiYearCalendar: {
      styleOverrides: {
        root: {
          '& .MuiPickersYear-yearButton': {
            ...defaultTheme.typography.subtitle1,
          },
        },
      },
    },
  },
});

export const {
  makeStyles,
  useStyles, //<- To use when you need css or cx but don't have custom classes
} = createMakeStyles({ useTheme });

// export { muiCache, tssCache };
