import { makeStyles } from 'tss-react/mui';
import { CSSObject } from 'tss-react';

export const styles = makeStyles({
  name: 'itemsTab',
})((theme) => ({
  remainingContainer: {
    minHeight: 170,
    width: '100%',
    maxHeight: '44vh',
    overflowY: 'auto',
    // hide scroll bar https://stackoverflow.com/a/49278385
    scrollbarWidth: 'none' /* Firefox */,
    msOverflowStyle: 'none' /* Internet Explorer 10+ */,
    /* WebKit (Chrome) */
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  remainingBg: {
    backgroundColor: '#F9FAFC',
  },
  visibleSidebarContainer: {
    overflowY: 'scroll',
    height: 145,
    paddingLeft: '24px',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      // height: '0.9em',
    },
    '&::-webkit-scrollbar-track': {
      // background: '#E4EFEF',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#D5D8EC',
      borderRadius: '2px',
    },
  },
  subHeader: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0,
    padding: '24px',
  },
  completedContainer: {
    padding: 24,
  },
  emptyText: {
    color: '#A5AABD',
    fontFamily: 'Montserrat',
    fontSize: 12,
    letterSpacing: 0,
    lineHeight: '15px',
    textAlign: 'center',
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  itemQty: {
    fontSize: 14,
    fontWeight: 900,
    marginRight: 8,
  },
  itemDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemSku: {
    fontSize: 14,
  },
  itemDetailsItem: {
    marginRight: 5,
    ...(theme.typography.body2 as CSSObject),
    fontSize: theme.typography.body2.fontSize,
    letterSpacing: 0,
  },
  itemDivider: {
    color: '#E5E7F5',
    marginTop: 8,
    marginBottom: 8,
  },
  remainingPickNStickIcon: {
    color: '#D5D8EC',
    fontSize: 15,
  },
  completedPickNStickIcon: {
    color: '#FFBD00',
    fontSize: 15,
  },
  skuContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  palletImg: {
    height: 18,
    width: 18,
    marginLeft: 8,
  },
  skuHeader: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: 0,
    marginBottom: 8,
  },
  physicalItemsHead: {
    color: theme.palette.textTheme.main,
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  physicalItem: {
    fontSize: 12,
  },
}));
