import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import { addResource, deleteResource } from '../../../state';
import { useRefresh, useUpdatePageQueryParams } from '../../../hooks';

const initialState = {
  viewName: '',
};

export function AddEditCustomViewForm({
  clearSelectedView,
  editData,
  queryParams,
  handleClose,
  listType,
  open,
  order,
  orderBy,
  setIsNewCustomView,
}) {
  const { classes } = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const { id, viewName } = editData || {};

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);

      const {
        'custom-view-id': _,
        order: _order,
        orderBy: _orderBy,
        ...params
      } = queryParams;

      const payload = {
        id,
        params,
        listType,
        order,
        orderBy,
        viewName: values.viewName,
      };
      const { data, error } = await dispatch(
        addResource({
          baseUrl: `/system/custom-views`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (data) {
        updatePageQueryParams({
          'custom-view-id': data.id,
        });
        setIsNewCustomView(false);
      }
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [
      queryParams,
      id,
      listType,
      order,
      orderBy,
      dispatch,
      updatePageQueryParams,
      setIsNewCustomView,
      refresh,
      handleClose,
    ],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/system/custom-views',
        id,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      clearSelectedView();
      refresh();
      handleClose();
    }
  }, [dispatch, id, clearSelectedView, refresh, handleClose]);

  const formik = useFormik({
    initialValues: { ...initialState, viewName },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          id ? 'Edit' : 'Add'
        } Custom View`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={!!id}
      handleDelete={handleDelete}
    >
      <TextInput
        name='viewName'
        formikProps={formik}
        label='Name'
        className={classes.input}
      />
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: '100%',
  },
}));
