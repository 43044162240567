import { Fragment, useState, useCallback, useRef } from 'react';
import {
  Chip,
  MenuList,
  ListItem,
  ListItemText,
  IconButton,
  ChipProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isValid } from 'date-fns';
import { Dropdown } from '../../common';
import { useUpdatePageQueryParams, useGetQueryParams } from '../../../hooks';
import { CloseIcon } from '../../../assets';
import {
  DateRangeDefaultValues,
  DateRangeQueryParams,
  MouseChangeEventType,
} from '../../../types';
import { DateTimePickerProps, DateTimePicker } from '@mui/x-date-pickers';
import { stableObject } from '../../../lib';

interface Props {
  label?: string;
  queryParams?: DateRangeQueryParams;
  filterSortIds?: Array<number>;
  chipProps?: ChipProps;
  clearable?: boolean;
  className?: string;
  defaultValues?: DateRangeDefaultValues;
  dateTimePickerProps?: Omit<DateTimePickerProps<any>, 'value' | 'onChange'>;
}

export const FiltersDateTimeRange = ({
  label = 'Filters',
  queryParams: _queryParams,
  chipProps: _chipProps,
  defaultValues,
  dateTimePickerProps: _datTimePickerProps,
  clearable = true,
}: Props) => {
  const queryParams = _queryParams || { fromParam: 'from', toParam: 'to' };
  const chipProps = _chipProps || (stableObject as ChipProps);
  const dateTimePickerProps =
    _datTimePickerProps || (stableObject as DateTimePickerProps<any>);
  const { classes, cx } = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { fromParam, toParam } = queryParams;
  const { [fromParam]: fromFilter, [toParam]: toFilter } = useGetQueryParams();

  const [open, setOpen] = useState(false);
  const [fromValue, setFromValue] = useState(
    fromFilter || defaultValues?.from || null,
  );
  const [toValue, setToValue] = useState(toFilter || defaultValues?.to || null);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = useCallback(
    (event: MouseEvent | TouchEvent | MouseChangeEventType) => {
      if (anchorRef.current?.contains(event.currentTarget as Node)) {
        return;
      }
      setOpen(false);
    },
    [],
  );

  const handleClear = useCallback(() => {
    updatePageQueryParams({ [fromParam]: null, [toParam]: null });
    setFromValue(null);
    setToValue(null);
    setOpen(false);
  }, [fromParam, toParam, updatePageQueryParams]);

  const handleChange = useCallback(
    (name: 'from' | 'to') => (dateValue: Date | null) => {
      if (name === 'from') {
        setFromValue(dateValue);
      } else {
        setToValue(dateValue);
      }

      if (isValid(dateValue) || dateValue === null) {
        if (name === 'from') {
          updatePageQueryParams({
            [fromParam]: dateValue?.toISOString() || null,
          });
        } else {
          updatePageQueryParams({
            [toParam]: dateValue?.toISOString() || null,
          });
        }
      }
    },
    [fromParam, toParam, updatePageQueryParams],
  );

  const { className, ...otherDateTimePickerProps } = dateTimePickerProps;

  return (
    <Fragment>
      <Chip
        ref={anchorRef}
        clickable
        color={!!(fromValue || toValue) ? 'primary' : 'default'}
        label={label}
        className={classes.chip}
        onDelete={
          clearable && !!(fromValue || toValue) ? handleClear : undefined
        }
        variant='outlined'
        onClick={handleToggle}
        {...chipProps}
      />
      <Dropdown
        ref={anchorRef}
        open={open}
        handleClose={handleClose}
        popperProps={{
          style: { zIndex: 1 },
          placement: 'bottom-start',
        }}
        paperProps={{ className: classes.paper }}
      >
        <MenuList autoFocusItem={open} className={classes.list}>
          <ListItem
            secondaryAction={
              <IconButton onClick={handleClose} size='large'>
                <CloseIcon />
              </IconButton>
            }
          >
            <ListItemText primary={label} />
          </ListItem>
          <ListItem>
            <DateTimePicker
              format='MM/dd/yyyy hh:mm a'
              value={fromValue}
              onChange={handleChange('from')}
              slotProps={{
                textField: {
                  className: cx(classes.input, className),
                  label: '',
                  size: 'small',
                  style: { marginRight: 8 },
                  variant: 'outlined',
                },
              }}
              {...otherDateTimePickerProps}
            />
            <DateTimePicker
              value={toValue}
              onChange={handleChange('to')}
              slotProps={{
                textField: {
                  className: cx(classes.input, className),
                  label: '',
                  size: 'small',
                  variant: 'outlined',
                },
              }}
              {...otherDateTimePickerProps}
            />
          </ListItem>
        </MenuList>
      </Dropdown>
    </Fragment>
  );
};

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: 600,
  },
  list: {
    width: '100%',
  },
  chip: {
    margin: '0 4px 10px 4px',
  },
  input: {
    width: '100%',
    marginTop: 0,
  },
}));
