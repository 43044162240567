import { useCallback, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { FileInput, TextInput, SelectInput, CheckboxInput } from '../inputs';
import {
  useDispatch,
  addResource,
  uploadFiles,
  deleteResource,
  fetchResourceById,
} from '../../../state';
import { useRefresh, useGetUsersData, useNotify } from '../../../hooks';
import { convertNullFieldsToEmptyString } from '../../../lib';

const initialState = {
  name: '',
  description: '',
  documentFeeType: '',
  quantity: 1,
  visibleToCustomer: true,
  // BillableToCustomer: true,
};

export function DrayageJobDocumentUpload({
  open,
  handleClose,
  drayageJobId,
  documentId,
  customerId,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();

  const { isCustomer, isProcessor } = useGetUsersData();

  const [submitting, setSubmitting] = useState(false);
  const [files, setFiles] = useState([]);
  const [state, setState] = useState(initialState);
  // const [configuredDocumentFees, setConfiguredDocumentFees] = useState([]);

  useEffect(() => {
    (async function () {
      if (documentId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/drayageJobs',
            id: drayageJobId,
            path: `documents/${documentId}`,
          }),
        );
        if (data) {
          const {
            document,
            id,
            documentFeeType,
            quantity,
            // BillableToCustomer,
            ...otherData
          } = data;
          const { name, description, ...rest } = document || {};
          setState((cur) => ({
            ...cur,
            ...rest,
            ...convertNullFieldsToEmptyString({
              name,
              description,
              ...otherData,
            }),
            quantity: quantity ?? 1,
            // BillableToCustomer,
            documentFeeType: documentFeeType
              ? documentFeeType
              : isCustomer
                ? ''
                : 'Other',
          }));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drayageJobId, documentId, dispatch]);

  // useEffect(() => {
  //   (async function () {
  //     if (customerId) {
  //       const { data } = await dispatch(
  //         fetchResourceById({
  //           baseUrl: '/customers',
  //           id: customerId,
  //           path: `configured-document-fees/basic`,
  //         }),
  //       );
  //       let docOptions = [];
  //       if (!isCustomer) {
  //         docOptions.push({ id: 'Other', name: 'Other' });
  //       }
  //       if (data) {
  //         docOptions = [...data, ...docOptions];
  //       }
  //       setConfiguredDocumentFees(docOptions);
  //     }
  //   })();
  // }, [customerId, dispatch, isCustomer]);

  // useEffect(() => {
  //   if (files.length) {
  //     const { rawFile: { name } = {} } = files[0];
  //     if (name) {
  //       formik.setFieldValue('name', name);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [files]);

  const handleSubmit = useCallback(
    async (values) => {
      if (!documentId && !files.length) {
        return notify('Please upload a file', 'error');
      }
      setSubmitting(true);
      let googleResponse, signedUrlsMapping;

      if (!documentId) {
        const { data, error: uploadError } = await dispatch(uploadFiles(files));
        if (uploadError) {
          return setSubmitting(false);
        }
        googleResponse = data.googleResponse;
        signedUrlsMapping = data.signedUrlsMapping;
      }
      const payload = generatePayload({
        drayageJobId,
        files,
        signedUrlsMapping,
        values,
        hasUpload: !documentId,
      });
      if (documentId || googleResponse) {
        const { error } = await dispatch(
          addResource({
            baseUrl: `/drayageJobs/${drayageJobId}/documents`,
            payload,
            message: 'Changes saved',
          }),
        );
        setSubmitting(false);
        if (!error) {
          refresh();
          handleClose();
        }
      }
    },
    [dispatch, documentId, files, handleClose, notify, drayageJobId, refresh],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: `/drayageJobs/${drayageJobId}/documents`,
        id: documentId,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
  }, [dispatch, documentId, handleClose, drayageJobId, refresh]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Upload document</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={!!documentId}
      handleDelete={handleDelete}
    >
      <TextInput
        name='name'
        formikProps={formik}
        label='Name'
        className={classes.input}
        required
        disabled={isProcessor}
      />
      <SelectInput
        name='documentFeeType'
        formikProps={formik}
        label='Document type'
        className={classes.input}
        options={[{ id: 'Other', name: 'Other' }]}
        // options={configuredDocumentFees}
        disabled={isProcessor}
        required
      />
      <TextInput
        name='description'
        formikProps={formik}
        label='Description'
        className={classes.input}
        disabled={isProcessor}
      />
      {formik.values.documentFeeType !== 'Other' && !isCustomer && (
        <TextInput
          name='quantity'
          formikProps={formik}
          label='Pages'
          className={classes.input}
          type='number'
          required
          slotProps={{
            htmlInput: {
              min: 1,
            },
          }}
        />
      )}
      {!isCustomer && (
        <CheckboxInput
          formikProps={formik}
          checkboxes={[
            {
              labelProps: {
                label: 'Visible to customer',
              },
              checkboxProps: {
                name: 'visibleToCustomer',
              },
            },
          ]}
        />
      )}
      {!documentId && (
        <FileInput
          files={files}
          setFiles={setFiles}
          filePrefix={`drayage-jobs/${drayageJobId}-`}
          multiple={false}
        />
      )}
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));

function generatePayload({
  hasUpload,
  drayageJobId,
  files,
  signedUrlsMapping,
  values,
}) {
  const {
    documentFeeType,
    drayageJobID,
    quantity,
    // BillableToCustomer,
    ...rest
  } = values;
  const payload = {
    drayageJobID: drayageJobId,
  };

  if (documentFeeType && documentFeeType !== 'Other') {
    payload.documentFeeType = documentFeeType;
    payload.quantity = quantity;
  }

  // payload.BillableToCustomer = BillableToCustomer;

  const Document = rest;

  if (hasUpload) {
    const { key, rawFile: { type } = {} } = files[0];
    Document.objectName = key;
    Document.url = signedUrlsMapping[key];
    Document.type = type;
  }

  payload.document = Document;

  return payload;
}

const schema = object().shape({
  name: string().required('Required'),
  documentFeeType: string().required('Required'),
  description: string().nullable(),
  quantity: string()
    .nullable()
    .when('documentFeeType', {
      is: (documentFeeType) => documentFeeType !== 'Other',
      then: string().nullable().required('Required'),
    }),
});
