import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { SelectInput } from '../inputs';
import { setMachineName } from '../../../state';
import { authGet } from '../../../lib';

export function SelectMachineForm({ handleClose, open, machineName = '' }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async function () {
      const { data } = await authGet('/devices/configs');
      if (data) {
        const dataOptions = data.map((d) => ({
          id: d.name,
          name: d.name + (d.friendlyName ? ` (${d.friendlyName})` : ''),
        }));
        setOptions(dataOptions);
      }
    })();
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      dispatch(setMachineName(values.machineName));
      handleClose();
    },
    [dispatch, handleClose],
  );

  const formik = useFormik({
    initialValues: { machineName },
    onSubmit: handleSubmit,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      disableCancel
      title={
        <div className={classes.titleContainer}>
          <span className={classes.title}>Select a machine</span>
          <span className={classes.subTitle}>
            A machine name is required for weight and printing labels
          </span>
        </div>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
    >
      <SelectInput
        name='machineName'
        formikProps={formik}
        options={options}
        label='Select machine'
        className={classes.input}
      />
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    color: '#000000',
    fontSize: 12,
    fontFamily: 'Montserrat',
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));
