import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { addQbCustomer } from '../../../state';
import { useRefresh } from '../../../hooks';

const initialState = {};

export function AddCustomerToQbForm({ open, handleClose, customerId }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      // const payload = {
      //   ...values,
      //   customerID: orderId,
      // };
      const { error } = await dispatch(addQbCustomer(customerId));
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [dispatch, customerId, refresh, handleClose],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    // validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Add customer to QuickBooks?</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      {/* <div>
        <CheckboxInput
          formikProps={formik}
          checkboxes={[
            {
              labelProps: {
                label: 'Charge to customer',
              },
              checkboxProps: {
                name: 'shouldBillCustomer',
              },
            },
          ]}
        />
      </div> */}
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
}));

// const schema = object().shape({
//   shouldBillCustomer: bool().typeError('Invalid value').nullable(),
// });
