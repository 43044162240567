import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { Grid2 as Grid, Table, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import Payment from 'payment';
import { addResource } from '../../../state';
import { useNotify, useRefresh } from '../../../hooks';
import { BasicDialog, FormModal } from '.';
import { TextInput, SelectInput, CheckboxInput } from '../inputs';
import {
  bankAccountSchema,
  cardSchema,
  getCCDisplay,
  getQBPaymentToken,
  modes,
  paymentMethodTypes,
} from './creditCardUtils';
import {
  PaymentMethodFields,
  DataGrid,
  TextField,
  CurrencyField,
  FunctionField,
} from '../..';
import { formatCurrency } from '../../../lib';

const initialState = {
  paymentMethodID: '',
  invoicePayments: {},
};

const paymentMethodInitialState = {
  isDefault: false,
  // card
  number: '',
  expiry: '',
  cvc: '',
  zip: '',
  focus: '',
  // bank
  accountNumber: '',
  phone: '',
  accountType: '',
  routingNumber: '',
};

export function InvoicesBatchPay({
  handleClose,
  open,
  selectedInvoices = {},
  customerId,
  paymentMethodRows,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const [balance, setBalance] = useState(0);
  const [invoiceIds, setInvoiceIds] = useState([]);
  const [mode, setMode] = useState(modes.CREDIT_CARD);
  const [submitting, setSubmitting] = useState(false);
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);
  const [state, setState] = useState(initialState);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const paymentMethodsOptions = useMemo(
    () => [
      {
        id: 0,
        name: (
          <span style={{ color: '#00C4F8' }}>
            + Add one time payment method
          </span>
        ),
      },
      ...Object.values(paymentMethodRows || {}).map((r) => {
        const { isDefault, lastFour, cardTypeDisplay } = r;
        const ccDisplay = getCCDisplay({ lastFour, cardTypeDisplay });
        return {
          id: r.id,
          name: `${ccDisplay}${isDefault ? ' (Default)' : ''}`,
        };
      }),
    ],
    [paymentMethodRows],
  );

  useEffect(() => {
    let totalBalance = 0;
    const ids = [];

    var invoiceState = Object.keys(selectedInvoices).reduce((acc, cur) => {
      const invoice = selectedInvoices[cur];
      totalBalance += invoice.remainingBalance;
      ids.push(invoice.id);
      acc[invoice.id] = { amount: '' };
      return acc;
    }, {});

    setState((cur) => ({ ...cur, invoicePayments: invoiceState }));
    setBalance(totalBalance);
    setInvoiceIds(ids);
  }, [selectedInvoices]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      let hasErrors = false;

      const isOneTimePayment = values.paymentMethodID === 0;

      const payload = {
        amount: values.amount,
        cardType:
          isOneTimePayment && mode === modes.CREDIT_CARD
            ? cardTypeMapping[Payment.fns.cardType(values.number)]
            : null,
        customerId,
        type:
          mode === modes.BANK_ACCOUNT
            ? paymentMethodTypes.ACH
            : paymentMethodTypes.CREDIT_CARD,
        invoicesPayments: Object.keys(values.invoicePayments).map((k) => {
          const invoice = values.invoicePayments[k];
          if (!invoice.amount) {
            notify(`No amount entered for invoice #${k}`, 'error');
            hasErrors = true;
          }
          return {
            invoiceId: parseInt(k),
            amount: parseFloat(invoice.amount),
          };
        }),
      };
      if (hasErrors) {
        setSubmitting(false);
        return;
      }
      if (isOneTimePayment) {
        const { qbData, errorMessage } = await getQBPaymentToken(mode, values);
        payload.generatedToken = qbData?.value;

        if (errorMessage) {
          setSubmitting(false);
          return notify(errorMessage, 'error');
        }
      } else {
        payload.paymentMethodID = values.paymentMethodID;
      }

      const { error } = await dispatch(
        addResource({
          baseUrl: `/accounting/payment`,
          payload,
        }),
      );

      setSubmitting(false);
      if (!error) {
        refresh();
        setShowSuccessDialog(true);
      }
    },
    [mode, customerId, dispatch, notify, refresh],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state, ...paymentMethodInitialState },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: showPaymentInfo
      ? schema.concat(
          mode === modes.CREDIT_CARD ? cardSchema : bankAccountSchema,
        )
      : schema,
  });

  const onChangePaymentMethod = useCallback(
    (e) => {
      const paymentMethod = Object.values(paymentMethodRows || {})?.find(
        (p) => p.id === e.target.value,
      );
      setShowPaymentInfo(!paymentMethod);
      setMode(
        paymentMethod?.type === modes.BANK_ACCOUNT
          ? modes.BANK_ACCOUNT
          : modes.CREDIT_CARD,
      );
    },
    [paymentMethodRows],
  );

  function handleChange(e, invoiceId) {
    const { values, setFieldValue } = formik;
    const { value } = e.target;
    const key = invoiceId + '';

    const newState = {
      ...values.invoicePayments,
      [key]: {
        ...values.invoicePayments[key],
        amount: value,
      },
    };

    setFieldValue('invoicePayments', newState);
  }

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <div className={classes.titleContainer}>
          <span className={classes.title}>Batch pay</span>
          <span className={classes.subTitle}>
            {`Total balance for selected invoices: ${formatCurrency(balance)}`}
          </span>
        </div>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='md'
      paperProps={{
        style: { width: showPaymentInfo ? 720 : 486 },
      }}
      submitting={submitting}
      disableScrollLock={false}
    >
      <div className={classes.layout}>
        <Table>
          <DataGrid
            bulkActionButtons={false}
            ids={invoiceIds}
            rows={selectedInvoices}
            hideFooter
          >
            <TextField
              source='id'
              label='Invoice'
              sortable={false}
              cellStyle={{ border: 'none' }}
              typographyProps={{ className: classes.firstTableCell }}
            />
            <CurrencyField
              source='remainingBalance'
              label='Balance'
              sortable={false}
              cellStyle={{ border: 'none' }}
            />
            <FunctionField
              source='remainingBalance'
              label='Amount'
              sortable={false}
              cellStyle={{ border: 'none' }}
              render={(record) => {
                const { id, remainingBalance } = record;
                const curValue = formik.values.invoicePayments[id]?.amount;
                if (curValue === undefined) {
                  return null;
                }
                return (
                  <RenderInput
                    id={id}
                    curValue={curValue}
                    handleChange={handleChange}
                    balance={remainingBalance}
                  />
                );
              }}
            />
          </DataGrid>
        </Table>
        <div className={classes.sectionTitle}>Payment Info</div>
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            size={{
              sm: showPaymentInfo ? 6 : 12,
            }}
          >
            <SelectInput
              name='paymentMethodID'
              formikProps={formik}
              label='Select payment method'
              required
              size='small'
              options={paymentMethodsOptions}
              className={classes.input}
              slotProps={{ htmlInput: { className: classes.selectInput } }}
              onChange={onChangePaymentMethod}
            />
          </Grid>
          {showPaymentInfo && (
            <div style={{ width: '100%' }}>
              <PaymentMethodFields
                formik={formik}
                initialState={paymentMethodInitialState}
                isOneTimePayment={true}
                mode={mode}
                setMode={setMode}
              />
            </div>
          )}
        </Grid>
      </div>
      {showSuccessDialog && (
        <BasicDialog
          open={showSuccessDialog}
          callback={() => {
            setShowSuccessDialog(false);
            handleClose();
          }}
          hideCloseBtn={true}
          title='Payment Successful'
          text={`Your payment was successful and the invoice(s) will update when it is done processing`}
        />
      )}
    </FormModal>
  );
}

function RenderInput({ id, balance, curValue, handleChange }) {
  const [payBalanceInFull, setPayBalanceInFull] = useState(false);

  const handlePayBalanceInFull = useCallback(
    (e) => {
      const checked = e.target.checked;
      setPayBalanceInFull(checked);
      if (checked) {
        const event = { target: { name: '', value: balance } };
        handleChange(event, id);
      }
    },
    [balance, handleChange, id],
  );

  return (
    <div>
      <TextInput
        name=''
        value={curValue}
        label=''
        size='small'
        // className={classes.input}
        // inputProps={{ className: classes.selectInput }}
        onChange={(e) => handleChange(e, id)}
        format='currency'
      />
      <Tooltip title='Pay in full'>
        <span style={{ marginLeft: 3 }}>
          <CheckboxInput
            checkboxes={[
              {
                checkboxProps: {
                  name: 'payFullAccountBalance',
                  checked: payBalanceInFull,
                  onChange: handlePayBalanceInFull,
                  size: 'small',
                },
              },
            ]}
          />
        </span>
      </Tooltip>
    </div>
  );
}

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 8px 16px 8px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 8px 0px 8px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
  },
  accountBalance: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '24px',
  },
  selectInput: {
    '& span': {
      color: '#000000 !important',
    },
  },
  input: {
    width: '100%',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 24,
    // padding: '0 20px',
  },
  firstTableCell: {
    fontWeight: 'bold',
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontStyle: 'italic',
  },
}));

export const schema = object().shape({
  paymentMethodID: string().required('Required'),
});

const cardTypeMapping = {
  visa: 'Visa',
  dinersclub: 'Diners',
  amex: 'AmericanExpress',
  discover: 'Discover',
  mastercard: 'MasterCard',
  jcb: 'Jcb',
};
