import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  field: {
    ...theme.palette.fontSize14,
    whiteSpace: 'nowrap',
  },
  chip: {
    margin: 4,
  },
  checkIcon: {
    color: '#8FD854',
    fontSize: 18,
  },
  clearIcon: {
    color: '#FF4F5B',
    fontSize: 18,
  },
}));
