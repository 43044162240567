import { useCallback, useState, useEffect, useMemo } from 'react';
import { Grid2 as Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { TextInput, AutocompleteInput, CheckboxInput } from '../inputs';
import {
  useDispatch,
  useSelector,
  addResource,
  systemSelectors,
} from '../../../state';
import { useRefresh, useGetUsersData, useNotify } from '../../../hooks';
import {
  convertNullFieldsToEmptyString,
  requiresStateCode,
} from '../../../lib';

const initialState = {
  shipToFirstName: '',
  shipToLastName: '',
  shipToCompanyName: '',
  shipToPhone: '',
  shipToAddress1: '',
  shipToAddress2: '',
  shipToCity: '',
  shipToStateID: '',
  shipToStateAlternate: '',
  shipToZip: '',
  shipToCountryID: '',
  shipWithFailedAddressValidation: false,
};

export function EditOrderShipInfoForm({
  open,
  handleClose,
  curData,
  orderId,
  customSubmit,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();
  const { isAdmin, isCustomer, isCustomerService, isTruckingSalesPerson } =
    useGetUsersData();
  const hasEditPermission =
    isAdmin || isCustomer || isCustomerService || isTruckingSalesPerson;
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);
  const [adminToken, setAdminToken] = useState();
  const [showManagerLogin, setShowManagerLogin] = useState(!hasEditPermission);
  const states = useSelector(systemSelectors.states);
  const countries = useSelector(systemSelectors.countries);
  const countriesObj = useSelector(systemSelectors.countriesObj);

  useEffect(() => {
    const { shipToFirstName, shipToLastName } = getReceiverName({
      shipToFirstName: curData.shipToFirstName,
      shipToLastName: curData.shipToLastName,
    });
    setState((cur) => ({
      ...cur,
      ...convertNullFieldsToEmptyString({
        shipToPhone: curData.shipToPhone,
        shipToAddress1: curData.shipToAddress1,
        shipToAddress2: curData.shipToAddress2,
        shipToCity: curData.shipToCity,
        shipToStateID: curData.shipToStateID,
        shipToStateAlternate: curData.shipToStateAlternate,
        shipToZip: curData.shipToZip,
        shipToCountryID: curData.shipToCountryID,
        shipToCompanyName: curData.shipToCompanyName,
        shipToFirstName,
        shipToLastName,
      }),
      shipWithFailedAddressValidation:
        curData.shipWithFailedAddressValidation ?? false,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const { shipToStateAlternate, shipToStateID, ...rest } = values;
      const isStateCodeRequired = requiresStateCode({
        countryId: values.shipToCountryID,
        stateAlternate: values.shipToStateAlternate,
        countriesObj,
      });
      const payload = { ...rest };
      if (isStateCodeRequired) {
        payload.shipToStateID = shipToStateID;
      } else {
        payload.shipToStateAlternate = shipToStateAlternate;
      }
      payload.id = orderId;
      if (typeof customSubmit === 'function') {
        customSubmit(payload);
        handleClose();
        return;
      }
      const { error } = await dispatch(
        addResource({
          baseUrl: `/orders/${orderId}/ship-info`,
          payload,
          message: 'Changes saved',
          tokenOverride: adminToken,
        }),
      );
      // : await dispatch(
      //     customPost({
      //       url: `orders/${orderId}/ship-info`,
      //       body: {
      //         id: orderId,
      //         ...values,
      //       },
      //       authToken: adminToken,
      //     }),
      //   );

      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      } else {
        return notify(
          error.message || error.title || 'Something went wrong',
          'error',
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [adminToken, countriesObj, customSubmit, orderId],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const onAdminLogin = useCallback((data) => {
    setShowManagerLogin(false);
    // setShowConfirmation(true);
    setAdminToken(data);
  }, []);

  const showAlternateStateInput = useMemo(() => {
    return !requiresStateCode({
      countryId: formik.values.shipToCountryID,
      stateAlternate: formik.values.shipToStateAlternate,
      countriesObj,
    });
  }, [
    countriesObj,
    formik.values.shipToCountryID,
    formik.values.shipToStateAlternate,
  ]);

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Edit shipping info</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: showManagerLogin ? 468 : 720 } }}
      submitting={submitting}
      showManagerLogin={showManagerLogin}
      onAdminLogin={onAdminLogin}
    >
      <div className={classes.layout}>
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToCompanyName'
              formikProps={formik}
              label='Company name'
              className={classes.input}
              autoComplete='new-password'
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToFirstName'
              formikProps={formik}
              label='First name'
              className={classes.input}
              autoComplete='new-password'
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToLastName'
              formikProps={formik}
              label='Last name'
              className={classes.input}
              autoComplete='new-password'
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToPhone'
              formikProps={formik}
              label='Phone'
              className={classes.input}
              autoComplete='new-password'
              format='phone'
              // required
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToAddress1'
              formikProps={formik}
              label='Address line 1'
              className={classes.input}
              autoComplete='new-password'
              required
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToAddress2'
              formikProps={formik}
              label='Address line 2'
              className={classes.input}
              autoComplete='new-password'
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToCity'
              formikProps={formik}
              label='City'
              className={classes.input}
              autoComplete='new-password'
              required
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            {showAlternateStateInput ? (
              <TextInput
                name='shipToStateAlternate'
                formikProps={formik}
                label='Enter state'
                className={classes.input}
                autoComplete='new-password'
              />
            ) : (
              <AutocompleteInput
                formikProps={formik}
                textFieldProps={{
                  name: 'shipToStateID',
                  label: 'State',
                  autoComplete: 'new-password',
                }}
                autocompleteProps={{
                  options: states,
                }}
              />
            )}
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <TextInput
              name='shipToZip'
              formikProps={formik}
              label='Zip'
              className={classes.input}
              autoComplete='new-password'
              required
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
            }}
          >
            <AutocompleteInput
              formikProps={formik}
              textFieldProps={{
                name: 'shipToCountryID',
                label: 'Country',
                autoComplete: 'new-password',
                required: true,
              }}
              autocompleteProps={{
                options: countries,
              }}
            />
          </Grid>
          <Grid ex={12}>
            <CheckboxInput
              formikProps={formik}
              checkboxes={[
                {
                  labelProps: {
                    label:
                      'I approve this invalid address to be saved and used for the order shipment.',
                  },
                  checkboxProps: {
                    name: 'shipWithFailedAddressValidation',
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
    padding: '24px 16px',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 50,
    marginBottom: 16,
  },
  input: {
    width: '100%',
  },
  item: {
    paddingBottom: 0,
  },
}));

function getReceiverName({ shipToFirstName, shipToLastName }) {
  if ((!shipToFirstName && !shipToLastName) || shipToFirstName)
    return { shipToFirstName, shipToLastName };
  const receiver = shipToLastName?.split(' ');
  const receiverFirstName = receiver?.[0] || '';
  const receiverLastName = receiver?.[receiver?.length - 1] || '';
  return {
    shipToFirstName: receiverFirstName,
    shipToLastName: receiverLastName,
  };
}

const schema = object().shape({
  // shipToPhone: string().required('Required'),
  shipToAddress1: string().required('Required'),
  shipToCity: string().required('Required'),
  shipToZip: string().required('Required'),
  shipToCountryID: string().nullable().required('Required'),
});
