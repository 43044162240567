import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import { useDispatch, addResource } from '../../../state';
import { useRefresh } from '../../../hooks';
import { WarehouseAisleAPI } from '../../../types';

type LocationType = 'Aisle' | 'Position' | 'Location';

interface Props {
  open: boolean;
  handleClose: () => any;
  type: LocationType;
  warehouseId: string | number;
  aisleId: number;
  locationToDuplicateId?: string | number;
  positionToDuplicate?: string | number;
  title: string;
  subTitle: string;
  infoTitle: string;
  inputLabel: string;
  setViewVersion?: React.Dispatch<React.SetStateAction<number>>;
}

const initialState = {
  quantity: '',
  name: '',
};

export function DuplicateWarehouseLocationForm({
  open,
  handleClose,
  warehouseId,
  aisleId,
  title,
  subTitle,
  infoTitle,
  inputLabel,
  type,
  locationToDuplicateId,
  positionToDuplicate,
  setViewVersion,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);

  const submitDuplicateAisle = useCallback(
    async (values: any) => {
      setSubmitting(true);
      const payload = {
        name: values.name,
        warehouseAisleID: aisleId,
        warehouseID: warehouseId,
      };
      const { error } = await dispatch(
        addResource<WarehouseAisleAPI>({
          baseUrl: `/warehouses/${warehouseId}/aisles/duplicate`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [warehouseId],
  );

  const submitDuplicatePosition = useCallback(
    async (values: any) => {
      setSubmitting(true);
      const payload = {
        quantity: values.quantity,
        warehouseAisleID: aisleId,
        warehouseID: warehouseId,
        positionToDuplicate,
      };
      const { error } = await dispatch(
        addResource({
          baseUrl: `/warehouses/${warehouseId}/aisles/${aisleId}/position/duplicate`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aisleId, positionToDuplicate, warehouseId],
  );

  const submitDuplicateLocation = useCallback(
    async (values: any) => {
      setSubmitting(true);
      const payload = {
        quantity: values.quantity,
        warehouseLocationID: locationToDuplicateId,
        warehouseID: warehouseId,
      };
      const { error } = await dispatch(
        addResource<WarehouseAisleAPI>({
          baseUrl: `/warehouses/${warehouseId}/aisles/${aisleId}/location/duplicate`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationToDuplicateId, type, warehouseId],
  );

  const handleSubmit = useCallback(
    async (values) => {
      if (type === 'Aisle') {
        await submitDuplicateAisle(values);
      } else if (type === 'Location') {
        await submitDuplicateLocation(values);
      } else {
        await submitDuplicatePosition(values);
      }
    },
    [
      type,
      submitDuplicateAisle,
      submitDuplicateLocation,
      submitDuplicatePosition,
    ],
  );

  const formik = useFormik({
    initialValues: { ...initialState, type },
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      callback={formik.handleSubmit}
      btnText='DUPLICATE'
      typeSubmit
      paperProps={{ style: { width: 520 } }}
      submitting={submitting}
    >
      <div className={classes.title}>{title}</div>
      <div className={classes.subTitle}>{subTitle}</div>
      <div className={classes.info}>{infoTitle}</div>
      {type === 'Aisle' ? (
        <TextInput
          name='name'
          formikProps={formik}
          label={inputLabel}
          className={classes.input}
        />
      ) : (
        <TextInput
          name='quantity'
          formikProps={formik}
          label={inputLabel}
          className={classes.input}
          type='number'
        />
      )}
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { DuplicateWarehouseLocationForm } })(
  (theme) => ({
    title: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 600,
      marginBottom: 8,
    },
    subTitle: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      letterSpacing: 0,
      marginBottom: 24,
    },
    info: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 24,
    },
    input: {
      marginBottom: 16,
      width: '100%',
    },
  }),
);

const schema = object().shape({
  name: string()
    .nullable()
    .when('type', (type: LocationType, schema) => {
      return type == 'Aisle' ? schema.required('Required') : schema;
    }),
  quantity: string()
    .nullable()
    .when('type', (type: LocationType, schema) => {
      return type == 'Location' || type === 'Position'
        ? schema.required('Required')
        : schema;
    }),
});
