import {
  NavigateFunction,
  // NavigateOptions,
  To,
} from 'react-router';

let navigate: NavigateFunction | null = null;

export const Navigation = {
  init(navFunction: NavigateFunction) {
    navigate = navFunction;
    return navigate;
  },
  goBack() {
    navigate?.(-1);
  },
  goForward() {
    navigate?.(1);
  },
  // TODO update to accept the NavigateOptions instead of state
  redirect(to: To, state?: any) {
    navigate?.(to, { state });
  },
};

export default Navigation;
