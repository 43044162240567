import {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  Fragment,
} from 'react';
import { createSelector } from '@reduxjs/toolkit';
import _isEqual from 'lodash.isequal';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Radio,
  TextField,
  Button,
  TableHead,
  Tooltip,
} from '@mui/material';
import { BasicDialog } from './BasicDialog';
import {
  generateOrderPayload,
  generateReshipPayload,
} from '../../../pages/processOrder/generateProcessPayload';
import {
  useDispatch,
  useSelector,
  addResource,
  getCarrierRateShopList,
  setOrderRateId,
  processOrder,
  systemSelectors,
  processReship,
  getCarrierList,
  orderSelectors,
  getAllCarrierPackageTypes,
  carriersSelectors,
} from '../../../state';
import { SelectInput as FormikSelectInput } from '../..';
import {
  carrierImagesObj,
  formatCurrency,
  Navigation,
  generateUrl,
  paths,
  orderStatuses,
  debounceFunc,
  getRatesDateText,
  sortByOptions,
  getPackageTypesOptions,
} from '../../../lib';
import { useLoader, useGetUsersData, useNotify } from '../../../hooks';
import { InfoMessage } from '../InfoMessage';
import { useStyles } from './viewRates.styles';
import { InfoIcon } from '../../../assets';

// get the reship state slice only if isReship
const selectReshipState = createSelector(
  (state) => state.orders.reshipState,
  (_, isReship) => isReship,
  (reshipState, isReship) => (isReship ? reshipState : null),
);

export function ViewRates({ disabled, promptDocQty }) {
  const { classes, cx, css } = useStyles();
  const dispatch = useDispatch();
  const setLoader = useLoader();
  const notify = useNotify();
  const { isAdmin, isCustomerService, isTruckingSalesPerson } =
    useGetUsersData();
  const hasEditPermission =
    isAdmin || isCustomerService || isTruckingSalesPerson;

  const [payload, setPayload] = useState();
  const [adminToken, setAdminToken] = useState();
  const [showManagerLogin, setShowManagerLogin] = useState(!hasEditPermission);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [carrierAccountOptions, setCarrierAccountOptions] = useState([]);
  const [filteredCarrierIds, setFilteredCarrierIds] = useState(['all']);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [filteredCarrierCodes, setFilteredCarrierCodes] = useState(['all']);
  const [maxDeliveryDays, setMaxDeliveryDays] = useState();
  const [selectedRateId, setSelectedRateId] = useState('');
  const [selectedIntegrationType, setSelectedIntegrationType] = useState();
  const [selectedRateAmazonInfo, setSelectedRateAmazonInfo] = useState({});
  const [sortBy, setSortBy] = useState('CheapestToExpensive');
  const [filteredPackageTypes, setFilteredPackageTypes] = useState([]);
  const [confirmedDocQty, setConfirmedDocQty] = useState(!promptDocQty);
  const [showDocQtyModal, setShowDocQtyModal] = useState(false);
  const [tooltipOpened, setTooltipOpened] = useState(false);

  const prevQueryParamsStringRef = useRef('');
  const rateMapping = useRef({});

  const carrierPackageTypes = useSelector(
    carriersSelectors.carrierPackageTypes,
  );
  const physicalItems = useSelector(orderSelectors.physicalItems);
  const packages = useSelector(orderSelectors.packages);
  const order = useSelector(orderSelectors.selectedOrder);
  const machineName = useSelector(systemSelectors.machineName);
  const isReship = useSelector(orderSelectors.isReship);
  const reshipState = useSelector((state) =>
    selectReshipState(state, isReship),
  );

  useEffect(() => {
    dispatch(getAllCarrierPackageTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const packageTypesOptions =
    useMemo(() => {
      return getPackageTypesOptions(filteredCarrierCodes, carrierPackageTypes);
    }, [carrierPackageTypes, filteredCarrierCodes]) || [];

  useEffect(() => {
    if (
      (hasEditPermission || adminToken) &&
      confirmedDocQty &&
      openModal &&
      order &&
      packages
    ) {
      const payload = isReship
        ? generateReshipPayload({
            packages,
            allPhysicalItems: physicalItems,
            orderData: order,
            status: orderStatuses.CLOSED,
            reshipState,
          })
        : generateOrderPayload({
            packages,
            allPhysicalItems: physicalItems,
            orderData: order,
            status: orderStatuses.CLOSED,
          });
      setPayload(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, confirmedDocQty, hasEditPermission, adminToken]);

  useEffect(() => {
    (async function () {
      if ((hasEditPermission || adminToken) && openModal && confirmedDocQty) {
        let carrierCodes = filteredCarrierCodes;
        if (filteredCarrierCodes?.[0] === 'all') {
          carrierCodes = undefined;
        }
        const { data } = await dispatch(
          getCarrierRateShopList({
            carrierCodes,
            customerId: order.customerID,
            carrierType: 'SmallParcel',
            includeMultiplePackagesNotSupported: true,
          }),
        );
        if (data) {
          setCarrierAccountOptions(data);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openModal,
    confirmedDocQty,
    hasEditPermission,
    adminToken,
    filteredCarrierCodes,
  ]);

  useEffect(() => {
    (async function () {
      const { data } = await dispatch(
        getCarrierList({
          includeManualCarriers: false,
          carrierType: 'SmallParcel',
        }),
      );
      if (data) {
        setCarrierOptions(data);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    if (!payload) {
      return;
    }

    let carrierIDs = filteredCarrierIds;
    if (filteredCarrierIds?.[0] === 'all') {
      carrierIDs = undefined;
    }

    let carrierCodes = filteredCarrierCodes;
    if (filteredCarrierCodes?.[0] === 'all') {
      carrierCodes = undefined;
    }

    let packageTypes = filteredPackageTypes;
    if (filteredPackageTypes?.[0] === 'all') {
      packageTypes = undefined;
    }

    const queryParams = {
      sortBy,
      carrierIDs,
      carrierCodes,
      packageTypes,
      maxDeliveryDays,
      orderShipmentID: order.orderShipmentID,
      applyCustomerMarkup: true,
    };

    const queryParamsString = JSON.stringify(queryParams);

    //check if params changed
    if (queryParamsString === prevQueryParamsStringRef.current) {
      return;
    }

    setData([]);
    rateMapping.current = {};
    setLoader(true);

    const rateShop = async () => {
      const { data, error } = await dispatch(
        addResource({
          baseUrl: '/orders/rate-shop',
          payload,
          query: queryParams,
          shouldShowErrorMessage: false,
          tokenOverride: adminToken,
        }),
      );

      setLoader(false);
      if (error) {
        notify(
          error.message || error.title || 'Something went wrong.',
          'error',
        );
      } else {
        setData(data);
        rateMapping.current = data.reduce((acc, d) => {
          acc[d.rateID] = d;
          return acc;
        }, {});
      }
    };

    rateShop();

    prevQueryParamsStringRef.current = queryParamsString;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredCarrierIds,
    filteredPackageTypes,
    maxDeliveryDays,
    sortBy,
    payload,
    hasEditPermission,
    adminToken,
    order,
  ]);

  useEffect(() => {
    if (carrierAccountOptions && filteredCarrierIds) {
      const updatedFilteredCarrierIds = filteredCarrierIds.filter((id) =>
        carrierAccountOptions.some((d) => d.id === id),
      );

      if (!_isEqual(updatedFilteredCarrierIds, filteredCarrierIds)) {
        setFilteredCarrierIds(updatedFilteredCarrierIds);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierAccountOptions]);

  const handleRateChange = useCallback((e) => {
    setSelectedRateId(e.target.value);
    const selectedRate = rateMapping.current[e.target.value];
    setSelectedRateAmazonInfo(selectedRate?.amazonRateInfo || {});
    setSelectedIntegrationType(selectedRate?.integrationType);
  }, []);

  const handleClick = useCallback(() => {
    if (!confirmedDocQty) {
      setShowDocQtyModal(true);
    }
    setOpenModal(true);
  }, [confirmedDocQty]);

  const handleSelect = useCallback(async () => {
    if (!confirmedDocQty) {
      setConfirmedDocQty(true);
      setShowDocQtyModal(false);
    }
    if (selectedRateId) {
      setLoader(true);
      // Not sure if we need this anymore as we are just printing the labels
      dispatch(setOrderRateId(selectedRateId));
      // setOpenModal(false);
      // setPayload(cur => ({...cur, rateID: selectedRateId}))
      if (isReship) {
        const { error } = await dispatch(
          processReship({
            orderId: order.id,
            payload: {
              ...payload,
              printLabels: true,
              rateID: selectedRateId,
              amazonRateInfo: selectedRateAmazonInfo,
              integrationType: selectedIntegrationType,
              machineName,
            },
          }),
        );
        setLoader(false);
        if (!error) Navigation.redirect(generateUrl(paths.ORDERS));
      } else {
        const { error } = await dispatch(
          processOrder({
            payload: {
              ...payload,
              printLabels: true,
              rateID: selectedRateId,
              amazonRateInfo: selectedRateAmazonInfo,
              integrationType: selectedIntegrationType,
              machineName,
            },
          }),
        );
        setLoader(false);
        if (error) {
          return notify(error.message || 'Something went wrong', 'error');
        }
        notify('Successfully processed the order');
        Navigation.redirect(generateUrl(paths.SCAN_ORDER));
      }
    }
  }, [
    confirmedDocQty,
    dispatch,
    isReship,
    machineName,
    notify,
    order.id,
    payload,
    selectedIntegrationType,
    selectedRateAmazonInfo,
    selectedRateId,
    setLoader,
  ]);

  const onAdminLogin = useCallback((data) => {
    setShowManagerLogin(false);
    // setShowConfirmation(true);
    setAdminToken(data);
  }, []);

  const handleClose = useCallback(() => {
    setOpenModal(false);
    setShowManagerLogin(!hasEditPermission);
    setPayload(null);
    setAdminToken(null);
  }, [hasEditPermission]);

  const handleTooltipClose = () => {
    setTooltipOpened(false);
  };

  return (
    <Fragment>
      <Button
        color='secondary'
        variant='contained'
        size='large'
        style={{ marginRight: 16 }}
        disableElevation
        onClick={handleClick}
        disabled={disabled}
      >
        View rates
      </Button>
      <BasicDialog
        open={openModal}
        title={<span className={classes.title}>View rates</span>}
        btnText={showDocQtyModal ? 'View rates' : 'Print labels'}
        callback={handleSelect}
        handleClose={handleClose}
        paperProps={{
          className: cx(classes.paper, {
            [classes.paperManagerLogin]: showManagerLogin || showDocQtyModal,
          }),
        }}
        disableCallback={confirmedDocQty && !selectedRateId}
        showManagerLogin={showManagerLogin}
        onAdminLogin={onAdminLogin}
      >
        {showDocQtyModal ? (
          <InfoMessage message='Please confirm correct document count' />
        ) : (
          <Fragment>
            <div className={classes.filtersRow}>
              <div className={classes.filterContainer}>
                <SelectInput
                  state={filteredCarrierCodes}
                  setState={setFilteredCarrierCodes}
                  multiple
                  options={[
                    { id: 'all', name: 'All carriers' },
                    ...carrierOptions,
                  ]}
                  label='Carrier'
                  style={{ width: 150 }}
                />
                <SelectInput
                  state={filteredCarrierIds}
                  setState={setFilteredCarrierIds}
                  label='Carrier acct'
                  multiple
                  options={[
                    { id: 'all', name: 'All carriers' },
                    ...carrierAccountOptions,
                  ]}
                  style={{ width: 150 }}
                />
                <TextInput
                  state={maxDeliveryDays}
                  setState={setMaxDeliveryDays}
                  label='Max delivery days'
                />
                <SelectInput
                  state={sortBy}
                  setState={setSortBy}
                  options={sortByOptions}
                  label='Sort by'
                  style={{ width: 150 }}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip
                    slotProps={{ popper: { disablePortal: true } }}
                    onClose={handleTooltipClose}
                    open={tooltipOpened}
                    disableFocusListener
                    disableTouchListener
                    title='Package types are available on selecting a carrier account'
                  >
                    <InfoIcon
                      onMouseLeave={handleTooltipClose}
                      onMouseEnter={() => setTooltipOpened(true)}
                      style={{
                        fontSize: 15,
                        marginRight: 4,
                        color: '#FF4F5B',
                      }}
                    />
                  </Tooltip>
                  <SelectInput
                    state={filteredPackageTypes}
                    setState={setFilteredPackageTypes}
                    options={packageTypesOptions}
                    multiple
                    style={{ width: 150 }}
                    label='Package type'
                  />
                </div>
              </div>
            </div>
            {!!filteredPackageTypes.length && (
              <InfoMessage
                message='Rate requests for multi-package shipments that include carrier-specific package types may return rates. However, when the label is purchased the package type will be disregarded.'
                className={css({ marginTop: 16 })}
              />
            )}
            <div className={css({ minHeight: 375 })}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead} />
                    <TableCell className={classes.tableHead}>Carrier</TableCell>
                    <TableCell className={classes.tableHead}>
                      Account/Package Type
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      Service/Est Delivery
                    </TableCell>
                    <TableCell className={classes.tableHead}>Cost</TableCell>
                    <TableCell align='right' className={classes.tableHead}>
                      Customer cost
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((d) => {
                    const {
                      rateID,
                      carrierCode,
                      carrierNickname,
                      customerRate,
                      deliveryDays,
                      estimatedDeliveryDate,
                      packageTypeDisplay,
                      serviceTypeDescription,
                      totalCost,
                      warningMessages,
                    } = d;
                    const { url, height, width } =
                      carrierImagesObj[carrierCode?.toLowerCase()] || {};

                    return (
                      <TableRow key={rateID}>
                        <TableCell padding='checkbox'>
                          <Radio
                            name='selectedRateId'
                            value={rateID}
                            onChange={handleRateChange}
                            checked={selectedRateId === rateID}
                            color='primary'
                            size='small'
                          />
                        </TableCell>
                        <TableCell>
                          <div
                            className={css({
                              display: 'flex',
                              alignItems: 'center',
                            })}
                          >
                            {url ? (
                              <img
                                src={url}
                                alt={carrierCode}
                                draggable={false}
                                style={{ width, height }}
                              />
                            ) : (
                              <span>--</span>
                            )}
                            {Array.isArray(warningMessages) &&
                              !!warningMessages.length && (
                                <Tooltip
                                  title={warningMessages.join(',')}
                                  placement='bottom'
                                >
                                  <InfoIcon
                                    color='error'
                                    className={css({
                                      marginLeft: 4,
                                      fontSize: 16,
                                    })}
                                  />
                                </Tooltip>
                              )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.contentContainer}>
                            <span className={classes.filterLabel}>
                              {carrierNickname}
                            </span>
                            <span className={classes.dataValues}>
                              {packageTypeDisplay}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.contentContainer}>
                            <span className={classes.filterLabel}>
                              {serviceTypeDescription}
                            </span>
                            <span className={classes.dataValues}>
                              {getRatesDateText(
                                deliveryDays,
                                estimatedDeliveryDate,
                              )}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell style={{ fontSize: 14 }}>
                          {formatCurrency(totalCost)}
                        </TableCell>
                        <TableCell align='right' style={{ fontSize: 14 }}>
                          {formatCurrency(customerRate ?? totalCost)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </Fragment>
        )}
      </BasicDialog>
    </Fragment>
  );
}

function SelectInput({ state, setState, options, label, multiple, style }) {
  const { classes } = useStyles();
  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      if (!multiple) {
        return setState(value);
      }

      if (value[value.length - 1] === 'all') {
        setState(['all']);
      } else {
        setState((cur) => {
          let newState = value;
          if (value.includes('all')) {
            newState = newState.filter((s) => s !== 'all');
          }
          return newState;
        });
      }
    },
    [multiple, setState],
  );

  return (
    <FormikSelectInput
      value={state}
      onChange={handleChange}
      label={label}
      // InputProps={{ disableUnderline: true }}
      slotProps={{
        select: {
          multiple,
          classes: { icon: classes.icon },
        },
        inputLabel: { className: classes.label },
      }}
      variant='outlined'
      size='small'
      options={options}
      className={classes.input}
      style={style}
    />
  );
}

function TextInput({ state, setState, label }) {
  const { classes } = useStyles();

  const debounceChange = useMemo(
    () =>
      debounceFunc((value) => {
        setState(value);
      }),
    [setState],
  );

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      debounceChange(value);
    },
    [debounceChange],
  );

  return (
    <TextField
      variant='outlined'
      value={state}
      onChange={handleChange}
      label={label}
      className={classes.input}
      style={{ width: 120 }}
      type='number'
      size='small'
      slotProps={{
        inputLabel: { className: classes.label },
      }}
    />
  );
}
