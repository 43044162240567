import { useCallback, useEffect, useState } from 'react';
import { Grid2 as Grid } from '@mui/material';
import { makeStyles } from '../../../themes';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { SelectInput, TextInput } from '../inputs';
import { useDispatch, addResource, getUsersBasicList } from '../../../state';
import { useRefresh } from '../../../hooks';
import { CheckInSmallParcelReceiptRequest, IdNamePair } from '../../../types';
import { object, string } from 'yup';

interface Props {
  open: boolean;
  handleClose: () => any;
  receiptId: number | string;
  warehouseId: number | string;
  setViewVersion?: React.Dispatch<React.SetStateAction<number>>;
}

interface State {
  palletsCount: number | '';
  managerUserID: number | '';
  warehouseStagingLocationName: string;
}

const initialState: State = {
  palletsCount: '',
  managerUserID: '',
  warehouseStagingLocationName: '',
};

export function ReceiptCheckInSmallParcelForm({
  open,
  handleClose,
  receiptId,
  warehouseId,
  setViewVersion,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [submitting, setSubmitting] = useState(false);
  const [managers, setManagers] = useState<Array<IdNamePair>>([]);

  useEffect(() => {
    (async function () {
      const { data } = await dispatch(
        getUsersBasicList({ warehouseID: warehouseId, userTypes: ['Manager'] }),
      );
      if (data) {
        setManagers(data);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId]);

  const handleSubmit = useCallback(
    async (values: State) => {
      const { palletsCount, managerUserID, warehouseStagingLocationName } =
        values;
      if (!palletsCount || !warehouseStagingLocationName) {
        return;
      }
      setSubmitting(true);
      const payload: CheckInSmallParcelReceiptRequest = {
        receiptID: receiptId,
        palletsCount,
        managerUserID,
        warehouseStagingLocationName,
      };

      const { error } = await dispatch(
        addResource<any, CheckInSmallParcelReceiptRequest>({
          baseUrl: `/receipts/${receiptId}/small-parcel/check-in`,
          payload,
          message: 'Successfully checked-in receipt',
          shouldSetUILoading: true,
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [receiptId],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>
          Update receipt shipment as arrived
        </span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      // paperProps={{ style: { width: 368 } }}
      submitting={submitting}
    >
      <Grid container spacing={5} rowSpacing={2}>
        <Grid
          size={{
            sm: 12,
          }}
        >
          <TextInput
            name='palletsCount'
            formikProps={formik}
            label='How many cartons were received?'
            className={classes.input}
            type='number'
          />
        </Grid>
        <Grid
          size={{
            sm: 12,
          }}
        >
          <SelectInput
            name='managerUserID'
            formikProps={formik}
            label='Assign a manager'
            options={managers}
            className={classes.input}
          />
        </Grid>
        <Grid
          size={{
            sm: 12,
          }}
        >
          <TextInput
            name='warehouseStagingLocationName'
            formikProps={formik}
            label='Select a location'
            className={classes.input}
          />
        </Grid>
      </Grid>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    width: '100%',
  },
}));

const schema = object().shape({
  palletsCount: string().required('Required'),
});
