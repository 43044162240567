import { useState, useCallback, useMemo, Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button, Divider, IconButton, Grid2 } from '@mui/material';
import {
  BasicDialog,
  DateInput,
  ListDeleteIcon,
  TextInput,
} from '../../components';
import type { InputChangeEventType, MouseChangeEventType } from '../../types';
import { makeuuid, updateNestedState } from '../../lib';
import { useIsMobile, useNotify } from '../../hooks';
import { AddCircleIcon } from '../../assets';
import { MobileModalForm } from '../../themes/mobile/MobileModalForm';
import { TextButton } from '../../themes';

import type { FormikProps } from 'formik';
import type {
  EditInventoryItemProps,
  CreateInventoryPhysicalItem,
} from './utils';

interface Props {
  open: boolean;
  handleClose: (doSubmit?: boolean) => void;
  item: EditInventoryItemProps;
  packageKey: string;
  itemKey: string;
  remainderSkuPhysicalItemsQuantity?: number;
  requiresLotNumber?: boolean;
  requiresSerialNumber?: boolean;
  requiresExpirationDate?: boolean;
  nestedKey?: Array<string>;
  formikProps: FormikProps<any>;
}

interface RenderFormProps {
  item: EditInventoryItemProps;
  totalPhysicalItems: number;
  maxQty: number;
  state: Record<string, CreateInventoryPhysicalItem>;
  remainderSkuPhysicalItemsQuantity: number;
  requiresLotNumber?: boolean;
  requiresSerialNumber?: boolean;
  requiresExpirationDate?: boolean;
  handleStateChange: (e: InputChangeEventType) => void;
  handleAddRow: () => void;
  handleDateChange: (key: string) => (value: Date | null) => void;
  handleRemove: (e: MouseChangeEventType) => void;
}

export function InventoryTrackingForm({
  open,
  handleClose,
  item,
  formikProps,
  packageKey,
  itemKey,
  nestedKey,
  remainderSkuPhysicalItemsQuantity = 0,
  requiresLotNumber,
  requiresSerialNumber,
  requiresExpirationDate,
}: Props) {
  const { css } = useStyles();
  const notify = useNotify();
  const isMobile = useIsMobile();

  const [state, setState] = useState<
    Record<string, CreateInventoryPhysicalItem>
  >(item.createPhysicalItems || {});

  const [maxQty] = useState(getMaxQty(item.skuQuantity));

  const totalPhysicalItems = useMemo(
    () => Object.values(state).reduce((acc, cur) => cur.quantity + acc, 0),
    [state],
  );

  const requiresInventoryTracking =
    requiresLotNumber || requiresSerialNumber || requiresExpirationDate;

  const { values, setValues } = formikProps;

  const handleAddRow = useCallback(() => {
    if (totalPhysicalItems + remainderSkuPhysicalItemsQuantity >= maxQty) {
      return notify('Quantity exceeds the total items', 'error');
    }
    setState((cur) => ({
      ...cur,
      [makeuuid(7)]: {
        quantity: 1,
        expirationDate: null,
        lotNumber: '',
        serialNumber: '',
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxQty, totalPhysicalItems]);

  const handleStateChange = useCallback(
    (e: InputChangeEventType) => {
      const key = e.currentTarget.getAttribute('data-key');
      if (key) {
        const { name, value: _value, valueAsNumber, type } = e.target;
        const value =
          type === 'number' && !isNaN(valueAsNumber) ? valueAsNumber : _value;

        if (name === 'quantity') {
          if (valueAsNumber < 0) {
            return;
          }
          const currentQty = state[key]?.quantity || 0;
          if (
            remainderSkuPhysicalItemsQuantity +
              totalPhysicalItems +
              (valueAsNumber - currentQty) >
            maxQty
          ) {
            return notify('Quantity exceeds the total items', 'error');
          }
        }

        setState((cur) => ({ ...cur, [key]: { ...cur[key], [name]: value } }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxQty, totalPhysicalItems],
  );

  const handleDateChange = useCallback(
    (key: string) => (value: Date | null) => {
      if (key) {
        setState((cur) => ({
          ...cur,
          [key]: { ...cur[key], expirationDate: value },
        }));
      }
    },
    [],
  );

  const handleRemove = useCallback((e: MouseChangeEventType) => {
    const key = e.currentTarget.getAttribute('data-key');
    if (key) {
      setState((cur) => {
        const { [key]: itemToDelete, ...rest } = cur;
        return rest;
      });
    }
  }, []);

  const handleSubmit = () => {
    const isCompleted = validateNonEmptyFields({
      inventoryState: state,
      requiresInventoryTracking: !!requiresInventoryTracking,
      requiresExpirationDate,
      requiresLotNumber,
      requiresSerialNumber,
    });
    if (!isCompleted) {
      return notify(
        'The item requires inventory tracking to proceed - please fill out the above field',
        'error',
      );
    }
    const newState = updateNestedState({
      nestedKey: nestedKey || [
        'createPackages',
        packageKey,
        'createItems',
        itemKey,
        'createPhysicalItems',
      ],
      value: state,
      state: values,
      shouldOverrideValue: true,
    });
    setValues({ ...values, ...newState });
    handleClose(true);
  };

  return (
    <Fragment>
      {isMobile ? (
        <MobileModalForm
          open={open}
          handleClose={handleClose}
          callback={handleSubmit}
        >
          <RenderForm
            item={item}
            state={state}
            totalPhysicalItems={totalPhysicalItems}
            maxQty={maxQty}
            remainderSkuPhysicalItemsQuantity={
              remainderSkuPhysicalItemsQuantity
            }
            handleStateChange={handleStateChange}
            handleAddRow={handleAddRow}
            handleDateChange={handleDateChange}
            handleRemove={handleRemove}
            requiresExpirationDate={requiresExpirationDate}
            requiresLotNumber={requiresLotNumber}
            requiresSerialNumber={requiresSerialNumber}
          />
        </MobileModalForm>
      ) : (
        <BasicDialog
          open={open}
          handleClose={() => handleClose()}
          callback={handleSubmit}
          paperProps={{ className: css({ maxWidth: 600 }) }}
        >
          <RenderForm
            item={item}
            state={state}
            totalPhysicalItems={totalPhysicalItems}
            maxQty={maxQty}
            remainderSkuPhysicalItemsQuantity={
              remainderSkuPhysicalItemsQuantity
            }
            handleStateChange={handleStateChange}
            handleAddRow={handleAddRow}
            handleDateChange={handleDateChange}
            handleRemove={handleRemove}
            requiresExpirationDate={requiresExpirationDate}
            requiresLotNumber={requiresLotNumber}
            requiresSerialNumber={requiresSerialNumber}
          />
        </BasicDialog>
      )}
    </Fragment>
  );
}

function RenderForm({
  item,
  totalPhysicalItems,
  maxQty,
  state,
  remainderSkuPhysicalItemsQuantity,
  handleStateChange,
  handleAddRow,
  handleDateChange,
  handleRemove,
  requiresExpirationDate,
  requiresLotNumber,
  requiresSerialNumber,
}: RenderFormProps) {
  const { classes, css } = useStyles();
  const isMobile = useIsMobile();
  const itemColumnWidth = isMobile ? 6 : 3;

  return (
    <div>
      <div
        className={classes.title}
      >{`Inventory tracking | SKU:${item?.sku} `}</div>
      <div>{`${totalPhysicalItems} of ${
        maxQty - remainderSkuPhysicalItemsQuantity
      } entered`}</div>
      <div className={classes.subTitle}>Add inventory items</div>
      {Object.keys(state).map((k) => {
        const physicalItem = state[k];

        return (
          <Fragment key={k}>
            <div className={classes.row}>
              <Grid2 container spacing={3} rowSpacing={isMobile ? 0 : 3}>
                <Grid2 size={itemColumnWidth}>
                  <TextInput
                    name='quantity'
                    value={physicalItem.quantity}
                    label='Quantity'
                    handleChange={handleStateChange}
                    slotProps={{ htmlInput: { 'data-key': k } }}
                    type='number'
                    disabled={!!physicalItem.serialNumber}
                    className={classes.input}
                  />
                </Grid2>
                <Grid2 size={itemColumnWidth}>
                  <TextInput
                    name='serialNumber'
                    value={physicalItem.serialNumber}
                    label='Serial number'
                    handleChange={handleStateChange}
                    slotProps={{ htmlInput: { 'data-key': k } }}
                    disabled={physicalItem.quantity > 1}
                    className={classes.input}
                    required={requiresSerialNumber}
                  />
                </Grid2>
                <Grid2 size={itemColumnWidth}>
                  <DateInput
                    name='expirationDate'
                    value={physicalItem.expirationDate}
                    label='Exp date'
                    onChange={handleDateChange(k)}
                    textFieldProps={{
                      inputProps: { 'data-key': k },
                      required: requiresExpirationDate,
                    }}
                    className={classes.input}
                  />
                </Grid2>
                <Grid2 size={itemColumnWidth}>
                  <TextInput
                    name='lotNumber'
                    value={physicalItem.lotNumber}
                    label='Lot #'
                    handleChange={handleStateChange}
                    slotProps={{ htmlInput: { 'data-key': k } }}
                    className={classes.input}
                    required={requiresLotNumber}
                  />
                </Grid2>
                {isMobile && (
                  <Grid2 size={itemColumnWidth}>
                    <div className={css({ marginTop: 8 })}>
                      <TextButton
                        size='small'
                        onClick={handleRemove}
                        data-key={k}
                        className={classes.removeButton}
                      >
                        Remove
                      </TextButton>
                    </div>
                  </Grid2>
                )}
              </Grid2>
              <div>
                {!isMobile && (
                  <IconButton size='small' onClick={handleRemove} data-key={k}>
                    <ListDeleteIcon />
                  </IconButton>
                )}
              </div>
            </div>
            {isMobile && <Divider className={classes.divider} />}
          </Fragment>
        );
      })}
      <div>
        <Button
          size='small'
          disableElevation
          onClick={handleAddRow}
          className={css({ textTransform: 'none' })}
          startIcon={<AddCircleIcon />}
        >
          Add
        </Button>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  name: { ReceiptInventoryTracking: InventoryTrackingForm },
})((theme) => ({
  title: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    marginBottom: 8,
    [theme.breakpoints.down('tablet')]: {
      fontSize: 16,
    },
  },
  qty: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    letterSpacing: 0,
    marginBottom: 16,
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0,
    marginBottom: 8,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 18,
    [theme.breakpoints.down('tablet')]: {
      marginBottom: 0,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  input: {
    width: '100%',
  },
  divider: {
    marginTop: 16,
    marginBottom: 16,
    borderColor: '#E5E7F5',
  },
  removeButton: {
    color: '#D5D8EC',
    fontSize: 14,
    fontWeight: 600,
  },
}));

function getMaxQty(itemQty: string | number | undefined): number {
  let qty = 1;

  switch (true) {
    case typeof itemQty === 'string' && !!itemQty:
      qty = parseInt(itemQty as string);
      break;
    case !itemQty:
      qty = 1;
      break;
    default:
      qty = itemQty as number;
  }

  return qty;
}

interface ValidateProps {
  inventoryState: Record<string, CreateInventoryPhysicalItem>;
  requiresInventoryTracking: boolean;
  requiresExpirationDate?: boolean;
  requiresSerialNumber?: boolean;
  requiresLotNumber?: boolean;
}
function validateNonEmptyFields({
  inventoryState,
  requiresInventoryTracking,
  requiresExpirationDate,
  requiresSerialNumber: requiresSerialNumber,
  requiresLotNumber: requiresLotNumber,
}: ValidateProps) {
  if (!requiresInventoryTracking) return true;

  return Object.values(inventoryState).every((row) => {
    switch (true) {
      case requiresExpirationDate && !row.expirationDate:
        return false;
      case requiresSerialNumber && !row.serialNumber:
        return false;
      case requiresLotNumber && !row.lotNumber:
        return false;
      default:
        return true;
    }
  });
}
