import { RouteIdParamsTypes, SystemServiceType } from '../../types';

export interface CameraApiConfig {
  route: string;
  idField: string;
  idValue: string | number;
}

export const serviceTypesRequiringWarehouseId: Array<SystemServiceType> = [
  'Appointment',
];

/**
 *  Get the photo API URL from route params
 * @param routeParams
 * @returns
 */
export function getDocumentsSubmitUrlAndId(
  routeParams?: RouteIdParamsTypes,
): CameraApiConfig | null {
  if (!routeParams) return null;
  const rv = {
    route: '',
    idField: '',
    idValue: '',
  };
  switch (true) {
    case !!routeParams.ORDER_ID:
      rv.route = `/orders/${routeParams.ORDER_ID}/documents`;
      rv.idField = 'orderID';
      rv.idValue = routeParams.ORDER_ID!;
      break;
    case !!routeParams.TRUCKING_JOB_ID:
      rv.route = `/shippingJobs/${routeParams.TRUCKING_JOB_ID}/documents`;
      rv.idField = 'shipmentJobID';
      rv.idValue = routeParams.TRUCKING_JOB_ID!;
      break;
    case !!routeParams.DRAYAGE_JOB_ID:
      rv.route = `/drayageJobs/${routeParams.DRAYAGE_JOB_ID}/documents`;
      rv.idField = 'drayageJobID';
      rv.idValue = routeParams.DRAYAGE_JOB_ID!;
      break;
    case !!routeParams.FULFILLED_SERVICE_ID:
      rv.route = `/services/fulfilled/${routeParams.FULFILLED_SERVICE_ID}/documents`;
      rv.idField = 'fulfilledServiceID';
      rv.idValue = routeParams.FULFILLED_SERVICE_ID!;
      break;
    case !!routeParams.RECEIPT_ID:
      rv.route = `/receipts/${routeParams.RECEIPT_ID}/documents`;
      rv.idField = 'receiptID';
      rv.idValue = routeParams.RECEIPT_ID!;
      break;
    case !!routeParams.APPOINTMENT_ID:
      rv.route = `/warehouses/${routeParams.WAREHOUSE_ID}/appointments/${routeParams.APPOINTMENT_ID}/documents`;
      rv.idField = 'appointmentID';
      rv.idValue = routeParams.APPOINTMENT_ID!;
      break;
    default:
      return null;
  }

  return rv;
}

/**
 * Get the photo API URL for an entity type with the entity id
 * @param entityType
 * @param entityId
 * @returns
 */
export function getDocumentsSubmitUrlAndIdFromServiceType(
  entityType: SystemServiceType,
  entityId: number | string,
  warehouseId?: number | string,
): CameraApiConfig | null {
  if (!entityId) return null;
  const rv = {
    route: '',
    idField: '',
    idValue: entityId,
  };
  switch (true) {
    case entityType === 'OrderFulfillment':
      rv.route = `/orders/${entityId}/documents`;
      rv.idField = 'orderID';
      break;
    case entityType === 'TruckingJob':
      rv.route = `/shippingJobs/${entityId}/documents`;
      rv.idField = 'shipmentJobID';
      break;
    case entityType === 'DrayageJob':
      rv.route = `/drayageJobs/${entityId}/documents`;
      rv.idField = 'drayageJobID';
      break;
    case entityType === 'Manual':
    case entityType === 'MoveInventory': // For now we will save it on th fulfilled service
      rv.route = `/services/fulfilled/${entityId}/documents`;
      rv.idField = 'fulfilledServiceID';
      break;
    case entityType === 'Receipt':
      rv.route = `/receipts/${entityType}/documents`;
      rv.idField = 'receiptID';
      break;
    case entityType === 'Appointment' && !!warehouseId:
      rv.route = `/warehouses/${warehouseId}/appointments/${entityId}/documents`;
      rv.idField = 'appointmentID';
      break;
    default:
      return null;
  }

  return rv;
}
