import {
  useCallback,
  useState,
  cloneElement,
  Children,
  isValidElement,
  Fragment,
  useMemo,
  memo,
  ReactNode,
  useEffect,
} from 'react';
import {
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  Collapse,
  Box,
  TableRowProps,
  TableCellProps,
  TableCellBaseProps,
} from '@mui/material';
import { ListHead } from './ListHead';
import { KeyboardArrowDownIcon, KeyboardArrowUpIcon } from '../../assets';
import {
  useDispatch,
  addSelectedIds,
  updateListParams,
  getSelectedRows,
} from '../../state';
import { useStyles } from './list.styles';

import {
  BulkActionProps,
  ExpandProps,
  ObjectWithId,
  OrderByType,
  InputChangeEventType,
  MouseChangeEventType,
} from '../../types';

interface Props<TRow extends ObjectWithId = ObjectWithId> {
  rowProps?: TableRowProps;
  cellProps?: TableCellProps;
  expand?: React.ReactElement<any>;
  expandOptions?: ExpandProps;
  bulkActionOptions?: BulkActionProps;
  getSelectedRowsAndIds?: (
    ids: Array<number | string>,
    row: Record<string, any>,
  ) => any;
  customRow?: Record<string, any>;
  rowClick?: (id: string | number, row: TRow) => any;
  mouseEnter?: (id: string | number, row: TRow) => any;
  mouseLeave?: (id: string | number, row: TRow) => any;
  rows?: Record<string | number, TRow>;
  hasBulkActions?: boolean;
  ids?: Array<number | string>;
  // onToggleItem,
  resource?: string;
  rowStyle?: (row: TRow, rowIndex: number) => any;
  rowCellStyle?: (row: TRow) => any;
  selectedIds?: Array<number | string>;
  rowsPerPage?: number;
  page?: number;
  children?: ReactNode;
  order?: OrderByType;
  headerProps?: TableCellBaseProps;
  orderBy?: any; // TODO
  bulkActionButtons?: React.ReactElement<any> | boolean;
  hideHeader?: boolean;
}

interface CloneRowProps<TRow extends ObjectWithId = ObjectWithId> {
  hasBulkActions?: boolean;
  id: number | string;
  rowKey: number | string;
  index: number;
  record: TRow;
  resource: string;
  rowClick?: (id: string | number, row: TRow) => any;
  selected: boolean;
}

interface CloneExpandProps<TRow extends ObjectWithId = ObjectWithId> {
  resource: TRow;
}

interface ExpandableRowProps {
  expandOptions?: {
    cellStyle?: React.CSSProperties;
    expandPosition?: 'start' | 'end';
  };
  rowId: number | string;
  expandedIds: Array<number | string>;
  handleExpand: (
    id: number | string,
    event:
      | MouseChangeEventType
      | React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => any;
}

const DataGridComponent = <TRow extends ObjectWithId = ObjectWithId>({
  rowProps: _rowProps,
  cellProps,
  expand,
  expandOptions: _expandOptions,
  bulkActionOptions,
  getSelectedRowsAndIds,
  customRow,
  rowClick,
  mouseEnter,
  mouseLeave,
  rows: _rows,
  hasBulkActions: _hasBulkActions,
  ids: _ids,
  // onToggleItem,
  resource,
  rowStyle,
  rowCellStyle,
  selectedIds: _selectedIds,
  rowsPerPage = 0,
  page = 0,
  children,
  order,
  headerProps,
  orderBy,
  bulkActionButtons,
  hideHeader,
}: Props<TRow>) => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const [rowProps] = useState(_rowProps || {});
  const [expandOptions] = useState(_expandOptions || {});
  const rows = useMemo(() => _rows ?? {}, [_rows]);
  const ids = useMemo(() => _ids ?? [], [_ids]);
  const selectedIds = useMemo(() => _selectedIds ?? [], [_selectedIds]);

  const { defaultExpanded } = expandOptions;
  const hasBulkActions = _hasBulkActions ?? bulkActionButtons !== false;

  const [expandedIds, setExpandIds] = useState<Array<number | string>>([]);
  const isSelected = (name: number | string) =>
    selectedIds.indexOf(name) !== -1;

  const setSelected = useCallback(
    (newIds: Array<number | string>) => {
      if (!resource && !getSelectedRowsAndIds) {
        return;
      }
      if (resource) {
        dispatch(addSelectedIds({ resource, newIds }));
      }
      if (getSelectedRowsAndIds) {
        getSelectedRowsAndIds(
          newIds,
          getSelectedRows({ newIds, rows }).newSelectedRows,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resource, rows],
  );

  useEffect(() => {
    // Update expandedIds when ids changes
    if (defaultExpanded) {
      setExpandIds(ids.map((id) => rows[id].id));
    } else {
      setExpandIds([]);
    }
  }, [ids, rows, defaultExpanded]);

  // const emptyRows = useMemo(
  //   () => (page > 0 ? Math.max(0, rowsPerPage - ids.length) : 0),
  //   [page, ids.length, rowsPerPage],
  // );

  const handleClick = useCallback(
    (event: MouseChangeEventType, name: number | string, row: TRow) => {
      event.stopPropagation();
      const selectedIndex = selectedIds.indexOf(name);
      let newSelected: Array<number | string> = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedIds, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedIds.slice(1));
      } else if (selectedIndex === selectedIds.length - 1) {
        newSelected = newSelected.concat(selectedIds.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedIds.slice(0, selectedIndex),
          selectedIds.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    },
    [selectedIds, setSelected],
  );

  const handleSelectAllClick = useCallback(
    (event: InputChangeEventType, checked: boolean) => {
      let newIds: Array<number | string> = [];
      if (event.target.checked) {
        newIds = ids.map((id) => rows[id].id);
      }
      setSelected(newIds);
    },
    [ids, rows, setSelected],
  );

  const handleRequestSort = useCallback(
    (
      event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
      property: string,
    ) => {
      const isAsc = orderBy === property && order === 'asc';
      dispatch(
        updateListParams({
          list: resource,
          params: { order: isAsc ? 'desc' : 'asc', orderBy: property },
        }),
      );
    },
    [dispatch, order, orderBy, resource],
  );

  const handleExpand = useCallback(
    (
      id: number | string,
      event:
        | MouseChangeEventType
        | React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    ) => {
      event?.stopPropagation();
      setExpandIds((cur) => {
        let newState = [...cur];
        if (cur.includes(id)) {
          newState = cur.filter((i) => i !== id);
        } else {
          newState.push(id);
        }
        return newState;
      });
    },
    [],
  );

  const checkboxClasses = useMemo(
    () => ({
      root: classes.checkbox,
      checked: classes.checkboxChecked,
    }),
    [classes.checkbox, classes.checkboxChecked],
  );

  const rowClasses = useMemo(
    () => ({
      selected: classes.selectedRow,
    }),
    [classes.selectedRow],
  );

  return (
    <Fragment>
      {!hideHeader && (
        <ListHead
          numSelected={selectedIds.length}
          headerProps={headerProps}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={ids.length}
          fields={children}
          hasBulkActions={hasBulkActions}
          bulkActionOptions={bulkActionOptions}
          hasExpand={!!expand && !expandOptions.keepExpanded}
        />
      )}
      <TableBody>
        {isValidElement(customRow) && cloneElement(customRow)}
        {ids.map((id, rowIndex) => {
          const row = rows[id];
          if (!row) {
            return null;
          }
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${rowIndex}`;
          return (
            <Fragment key={id}>
              <TableRow
                role='checkbox'
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
                className={cx({
                  [classes.clickableRow]:
                    typeof rowClick === 'function' ||
                    (expand && !expandOptions.keepExpanded),
                })}
                style={
                  typeof rowStyle === 'function'
                    ? rowStyle(row, rowIndex)
                    : undefined
                }
                onClick={
                  typeof rowClick === 'function'
                    ? () => rowClick(id, row as TRow)
                    : expand && !expandOptions.keepExpanded
                      ? (e) => handleExpand(row.id, e)
                      : undefined
                }
                onMouseEnter={
                  typeof mouseEnter === 'function'
                    ? () => mouseEnter(id, row as TRow)
                    : undefined
                }
                onMouseLeave={
                  typeof mouseLeave === 'function'
                    ? () => mouseLeave(id, row as TRow)
                    : undefined
                }
                hover={!!rowClick || (!!expand && !expandOptions.keepExpanded)}
                classes={rowClasses}
                {...rowProps}
              >
                {expandOptions?.expandPosition === 'start' &&
                  isValidElement(expand) &&
                  !expandOptions.keepExpanded && (
                    <ExpandableRow
                      expandOptions={expandOptions}
                      rowId={row.id}
                      expandedIds={expandedIds}
                      handleExpand={handleExpand}
                    />
                  )}
                {hasBulkActions && (
                  <TableCell
                    padding='checkbox'
                    {...cellProps}
                    className={
                      bulkActionOptions?.isSticky
                        ? classes.stickyLeftColumn
                        : undefined
                    }
                  >
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                      onClick={(event) =>
                        handleClick(event, row.id, row as TRow)
                      }
                      classes={checkboxClasses}
                      size='small'
                    />
                  </TableCell>
                )}
                {Children.map(children, (field, index) => {
                  return isValidElement(field) ? (
                    <TableCell
                      align={(field.props as any).align || 'left'}
                      style={{
                        ...(field.props as any).cellStyle,
                        ...(rowCellStyle && rowCellStyle(row)),
                      }}
                      className={cx(classes.tableCell, {
                        [classes.stickyRightColumn]:
                          (field.props as any).stickyPosition === 'right',
                        [classes.stickyLeftColumn]:
                          (field.props as any).stickyPosition === 'left',
                      })}
                    >
                      {cloneElement(
                        field as React.ReactElement<CloneRowProps<TRow>>,
                        {
                          // expand,
                          hasBulkActions,
                          id: row.id,
                          rowKey: id,
                          index: rowIndex,
                          // onToggleItem,
                          record: row,
                          resource,
                          rowClick,
                          selected: selectedIds.includes(row.id),
                        },
                        children,
                      )}
                    </TableCell>
                  ) : null;
                })}
                {expandOptions?.expandPosition !== 'start' &&
                  isValidElement(expand) &&
                  !expandOptions.keepExpanded && (
                    <ExpandableRow
                      expandOptions={expandOptions}
                      rowId={row.id}
                      expandedIds={expandedIds}
                      handleExpand={handleExpand}
                    />
                  )}
                {/* <TableCell component='th' id={labelId} scope='row' padding='none'>
              {row.id}
            </TableCell> */}
              </TableRow>
              {isValidElement(expand) && (
                <TableRow>
                  <TableCell
                    style={{
                      ...(expandedIds.includes(row.id) && {
                        overflowX: 'auto',
                      }),
                      ...(expandOptions.expandedRowStyle ?? {
                        paddingBottom: 0,
                        paddingTop: 0,
                      }),
                    }}
                    className={cx(classes.tableCell, {
                      [classes.borderNone]: !expandedIds.includes(row.id),
                    })}
                    colSpan={Children.count(children) + 1}
                  >
                    {expandOptions.keepExpanded ? (
                      <Box margin={1}>
                        {cloneElement(
                          expand as React.ReactElement<CloneExpandProps<TRow>>,
                          { resource: row as TRow },
                        )}
                      </Box>
                    ) : (
                      <Collapse
                        in={expandedIds.includes(row.id)}
                        timeout='auto'
                        unmountOnExit
                      >
                        <Box margin={1}>
                          {cloneElement(
                            expand as React.ReactElement<
                              CloneExpandProps<TRow>
                            >,
                            { resource: row as TRow },
                          )}
                        </Box>
                      </Collapse>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          );
        })}
        {/* {emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={6} />
          </TableRow>
        )} */}
      </TableBody>
    </Fragment>
  );
};

export const DataGrid = memo(DataGridComponent) as <
  TRow extends ObjectWithId = ObjectWithId,
>(
  props: Props<TRow>,
) => React.ReactElement<any>;

const ExpandableRow = ({
  expandOptions,
  rowId,
  expandedIds,
  handleExpand,
}: ExpandableRowProps) => {
  const { classes } = useStyles();
  const { cellStyle, expandPosition } = expandOptions || {};
  return (
    <TableCell
      align={expandPosition === 'start' ? 'left' : 'right'}
      className={classes.tableCell}
      style={cellStyle}
    >
      <IconButton size='small' onClick={(e) => handleExpand(rowId, e)}>
        {expandedIds.includes(rowId) ? (
          <KeyboardArrowUpIcon className={classes.expandIcon} />
        ) : (
          <KeyboardArrowDownIcon className={classes.expandIcon} />
        )}
      </IconButton>
    </TableCell>
  );
};
