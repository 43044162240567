import React, { useCallback, forwardRef } from 'react';
import _get from 'lodash.get';
import { TextField } from '@mui/material';
import { NumberFormatter } from '../InputNumberFormatter';
import { useStyles } from './inputs.styles';

export const TextInput = forwardRef(
  /**
   *
   * @param {import("@mui/material/TextField").TextFieldProps & otherProps} props
   * @returns
   */
  (
    {
      name,
      value,
      useCustomValue = false,
      formikProps,
      handleChange,
      handleBlur,
      validationKey,
      ...otherProps
    },
    ref,
  ) => {
    const {
      handleChange: formikHandleChange,
      handleBlur: formikHandleBlur,
      values,
      touched,
      errors,
    } = formikProps || {};

    const onChange = useCallback(
      (e) => {
        formikHandleChange && !useCustomValue && formikHandleChange(e);
        handleChange && handleChange(e);
      },
      [formikHandleChange, handleChange, useCustomValue],
    );

    const onBlur = useCallback(
      (e) => {
        formikHandleBlur && formikHandleBlur(e);
        handleBlur && handleBlur(e);
      },
      [formikHandleBlur, handleBlur],
    );

    // On blur touched is set on the name but on submit it's set on the nested object so we need to check both
    const isTouched = !!(_get(touched, name) || _get(touched, validationKey));
    const errorText = _get(errors, validationKey ?? name);

    return (
      <RenderTextInput
        ref={ref}
        name={name}
        value={
          formikProps !== undefined && !useCustomValue ? values[name] : value
        }
        onBlur={onBlur}
        onChange={onChange}
        error={isTouched && !!errorText}
        helperText={isTouched ? errorText : undefined}
        {...otherProps}
      />
    );
  },
);

export const RenderTextInput = forwardRef(
  (
    {
      name,
      label,
      type = 'text',
      handleBlur,
      className,
      format,
      slotProps: _slotProps,
      onChange,
      onBlur,
      value,
      error,
      helperText,
      ...otherProps
    },
    ref,
  ) => {
    const { classes, cx } = useStyles();
    const {
      input = {},
      htmlInput = {},
      inputLabel = {},
      ...slotProps
    } = _slotProps || {};

    return (
      <TextField
        variant='standard'
        ref={ref}
        value={value}
        name={name}
        type={type}
        label={label}
        onChange={onChange}
        onBlur={onBlur}
        className={cx(classes.inputRoot, className)}
        error={error}
        helperText={helperText}
        autoComplete='off'
        slotProps={{
          input: {
            inputComponent: format ? NumberFormatter : undefined,
            ...input,
          },
          htmlInput: { format, 'data-lpignore': true, ...htmlInput },
          inputLabel: { className: classes.label, ...inputLabel },
          ...slotProps,
        }}
        {...otherProps}
      />
    );
  },
);

/**
 * @typedef {object} otherProps
 * @property {object} [formikProps]
 * @property {boolean} [useCustomValue]
 * @property {"phone"|"ssn"|"currency"} [format]
 * @property {string|Array<string>} [validationKey] For validation of nested object
 * @property {React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>} [handleChange]
 */
