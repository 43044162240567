import Payment from 'payment';
import axios from 'axios';
import { object, string } from 'yup';
import { normalizeResponseError } from '../../../lib';

const { VITE_QB_PAYMENTS_BASE_URL } = import.meta.env;

// Code from https://codesandbox.io/s/ovvwzkzry9?file=/App.js:3386-3403

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8,
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === 'amex' ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 6)}`;
  }

  return clearValue;
}

export function formatFormData(data) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

export async function getQBPaymentToken(mode, values) {
  const payload =
    mode === modes.CREDIT_CARD
      ? generateCardPayload(values)
      : generateBankAccountPayload(values);

  const { data: qbData, error: qbError } = await axios
    .post(`${VITE_QB_PAYMENTS_BASE_URL}/payments/tokens`, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .catch((err) => {
      return normalizeResponseError('POST', '', {}, false)(err);
    });
  let errorMessage = '';
  if (qbError || !qbData?.value) {
    errorMessage = 'Error calling qb';
    if (Array.isArray(qbError?.errors)) {
      errorMessage = qbError.errors[0]?.message ?? errorMessage;
    }
  }
  return { qbData, errorMessage };
}

function generateCardPayload(state) {
  const { name, isDefault, number, zip, expiry, cvc } = state;

  const expiryValues = expiry?.split('/');

  const card = {
    name,
    cvc,
    default: isDefault,
    number: number?.replace(/\s/g, ''),
  };

  if (Array.isArray(expiryValues)) {
    card.expMonth = expiryValues[0];
    card.expYear = expiryValues[1];
  }

  if (zip) {
    card.address = { postalCode: zip };
  }

  return {
    card,
  };
}

function generateBankAccountPayload(state) {
  const { name, accountNumber, phone, accountType, routingNumber, isDefault } =
    state;

  return {
    bankAccount: {
      name,
      accountNumber,
      phone,
      accountType,
      routingNumber,
      default: isDefault,
    },
  };
}

export function getCCDisplay({ lastFour, cardTypeDisplay = null }) {
  const lastFourDisplay = lastFour ? lastFour?.slice(-8) : '';
  return `${cardTypeDisplay || ''}****${lastFourDisplay}`;
}

export const cardSchema = object().shape({
  name: string().nullable().required('Required'),
  number: string().nullable().required('Required'),
  expiry: string().nullable().required('Required'),
  cvc: string().nullable().required('Required'),
});

export const bankAccountSchema = object().shape({
  name: string().nullable().required('Required'),
  accountNumber: string().nullable().required('Required'),
  phone: string().nullable().required('Required'),
  accountType: string().nullable().required('Required'),
  routingNumber: string().nullable().required('Required'),
});

export const modes = {
  CREDIT_CARD: 'CreditCard',
  BANK_ACCOUNT: 'BankAccount',
};

export const paymentMethodTypes = {
  CHECK: 'check',
  CREDIT_CARD: 'creditCard',
  ACH: 'ach',
  WIRE: 'wire',
  OTHER: 'other',
};
