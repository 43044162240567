import { useCallback, useMemo } from 'react';
import { ButtonGroup, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { scanModes } from '../../lib';
import { useSelector, orderSelectors } from '../../state';
import { AutoCompleteScanner } from '../common';
import { validateSelectedItem } from './orderValidators';

export function ScanItem({
  currentPackage = {},
  itemsRemainingOverride,
  upcSkuObj,
  onScan,
  setScanMode,
  scanMode,
  disablePickAndPackTab,
  disablePickAndStickTab,
  disabled,
  onPickAndStickClick,
  onPickAndPackClick,
  hideBtn,
  labelOverride,
}) {
  const { classes, cx } = styles();
  const originalItems = useSelector(orderSelectors.originalItems);
  const _itemsRemaining = useSelector(orderSelectors.itemsRemaining);
  const itemsCompleted = useSelector(orderSelectors.itemsCompleted);

  const itemsRemaining = itemsRemainingOverride || _itemsRemaining;

  const scanValidatorFunc = useCallback(
    ({ value }) => {
      return validateSelectedItem({
        value,
        originalItems,
        itemsCompleted,
        itemsRemaining,
        currentPackage,
        upcSkuObj,
      });
    },
    [currentPackage, itemsCompleted, itemsRemaining, originalItems, upcSkuObj],
  );

  const orderItemsOptions = useMemo(() => {
    return Object.keys(itemsRemaining).reduce((acc, cur) => {
      const curItem = itemsRemaining[cur];
      if (curItem.quantity > 0) {
        acc.push({
          sku: curItem.sku,
          description: curItem.description,
        });
      }
      return acc;
    }, []);
  }, [itemsRemaining]);

  return (
    <div className={classes.card}>
      {!hideBtn && (
        <ButtonGroup color='primary' className={classes.btnGroup}>
          <Button
            disabled={disablePickAndStickTab}
            onClick={
              onPickAndStickClick ||
              (() => setScanMode(scanModes.PICK_AND_STICK))
            }
            className={cx(classes.tab, {
              [classes.activeTab]: scanMode === scanModes.PICK_AND_STICK,
            })}
          >
            Pick & stick
          </Button>
          <Button
            disabled={disablePickAndPackTab}
            onClick={
              onPickAndPackClick || (() => setScanMode(scanModes.PICK_AND_PACK))
            }
            className={cx(classes.tab, {
              [classes.activeTab]: scanMode === scanModes.PICK_AND_PACK,
            })}
          >
            Pick & pack
          </Button>
        </ButtonGroup>
      )}
      <AutoCompleteScanner
        scanValidatorFunc={scanValidatorFunc}
        onScan={onScan}
        wrapInCard={false}
        options={orderItemsOptions}
        optionsLabelProperties={['sku']}
        optionsValueProperties={['sku']}
        label={labelOverride || 'Scan or select item to add to package'}
        disabled={disabled}
      />
      {/* <SelectScanner
        scanValidatorFunc={scanValidatorFunc}
        onScan={onScan}
        wrapInCard={false}
      >
        {Object.keys(itemsRemaining).map((item) => {
          const { id, sku, description } = itemsRemaining[item];
          return (
            <MenuItem key={id} value={sku}>
              {description}
            </MenuItem>
          );
        })}
      </SelectScanner> */}
    </div>
  );
}

const styles = makeStyles({
  name: 'scanOrder',
})((theme) => ({
  card: {
    maxWidth: 828,
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: '31px 40px',
  },
  input: {
    width: '100%',
    // marginRight: 24,
  },
  tab: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'capitalize',
    padding: '5px 10px',
    color: '#000000',
    border: '1px solid #D5D8EC',
  },
  activeTab: {
    color: '#00C4F8',
    border: '1px solid #00C4F8',
  },
  btnGroup: {
    width: 200,
    marginRight: 80,
  },
}));
