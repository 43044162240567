import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({
  name: 'orderBar',
})((theme) => ({
  container: {
    width: 350,
    borderRight: '1px solid #E5E7F5',
    padding: '24px 0',
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 24px',
  },
  topLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  orderNum: {
    fontFamily: 'Montserrat',
    fontSize: 17,
    fontWeight: 500,
    letterSpacing: 0,
    paddingLeft: 8,
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 143,
  },
  actionButton: {
    width: 32,
    height: 32,
    minWidth: 'unset',
    backgroundColor: '#F5F6FE',
  },
  tabContainer: {
    borderBottom: '1px solid #E5E7F5',
    padding: 24,
    paddingBottom: 0,
    // marginBottom: 24,
  },
  tab: {
    minWidth: '33.333%',
    textTransform: 'unset',
    fontFamily: 'Montserrat',
    color: theme.palette.text.primary,
  },
  badgeRoot: {
    padding: '2px 6px',
  },
  badge: {
    backgroundColor: '#FF4F5B',
    padding: '0 3px',
    minWidth: 16,
    height: 16,
    fontFamily: 'Montserrat',
    fontSize: 10,
  },
  backIcon: {
    color: 'inherit',
    marginRight: 7,
  },
}));
