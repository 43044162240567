import { formatISO } from 'date-fns';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IdNamePair } from '../../types/globalTypes';

type CachedType = 'basicCarrierCompanies' | 'carrierPackageTypes';

interface CarrierState {
  basicCarriers: Array<IdNamePair>;
  basicCarrierAccounts: Array<IdNamePair>;
  basicCarrierCompanies: Array<IdNamePair>;
  carrierPackageTypes: Record<string, Array<IdNamePair>>;
  lastFetchedBasicCarrierCompaniesOn: string | null;
  lastFetchedCarrierPackageTypesOn: string | null;
}

const initialState: CarrierState = {
  basicCarriers: [],
  basicCarrierAccounts: [],
  basicCarrierCompanies: [],
  carrierPackageTypes: {},
  lastFetchedBasicCarrierCompaniesOn: null,
  lastFetchedCarrierPackageTypesOn: null,
};

const carriers = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    setBasicCarriers(state, action: PayloadAction<Array<IdNamePair>>) {
      state.basicCarriers = action.payload;
    },
    setBasicCarrierAccounts(state, action: PayloadAction<Array<IdNamePair>>) {
      state.basicCarrierAccounts = action.payload;
    },
    setBasicCarrierCompanies(state, action: PayloadAction<Array<IdNamePair>>) {
      state.basicCarrierCompanies = action.payload;
      state.lastFetchedBasicCarrierCompaniesOn = formatISO(new Date());
    },
    setCarrierPackageTypes(
      state,
      action: PayloadAction<Record<string, Array<IdNamePair>>>,
    ) {
      state.carrierPackageTypes = action.payload;
      state.lastFetchedCarrierPackageTypesOn = formatISO(new Date());
    },
    /**
     * Invalidate the cache so that the next api call will re-fetch the data and update the cache
     * @param state
     * @param action
     */
    invalidateCarrierCache(state, action: PayloadAction<CachedType>) {
      switch (action.payload) {
        case 'basicCarrierCompanies':
          state.lastFetchedBasicCarrierCompaniesOn = null;
          break;
        case 'carrierPackageTypes':
          state.lastFetchedCarrierPackageTypesOn = null;
          break;
      }
    },
  },
});

export const {
  setBasicCarriers,
  setBasicCarrierAccounts,
  setBasicCarrierCompanies,
  setCarrierPackageTypes,
  invalidateCarrierCache,
} = carriers.actions;

export const CarriersReducer = carriers.reducer;
