import React, { memo } from 'react';
import {
  Backdrop, // LinearProgress,
  CircularProgress,
} from '@mui/material';
import { useSelector } from '../../state';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  loadingBackdrop: {
    backgroundColor: 'rgba(255,255,255,0.7)',
    zIndex: theme.zIndex.modal + 100,
  },
  loader: {
    width: '100%',
    textAlign: 'center',
    paddingTop: 30,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 300,
  },
  linearProgress: {
    width: 600,
  },
}));

export const PageLoader = memo<{ appLoading?: boolean }>(
  ({ appLoading = false }) => {
    const { classes } = useStyles();
    const loading = useSelector((state) => state.ui.loading);

    return (
      <Backdrop
        className={classes.loadingBackdrop}
        open={loading || appLoading}
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.container}>
          <CircularProgress
            disableShrink
            size={75}
            thickness={1}
            color='secondary'
          />
          {/* <LinearProgress className={classes.linearProgress} color='secondary' /> */}
        </div>
      </Backdrop>
    );
  },
);

export const Loader = memo(
  ({ size = 24, className }: { size?: number; className?: string }) => {
    const { classes, cx } = useStyles();

    return (
      <div className={cx(classes.loader, className)}>
        <CircularProgress size={size} disableShrink />
      </div>
    );
  },
);
