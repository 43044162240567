import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { SelectInput } from '../inputs';
import { addResource, systemSelectors } from '../../../state';
import { useRefresh } from '../../../hooks';

const initialState = {
  status: '',
};

export function EditOrderForm({ open, handleClose, curData }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  const orderStatusTypes = useSelector(
    systemSelectors.orderStatus,
    shallowEqual,
  );

  useEffect(() => {
    setState({
      status: curData.status ?? '',
    });
  }, [curData.status]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const id = curData.orderId;
      const payload = {
        ...values,
        id,
      };
      const { error } = await dispatch(
        addResource({
          baseUrl: `/orders/${id}`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [curData.orderId, dispatch, handleClose, refresh],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`Order #${curData.externalID}`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      <SelectInput
        name='status'
        formikProps={formik}
        options={orderStatusTypes}
        label='Status'
        className={classes.input}
      />
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));
