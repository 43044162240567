import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedOrder: null,
  originalItems: {},
  itemsRemaining: {},
  itemsCompleted: {},
  physicalItems: {},
  packages: {},
  upcSkuObj: {},
  hasUnsealedPackage: false,
  hasUnsealedPallet: false,
  completedPackagesCount: 0,
  completedItemsQty: 0,
  packageEditId: null,
  rateId: null,
  canOnlyShip: false,
  isReship: false,
  hasPallets: false,
  shouldPromptDocQty: false,
  reshipState: {
    // flags if the default values were overridden
    hasOverrideCarrierInfo: false,
    hasOverrideShipToInfo: false,
    reshipReturnReason: '',
    overrideShouldChargeToCustomer: false,
    warehouseID: '',
    carrierRequestedName: '',
    carrierID: '',
    carrierServiceCode: '',
    fulfillmentType: '',
    shippingSelectionPreference: '',
    thirdPartyAccountNumber: '',
    insuranceCost: '',
    requiresDeliveryConfirmation: 'no',
    shipToCompanyName: '',
    shipToFirstName: '',
    shipToLastName: '',
    shipToAddress1: '',
    shipToAddress2: '',
    shipToCity: '',
    shipToStateID: '',
    shipToZip: '',
    shipToCountryID: '',
    shipToPhone: '',
  },
};

const orders = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setSelectedOrder(state, action) {
      state.selectedOrder = action.payload;
    },
    setOriginalItems(state, action) {
      state.originalItems = action.payload;
    },
    setItemsRemaining(state, action) {
      state.itemsRemaining = action.payload;
    },
    setItemsCompleted(state, action) {
      const completedItems = action.payload;
      state.itemsCompleted = completedItems;
      // set the completed QTY that shows in the items tab
      if (completedItems) {
        const itemsQty = Object.keys(completedItems).reduce(
          (acc, cur) => acc + (completedItems[cur].quantity ?? 0),
          0,
        );
        state.completedItemsQty = itemsQty;
      }
    },
    setPackages(state, action) {
      state.packages = action.payload;
    },
    setPhysicalItems(state, action) {
      state.physicalItems = action.payload;
    },
    setUpcSkuObj(state, action) {
      state.upcSkuObj = action.payload;
    },
    setHasUnsealedPackage(state, action) {
      state.hasUnsealedPackage = action.payload;
    },
    setHasUnsealedPallet(state, action) {
      state.hasUnsealedPallet = action.payload;
    },
    setCompletedPackagesCount(state, action) {
      state.completedPackagesCount = action.payload;
    },
    setPackageEditId(state, action) {
      state.packageEditId = action.payload;
    },
    setOrderRateId(state, action) {
      state.rateId = action.payload;
    },
    setCanOnlyShip(state, action) {
      state.canOnlyShip = action.payload;
    },
    setIsReship(state, action) {
      state.isReship = action.payload;
    },
    updateReshipProperties(state, action) {
      state.reshipState = { ...state.reshipState, ...action.payload };
    },
    setShouldPromptDocQty(state, action) {
      state.shouldPromptDocQty = action.payload;
    },
    setHasPallets(state, action) {
      state.hasPallets = action.payload;
    },
    resetOrderFields(state, action) {
      // https://stackoverflow.com/a/66505785
      Object.assign(state, initialState);
    },
  },
});

export const {
  setSelectedOrder,
  setOriginalItems,
  setHasUnsealedPackage,
  setHasUnsealedPallet,
  setItemsCompleted,
  setPackages,
  setPhysicalItems,
  setItemsRemaining,
  setUpcSkuObj,
  setCompletedPackagesCount,
  setPackageEditId,
  setOrderRateId,
  resetOrderFields,
  setCanOnlyShip,
  setIsReship,
  updateReshipProperties,
  setShouldPromptDocQty,
  setHasPallets,
} = orders.actions;
export const ordersReducer = orders.reducer;
