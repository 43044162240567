import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatISO } from 'date-fns';
import { IdNamePair } from '../../types/globalTypes';

interface WarehouseState {
  basicWarehouses: Array<IdNamePair>;
  basicTerminals: Array<IdNamePair>;
  lastFetchedWarehousesOn: string | null;
  lastFetchedTerminalsOn: string | null;
}

type CachedType = 'basicWarehouses' | 'basicTerminals';

const initialState: WarehouseState = {
  basicWarehouses: [],
  basicTerminals: [],
  lastFetchedTerminalsOn: null,
  lastFetchedWarehousesOn: null,
};

const warehouses = createSlice({
  name: 'warehouses',
  initialState,
  reducers: {
    getWarehouses(state, action: PayloadAction<Array<IdNamePair>>) {
      state.basicWarehouses = action.payload;
      state.lastFetchedWarehousesOn = formatISO(new Date());
    },
    getTerminals(state, action: PayloadAction<Array<IdNamePair>>) {
      state.basicTerminals = action.payload;
      state.lastFetchedTerminalsOn = formatISO(new Date());
    },
    invalidateWarehouseCache(state, action: PayloadAction<CachedType>) {
      switch (action.payload) {
        case 'basicWarehouses':
          state.lastFetchedWarehousesOn = null;
          break;
        case 'basicTerminals':
          state.lastFetchedTerminalsOn = null;
          break;
      }
    },
  },
});

export const { getWarehouses, getTerminals, invalidateWarehouseCache } =
  warehouses.actions;

export const WarehousesReducer = warehouses.reducer;
