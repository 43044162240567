import { memo, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { format } from 'date-fns';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { Badge, Tooltip } from '@mui/material';

export const DynamicCalendarInput = memo(
  /**
   * A material-ui date picker using formik
   * @param {import("@mui/x-date-pickers/DateCalendar").DateCalendarProps & otherProps} props
   * @returns
   */
  function ({
    isLoading,
    highlightedDays,
    highlightedDaysMappings,
    highlightedDaysMappingsNameKey = 'description',
    ...otherProps
  }) {
    return (
      <DateCalendar
        loading={isLoading}
        renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: HighlightedDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
            highlightedDaysMappings,
            highlightedDaysMappingsNameKey,
          },
        }}
        {...otherProps}
      />
    );
  },
);

function HighlightedDay(props) {
  const {
    highlightedDays = [],
    day,
    highlightedDaysMappings,
    highlightedDaysMappingsNameKey,
    ...other
  } = props;
  const { classes } = useStyles();

  const formattedDay = format(day, 'yyyy-MM-dd');

  const isSelected = useMemo(
    () => highlightedDays.some((d) => d === formattedDay),
    [formattedDay, highlightedDays],
  );

  const dayName =
    highlightedDaysMappings?.[formattedDay]?.[highlightedDaysMappingsNameKey] ??
    '';

  return (
    <Tooltip title={dayName} placement='top'>
      <Badge
        key={props.day.toString()}
        overlap='circular'
        variant='dot'
        classes={{ badge: isSelected ? classes.badge : undefined }}
        badgeContent={isSelected ? ' ' : undefined}
      >
        <PickersDay {...other} day={day} />
      </Badge>
    </Tooltip>
  );
}

const useStyles = makeStyles({ name: { DynamicCalendarInput } })((theme) => ({
  badge: {
    backgroundColor: '#FF4F5B',
    right: '23%',
  },
}));

/**
 * @typedef {object} otherProps
 * @property {Array<Date>} [highlightedDays] An array of dates to highlight witt a badge
 * @property {Record<Date, Record<string, string>>} [highlightedDaysMappings] Used to show tooltip with highlighted date name
 * @property {string} [highlightedDaysMappingsNameKey] The property to use on the `highlightedDaysMappings` to get the day description. Defaults to `description`.
 */
