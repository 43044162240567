import { useState, useCallback, memo } from 'react';
import _get from 'lodash.get';
import { Switch } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import { AlignType, ObjectWithId } from '../../../types';
import { stableObject } from '../../../lib';

interface ChangeParams<TRecord extends ObjectWithId = ObjectWithId> {
  value: boolean;
  record: TRecord;
}

interface Props<TRecord extends ObjectWithId = ObjectWithId> {
  onChange: (params: ChangeParams<TRecord>) => any;
  record?: TRecord;
  className?: string;
  headerClassName?: string;
  cellStyle?: React.CSSProperties;
  source: string;
  label: string;
  disablePadding?: string;
  align?: AlignType;
  sortBy?: string;
  sortable?: false;
}

const SwitchFieldComponent = <TRecord extends ObjectWithId = ObjectWithId>({
  className,
  source,
  record: _record,
  onChange,
  ...rest
}: Props<TRecord>) => {
  const { classes } = useStyles();
  const record = (_record || stableObject) as TRecord;

  const value = _get(record, source);
  const [state, setState] = useState(!!value);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      setState(checked);
      onChange({ value: checked, record });
    },
    [onChange, record],
  );

  return (
    <Switch
      checked={state}
      onChange={handleChange}
      color='primary'
      name='state'
      size='small'
      classes={{
        switchBase: classes.switchBase,
        checked: classes.checked,
        track: classes.track,
      }}
    />
  );
};

export const SwitchField = memo(SwitchFieldComponent) as <
  TRecord extends ObjectWithId = ObjectWithId,
>(
  props: Props<TRecord>,
) => React.ReactElement<any>;

const useStyles = makeStyles({ name: { SwitchFieldComponent } })(
  (theme, _params, classes) => ({
    switchBase: {
      '&.Mui-checked': {
        color: '#061BEE',
      },
      // [`&.Mui-checked + .${classes.track}`]: {
      //   backgroundColor: '#061BEE',
      //   opacity: 0.4,
      // },
    },
    checked: {},
    track: {},
  }),
);
