import { makeStyles } from 'tss-react/mui';

export const styles = makeStyles({
  name: 'detailsTab',
})((theme) => ({
  content: {
    padding: '0 24px',
  },
  client: {
    marginTop: 24,
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0,
    paddingBottom: 4,
  },
  warehouse: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    letterSpacing: 0,
    paddingBottom: 16,
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  group: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    padding: '3px 10px',
    maxWidth: 'fit-content',
    borderRadius: 3,
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: 600,
  },
  editIcon: {
    color: '#D5D8EC',
    cursor: 'pointer',
    fontSize: 18,
  },
  // status: {
  //   ...theme.typography.body2,
  //   letterSpacing: 0,
  //   paddingLeft: 8,
  // },
  divider: {
    borderBottom: '1px solid #E5E7F5',
    margin: '16px 0',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0,
    paddingBottom: 16,
    display: 'flex',
    alignItems: 'center',
  },
  carrierGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: 42,
  },
  carrierLabel: {
    ...theme.typography.body2,
    letterSpacing: 0,
  },
  pickNStickIcon: {
    color: '#FFBD00',
    // color: '#D5D8EC',
    fontSize: 18,
    marginRight: 5,
  },
  carrierTxt: {
    fontSize: 16,
    letterSpacing: 0,
  },
  viewRates: {
    marginTop: 16,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1,
  },
  shipName: {
    marginBottom: 8,
    fontFamily: 'Montserrat',
    fontSize: 14,
    letterSpacing: 0,
    textTransform: 'capitalize',
  },
  shipAddress: {
    ...theme.typography.body2,
    letterSpacing: 0,
    lineHeight: '20px',
  },
  docSection: {
    display: 'flex',
    marginBottom: 16,
  },
  docIcon: {
    color: '#D5D8EC',
    marginRight: 8,
    cursor: 'pointer',
  },
  docName: {
    fontSize: 14,
    letterSpacing: 0,
  },
  docDesc: {
    ...theme.typography.body2,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkIcon: {
    color: '#8FD854',
    fontSize: 18,
  },
  grid: {
    marginTop: 0,
  },
  gridItem: {
    padding: '10px 8px !important',
  },
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: 18,
    marginLeft: 4,
  },
  lastUpdated: {
    color: '#A5AABD',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    letterSpacing: 0,
    marginTop: 4,
  },
}));
