import React, { useState } from 'react';
import { Link } from 'react-router';
import {
  Fab,
  Fade,
  List,
  ListItem,
  ListItemAvatar, // Dialog,
  Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AddIcon, CalculatorIcon } from '../../assets';
import {
  // Navigation,
  generateUrl,
  paths,
  pathParams,
  nestedPages,
} from '../../lib';

export function FrequentlyAccessed() {
  const { classes, cx } = useStyles();

  const [optionsOpen, setOptionsOpen] = useState(false);

  return (
    <div
      onMouseEnter={() => setOptionsOpen(true)}
      onMouseLeave={() => setOptionsOpen(false)}
    >
      <Fade
        in={optionsOpen}
        className={classes.optionsContainer}
        mountOnEnter
        unmountOnExit
      >
        <List disablePadding>
          <ListItem disableGutters>
            <Tooltip title='Get rate estimate' placement='right'>
              <ListItemAvatar>
                <Fab
                  size='small'
                  // onClick={() => handleModals('applicationResident')}
                  color='primary'
                  component={Link}
                  to={generateUrl(paths.GET_SP_ESTIMATE, {
                    [pathParams.NESTED_PAGE]: nestedPages.SP_ESTIMATE,
                  })}
                >
                  <CalculatorIcon className={classes.fabIcon} />
                </Fab>
              </ListItemAvatar>
            </Tooltip>
          </ListItem>
        </List>
      </Fade>
      <Fab className={classes.fab} color='primary' size='large'>
        {
          <AddIcon
            className={cx(classes.addIcon, {
              [classes.rotate]: optionsOpen,
            })}
          />
        }
      </Fab>
    </div>
  );
}

const useStyles = makeStyles()((theme) => ({
  fabPosition: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(2),
    minWidth: 77,
    zIndex: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fab: {
    backgroundColor: '#F91429',
    '&:hover': {
      backgroundColor: '#F91429',
    },
  },
  fabIcon: {
    color: '#FFFFFF',
  },
  addIcon: {
    animation: 'spin 6s linear infinite',
  },
  rotate: {
    transform: 'rotate(45deg)',
  },
  optionsContainer: {
    marginBottom: 15,
    marginLeft: 10,
  },
}));
