import { useCallback, useState } from 'react';
import { makeStyles } from '../muiTheme';
import { TextInput } from '../../components';
import { DirectionType, InputChangeEventType } from '../../types';
import MobileActionForm from './MobileActionForm';
import { MobileModalForm } from './MobileModalForm';

interface MobileAddNoteFormProps {
  open?: boolean;
  title: string;
  subTitle?: string;
  btnText: string;
  cancelBtnText?: string;
  inputText: string;
  isStandAloneModal?: boolean;
  actionDirection?: DirectionType;
  onClose: () => void;
  onCancel?: () => any;
  onSubmit: (note: string) => void | Promise<void>;
}

interface FormProps {
  subTitle?: string;
  note: string;
  inputText: string;
  handleChange: (e: InputChangeEventType) => void;
}

export function MobileAddNoteForm({
  open,
  title,
  subTitle,
  btnText,
  cancelBtnText = 'Cancel',
  inputText,
  isStandAloneModal = true,
  actionDirection,
  onClose,
  onSubmit,
  onCancel,
}: MobileAddNoteFormProps) {
  const { classes } = useStyles();
  const [note, setFormNote] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleChange = useCallback((e: InputChangeEventType) => {
    const { value } = e.target;
    setFormNote(value);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!note) return;
    setSubmitting(true);
    await onSubmit(note);
    setSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  const handleCancel = useCallback(() => {
    onCancel ? onCancel() : onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isStandAloneModal ? (
    <MobileModalForm
      open={open}
      title={title}
      handleCancel={handleCancel}
      handleClose={onClose}
      callback={handleSubmit}
      btnText={btnText}
      cancelBtnText={cancelBtnText}
      submitting={submitting}
      submitBtnClassName={classes.submit}
      actionDirection={actionDirection}
    >
      <RenderForm
        subTitle={subTitle}
        note={note}
        inputText={inputText}
        handleChange={handleChange}
      />
    </MobileModalForm>
  ) : (
    <MobileActionForm
      title={title}
      handleCancel={handleCancel}
      callback={handleSubmit}
      btnText={btnText}
      cancelBtnText={cancelBtnText}
      submitting={submitting}
      submitBtnClassName={classes.submit}
      actionDirection={actionDirection}
    >
      <RenderForm
        subTitle={subTitle}
        note={note}
        inputText={inputText}
        handleChange={handleChange}
      />
    </MobileActionForm>
  );
}

function RenderForm({ subTitle, note, inputText, handleChange }: FormProps) {
  const { classes } = useStyles();
  return (
    <div className={classes.formLayout}>
      {subTitle && <div className={classes.subTitle}>{subTitle}</div>}
      <TextInput
        name='note'
        value={note}
        onChange={handleChange}
        label={inputText}
      />
    </div>
  );
}

const useStyles = makeStyles({ name: { MobileAddNoteForm } })((theme) => ({
  formLayout: {
    display: 'flex',
    flexDirection: 'column',
  },
  subTitle: {
    fontSize: 14,
    letterSpacing: 0,
    marginBottom: 24,
  },
  submit: {
    backgroundColor: '#FF4F5B',
    color: '#FFFFFF',
    ':hover': {
      backgroundColor: '#FF4F5B',
    },
  },
}));
