import { useCallback, useState, useEffect } from 'react';
import { object, string } from 'yup';
import { Grid2 as Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput, SelectInput, AutocompleteInput } from '../inputs';
import {
  useDispatch,
  useSelector,
  addResource,
  systemSelectors,
  getBasicQbModels,
} from '../../../state';
import { useRefresh } from '../../../hooks';

const initialState = {
  companyName: '',
  accountNumber: '',
  qbTermRefID: '',
  address1: '',
  address2: '',
  city: '',
  stateCode: '',
  zip: '',
  country: '',
};

export function QbVendorForm({ open, handleClose }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const qbTerms = useSelector(systemSelectors.basicQbTerms);
  const _countries = useSelector(systemSelectors.countries);
  const _states = useSelector(systemSelectors.states);

  const [submitting, setSubmitting] = useState(false);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    dispatch(getBasicQbModels('Term'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStates(_states.map((s) => ({ id: s.code, name: s.name })));
  }, [_states]);

  useEffect(() => {
    setCountries(_countries.map((c) => ({ id: c.code, name: c.name })));
  }, [_countries]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);

      const { error } = await dispatch(
        addResource({
          baseUrl: '/system/vendors',
          payload: values,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        // update the cache
        dispatch(getBasicQbModels('Vendor', false));
        handleClose();
      }
    },
    [dispatch, handleClose, refresh],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Add QuickBooks vendor</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      <Grid container spacing={5} rowSpacing={2}>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='companyName'
            formikProps={formik}
            label='Company name'
            className={classes.input}
            required
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='accountNumber'
            formikProps={formik}
            label='Account number'
            className={classes.input}
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='address1'
            formikProps={formik}
            label='Address 1'
            className={classes.input}
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='address2'
            formikProps={formik}
            label='Address 2'
            className={classes.input}
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='city'
            formikProps={formik}
            label='City'
            className={classes.input}
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <AutocompleteInput
            formikProps={formik}
            textFieldProps={{
              name: 'stateCode',
              label: 'State',
              autoComplete: 'new-password',
            }}
            autocompleteProps={{
              options: states,
            }}
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <TextInput
            name='zip'
            formikProps={formik}
            label='Zip'
            className={classes.input}
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <AutocompleteInput
            formikProps={formik}
            textFieldProps={{
              name: 'country',
              label: 'Country',
              autoComplete: 'new-password',
            }}
            autocompleteProps={{
              options: countries,
            }}
          />
        </Grid>
        <Grid
          style={{ paddingBottom: 0 }}
          size={{
            sm: 6,
          }}
        >
          <SelectInput
            name='qbTermRefID'
            formikProps={formik}
            options={qbTerms}
            label='Term'
            className={classes.input}
          />
        </Grid>
      </Grid>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    width: '100%',
  },
  item: {
    paddingBottom: 0,
  },
}));

const schema = object().shape({
  companyName: string().required('Required'),
});
