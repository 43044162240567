import React, { useCallback } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
// import { useStyles } from './inputs.styles';

export function SwitchInput({
  formikProps,
  handleChange = undefined,
  name,
  value = undefined,
  label,
  useCustomValue = false,
  disabled = false,
}) {
  // const { classes } = useStyles();
  const { handleChange: formikHandleChange, values } = formikProps || {};

  const onChange = useCallback(
    (e) => {
      formikHandleChange && !useCustomValue && formikHandleChange(e);
      handleChange && handleChange(e);
    },
    [formikHandleChange, handleChange, useCustomValue],
  );

  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            color='primary'
            checked={
              formikProps !== undefined && !useCustomValue
                ? values[name]
                : value
            }
            onChange={onChange}
            name={name}
            label={label}
            disabled={disabled}
          />
        }
        label={label}
      />
    </div>
  );
}
