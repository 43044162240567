import { makeStyles } from 'tss-react/mui';

export const styles = makeStyles({
  name: 'auth',
})((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
  },
  input: {
    width: '100%',
    marginBottom: 18,
  },
  tabletInputs: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 40,
  },
  tabletInput: {
    width: 400,
    marginBottom: 16,
  },
  visibilityIcon: {
    cursor: 'pointer',
    color: theme.palette.text.secondary,
  },
  actions: {
    marginTop: 18,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabletActions: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  // submit: {
  //   width: 96,
  //   height: 40,
  // },
  link: {
    color: theme.palette.primary.main,
    fontSize: 14,
    letterSpacing: 0,
    cursor: 'pointer',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  },
  tabletBtn: {
    width: 400,
    height: 56,
    marginBottom: 24,
  },
}));
