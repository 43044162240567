import React, { useMemo } from 'react';
import { Table } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FormModal } from '.';
import { CurrencyField, DataGrid, TextField } from '../../list';

interface InvoicesPaymentAPI {
  invoiceID: string | number;
  amount: string;
}

export function CustomerPaymentsAppliedToForm({
  handleClose,
  open,
  invoicesPayments,
}: {
  handleClose: () => any;
  open: boolean;
  invoicesPayments: Array<InvoicesPaymentAPI>;
}) {
  const { classes } = useStyles();

  const {
    invoiceRows,
    invoiceSortIds,
  }: {
    invoiceRows: Record<number, any>;
    invoiceSortIds: Array<string>;
  } = useMemo(() => {
    const invoiceSortIds: Array<string> = [];
    const invoiceRows = invoicesPayments.reduce((acc, cur) => {
      const key = cur.invoiceID.toString();
      acc[key] = cur;
      invoiceSortIds.push(key);
      return acc;
    }, {});
    return { invoiceRows, invoiceSortIds };
  }, [invoicesPayments]);

  return (
    <FormModal
      btnText='Close'
      callback={handleClose}
      disableCancel
      handleClose={handleClose}
      open={open}
      title={<span className={classes.title}>Payments Applied To</span>}
    >
      <Table>
        <DataGrid
          bulkActionButtons={false}
          ids={invoiceSortIds}
          rows={invoiceRows}
        >
          <TextField source='invoiceID' label='Invoice ID' sortable={false} />
          <CurrencyField source='amount' label='Amount' sortable={false} />
        </DataGrid>
      </Table>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
}));
