import { memo } from 'react';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { NavLink } from 'react-router';
import { useSelector, uiSelectors } from '../../state';
import { useStyles } from './Sidebar.styles';

interface SidebarItemProps {
  /** The URL path for the NavLink component. */
  to: string;
  /** The text to display for the sidebar item. */
  text: string;
  /** The icon to display for the sidebar item. */
  icon?: React.JSXElementConstructor<any>;
  /** Whether the sidebar item is disabled. */
  disabled?: boolean;
  /** A function to determine if the sidebar item is active. */
  isActive?: () => boolean;
  /** Whether the sidebar item is nested. */
  isNested?: boolean;
  /** A function to handle click events. */
  onClick?: () => void;
}

export const SidebarItem = memo(function SidebarItem({
  to,
  text,
  icon: Icon,
  disabled,
  isActive: _isActive,
  isNested,
  onClick,
}: SidebarItemProps) {
  const { classes, cx } = useStyles();
  const isSidebarOpen = useSelector(uiSelectors.isSidebarOpen);
  const isTabActive = _isActive?.();

  return (
    <NavLink
      to={disabled ? '#' : to}
      // className={({ isActive: routeIsActive }) => {
      //   console.log('routeIsActive', routeIsActive, 'to', to);

      //   return routeIsActive ? classes.activeLi : classes.navLink;
      // }}
      // disabled={disabled}
      className={classes.navLink}
      onClick={onClick}
    >
      {({ isActive }) => (
        <Tooltip title={isSidebarOpen ? '' : text} placement='right'>
          <ListItemButton
            disabled={disabled}
            className={cx({
              [classes.nested]: isNested,
              [classes.nestedSidebarClosed]: isNested && !isSidebarOpen,
              [classes.ListItem]: !isNested,
              [classes.activeRow]: isActive,
            })}
          >
            {Icon && (
              <ListItemIcon>
                <Icon
                  className={cx(classes.icon, {
                    [classes.activeIcon]: isActive || isTabActive,
                  })}
                />
              </ListItemIcon>
            )}
            <ListItemText primary={text} className={classes.liText} />
          </ListItemButton>
        </Tooltip>
      )}
    </NavLink>
  );
});
