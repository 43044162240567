import { isValidElement, cloneElement, useCallback, memo } from 'react';
import { Toolbar, Typography, Tooltip, IconButton, Grow } from '@mui/material';
import { DeleteIcon } from '../../assets';
import { useStyles } from './toolbar.styles';
import { ObjectWithId } from '../../types';

interface Props<TRecord extends ObjectWithId = ObjectWithId> {
  numSelected: number;
  ids: Array<number | string>;
  rows: Record<string, TRecord>;
  selectedIds: Array<number | string>;
  selectedRows: Record<string, TRecord>;
  bulkActionButtons?: React.ReactElement<any> | false;
}

export const ListToolbar = memo(({ bulkActionButtons, ...props }: Props) => {
  const { classes, cx } = useStyles();

  return (
    <Grow in={props.numSelected > 0} mountOnEnter unmountOnExit>
      <Toolbar className={cx(classes.root, classes.highlight)}>
        <Typography
          className={classes.title}
          color='inherit'
          variant='h5'
          component='div'
        >
          {props.numSelected} selected
        </Typography>
        {isValidElement(bulkActionButtons) ? (
          cloneElement(bulkActionButtons, { ...props })
        ) : (
          <BulkDeleteButton selectedIds={props.selectedIds} />
        )}
      </Toolbar>
    </Grow>
  );
});

export const BulkDeleteButton = memo(
  ({ selectedIds }: { selectedIds: Array<number | string> }) => {
    const handleDelete = useCallback(() => {
      // eslint-disable-next-line no-console
      console.log(selectedIds);
      // Implement delete. Optimistic rendering?
    }, [selectedIds]);

    return (
      <Tooltip title='Delete'>
        <IconButton aria-label='delete' onClick={handleDelete} size='large'>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  },
);
