import React, { useCallback, useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { string, object } from 'yup';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput, SelectInput } from '../inputs';
import {
  addResource,
  deleteResource,
  fetchResourceById,
  systemSelectors,
} from '../../../state';
import { convertNullFieldsToEmptyString } from '../../../lib';
// import { ErrorIcon } from '../../../assets';

const initialState = {
  // quantity: '',
  unitOfMeasureCategory: '',
  rate: '',
  size: '',
  measurementType: 'overSizedWeight',
};

export function CustomerFeeRateForm({
  open,
  handleClose,
  customerId,
  feeId,
  discountId,
  feeType,
  setViewVersion,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);
  const [mode, setMode] = useState('uom');

  useEffect(() => {
    (async function () {
      if (discountId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/customers',
            id: customerId,
            path: `fees/${feeId}/rates/${discountId}`,
          }),
        );
        if (data) {
          if (data.oversizedDimension || data.oversizedWeight) {
            setMode('measurement');
          }
          const formatted = getEditData(data);
          setState((cur) => ({
            ...cur,
            ...formatted,
          }));
        }
      }
    })();
  }, [customerId, discountId, dispatch, feeId]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = generatePayload(values, mode);
      const { error } = await dispatch(
        addResource({
          baseUrl: `/customers/${customerId}/fees/${feeId}/rates`,
          payload: { ...payload, qtyNotRequired: feeType !== 'PalletFee' },
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion((cur) => ++cur);
        handleClose();
      }
    },
    [customerId, dispatch, feeId, feeType, handleClose, mode, setViewVersion],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/customers',
        id: customerId,
        path: `fees/${feeId}/rates/${discountId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      setViewVersion((cur) => ++cur);
      handleClose();
    }
  }, [customerId, discountId, dispatch, feeId, handleClose, setViewVersion]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          discountId ? 'Edit' : 'Add'
        } additional rate`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={!!discountId}
      handleDelete={handleDelete}
    >
      <Fragment>
        {!discountId && (
          <ToggleMode mode={mode} setMode={setMode} formik={formik} />
        )}
        {mode === 'uom' && <UnitOfMeasureMode formik={formik} />}
        {mode === 'measurement' && <MeasurementMode formik={formik} />}
        <TextInput
          name='rate'
          formikProps={formik}
          label='Rate'
          className={classes.input}
          format='currency'
          required
        />
      </Fragment>
    </FormModal>
  );
}

function ToggleMode({ formik, mode, setMode }) {
  const { classes, cx } = useStyles();
  const handleChange = useCallback(
    (e) => {
      setMode(e.target.value);
    },
    [setMode],
  );

  return (
    <FormControl
      variant='standard'
      component='fieldset'
      className={cx({ [classes.radios]: mode === 'measurement' })}
    >
      <FormLabel
        component='legend'
        classes={{ root: classes.radioLabel, focused: classes.focused }}
      >
        Mode
      </FormLabel>
      <RadioGroup name='mode' value={mode} onChange={handleChange} row>
        <FormControlLabel
          value='uom'
          control={<Radio color='primary' />}
          label='Unit of measure'
        />
        <FormControlLabel
          value='measurement'
          control={<Radio color='primary' />}
          label='Measurement'
        />
      </RadioGroup>
    </FormControl>
  );
}

// function PalletDiscount({ formik }) {
//   const { classes } = useStyles();

//   return (
//     <Fragment>
//       <TextInput
//         name='quantity'
//         formikProps={formik}
//         label='Min Qty'
//         className={classes.input}
//       />
//       <TextInput
//         name='rate'
//         formikProps={formik}
//         label='Rate'
//         className={classes.input}
//         format='currency'
//         required
//       />
//     </Fragment>
//   );
// }

function UnitOfMeasureMode({ formik }) {
  const { classes } = useStyles();

  const dimensionTypes = useSelector(
    systemSelectors.unitOfMeasureTypes,
    shallowEqual,
  );

  return (
    <SelectInput
      name='unitOfMeasureCategory'
      formikProps={formik}
      options={dimensionTypes}
      label='Unit of measure'
      className={classes.input}
    />
  );
}

function MeasurementMode({ formik }) {
  const { classes } = useStyles();

  return (
    <div className={classes.layout}>
      {/* <div className={classes.warningTextContainer}>
        <ErrorIcon className={classes.errorIcon} />
        <span className={classes.warningText}>
          The rates will be applied in the order they are added
        </span>
      </div> */}
      <div className={classes.smallInputs}>
        <SelectInput
          name='measurementType'
          formikProps={formik}
          options={[
            { id: 'overSizedWeight', name: 'Oversized weight' },
            { id: 'overSizedDimension', name: 'Oversized dimension' },
          ]}
          label='Oversize category'
          className={classes.input}
          style={{ marginRight: 14 }}
        />
        {formik.values.measurementType === 'overSizedDimension' ? (
          <TextInput
            name='size'
            label='Size'
            formikProps={formik}
            type='number'
            slotProps={{
              input: {
                endAdornment: <span>in</span>,
              },
            }}
            style={{ maxWidth: 100 }}
          />
        ) : (
          <TextInput
            name='size'
            label='Weight'
            formikProps={formik}
            type='number'
            slotProps={{
              input: {
                endAdornment: <span>lb</span>,
              },
            }}
            style={{ maxWidth: 100 }}
          />
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    marginTop: '-12px',
    marginBottom: 8,
  },
  warningTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    fontSize: 18,
    color: '#FFBD00',
    background: '#FFFFFF',
    marginRight: 6,
  },
  warningText: {
    color: '#A5AABD',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
  radios: {
    marginBottom: 24,
  },
  radioLabel: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    '&.Mui-focused': {
      color: '#000000',
    },
  },
  focused: {},
  layout: {
    display: 'flex',
    flexDirection: 'column',
  },
  smallInputs: {
    display: 'flex',
  },
}));

function getEditData(data) {
  const {
    dimensionCategoryDisplay,
    dimensionDisplay,
    oversizedDimension,
    oversizedWeight,
    ...rest
  } = data;
  return {
    ...convertNullFieldsToEmptyString(rest),
    size: oversizedDimension ?? oversizedWeight ?? '',
    measurementType: oversizedWeight ? 'overSizedWeight' : 'overSizedDimension',
  };
}

function generatePayload(values, mode) {
  const { unitOfMeasureCategory, size, measurementType, ...rest } = values;
  let rv = {
    ...rest,
    id: values.id || null,
  };
  if (mode === 'measurement') {
    rv = {
      ...rv,
      unitOfMeasureCategory: 'Master',
    };
    if (measurementType === 'overSizedDimension') {
      rv.oversizedDimension = size;
    } else {
      rv.oversizedWeight = size;
    }
  } else {
    rv.unitOfMeasureCategory = unitOfMeasureCategory;
  }
  return rv;
}

const schema = object().shape({
  rate: string().required('Required'),
});
