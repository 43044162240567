import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from 'tss-react/mui';
import { addResource } from '../../../state';
import { FormModal, TextInput } from '../..';
import { useRefresh } from '../../../hooks';

export function StoreOrderForm({
  handleClose,
  open,
  currentLocation,
  externalId,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const [state] = useState({ location: currentLocation ?? '' });
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const { error } = await dispatch(
        addResource({
          baseUrl: `/orders/${externalId}/store`,
          payload: {
            externalID: externalId,
            location: values.location,
          },
          message: 'Changes saved',
        }),
      );

      if (!error) {
        refresh();
        handleClose();
        setSubmitting(false);
      }
    },
    [dispatch, externalId, handleClose, refresh],
  );

  const formik = useFormik({
    initialValues: state,
    // enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={`Store order #${externalId}`}
      callback={formik.handleSubmit}
      paperProps={{ style: { width: 512 } }}
      btnText='Store order'
      disableCancel
      typeSubmit
      submitting={submitting}
    >
      <div className={classes.layout}>
        <TextInput
          name='location'
          formikProps={formik}
          label='Location'
          className={classes.input}
          required
        />
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    color: '#A5AABD',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
  },
  icon: {
    fontSize: 18,
    marginRight: 8,
    color: '#FFBD00',
    background: '#FFFFFF',
  },
}));

const schema = object().shape({
  location: string().nullable().required('Required'),
});
