import { useCallback, Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { SelectInput, TextInput } from '../inputs';
import {
  useDispatch,
  useSelector,
  systemSelectors,
  upsertWarehouseClosedDay,
  deleteWarehouseClosedDay,
} from '../../../state';

const initialValues = {
  description: '',
  type: '',
};

export function EditWarehouseClosedDayForm({
  handleClose,
  open,
  warehouseId,
  date,
  currentData,
  setViewVersion,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const closedDayTypes = useSelector(systemSelectors.warehouseClosedDayTypes);
  const settingsId = currentData?.id;

  const handleSubmit = useCallback(
    async (values) => {
      const { error } = settingsId
        ? await dispatch(deleteWarehouseClosedDay(warehouseId, settingsId))
        : await dispatch(
            upsertWarehouseClosedDay(warehouseId, {
              date,
              ...values,
            }),
          );
      if (!error) {
        setViewVersion((cur) => ++cur);
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settingsId, warehouseId],
  );

  const formik = useFormik({
    initialValues: { ...initialValues, id: settingsId },
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <div className={classes.titleContainer}>
          <span
            className={classes.title}
          >{`Warehouse day settings ${date}`}</span>
          {!!settingsId && (
            <span className={classes.subTitle}>
              Are you sure you want to set this date as open?
            </span>
          )}
        </div>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
    >
      {!settingsId && (
        <Fragment>
          <TextInput
            name='description'
            formikProps={formik}
            label='Description'
            className={classes.input}
          />
          <SelectInput
            name='type'
            formikProps={formik}
            options={closedDayTypes}
            label='Type'
            className={classes.input}
          />
        </Fragment>
      )}
    </FormModal>
  );
}

const schema = object().shape({
  description: string('Must be string').when('id', (id, schema) => {
    return !id ? schema.required('Required') : schema;
  }),
  type: string('Must be string').when('id', (id, schema) => {
    return !id ? schema.required('Required') : schema;
  }),
});

const useStyles = makeStyles()((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    color: '#000000',
    fontSize: 12,
    fontFamily: 'Montserrat',
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));
