import { useCallback, useState, useEffect, Fragment, useMemo } from 'react';
import { object, string } from 'yup';
import { Grid2 as Grid, DialogActions, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import {
  SelectInput,
  SwitchInput,
  TextInput,
  AutocompleteInput,
  RadioInput,
  CheckboxInput,
} from '../inputs';
import {
  useDispatch,
  useSelector,
  addResource,
  systemSelectors,
  getCarrierAccountsList,
  fetchResourceById,
  getCachedBasicCarrierCompaniesList,
  carriersSelectors,
  getBasicCompaniesList,
  getAllCarrierPackageTypes,
  getCarrierList,
} from '../../../state';
import {
  smallParcelBillToPartyOptions,
  freightBillToPartyOptions,
  getCarrierTypeFromFulfillment,
  getPackageTypesOptions,
  uniqueBy,
} from '../../../lib';
import { useRefresh, useNotify } from '../../../hooks';
import { InfoMessage } from '../InfoMessage';
// import { generateBillToContact } from './EditOrderCarrierForm';
// import { ContactFields } from './ContactFields';

const radioInputModes = {
  EXISTING: 'existing',
  MANUAL: 'manual',
};

const initialState = {
  createNewCarrier: false,
  createThirdPartyAccount: false,
  fulfillmentType: '',
  carrierName: '',
  companyID: '',
  carrierID: '',
  carrierCodeMode: radioInputModes.EXISTING,
  carrierServiceCode: '',
  carrierPackageCode: '',
  addCompanyMode: radioInputModes.EXISTING,
  createInternalCompanyName: '',
  internalCompanyID: '',
  freeTextCarrierCode: '',
  shippingSelectionPreference: '',
  billToParty: '',
  thirdPartyAccountNumber: '',
  thirdPartyCountryID: '',
  thirdPartyZip: '',
  insuranceCost: '',
  confirmationType: '',
  requiresDeliveryConfirmation: false,
  containsAlcohol: false,
  deliveredDutyPaid: false,
  nonMachinable: false,
  saturdayDelivery: false,
  dryIce: false,
  dryIceWeight: '',
  // billToContactCompanyName: '',
  // billToContactFirstName: '',
  // billToContactLastName: '',
  // billToContactAddress: '',
  // billToContactCity: '',
  // billToContactStateId: '',
  // billToContactZip: '',
  // billToContactCountryId: '',
};

export function BatchEditForm({
  open,
  handleClose,
  orderIds,
  customerIds,
  uniqueCustomerId,
  hasSelectedCarrier,
  defaultFulfillmentType,
}) {
  const { classes, cx, css } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const notify = useNotify();

  const [showHasCarrierMsg, setShowHasCarrierMsg] =
    useState(hasSelectedCarrier);
  const [submitting, setSubmitting] = useState(false);
  const [carrierAccountsList, setCarrierAccountsList] = useState([]);
  const [carrierCompanyList, setCarrierCompanyList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [freightCarrierCodes, setFreightCarrierCodes] = useState([]);

  const carrierPackageTypes = useSelector(
    carriersSelectors.carrierPackageTypes,
  );
  const companies = useSelector(carriersSelectors.basicCarrierCompanies);
  const fulfillmentTypes = useSelector(systemSelectors.fulfillmentTypes);
  const shippingSelectionPreferenceTypes = useSelector(
    systemSelectors.shippingSelectionPreferenceTypes,
  );
  const deliveryConfirmationTypes = useSelector(
    systemSelectors.deliveryConfirmationTypes,
  );

  const countries = useSelector(systemSelectors.countries);

  useEffect(() => {
    fetchCarrierCompanies();
    dispatch(getAllCarrierPackageTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function () {
      await dispatch(getCachedBasicCarrierCompaniesList());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCarrierAccounts = useCallback(
    async (companyID) => {
      const { data } = await dispatch(
        getCarrierAccountsList({
          companyID,
          customerID: uniqueCustomerId,
          includeTplAccountsForCustomer: true,
        }),
      );
      if (data) {
        setCarrierAccountsList(data);
      }
    },
    [uniqueCustomerId, dispatch],
  );

  const handleConfirmCarrierOverride = useCallback(() => {
    setShowHasCarrierMsg(false);
  }, []);

  const handleSubmit = useCallback(
    async (_values) => {
      setSubmitting(true);
      const {
        billToContactId,
        billToContactPersonId,
        billToContactFirstName,
        billToContactLastName,
        billToContactAddress,
        billToContactCity,
        billToContactStateId,
        billToContactZip,
        billToContactCountryId,
        addCompanyMode,
        carrierCodeMode,
        ...values
      } = _values;
      const payload = {
        ...values,
        orderIDs: orderIds,
        // billToContact: generateBillToContact(_values),
      };
      payload.advancedOptions = generateAdvancedOptions(values);
      const { error, data } = await dispatch(
        addResource({
          baseUrl: `/batches/edit/order-carrier`,
          payload,
        }),
      );
      setSubmitting(false);
      if (!error) {
        if (Array.isArray(data?.messages) && data.messages.length) {
          const msgDisplay = data.messages.map((m, i) => (
            <Fragment key={i}>
              <span>{m}</span>
              <br />
            </Fragment>
          ));
          notify(msgDisplay, 'error');
        } else {
          notify('Changes saved');
          refresh();
          handleClose();
        }
      }
    },
    [dispatch, handleClose, notify, orderIds, refresh],
  );

  const formik = useFormik({
    initialValues: {
      ...initialState,
      fulfillmentType: defaultFulfillmentType ?? '',
    },
    // enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const carrierType = getCarrierTypeFromFulfillment(
    formik.values.fulfillmentType,
  );

  const packageTypesOptions =
    useMemo(() => {
      if (carrierType !== 'SmallParcel') return null;
      let selectedCarrierCodes = [];
      if (formik.values.companyID) {
        selectedCarrierCodes =
          carrierCompanyList
            .filter((c) => c.id === formik.values.companyID)
            .map((c) => c.carrierCode) || [];
      }
      return getPackageTypesOptions(selectedCarrierCodes, carrierPackageTypes);
    }, [
      carrierCompanyList,
      carrierPackageTypes,
      carrierType,
      formik.values.companyID,
    ]) || [];

  const isCreateNewCarrier = formik.values.createNewCarrier;

  useEffect(() => {
    (async () => {
      if (isCreateNewCarrier && !freightCarrierCodes.length) {
        const { data } = await dispatch(
          getCarrierList({
            customerIDs: [uniqueCustomerId],
            includeManualCarriers: true,
            carrierType: 'Freight',
          }),
        );
        if (data) {
          setFreightCarrierCodes(data);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateNewCarrier, uniqueCustomerId]);

  const fetchCarrierCompanies = useCallback(
    (_carrierType) => {
      (async () => {
        const currentCarrierType = _carrierType ?? carrierType;
        const { data } = await dispatch(
          getBasicCompaniesList({
            params: {
              carrierType: currentCarrierType,
              customerIDs: customerIds,
              includeManualCarriers: true,
              includeAllCarrierCodes: true,
              accountOwner:
                currentCarrierType === 'Freight' ? 'TplCustomer' : undefined,
            },
          }),
        );
        if (data) {
          const results = uniqueBy(data, 'id');
          setCarrierCompanyList(results || data);
        }
      })();
    },
    [carrierType, customerIds, dispatch],
  );

  const fetchServices = useCallback(
    async (carrierId) => {
      if (carrierId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/carriers',
            id: carrierId,
            path: 'services/basic',
            query: { carrierType },
          }),
        );
        if (data) {
          setServicesList(data);
        }
      }
    },
    [carrierType, dispatch],
  );

  const { handleChange, ...formikProps } = formik;

  const onChange = async (e) => {
    const { name, value } = e.target;

    if (formik.values[name] === value) return;

    if (name === 'fulfillmentType') {
      formik.setValues(initialState);
      setCarrierAccountsList([]);
      setServicesList([]);
      const newCarrierType = getCarrierTypeFromFulfillment(value);
      if (value && carrierType !== newCarrierType) {
        await fetchCarrierCompanies(newCarrierType);
      }
    }
    if (name === 'companyID') {
      formik.setFieldValue('carrierID', '');
      formik.setFieldValue('carrierServiceCode', '');
      formik.setFieldValue('carrierPackageCode', '');
      setCarrierAccountsList([]);
      setServicesList([]);
      !!value &&
        !formik.values.createNewCarrier &&
        (await fetchCarrierAccounts(value));
    }
    if (name === 'carrierID') {
      formik.setFieldValue('carrierServiceCode', '');
      !!value && (await fetchServices(value));
    }
    if (
      name === 'thirdPartyAccountNumber' &&
      !!formik.values.shippingSelectionPreference
    ) {
      formik.setFieldValue('shippingSelectionPreference', '');
    }
    if (name === 'carrierCodeMode') {
      formik.setFieldValue('freeTextCarrierCode', '');
    }

    handleChange(e);
  };

  function generateAdvancedOptions(values) {
    const {
      containsAlcohol,
      deliveredDutyPaid,
      nonMachinable,
      saturdayDelivery,
      dryIce,
      dryIceWeight,
      billToParty,
    } = values;

    // if there are no currently saved options and they don't add options
    if (
      ![
        containsAlcohol,
        deliveredDutyPaid,
        nonMachinable,
        saturdayDelivery,
        dryIce,
        billToParty,
      ].some((d) => !!d)
    ) {
      return null;
    }

    const rv = {
      containsAlcohol,
      deliveredDutyPaid,
      nonMachinable,
      saturdayDelivery,
      dryIce,
      billToParty,
    };

    if (dryIce) {
      rv.dryIceWeight = dryIceWeight;
    }

    return rv;
  }

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Batch edit</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
      hasCustomActionBtn={showHasCarrierMsg}
    >
      <div className={classes.layout}>
        {showHasCarrierMsg ? (
          <div>
            <InfoMessage message='Order/(s) selected have a specific carrier requested. By continuing to batch edit the carrier will be removed' />
            <p>Are you sure you want to continue to batch edit?</p>
            <DialogActions>
              <Button color='primary' onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button color='primary' onClick={handleConfirmCarrierOverride}>
                OK
              </Button>
            </DialogActions>
          </div>
        ) : (
          <Fragment>
            <InfoMessage
              message={`Changes you make will affect all ${orderIds.length} orders selected`}
            />
            <div className={classes.sectionTitle}>Carrier info</div>
            {carrierType === 'Freight' && (
              <SwitchInput
                name='createNewCarrier'
                label='Create new freight account'
                formikProps={formik}
              />
            )}
            {carrierType === 'Freight' && formik.values.createNewCarrier && (
              <Fragment>
                <RadioInput
                  formikProps={formikProps}
                  handleChange={onChange}
                  name='carrierCodeMode'
                  title='Carrier code mode'
                  controlStyle={{ marginTop: 24 }}
                  radiosRow
                  radios={[
                    { label: 'Select', value: radioInputModes.EXISTING },
                    { label: 'Create', value: radioInputModes.MANUAL },
                  ]}
                />
                <div className={css({ marginBottom: 40 })}>
                  {formik.values.carrierCodeMode ===
                  radioInputModes.EXISTING ? (
                    <AutocompleteInput
                      formikProps={formik}
                      textFieldProps={{
                        name: 'freeTextCarrierCode',
                        label: 'Carrier code',
                        autoComplete: 'new-password',
                        required: true,
                      }}
                      autocompleteProps={{
                        options: freightCarrierCodes,
                      }}
                    />
                  ) : (
                    <TextInput
                      name='freeTextCarrierCode'
                      label='Carrier code'
                      formikProps={formik}
                      className={classes.input}
                      required
                    />
                  )}
                </div>
              </Fragment>
            )}
            <Grid container spacing={5} rowSpacing={2}>
              <Grid
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 6,
                }}
              >
                <SelectInput
                  name='fulfillmentType'
                  formikProps={formikProps}
                  onChange={onChange}
                  options={fulfillmentTypes}
                  label='Fulfillment type'
                  className={classes.input}
                  clearable
                />
              </Grid>
              <Grid
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 6,
                }}
              >
                {formik.values.createNewCarrier ? (
                  <TextInput
                    name='carrierName'
                    formikProps={formikProps}
                    handleChange={onChange}
                    label='Carrier name'
                    className={classes.input}
                    required
                  />
                ) : (
                  <AutocompleteInput
                    formikProps={formik}
                    textFieldProps={{
                      name: 'companyID',
                      label: 'Carrier',
                      autoComplete: 'new-password',
                      required: true,
                      onChange,
                    }}
                    autocompleteProps={{
                      options: carrierCompanyList,
                    }}
                  />
                )}
              </Grid>
              {carrierType === 'SmallParcel' && (
                <Grid
                  style={{ paddingBottom: 0 }}
                  size={{
                    sm: 6,
                  }}
                >
                  <SelectInput
                    name='carrierID'
                    formikProps={formikProps}
                    onChange={onChange}
                    label='Carrier account'
                    className={classes.input}
                    options={carrierAccountsList}
                    clearable
                    required={!!formik.values.companyID}
                    disabled={formik.values.createNewCarrier}
                  />
                </Grid>
              )}
              {carrierType === 'SmallParcel' && (
                <Grid
                  style={{ paddingBottom: 0 }}
                  size={{
                    sm: 6,
                  }}
                >
                  <SelectInput
                    key={formik.values.carrierID}
                    name='carrierServiceCode'
                    formikProps={formikProps}
                    onChange={onChange}
                    label='Service'
                    className={classes.input}
                    options={servicesList}
                    clearable
                    required={!!formik.values.companyID}
                    disabled={formik.values.createNewCarrier}
                  />
                </Grid>
              )}
              {carrierType === 'SmallParcel' && (
                <Grid
                  style={{ paddingBottom: 0 }}
                  size={{
                    sm: 6,
                  }}
                >
                  <SelectInput
                    key={formik.values.carrierID}
                    name='carrierPackageCode'
                    formikProps={formikProps}
                    onChange={onChange}
                    label='Package type'
                    className={classes.input}
                    options={packageTypesOptions}
                    clearable
                  />
                </Grid>
              )}
              <Grid
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 6,
                }}
              >
                <SelectInput
                  name='shippingSelectionPreference'
                  formikProps={formikProps}
                  onChange={onChange}
                  label='Preferred shipping method'
                  className={classes.input}
                  options={shippingSelectionPreferenceTypes}
                  disabled={!!formik.values.thirdPartyAccountNumber}
                  clearable
                />
              </Grid>
              <Grid
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 6,
                }}
              >
                <SelectInput
                  name='billToParty'
                  formikProps={formik}
                  label='Bill to'
                  className={classes.input}
                  options={
                    carrierType === 'SmallParcel'
                      ? smallParcelBillToPartyOptions
                      : freightBillToPartyOptions
                  }
                  clearable
                />
              </Grid>
            </Grid>
            {carrierType === 'SmallParcel' && (
              <Fragment>
                <div className={classes.sectionTitle}>
                  Third party account info
                </div>
                <SwitchInput
                  name='createThirdPartyAccount'
                  label='Create third party account'
                  formikProps={formik}
                />
                <Grid container spacing={5}>
                  <Grid
                    style={{ paddingBottom: 0 }}
                    size={{
                      sm: 6,
                    }}
                  >
                    <TextInput
                      name='thirdPartyAccountNumber'
                      formikProps={formikProps}
                      onChange={onChange}
                      label='Third party account'
                      className={classes.input}
                      required={formik.values.createThirdPartyAccount}
                    />
                  </Grid>
                  {formik.values.createThirdPartyAccount && (
                    <Fragment>
                      <Grid
                        style={{ paddingBottom: 0 }}
                        size={{
                          sm: 6,
                        }}
                      >
                        <AutocompleteInput
                          formikProps={formik}
                          textFieldProps={{
                            name: 'thirdPartyCountryID',
                            label: 'Country code',
                            autoComplete: 'new-password',
                            required: true,
                          }}
                          autocompleteProps={{
                            options: countries,
                          }}
                        />
                      </Grid>
                      <Grid
                        style={{ paddingBottom: 0 }}
                        size={{
                          sm: 6,
                        }}
                      >
                        <TextInput
                          name='thirdPartyZip'
                          formikProps={formikProps}
                          onChange={onChange}
                          label='Zip code'
                          className={classes.input}
                          required
                        />
                      </Grid>
                    </Fragment>
                  )}

                  <Grid
                    style={{ paddingBottom: 0 }}
                    size={{
                      sm: 6,
                    }}
                  >
                    <TextInput
                      name='insuranceCost'
                      formikProps={formikProps}
                      onChange={onChange}
                      label='Insurance amount'
                      className={classes.input}
                      format='currency'
                    />
                  </Grid>
                  <Grid
                    style={{ paddingBottom: 0 }}
                    size={{
                      sm: 6,
                    }}
                  >
                    <SelectInput
                      name='confirmationType'
                      formikProps={formikProps}
                      onChange={onChange}
                      label='Delivery confirmation type'
                      className={classes.input}
                      options={deliveryConfirmationTypes}
                      clearable
                    />
                  </Grid>
                </Grid>

                <div style={{ marginTop: 40 }}>
                  <CheckboxInput
                    formikProps={formik}
                    checkboxes={[
                      {
                        labelProps: {
                          label: 'Signature required',
                        },
                        checkboxProps: {
                          name: 'requiresDeliveryConfirmation',
                        },
                      },
                    ]}
                  />
                  <CheckboxInput
                    formikProps={formik}
                    checkboxes={[
                      {
                        labelProps: {
                          label: 'Contains alcohol',
                        },
                        checkboxProps: {
                          name: 'containsAlcohol',
                        },
                      },
                    ]}
                  />
                  <CheckboxInput
                    formikProps={formik}
                    checkboxes={[
                      {
                        labelProps: {
                          label: 'Delivered duty paid',
                        },
                        checkboxProps: {
                          name: 'deliveredDutyPaid',
                        },
                      },
                    ]}
                  />
                  <CheckboxInput
                    formikProps={formik}
                    checkboxes={[
                      {
                        labelProps: {
                          label: 'Non machinable',
                        },
                        checkboxProps: {
                          name: 'nonMachinable',
                        },
                      },
                    ]}
                  />
                  <CheckboxInput
                    formikProps={formik}
                    checkboxes={[
                      {
                        labelProps: {
                          label: 'Saturday delivery',
                        },
                        checkboxProps: {
                          name: 'saturdayDelivery',
                        },
                      },
                    ]}
                  />
                  <CheckboxInput
                    formikProps={formik}
                    checkboxes={[
                      {
                        labelProps: {
                          label: 'Dry ice',
                        },
                        checkboxProps: {
                          name: 'dryIce',
                        },
                      },
                    ]}
                  />
                </div>
                {formik.values.dryIce && (
                  <Grid container spacing={5}>
                    <Grid
                      size={{
                        sm: 6,
                      }}
                    >
                      <TextInput
                        name='dryIceWeight'
                        formikProps={formik}
                        label='dry ice weight'
                        className={classes.input}
                        type='number'
                      />
                    </Grid>
                  </Grid>
                )}
              </Fragment>
            )}
            {/* {carrierType === 'Freight' &&
          formik.values.billToParty === 'ThirdParty' && (
            <Fragment>
              <div className={classes.sectionTitle}>Bill to contact</div>
              <ContactFields
                formikProps={formik}
                inputClassName={classes.input}
                inputNamePrefix='billToContact'
              />
            </Fragment>
          )} */}
            {formik.values.createNewCarrier && (
              <Fragment>
                <div
                  className={cx(classes.sectionTitle, css({ marginBottom: 0 }))}
                >
                  Company
                </div>
                <RadioInput
                  formikProps={formik}
                  name='addCompanyMode'
                  title=''
                  controlStyle={{ marginTop: 24 }}
                  radiosRow
                  radios={[
                    {
                      label: 'Select company',
                      value: radioInputModes.EXISTING,
                    },
                    {
                      label: 'Create from code',
                      value: radioInputModes.MANUAL,
                    },
                  ]}
                />
                {formik.values.addCompanyMode === radioInputModes.EXISTING ? (
                  <AutocompleteInput
                    formikProps={formik}
                    textFieldProps={{
                      name: 'internalCompanyID',
                      label: 'Company',
                      required: true,
                      className: classes.input,
                      disabled:
                        formik.values.addCompanyMode === radioInputModes.MANUAL,
                    }}
                    autocompleteProps={{
                      // className: classes.input,
                      options: companies,
                      disabled:
                        formik.values.addCompanyMode === radioInputModes.MANUAL,
                    }}
                  />
                ) : (
                  <TextInput
                    name='createInternalCompanyName'
                    label='Company'
                    formikProps={formik}
                    className={classes.input}
                    required
                  />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 50,
    marginBottom: 16,
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));

const schema = object().shape({
  fulfillmentType: string().nullable(),
  carrierName: string()
    .nullable()
    .when('createNewCarrier', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
  carrierID: string()
    .nullable()
    .when(['companyID', 'fulfillmentType'], {
      is: (companyID, fulfillmentType) => {
        const carrierType = getCarrierTypeFromFulfillment(fulfillmentType);
        return carrierType === 'SmallParcel' && !!companyID;
      },
      then: (schema) => schema.required('Required'),
    }),
  carrierServiceCode: string()
    .nullable()
    .when(['companyID', 'fulfillmentType'], {
      is: (companyID, fulfillmentType) => {
        const carrierType = getCarrierTypeFromFulfillment(fulfillmentType);
        return carrierType === 'SmallParcel' && !!companyID;
      },
      then: (schema) => schema.required('Required'),
    }),
  thirdPartyCountryID: string()
    .nullable()
    .when('createThirdPartyAccount', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
  thirdPartyZip: string()
    .nullable()
    .when('createThirdPartyAccount', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
  thirdPartyAccountNumber: string()
    .nullable()
    .when('createThirdPartyAccount', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
  internalCompanyID: string('Must be string')
    .nullable()
    .when(['createNewCarrier', 'addCompanyMode'], {
      is: (createNewCarrier, addCompanyMode) =>
        !!createNewCarrier && addCompanyMode === radioInputModes.EXISTING,
      then: (schema) => schema.required('Required'),
    }),
  createInternalCompanyName: string('Must be string')
    .nullable()
    .when(['createNewCarrier', 'addCompanyMode'], {
      is: (createNewCarrier, addCompanyMode) =>
        !!createNewCarrier && addCompanyMode === radioInputModes.MANUAL,
      then: (schema) => schema.required('Required'),
    }),
});
