import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
// import { CheckboxInput } from '../inputs';
import { useDispatch, clearOrderPackages } from '../../../state';
import { useGetUsersData, useRefresh } from '../../../hooks';
import { InfoMessage } from '../../../components';

const initialState = {
  shouldBillCustomer: true,
};

export function ClearOrderPackagesForm({ open, handleClose, orderId }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const { isAdmin, isCustomer, isCustomerService, isTruckingSalesPerson } =
    useGetUsersData();
  const hasEditPermission =
    isAdmin || isCustomer || isCustomerService || isTruckingSalesPerson;

  const [adminToken, setAdminToken] = useState();
  const [showManagerLogin, setShowManagerLogin] = useState(!hasEditPermission);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const { error } = await dispatch(
        clearOrderPackages({ orderId, token: adminToken }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [dispatch, orderId, adminToken, refresh, handleClose],
  );

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleSubmit,
    // validationSchema: schema,
  });

  const onAdminLogin = useCallback((data) => {
    setShowManagerLogin(false);
    // setShowConfirmation(true);
    setAdminToken(data);
  }, []);

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Clear order packages</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      showManagerLogin={showManagerLogin}
      onAdminLogin={onAdminLogin}
    >
      <InfoMessage message='By clearing all packaging all package related fees will be removed. If you would like to charge the customer please proceed by adding the fees to the order.' />
      <div>Are you sure you want to clear the orders packages?</div>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
}));
