import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  generateUrl,
  Navigation,
  nestedPages,
  pathParams,
  paths,
  truckingJobTabs,
} from '../../../lib';
import { InfoIcon } from '../../../assets';
import { FormModal } from './FormModal';
import { shallowEqual, useSelector } from 'react-redux';
import { systemSelectors } from '../../../state';

export function CustomerQuoteViewForm({
  handleClose,
  open,
  truckingJobId,
  truckingJobData,
}) {
  const { classes } = useStyles();

  const shipmentLocationTypes = useSelector(
    systemSelectors.shipmentLocationTypes,
    shallowEqual,
  );

  const { charges, locations } = truckingJobData || {};

  const chargeTypesToDisplay = useMemo(() => {
    return Object.keys(chargeTypes).filter(
      (c) => charges && !charges.includes(c),
    );
  }, [charges]);

  const shipmentLocationTypesToDisplay = useMemo(() => {
    const shipTo = locations?.find((l) => l.sortOrder === 2) || {};
    return shipmentLocationTypes.filter((s) => s.id !== shipTo?.locationType);
  }, [locations, shipmentLocationTypes]);

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Update your shipment:</span>}
      callback={() =>
        Navigation.redirect(
          generateUrl(paths.TRUCKING_JOB_PAGES, {
            [pathParams.TRUCKING_JOB_TAB]: truckingJobTabs.open,
            [pathParams.TRUCKING_JOB_ID]: truckingJobId,
            [pathParams.NESTED_PAGE]: nestedPages.EDIT_TRUCKING_JOB,
          }),
        )
      }
      btnText='Yes, update my shipment'
      cancelBtnText='No'
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
    >
      <div className={classes.layout}>
        <span className={classes.messageContainer}>
          <InfoIcon className={classes.icon} />
          <span>
            If the shipment does not include all the necessary information then
            the estimated rate may be inaccurate.
          </span>
        </span>
        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            Does this job necessitate any accessorials below?
          </div>
          <ul className={classes.list}>
            {chargeTypesToDisplay.map((c) => (
              <li className={classes.listItem} key={c}>
                {chargeTypes[c]}
              </li>
            ))}
          </ul>
        </div>
        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            Is there a specification you would like to include on the ship to
            address?
          </div>
          <ul className={classes.list}>
            {shipmentLocationTypesToDisplay.map((s, i) => (
              <li key={i} className={classes.listItem}>
                {s?.name}
              </li>
            ))}
          </ul>
        </div>
        <div className={classes.section}>
          <div className={classes.sectionHeader}>Are any of your packages:</div>
          <ul className={classes.list}>
            <li className={classes.listItem}>Hazmat</li>
            <li className={classes.listItem}>Stackable</li>
          </ul>
        </div>
      </div>
    </FormModal>
  );
}

const chargeTypes = {
  ArrivalNotice: 'Arrival notice',
  ArrivalSchedule: 'Arrival schedule',
  LiftgatePickup: 'Liftgate pickup',
  LiftgateDelivery: 'Liftgate delivery',
  ProtectFromFreezing: 'Protect from freezing',
  InsidePickup: 'Inside pickup',
  InsideDelivery: 'Inside delivery',
  SortAndSegregate: 'Sort and segregate',
};

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  icon: {
    fontSize: 18,
    marginBottom: -4,
    marginRight: 8,
    color: '#FFBD00',
    background: '#FFFFFF',
  },
  messageContainer: {
    color: '#A5AABD',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
  },
  section: {
    marginTop: 40,
  },
  sectionHeader: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 18,
  },
  list: {
    listStyle: 'inside',
    display: 'flex',
    flexFlow: 'wrap',
  },
  listItem: {
    width: 150,
    margin: '8px 0',
  },
}));
