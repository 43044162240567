import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({
  name: 'pack-tab',
})((theme) => ({
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 64,
  },
  fullPageEmptyContainer: {
    paddingTop: 0,
    height: 'calc(100vh - 115px)',
  },
  emptyText: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 8,
  },
  emptyTextFullPage: {
    fontSize: 20,
  },
  emptySubText: {
    ...theme.typography.body2,
    fontFamily: 'Montserrat',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 12,
    width: 200,
    letterSpacing: 0,
    textAlign: 'center',
  },
  emptySubTextFullPage: {
    fontSize: 14,
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 16,
  },
  palletPackageHeaderContainer: {
    paddingBottom: 6,
  },
  subHeader: {
    fontWeight: 'bold',
    marginRight: 7,
  },
  editIcon: {
    color: '#D5D8EC',
    cursor: 'pointer',
    fontSize: 18,
  },
  editIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listContainer: {
    fontFamily: 'Lato',
    minHeight: 170,
    width: '100%',
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  summary: {
    padding: '16px 0',
    minHeight: 0,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 16,
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 6,
  },
  itemQty: {
    fontSize: 14,
    fontWeight: 900,
    marginRight: 8,
  },
  itemSku: {
    fontSize: 14,
  },
  itemDetailsItem: {
    marginRight: 5,
    ...theme.typography.body2,
    letterSpacing: 0,
  },
  itemDivider: {
    color: '#E5E7F5',
    margin: '14px -24px',
  },
  itemMaterialDivider: {
    borderWidth: 1,
    borderColor: '#D5D8EC',
    margin: '14px -12px',
  },
  remainingPickNStickIcon: {
    color: '#FFBD00',
    fontSize: 15,
  },
  dimensions: {
    color: '#A5AABD',
    fontSize: 14,
    letterSpacing: 0,
  },
  lot: {
    fontFamily: 'Lato',
    fontSize: 14,
    marginBottom: 16,
  },
  palletPackage: {
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 600,
  },
  accordion: {
    padding: '0 24px',
    borderBottom: '1px solid rgba(0, 0, 0)',
    '&:before': {
      display: 'none',
    },
  },
  packageDivider: {
    color: '#E5E7F5',
    margin: '14px -12px',
  },
  expanded: {
    paddingBottom: 0,
  },
}));
