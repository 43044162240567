import { memo, useCallback } from 'react';
import _get from 'lodash.get';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useStyles } from './inputs.styles';

export const FormikDateTimeInput = memo(
  /**
   * A material-ui date picker using formik
   * @param {import("@mui/x-date-pickers/DateTimePicker").DateTimePickerProps & otherProps} props
   * @returns
   */
  function ({
    name,
    label,
    type = 'text',
    formikProps,
    handleChange,
    handleBlur,
    className,
    format: _format,
    showSeconds,
    required,
    validationKey,
    textFieldProps: _textFieldProps,
    ...otherProps
  }) {
    const { classes, cx } = useStyles();
    const {
      handleChange: formikHandleChange,
      handleBlur: formikHandleBlur,
      values,
      touched,
      errors,
    } = formikProps;

    const textFieldProps = _textFieldProps || {};

    const onBlur = useCallback(
      (e) => {
        formikHandleBlur(e);
        handleBlur && handleBlur(e);
      },
      [formikHandleBlur, handleBlur],
    );

    const onChange = useCallback(
      (value) => {
        // const value = date ? jsDateToLocalISO8601DateString(date) : null;
        const event = { target: { name, value } };
        formikHandleChange(event);
        handleChange && handleChange(event);
      },
      [formikHandleChange, handleChange, name],
    );

    // On blur touched is set on the name but on submit it's set on the nested object so we need to check both
    const isTouched = !!(_get(touched, name) || _get(touched, validationKey));
    const errorText = _get(errors, validationKey ?? name);

    const format = showSeconds ? 'MM/dd/yyyy hh:mm:ss a' : 'MM/dd/yyyy hh:mm a';
    const views = showSeconds
      ? ['day', 'hours', 'minutes', 'seconds']
      : undefined;

    return (
      <DateTimePicker
        value={values[name] ?? null}
        label={label}
        format={format}
        views={views}
        onChange={onChange}
        slotProps={{
          textField: {
            name,
            variant: 'standard',
            onBlur,
            error: isTouched && !!errorText,
            helperText: isTouched ? errorText : undefined,
            className: cx(classes.inputRoot, className),
            InputLabelProps: { className: classes.label },
            type,
            label,
            required,
            ...textFieldProps,
          },
        }}
        {...otherProps}
      />
    );
  },
);

/**
 * @typedef {object} otherProps
 * @property {object} formikProps
 * @property {import("@mui/material/TextField").TextFieldProps} [textFieldProps]
 * @property {string|Array<string>} [validationKey] For validation of nested object
 * @property {boolean} [showSeconds]
 */

// Needed because of https://github.com/mui-org/material-ui-pickers/issues/1526
// function jsDateToLocalISO8601DateString(date) {
//   return [
//     String(date.getFullYear()),
//     String(101 + date.getMonth()).substring(1),
//     String(100 + date.getDate()).substring(1),
//   ].join('-');
// }

// function dateStringToLocalDate(s) {
//   if (!s) return null;
//   return toZonedTime(s);
// }
