import { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { billingStatusStyles } from '../../styles/sharedStyles';
import { useNotify } from '../../hooks';
import {
  authGet,
  formatCurrency,
  formatDate,
  generateUrl,
  nestedPages,
  pathParams,
  paths,
} from '../../lib';
import { useStyles } from './billing.styles';

export function AuditingPayables({ baseUrl, id, viewVersion, getHasBill }) {
  const { classes } = useStyles();
  const { classes: billingStatusClasses, cx } = useBillingStatusStyles();
  const notify = useNotify();

  const [data, setData] = useState([]);

  useEffect(() => {
    (async function () {
      if (id) {
        const { data, error } = await authGet(
          `${baseUrl}/${id}/fees/carrier-bills`,
        );
        if (error) {
          return notify(error.message || 'Error getting carrier bills');
        }
        setData(data);
        typeof getHasBill === 'function' && getHasBill(!!data.length);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUrl, notify, id, viewVersion]);

  return (
    <>
      <div className={classes.title}>Accounts Payable</div>
      {data.length > 0 ? (
        <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
          {data.map((bill) => {
            const {
              billDate,
              billID,
              billStatus,
              billStatusDisplay,
              chargeAmount,
              externalBillID,
              reconciledByID,
              reconciledByFullName,
              reconciledOn,
              reconciliationTypeDisplay,
              shipmentID,
            } = bill;
            return (
              <div className={classes.item} key={billID}>
                <span
                  className={classes.itemTitle}
                >{`Carrier bill #${externalBillID}`}</span>
                <div className={classes.itemStatus}>
                  <span
                    className={cx(
                      classes.dot,
                      billingStatusClasses[billStatus],
                    )}
                  />
                  <span>{billStatusDisplay}</span>
                </div>
                <span className={classes.itemInfo}>
                  Bill total: {formatCurrency(chargeAmount)}
                </span>
                <span className={classes.itemInfo}>{formatDate(billDate)}</span>
                {(reconciledByID || reconciliationTypeDisplay) && (
                  <>
                    <span
                      className={classes.itemInfo}
                    >{`Reconciled ${formatDate(
                      reconciledOn,
                    )}: ${reconciledByFullName ?? ''}`}</span>
                    <span
                      className={classes.itemInfo}
                    >{`Decision: ${reconciliationTypeDisplay}`}</span>
                  </>
                )}
                {shipmentID && (
                  <Link
                    color='primary'
                    href={generateUrl(paths.BILL_DETAILS_PAGE, {
                      [pathParams.BILL_ID]: billID,
                      [pathParams.NESTED_PAGE]: nestedPages.BILL_DETAILS,
                    })}
                    underline='none'
                    target='_blank'
                    className={classes.viewLink}
                  >
                    View
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className={classes.emptyState}>No Bills</div>
      )}
    </>
  );
}

const useBillingStatusStyles = makeStyles()((theme) => ({
  ...billingStatusStyles,
}));
