import { AuthResponseError, anonGet, authGet } from '../../lib';
import {
  setBasicCarrierAccounts,
  setBasicCarrierCompanies,
  setBasicCarriers,
  setCarrierPackageTypes,
  showError,
} from '../features';
import type { AppThunk, NormalizeReturn } from '../types';
import type { IdNamePair } from '../../types/globalTypes';
import type {
  CarrierAccountOwnerType,
  CarrierType,
} from '../../types/carrierTypes';
import { CompanyType } from '../../types';
import { addHours, isBefore, parseISO } from 'date-fns';

interface BasicCarrierFilters {
  includeManualCarriers?: boolean;
  hasGenerateManifestRequirement?: boolean;
  customerIDs?: Array<number>;
  carrierType?: CarrierType;
  accountOwner?: CarrierAccountOwnerType;
}

interface BasicCarrierAccountsFilters {
  text?: string;
  code?: string;
  displayName?: string;
  accountOwner?: CarrierAccountOwnerType;
  carrierType?: CarrierType;
  customerID?: number | string;
  customerOwnedAccountCustomerID?: number | string;
  isIntegrated?: boolean;
  includeTplAccountsForCustomer?: boolean;
  companyID?: number | string;
}

interface BasicCompanyFilters extends BasicCarrierFilters {
  companyType?: CompanyType;
  /**
   * If true the list won't be unique by company so if there are multiple codes per company it will be returned. Defaults to false.
   */
  includeAllCarrierCodes?: boolean;
}

interface BasicCompanyListParams {
  params?: BasicCompanyFilters;
  getAnonymous?: boolean;
}

/**
 * Get a list of carrier names and codes
 * @returns {normalizeReturn}
 */
function getCarrierList(
  params?: BasicCarrierFilters,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<Array<IdNamePair>>([
      'carriers/basic',
      params,
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    // don't update global state if there are filters
    if (!params) {
      dispatch(setBasicCarriers(data));
    }
    return { data };
  };
}

function getCarrierListAnonymousUser(
  params?: BasicCarrierFilters,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch) => {
    const { data, error } = await anonGet<Array<IdNamePair>>([
      'carriers/basic/anonymous-user',
      params,
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    // don't update global state if there are filters
    if (!params) {
      dispatch(setBasicCarriers(data));
    }
    return { data };
  };
}

function getBasicCompaniesList(
  functionParams?: BasicCompanyListParams,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch) => {
    const { getAnonymous, params } = functionParams || {};

    let data: Array<IdNamePair> = [];
    let error: AuthResponseError | undefined = undefined;

    if (getAnonymous) {
      const { data: _data, error: _error } = await anonGet<Array<IdNamePair>>([
        'accounts/companies/basic',
        params,
      ]);
      data = _data;
      error = _error;
    } else {
      const { data: _data, error: _error } = await authGet<Array<IdNamePair>>([
        'accounts/companies/basic',
        params,
      ]);
      data = _data;
      error = _error;
    }

    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

function getCachedBasicCarrierCompaniesList(
  getAnonymous = false,
  ignoreCache = false,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch, getState) => {
    const {
      carriers: {
        basicCarrierCompanies,
        lastFetchedBasicCarrierCompaniesOn,
      } = {},
    } = getState();
    if (
      !ignoreCache &&
      basicCarrierCompanies?.length &&
      !!lastFetchedBasicCarrierCompaniesOn &&
      isBefore(
        new Date(),
        addHours(parseISO(lastFetchedBasicCarrierCompaniesOn), 1),
      )
    ) {
      return {
        data: basicCarrierCompanies,
      };
    }
    const param = { companyType: CompanyType.Carrier };
    let data: Array<IdNamePair> = [];
    let error: AuthResponseError | undefined = undefined;

    if (getAnonymous) {
      const { data: _data, error: _error } = await anonGet<Array<IdNamePair>>([
        'accounts/companies/basic',
        param,
      ]);
      data = _data;
      error = _error;
    } else {
      const { data: _data, error: _error } = await authGet<Array<IdNamePair>>([
        'accounts/companies/basic',
        param,
      ]);
      data = _data;
      error = _error;
    }

    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    dispatch(setBasicCarrierCompanies(data));
    return { data };
  };
}

/**
 * Get a list of carrier service codes by carrier code
 * @returns {normalizeReturn}
 */
function getCarrierServiceByCodeList(
  carrierCode: string,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<Array<IdNamePair>>([
      '/carriers/services/basic',
      { carrierCode },
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }

    return { data };
  };
}

/**
 * Get a list of carrier accounts
 * @returns {normalizeReturn}
 */
function getCarrierAccountsList(
  params?: BasicCarrierAccountsFilters,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<Array<IdNamePair>>([
      `carriers/basic/accounts`,
      params,
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    // don't update global state if there are filters
    if (!params) {
      dispatch(setBasicCarrierAccounts(data));
    }
    return { data };
  };
}

/**
 * Get a carrier by id
 * @param {string} carrierId
 * @returns {normalizeReturn}
 */
function getCarrierById(
  carrierId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(`carriers/${carrierId}`);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

/**
 * Get a list of carrier services by id
 * @param {string} carrierId
 * @returns {normalizeReturn}
 */
function getCarrierServicesById(
  carrierId: number | string,
  params?: object,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet([
      `carriers/${carrierId}/services`,
      params,
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

// /**
//  * Process an order
//  * @param {object} params
//  * @param {object} params.payload
//  * @param {object} [params.token] Use if we need an admin token
//  * @returns {normalizeReturn}
//  */
// function getExistingCarrier(carrierId) {
//   return async (dispatch) => {
//     const { data, error = false } = {}; //await authGet(`orders/process/`); // TODO make real
//     if (error) {
//       dispatch(showError({ message: error.message || 'An error occurred' }));
//     }
//     return { data };
//   };
// }

/**
 * Get a list of carrier accounts
 * @returns {normalizeReturn}
 */
function getCarrierRateShopList(
  params?: object,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<Array<IdNamePair>>([
      `carriers/basic/accounts/rate-shop`,
      params,
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

function getAllCarrierPackageTypes(
  ignoreCache = false,
): AppThunk<Promise<NormalizeReturn<Record<string, Array<IdNamePair>>>>> {
  return async (dispatch, getState) => {
    const {
      carriers: { carrierPackageTypes, lastFetchedCarrierPackageTypesOn } = {},
    } = getState();

    if (
      !ignoreCache &&
      carrierPackageTypes?.length &&
      !!lastFetchedCarrierPackageTypesOn &&
      isBefore(
        new Date(),
        addHours(parseISO(lastFetchedCarrierPackageTypesOn), 1),
      )
    ) {
      return {
        data: carrierPackageTypes,
      };
    }
    const { data, error } = await authGet<Record<string, Array<IdNamePair>>>(
      '/carriers/package-types',
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    dispatch(setCarrierPackageTypes(data));
    return { data };
  };
}

export {
  getCarrierList,
  getCarrierListAnonymousUser,
  getCarrierServiceByCodeList,
  getBasicCompaniesList,
  getCachedBasicCarrierCompaniesList,
  getCarrierAccountsList,
  // getExistingCarrier,
  getCarrierById,
  getCarrierServicesById,
  getCarrierRateShopList,
  getAllCarrierPackageTypes,
};
