import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'search' })((theme) => ({
  root: {
    width: '100%',
    padding: 24,
  },
  firstRow: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  customView: {
    display: 'flex',
    alignItems: 'baseline',
  },
  filterBtn: {
    textTransform: 'capitalize',
  },
  filterRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    marginTop: 24,
    flexWrap: 'wrap',
  },
  filterSelect: {
    width: 204,
    marginRight: 24,
  },
  autocomplete: {
    padding: '0 10px',
  },
  statusChip: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    // height: 24,
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 16,
  },
  multiSearch: {
    marginRight: 24,
  },
}));
