export * from './paths';
export * from './AuthRequest';
export * from './routing';
export * from './systemUtils';
export * from './utils';
export * from './EventHandle';
export * from './constants';
export * from './ErrorSentry';

/**
 * A stable object that doesn't get recreated on every render. Used to default props.
 */
export const stableObject = Object.freeze({});
