import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { object, string } from 'yup';
import { Grid2 as Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { SelectInput } from '../inputs';
import {
  addResource,
  fetchResourceById,
  systemSelectors,
} from '../../../state';
import { convertNullFieldsToEmptyString } from '../../../lib';
import { ContactFields } from './ContactFields';

const initialState = {
  reference1: 'OrderNumber',
  reference2: 'ReferenceNumber',
  reference3: 'Sku',
  shipFromContactCompanyName: '',
  shipFromContactFirstName: '',
  shipFromContactLastName: '',
  shipFromContactAddress: '',
  shipFromContactCity: '',
  shipFromContactStateId: '',
  shipFromContactZip: '',
  shipFromContactCountryId: '',
  shipFromContactOfficePhone: '',
};

export function CustomerShippingLabelForm({
  open,
  handleClose,
  customerId,
  carrierId,
  setViewVersion,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  const customerShipmentLabelTypes = useSelector(
    systemSelectors.customerShipmentLabelTypes,
    shallowEqual,
  );

  useEffect(() => {
    (async function () {
      if (carrierId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/customers',
            id: customerId,
            path: `carrier-labels-settings/${carrierId}`,
            showErrorMessage: false,
          }),
        );
        if (data) {
          const {
            labelMessageData,
            shipFromOverrideContact: _shipFromOverrideContact,
            ...rest
          } = data;

          const shipFromContact = _shipFromOverrideContact ?? {};
          const shipFromContactPerson = shipFromContact.person ?? {};
          const shipFromContactAddress = shipFromContactPerson.address ?? {};

          setState((cur) => ({
            ...cur,
            ...rest,
            ...convertNullFieldsToEmptyString({
              reference1: labelMessageData?.reference1,
              reference2: labelMessageData?.reference2,
              reference3: labelMessageData?.reference3,
              shipFromContactCompanyName: shipFromContact.companyName,
              shipFromContactFirstName: shipFromContactPerson.firstName,
              shipFromContactLastName: shipFromContactPerson.lastName,
              shipFromContactOfficePhone: shipFromContactPerson.officePhone,
              shipFromContactAddress: shipFromContactAddress.address1,
              shipFromContactCity: shipFromContactAddress.city,
              shipFromContactStateId: shipFromContactAddress.stateID,
              shipFromContactZip: shipFromContactAddress.zip,
              shipFromContactCountryId: shipFromContactAddress.countryID,
            }),
            shipFromContactId: shipFromContact.id,
            shipFromContactPersonId: shipFromContactPerson.personID,
          }));
        }
      }
    })();
  }, [carrierId, customerId, dispatch]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const {
        reference1,
        reference2,
        reference3,
        shipFromContactCompanyName,
        shipFromContactFirstName,
        shipFromContactLastName,
        shipFromContactAddress,
        shipFromContactCity,
        shipFromContactStateId,
        shipFromContactZip,
        shipFromContactCountryId,
        shipFromContactOfficePhone,
        ...rest
      } = values;
      const { error } = await dispatch(
        addResource({
          baseUrl: `/customers/${customerId}/carrier-labels-settings/${carrierId}`,
          payload: {
            ...rest,
            labelMessageData: {
              reference1,
              reference2,
              reference3,
            },
            shipFromOverrideContact: generateShipFromContact(values),
          },
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion((cur) => ++cur);
        handleClose();
      }
    },
    [carrierId, customerId, dispatch, handleClose, setViewVersion],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Shipping labels settings</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='lg'
      paperProps={{ style: { width: 720 } }}
      submitting={submitting}
    >
      <div className={classes.layout}>
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='reference1'
              formikProps={formik}
              label='Reference Line 1'
              className={classes.input}
              options={customerShipmentLabelTypes}
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='reference2'
              formikProps={formik}
              label='Reference Line 2'
              className={classes.input}
              options={customerShipmentLabelTypes}
            />
          </Grid>
          <Grid
            style={{ paddingBottom: 0 }}
            size={{
              sm: 6,
            }}
          >
            <SelectInput
              name='reference3'
              formikProps={formik}
              label='Reference Line 3'
              className={classes.input}
              options={customerShipmentLabelTypes}
            />
          </Grid>
        </Grid>
        <div className={classes.sectionTitle}>Ship from override</div>
        <ContactFields
          formikProps={formik}
          inputClassName={classes.input}
          inputNamePrefix='shipFromContact'
          includePhone
        />
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 16px 16px 16px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    width: '100%',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 50,
    marginBottom: 16,
  },
}));

const schema = object().shape({
  reference1: string(),
  reference2: string(),
  reference3: string(),
});

function generateShipFromContact(values) {
  const {
    shipFromContactFirstName,
    shipFromContactLastName,
    shipFromContactId,
    shipFromContactPersonId,
    shipFromContactCompanyName,
    shipFromContactAddress,
    shipFromContactCity,
    shipFromContactStateId,
    shipFromContactZip,
    shipFromContactCountryId,
    shipFromContactOfficePhone,
  } = values;

  // if there are no currently saved options and they don't add options
  if (
    ![
      shipFromContactFirstName,
      shipFromContactLastName,
      shipFromContactCompanyName,
      shipFromContactAddress,
      shipFromContactCity,
      shipFromContactStateId,
      shipFromContactZip,
      shipFromContactCountryId,
      shipFromContactOfficePhone,
    ].some((d) => !!d)
  ) {
    return null;
  }

  return {
    id: shipFromContactId,
    companyName: shipFromContactCompanyName,
    person: {
      personID: shipFromContactPersonId,
      firstName: shipFromContactFirstName,
      lastName: shipFromContactLastName,
      officePhone: shipFromContactOfficePhone,
      address: {
        address1: shipFromContactAddress,
        city: shipFromContactCity,
        stateID: shipFromContactStateId,
        zip: shipFromContactZip,
        countryID: shipFromContactCountryId,
      },
    },
  };
}
