import { addHours, isBefore, parseISO } from 'date-fns';
import { authGet } from '../../lib';
import { setBasicUsers, setUsersForActivity, showError } from '../features';

import { AppThunk, NormalizeReturn } from '../types';
import { IdNamePair } from '../../types/globalTypes';
import {
  ContactAPI,
  ContactType,
  JobTitleType,
  UserType,
  UsersForActivityType,
} from '../../types/contactTypes';

interface BasicUserParams {
  warehouseID?: number | string;
  roles?: Array<string>;
  userTypes?: Array<UserType>;
  text?: string;
}

interface BasicContactParams {
  contactType?: ContactType;
  jobTitle?: JobTitleType;
  text?: string;
  companyIDs?: Array<string | number>;
}

export function getUsersBasicList(
  params?: BasicUserParams,
  ignoreCache = false,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch, getState) => {
    const { contacts: { basicUsers, lastFetchedBasicUsersOn } = {} } =
      getState();
    if (
      !ignoreCache &&
      !params &&
      basicUsers?.length &&
      !!lastFetchedBasicUsersOn &&
      isBefore(new Date(), addHours(parseISO(lastFetchedBasicUsersOn), 1))
    ) {
      return {
        data: basicUsers,
      };
    }
    const { data, error } = await authGet<Array<IdNamePair>>([
      '/accounts/users/basic',
      params,
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    // not caching if there ara filters. TODO client side filtering
    if (!params) {
      dispatch(setBasicUsers(data));
    }
    return { data };
  };
}

export function getUsersForActivityList(
  params?: BasicUserParams,
  ignoreCache = false,
): AppThunk<Promise<NormalizeReturn<Array<UsersForActivityType>>>> {
  return async (dispatch, getState) => {
    const {
      contacts: { usersForActivity, lastFetchedUsersForActivityOn } = {},
    } = getState();

    if (
      !ignoreCache &&
      !params &&
      usersForActivity?.length &&
      !!lastFetchedUsersForActivityOn &&
      isBefore(new Date(), addHours(parseISO(lastFetchedUsersForActivityOn), 1))
    ) {
      return {
        data: usersForActivity,
      };
    }
    const { data, error } = await authGet<Array<UsersForActivityType>>([
      '/accounts/users/list/activity',
      params,
    ]);

    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    // not caching if there ara filters. TODO client side filtering
    if (!params) {
      dispatch(setUsersForActivity(data));
    }
    return { data };
  };
}

export function getBasicContacts(
  params?: BasicContactParams,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<Array<IdNamePair>>([
      '/accounts/contacts/basic',
      params,
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }

    return { data };
  };
}

export function getCompanyUsers(
  companyId: number,
  params?: object,
): AppThunk<Promise<NormalizeReturn<Array<ContactAPI>>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<Array<ContactAPI>>([
      `/accounts/companies/${companyId}/company-users`,
      params,
    ]);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }

    return { data };
  };
}

export function getCompanyUsersEmails(
  companyId: number | string,
): AppThunk<Promise<NormalizeReturn<Array<IdNamePair>>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<Array<IdNamePair>>(
      `/accounts/companies/${companyId}/company-users/emails/basic`,
    );
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }

    return { data };
  };
}
