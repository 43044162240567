import * as signalR from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { connectionTypes } from './lib';
import { WeightObject } from './types/globalTypes';
import {
  AlertsConnect,
  DeviceConnect,
  MessagesConnect,
  SignalRGenericMessage,
  SubscribeAlertType,
} from './types/signalRTypes';
import { IHubProtocol } from '@microsoft/signalr';

type SignalrResponseStatus = 'Success' | 'Failure';

type SignalrGenericResponse = {
  Status: SignalrResponseStatus;
  Message: string;
};

const {
  VITE_SIGNALR_DEVICES_URL,
  VITE_SIGNALR_MESSAGES_URL,
  VITE_SIGNALR_ALERTS_URL,
} = import.meta.env;

let deviceConnection: signalR.HubConnection;
let messagesConnection: signalR.HubConnection;
let alertsConnection: signalR.HubConnection;

export async function connectToSignalRDevice({
  token,
  onReadScale,
  onConnectionSuccess,
  onConnectionError,
  onReconnecting,
  onDisconnected,
}: DeviceConnect) {
  deviceConnection = new signalR.HubConnectionBuilder()
    .withUrl(VITE_SIGNALR_DEVICES_URL!, {
      accessTokenFactory: () => token,
    })
    .withAutomaticReconnect()
    .withHubProtocol(new MessagePackHubProtocol() as IHubProtocol)
    .build();

  deviceConnection.on('readScale', function (data: WeightObject) {
    onReadScale(data);
  });

  try {
    await deviceConnection.start();
    onConnectionSuccess(connectionTypes.DEVICES);
    onReconnecting(false, connectionTypes.DEVICES);
  } catch (error) {
    onConnectionError({ ...(error ?? {}) }, connectionTypes.DEVICES);
    console.error(error);
  }

  // Called when reconnecting failed
  deviceConnection.onclose((error) => {
    onDisconnected(connectionTypes.DEVICES);
  });

  // Called when connection was lost and attempting to reconnect
  deviceConnection.onreconnecting((error) => {
    onReconnecting(true, connectionTypes.DEVICES);
  });

  deviceConnection.onreconnected(async (connectionId) => {
    onConnectionSuccess(connectionTypes.DEVICES);
    onReconnecting(false, connectionTypes.DEVICES);
  });
}

export async function subscribeToReadScale(deviceName: string) {
  if (!deviceConnection) return { Status: 'Failure', Message: 'Not connected' };

  try {
    const res: SignalrGenericResponse = await deviceConnection.invoke(
      'SubscribeScale',
      deviceName,
    );
    return res;
  } catch (error) {
    return { Status: 'Failure', Message: 'Something went wrong' };
  }
}

export async function unsubscribeToReadScale() {
  if (!deviceConnection) return { Status: 'Failure', Message: 'Not connected' };
  try {
    const res: SignalrGenericResponse =
      await deviceConnection.invoke('UnsubscribeScale');
    return res;
  } catch (error) {
    return { Status: 'Failure', Message: 'Something went wrong' };
  }
}

export async function connectToSignalRMessages({
  token,
  onOrderBatchMessage,
  onConnectionSuccess,
  onConnectionError,
  onReconnecting,
  onDisconnected,
}: MessagesConnect) {
  messagesConnection = new signalR.HubConnectionBuilder()
    .withUrl(VITE_SIGNALR_MESSAGES_URL!, {
      accessTokenFactory: () => token,
    })
    .withAutomaticReconnect()
    .withHubProtocol(new MessagePackHubProtocol() as IHubProtocol)
    .build();

  messagesConnection.on(
    'sendOrderBatchStatus',
    function (data: Record<string, any>) {
      onOrderBatchMessage(data);
    },
  );

  try {
    await messagesConnection.start();
    onConnectionSuccess(connectionTypes.SYSTEM_MESSAGE);
    onReconnecting(false, connectionTypes.SYSTEM_MESSAGE);
  } catch (error: any) {
    onConnectionError({ ...(error ?? {}) }, connectionTypes.SYSTEM_MESSAGE);
    console.error(error);
  }

  // Called when reconnecting failed
  messagesConnection.onclose((error) => {
    onDisconnected(connectionTypes.SYSTEM_MESSAGE);
  });

  // Called when connection was lost and attempting to reconnect
  messagesConnection.onreconnecting((error) => {
    onReconnecting(true, connectionTypes.SYSTEM_MESSAGE);
  });

  messagesConnection.onreconnected(async (connectionId) => {
    onConnectionSuccess(connectionTypes.SYSTEM_MESSAGE);
    onReconnecting(false, connectionTypes.SYSTEM_MESSAGE);
  });
}

export async function connectToSignalRAlerts({
  token,
  onGenericMessage,
  onConnectionSuccess,
  onConnectionError,
  onReconnecting,
  onDisconnected,
}: AlertsConnect) {
  alertsConnection = new signalR.HubConnectionBuilder()
    .withUrl(VITE_SIGNALR_ALERTS_URL!, {
      accessTokenFactory: () => token,
    })
    .withAutomaticReconnect()
    .withHubProtocol(new MessagePackHubProtocol() as IHubProtocol)
    .build();

  alertsConnection.on(
    'sendGenericMessage',
    function (data: SignalRGenericMessage) {
      onGenericMessage(data);
    },
  );

  try {
    await alertsConnection.start();
    onConnectionSuccess(connectionTypes.SYSTEM_ALERT);
    onReconnecting(false, connectionTypes.SYSTEM_ALERT);
  } catch (error) {
    onConnectionError({ ...(error ?? {}) }, connectionTypes.SYSTEM_ALERT);
    console.error(error);
  }

  // Called when reconnecting failed
  alertsConnection.onclose((error) => {
    onDisconnected(connectionTypes.SYSTEM_ALERT);
  });

  // Called when connection was lost and attempting to reconnect
  alertsConnection.onreconnecting((error) => {
    onReconnecting(true, connectionTypes.SYSTEM_ALERT);
  });

  alertsConnection.onreconnected(async (connectionId) => {
    onConnectionSuccess(connectionTypes.SYSTEM_ALERT);
    onReconnecting(false, connectionTypes.SYSTEM_ALERT);
  });
}

export async function subscribeToAlert(
  type: SubscribeAlertType,
  entityId: number | string | null = null,
): Promise<SignalrGenericResponse> {
  if (!alertsConnection) return { Status: 'Failure', Message: 'Not connected' };
  try {
    const res: SignalrGenericResponse = await alertsConnection.invoke(
      'SubscribeToGenericAlertGroup',
      type,
      entityId,
    );
    return res;
  } catch (error) {
    return { Status: 'Failure', Message: 'Something went wrong' };
  }
}

export async function unsubscribeToAlert(
  type: SubscribeAlertType,
  entityId: number | string | null = null,
): Promise<SignalrGenericResponse> {
  if (!alertsConnection) return { Status: 'Failure', Message: 'Not connected' };
  try {
    const res: SignalrGenericResponse = await alertsConnection.invoke(
      'UnsubscribeToGenericAlertGroup',
      type,
      entityId,
    );
    return res;
  } catch (error) {
    return { Status: 'Failure', Message: 'Something went wrong' };
  }
}
