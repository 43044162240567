import { Fragment } from 'react';
import { paths, generateValidatedParamsUrl } from '../../../lib';
import { DashboardIcon } from '../../../assets';
import { SidebarItem } from '../SidebarItem';

export function ScannerSidebar() {
  return (
    <Fragment>
      <SidebarItem
        to={generateValidatedParamsUrl(paths.MANAGE_PALLETS)}
        text='Home'
        icon={DashboardIcon}
      />
    </Fragment>
  );
}
