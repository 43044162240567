import { useCallback, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import { useDispatch, addResource } from '../../../state';
import { useRefresh } from '../../../hooks';

const initialState = {
  proNumber: '',
};

export function EditTruckingJobShippingForm({
  open,
  handleClose,
  jobId,
  curData,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (curData) {
      setState({ ...curData });
    }
  }, [curData]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = {
        ...values,
        truckingJobID: jobId,
      };
      const { error } = await dispatch(
        addResource({
          baseUrl: `/shippingJobs/${jobId}/update-shipment-info`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [jobId, dispatch, handleClose, refresh],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Edit shipping info</span>}
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
    >
      <TextInput
        name='proNumber'
        formikProps={formik}
        label='Pro #'
        className={classes.input}
      />
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: '100%',
  },
}));
