import { useCallback, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { SelectInput, TextInput } from '../inputs';
import {
  useDispatch,
  addResource,
  deleteResource,
  fetchResourceById,
  useSelector,
  systemSelectors,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { convertNullFieldsToEmptyString } from '../../../lib';
import {
  WarehouseWorkstationAPI,
  WarehouseWorkstationType,
} from '../../../types';

interface Props {
  open: boolean;
  handleClose: () => any;
  warehouseId: string | number;
  workstationId?: number;
}

interface State
  extends Omit<WarehouseWorkstationAPI, 'id' | 'warehouseID' | 'type'> {
  type: WarehouseWorkstationType | '';
}

const initialState: State = {
  name: '',
  type: '',
};

export function WarehouseWorkstationsForm({
  open,
  handleClose,
  warehouseId,
  workstationId,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const workstationTypes = useSelector(
    systemSelectors.warehouseWorkstationTypes,
  );

  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    (async function () {
      if (workstationId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/warehouses',
            id: warehouseId,
            path: `workstations/${workstationId}`,
          }),
        );
        if (data) {
          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString(data),
          }));
        }
      }
    })();
  }, [warehouseId, dispatch, workstationId]);

  const handleSubmit = useCallback(
    async (values: State) => {
      setSubmitting(true);
      const payload = {
        ...values,
      };
      const { error } = await dispatch(
        addResource<WarehouseWorkstationAPI>({
          baseUrl: `/warehouses/${warehouseId}/workstations`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [warehouseId, dispatch, handleClose, refresh],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/warehouses',
        id: warehouseId,
        path: `workstations/${workstationId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, workstationId]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          workstationId ? 'Edit' : 'Add'
        } warehouse workstation`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={!!workstationId}
      handleDelete={handleDelete}
    >
      <TextInput
        name='name'
        formikProps={formik}
        label='Name'
        className={classes.input}
        required
      />
      <SelectInput
        name='type'
        formikProps={formik}
        label='Type'
        options={workstationTypes}
        className={classes.input}
        required
      />
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { WarehouseWorkstationsForm } })(
  (theme) => ({
    title: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 600,
    },
    input: {
      marginBottom: 16,
      width: '100%',
    },
  }),
);

const schema = object().shape({
  name: string().required('Required'),
  type: string().required('Required'),
});
