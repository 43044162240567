import { Fragment, useState, useCallback, useRef, useEffect } from 'react';
import {
  Chip,
  MenuList,
  ListItem,
  ListItemText,
  Switch,
  IconButton,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import { FilterMenu } from '../../';
import { Dropdown } from '../../common';
import { useUpdatePageQueryParams, useGetQueryParams } from '../../../hooks';
import { CloseIcon } from '../../../assets';
import { MouseChangeEventType } from '../../../types';

interface Props {
  label?: string;
  queryParam?: string;
  defaultValue?: boolean;
  filterSortIds?: Array<number>;
  clearable?: boolean;
  className?: string;
}

export const FiltersSwitch = ({
  label = 'Filters',
  queryParam = 'list-filters',
  // filters,
  className,
  defaultValue,
  clearable = true,
  ...rest
}: Props) => {
  const { classes } = useStyles();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { [queryParam]: queryFilter } = useGetQueryParams();

  const [value, setValue] = useState(queryFilter ?? defaultValue ?? false);
  const [open, setOpen] = useState(false);

  const isFirstRender = useRef(true);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = useCallback(
    (event: MouseEvent | TouchEvent | MouseChangeEventType) => {
      if (anchorRef.current?.contains(event.currentTarget as Node)) {
        return;
      }
      setOpen(false);
    },
    [],
  );

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      if (defaultValue) {
        setValue(defaultValue);
      }
      return;
    } else if (queryFilter !== value) {
      setValue(queryFilter ?? false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, queryFilter]);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleChange = useCallback(
    (e) => {
      const checked = e.target.checked;
      updatePageQueryParams({ [queryParam]: checked });
      setValue(checked);
    },
    [queryParam, updatePageQueryParams],
  );

  return (
    <Fragment>
      <Chip
        ref={anchorRef}
        clickable
        color={clearable && value ? 'primary' : 'default'}
        label={label}
        className={classes.chip}
        // onDelete={!!curFilters.length ? handleClearAll : undefined}
        variant='outlined'
        onClick={handleToggle}
      />
      <Dropdown
        ref={anchorRef}
        open={open}
        handleClose={handleClose}
        popperProps={{ style: { zIndex: 1 }, placement: 'bottom-start' }}
      >
        <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
          <ListItem
            secondaryAction={
              <IconButton onClick={handleClose} size='small'>
                <CloseIcon />
              </IconButton>
            }
          >
            <ListItemText primary='' />
          </ListItem>
          <ListItem
            secondaryAction={
              <Switch checked={value} onChange={handleChange} color='primary' />
            }
          >
            <ListItemText primary={label} />
          </ListItem>
        </MenuList>
      </Dropdown>
    </Fragment>
  );
};

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    // So we see the popover when it's empty.
    minWidth: 16,
    minHeight: 16,
    maxWidth: 'calc(100vw - 32px)',
    maxHeight: 'calc(100vh - 32px)',
    width: 300,
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,
  },
  chip: {
    margin: '0 4px 10px 4px',
  },
  text: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 16,
  },
  selectedText: {
    color: '#000000',
  },
  arrowIcon: {
    fontSize: 18,
  },
}));
