import { SvgIcon, SvgIconProps } from '@mui/material';

export { default as CloseIcon } from '@mui/icons-material/Close';
export { default as DashboardIcon } from '@mui/icons-material/Dashboard';
export { default as ListIcon } from '@mui/icons-material/List';
export { default as ListAltIcon } from '@mui/icons-material/ListAlt';
export { default as HistoryIcon } from '@mui/icons-material/History';
export { default as PrintIcon } from '@mui/icons-material/Print';
export { default as ArrowBackIcon } from '@mui/icons-material/ArrowBack';
export { default as ArrowForwardIcon } from '@mui/icons-material/ArrowForward';
export { default as PauseIcon } from '@mui/icons-material/Pause';
export { default as DeleteIcon } from '@mui/icons-material/Delete';
export { default as DeleteOutlineIcon } from '@mui/icons-material/DeleteOutline';
export { default as DescriptionIcon } from '@mui/icons-material/Description';
export { default as CheckCircleIcon } from '@mui/icons-material/CheckCircle';
export { default as EditIcon } from '@mui/icons-material/Edit';
export { default as ExpandMoreIcon } from '@mui/icons-material/ExpandMore';
export { default as ExpandLessIcon } from '@mui/icons-material/ExpandLess';
export { default as VisibilityIcon } from '@mui/icons-material/Visibility';
export { default as VisibilityOffIcon } from '@mui/icons-material/VisibilityOff';
export { default as AddCircleOutlineIcon } from '@mui/icons-material/AddCircleOutline';
export { default as AddCircleIcon } from '@mui/icons-material/AddCircle';
export { default as AddIcon } from '@mui/icons-material/Add';
export { default as AttachMoneyIcon } from '@mui/icons-material/AttachMoney';
export { default as LocalShippingIcon } from '@mui/icons-material/LocalShipping';
export { default as PeopleAltIcon } from '@mui/icons-material/PeopleAlt';
export { default as SettingsIcon } from '@mui/icons-material/Settings';
export { default as FilterListIcon } from '@mui/icons-material/FilterList';
export { default as ClearIcon } from '@mui/icons-material/Clear';
export { default as DoneIcon } from '@mui/icons-material/Done';
export { default as GetAppIcon } from '@mui/icons-material/GetApp';
export { default as SearchIcon } from '@mui/icons-material/Search';
export { default as EmailIcon } from '@mui/icons-material/Email';
export { default as PhoneIcon } from '@mui/icons-material/Phone';
export { default as PhoneIphoneIcon } from '@mui/icons-material/PhoneIphone';
export { default as PersonIcon } from '@mui/icons-material/Person';
export { default as StarIcon } from '@mui/icons-material/Star';
export { default as StarBorderIcon } from '@mui/icons-material/StarBorder';
export { default as ErrorIcon } from '@mui/icons-material/Error';
export { default as ArrowDropDownIcon } from '@mui/icons-material/ArrowDropDown';
export { default as ArrowDropUpIcon } from '@mui/icons-material/ArrowDropUp';
export { default as KeyboardArrowRightOutlinedIcon } from '@mui/icons-material/KeyboardArrowRightOutlined';
export { default as KeyboardArrowLeftOutlinedIcon } from '@mui/icons-material/KeyboardArrowLeftOutlined';
export { default as KeyboardArrowUpIcon } from '@mui/icons-material/KeyboardArrowUp';
export { default as KeyboardArrowDownIcon } from '@mui/icons-material/KeyboardArrowDown';
export { default as MoreVertIcon } from '@mui/icons-material/MoreVert';
export { default as BusinessIcon } from '@mui/icons-material/Business';
export { default as HomeIcon } from '@mui/icons-material/Home';
export { default as WarningOutlinedIcon } from '@mui/icons-material/WarningOutlined';
export { default as RemoveCircleIcon } from '@mui/icons-material/RemoveCircle';
export { default as InfoIcon } from '@mui/icons-material/Info';
export { default as LockOpenOutlinedIcon } from '@mui/icons-material/LockOpenOutlined';
export { default as LockOutlinedIcon } from '@mui/icons-material/LockOutlined';
export { default as NotificationIcon } from '@mui/icons-material/Notifications';
export { default as OpenInNewIcon } from '@mui/icons-material/OpenInNew';
export { default as ClearAllIcon } from '@mui/icons-material/ClearAll';
export { default as HelpOutlineIcon } from '@mui/icons-material/HelpOutline';
export { default as ReturnIcon } from '@mui/icons-material/AssignmentReturn';
export { default as CancelIcon } from '@mui/icons-material/Cancel';
export { default as CachedIcon } from '@mui/icons-material/Cached';
export { default as LabelIcon } from '@mui/icons-material/Label';
export { default as AssessmentIcon } from '@mui/icons-material/Assessment';
export { default as SaveAltOutlinedIcon } from '@mui/icons-material/SaveAltOutlined';
export { default as AccessTimeIcon } from '@mui/icons-material/AccessTime';
export { default as DirectionsBoatIcon } from '@mui/icons-material/DirectionsBoat';
export { default as SendIcon } from '@mui/icons-material/Send';
export { default as EventAvailableIcon } from '@mui/icons-material/EventAvailable';
export { default as FlagIcon } from '@mui/icons-material/Flag';
export { default as RoomIcon } from '@mui/icons-material/Room';
export { default as DoneAllIcon } from '@mui/icons-material/DoneAll';
export { default as CancelCircleIcon } from '@mui/icons-material/Cancel';
export { default as NorthEastIcon } from '@mui/icons-material/CallMade';
export { default as SouthWestIcon } from '@mui/icons-material/CallReceived';
export { default as PausePresentationIcon } from '@mui/icons-material/PausePresentation';
export { default as PinDropIcon } from '@mui/icons-material/PinDrop';
export { default as RvHookupIcon } from '@mui/icons-material/RvHookup';
export { default as LocationOnIcon } from '@mui/icons-material/LocationOn';
export { default as LoopIcon } from '@mui/icons-material/Loop';
export { default as CreditCardIcon } from '@mui/icons-material/CreditCard';
export { default as ShoppingBagIcon } from '@mui/icons-material/ShoppingBag';
export { default as AccountBalanceWalletIcon } from '@mui/icons-material/AccountBalanceWallet';
export { default as PieChartIcon } from '@mui/icons-material/PieChart';
export { default as SummarizeIcon } from '@mui/icons-material/Summarize';
export { default as PanToolOutlinedIcon } from '@mui/icons-material/PanToolOutlined';
export { default as StopIcon } from '@mui/icons-material/Stop';
export { default as PlayArrowIcon } from '@mui/icons-material/PlayArrow';
export { default as ReceiptLongIcon } from '@mui/icons-material/ReceiptLong';
export { default as ContentCopyIcon } from '@mui/icons-material/ContentCopy';
export { default as VerifiedIcon } from '@mui/icons-material/Verified';
export { default as CameraAltIcon } from '@mui/icons-material/CameraAlt';
export { default as FlipCameraAndroidIcon } from '@mui/icons-material/FlipCameraAndroid';
export { default as TouchAppIcon } from '@mui/icons-material/TouchApp';
export { default as CalendarTodayIcon } from '@mui/icons-material/CalendarToday';
export { default as StickyNote2Icon } from '@mui/icons-material/StickyNote2';
export { default as TimerOutlinedIcon } from '@mui/icons-material/TimerOutlined';
export { default as AccountCircleIcon } from '@mui/icons-material/AccountCircle';
export { default as DirectionsWalkIcon } from '@mui/icons-material/DirectionsWalk';
export { default as MoreHorizIcon } from '@mui/icons-material/MoreHoriz';
export { default as ReportProblemIcon } from '@mui/icons-material/ReportProblem';
export { default as TaskAltIcon } from '@mui/icons-material/TaskAlt';
export { default as Grid3x3Icon } from '@mui/icons-material/Grid3x3';
export { default as HeightIcon } from '@mui/icons-material/Height';
export { default as CropSquareIcon } from '@mui/icons-material/CropSquare';
export { default as ChevronRightIcon } from '@mui/icons-material/ChevronRight';
export { default as LinkIcon } from '@mui/icons-material/Link';
export { default as LinkOffIcon } from '@mui/icons-material/LinkOff';
export { default as RadioButtonUncheckedIcon } from '@mui/icons-material/RadioButtonUnchecked';
export { default as RefreshIcon } from '@mui/icons-material/Refresh';

function PickNStickIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d='M19,3H4.99C3.89,3,3,3.9,3,5l0.01,14c0,1.1,0.89,2,1.99,2h10l6-6V5C21,3.9,20.1,3,19,3z M7,8h10v2H7V8z M12,14H7v-2h5V14z M14,19.5V14h5.5L14,19.5z' />
    </SvgIcon>
  );
}

function QuotesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d='M14,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2V8L14,2z M15,12h-4v1h3c0.55,0,1,0.45,1,1v3 c0,0.55-0.45,1-1,1h-1v1h-2v-1H9v-2h4v-1h-3c-0.55,0-1-0.45-1-1v-3c0-0.55,0.45-1,1-1h1V9h2v1h2V12z M13,8V3.5L17.5,8H13z' />
    </SvgIcon>
  );
}

function CustomersIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d='M20 0H4v2h16V0zM4 24h16v-2H4v2zM20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 2.75c1.24 0 2.25 1.01 2.25 2.25s-1.01 2.25-2.25 2.25S9.75 10.24 9.75 9 10.76 6.75 12 6.75zM17 17H7v-1.5c0-1.67 3.33-2.5 5-2.5s5 .83 5 2.5V17z' />
    </SvgIcon>
  );
}

function AuditIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d='M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2V8L14,2z M15.04,19.45l-1.88-1.88 c-1.33,0.71-3.01,0.53-4.13-0.59c-1.37-1.37-1.37-3.58,0-4.95c1.37-1.37,3.58-1.37,4.95,0c1.12,1.12,1.31,2.8,0.59,4.13l1.88,1.88 L15.04,19.45z M13,9V3.5L18.5,9H13z' />
    </SvgIcon>
  );
}

function CarrierAccountIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g>
        <circle cx='10' cy='8' r='4' />
        <path d='M10.67,13.02C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26 C10.47,18.87,10,17.49,10,16C10,14.93,10.25,13.93,10.67,13.02z' />
        <path d='M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z' />
      </g>
    </SvgIcon>
  );
}

function MaterialsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d='M13 13v8h8v-8h-8zM3 21h8v-8H3v8zM3 3v8h8V3H3zm13.66-1.31L11 7.34 16.66 13l5.66-5.66-5.66-5.65z' />
    </SvgIcon>
  );
}

function FaxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z' />
      </g>
    </SvgIcon>
  );
}

function NonUserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g>
        <path d='M8.65,5.82C9.36,4.72,10.6,4,12,4c2.21,0,4,1.79,4,4c0,1.4-0.72,2.64-1.82,3.35L8.65,5.82z M20,17.17 c-0.02-1.1-0.63-2.11-1.61-2.62c-0.54-0.28-1.13-0.54-1.77-0.76L20,17.17z M21.19,21.19L2.81,2.81L1.39,4.22l8.89,8.89 c-1.81,0.23-3.39,0.79-4.67,1.45C4.61,15.07,4,16.1,4,17.22V20h13.17l2.61,2.61L21.19,21.19z' />
      </g>
    </SvgIcon>
  );
}

function InventoryIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g>
        <path d='M0 0h24v24H0V0z' fill='none' />
        <path d='M20 2H4c-1 0-2 .9-2 2v3.01c0 .72.43 1.34 1 1.69V20c0 1.1 1.1 2 2 2h14c.9 0 2-.9 2-2V8.7c.57-.35 1-.97 1-1.69V4c0-1.1-1-2-2-2zm-5 12H9v-2h6v2zm5-7H4V4l16-.02V7z' />
      </g>
    </SvgIcon>
  );
}

function CalculatorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g>
        <g>
          <rect fill='none' height='24' width='24' />
        </g>
        <g>
          <g>
            <path d='M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z' />
            <rect height='1.5' width='5' x='6.25' y='7.72' />
            <rect height='1.5' width='5' x='13' y='15.75' />
            <rect height='1.5' width='5' x='13' y='13.25' />
            <polygon points='8,18 9.5,18 9.5,16 11.5,16 11.5,14.5 9.5,14.5 9.5,12.5 8,12.5 8,14.5 6,14.5 6,16 8,16' />
            <polygon points='14.09,10.95 15.5,9.54 16.91,10.95 17.97,9.89 16.56,8.47 17.97,7.06 16.91,6 15.5,7.41 14.09,6 13.03,7.06 14.44,8.47 13.03,9.89' />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

function FilterAltIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g>
        <path d='M0,0h24 M24,24H0' fill='none' />
        <path d='M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z' />
        <path d='M0,0h24v24H0V0z' fill='none' />
      </g>
    </SvgIcon>
  );
}

function UploadIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox='0 0 155 117'>
      <g
        fill='#FFF'
        fillRule='nonzero'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path stroke='#00C4F8' d='M63.031 19.88 55.403 1H8v115h140V19.88z' />
        <path stroke='#1DC3F3' d='M15 25h126v87H15z' />
        <path stroke='#00C4F8' d='M146.791 116H8.213L1 34h153z' />
      </g>
    </SvgIcon>
  );
}

function NoteIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox='0 0 80 102'>
      <path
        d='M79 21.33 62.687 1H1v100h78V28.471H59.02V7.894M16.719 43.631h46.597M16.72 81.936h46.597M16.72 62.766h46.597'
        stroke='#00C4F8'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      />{' '}
    </SvgIcon>
  );
}

function BillIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox='0 0 111 122'>
      <g
        stroke='#1DC3F3'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M38.617 15.98 55.407 1l16.837 14.98M93.58 35.067 110 49.627V120H1V49.627l16.234-14.56' />
        <path d='m1 51 54.477 48L110 51M1 121l39.008-35m30.845 0L110 121' />
        <path d='M94 65V17H18v48' />
      </g>{' '}
    </SvgIcon>
  );
}
function NoEstimateIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox='0 0 45 57'>
      <g
        id='--quotes'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='14.2-Quotes---quote-view-mode'
          transform='translate(-368.000000, -490.000000)'
        >
          <g id='Group-5' transform='translate(314.000000, 491.000000)'>
            <g id='No-estimate-icon' transform='translate(55.000000, 0.000000)'>
              <polyline
                id='Shape'
                stroke='#00C4F8'
                strokeLinecap='round'
                strokeLinejoin='round'
                points='43 11.1818502 34.0071652 0 0 0 0 55 43 55 43 11.1818502 34.0071652 11.1818502 34.0071652 0'
              />
              <path
                d='M9.02711218,38 L33.9728878,38 C34.5401462,38 35,38.4598538 35,39.0271122 C35,39.5943706 34.5401462,40.0542244 33.9728878,40.0542244 L9.02711218,40.0542244 C8.45985378,40.0542244 8,39.5943706 8,39.0271122 C8,38.4598538 8.45985378,38 9.02711218,38 Z'
                id='Path'
                fill='#00C4F8'
                fillRule='nonzero'
              />
              <path
                d='M9.02711218,46 L33.9728878,46 C34.5401462,46 35,46.4598538 35,47.0271122 C35,47.5943706 34.5401462,48.0542244 33.9728878,48.0542244 L9.02711218,48.0542244 C8.45985378,48.0542244 8,47.5943706 8,47.0271122 C8,46.4598538 8.45985378,46 9.02711218,46 Z'
                id='Path-Copy'
                fill='#00C4F8'
                fillRule='nonzero'
              />
              <path
                d='M9.02711218,22 L33.9728878,22 C34.5401462,22 35,22.4598538 35,23.0271122 C35,23.5943706 34.5401462,24.0542244 33.9728878,24.0542244 L9.02711218,24.0542244 C8.45985378,24.0542244 8,23.5943706 8,23.0271122 C8,22.4598538 8.45985378,22 9.02711218,22 Z'
                id='Path'
                fill='#00C4F8'
                fillRule='nonzero'
              />
              <path
                d='M9.02711218,30 L33.9728878,30 C34.5401462,30 35,30.4598538 35,31.0271122 C35,31.5943706 34.5401462,32.0542244 33.9728878,32.0542244 L9.02711218,32.0542244 C8.45985378,32.0542244 8,31.5943706 8,31.0271122 C8,30.4598538 8.45985378,30 9.02711218,30 Z'
                id='Path'
                fill='#00C4F8'
                fillRule='nonzero'
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
function CloseSquareIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d='M3,3v18h18V3H3z M17,15.59L15.59,17L12,13.41L8.41,17L7,15.59L10.59,12L7,8.41L8.41,7L12,10.59L15.59,7L17,8.41L13.41,12 L17,15.59z' />
    </SvgIcon>
  );
}

function HazardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} height='10' width='11.25' viewBox='0 0 576 512'>
      <path d='M173.2 0c-1.8 0-3.5 .7-4.8 2C138.5 32.3 120 74 120 120c0 26.2 6 50.9 16.6 73c-22 2.4-43.8 9.1-64.2 20.5C37.9 232.8 13.3 262.4 .4 296c-.7 1.7-.5 3.7 .5 5.2c2.2 3.7 7.4 4.3 10.6 1.3C64.2 254.3 158 245.1 205 324s-8.1 153.1-77.6 173.2c-4.2 1.2-6.3 5.9-4.1 9.6c1 1.6 2.6 2.7 4.5 3c36.5 5.9 75.2 .1 109.7-19.2c20.4-11.4 37.4-26.5 50.5-43.8c13.1 17.3 30.1 32.4 50.5 43.8c34.5 19.3 73.3 25.2 109.7 19.2c1.9-.3 3.5-1.4 4.5-3c2.2-3.7 .1-8.4-4.1-9.6C379.1 477.1 324 403 371 324s140.7-69.8 193.5-21.4c3.2 2.9 8.4 2.3 10.6-1.3c1-1.6 1.1-3.5 .5-5.2c-12.9-33.6-37.5-63.2-72.1-82.5c-20.4-11.4-42.2-18.1-64.2-20.5C450 170.9 456 146.2 456 120c0-46-18.5-87.7-48.4-118c-1.3-1.3-3-2-4.8-2c-5 0-8.4 5.2-6.7 9.9C421.7 80.5 385.6 176 288 176S154.3 80.5 179.9 9.9c1.7-4.7-1.6-9.9-6.7-9.9zM240 272a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM181.7 417.6c6.3-11.8 9.8-25.1 8.6-39.8c-19.5-18-34-41.4-41.2-67.8c-12.5-8.1-26.2-11.8-40-12.4c-9-.4-18.1 .6-27.1 2.7c7.8 57.1 38.7 106.8 82.9 139.4c6.8-6.7 12.6-14.1 16.8-22.1zM288 64c-28.8 0-56.3 5.9-81.2 16.5c2 8.3 5 16.2 9 23.5c6.8 12.4 16.7 23.1 30.1 30.3c13.3-4.1 27.5-6.3 42.2-6.3s28.8 2.2 42.2 6.3c13.4-7.2 23.3-17.9 30.1-30.3c4-7.3 7-15.2 9-23.5C344.3 69.9 316.8 64 288 64zM426.9 310c-7.2 26.4-21.7 49.7-41.2 67.8c-1.2 14.7 2.2 28.1 8.6 39.8c4.3 8 10 15.4 16.8 22.1c44.3-32.6 75.2-82.3 82.9-139.4c-9-2.2-18.1-3.1-27.1-2.7c-13.8 .6-27.5 4.4-40 12.4z' />
    </SvgIcon>
  );
}

export {
  PickNStickIcon,
  QuotesIcon,
  CustomersIcon,
  AuditIcon,
  CarrierAccountIcon,
  MaterialsIcon,
  FaxIcon,
  NonUserIcon,
  InventoryIcon,
  CalculatorIcon,
  FilterAltIcon,
  UploadIcon,
  NoteIcon,
  BillIcon,
  NoEstimateIcon,
  CloseSquareIcon,
  HazardIcon,
};
