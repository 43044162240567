import { useCallback, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { FormModal } from './FormModal';
import { TextInput } from '../inputs';
import {
  useDispatch,
  addResource,
  deleteResource,
  fetchResourceById,
  updateResource,
} from '../../../state';
import { useRefresh } from '../../../hooks';
import { convertNullFieldsToEmptyString } from '../../../lib';
import { WarehouseAisleAPI } from '../../../types';
import { InfoMessage } from '../InfoMessage';

interface Props {
  open: boolean;
  handleClose: () => any;
  warehouseId: string | number;
  aisleId?: number;
  setViewVersion?: React.Dispatch<React.SetStateAction<number>>;
}

interface EditPositionProps extends Props {
  position: number | string;
}

type State = Omit<WarehouseAisleAPI, 'warehouseID'>;

const initialState: State = {
  name: '',
};

export function WarehouseAisleForm({
  open,
  handleClose,
  warehouseId,
  aisleId,
  setViewVersion,
}: Props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    (async function () {
      if (aisleId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/warehouses',
            id: warehouseId,
            path: `aisles/${aisleId}`,
          }),
        );
        if (data) {
          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString(data),
          }));
        }
      }
    })();
  }, [warehouseId, dispatch, aisleId]);

  const handleSubmit = useCallback(
    async (values: State) => {
      setSubmitting(true);
      const payload: WarehouseAisleAPI = {
        ...values,
        id: values.id || null,
        warehouseID: warehouseId,
      };
      const { error } = await dispatch(
        addResource<WarehouseAisleAPI>({
          baseUrl: `/warehouses/${warehouseId}/aisles`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
        handleClose();
      }
    },
    [warehouseId, dispatch, setViewVersion, refresh, handleClose],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/warehouses',
        id: warehouseId,
        path: `aisles/${aisleId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, aisleId]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          aisleId ? 'Edit' : 'Add'
        } warehouse aisle`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 520 } }}
      submitting={submitting}
      isEdit={!!aisleId}
      handleDelete={handleDelete}
    >
      <TextInput
        name='name'
        formikProps={formik}
        label='Aisle'
        className={classes.input}
      />
    </FormModal>
  );
}

const editInitialState = {
  warehouseAislePosition: '',
};

export function EditWarehouseAislePositionForm({
  open,
  handleClose,
  warehouseId,
  aisleId,
  position,
  setViewVersion,
}: EditPositionProps) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(editInitialState);

  useEffect(() => {
    setState((cur) => ({ ...cur, warehouseAislePosition: position as string }));
  }, [position]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = {
        ...values,
        warehouseAisleID: aisleId,
        warehouseID: warehouseId,
      };
      const { error } = await dispatch(
        updateResource({
          baseUrl: `/warehouses/${warehouseId}/aisles/${aisleId}/positions/${position}`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
        handleClose();
      }
    },
    [
      warehouseId,
      dispatch,
      aisleId,
      position,
      setViewVersion,
      refresh,
      handleClose,
    ],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/warehouses',
        id: warehouseId,
        path: `aisles/${aisleId}/positions/${position}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, aisleId]);

  const formik = useFormik({
    initialValues: { ...editInitialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: editPositionSchema,
  });

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span
          className={classes.title}
        >{`Edit aisle ${aisleId} position ${position}`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 520 } }}
      submitting={submitting}
      isEdit={!!aisleId}
      handleDelete={handleDelete}
    >
      <InfoMessage message='Editing the position will update all locations that were setup in this position.' />
      <TextInput
        name='warehouseAislePosition'
        formikProps={formik}
        label='Position'
        className={classes.input}
      />
    </FormModal>
  );
}

const useStyles = makeStyles({ name: { WarehouseAisleForm } })((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: '100%',
  },
}));

const schema = object().shape({
  name: string().required('Required'),
});

const editPositionSchema = object().shape({
  warehouseAislePosition: string().required('Required'),
});
