import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  Fragment,
} from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { makeStyles } from 'tss-react/mui';
import { FormModal } from './FormModal';
import { addResource, fetchResourceById } from '../../../state';
import { useRefresh } from '../../../hooks';
import { TextInput, RadioInput } from '../..';

const initialState = {
  mode: 'location',
  palletId: '',
  location: '',
  proNumber: '',
};

export function PalletManagementForm({
  open,
  handleClose,
  palletIdProp,
  externalIdProp,
  isLocationForm,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();

  const orderRef = useRef();
  const palletRef = useRef();
  const palletInputRef = useRef();
  const locationInputRef = useRef();
  const proNumRef = useRef();
  const proNumInputRef = useRef();

  const [state, setState] = useState(initialState);
  const [externalId, setExternalId] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const fetchPalletLocation = useCallback(
    (palletId, orderExternalId) => {
      return dispatch(
        fetchResourceById({
          baseUrl: `/orders/${orderExternalId}/order-package`,
          id: palletId,
          path: 'location',
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (palletIdProp && externalIdProp) {
      setExternalId(externalIdProp);
      (async function () {
        const { data } = await fetchPalletLocation(
          palletIdProp,
          externalIdProp,
        );
        if (data) {
          setState((cur) => ({
            ...cur,
            palletId: palletIdProp,
            location: data.location,
          }));
        }
      })();
    }
  }, [dispatch, externalIdProp, fetchPalletLocation, palletIdProp]);

  let counter = 1; // HACK to force resetting the state after submitting

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);

      const payload = {
        orderExternalId: externalId,
        orderPackageID: values.palletId,
        location: values.location,
        proNumber: values.mode === 'dispatch' ? values.proNumber : undefined,
        isPallet: true,
        packages: [
          { orderPackageID: values.palletId, location: values.location },
        ],
      };

      const url =
        values.mode === 'location'
          ? '/orders/order-packages/location'
          : '/orders/freight/dispatch-pallet';
      const { error } = await dispatch(
        addResource({
          baseUrl: url,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        if (isLocationForm) {
          handleClose();
        }
        setState((cur) => ({
          ...initialState,
          mode: values.mode,
          counter: counter++,
        }));
        palletInputRef.current.focus();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, externalId, refresh],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const handleKeyDown = useCallback(
    async (event) => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        event.preventDefault();
        event.stopPropagation();
        const { name, value } = event.target;
        if (!value) {
          return;
        }
        if (name === 'palletId') {
          const { data } = await fetchPalletLocation(value, externalId);
          if (data) {
            formik.setFieldValue('location', data.location ?? '');
            if (formik.values.mode === 'location') {
              locationInputRef?.current.focus();
            } else if (formik.values.mode === 'dispatch') {
              proNumInputRef?.current.focus();
            }
          }
        } else if (name === 'externalId') {
          setExternalId(value);
          palletInputRef.current.focus();
        } else if (name === 'proNumber') {
          formik.setFieldValue('proNumber', value ?? '');
          if (externalId && formik.values.palletId) {
            formik.submitForm();
          }
        }
      }
    },
    [externalId, fetchPalletLocation, formik],
  );

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={<span className={classes.title}>Store & dispatch pallet</span>}
      callback={formik.handleSubmit}
      paperProps={{ style: { width: 468 } }}
      typeSubmit
      submitting={submitting}
    >
      {!isLocationForm && (
        <div style={{ marginBottom: 8 }}>
          <RadioInput
            formikProps={formik}
            name='mode'
            radiosRow
            size='small'
            title='Select mode'
            titleClassName={classes.labelTitle}
            radios={[
              { label: 'Save location', value: 'location' },
              { label: 'Dispatch pallet', value: 'dispatch' },
            ]}
          />
        </div>
      )}
      {!isLocationForm && (
        <TextInput
          label='Order id'
          className={classes.input}
          name='externalId'
          value={externalId}
          style={{ marginTop: 8 }}
          onChange={(e) => setExternalId(e.target.value)}
          onKeyDown={handleKeyDown}
          ref={orderRef}
          required
          autoFocus
        />
      )}
      <Fragment>
        {!isLocationForm && (
          <TextInput
            label='Scan or enter pallet'
            className={classes.input}
            name='palletId'
            formikProps={formik}
            // autoFocus
            required
            ref={palletRef}
            inputRef={palletInputRef} // needed for focus
            onKeyDown={handleKeyDown}
          />
        )}
        {formik.values.mode === 'location' && (
          <TextInput
            label='Location'
            className={classes.input}
            name='location'
            formikProps={formik}
            style={{ marginTop: 8 }}
            inputRef={locationInputRef}
            // onChange={handleInputChange}
            required
          />
        )}
        {formik.values.mode === 'dispatch' && (
          <TextInput
            label='PRO number'
            className={classes.input}
            name='proNumber'
            formikProps={formik}
            style={{ marginTop: 8 }}
            ref={proNumRef}
            inputRef={proNumInputRef}
            onKeyDown={handleKeyDown}
          />
        )}
      </Fragment>
    </FormModal>
  );
}

const useStyles = makeStyles({
  name: { PalletManagementForm },
})((theme) => ({
  card: {
    height: 110,
    width: 468,
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    width: 300,
    marginRight: 24,
  },
}));

const schema = object().shape({
  palletId: string().nullable().required('Required'),
  location: string()
    .nullable()
    .when('mode', {
      is: 'location',
      then: string().nullable().required('Required'),
    }),
});
