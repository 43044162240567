import _debounce from 'lodash.debounce';
import _uniqBy from 'lodash.uniqby';
import { creditCardProcessingFeeTypes } from './constants';
import { formatDate } from './systemUtils/dateUtils';

/**
 * Convert an array of items to an object with the id as the key.
 * @param {Array.<Object>} arr The array
 * @param {Array} [sortIds] An optional empty array that will store the ids
 * @param {string} [idKey='enumValue'] The key to use as the id. Defaults to enumValue
 * in the correct order
 * @returns {Object}
 */
export function arrayToObjByEnumOrId(arr = [], sortIds, idKey = 'enumValue') {
  return arr.reduce((acc, cur, index) => {
    let key;
    if (cur[idKey] || cur.id) {
      key = (cur[idKey] || cur.id) + '';
    } else {
      cur.id = index;
      key = index + ''; // Use the index as the key when IDs are null or 0
    }

    acc[key] = cur;
    if (sortIds) {
      sortIds.push(key);
    }
    return acc;
  }, {});
}

export function removeDupesObjArray(curValues, newValues) {
  if (!Array.isArray(curValues) || !Array.isArray(newValues)) {
    return [];
  }
  const curIds = curValues.map((v) => v.id);
  const newIds = newValues.map((v) => v.id);
  const removeDupesIds = Array.from(new Set([...curIds, ...newIds]));
  const toObjById = arrayToObjByEnumOrId([...curValues, ...newValues]);
  return removeDupesIds.map((id) => toObjById[id]);
}

export function validateOnSubmit({ values, schema, setErrors, context }) {
  return schema
    .validate(values, { abortEarly: false, context })
    .then(() => Promise.resolve())
    .catch((err) => {
      const reduce = err.inner.reduce((acc, cur) => {
        acc[cur.path] = cur.errors[0];
        return acc;
      }, {});
      setErrors(reduce);
      return Promise.reject();
    });
}

// code from https://stackoverflow.com/a/1349426
export function makeuuid(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/**
 *
 * @param {string} [phone]
 * @param {string} [emptyText]
 * @returns
 */
export function formatPhone(phone, emptyText) {
  if (!phone) return emptyText;
  return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
}

/**
 * Loop through an object and set all fields that are null to ''
 * An optional array of fields to skip
 * @param {Object} values
 * @param {array} [fieldsToSkip]
 */
export function convertNullFieldsToEmptyString(values, fieldsToSkip = []) {
  if (!values) return values;
  return Object.keys(values).reduce((acc, cur) => {
    if (fieldsToSkip.includes(cur)) {
      acc[cur] = values[cur];
    } else {
      acc[cur] = values[cur] ?? '';
    }
    return acc;
  }, {});
}

/**
 * Limit the length of a string with an optional parameter
 * to show an ellipsis at the end of the string.
 * @param {string} string
 * @param {number} limit
 * @param {boolean} showEllipsis [n]
 * @return {string} The truncated string
 */
export function limitStringSize(string, limit, showEllipsis) {
  if (typeof string !== 'string' || string.length <= limit) {
    return string;
  }
  return showEllipsis
    ? string.slice(0, limit - 2) + '...'
    : string.slice(0, limit);
}

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/constructor
export function isObject(value) {
  return value?.constructor === Object;
}

export function isNullOrUndefined(value) {
  return typeof value === 'undefined' || value === null;
}

export function uniqueBy(collection, property) {
  return _uniqBy(collection, property);
}

export function stringCapitalize(value) {
  if (!value) return value;
  return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 *
 * @param {Array<import('../types').SelectOption>} list an array of enum meta
 * @param {string} [idField] the field to use as the `id` property
 * @param {Array<number>} [sortIds]
 * @returns Object
 */
export function getFiltersFromDropdownList(list, idField = 'id', sortIds) {
  if (!list) return {};
  return list.reduce((acc, cur) => {
    const { name } = cur;
    const idFieldVal = cur[idField] ?? cur.id;
    if (name && idFieldVal !== null && idFieldVal !== undefined) {
      acc[idFieldVal] = name;
    }
    !!sortIds && sortIds.push(idFieldVal + '');
    return acc;
  }, {});
}

export function getDimensionsString({ width, length, height }) {
  return (
    (width || length || height) &&
    `${length ?? '-'} x ${width ?? '-'} x ${height ?? '-'} in`
  );
}

/**
 *  Get the first value from an object from an array of keys
 * @param {object} params
 * @param {array<string | number>} params.keysArr An array of keys to check
 * @param {object} params.obj
 * @returns
 */
export function getFirstObjectValueFromKeysArray({ keysArr, obj }) {
  if (!obj || !isObject(obj)) return;
  let value = '';
  keysArr.forEach((key) => {
    let item = obj[key];
    if (item) {
      value = item;
      return item;
    }
  });
  return value;
}

export function getHighestPackageNum(packages) {
  if (!packages) return '0';
  const keys = Object.keys(packages);
  if (!keys.length) return '0';
  return Math.max(...keys).toString();
}

export function getFeeAmountType(type) {
  switch (type) {
    case 'CarrierPercentageFee':
    case 'FuelFee':
    case creditCardProcessingFeeTypes.VISA:
    case creditCardProcessingFeeTypes.MASTER_CARD:
    case creditCardProcessingFeeTypes.AMERICAN_EXPRESS:
    case creditCardProcessingFeeTypes.DISCOVER:
    case creditCardProcessingFeeTypes.DINERS:
    case creditCardProcessingFeeTypes.JCB:
      return 'percent';
    default:
      return 'amount';
  }
}

export function getValueFromAutocompleteInput(
  newInputValue,
  valuePropName = 'id',
) {
  switch (true) {
    case isObject(newInputValue):
      return newInputValue[valuePropName];
    case Array.isArray(newInputValue):
      return newInputValue.map((v) => (isObject(v) ? v[valuePropName] : v));
    default:
      return newInputValue;
  }
}

/**
 * Checks if the full name is in the `lastName` field and returns the first and last name
 * @param {object} params
 * @param {string} [params.firstName]
 * @param {string} [params.lastName]
 * @returns
 */
export function getReceiverName({ firstName, lastName }) {
  if (firstName) return `${firstName} ${lastName}`;
  if (!firstName && !lastName) return '';
  const receiver = lastName?.split(' ');
  const receiverFirstName = receiver?.[0];
  const receiverLastName = receiver?.[receiver?.length - 1];
  return `${receiverFirstName} ${receiverLastName}`;
}

export function getRatesDateText(deliveryDays, estimatedDeliveryDate) {
  let value = 'days';
  if (deliveryDays === 1) {
    value = 'day';
  }

  return `Estimated delivery ${
    deliveryDays > 0
      ? `${deliveryDays} ${value}${
          estimatedDeliveryDate
            ? ` - ${formatDate(estimatedDeliveryDate, {
                formatStr: 'Pp zzz',
                useTime: true,
              })}`
            : ''
        }`
      : 'N/A'
  }`;
}

export function debounceFunc(cb, delay = 300) {
  return _debounce(cb, delay);
}

/**
 *
 * @param {object} param
 * @param {number} [param.id]
 * @param {string} [param.firstName]
 * @param {string} [param.lastName]
 * @param {string} [param.address1]
 * @param {string} [param.address2]
 * @param {string} [param.city]
 * @param {string | number} [param.stateId]
 * @param {string} [param.stateAlternate]
 * @param {string} [param.zip]
 * @param {string | number} [param.countryId]
 * @param {string} [param.phone]
 * @param {string} [param.companyName]
 * @param {string|number} [param.companyID]
 * @param {import('../types').ContactType} [param.contactType]
 * @param {import('../types').JobTitleType} [param.jobTitle]
 * @param {string} [param.email]
 * @param {boolean} [param.isStateCodeRequired]
 * @param {boolean} [param.saveUserContact]
 * @returns {import('../types').ContactAPI}
 */
export function formatContactApi({
  id,
  firstName,
  lastName,
  address1,
  address2,
  city,
  stateId,
  stateAlternate,
  zip,
  countryId,
  phone,
  companyName,
  companyID,
  contactType,
  jobTitle,
  email,
  saveUserContact = false,
  isStateCodeRequired = true,
}) {
  const addressApi = {
    address1,
    address2,
    city,
    zip,
    countryId,
  };

  if (!isStateCodeRequired && stateAlternate) {
    addressApi.stateAlternate = stateAlternate;
  } else {
    addressApi.stateID = stateId;
  }

  const personApi = {
    firstName,
    lastName,
    officePhone: phone,
    email,
    address: addressApi,
  };

  return {
    id,
    companyName,
    companyID,
    contactType,
    jobTitle,
    person: personApi,
    saveUserContact,
  };
}

export function getCarrierTypeFromFulfillment(fulfillmentType) {
  switch (fulfillmentType) {
    case null:
    case '':
    case 'SmallParcel':
      return 'SmallParcel';
    case 'LTL':
    case 'FTL':
      return 'Freight';
    default:
  }
}

export function generateDocumentApi({ signedUrlsMapping, files, name }) {
  const { key, rawFile: { type } = {} } = files[0];
  return {
    quantity: 1,
    document: {
      name,
      objectName: key,
      url: signedUrlsMapping[key],
      type,
    },
  };
}

export function generateDocumentsApi({
  signedUrlsMapping,
  files,
  name,
  isPhoto = false,
}) {
  if (!files) return [];

  return files.reduce((acc, cur) => {
    const { key, rawFile: { type } = {} } = cur;
    acc.push({
      quantity: 1,
      document: {
        name,
        objectName: key,
        url: signedUrlsMapping[key],
        type,
        isPhoto,
      },
    });
    return acc;
  }, []);
}
