import {
  TypedUseSelectorHook,
  useDispatch as _useDispatch,
  useSelector as _useSelector,
  shallowEqual,
} from 'react-redux';
import type { ThunkAction, Action } from '@reduxjs/toolkit';
import type { RootState, AppDispatch } from '../store';
import type { AuthResponseError } from '../lib/AuthRequest';
import { OrderByType } from '../types/listTypes';

// https://redux.js.org/tutorials/typescript-quick-start#define-typed-hooks
export const useDispatch: () => AppDispatch = _useDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = _useSelector;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export interface NormalizeReturn<T = unknown> {
  data?: T;
  error?: AuthResponseError;
}

interface ResourceParam {
  page: number;
  numberOfRows: number;
  rowsPerPage?: number;
  order?: OrderByType;
  orderBy?: string;
  filter: object;
  queryParams: object;
  selectedIds: Array<number | string>;
  selectedRows: Record<string, any>;
  total: number;
  hasList: boolean;
}

export interface Resource {
  name: string;
  ids: Array<any>;
  data: Record<string, any>;
  params: ResourceParam;
}

export { shallowEqual, RootState };
