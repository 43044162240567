import { Fragment } from 'react';
import {
  paths,
  generateValidatedParamsUrl,
  pathParams,
  appointmentOpenStatuses,
} from '../../../lib';
import { CalendarTodayIcon, AccountCircleIcon } from '../../../assets';
import { useGetUsersData } from '../../../hooks';
import { SidebarItem } from '../SidebarItem';

export function AppointmentUserSidebar() {
  const { userCompanyId, userId } = useGetUsersData();

  const profilePath = generateValidatedParamsUrl(paths.COMPANY_USER_PROFILE, {
    [pathParams.COMPANY_ID]: userCompanyId,
    [pathParams.USER_ID]: userId,
  });

  return (
    <Fragment>
      <SidebarItem
        to={generateValidatedParamsUrl(paths.APPOINTMENTS, undefined, {
          status: appointmentOpenStatuses,
        })}
        text='Appointments'
        icon={CalendarTodayIcon}
        isActive={() =>
          window.location.pathname.startsWith(
            generateValidatedParamsUrl(paths.APPOINTMENTS),
          )
        }
      />
      {!!userCompanyId && !!userId && (
        <SidebarItem
          to={profilePath}
          text='My account'
          icon={AccountCircleIcon}
          isActive={() => window.location.pathname.startsWith(profilePath)}
        />
      )}
    </Fragment>
  );
}
