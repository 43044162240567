import { addResource, updateResource } from '.';
import { authGet } from '../../lib';
import { showError } from '../features';
import type { AppThunk, NormalizeReturn } from '../types';

function getBillDetails(
  billId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(`accounting/bills/${billId}`);
    if (error) {
      dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

function getAccountPayableDetails(
  shipmentId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await authGet(
      `accounting/accounts-payable/${shipmentId}`,
    );
    if (error) {
      error.status !== 404 &&
        dispatch(showError({ message: error.message || 'An error occurred' }));
      return { error };
    }
    return { data };
  };
}

function reconcileBill(
  payload: object,
  message: string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      updateResource({
        baseUrl: `/accounting/bills/reconcile`,
        payload,
        message,
      }),
    );
    if (error) {
      return { error };
    }
    return { data };
  };
}

function saveBillToQB(
  billId: number | string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `accounting/bills/${billId}/send-to-qb`,
        payload: {},
        message: `Bill #${billId} has been sent to QuickBooks`,
        shouldSetUILoading: true,
      }),
    );
    if (error) {
      dispatch(showError({ message: error.message }));
      return {
        error,
      };
    }
    return { data };
  };
}

function addBill(payload: any): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const { data, error } = await dispatch(
      addResource({
        baseUrl: `accounting/bills`,
        payload,
        message: `Bill saved ${payload?.shouldSendToQB ? 'to QB' : 'on hold'}`,
        shouldSetUILoading: true,
      }),
    );
    if (error) {
      dispatch(showError({ message: error.message }));
      return {
        error,
      };
    }
    return { data };
  };
}

export {
  getBillDetails,
  getAccountPayableDetails,
  reconcileBill,
  saveBillToQB,
  addBill,
};
