import React, { useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { invoiceStatusStyles } from '../../styles/sharedStyles';
import { useGetUsersData, useNotify } from '../../hooks';
import {
  authGet,
  formatCurrency,
  formatDate,
  generateUrl,
  nestedPages,
  pathParams,
  paths,
} from '../../lib';
import { useStyles } from './billing.styles';

export function AccountReceivables({
  baseUrl,
  id,
  customerId,
  getHasInvoice,
  viewVersion,
}) {
  const { classes } = useStyles();
  const { classes: invoiceClasses, cx } = useInvoiceStatusStyles();
  const notify = useNotify();
  const { isCustomer } = useGetUsersData();

  const [data, setData] = useState([]);

  useEffect(() => {
    (async function () {
      if (id) {
        const { data, error } = await authGet(`${baseUrl}/${id}/fees/invoices`);
        if (error) {
          return notify(error.message || 'Error getting invoices');
        }
        typeof getHasInvoice === 'function' && getHasInvoice(!!data.length);
        setData(data);
      }
    })();
  }, [baseUrl, notify, id, viewVersion, getHasInvoice]);

  return (
    <>
      <div className={classes.title}>
        {isCustomer ? 'Accounting' : 'Accounts Receivable'}
      </div>
      {data.length > 0 ? (
        <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
          {data.map((invoice) => {
            const {
              invoiceDate,
              invoiceID,
              qbDocNumber,
              status,
              statusDisplay,
              totalAmount,
            } = invoice;
            return (
              <div className={classes.item} key={invoiceID}>
                <span className={classes.itemTitle}>{`Invoice ${
                  qbDocNumber ? `#${qbDocNumber}` : ''
                }`}</span>
                <div className={classes.itemStatus}>
                  <span className={cx(classes.dot, invoiceClasses[status])} />
                  <span>{statusDisplay}</span>
                </div>
                <span className={classes.itemInfo}>
                  Invoice total: {formatCurrency(totalAmount)}
                </span>
                <span className={classes.itemInfo}>
                  {formatDate(invoiceDate)}
                </span>
                {invoiceID && (
                  <Link
                    color='primary'
                    href={generateUrl(
                      paths.CUSTOMER_INVOICE_DETAILS,
                      {
                        [pathParams.CUSTOMER_ID]: customerId,
                        [pathParams.NESTED_PAGE]:
                          nestedPages.CUSTOMER_ACCOUNTING,
                        [pathParams.INVOICE_ID]: invoiceID,
                      },
                      {
                        navigateTo:
                          window.location.pathname + window.location.search,
                      },
                    )}
                    underline='none'
                    target='_blank'
                    className={classes.viewLink}
                  >
                    View
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className={classes.emptyState}>No Invoices</div>
      )}
    </>
  );
}

const useInvoiceStatusStyles = makeStyles()((theme) => ({
  ...invoiceStatusStyles,
}));
