import _clonedeep from 'lodash.clonedeep';
import {
  setPackages,
  setItemsCompleted,
  setItemsRemaining,
  setPhysicalItems,
} from '../../state';
import {
  arrayToObjByEnumOrId,
  packingMaterialTypes,
  getDimensionsString,
  orderStatuses,
  unitOfMeasure,
  formatContactApi,
} from '../../lib';
import cloneDeep from 'lodash.clonedeep';

export function generateOrderPayload({
  packages,
  allPhysicalItems,
  orderData,
  status = 'None',
}) {
  const { firstValidation, lineItems, notes, ...otherOrderData } = orderData;
  const payload = { ...otherOrderData, status };
  let remainingDbPhysicalItems = allPhysicalItems;

  const packagesArr = Object.keys(packages).map((key) => {
    const currentPackage = packages[key];
    const {
      items,
      metadata = {},
      material,
      ...otherPackageValues
    } = currentPackage;
    const isPickAndStick = !!metadata.isPickAndStick;
    const isCustomMode = !!metadata.isCustomMode;
    let payload = {
      ...otherPackageValues,
      mode: isCustomMode
        ? 'Unknown'
        : isPickAndStick
          ? 'PickAndStick'
          : 'PickAndPack',
      isSealed: metadata.isCompleted,
      weightPounds: metadata.weightPounds,
      items:
        items &&
        Object.keys(items).map((k) => {
          const item = items[k];
          if (!allPhysicalItems) {
            return item;
          }
          const { physicalItems, ...rest } = item;
          const { itemPhysicalItems, remainingDbItems } =
            generateItemPhysicalItems({
              sku: k,
              itemPhysicalItems: physicalItems,
              dbPhysicalItems: remainingDbPhysicalItems,
              itemQty: item.quantity,
            });
          if (remainingDbItems) {
            remainingDbPhysicalItems = remainingDbItems;
          }
          return {
            ...rest,
            physicalItems: itemPhysicalItems,
          };
        }),
      materials:
        material &&
        Object.keys(material).map((k) => {
          const {
            sku,
            dimensions,
            description,
            packingMaterialTypeDisplay,
            ...otherMaterial
          } = material[k];
          return {
            ...otherMaterial,
            packingMaterialID: sku,
          };
        }),
    };
    // package dimensions
    if (isPickAndStick || isCustomMode) {
      payload = { ...payload, ...getPickAndStickDimensions(currentPackage) };
    }
    return payload;
  });

  payload.packages = packagesArr;
  return payload;
}

function generateItemPhysicalItems({
  sku,
  itemPhysicalItems = {},
  dbPhysicalItems = {},
  itemQty,
}) {
  const itemPhysicalItemsKeys = Object.keys(itemPhysicalItems);
  // If we did inventory tracking then the physical items should already be there
  if (itemPhysicalItemsKeys.length) {
    return {
      itemPhysicalItems: itemPhysicalItemsKeys.map((p) => itemPhysicalItems[p]),
    };
  }
  const dbItemPhysicalItems = dbPhysicalItems[sku];
  // If no db physical items and no item physical items
  if (!Array.isArray(dbItemPhysicalItems)) return { itemPhysicalItems: [] };

  // If there are no tem physical items but there are db physical items
  const remainingDbItemPhysicalItems = _clonedeep(dbItemPhysicalItems);
  const selectedDbItems = remainingDbItemPhysicalItems.splice(0, itemQty);
  const returnPhysicalItems = [];
  for (let i = 0; i < itemQty; i++) {
    // If there is no more db items create a new one
    returnPhysicalItems.push(
      selectedDbItems[i] || {
        lotNumber: null,
        serialNumber: null,
        expirationDate: null,
      },
    );
  }
  const newState = {
    ...dbPhysicalItems,
    [sku]: remainingDbItemPhysicalItems,
  };
  return { itemPhysicalItems: returnPhysicalItems, remainingDbItems: newState };
}

function getPickAndStickDimensions(currentPackage) {
  return {
    pickAndStickLengthInches: currentPackage.pickAndStickLengthInches,
    pickAndStickWidthInches: currentPackage.pickAndStickWidthInches,
    pickAndStickHeightInches: currentPackage.pickAndStickHeightInches,
    weightPounds: currentPackage.weightPounds,
  };
}

/**
 * Updates the items remaining, items completed, and packages state
 * @param {object} data The incoming payload
 * @param {object} itemsObj The order items as object with the sku as the key
 * @param {function} dispatch
 */
export function syncIncomingCompletedOrderFields(data, itemsObj, dispatch) {
  const {
    packages: incomingPackages,
    status,
    physicalItems: allPhysicalItems,
  } = data;
  const assignedPhysicalItemIds = [];

  const packages = incomingPackages.reduce((acc, cur, index) => {
    const {
      mode,
      weightPounds,
      materials,
      items: incomingItems,
      childPackages,
      isSealed,
      ...rest
    } = cur;

    const material = generateIncomingMaterial(materials);

    const items = generateIncomingItems({ items: incomingItems, itemsObj });

    Object.keys(items).forEach((i) => {
      assignedPhysicalItemIds.push(
        ...Object.keys(items[i]?.physicalItems ?? {}),
      );
    });

    acc[index + 1] = {
      ...rest,
      weightPounds,
      metadata: {
        isCompleted: true,
        isPickAndStick: mode === 'PickAndStick',
        weightPounds,
      },
      material,
      items,
    };
    return acc;
  }, {});

  dispatch(setPackages(packages));
  generateCompletedOrderPhysicalItemsState(
    allPhysicalItems,
    assignedPhysicalItemIds,
    dispatch,
  );

  if (status === orderStatuses.HOLD || status === orderStatuses.PAUSED) {
    return syncItemsRemainingCompleted(incomingPackages, itemsObj, dispatch);
  }
  dispatch(setItemsCompleted(itemsObj));
}

function syncItemsRemainingCompleted(packages, itemsObj, dispatch) {
  const groupedUomData = {};

  const allItemsInPackages = packages.reduce((acc, cur) => {
    const { items: _items, childPackages } = cur;
    const items = Array.isArray(_items) ? cloneDeep(_items) : [];

    if (Array.isArray(childPackages)) {
      childPackages.forEach((cp) => {
        const { items: _childItems } = cp;
        const childItems = _childItems || [];
        items.push(...childItems);
      });
    }

    acc.push(...items);
    items?.forEach((i) => {
      const { customerItemID, packedPerUnitOfMeasureCount } = i;
      if (packedPerUnitOfMeasureCount) {
        groupedUomData[customerItemID] = groupedUomData[customerItemID] || {
          [unitOfMeasure.MASTER]: 0,
          [unitOfMeasure.SECONDARY]: 0,
          [unitOfMeasure.PRIMARY]: 0,
        };
        const { master, secondary, primary } = packedPerUnitOfMeasureCount;
        groupedUomData[customerItemID][unitOfMeasure.PRIMARY] =
          groupedUomData[customerItemID][unitOfMeasure.PRIMARY] + primary;
        groupedUomData[customerItemID][unitOfMeasure.SECONDARY] =
          groupedUomData[customerItemID][unitOfMeasure.SECONDARY] + secondary;
        groupedUomData[customerItemID][unitOfMeasure.MASTER] =
          groupedUomData[customerItemID][unitOfMeasure.MASTER] + master;
      }
    });
    return acc;
  }, []);

  let itemsRemaining = _clonedeep(itemsObj);
  const itemsCompleted = {};
  allItemsInPackages.forEach((i) => {
    const { sku, customerItemID, quantity } = i;
    const curItem = itemsRemaining[sku];
    if (!curItem) return;
    const itemRemainingQty = curItem.quantity - quantity;
    // items remaining
    if (itemRemainingQty < 1) {
      const { [sku]: itemToRemove, ...rest } = itemsRemaining;
      itemsRemaining = rest;
    } else {
      curItem.quantity = itemRemainingQty;
      curItem.packedPerUnitOfMeasureCount = groupedUomData[customerItemID + ''];
    }
    // items completed
    itemsCompleted[sku] = itemsCompleted[sku] || { ...curItem, quantity: 0 };
    const completedItem = itemsCompleted[sku];
    completedItem.quantity = completedItem.quantity + quantity;
  });
  dispatch(setItemsCompleted(itemsCompleted));
  dispatch(setItemsRemaining(itemsRemaining));
}

function generateCompletedOrderPhysicalItemsState(
  allPhysicalItems = [],
  assignedPhysicalItemIds = [],
  dispatch,
) {
  const physicalItemsObj = {};
  allPhysicalItems.forEach((pi) => {
    const { id, sku } = pi;
    if (!assignedPhysicalItemIds.includes(id + '')) {
      physicalItemsObj[sku] = physicalItemsObj[sku] || [];
      physicalItemsObj[sku].push(pi);
    }
  });
  dispatch(setPhysicalItems(physicalItemsObj));
}

export function generateReshipPayload({
  packages,
  allPhysicalItems,
  orderData,
  status = 'Closed',
  reshipState,
}) {
  const data = generateOrderPayload({
    packages,
    allPhysicalItems,
    orderData,
    status,
  });

  const {
    entityIds,
    hasOverrideCarrierInfo,
    hasOverrideShipToInfo,
    reshipReturnReason,
    overrideShouldChargeToCustomer,
    warehouseID,
    carrierRequestedName,
    carrierID,
    carrierServiceCode,
    fulfillmentType,
    shippingSelectionPreference,
    thirdPartyAccountNumber,
    insuranceCost,
    requiresDeliveryConfirmation,
    shipToCompanyName,
    shipToFirstName,
    shipToLastName,
    shipToAddress1,
    shipToAddress2,
    shipToCity,
    shipToStateID,
    shipToZip,
    shipToCountryID,
    shipToPhone,
  } = reshipState || {};

  const { shipToContactId, overrideOrderShipmentID } = entityIds || {};

  data.overrideOrderShipmentID = overrideOrderShipmentID;
  data.warehouseID = warehouseID;
  data.reshipReturnReason = reshipReturnReason;
  data.overrideShouldChargeToCustomer = overrideShouldChargeToCustomer;

  if (hasOverrideShipToInfo) {
    data.shipToContactOverride = formatContactApi({
      id: shipToContactId,
      companyName: shipToCompanyName,
      firstName: shipToFirstName,
      lastName: shipToLastName,
      address1: shipToAddress1,
      address2: shipToAddress2,
      city: shipToCity,
      stateId: shipToStateID,
      countryId: shipToCountryID,
      zip: shipToZip,
      phone: shipToPhone,
    });
  }

  if (hasOverrideCarrierInfo) {
    data.carrierOverrideData = {
      carrierID,
      carrierName: carrierRequestedName,
      carrierServiceCode,
      thirdPartyAccountNumber,
      fulfillmentType,
      shippingSelectionPreference,
      insuranceCost,
      requiresDeliveryConfirmation: requiresDeliveryConfirmation === 'yes',
    };
  }

  return data;
}

// freight

export function generateFreightOrderPayload({
  packages,
  allPhysicalItems,
  orderData,
  status = 'None',
  isFreightPalletMode = true,
}) {
  const {
    carrierInfo,
    firstValidation,
    items,
    lineItems,
    notes,
    shipToContact,
    ...otherOrderData
  } = orderData;
  const payload = { ...otherOrderData, status };
  let remainingDbPhysicalItems = allPhysicalItems;

  const packagesArr = Object.keys(packages).map((key) => {
    const currentPackage = packages[key];
    const generatedPackage = generatePackageData({
      currentPackage,
      allPhysicalItems,
      remainingDbPhysicalItems,
      isFreightPalletMode,
    });

    if (currentPackage.childPackages) {
      const childPackages = Object.keys(currentPackage.childPackages).map(
        (cp) => {
          const currentChildPackage = currentPackage.childPackages[cp];
          return generatePackageData({
            currentPackage: currentChildPackage,
            allPhysicalItems,
            remainingDbPhysicalItems,
            isChildPackage: true,
            isFreightPalletMode,
          });
        },
      );

      generatedPackage.childPackages = childPackages;
    }

    return generatedPackage;
  });

  payload.packages = packagesArr;
  return payload;
}

function generatePackageData({
  currentPackage,
  allPhysicalItems,
  remainingDbPhysicalItems,
  isChildPackage,
  isFreightPalletMode,
}) {
  const {
    items,
    metadata = {},
    material,
    ...otherPackageValues
  } = currentPackage;

  const isPickAndStick = !!metadata.isPickAndStick;
  const isPickAndShip = !!metadata.isPickAndShip;
  const isCustomMode = !!metadata.isCustomMode;

  let mode;
  if (!isFreightPalletMode || isChildPackage) {
    mode = isCustomMode
      ? 'Unknown'
      : isPickAndStick
        ? 'PickAndStick'
        : 'PickAndPack';
  } else {
    mode = isPickAndShip ? 'PickAndShip' : 'PrepPallet';
  }

  let payload = {
    ...otherPackageValues,
    mode,
    isSealed: metadata.isCompleted,
    weightPounds: metadata.weightPounds,
    isOnPallet: isChildPackage && isFreightPalletMode,
    items:
      items &&
      Object.keys(items).map((k) => {
        const item = items[k];
        if (!allPhysicalItems) {
          return item;
        }
        const { physicalItems, ...rest } = item;
        const { itemPhysicalItems, remainingDbItems } =
          generateItemPhysicalItems({
            sku: k,
            itemPhysicalItems: physicalItems,
            dbPhysicalItems: remainingDbPhysicalItems,
            itemQty: item.quantity,
          });
        if (remainingDbItems) {
          remainingDbPhysicalItems = remainingDbItems;
        }
        return {
          ...rest,
          physicalItems: itemPhysicalItems,
        };
      }),
    materials:
      material &&
      Object.keys(material).map((k) => {
        const {
          sku,
          dimensions,
          description,
          packingMaterialTypeDisplay,
          ...otherMaterial
        } = material[k];
        return {
          ...otherMaterial,
          packingMaterialID: sku,
        };
      }),
  };
  // package dimensions
  if (isPickAndStick || isCustomMode) {
    payload = { ...payload, ...getPickAndStickDimensions(currentPackage) };
  }
  return payload;
}

/**
 * Updates the items remaining, items completed, and packages state
 * @param {object} data The incoming payload
 * @param {object} itemsObj The order items as object with the sku as the key
 * @param {function} dispatch
 */
export function syncIncomingCompletedFreightOrderFields(
  data,
  itemsObj,
  dispatch,
) {
  const {
    packages: incomingPackages,
    status,
    physicalItems: allPhysicalItems,
  } = data;
  const assignedPhysicalItemIds = [];

  const packages = incomingPackages.reduce((acc, cur, index) => {
    const {
      mode,
      weightPounds,
      materials,
      items: incomingItems,
      childPackages,
      isSealed,
      ...rest
    } = cur;

    const parentMaterials = generateIncomingMaterial(materials);

    const generatedParentItems = generateIncomingItems({
      items: incomingItems,
      itemsObj,
    });

    if (generatedParentItems) {
      Object.keys(generatedParentItems).forEach((i) => {
        assignedPhysicalItemIds.push(
          ...Object.keys(generatedParentItems[i]?.physicalItems ?? {}),
        );
      });
    }

    const hasChildPackages =
      Array.isArray(childPackages) && childPackages.length;

    const generatedChildPackages = {};
    if (hasChildPackages) {
      childPackages.forEach((cp, i) => {
        const {
          mode: childMode,
          weightPounds: childWeight,
          materials: childMaterials,
          items: childItems,
          isSealed: childIsSealed,
          childPackages: _childPackages,
          ...otherChild
        } = cp;

        const generatedChildMaterials =
          generateIncomingMaterial(childMaterials);
        const generatedChildItems = generateIncomingItems({
          items: childItems,
          itemsObj,
        });

        Object.keys(generatedChildItems).forEach((i) => {
          assignedPhysicalItemIds.push(
            ...Object.keys(generatedChildItems[i]?.physicalItems ?? {}),
          );
        });

        generatedChildPackages[i + 1] = {
          ...otherChild,
          weightPounds: childWeight,
          metadata: {
            isCompleted: true,
            isPickAndStick: childMode === 'PickAndStick',
            weightPounds: childWeight,
          },
          material: generatedChildMaterials,
          items: generatedChildItems,
        };
      });
    }

    acc[index + 1] = {
      ...rest,
      weightPounds,
      metadata: {
        isCompleted: true,
        isPickAndShip: mode === 'PickAndShip',
        lotNumber: incomingItems[0]?.physicalItems?.[0]?.lotNumber,
        weightPounds,
      },
      material: parentMaterials,
      items: generatedParentItems,
      childPackages: hasChildPackages ? generatedChildPackages : undefined,
    };
    return acc;
  }, {});

  dispatch(setPackages(packages));
  generateCompletedOrderPhysicalItemsState(
    allPhysicalItems,
    assignedPhysicalItemIds,
    dispatch,
  );

  if (status === orderStatuses.HOLD || status === orderStatuses.PAUSED) {
    syncItemsRemainingCompleted(incomingPackages, itemsObj, dispatch);
  } else {
    dispatch(setItemsCompleted(itemsObj));
  }
}

function generateIncomingMaterial(materials) {
  return materials.reduce((accM, curM) => {
    const {
      packingMaterialID,
      packingMaterialTypeDisplay,
      lengthInches,
      widthInches,
      heightInches,
    } = curM;
    let key = packingMaterialID;
    if (packingMaterialTypeDisplay === packingMaterialTypes.BOX) {
      key = 'box';
    }
    accM[key] = {
      ...curM,
      sku: packingMaterialID,
      description: packingMaterialTypeDisplay,
      dimensions: getDimensionsString({
        height: heightInches,
        length: lengthInches,
        width: widthInches,
      }),
    };
    return accM;
  }, {});
}

function generateIncomingItems({ items, itemsObj }) {
  if (!items) return {};

  return items.reduce((accI, curI) => {
    const { sku, quantity, id, physicalItems, packedPerUnitOfMeasureCount } =
      curI;
    const item = itemsObj[sku] || {};
    let physicalItemsObj;
    if (Array.isArray(physicalItems)) {
      physicalItemsObj = arrayToObjByEnumOrId(physicalItems);
    }
    accI[sku] = {
      ...item,
      quantity,
      id,
      physicalItems: physicalItemsObj,
      packedPerUnitOfMeasureCount,
    };
    return accI;
  }, {});
}
