import { useCallback, useState, useEffect, Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { SelectInput, TextInput } from '../inputs';
import { useDispatch, addResource } from '../../../state';
import { useRefresh } from '../../../hooks';
import {
  authGet,
  convertNullFieldsToEmptyString,
  handleAddNestedState,
  handleRemoveNestedState,
  updateNestedState,
} from '../../../lib';
import {
  MouseChangeEventType,
  IdNamePair,
  InputChangeEventType,
  ReceiptCrossDocAPI,
  ReceiptSkuUomMappingResponse,
  ReceiptItemCrossDocAPI,
} from '../../../types';
import { Grid2 as Grid } from '@mui/material';
import { TextButton } from '../../../themes';
import { ListDeleteIcon } from '../../list';

interface Props {
  open: boolean;
  handleClose: () => any;
  receiptId: string | number;
  setViewVersion?: React.Dispatch<React.SetStateAction<number>>;
}

interface State {
  crossDocs: Record<string, UomState>;
}

interface UomState extends ReceiptItemCrossDocAPI {
  sku: string;
  itemUnitOfMeasureID: number | '';
  orderExternalID: string;
  quantity: number | '';
}

const defaultUom: UomState = {
  sku: '',
  itemUnitOfMeasureID: '',
  orderExternalID: '',
  quantity: '',
};

const initialState: State = {
  crossDocs: {},
};

export function ReceiptCrossDocItemsForm({
  open,
  handleClose,
  receiptId,
  setViewVersion,
}: Props) {
  const { classes, css } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const [skuOptions, setSkuOptions] = useState<Array<IdNamePair>>([]);
  const [uomOptions, setUomOptions] = useState<
    Record<string, Array<IdNamePair>>
  >({});
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    (async function () {
      if (receiptId) {
        const { data } = await authGet<Array<ReceiptSkuUomMappingResponse>>(
          `/receipts/${receiptId}/items/sku-unit-of-measures`,
        );
        if (data) {
          const skuOptions: Array<IdNamePair> = [];
          const uomOptions: Record<string, Array<IdNamePair>> = {};
          data.forEach((d) => {
            skuOptions.push({ id: d.sku, name: d.sku });
            if (d.unitOfMeasures) {
              uomOptions[d.sku] = d.unitOfMeasures.map((u) => ({
                id: u.itemUnitOfMeasureID,
                name: u.unitOfMeasureDisplay,
              }));
            }
          });
          setSkuOptions(skuOptions);
          setUomOptions(uomOptions);
        }
      }
    })();
  }, [receiptId]);

  useEffect(() => {
    (async function () {
      if (receiptId) {
        const { data } = await authGet<ReceiptCrossDocAPI>(
          `/receipts/${receiptId}/items/cross-doc`,
        );
        if (data) {
          setState((cur) => ({
            ...cur,
            crossDocs: data.crossDocs.reduce(
              (acc, cur) => ({
                ...acc,
                [cur.id + '']: { ...convertNullFieldsToEmptyString(cur) },
              }),
              {},
            ),
          }));
        }
      }
    })();
  }, [receiptId]);

  const handleSubmit = useCallback(
    async (values: State) => {
      setSubmitting(true);
      const payload: ReceiptCrossDocAPI = {
        receiptID: receiptId,
        crossDocs: Object.values(values.crossDocs).map((i) => ({
          receiptID: receiptId,
          ...i,
        })),
      };
      const { error } = await dispatch(
        addResource({
          baseUrl: `/receipts/${receiptId}/items/cross-doc`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion ? setViewVersion((cur) => ++cur) : refresh();
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [receiptId],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const handleAddItem = (e: MouseChangeEventType) => {
    const { values, setValues } = formik;

    handleAddNestedState({
      defaultValues: defaultUom,
      nestedObjectKey: 'crossDocs',
      setState: setValues,
      state: values,
    });
  };

  const handleRemoveItem = (e: MouseChangeEventType) => {
    const { values, setValues } = formik;
    const key = e.currentTarget.getAttribute('data-key');
    handleRemoveNestedState({
      state: values,
      setState: setValues,
      nestedObjectKey: 'crossDocs',
      keyToRemove: key!,
    });
  };

  const handleChange = (key: string) => (e: InputChangeEventType) => {
    const { values, setValues } = formik;
    const { name, value } = e.target;

    const newValue = {
      [name]: value,
    };

    if (name === 'sku') {
      newValue['itemUnitOfMeasureID'] = '';
    }

    const newState = updateNestedState({
      nestedKey: ['crossDocs', key!],
      value: newValue,
      state: values,
    });

    setValues({ ...values, ...newState });
  };

  const { handleChange: _handleChange, ...formikProps } = formik;

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>
          Which items are going to be used for upcoming orders?
        </span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='md'
      submitting={submitting}
    >
      <Grid container spacing={3}>
        {Object.keys(formik.values.crossDocs).map((key, i) => {
          const { sku, itemUnitOfMeasureID, orderExternalID, quantity } =
            formik.values.crossDocs[key];

          return (
            <Fragment key={i}>
              <Grid
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 4,
                }}
              >
                <SelectInput
                  name='sku'
                  formikProps={formikProps}
                  value={sku}
                  label='SKU'
                  options={skuOptions}
                  className={classes.input}
                  onChange={handleChange(key)}
                  useCustomValue
                />
              </Grid>
              <Grid
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 2,
                }}
              >
                <SelectInput
                  name='itemUnitOfMeasureID'
                  formikProps={formikProps}
                  value={itemUnitOfMeasureID}
                  label='UOM'
                  options={uomOptions[sku] || []}
                  className={classes.input}
                  onChange={handleChange(key)}
                  useCustomValue
                />
              </Grid>
              <Grid
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 2,
                }}
              >
                <TextInput
                  name='quantity'
                  formikProps={formikProps}
                  label='QTY'
                  value={quantity}
                  className={classes.input}
                  handleChange={handleChange(key)}
                  type='number'
                  useCustomValue
                />
              </Grid>
              <Grid
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 3,
                }}
              >
                <TextInput
                  name='orderExternalID'
                  formikProps={formikProps}
                  label='Order'
                  value={orderExternalID}
                  className={classes.input}
                  handleChange={handleChange(key)}
                  useCustomValue
                />
              </Grid>
              <Grid
                style={{ paddingBottom: 0 }}
                size={{
                  sm: 1,
                }}
              >
                <ListDeleteIcon
                  wrapWithButton
                  iconButtonProps={{
                    onClick: handleRemoveItem,
                    'data-key': key,
                  }}
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
      <div className={css({ marginTop: 16 })}>
        <TextButton onClick={handleAddItem}>Link another item</TextButton>
      </div>
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    width: '100%',
  },
}));
