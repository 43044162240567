import { lighten } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: theme.spacing(8),
    height: theme.spacing(8),
    position: 'absolute',
    left: 0,
    right: 0,
    top: '-64px',
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: '#F5F6FE',
  },
  title: {
    flex: '1 1 100%',
    color: lighten(theme.palette.text.primary, 0.45),
    fontSize: 18,
  },
}));
