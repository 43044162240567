import { useCallback, useState, useEffect } from 'react';
import { object, string } from 'yup';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput, SelectInput } from '../inputs';
import {
  useDispatch,
  useSelector,
  addResource,
  deleteResource,
  fetchResourceById,
  systemSelectors,
} from '../../../state';
import {
  creditCardProcessingFeeTypes,
  convertNullFieldsToEmptyString,
  getFeeAmountType,
} from '../../../lib';

const initialState = {
  name: '',
  type: '',
  documentFeeType: '',
  amount: '',
  percentage: '',
};

export function CustomerFeeForm({
  open,
  handleClose,
  customerId,
  feeId,
  feeType,
  setViewVersion,
  isCreditCardFee,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [feeOptions, setFeeOptions] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);

  const customerFeeTypes = useSelector(systemSelectors.customerFeeTypes);
  const documentFeeTypes = useSelector(systemSelectors.documentFeeTypes);
  const creditCardProcessingFeeTypes = useSelector(
    systemSelectors.creditCardProcessingFeeTypes,
  );

  useEffect(() => {
    if (isCreditCardFee) {
      setFeeOptions(creditCardProcessingFeeTypes);
    } else if (customerFeeTypes.length) {
      setFeeOptions(customerFeeTypes.filter((f) => f.id !== 'DrayageFee'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerFeeTypes.length, isCreditCardFee]);

  useEffect(() => {
    (async function () {
      if (feeId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/customers',
            id: customerId,
            path: `fees/${feeId}`,
          }),
        );
        if (data) {
          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString(data),
          }));
        }
      } else if (isCreditCardFee) {
        setState((cur) => ({ ...cur, type: feeType }));
      }
    })();
  }, [customerId, dispatch, feeId, feeType, isCreditCardFee]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = {
        ...values,
        defaultPickAndStickMode: values.defaultPickAndStickMode === 'yes',
        id: values.id || null,
        documentFeeType:
          values.type === 'OrderDocumentFee' ? values.documentFeeType : null,
      };
      const { error } = await dispatch(
        addResource({
          baseUrl: `/customers/${customerId}/fees`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        setViewVersion((cur) => ++cur);
        handleClose();
      }
    },
    [customerId, dispatch, handleClose, setViewVersion],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/customers',
        id: customerId,
        path: `fees/${feeId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      setViewVersion((cur) => ++cur);
      handleClose();
    }
  }, [customerId, dispatch, feeId, handleClose, setViewVersion]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const isFeeTypeAmount = getFeeAmountType(formik.values.type) === 'amount';

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${feeId ? 'Edit' : 'Add'} fee`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={!!feeId}
      handleDelete={handleDelete}
    >
      {!isCreditCardFee && (
        <TextInput
          name='name'
          formikProps={formik}
          label='Fee name'
          className={classes.input}
        />
      )}
      <SelectInput
        name='type'
        formikProps={formik}
        options={feeOptions}
        label='Fee type'
        className={classes.input}
        disabled={isCreditCardFee && !feeId}
        required
      />
      {formik.values.type === 'OrderDocumentFee' && (
        <SelectInput
          name='documentFeeType'
          formikProps={formik}
          options={documentFeeTypes}
          label='Document fee type'
          className={classes.input}
          required
        />
      )}
      <TextInput
        name={isFeeTypeAmount ? 'amount' : 'percentage'}
        formikProps={formik}
        label='Rate'
        className={classes.input}
        format={isFeeTypeAmount ? 'currency' : undefined}
        slotProps={{
          input: {
            endAdornment: !isFeeTypeAmount ? <span>%</span> : undefined,
          },
        }}
        required
      />
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));

const schema = object().shape({
  type: string().required('Required'),
  documentFeeType: string('Must be string').when('type', {
    is: 'OrderDocumentFee',
    then: string().required('Required'),
  }),
  percentage: string('Must be string').when('type', (type, schema) => {
    return type === 'CarrierPercentageFee' || isCCProcessingFeeType(type)
      ? schema.required('Required')
      : schema;
  }),
  amount: string('Must be string').when('type', (type, schema) => {
    return type !== 'CarrierPercentageFee' && !isCCProcessingFeeType(type)
      ? schema.required('Required')
      : schema;
  }),
});

const isCCProcessingFeeType = (type) => {
  return [
    creditCardProcessingFeeTypes.VISA,
    creditCardProcessingFeeTypes.MASTER_CARD,
    creditCardProcessingFeeTypes.AMERICAN_EXPRESS,
    creditCardProcessingFeeTypes.DISCOVER,
    creditCardProcessingFeeTypes.DINERS,
    creditCardProcessingFeeTypes.JCB,
  ].includes(type);
};
