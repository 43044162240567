import { useCallback } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { useStyles } from './inputs.styles';

/**
 *
 * @param {import("@mui/material/Radio").TextFieldProps & otherProps} props
 * @returns
 */
export function RadioInput({
  formikProps,
  handleChange,
  name,
  value,
  radios,
  useCustomValue = false,
  title,
  titleClassName,
  radiosRow,
  size,
  controlStyle,
  disabled,
}) {
  const { classes, cx } = useStyles();
  const {
    handleChange: formikHandleChange,
    values,
    touched,
    errors,
  } = formikProps || {};

  const onChange = useCallback(
    (e) => {
      formikHandleChange && !useCustomValue && formikHandleChange(e);
      handleChange && handleChange(e);
    },
    [formikHandleChange, handleChange, useCustomValue],
  );

  return (
    <div>
      <FormControl
        variant='standard'
        component='fieldset'
        style={controlStyle}
        error={touched?.[name] && Boolean(errors?.[name])}
      >
        <FormHelperText style={{ top: -15 }}>
          {touched?.[name] && errors?.[name]}
        </FormHelperText>
        <FormLabel
          component='legend'
          className={cx(classes.radioLabel, titleClassName)}
          classes={{ focused: classes.focused }}
        >
          {title}
        </FormLabel>
        <RadioGroup
          name={name}
          value={
            formikProps !== undefined && !useCustomValue ? values[name] : value
          }
          onChange={onChange}
          row={radiosRow}
        >
          {radios.map((r, index) => {
            const { label, value, labelClassName } = r;
            return (
              label && (
                <FormControlLabel
                  key={index}
                  value={value}
                  control={
                    <Radio color='primary' size={size} disabled={disabled} />
                  }
                  label={label}
                  classes={{ label: labelClassName }}
                />
              )
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

/**
 * @typedef {object} otherProps
 * @property {object} [formikProps]
 * @property {boolean} [useCustomValue]
 * @property {string|Array<string>} [validationKey] For validation of nested object
 * @property {React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>} [handleChange]
 */
