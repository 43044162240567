import { Fragment } from 'react';
import {
  paths,
  pathParams,
  nestedPages,
  reportTypes,
  generateValidatedParamsUrl,
  isProduction,
  appointmentOpenStatuses,
  stockViewTypes,
} from '../../../lib';
import {
  ListAltIcon,
  LocalShippingIcon,
  AttachMoneyIcon,
  MaterialsIcon,
  SettingsIcon,
  AssessmentIcon,
  SummarizeIcon,
  PieChartIcon,
  ReceiptLongIcon,
  CalendarTodayIcon,
  InventoryIcon,
} from '../../../assets';
import { useGetUsersData } from '../../../hooks';
import { SidebarItem } from '../SidebarItem';
import { NestedList } from '../NestedList';

export function CustomerSidebar({ handleClearSelectedOrders }) {
  const { canViewOrderList, customerId } = useGetUsersData();
  return (
    <Fragment>
      <NestedList text='Dashboards' icon={AssessmentIcon} id='dashboard'>
        <SidebarItem
          to={generateValidatedParamsUrl(paths.ORDER_FULFILLMENT_DASHBOARD, {
            [pathParams.ORDER_FULFILLMENT_DASHBOARD_TAB]:
              nestedPages.FULFILLMENT_DASHBOARD_SP,
          })}
          text='Order fulfillment'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(
              generateValidatedParamsUrl(paths.ORDER_FULFILLMENT_DASHBOARD),
            )
          }
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.SHIPPING_DASHBOARD, {
            [pathParams.SHIPPING_DASHBOARD_TAB]:
              nestedPages.SHIPPING_SMALL_PARCEL_METRIC,
          })}
          text='Shipments'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(
              generateValidatedParamsUrl(paths.SHIPPING_DASHBOARD),
            )
          }
        />
      </NestedList>
      <SidebarItem
        to={generateValidatedParamsUrl(paths.SHARED_REPORTS, {
          [pathParams.REPORT_TYPE]: nestedPages.P_L_REPORTS,
          [pathParams.NESTED_PAGE]: reportTypes.P_L_FEE_CATEGORY,
        })}
        text='Reporting'
        icon={PieChartIcon}
        isActive={() => window.location.pathname.startsWith('/reports')}
      />
      {!isProduction && (
        <SidebarItem
          text='Receipts'
          to={generateValidatedParamsUrl(paths.RECEIPTS)}
          icon={ReceiptLongIcon}
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.RECEIPTS}`)
          }
        />
      )}
      <NestedList text='Inventory' icon={InventoryIcon} id='inventory'>
        <SidebarItem
          text='Stock'
          to={generateValidatedParamsUrl(paths.STOCK, {
            [pathParams.VIEW_TYPE]: stockViewTypes.DETAILS,
          })}
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.STOCK}`)
          }
        />
        <SidebarItem
          text='Locations'
          isNested
          to={generateValidatedParamsUrl(paths.LOCATIONS)}
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.LOCATIONS}`)
          }
        />
      </NestedList>
      {canViewOrderList && (
        <SidebarItem
          text='Orders'
          to={generateValidatedParamsUrl(paths.ORDERS)}
          icon={ListAltIcon}
          onClick={handleClearSelectedOrders}
          isActive={() => window.location.pathname.startsWith('/order')}
        />
      )}
      <NestedList text='Shipping' icon={MaterialsIcon} id='shipping'>
        <SidebarItem
          to={generateValidatedParamsUrl(paths.GET_SP_ESTIMATE, {
            [pathParams.NESTED_PAGE]: nestedPages.SP_ESTIMATE,
          })}
          text='Browse Rates'
          isNested
          isActive={() =>
            window.location.pathname.startsWith('/small-parcel-estimate')
          }
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.PURCHASED_LABEL)}
          text='Labels & Rates'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.PURCHASED_LABEL}`)
          }
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.SHIPPING_HISTORY)}
          text='Shipping history'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.SHIPPING_HISTORY}`)
          }
        />
      </NestedList>
      <NestedList text='Trucking' icon={LocalShippingIcon} id='trucking'>
        <SidebarItem
          to={generateValidatedParamsUrl(paths.DRAYAGE_JOBS, {
            [pathParams.DRAYAGE_JOB_TAB]: nestedPages.DRAYAGE_JOBS_OPENED,
          })}
          text='Drayage'
          isNested
          isActive={() => window.location.pathname.startsWith(`/drayage-jobs`)}
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.TRUCKING_JOBS, {
            [pathParams.TRUCKING_JOB_TAB]: nestedPages.TRUCKING_JOBS_OPENED,
          })}
          text='Quotes & Trucking'
          isNested
          isActive={() => window.location.pathname.startsWith(`/trucking-jobs`)}
        />
      </NestedList>
      <SidebarItem
        to={generateValidatedParamsUrl(paths.APPOINTMENTS, undefined, {
          status: appointmentOpenStatuses,
        })}
        text='Appointments'
        icon={CalendarTodayIcon}
        isActive={() => window.location.pathname.includes(`/appointments`)}
      />
      <SidebarItem
        to={generateValidatedParamsUrl(paths.FULFILLED_SERVICES)}
        text='Fulfilled services'
        icon={SummarizeIcon}
        isActive={() =>
          window.location.pathname.startsWith(`/${paths.FULFILLED_SERVICES}`)
        }
      />
      <NestedList text='Accounting' icon={AttachMoneyIcon} id='accounting'>
        <SidebarItem
          to={generateValidatedParamsUrl(paths.ACCOUNTING)}
          text='Accounting'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/${paths.ACCOUNTING}`)
          }
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.RESELLER_BILLS)}
          text='Reseller Bills'
          isNested
          isActive={() =>
            window.location.pathname.startsWith(`/reseller-bills`)
          }
        />
      </NestedList>
      <NestedList text='Settings' icon={SettingsIcon} id='settings'>
        <SidebarItem
          to={generateValidatedParamsUrl(paths.CUSTOMER_PAGES, {
            [pathParams.CUSTOMER_ID]: customerId,
            [pathParams.NESTED_PAGE]: nestedPages.CUSTOMER_CONTRACT,
          })}
          text='Contract'
          isNested
          isActive={() => window.location.pathname.startsWith('/customer')}
        />
        <SidebarItem
          to={generateValidatedParamsUrl(paths.CARRIERS_ACCOUNTS)}
          text='Carriers & accounts'
          isNested
          isActive={() =>
            window.location.pathname.startsWith('/carriers-accounts')
          }
        />
        <SidebarItem
          text='Items'
          to={generateValidatedParamsUrl(paths.CUSTOMER_PAGES, {
            [pathParams.CUSTOMER_ID]: customerId,
            [pathParams.NESTED_PAGE]: nestedPages.CUSTOMER_ITEMS,
          })}
          isNested
          isActive={() => window.location.pathname.startsWith('/items')}
        />

        <SidebarItem
          text='Documents'
          to={generateValidatedParamsUrl(paths.DOCUMENTS)}
          isNested
          isActive={() => window.location.pathname.startsWith('/documents')}
        />
      </NestedList>
    </Fragment>
  );
}
