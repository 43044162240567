import { makeStyles } from 'tss-react/mui';
import { Button, ButtonProps } from '@mui/material';

export function TextButton(props: ButtonProps) {
  const { classes, cx } = useStyles();
  const { children, className, ...rest } = props;
  return (
    <Button className={cx(classes.btn, className)} disableElevation {...rest}>
      {children}
    </Button>
  );
}

const useStyles = makeStyles({ name: { TextButton } })((theme) => ({
  btn: {
    fontSize: 14,
    letterSpacing: 0,
    textTransform: 'none',
  },
}));
