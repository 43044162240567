import { useCallback, useState, useEffect, useMemo } from 'react';
import { ButtonGroup, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useNotify } from '../../hooks';
import {
  useSelector,
  useDispatch,
  orderSelectors,
  getBasicPackingMaterials,
} from '../../state';
import { scanModes } from '../../lib';
import { AutoCompleteScanner } from '../common';
import {
  validateSelectedItem,
  validateSelectedMaterial,
} from './orderValidators';

export function ScanPackingMaterialOrItems({
  itemsRemainingOverride,
  includePackingType,
  excludePackingType,
  onScan,
  setScanMode,
  scanMode,
  currentPackage = {},
  upcSkuObj,
  disablePickAndStickTab,
  disablePickAndPackTab,
  scannerDisabled,
  customerID,
}) {
  const { classes, cx } = styles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const originalItems = useSelector(orderSelectors.originalItems);
  const _itemsRemaining = useSelector(orderSelectors.itemsRemaining);
  const itemsCompleted = useSelector(orderSelectors.itemsCompleted);
  const [packingItems, setPackingItems] = useState();

  const itemsRemaining = itemsRemainingOverride || _itemsRemaining;

  useEffect(() => {
    (async function () {
      const { data, error } = await dispatch(
        getBasicPackingMaterials({
          includeTypeList: includePackingType,
          excludeTypeList: excludePackingType,
          customerID,
        }),
      );
      if (error) {
        return notify(error.message, 'error');
      }
      const options = data.map((d) => ({ ...d, optionType: 'Material items' }));
      setPackingItems(options);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notify]);

  const scanValidatorFunc = useCallback(
    ({ value }) => {
      const {
        error: itemError,
        isNotFound: itemNotFound,
        data: itemData,
      } = validateSelectedItem({
        value,
        originalItems,
        itemsCompleted,
        itemsRemaining,
        currentPackage,
        upcSkuObj,
      });
      if (itemData) {
        return { data: itemData };
      } else if (!itemNotFound) {
        return { error: itemError };
      } else {
        return validateSelectedMaterial({ value });
      }
    },
    [currentPackage, itemsCompleted, itemsRemaining, originalItems, upcSkuObj],
  );

  const orderItemsOptions = useMemo(() => {
    return Object.keys(itemsRemaining).reduce((acc, cur) => {
      const curItem = itemsRemaining[cur];
      if (curItem.quantity > 0) {
        acc.push({
          sku: curItem.sku,
          upc: curItem.upc,
          description: curItem.description,
          optionType: 'Order items',
        });
      }
      return acc;
    }, []);
  }, [itemsRemaining]);

  return (
    <div className={classes.card}>
      {setScanMode && (
        <ButtonGroup color='primary' className={classes.btnGroup}>
          <Button
            disabled={disablePickAndStickTab}
            onClick={() => setScanMode(scanModes.PICK_AND_STICK)}
            className={cx(classes.tab, {
              [classes.activeTab]: scanMode === scanModes.PICK_AND_STICK,
            })}
          >
            Pick & stick
          </Button>
          <Button
            disabled={disablePickAndPackTab}
            onClick={() => setScanMode(scanModes.PICK_AND_PACK)}
            className={cx(classes.tab, {
              [classes.activeTab]: scanMode === scanModes.PICK_AND_PACK,
            })}
          >
            Pick & pack
          </Button>
        </ButtonGroup>
      )}
      {!!packingItems && (
        <AutoCompleteScanner
          scanValidatorFunc={scanValidatorFunc}
          onScan={onScan}
          wrapInCard={false}
          options={[...orderItemsOptions, ...packingItems]}
          optionsLabelProperties={['name', 'sku']}
          optionsValueProperties={['id', 'sku', 'upc']}
          label='Scan or select items or packaging materials'
          groupBy='optionType'
          disabled={scannerDisabled}
        />
      )}
    </div>
  );
}

const styles = makeStyles({
  name: 'scanOrder',
})((theme) => ({
  card: {
    maxWidth: 828,
    width: '100%',
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: '31px 40px',
  },
  input: {
    width: '100%',
    // marginRight: 24,
  },
  tab: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'capitalize',
    padding: '5px 10px',
    color: '#000000',
    border: '1px solid #D5D8EC',
  },
  activeTab: {
    color: '#00C4F8',
    border: '1px solid #00C4F8',
  },
  btnGroup: {
    width: 200,
    marginRight: 80,
  },
}));
