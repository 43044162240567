import { makeStyles } from 'tss-react/mui';
import { OrderBar, IconButtonLink } from '../../components';
import { ArrowBackIcon } from '../../assets';
import { generateUrl } from '../../lib';

interface Props {
  children?: React.ReactNode;
  header?: string;
  hideHeader?: boolean;
  smallHeader?: string;
  backNavigationPath?: string;
  orderBar?: React.ReactNode;
  orderNumber?: string | number;
  style?: React.CSSProperties;
}

export function ProcessContainer({
  children,
  header,
  hideHeader = false,
  smallHeader,
  backNavigationPath,
  orderBar,
  orderNumber,
  style,
}: Props) {
  const { classes, cx } = useStyles();
  return (
    <div className={classes.container}>
      {orderBar && <OrderBar orderNumber={orderNumber} />}
      <div
        className={cx(classes.content, {
          [classes.contentNoHeader]: !header,
        })}
        style={style}
      >
        {smallHeader}
        {!hideHeader && (
          <div className={classes.headerContainer}>
            {!!backNavigationPath && (
              <IconButtonLink
                to={generateUrl(backNavigationPath)}
                className={classes.backIcon}
                size='small'
              >
                <ArrowBackIcon />
              </IconButtonLink>
            )}
            {!!header && <h1 className={classes.header}>{header}</h1>}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

const useStyles = makeStyles({ name: { ProcessContainer } })((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('tablet')]: {
      height: '100%',
    },
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    // justifyContent: "center",
    width: '100%',
    minHeight: '100vh',
    padding: '80px 16px 40px 16px',
    [theme.breakpoints.down('tablet')]: {
      padding: '40px 16px',
      minHeight: '100%',
    },
  },
  contentNoHeader: {
    padding: '0px 16px 40px 16px',
  },
  header: {
    fontFamily: 'Montserrat',
    fontSize: 32,
    fontWeight: 500,
    letterSpacing: 0,
    [theme.breakpoints.down('tablet')]: {
      fontSize: 26,
    },
  },
  headerContainer: {
    marginBottom: 48,
    display: 'flex',
    alignItems: 'center',
  },
  backIcon: {
    color: 'inherit',
    marginRight: 7,
  },
}));
