import { Fragment, useCallback, useState, useEffect } from 'react';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import { Button, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, cancelOrder } from '../../../state';
import { useNotify, useGetUsersData } from '../../../hooks';
import { CancelIcon } from '../../../assets';
import { generateUrl, paths, Navigation } from '../../../lib';
import { FormModal, TextInput } from '../..';

interface CancelOrderProps {
  orderId: string | number;
  modalOpened: boolean;
  successCallback?: () => void;
  onCancel?: () => void;
}

interface State {
  reason: string;
}

const initialState: State = {
  reason: '',
};

export function CancelOrder({
  orderId,
  modalOpened,
  successCallback,
  onCancel,
}: CancelOrderProps) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const { isAdmin, isCustomerService, isTruckingSalesPerson, isCustomer } =
    useGetUsersData();
  const [showManagerLogin, setShowManagerLogin] = useState(false);
  const hasEditPermission =
    isAdmin || isCustomerService || isTruckingSalesPerson || isCustomer;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [adminToken, setAdminToken] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState(false);

  const handleShowModal = useCallback(() => {
    if (hasEditPermission) {
      setShowConfirmation(true);
    } else {
      setShowManagerLogin(true);
    }
  }, [hasEditPermission]);

  useEffect(() => {
    if (modalOpened) {
      handleShowModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteOrder = useCallback(
    async (values: State) => {
      setSubmitting(true);
      const payload = { reason: values.reason };
      const { error } = await dispatch(
        cancelOrder({ orderId, token: adminToken, payload }),
      );
      setSubmitting(false);
      if (error) {
        return notify(
          error.message || error.title || 'Something went wrong',
          'error',
        );
      }
      notify('Order successfully cancelled');
      successCallback
        ? successCallback()
        : Navigation.redirect(generateUrl(paths.SCAN_ORDER));
    },
    [adminToken, dispatch, successCallback, notify, orderId],
  );

  const onAdminLogin = useCallback((token: string) => {
    setShowManagerLogin(false);
    setShowConfirmation(true);
    setAdminToken(token);
  }, []);

  const handleCancel = useCallback(() => {
    setShowConfirmation(false);
    setShowManagerLogin(false);
    onCancel && onCancel();
  }, [onCancel]);

  const formik = useFormik<State>({
    initialValues: initialState,
    enableReinitialize: false,
    onSubmit: handleDeleteOrder,
    validationSchema: schema,
  });

  return (
    <Fragment>
      {!modalOpened && (
        <Tooltip title='Cancel order' placement='bottom'>
          <Button
            color='secondary'
            className={classes.actionButton}
            size='small'
            onClick={handleShowModal}
          >
            <CancelIcon className={classes.icon} />
          </Button>
        </Tooltip>
      )}
      <FormModal
        open={showConfirmation || showManagerLogin}
        handleClose={handleCancel}
        title={
          showManagerLogin
            ? 'Manager login required to complete edits'
            : 'Are you sure you want to cancel this order?'
        }
        callback={formik.handleSubmit}
        btnText='SAVE'
        paperProps={{ style: { width: 468 } }}
        showManagerLogin={showManagerLogin}
        onAdminLogin={onAdminLogin}
        submitting={submitting}
        typeSubmit
      >
        <TextInput
          name='reason'
          formikProps={formik}
          label='Reason'
          multiline
          rows={3}
          required
        />
      </FormModal>
    </Fragment>
  );
}

const useStyles = makeStyles({ name: { CancelOrder } })((theme) => ({
  actionButton: {
    width: 32,
    height: 32,
    minWidth: 'unset',
    backgroundColor: '#F5F6FE',
  },
  icon: {
    fontSize: 14,
  },
}));

const schema = object().shape({
  reason: string().required('Required'),
});
