import { IdNamePair } from './globalTypes';
import { ShipmentLocationType } from './truckingJobTypes';
import { WarehouseVehicleType } from './warehouseTypes';

export type JobTitleType =
  | 'CEO'
  | 'Salesman'
  | 'Bookkeeper'
  | 'Other'
  | 'TruckDriver';

export type ShippingCategoryType = 'Residential' | 'Commercial' | 'Other';

export type AddressValidationStatusType = 'Valid' | 'Invalid';

export type UserType =
  | 'Admin'
  | 'CustomerService'
  | 'Processor'
  | 'Scanner'
  | 'Customer'
  | 'TruckingSalesperson'
  | 'DefaultCarrier'
  | 'ScannerProcessor'
  | 'Integration'
  | 'ManageAppointment'
  | 'AppointmentCheckIn'
  | 'Manager';

export type ContactType =
  | 'ShipTo'
  | 'Carrier'
  | 'Customer'
  | 'ShipFrom'
  | 'Integration'
  | 'AppointmentScheduler';

export enum CompanyType {
  Carrier = 'Carrier',
}

export interface ContactAPI {
  id?: number;
  companyName?: string;
  companyID?: string | number;
  contactType?: ContactType;
  externalID?: string;
  jobTitle?: JobTitleType;
  person?: PersonAPI;
}

export interface PersonAPI {
  personID?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  officePhone?: string;
  mobilePhone?: string;
  faxNumber?: string;
  address?: AddressAPI;
}

export interface AddressAPI {
  address1?: string;
  address2?: string;
  city?: string;
  zip?: string;
  stateID?: number;
  stateAlternate?: string;
  stateCode?: string;
  countryID?: number;
  formattedAddress?: string;
  countryName?: string;
  shippingCategory?: ShippingCategoryType;
  addressValidationStatus?: AddressValidationStatusType;
}

export interface UserAPI {
  id: number;
  type: UserType;
  email: string;
  userName: string;
  customerID?: number;
  warehouseID?: number;
  assignedToWarehouseName?: string;
  jobTitle?: JobTitleType;
  lastLogin?: string | Date;
  salaryPerHour?: number;
  userTypeDisplay?: string;
  personID?: number;
  firstName: string;
  lastName: string;
  officePhone?: string;
  mobilePhone?: string;
  faxNumber?: string;
  address?: AddressAPI;
}

export interface CompanyAPI {
  id?: number;
  type: CompanyType;
  companyName: string;
}

export interface CompanyUserAPI {
  id?: number;
  type: UserType;
  email?: string;
  contact?: ContactAPI;
  companyUsers?: Array<ContactAPI>;
  company?: CompanyAPI;
}

export interface UsersForActivityType {
  id: number;
  userName: string;
  userType: string;
  vehicles: Array<WarehouseVehicleType>;
  userTypeDisplay: string;
}

export interface UserContactsResponse extends IdNamePair {
  contact: UserContactType;
}

export interface UserContactType {
  addressID: number | string;
  contactID?: number | string;
  companyName?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  fax: string;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  stateID: number;
  stateCode: string;
  countryID: number;
  countryName: string;
  shipmentLocationType?: ShipmentLocationType;
}
