export function roundDownToDecimal(amount: number, digits = 2) {
  if (amount === undefined || amount === null) return amount;
  if (digits < 0) {
    return amount;
  }
  const multiplier = 10 ** digits;
  return Math.floor(amount * multiplier) / multiplier;
}

// code from https://stackoverflow.com/a/1421988
export function isNumber(n: any) {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}

export function formatCurrency(
  amount?: number,
  maximumFractionDigits?: number,
) {
  if (amount === undefined || amount === null) return amount;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
    minimumFractionDigits: maximumFractionDigits,
  }).format(amount);
}

export function formatNumber(amount?: number, maximumFractionDigits?: number) {
  if (amount === undefined || amount === null) return amount;
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits,
    minimumFractionDigits: maximumFractionDigits,
  }).format(amount);
}

export function formatCubicFeet(value?: number | null) {
  if (!value) return null;

  // Round to two decimal places to avoid excessive precision
  const roundedValue = Math.round(value * 100) / 100;

  // Extract the whole number of feet
  let feet = Math.floor(roundedValue);

  // Calculate the remaining inches (as a decimal)
  const inches = (roundedValue - feet) * 12;

  // Round inches to nearest whole number (avoid extra decimals)
  let roundedInches = Math.round(inches);

  // Handle case where rounding inches results in 12 inches
  if (roundedInches === 12) {
    roundedInches = 0;
    feet += 1;
  }

  // Construct the formatted string
  return `${feet}in${roundedInches}`;
}
