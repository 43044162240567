import axios from 'axios';
import { md5 } from 'js-md5';
import {
  AuthResponseError,
  AxiosAuthResponse,
  authGet,
  authPost,
  normalizeResponseError,
} from '../../lib';
import { setUILoading, showError } from '../features';
import type { AppThunk, NormalizeReturn } from '../types';
import type {
  FileType,
  SignedReadWrite,
  SignedUrlMapping,
} from '../../types/fileTypes';

export interface UploadResponse {
  googleResponse: any;
  signedUrlsMapping: SignedUrlMapping;
}

interface CustomResults<T = any> {
  data?: T;
  error?: AuthResponseError;
}

export function getReadWriteSignedUrl(
  fileName: string,
): AppThunk<Promise<NormalizeReturn<SignedReadWrite>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<SignedReadWrite>([
      '/cloud/signedReadWriteUrl',
      { objectName: fileName },
    ]);
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

function getReadWriteSignedUrls(
  fileNames: Array<string>,
): AppThunk<Promise<NormalizeReturn<Array<SignedReadWrite>>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<Array<SignedReadWrite>>([
      '/cloud/signedReadWriteUrls',
      { objectNames: fileNames },
    ]);
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}

export function getSignedReadUrl(
  fileName: string,
): AppThunk<Promise<NormalizeReturn<SignedReadWrite>>> {
  return async (dispatch) => {
    const { data, error } = await authGet<SignedReadWrite>([
      '/cloud/signedReadUrl',
      { objectName: fileName },
    ]);
    if (error) {
      dispatch(
        showError({
          message: error.message || error.title || 'An error occurred',
        }),
      );
      return { error };
    }
    return { data };
  };
}
export function uploadToGoogle(
  signedUrl: string,
  file: File,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const hash = await getContentHash(file);
    return new Promise((resolve, reject) => {
      const { type: mime_type } = file;
      axios
        .put(signedUrl, file, {
          headers: {
            'content-type': mime_type,
            'content-MD5': hash,
          },
          // onUploadProgress:
        })
        .then((data) => {
          resolve({ data });
        })
        .catch((err) => {
          reject(normalizeResponseError('PUT', '', {}, false)(err));
        });
    });
  };
}

export function uploadFiles(
  files: Array<FileType>,
): AppThunk<Promise<NormalizeReturn<UploadResponse>>> {
  return async (dispatch) => {
    const fileNames: Array<string> = [];
    const signedUrlsMapping: SignedUrlMapping = {};
    const filesObject = files.reduce((acc, cur) => {
      const { key, rawFile } = cur;
      fileNames.push(key);
      acc[key] = rawFile;
      return acc;
    }, {});

    const { data: signedUrls, error: signedUrlsError } = await dispatch(
      getReadWriteSignedUrls(fileNames),
    );
    if (signedUrlsError) {
      return { error: signedUrlsError };
    }
    if (signedUrls) {
      const googleUploadPromises: Array<
        Promise<void | NormalizeReturn<unknown>>
      > = [];
      const uploadErrors: Array<any> = [];
      signedUrls.forEach((s) => {
        googleUploadPromises.push(
          dispatch(
            uploadToGoogle(s.signedReadUrl, filesObject[s.objectName]),
          ).catch((err) => {
            uploadErrors.push(err);
          }),
        );
        signedUrlsMapping[s.objectName] = s.signedReadUrl;
      });

      const googleResponse = await Promise.all(googleUploadPromises);

      if (uploadErrors.length) {
        const message = 'Some of the files were not uploaded';
        dispatch(showError({ message }));
        return { error: { message, code: 0 } };
      }

      return { data: { googleResponse, signedUrlsMapping } };
    }

    return { error: { message: 'No signed urls received', code: 0 } };
  };
}

/** Returns an MD5 hash for the given file or blob in base64 format.
 * @param {Blob} fileOrBlob A file is a blob, so either one is fine.
 * @returns {Promise<string>} An MD5 hash in base64 format.
 */
export function getContentHash(fileOrBlob: File | Blob): Promise<string> {
  if (
    fileOrBlob instanceof File === false &&
    fileOrBlob instanceof Blob === false
  ) {
    return Promise.reject('Invalid file');
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(fileOrBlob);
    reader.onloadend = () => {
      const result = reader.result;
      if (result) {
        resolve(md5.base64(result));
      } else {
        reject('error reading file');
      }
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
}

export function downloadFromBucket(
  url: string,
  fileName: string,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    const results: CustomResults = {
      data: undefined,
      error: undefined,
    };
    await axios
      .get<any, AxiosAuthResponse<BlobPart>>(url, {
        responseType: 'blob',
      })
      .then((response) => {
        const { data, error } = response;
        if (error) {
          results.error = error;
          dispatch(
            showError({
              message: error.message || error.title || 'An error occurred',
            }),
          );
        } else {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          results.data = 'Success';
        }
      })
      .catch((err) => {
        const error = normalizeResponseError('POST', url, {})(err);
        dispatch(
          showError({
            message:
              error.error?.message || error.error?.title || 'An error occurred',
          }),
        );
        results.error = error?.error;
      });

    return results;
  };
}

export function openFile(url: string): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    dispatch(setUILoading(true));

    const { data, error } = await authGet(url, undefined, undefined, {
      responseType: 'arraybuffer',
    });
    dispatch(setUILoading(false));
    if (error) {
      dispatch(
        showError({
          message: error?.message || error?.title || 'An error occurred',
        }),
      );

      return { error };
    }

    const file = new Blob([data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const win = window.open(fileURL, '_blank');
    if (!win) {
      const msg =
        'Unable to open document. Please ensure your pop-up blocker is disabled try again.';
      dispatch(
        showError({
          message: msg,
        }),
      );

      return { error: { Message: msg, code: 500 } };
    }
    win.focus();

    return { data };
  };
}

export function openFileWithPayloadRequest<TRequest = Record<string, any>>(
  url: string,
  payload: TRequest,
): AppThunk<Promise<NormalizeReturn>> {
  return async (dispatch) => {
    dispatch(setUILoading(true));

    const { data, error } = await authPost(url, payload, undefined, undefined, {
      responseType: 'arraybuffer',
    });
    dispatch(setUILoading(false));
    if (error) {
      dispatch(
        showError({
          message: error?.message || error?.title || 'An error occurred',
        }),
      );

      return { error };
    }

    const file = new Blob([data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const win = window.open(fileURL, '_blank');
    if (!win) {
      const msg =
        'Unable to open document. Please ensure your pop-up blocker is disabled try again.';
      dispatch(
        showError({
          message: msg,
        }),
      );

      return { error: { Message: msg, code: 500 } };
    }
    win.focus();

    return { data };
  };
}
