import { useEffect } from 'react';
import {
  CameraApiConfig,
  getDocumentsSubmitUrlAndIdFromServiceType,
} from '../lib';
import {
  useDispatch,
  setCameraEntityConfig,
  useSelector,
  uiSelectors,
} from '../state';

import { SystemServiceType } from '../types';

interface Params {
  entityId: number | string;
  warehouseId?: number | string;
  serviceType: SystemServiceType;
  /**
   * If the user has the timer running then they are fulfilling a task and the config
   * was set to that tasks entity so we may not want to override. Default is false
   */
  overrideTimerTaskEntity?: boolean;
}

export function useSetCameraConfig({
  entityId,
  serviceType,
  warehouseId,
  overrideTimerTaskEntity = false,
}: Params) {
  const dispatch = useDispatch();
  const showTimer = useSelector(uiSelectors.showTimer);

  useEffect(() => {
    if (overrideTimerTaskEntity || !showTimer) {
      let dataConfig: CameraApiConfig | null = null;
      if (entityId) {
        dataConfig = getDocumentsSubmitUrlAndIdFromServiceType(
          serviceType,
          entityId,
          warehouseId,
        );
      }
      dispatch(setCameraEntityConfig(dataConfig));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, serviceType]);

  useEffect(() => {
    if (overrideTimerTaskEntity || !showTimer) {
      // clear the data when navigating away
      return () => {
        dispatch(setCameraEntityConfig(null));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
