import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'list' })((theme) => ({
  root: {
    width: '100%',
    padding: 40,
    // padding: '40px 0px 40px 40px',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    // boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    marginBottom: 40,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    boxShadow: '0 2px 24px 0 #E5E7F5, 0 2px 40px 0 #F9FAFC',
    position: 'relative',
    '& .MuiTableCell-root': {
      '& span': {
        fontSize: 14,
      },
    },
  },
  asideOpen: {
    borderRight: '1px solid theme.palette.textTheme.main',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  tableContainer: {
    overflowX: 'auto',
    maxWidth: 'calc(100vw - 163px)',
    // maxHeight: '900px',
    // overflowY: 'auto',
  },
  tableContainerSidebarOpen: {
    maxWidth: 'calc(100vw - 377px)',
  },
  // table: {
  //   minWidth: 750,
  // },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHead: {
    color: theme.palette.textTheme.main,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    position: 'sticky',
    top: 0,
    background: 'white',
    zIndex: 3,
  },
  clickableRow: {
    cursor: 'pointer',
  },
  pagination: {
    ...(theme.typography.body2 as CSSObject),
    fontFamily: 'Lato',
    fontSize: 14,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    flex: '1 1 100%',
    textTransform: 'capitalize',
  },
  selectedRow: {
    backgroundColor: '#F5F6FE !important',
  },
  tableCell: {
    borderBottom: '1px solid #E5E7F5',
  },
  checkbox: {
    color: '#D5D8EC',
  },
  checkboxChecked: {},
  expandIcon: {
    fontSize: 18,
    color: '#D5D8EC',
  },
  borderNone: {
    border: 'none',
  },
  stickyRightColumn: {
    // position: 'absolute',
    position: 'sticky',
    right: 0,
    background: 'white',
    // zIndex: 0,
    // '&:first-child': {
    //   zIndex: 2,
    // },

    // zIndex: 'auto',
    // transition: 'z-index 2147483647s',
    // '&:hover': {
    //   zIndex: 1,
    //   transition: 'z-index 0s',
    // },
  },
  stickyLeftColumn: {
    position: 'sticky',
    left: 0,
    background: 'white',
    '&:first-of-type': {
      zIndex: 2,
    },
  },
}));
