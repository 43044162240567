import React, { memo, useCallback } from 'react';
import _get from 'lodash.get';
import { Link, LinkProps } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import { AlignType } from '../../../types';
import { stableObject } from '../../../lib';

interface Props {
  linkProps?: LinkProps;
  record?: Record<string, any>;
  className?: string;
  headerClassName?: string;
  cellStyle?: React.CSSProperties;
  source: string;
  label: string;
  emptyText?: string;
  disablePadding?: string;
  align?: AlignType;
  sortBy?: string;
  sortable?: false;
}

export const EmailField = memo<Props>(
  ({ className, linkProps: _linkProps, source, record: _record, ...rest }) => {
    const { classes, cx } = useStyles();
    const stopPropagation = useCallback((e) => e.stopPropagation(), []);
    const record = _record || stableObject;
    const linkProps = _linkProps || stableObject;

    const value = _get(record, source);
    if (!value) return <span>--</span>;
    return (
      <Link
        href={`mailto:${value}`}
        onClick={stopPropagation}
        className={cx(classes.text, className)}
        underline='none'
        {...linkProps}
      >
        {value}
      </Link>
    );
  },
);

const useStyles = makeStyles({ name: { EmailField } })((theme) => ({
  text: {
    fontSize: 14,
  },
}));
