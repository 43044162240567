import {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useStateWithCallbackInstant } from 'use-state-with-callback';
import { createSelector } from '@reduxjs/toolkit';
import { Grid2 as Grid, Tooltip, Typography } from '@mui/material';
import {
  EditOrderCarrierForm,
  EditOrderShipInfoForm,
  EditReshipWarehouseForm,
  ReshipReasonForm,
  LongTextHoverTooltip,
  ProcessOrderNotes,
  OrderDocumentUpload,
} from '../../components';
import {
  EditIcon,
  BusinessIcon,
  HomeIcon,
  DoneIcon,
  CloseIcon,
  WarningOutlinedIcon,
  PickNStickIcon,
  DescriptionIcon,
  NoteIcon,
} from '../../assets';
import PickAndStickSVG from '../../assets/images/pick-and-stick.svg';
import { orderStatusStyles } from '../../styles/sharedStyles';
import {
  formatCurrency,
  getReceiverName,
  formatDate,
  paths,
  generateUrl,
  pathParams,
  Navigation,
} from '../../lib';
import {
  useSelector,
  useDispatch,
  uiSelectors,
  systemSelectors,
  getProcessOrder,
  setSelectedOrder,
  updateReshipProperties,
  orderSelectors,
} from '../../state';
import { useOpenFile, useNotify } from '../../hooks';
import { styles } from './DetailsTab.styles';

// get the reship state slice only if isReship
const selectReshipState = createSelector(
  (state) => state.orders.reshipState,
  (_, isReship) => isReship,
  (reshipState, isReship) => (isReship ? reshipState : null),
);

export function DetailsTab({ children, header, selectedOrder }) {
  const { classes, cx } = styles();
  const dispatch = useDispatch();
  const handleOpenFile = useOpenFile();
  const notify = useNotify();
  const statesObj = useSelector(systemSelectors.statesObj);

  const viewVersion = useSelector(uiSelectors.viewVersion);

  const isReship = useSelector(orderSelectors.isReship);
  const reshipState = useSelector((state) =>
    selectReshipState(state, isReship),
  );

  const [showEditCarrier, setShowEditCarrier] = useState(false);
  const [showEditShipInfo, setShowEditShipInfo] = useState(false);
  const [showEditWarehouse, setShowEditWarehouse] = useState(false);
  const [showEditReshipReason, setShowEditReshipReason] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [overrideCarrierName, setOverrideCarrierName] = useState('');
  const [overrideServiceName, setOverrideServiceName] = useState('');
  const [overrideFulfillmentDisplay, setOverrideFulfillmentDisplay] =
    useState('');
  const [overrideWarehouseName, setOverrideWarehouseName] = useState('');

  const [showDocEditModal, setShowDocEditModal] = useState(false);
  const [docEditId, setDocEditId] = useStateWithCallbackInstant(null, (id) =>
    setShowDocEditModal(!!id),
  );

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    (async function () {
      const { data } = await dispatch(
        getProcessOrder(selectedOrder.externalID, false),
      );
      if (data) {
        const newOrderState = generateOrderState(selectedOrder, data);
        // check if changes to fulfillment type requires navigating to other packing flow
        const flowToNavigate = getFlowToNavigateTo(
          selectedOrder,
          newOrderState,
        );

        if (flowToNavigate) {
          const path =
            flowToNavigate === 'freightFlow'
              ? paths.FREIGHT_PACKING_MODE
              : paths.PACKING_MODE;

          Navigation.redirect(
            generateUrl(
              path,
              { [pathParams.ORDER_ID]: selectedOrder.externalID },
              { view: 'items', 'should-reset': true },
            ),
          );
        } else {
          dispatch(setSelectedOrder(newOrderState));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewVersion]);

  const handleEditReshipShipInfo = useCallback(
    (resourceType) => (data) => {
      const {
        id,
        carrierServiceName,
        carrierName,
        fulfillmentTypeDisplay,
        warehouseName,
        ...rest
      } = data;
      const payload = rest;
      resourceType === 'carrier' && (payload.hasOverrideCarrierInfo = true);
      resourceType === 'shipTo' && (payload.hasOverrideShipToInfo = true);
      dispatch(updateReshipProperties(payload));
      !!carrierName && setOverrideCarrierName(carrierName);
      !!carrierServiceName && setOverrideServiceName(carrierServiceName);
      !!fulfillmentTypeDisplay &&
        setOverrideFulfillmentDisplay(fulfillmentTypeDisplay);
      !!warehouseName && setOverrideWarehouseName(warehouseName);
      notify('Changes saved');
    },
    [dispatch, notify],
  );

  const {
    id: orderId,
    customerID,
    customerName,
    warehouseName,
    warehouseTimeZoneID,
    status,
    // statusDisplay,
    defaultPickAndStickMode,
    defaultShipsWholePallet,
    documents,
    notes,
    items,
    carrierInfo: {
      carrierDisplayName,
      carrierServiceName,
      fulfillmentTypeDisplay,
      invalidThirdPartyAccount,
      lastUpdatedBy,
      lastUpdatedOn,
    } = {},
    shipToContact: { address: receiverAddress } = {},
  } = selectedOrder || {};

  const { shippingCategory } = receiverAddress || {};

  const {
    fulfillmentType,
    companyID,
    carrierRequestedID,
    carrierRequestedName,
    carrierServiceRequestedCode,
    carrierPackageCode,
    shippingSelectionPreference,
    thirdPartyAccountNumber,
    insuranceCost,
    requiresDeliveryConfirmation,
    confirmationType,
    customs,
    isInternationalShipment,
    advancedOptions,
    truckingJobPickupNote,
    truckingJobDestinationNote,
    billToContact,
  } = useMemo(
    () => getEditCarrierData(selectedOrder, isReship, reshipState) || {},
    [isReship, reshipState, selectedOrder],
  );

  const {
    shipToAddress1,
    shipToAddress2,
    shipToCity,
    shipToStateID,
    shipToStateAlternate,
    shipToZip,
    shipToCountryID,
    shipToCompanyName,
    shipToFirstName,
    shipToLastName,
    shipToPhone,
    shipWithFailedAddressValidation,
    addressValidationMessages,
  } = useMemo(
    () => getEditShipData(selectedOrder, isReship, reshipState) || {},
    [isReship, reshipState, selectedOrder],
  );

  const editShipmentData = {
    shipToAddress1,
    shipToAddress2,
    shipToCity,
    shipToStateID,
    shipToStateAlternate,
    shipToZip,
    shipToCountryID,
    shipToCompanyName,
    shipToFirstName,
    shipToLastName,
    shipToPhone,
    shipWithFailedAddressValidation,
    addressValidationMessages,
  };

  const editCarrierData = {
    fulfillmentType,
    companyID,
    carrierRequestedID,
    carrierRequestedName,
    carrierServiceRequestedCode,
    carrierPackageCode,
    shippingSelectionPreference,
    thirdPartyAccountNumber,
    insuranceCost,
    customerID,
    requiresDeliveryConfirmation,
    confirmationType,
    customs,
    isInternationalShipment,
    advancedOptions,
    truckingJobPickupNote,
    truckingJobDestinationNote,
    billToContact,
  };

  if (isInternationalShipment) {
    editCarrierData.items = items;
  }

  const invalidAddressMessages = addressValidationMessages?.messages;

  return (
    <Fragment>
      <div className={classes.content}>
        <div className={classes.client}>{customerName}</div>
        <div className={classes.warehouse}>
          {overrideWarehouseName || warehouseName}
          <Fragment>
            {isReship && (
              <EditIcon
                className={classes.editIcon}
                style={{ marginLeft: 4 }}
                onClick={() => setShowEditWarehouse(true)}
              />
            )}
          </Fragment>
        </div>
        <div className={classes.row}>
          {/* <div className={classes.group}>
            <CheckCircleIcon className={classes.statusIcon} fontSize='small' />
            <div className={classes.status}>Ready to ship</div>
          </div> */}
          {
            isReship && !!reshipState?.reshipReturnReasonDisplay && (
              <div className={classes.sectionTitle}>
                <span
                  className={cx(classes.status)}
                  style={{
                    ...orderStatusStyles.Packed,
                    maxWidth: '100%',
                  }}
                >
                  {reshipState.reshipReturnReasonDisplay + ' '}
                </span>
                <EditIcon
                  className={classes.editIcon}
                  style={{ marginLeft: 4 }}
                  onClick={() => setShowEditReshipReason(true)}
                />
              </div>
            )
            //  : (
            //   <div
            //     className={cx(classes.status)}
            //     style={orderStatusStyles[status]}
            //   >
            //     {statusDisplay}
            //   </div>
            // )
          }
        </div>
        {defaultPickAndStickMode && (
          <div className={classes.row}>
            <div className={classes.flex}>
              <PickNStickIcon className={classes.pickNStickIcon} />
              <span>Pick and stick</span>
            </div>
          </div>
        )}
        {defaultShipsWholePallet && (
          <div className={classes.row}>
            <div className={classes.flex}>
              <PickAndStickSVG style={{ marginRight: 8 }} />
              <span>Pick and ship</span>
            </div>
          </div>
        )}
      </div>
      <div className={classes.divider}></div>
      <div className={classes.content}>
        <div className={classes.sectionTitle}>
          Ship to info{' '}
          <EditIcon
            className={classes.editIcon}
            style={{ marginLeft: 4 }}
            onClick={() => setShowEditShipInfo(true)}
          />
        </div>
        <div className={classes.shipName}>
          {getReceiverName({
            firstName: shipToFirstName,
            lastName: shipToLastName,
          })}
        </div>
        <div className={classes.shipName}>{shipToCompanyName}</div>
        <div className={classes.flex} style={{ alignItems: 'flex-start' }}>
          {shippingCategory === 'Residential' && (
            <HomeIcon
              style={{ fontSize: 18, marginRight: 4 }}
              className={classes.shipAddress}
            />
          )}
          {shippingCategory === 'Commercial' && (
            <BusinessIcon
              style={{ fontSize: 18, marginRight: 4 }}
              className={classes.shipAddress}
            />
          )}
          <div className={classes.flexColumn}>
            <div className={classes.shipAddress}>{shipToAddress1}</div>
            <div className={classes.shipAddress}>{`${shipToCity || ''}, ${
              statesObj?.[shipToStateID]?.code || ''
            } ${shipToZip || ''}`}</div>
          </div>
        </div>
        {Array.isArray(invalidAddressMessages) && (
          <div
            className={classes.row}
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            {invalidAddressMessages.map((m, i) => {
              const { message } = m;
              return (
                <Typography
                  key={i}
                  component='span'
                  color='error'
                  style={{ fontSize: 14 }}
                >
                  {message}
                </Typography>
              );
            })}
          </div>
        )}
      </div>
      <div className={classes.divider}></div>
      <div className={classes.content}>
        <div className={classes.sectionTitle}>
          Carrier info{' '}
          <EditIcon
            className={classes.editIcon}
            style={{ marginLeft: 4 }}
            onClick={() => setShowEditCarrier(true)}
          />
        </div>
        <Grid container spacing={1} className={classes.grid}>
          <CarrierField
            title='Fulfillment type'
            value={overrideFulfillmentDisplay || fulfillmentTypeDisplay}
            fullWidth
          />
          <CarrierField
            title='Carrier'
            value={overrideCarrierName || carrierDisplayName}
          />
          <CarrierField
            title='Account nickname'
            value={
              <LongTextHoverTooltip
                text={carrierRequestedName}
                maxLength={18}
              />
            }
          />
          <CarrierField
            title='Service'
            value={
              <LongTextHoverTooltip
                text={overrideServiceName || carrierServiceName}
                maxLength={18}
              />
            }
          />
          <CarrierField
            title='Preferred method'
            value={shippingSelectionPreference}
          />
          {!!lastUpdatedBy && (
            <Grid
              classes={{ item: classes.gridItem }}
              size={{
                sm: 12,
              }}
            >
              <span className={classes.lastUpdated} style={{ marginTop: 0 }}>
                {`Last updated by ${lastUpdatedBy} ${
                  !!lastUpdatedOn
                    ? '- on ' +
                      formatDate(lastUpdatedOn, {
                        formatStr: 'Pp zzz',
                        useTime: true,
                        timeZone: warehouseTimeZoneID,
                      })
                    : ''
                }`}
              </span>
            </Grid>
          )}
        </Grid>
        <div
          className={classes.sectionTitle}
          style={{ fontWeight: 600, marginTop: 24 }}
        >
          Shipping options
        </div>
        <Grid container spacing={1} className={classes.grid}>
          <CarrierField title='Bill to' value={null} />
          <CarrierField
            title='Third party account'
            value={thirdPartyAccountNumber}
            errorMsg={invalidThirdPartyAccount && 'Invalid Third party account'}
          />
          <CarrierField
            title='Confirmation'
            value={
              requiresDeliveryConfirmation ? (
                <DoneIcon style={{ fontSize: 18 }} />
              ) : (
                <CloseIcon style={{ fontSize: 18 }} />
              )
            }
          />
          <CarrierField
            title='Insurance amount'
            value={formatCurrency(insuranceCost)}
          />
        </Grid>
        {/* TODO implement when order completed */}
        {/* <Button color='primary' className={classes.viewRates}>
          view rates
        </Button> */}
      </div>
      <div className={classes.divider}></div>
      {showEditCarrier && (
        <EditOrderCarrierForm
          open={showEditCarrier}
          handleClose={() => setShowEditCarrier(false)}
          orderId={orderId}
          orderStatus={status}
          curData={editCarrierData}
          isProcessing
          customSubmit={
            isReship ? handleEditReshipShipInfo('carrier') : undefined
          }
        />
      )}
      {showEditShipInfo && (
        <EditOrderShipInfoForm
          open={showEditShipInfo}
          handleClose={() => setShowEditShipInfo(false)}
          orderId={orderId}
          curData={editShipmentData}
          customSubmit={
            isReship ? handleEditReshipShipInfo('shipTo') : undefined
          }
        />
      )}
      {showNotes && (
        <ProcessOrderNotes
          open={showNotes}
          handleClose={() => setShowNotes(false)}
          notes={notes}
        />
      )}
      {showEditWarehouse && (
        <EditReshipWarehouseForm
          open={showEditWarehouse}
          handleClose={() => setShowEditWarehouse(false)}
          warehouseID={reshipState?.warehouseID}
          callback={handleEditReshipShipInfo()}
        />
      )}
      {showEditReshipReason && (
        <ReshipReasonForm
          open={showEditReshipReason}
          handleClose={() => setShowEditReshipReason(false)}
          reshipReturnReason={reshipState?.reshipReturnReason}
          overrideShouldChargeToCustomer={
            reshipState?.overrideShouldChargeToCustomer
          }
          callback={handleEditReshipShipInfo()}
        />
      )}
      {!!notes?.length && (
        <Fragment>
          <div className={classes.content}>
            <div className={classes.sectionTitle}>
              Notes
              <NoteIcon
                className={classes.docIcon}
                onClick={() => setShowNotes(true)}
                style={{ fontSize: 18, marginLeft: 5 }}
              />
            </div>
          </div>
          <div className={classes.divider}></div>
        </Fragment>
      )}
      <div className={classes.content}>
        <div className={classes.sectionTitle}>Documents</div>
        {documents.map((d) => {
          const {
            id,
            documentFeeTypeDisplay,
            quantity,
            document: {
              id: docId,
              createdOn,
              name,
              objectName,
              description,
            } = {},
          } = d;

          return (
            <div className={classes.docSection} key={id}>
              <DescriptionIcon
                className={classes.docIcon}
                fontSize='small'
                onClick={() => handleOpenFile(objectName)}
              />
              <div>
                <div className={cx(classes.docName, classes.flex)}>
                  {name}
                  <EditIcon
                    className={classes.editIcon}
                    style={{ marginLeft: 4 }}
                    onClick={() => setDocEditId(docId)}
                  />
                </div>
                <div className={classes.docDesc}>{`${
                  documentFeeTypeDisplay !== 'Other'
                    ? documentFeeTypeDisplay
                    : ''
                } created on ${formatDate(createdOn)}`}</div>
                {!!description && (
                  <div className={classes.docDesc}>
                    <LongTextHoverTooltip text={description} maxLength={45} />
                  </div>
                )}
                {!!quantity && (
                  <div
                    className={classes.docDesc}
                  >{`${quantity} page(s) billed to customer`}</div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {showDocEditModal && (
        <OrderDocumentUpload
          open={showDocEditModal}
          handleClose={() => setDocEditId(null)}
          orderId={orderId}
          documentId={docEditId}
          customerId={customerID}
        />
      )}
    </Fragment>
  );
}

// function checkIfPickAndStick(lineItems) {
//   if (!lineItems) return false;
//   return lineItems.some((l) => l.hasEverPickedAndSticked);
// }

function CarrierField({ title, value, fullWidth, errorMsg }) {
  const { classes } = styles();
  return (
    <Grid
      classes={{ item: classes.gridItem }}
      size={{
        sm: fullWidth ? 12 : 6,
      }}
    >
      <div className={classes.carrierGroup}>
        <div className={classes.flex}>
          <div className={classes.carrierLabel}>{title}</div>
          {!!errorMsg && (
            <Tooltip title={errorMsg}>
              <WarningOutlinedIcon className={classes.errorIcon} />
            </Tooltip>
          )}
        </div>
        <div className={classes.carrierTxt}>{value ?? '--'}</div>
      </div>
    </Grid>
  );
}

function generateOrderState(curValues, newValues) {
  const {
    customerName,
    warehouseName,
    shipToCompanyName,
    carrierInfo,
    shipToContact,
    insuranceCost,
    documents,
  } = newValues;

  return {
    ...curValues,
    customerName,
    warehouseName,
    shipToCompanyName,
    carrierInfo,
    shipToContact,
    insuranceCost,
    documents,
  };
}

/**
 *  Check if when changing the fulfillment type we need to navigate to freight flow or vice versa
 * @param {object} curOrderState
 * @param {object} incomingOrderState
 * @returns {string} The flow to navigate to
 */
function getFlowToNavigateTo(curOrderState, incomingOrderState) {
  // check if we need to navigate to freight flow or vice versa
  let flowToNavigate = null;
  const curFulfillmentType = curOrderState.carrierInfo?.fulfillmentType;
  const incomingFulfillmentType =
    incomingOrderState.carrierInfo?.fulfillmentType;

  if (curFulfillmentType || incomingFulfillmentType) {
    if (
      (!curFulfillmentType || curFulfillmentType === 'SmallParcel') &&
      (incomingFulfillmentType === 'LTL' || incomingFulfillmentType === 'FTL')
    ) {
      flowToNavigate = 'freightFlow';
    } else if (
      (curFulfillmentType === 'LTL' || curFulfillmentType === 'FTL') &&
      (!incomingFulfillmentType || incomingFulfillmentType === 'SmallParcel')
    ) {
      flowToNavigate = 'smallParcelFlow';
    }
  }

  return flowToNavigate;
}

function getEditShipData(selectedOrder, isReship, reshipState) {
  const {
    shipToCompanyName,
    shipToContact: {
      firstName,
      lastName,
      address: receiverAddress,
      officePhone,
      shipWithFailedAddressValidation,
      addressValidationMessages,
    } = {},
  } = selectedOrder || {};
  const { address1, address2, city, stateID, countryID, stateAlternate, zip } =
    receiverAddress || {};

  const {
    hasOverrideShipToInfo,
    shipToCompanyName: reshipCompanyName,
    shipToFirstName,
    shipToLastName,
    shipToAddress1,
    shipToAddress2,
    shipToCity,
    shipToStateID,
    shipToStateAlternate,
    shipToZip,
    shipToCountryID,
    shipToPhone,
  } = reshipState || {};

  if (isReship && hasOverrideShipToInfo) {
    return {
      shipToAddress1,
      shipToAddress2,
      shipToCity,
      shipToStateID,
      shipToStateAlternate,
      shipToZip,
      shipToCountryID,
      shipToCompanyName: reshipCompanyName,
      shipToFirstName,
      shipToLastName,
      shipToPhone,
    };
  } else {
    return {
      shipToAddress1: address1,
      shipToAddress2: address2,
      shipToCity: city,
      shipToStateID: stateID,
      shipToStateAlternate: stateAlternate,
      shipToZip: zip,
      shipToCountryID: countryID,
      shipToCompanyName,
      shipToFirstName: firstName,
      shipToLastName: lastName,
      shipToPhone: officePhone,
      shipWithFailedAddressValidation,
      addressValidationMessages,
    };
  }
}

function getEditCarrierData(selectedOrder, isReship, reshipState) {
  const {
    customerID,
    items,
    carrierInfo: {
      carrierDisplayName,
      carrierServiceCode,
      carrierPackageCode,
      fulfillmentType,
      companyID,
      carrierID,
      carrierAccountID,
      shippingSelectionPreference,
      requiresDeliveryConfirmation,
      confirmationType,
      insuranceCost,
      customs,
      isInternationalShipment,
      advancedOptions,
      truckingJobPickupNote,
      truckingJobDestinationNote,
      billToContact,
    } = {},
  } = selectedOrder || {};

  const {
    hasOverrideCarrierInfo,
    fulfillmentType: reshipFulfillmentType,
    carrierID: reshipCarrierID,
    companyID: reshipCompanyID,
    carrierRequestedName,
    carrierServiceCode: reshipCarrierServiceCode,
    carrierPackageCode: reshipCarrierPackageCode,
    shippingSelectionPreference: reshipShippingSelectionPreference,
    thirdPartyAccountNumber: reshipThirdPartyAccountNumber,
    insuranceCost: reshipInsuranceCost,
    requiresDeliveryConfirmation: reshipRequiresDeliveryConfirmation,
    confirmationType: reshipConfirmationType,
  } = reshipState || {};

  if (isReship && hasOverrideCarrierInfo) {
    return {
      fulfillmentType: reshipFulfillmentType,
      carrierRequestedID: reshipCarrierID,
      companyID: reshipCompanyID,
      carrierRequestedName,
      carrierServiceRequestedCode: reshipCarrierServiceCode,
      carrierPackageCode: reshipCarrierPackageCode,
      shippingSelectionPreference: reshipShippingSelectionPreference,
      thirdPartyAccountNumber: reshipThirdPartyAccountNumber,
      insuranceCost: reshipInsuranceCost,
      customerID,
      requiresDeliveryConfirmation: reshipRequiresDeliveryConfirmation,
      confirmationType: reshipConfirmationType,
    };
  } else {
    const data = {
      fulfillmentType,
      companyID,
      carrierRequestedID: carrierID,
      carrierRequestedName: carrierDisplayName,
      carrierServiceRequestedCode: carrierServiceCode,
      carrierPackageCode,
      shippingSelectionPreference,
      thirdPartyAccountNumber: carrierAccountID,
      insuranceCost,
      customerID,
      requiresDeliveryConfirmation,
      confirmationType,
      customs,
      isInternationalShipment,
      advancedOptions,
      truckingJobPickupNote,
      truckingJobDestinationNote,
      billToContact,
    };
    if (isInternationalShipment) {
      data.items = items;
    }
    return data;
  }
}
