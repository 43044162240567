import { useCallback, useState, useEffect, useMemo } from 'react';
import { object, string } from 'yup';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import { FormModal } from './FormModal';
import { TextInput, SelectInput, CheckboxInput } from '../inputs';
import {
  useDispatch,
  useSelector,
  addResource,
  deleteResource,
  fetchResourceById,
  systemSelectors,
} from '../../../state';
import { useRefresh, useGetUsersData } from '../../../hooks';
import { authGet, convertNullFieldsToEmptyString } from '../../../lib';
import { InfoMessage } from '../InfoMessage';

const initialState = {
  type: '',
  name: '',
  amount: '',
  baseCost: '',
  customerFeeID: '',
  defaultFeeID: '',
  quantity: 1,
  billableToCustomer: true,
  note: '',
};

export function DrayageJobFeeForm({
  open,
  handleClose,
  drayageJobId,
  drayageJobFeeId,
  customerId,
  carrierId,
}) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const { isCustomer } = useGetUsersData();

  const allFeeTypes = useSelector(systemSelectors.shippingFeeTypes);

  const customFeeTypes = useMemo(
    () =>
      allFeeTypes.filter(
        (f) => f.id !== 'CarrierPercentageFee' && f.id !== 'CarrierPalletFee',
      ),
    [allFeeTypes],
  );

  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState(initialState);
  const [baseFeesOptions, setBaseFeesOptions] = useState({});
  const [chassisFeesOptions, setChassisFeesOptions] = useState({});
  // const [fuelFeesOptions, setFuelFeesOptions] = useState({});
  const [flatFeesOptions, setFlatFeesOptions] = useState({});
  const [hourlyFeesOptions, setHourlyFeesOptions] = useState({});
  const [dayFeesOptions, setDayFeesOptions] = useState({});
  const [congestionFeesOptions, setCongestionFeesOptions] = useState({});
  const [hazmatFeesOptions, setHazmatFeesOptions] = useState({});
  const [standardFeesOptions, setStandardFeesOptions] = useState({});
  const [oversizeFeesOptions, setOversizeFeesOptions] = useState({});
  const [terminalFeesOptions, setTerminalFeesOptions] = useState({});
  const [customerFeesMapping, setCustomerFeesMapping] = useState({});

  useEffect(() => {
    (async function () {
      if (drayageJobFeeId) {
        const { data } = await dispatch(
          fetchResourceById({
            baseUrl: '/drayageJobs',
            id: drayageJobId,
            path: `fees/${drayageJobFeeId}`,
          }),
        );
        if (data) {
          const {
            customerFeeID,
            discountedRates,
            feeTypeDisplay,
            max,
            min,
            percentage,
            amount,
            baseCost,
            quantity,
            ...rest
          } = data;

          if (data.type) {
            // if a custom fee was generated somewhere else (ie refresh order fee), make sure it's in the list
            const specialFeeTypesOptions = customFeeTypes.map((t) => t.id);
            if (!specialFeeTypesOptions.includes(data.type)) {
              customFeeTypes.push({ id: data.type, name: feeTypeDisplay });
            }
          }
          setState((cur) => ({
            ...cur,
            ...convertNullFieldsToEmptyString(rest),
            amount: amount != null ? amount + (baseCost ?? 0) : '',
            quantity: quantity ?? 1,
          }));
        }
      } else if (customerId && carrierId) {
        const { data } = await authGet([
          '/drayageJobs/fees/contract-fees',
          { customerId, carrierId },
        ]);
        if (data) {
          let idAmountMapping = {};
          Object.keys(data).forEach((d) => {
            const fees = data[d];

            const mappings = fees.reduce((acc, cur) => {
              acc[cur.id.toString()] = cur;
              return acc;
            }, {});

            idAmountMapping = { ...idAmountMapping, ...mappings };
            switch (d) {
              case 'BaseFee':
                setBaseFeesOptions(fees);
                break;
              case 'DayFee':
                setDayFeesOptions(fees);
                break;
              case 'FlatFee':
                setFlatFeesOptions(fees);
                break;
              case 'HourlyFee':
                setHourlyFeesOptions(fees);
                break;
              // case 'FuelFee':
              //   setFuelFeesOptions(fees);
              //   break;
              case 'CongestionFee':
                setCongestionFeesOptions(fees);
                break;
              case 'ChassisFee':
                setChassisFeesOptions(fees);
                break;
              case 'CarrierHazmatFee':
                setHazmatFeesOptions(fees);
                break;
              case 'CarrierStandardFee':
                setStandardFeesOptions(fees);
                break;
              case 'OversizedContainerFee':
                setOversizeFeesOptions(fees);
                break;
              case 'TerminalFee':
                setTerminalFeesOptions(fees);
                break;
              default:
                break;
            }
          });

          setCustomerFeesMapping(idAmountMapping);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drayageJobId, dispatch, drayageJobFeeId, customerId]);

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      const payload = {
        ...values,
        id: values.id || null,
      };
      const { error } = await dispatch(
        addResource({
          baseUrl: `drayageJobs/${drayageJobId}/fees`,
          payload,
          message: 'Changes saved',
        }),
      );
      setSubmitting(false);
      if (!error) {
        refresh();
        handleClose();
      }
    },
    [dispatch, drayageJobId, refresh, handleClose],
  );

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      deleteResource({
        baseUrl: '/drayageJobs',
        id: drayageJobId,
        path: `fees/${drayageJobFeeId}`,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
  }, [drayageJobId, dispatch, drayageJobFeeId, handleClose, refresh]);

  const formik = useFormik({
    initialValues: { ...initialState, ...state },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  useEffect(() => {
    if (!drayageJobFeeId) {
      formik.setFieldValue('customerFeeID', '');
      formik.setFieldValue('amount', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.type]);

  const handleTypeChange = useCallback(
    async (e) => {
      const { name, value } = e.target;
      formik.setFieldValue(name, value);
      const foundMapping = customerFeesMapping[value + ''];
      if (foundMapping) {
        formik.setFieldValue('amount', foundMapping.amount ?? 0);
        formik.setFieldValue('name', foundMapping.name ?? 0);
        formik.setFieldValue('defaultFeeID', foundMapping.defaultFeeID);
      }
    },
    [customerFeesMapping, formik],
  );

  const { handleChange, ...formikProps } = formik;

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <span className={classes.title}>{`${
          drayageJobFeeId ? 'Edit' : 'Add'
        } drayage fee`}</span>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      paperProps={{ style: { width: 468 } }}
      submitting={submitting}
      isEdit={!!drayageJobFeeId}
      handleDelete={handleDelete}
    >
      {formik.values.type === 'BaseFee' && (
        <InfoMessage message='Changing the base fee will affect the fuel fee.' />
      )}
      <SelectInput
        name='type'
        formikProps={formikProps}
        onChange={handleTypeChange}
        label='Fee type'
        className={classes.input}
        options={customFeeTypes}
        disabled={!!drayageJobFeeId}
      />
      {!!baseFeesOptions.length && formik.values.type === 'BaseFee' && (
        <SelectInput
          name='customerFeeID'
          formikProps={formikProps}
          onChange={handleTypeChange}
          label='Select fee'
          className={classes.input}
          options={baseFeesOptions}
          clearable
        />
      )}
      {!!dayFeesOptions.length && formik.values.type === 'DayFee' && (
        <SelectInput
          name='customerFeeID'
          formikProps={formikProps}
          onChange={handleTypeChange}
          label='Select fee'
          className={classes.input}
          options={dayFeesOptions}
          clearable
        />
      )}
      {!!hourlyFeesOptions.length && formik.values.type === 'HourlyFee' && (
        <SelectInput
          name='customerFeeID'
          formikProps={formikProps}
          onChange={handleTypeChange}
          label='Select fee'
          className={classes.input}
          options={hourlyFeesOptions}
          clearable
        />
      )}
      {!!flatFeesOptions.length && formik.values.type === 'FlatFee' && (
        <SelectInput
          name='customerFeeID'
          formikProps={formikProps}
          onChange={handleTypeChange}
          label='Select fee'
          className={classes.input}
          options={flatFeesOptions}
          clearable
        />
      )}
      {/* {!!fuelFeesOptions.length && formik.values.type === 'FuelFee' && (
        <SelectInput
          name='customerFeeID'
          formikProps={formikProps}
          onChange={handleTypeChange}
          label='Select fee'
          className={classes.input}
          options={fuelFeesOptions}
          clearable
        />
      )} */}
      {!!congestionFeesOptions.length &&
        formik.values.type === 'CongestionFee' && (
          <SelectInput
            name='customerFeeID'
            formikProps={formikProps}
            onChange={handleTypeChange}
            label='Select fee'
            className={classes.input}
            options={congestionFeesOptions}
            clearable
          />
        )}
      {!!chassisFeesOptions.length && formik.values.type === 'ChassisFee' && (
        <SelectInput
          name='customerFeeID'
          formikProps={formikProps}
          onChange={handleTypeChange}
          label='Select fee'
          className={classes.input}
          options={chassisFeesOptions}
          clearable
        />
      )}
      {!!hazmatFeesOptions.length &&
        formik.values.type === 'CarrierHazmatFee' && (
          <SelectInput
            name='customerFeeID'
            formikProps={formikProps}
            onChange={handleTypeChange}
            label='Select fee'
            className={classes.input}
            options={hazmatFeesOptions}
            clearable
          />
        )}
      {!!standardFeesOptions.length &&
        formik.values.type === 'CarrierStandardFee' && (
          <SelectInput
            name='customerFeeID'
            formikProps={formikProps}
            onChange={handleTypeChange}
            label='Select fee'
            className={classes.input}
            options={standardFeesOptions}
            clearable
          />
        )}
      {!!oversizeFeesOptions.length &&
        formik.values.type === 'OversizedContainerFee' && (
          <SelectInput
            name='customerFeeID'
            formikProps={formikProps}
            onChange={handleTypeChange}
            label='Select fee'
            className={classes.input}
            options={oversizeFeesOptions}
            clearable
          />
        )}
      {!!terminalFeesOptions.length && formik.values.type === 'TerminalFee' && (
        <SelectInput
          name='customerFeeID'
          formikProps={formikProps}
          onChange={handleTypeChange}
          label='Select fee'
          className={classes.input}
          options={terminalFeesOptions}
          clearable
        />
      )}
      {!formik.values.customerFeeID && (
        <TextInput
          name='name'
          formikProps={formik}
          label='Fee name'
          className={classes.input}
        />
      )}
      {formik.values.type === 'FuelFee' && (
        <TextInput
          name='baseCost'
          formikProps={formik}
          label='Base cost'
          className={classes.input}
          format='currency'
        />
      )}
      <TextInput
        name='amount'
        formikProps={formik}
        label='Rate'
        className={classes.input}
        format='currency'
      />
      <TextInput
        name='quantity'
        formikProps={formik}
        label='Quantity'
        className={classes.input}
        type='number'
        slotProps={{
          htmlInput: {
            min: 1,
          },
        }}
      />
      <TextInput
        name='note'
        label='Notes'
        formikProps={formik}
        className={classes.input}
        multiline
        rows={3}
      />
      {!isCustomer && (
        <CheckboxInput
          formikProps={formik}
          checkboxes={[
            {
              labelProps: {
                label: 'Billable to customer',
              },
              checkboxProps: {
                name: 'billableToCustomer',
              },
            },
          ]}
        />
      )}
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  input: {
    marginBottom: 16,
    width: 300,
  },
}));

const schema = object().shape({
  quantity: string().required('Required'),
});
