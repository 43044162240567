import { memo } from 'react';
import _get from 'lodash.get';
import { Typography, Tooltip, TypographyProps } from '@mui/material';
import { limitStringSize, stableObject } from '../../../lib';
import { useStyles } from './fields.styles';
import { AlignType } from '../../../types';

interface Props {
  typographyProps?: TypographyProps;
  record?: Record<string, any>;
  className?: string;
  headerClassName?: string;
  cellStyle?: React.CSSProperties;
  source: string;
  label: string;
  emptyText?: string;
  disablePadding?: string;
  align?: AlignType;
  sortBy?: string;
  sortable?: false;
  maxCharacterLength: number;
}

export const HoverableField = memo<Props>(
  ({
    className,
    typographyProps: _typographyProps,
    source,
    record: _record,
    maxCharacterLength,
    emptyText = '--',
  }) => {
    const { classes, cx } = useStyles();
    const record = _record || stableObject;
    const typographyProps = _typographyProps || stableObject;

    const value = _get(record, source);

    const truncated = limitStringSize(value, maxCharacterLength, true);
    const showTooltip = value?.length > maxCharacterLength;

    if (!value) return <span> {emptyText}</span>;

    return (
      <Tooltip title={showTooltip ? value : ''}>
        <Typography
          component='span'
          variant='body2'
          className={cx(classes.field, className)}
          {...typographyProps}
        >
          {truncated}
        </Typography>
      </Tooltip>
    );
  },
);
