import { useCallback, useEffect, useMemo, useState } from 'react';
import { object, string } from 'yup';
import { Grid2 as Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useFormik } from 'formik';
import Payment from 'payment';
import { useDispatch, addResource } from '../../../state';
import { useNotify } from '../../../hooks';
import { BasicDialog, FormModal } from '.';
import { CheckboxInput, TextInput, SelectInput } from '../inputs';
import {
  bankAccountSchema,
  cardSchema,
  getCCDisplay,
  getQBPaymentToken,
  modes,
  paymentMethodTypes,
} from './creditCardUtils';
import { PaymentMethodFields } from '../..';
import { formatCurrency } from '../../../lib';

const initialState = {
  amount: '',
  paymentMethodID: '',
  name: '',
};

const paymentMethodInitialState = {
  isDefault: false,
  // card
  number: '',
  expiry: '',
  cvc: '',
  zip: '',
  focus: '',
  // bank
  accountNumber: '',
  phone: '',
  accountType: '',
  routingNumber: '',
};

export function AddFundForm({
  accountBalanceTypeDisplay,
  balance,
  customerId,
  handleClose,
  invoiceId,
  minBalanceRequired,
  minReplenishAmount,
  open,
  paymentMethodRows,
  pendingChargesAmount,
  qbBalance,
  replenishAmountDue,
  setViewVersion,
  totalBalanceDue,
}) {
  const { classes, css, cx } = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const [mode, setMode] = useState(modes.CREDIT_CARD);
  const [submitting, setSubmitting] = useState(false);
  const [payBalanceInFull, setPayBalanceInFull] = useState(false);
  const [payReplenishAmntDue, setPayReplenishAmntDue] = useState(false);
  const [payBalanceAndReplenishment, setPayBalanceAndReplenishment] =
    useState(false);
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const paymentMethodsOptions = useMemo(
    () => [
      {
        id: 0,
        name: (
          <span style={{ color: '#00C4F8' }}>
            + Add one time payment method
          </span>
        ),
      },
      ...Object.values(paymentMethodRows || {}).map((r) => {
        const { isDefault, lastFour, cardTypeDisplay } = r;
        const ccDisplay = getCCDisplay({ lastFour, cardTypeDisplay });
        return {
          id: r.id,
          name: `${ccDisplay}${isDefault ? ' (Default)' : ''}`,
        };
      }),
    ],
    [paymentMethodRows],
  );

  const handleSubmit = useCallback(
    async (values) => {
      setSubmitting(true);

      const isOneTimePayment = values.paymentMethodID === 0;

      const payload = {
        amount: values.amount,
        cardType:
          isOneTimePayment && mode === modes.CREDIT_CARD
            ? cardTypeMapping[Payment.fns.cardType(values.number)]
            : null,
        customerId,
        type:
          mode === modes.BANK_ACCOUNT
            ? paymentMethodTypes.ACH
            : paymentMethodTypes.CREDIT_CARD,
        invoicesPayments: invoiceId
          ? [
              {
                invoiceId: parseInt(invoiceId),
                amount: values.amount,
              },
            ]
          : null,
      };
      if (isOneTimePayment) {
        const { qbData, errorMessage } = await getQBPaymentToken(mode, values);
        payload.generatedToken = qbData?.value;

        if (errorMessage) {
          setSubmitting(false);
          return notify(errorMessage, 'error');
        }
      } else {
        payload.paymentMethodID = values.paymentMethodID;
      }
      const { error } = await dispatch(
        addResource({
          baseUrl: `/accounting/payment`,
          payload,
        }),
      );
      setSubmitting(false);
      // This will happen if our data is not synced with QB and we try to make a payment
      // for more than the QB balance
      if (error?.code === 'InvalidAmount') {
        setViewVersion((cur) => ++cur);
        handleClose();
      }
      if (!error) {
        setViewVersion((cur) => ++cur);
        setShowSuccessDialog(true);
      }
    },
    [
      mode,
      customerId,
      invoiceId,
      dispatch,
      notify,
      setViewVersion,
      handleClose,
    ],
  );

  const formik = useFormik({
    initialValues: { ...initialState, ...paymentMethodInitialState },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: showPaymentInfo
      ? schema.concat(
          mode === modes.CREDIT_CARD ? cardSchema : bankAccountSchema,
        )
      : schema,
  });

  const { setFieldValue } = formik;

  const onChangePaymentMethod = useCallback(
    (e) => {
      const paymentMethod = Object.values(paymentMethodRows || {})?.find(
        (p) => p.id === e.target.value,
      );
      setShowPaymentInfo(!paymentMethod);
      setMode(
        paymentMethod?.type === modes.BANK_ACCOUNT
          ? modes.BANK_ACCOUNT
          : modes.CREDIT_CARD,
      );
    },
    [paymentMethodRows],
  );

  useEffect(() => {
    if (payBalanceInFull || payReplenishAmntDue || payBalanceAndReplenishment) {
      let amount = 0;
      if (payBalanceInFull) {
        amount += balance;
      }
      if (payReplenishAmntDue) {
        amount += replenishAmountDue;
      }
      if (payBalanceAndReplenishment) {
        amount = balance + replenishAmountDue;
      }
      setFieldValue('amount', parseFloat(amount).toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    balance,
    payBalanceInFull,
    payReplenishAmntDue,
    payBalanceAndReplenishment,
    replenishAmountDue,
  ]);

  const handlePayBalanceInFull = useCallback((e) => {
    setPayBalanceInFull(e.target.checked);
  }, []);

  const handlePayReplenishAmountDue = useCallback((e) => {
    setPayReplenishAmntDue(e.target.checked);
    if (e.target.checked) {
      setPayBalanceAndReplenishment(false);
    }
  }, []);

  const handlePayBalanceAndReplenishment = useCallback((e) => {
    setPayBalanceAndReplenishment(e.target.checked);
    if (e.target.checked) {
      setPayReplenishAmntDue(false);
    }
  }, []);

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title={
        <div className={classes.titleContainer}>
          <span className={classes.title}>
            {invoiceId ? 'Make a payment' : 'Add funds'}
          </span>
          <span className={classes.subTitle}>
            {invoiceId ? (
              `Make a payment for Invoice #${invoiceId}`
            ) : minReplenishAmount && totalBalanceDue > 0 ? (
              <div style={{ paddingTop: 8 }}>
                <div className={classes.accountInfoSection}>
                  Account Balance:
                </div>
                <div className={classes.accountInfoRow}>
                  <p className={classes.accountInfoTitle}>
                    QB invoiced balance:
                  </p>
                  <p>{formatCurrency(qbBalance)}</p>
                </div>
                <div className={classes.accountInfoRow}>
                  <p className={classes.accountInfoTitle}>
                    Billable balance (non-invoiced):
                  </p>
                  <p>{formatCurrency(pendingChargesAmount)}</p>
                </div>
                <div className={classes.accountInfoSection}>Account limit:</div>
                <div className={classes.accountInfoRow}>
                  <p className={classes.accountInfoTitle}>
                    ({accountBalanceTypeDisplay}) amount:
                  </p>
                  <p>{formatCurrency(minBalanceRequired)}</p>
                </div>
                <div className={classes.accountInfoRow}>
                  <p className={classes.accountInfoTitle}>Replenish amount:</p>
                  <p>{formatCurrency(minReplenishAmount)}</p>
                </div>
              </div>
            ) : (
              `Account balance: ${formatCurrency(balance)}`
            )}
          </span>
        </div>
      }
      callback={formik.handleSubmit}
      btnText='SAVE'
      typeSubmit
      maxWidth='md'
      paperProps={{
        style: { width: showPaymentInfo ? 720 : 486 },
      }}
      submitting={submitting}
    >
      <div className={classes.layout}>
        {minReplenishAmount && totalBalanceDue > 0 && (
          <div style={{ marginBottom: 16 }}>
            <div className={classes.sectionTitle}>Make a payment</div>
            {balance > 0 && (
              <>
                <CheckboxInput
                  checkboxes={[
                    {
                      labelProps: {
                        label: `Replenishment & account balance: ${formatCurrency(
                          replenishAmountDue + balance,
                        )}`,
                      },
                      checkboxProps: {
                        name: 'payBalanceAndReplenishment',
                        checked: payBalanceAndReplenishment,
                        onChange: handlePayBalanceAndReplenishment,
                      },
                    },
                  ]}
                />
                {replenishAmountDue > 0 && (
                  <p className={cx(classes.secondaryText)}>
                    {`For accounts exceeding the limit, a payment is required to
                    cover both the replenishment amount of
                    ${formatCurrency(
                      replenishAmountDue,
                    )} and the account balance
                    of ${formatCurrency(balance)}`}
                  </p>
                )}
              </>
            )}
            {replenishAmountDue > 0 && (
              <>
                <CheckboxInput
                  checkboxes={[
                    {
                      labelProps: {
                        label: `Replenish Amount Due: ${formatCurrency(
                          replenishAmountDue,
                        )}`,
                      },
                      checkboxProps: {
                        name: 'payReplenishAmntDue',
                        checked: payReplenishAmntDue,
                        onChange: handlePayReplenishAmountDue,
                      },
                    },
                  ]}
                />
                <p className={cx(classes.secondaryText)}>
                  For accounts that reached the limit, a replenishment payment
                  is required.
                </p>
              </>
            )}
          </div>
        )}
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            size={{
              sm: showPaymentInfo ? 6 : 12,
            }}
          >
            <TextInput
              name='amount'
              formikProps={formik}
              label='Amount'
              required
              size='small'
              format='currency'
              disabled={
                payBalanceInFull ||
                payReplenishAmntDue ||
                payBalanceAndReplenishment
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={5} rowSpacing={2}>
          <Grid
            size={{
              sm: showPaymentInfo ? 6 : 12,
            }}
          >
            <SelectInput
              name='paymentMethodID'
              formikProps={formik}
              label='Select payment method'
              required
              size='small'
              options={paymentMethodsOptions}
              className={classes.input}
              slotProps={{ htmlInput: { className: classes.selectInput } }}
              onChange={onChangePaymentMethod}
            />
          </Grid>
          {showPaymentInfo && (
            <div style={{ width: '100%' }}>
              <div
                className={cx(classes.sectionTitle, css({ padding: '0 20px' }))}
              >
                Payment Info
              </div>
              <PaymentMethodFields
                formik={formik}
                initialState={paymentMethodInitialState}
                isOneTimePayment={true}
                mode={mode}
                setMode={setMode}
              />
            </div>
          )}
          {(!minReplenishAmount || !totalBalanceDue || totalBalanceDue < 0) && (
            <Grid
              style={{ paddingTop: 0 }}
              size={{
                sm: 12,
              }}
            >
              {balance > 0 && (
                <CheckboxInput
                  checkboxes={[
                    {
                      labelProps: {
                        label: `${
                          invoiceId ? 'Balance' : 'Account balance'
                        }: ${formatCurrency(balance)}`,
                      },
                      checkboxProps: {
                        name: 'payFullAccountBalance',
                        checked: payBalanceInFull,
                        onChange: handlePayBalanceInFull,
                      },
                    },
                  ]}
                />
              )}
              {replenishAmountDue > 0 && (
                <CheckboxInput
                  checkboxes={[
                    {
                      labelProps: {
                        label: `Replenish Amount Due: ${formatCurrency(
                          replenishAmountDue,
                        )}`,
                      },
                      checkboxProps: {
                        name: 'payReplenishAmntDue',
                        checked: payReplenishAmntDue,
                        onChange: handlePayReplenishAmountDue,
                      },
                    },
                  ]}
                />
              )}
            </Grid>
          )}
        </Grid>
      </div>
      {showSuccessDialog && (
        <BasicDialog
          open={showSuccessDialog}
          callback={() => {
            setShowSuccessDialog(false);
            handleClose();
          }}
          hideCloseBtn={true}
          title='Payment Successful'
          text={`Your payment was successful${
            invoiceId
              ? ' and the invoice will update when it is done processing'
              : ''
          }`}
        />
      )}
    </FormModal>
  );
}

const useStyles = makeStyles()((theme) => ({
  layout: {
    padding: '0 8px 16px 8px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 8px 0px 8px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
  },
  accountInfoSection: {
    fontWeight: 'bold',
    marginBottom: 8,
    marginTop: 8,
  },
  accountInfoRow: {
    display: 'flex',
  },
  accountInfoTitle: {
    width: 275,
  },
  accountBalance: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '24px',
  },
  secondaryText: {
    fontStyle: 'italic',
    fontSize: 12,
    color: '#A5AABD',
  },
  selectInput: {
    '& span': {
      color: '#000000 !important',
    },
  },
  input: {
    width: '100%',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 24,
  },
}));

const schema = object().shape({
  amount: string().required('Required'),
  paymentMethodID: string().required('Required'),
});

const cardTypeMapping = {
  visa: 'Visa',
  dinersclub: 'Diners',
  amex: 'AmericanExpress',
  discover: 'Discover',
  mastercard: 'MasterCard',
  jcb: 'Jcb',
};
