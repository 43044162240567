import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.textTheme.main,
    fontFamily: 'Roboto',
    fontSize: 16,
    textTransform: 'none',
    width: 'inherit',
    minWidth: 'inherit',
    '&.Mui-selected': {
      color: '#096EF8',
    },
  },
  selected: {},
  indicator: {
    backgroundColor: '#096EF8',
  },
}));
