import { isValidElement, cloneElement, memo } from 'react';
import { useStyles } from './defaultState.styles';

interface DefaultStateProps {
  svg?: React.ComponentType<React.SVGProps<SVGElement>>;
  icon?: React.ReactElement<any>;
  title: string;
  firstLineTxt: string;
  secondLineTxt?: string;
  containerClassName?: string;
}

export const DefaultState = memo<DefaultStateProps>(
  ({
    svg: SvgComponent,
    icon,
    title,
    firstLineTxt,
    secondLineTxt,
    containerClassName,
  }) => {
    const { classes, cx } = useStyles();

    return (
      <div className={cx(classes.emptyContainer, containerClassName)}>
        {isValidElement(icon)
          ? cloneElement(icon)
          : !!SvgComponent && <SvgComponent style={{ marginBottom: 16 }} />}
        <div className={classes.emptyText}>{title}</div>
        <div className={classes.emptySubText}>
          <span>{firstLineTxt}</span>
          {!!secondLineTxt && <span>{secondLineTxt}</span>}
        </div>
      </div>
    );
  },
);
