import { ReactElement, useCallback } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { makeuuid } from '../../../lib';
import { useStyles } from './fileInput.styles';
import { FilePreview } from '../FilesPreview';
import {
  FileImageType,
  FileTextType,
  FileType,
  SupportedFileType,
} from '../../../types';

interface Props {
  disabled?: boolean;
  multiple?: boolean;
  acceptFileTypes?: Partial<
    Record<SupportedFileType, Array<FileTextType | FileImageType>>
  >;
  files: Array<FileType>;
  setFiles: React.Dispatch<React.SetStateAction<Array<FileType>>>;
  filePrefix?: string;
  uploadText?: string | ReactElement<any>;
}

export function FileInput({
  disabled = false,
  multiple = true,
  acceptFileTypes = defaultAcceptFiles,
  files,
  setFiles,
  filePrefix = '',
  uploadText = "Drag 'n' drop some files here, or click to select files",
}: Props) {
  const { classes, cx } = useStyles();

  const onDrop = useCallback(
    (acceptedFiles: Array<FileWithPath>) => {
      const mappedFiles = acceptedFiles.map((af) => ({
        rawFile: af,
        key: `${filePrefix}${makeuuid(12)}-${af.path}`,
      }));
      setFiles((cur) =>
        multiple ? [...cur, ...mappedFiles] : [...mappedFiles],
      );
    },
    [filePrefix, multiple, setFiles],
  );

  const handleRemove = useCallback(
    (key: string) => {
      setFiles((cur) => {
        const filtered = cur.filter((f) => f.key !== key);
        return filtered;
      });
    },
    [setFiles],
  );

  const {
    getRootProps,
    getInputProps,
    // acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, disabled, multiple, accept: acceptFileTypes });

  return (
    <section className={classes.container}>
      <div
        {...getRootProps({
          className: cx(classes.dropzone, {
            [classes.accepted]: isDragAccept,
            [classes.rejected]: isDragReject,
            [classes.active]: isDragActive,
          }),
        })}
      >
        <input {...getInputProps()} />
        <div>{uploadText}</div>
      </div>
      <aside>
        {files.map((file) => {
          return (
            <FilePreview
              key={file.key}
              file={file.rawFile}
              fileKey={file.key}
              handleRemove={handleRemove}
            />
          );
        })}
      </aside>
    </section>
  );
}

const defaultAcceptFiles: Record<
  SupportedFileType,
  Array<FileTextType | FileImageType>
> = {
  'text/*': ['.txt', '.csv', '.pdf', '.doc', '.docx', '.xlsx', '.xls'],
  'image/*': ['.jpeg', '.jpg', '.png'],
};
